import { initSubstationCrud, SubstationCrud } from "./substation.model";
import { F33Crud, initF33Crud, initF33SsCrud } from "./feeder33.model";
import { getInitRegion, Region } from "./region.model";

export interface Feeder11 {
    id: number;
    name: string;
    number: string;
    substation?: string;
}

export interface F11Crud {
    id: number;
    name: string;
    number: string;
    substation: SubstationCrud;
    feeder33: F33Crud;
    region: Region;
    nameplateRating: string;
    utilityId: number;
}

export interface FeederOption {
    id: number;
    name: string;
    number: string;
    type: number;
}

export interface F11CrudCreate {
    id: number;
    name: string;
    number: string;
    substationId: number;
    feeder33Id: number;
    nameplateRating: string;
    regionId: number;
    utilityId: number;
}

export function initF11Crud(): F11Crud {
    return {
        id: 0,
        name: '',
        number: '',
        substation: initSubstationCrud(),
        feeder33: initF33Crud(),
        region: getInitRegion(),
        nameplateRating: '',
        utilityId: 0
    };
}

export function mapObjToCreateObj(f11: F11Crud): F11CrudCreate {
    return {
        id: f11.id,
        name: f11.name,
        number: f11.number,
        substationId: f11.substation.id,
        feeder33Id: f11.feeder33.id,
        nameplateRating: f11.nameplateRating,
        regionId: f11.region.id,
        utilityId: f11.utilityId
    };
}