export interface CurrencyDropDownList {
    code: any
    text: any
}

export const CurrencyList: CurrencyDropDownList[] = [
    {"code": "AED", "text": "United Arab Emirates Dirham – AED"},
    {"code": "AFN", "text": "Afghan Afghani – AFN"},
    {"code": "ALL", "text": "Albanian Lek – ALL"},
    {"code": "AMD", "text": "Armenian Dram – AMD"},
    {"code": "ANG", "text": "Netherlands Antillean Guilder – ANG"},
    {"code": "AOA", "text": "Angolan Kwanza – AOA"},
    {"code": "ARS", "text": "Argentine Peso – ARS"},
    {"code": "AUD", "text": "Australian Dollar – AUD"},
    {"code": "AWG", "text": "Aruban Florin – AWG"},
    {"code": "AZN", "text": "Azerbaijani Manat – AZN"},
    {"code": "BAM", "text": "Bosnia-Herzegovina Convertible Mark – BAM"},
    {"code": "BBD", "text": "Barbadian Dollar – BBD"},
    {"code": "BDT", "text": "Bangladeshi Taka – BDT"},
    {"code": "BGN", "text": "Bulgarian Lev – BGN"},
    {"code": "BHD", "text": "Bahraini Dinar – BHD"},
    {"code": "BIF", "text": "Burundian Franc – BIF"},
    {"code": "BMD", "text": "Bermudian Dollar – BMD"},
    {"code": "BND", "text": "Brunei Dollar – BND"},
    {"code": "BOB", "text": "Bolivian Boliviano – BOB"},
    {"code": "BRL", "text": "Brazilian Real – BRL"},
    {"code": "BSD", "text": "Bahamian Dollar – BSD"},
    {"code": "BTN", "text": "Bhutanese Ngultrum – BTN"},
    {"code": "BWP", "text": "Botswana Pula – BWP"},
    {"code": "BYN", "text": "Belarusian Ruble – BYN"},
    {"code": "BZD", "text": "Belize Dollar – BZD"},
    {"code": "CAD", "text": "Canadian Dollar – CAD"},
    {"code": "CDF", "text": "Congolese Franc – CDF"},
    {"code": "CHF", "text": "Swiss Franc – CHF"},
    {"code": "CLP", "text": "Chilean Peso – CLP"},
    {"code": "CNY", "text": "Chinese Yuan – CNY"},
    {"code": "COP", "text": "Colombian Peso – COP"},
    {"code": "CRC", "text": "Costa Rican Colón – CRC"},
    {"code": "CUP", "text": "Cuban Peso – CUP"},
    {"code": "CVE", "text": "Cape Verdean Escudo – CVE"},
    {"code": "CZK", "text": "Czech Koruna – CZK"},
    {"code": "DJF", "text": "Djiboutian Franc – DJF"},
    {"code": "DKK", "text": "Danish Krone – DKK"},
    {"code": "DOP", "text": "Dominican Peso – DOP"},
    {"code": "DZD", "text": "Algerian Dinar – DZD"},
    {"code": "EGP", "text": "Egyptian Pound – EGP"},
    {"code": "ERN", "text": "Eritrean Nakfa – ERN"},
    {"code": "ETB", "text": "Ethiopian Birr – ETB"},
    {"code": "EUR", "text": "Euro – EUR"},
    {"code": "FJD", "text": "Fijian Dollar – FJD"},
    {"code": "FKP", "text": "Falkland Islands Pound – FKP"},
    {"code": "FOK", "text": "Faroese Króna – FOK"},
    {"code": "GBP", "text": "British Pound Sterling – GBP"},
    {"code": "GEL", "text": "Georgian Lari – GEL"},
    {"code": "GGP", "text": "Guernsey Pound – GGP"},
    {"code": "GHS", "text": "Ghanaian Cedi – GHS"},
    {"code": "GIP", "text": "Gibraltar Pound – GIP"},
    {"code": "GMD", "text": "Gambian Dalasi – GMD"},
    {"code": "GNF", "text": "Guinean Franc – GNF"},
    {"code": "GTQ", "text": "Guatemalan Quetzal – GTQ"},
    {"code": "GYD", "text": "Guyanaese Dollar – GYD"},
    {"code": "HKD", "text": "Hong Kong Dollar – HKD"},
    {"code": "HNL", "text": "Honduran Lempira – HNL"},
    {"code": "HRK", "text": "Croatian Kuna – HRK"},
    {"code": "HTG", "text": "Haitian Gourde – HTG"},
    {"code": "HUF", "text": "Hungarian Forint – HUF"},
    {"code": "IDR", "text": "Indonesian Rupiah – IDR"},
    {"code": "ILS", "text": "Israeli New Shekel – ILS"},
    {"code": "IMP", "text": "Isle of Man Pound – IMP"},
    {"code": "INR", "text": "Indian Rupee – INR"},
    {"code": "IQD", "text": "Iraqi Dinar – IQD"},
    {"code": "IRR", "text": "Iranian Rial – IRR"},
    {"code": "ISK", "text": "Icelandic Króna – ISK"},
    {"code": "JEP", "text": "Jersey Pound – JEP"},
    {"code": "JMD", "text": "Jamaican Dollar – JMD"},
    {"code": "JOD", "text": "Jordanian Dinar – JOD"},
    {"code": "JPY", "text": "Japanese Yen – JPY"},
    {"code": "KES", "text": "Kenyan Shilling – KES"},
    {"code": "KGS", "text": "Kyrgyzstani Som – KGS"},
    {"code": "KHR", "text": "Cambodian Riel – KHR"},
    {"code": "KID", "text": "Kiribati Dollar – KID"},
    {"code": "KMF", "text": "Comorian Franc – KMF"},
    {"code": "KPW", "text": "North Korean Won – KPW"},
    {"code": "KRW", "text": "South Korean Won – KRW"},
    {"code": "KWD", "text": "Kuwaiti Dinar – KWD"},
    {"code": "KYD", "text": "Cayman Islands Dollar – KYD"},
    {"code": "KZT", "text": "Kazakhstani Tenge – KZT"},
    {"code": "LAK", "text": "Laotian Kip – LAK"},
    {"code": "LBP", "text": "Lebanese Pound – LBP"},
    {"code": "LKR", "text": "Sri Lankan Rupee – LKR"},
    {"code": "LRD", "text": "Liberian Dollar – LRD"},
    {"code": "LSL", "text": "Lesotho Loti – LSL"},
    {"code": "LYD", "text": "Libyan Dinar – LYD"},
    {"code": "MAD", "text": "Moroccan Dirham – MAD"},
    {"code": "MDL", "text": "Moldovan Leu – MDL"},
    {"code": "MGA", "text": "Malagasy Ariary – MGA"},
    {"code": "MKD", "text": "Macedonian Denar – MKD"},
    {"code": "MMK", "text": "Myanma Kyat – MMK"},
    {"code": "MNT", "text": "Mongolian Tugrik – MNT"},
    {"code": "MOP", "text": "Macanese Pataca – MOP"},
    {"code": "MRU", "text": "Mauritanian Ouguiya – MRU"},
    {"code": "MUR", "text": "Mauritian Rupee – MUR"},
    {"code": "MVR", "text": "Maldivian Rufiyaa – MVR"},
    {"code": "MWK", "text": "Malawian Kwacha – MWK"},
    {"code": "MXN", "text": "Mexican Peso – MXN"},
    {"code": "MYR", "text": "Malaysian Ringgit – MYR"},
    {"code": "MZN", "text": "Mozambican Metical – MZN"},
    {"code": "NAD", "text": "Namibian Dollar – NAD"},
    {"code": "NGN", "text": "Nigerian Naira – NGN"},
    {"code": "NIO", "text": "Nicaraguan Córdoba – NIO"},
    {"code": "NOK", "text": "Norwegian Krone – NOK"},
    {"code": "NPR", "text": "Nepalese Rupee – NPR"},
    {"code": "NZD", "text": "New Zealand Dollar – NZD"},
    {"code": "OMR", "text": "Omani Rial – OMR"},
    {"code": "PAB", "text": "Panamanian Balboa – PAB"},
    {"code": "PEN", "text": "Peruvian Nuevo Sol – PEN"},
    {"code": "PGK", "text": "Papua New Guinean Kina – PGK"},
    {"code": "PHP", "text": "Philippine Peso – PHP"},
    {"code": "PKR", "text": "Pakistani Rupee – PKR"},
    {"code": "PLN", "text": "Polish Zloty – PLN"},
    {"code": "PYG", "text": "Paraguayan Guarani – PYG"},
    {"code": "QAR", "text": "Qatari Rial – QAR"},
    {"code": "RON", "text": "Romanian Leu – RON"},
    {"code": "RSD", "text": "Serbian Dinar – RSD"},
    {"code": "RUB", "text": "Russian Ruble – RUB"},
    {"code": "RWF", "text": "Rwandan Franc – RWF"},
    {"code": "SAR", "text": "Saudi Riyal – SAR"},
    {"code": "SBD", "text": "Solomon Islands Dollar – SBD"},
    {"code": "SCR", "text": "Seychellois Rupee – SCR"},
    {"code": "SDG", "text": "Sudanese Pound – SDG"},
    {"code": "SEK", "text": "Swedish Krona – SEK"},
    {"code": "SGD", "text": "Singapore Dollar – SGD"},
    {"code": "SHP", "text": "Saint Helena Pound – SHP"},
    {"code": "SLL", "text": "Sierra Leonean Leone – SLL"},
    {"code": "SOS", "text": "Somali Shilling – SOS"},
    {"code": "SRD", "text": "Surinamese Dollar – SRD"},
    {"code": "SSP", "text": "South Sudanese Pound – SSP"},
    {"code": "STN", "text": "São Tomé and Príncipe Dobra – STN"},
    {"code": "SVC", "text": "Salvadoran Colón – SVC"},
    {"code": "SYP", "text": "Syrian Pound – SYP"},
    {"code": "SZL", "text": "Swazi Lilangeni – SZL"},
    {"code": "THB", "text": "Thai Baht – THB"},
    {"code": "TJS", "text": "Tajikistani Somoni – TJS"},
    {"code": "TMT", "text": "Turkmenistani Manat – TMT"},
    {"code": "TND", "text": "Tunisian Dinar – TND"},
    {"code": "TOP", "text": "Tongan Pa'anga – TOP"},
    {"code": "TRY", "text": "Turkish Lira – TRY"},
    {"code": "TTD", "text": "Trinidad and Tobago Dollar – TTD"},
    {"code": "TVD", "text": "Tuvaluan Dollar – TVD"},
    {"code": "TWD", "text": "New Taiwan Dollar – TWD"},
    {"code": "TZS", "text": "Tanzanian Shilling – TZS"},
    {"code": "UAH", "text": "Ukrainian Hryvnia – UAH"},
    {"code": "UGX", "text": "Ugandan Shilling – UGX"},
    {"code": "USD", "text": "United States Dollar – USD"},
    {"code": "UYU", "text": "Uruguayan Peso – UYU"},
    {"code": "UZS", "text": "Uzbekistan Som – UZS"},
    {"code": "VES", "text": "Venezuelan Bolívar – VES"},
    {"code": "VND", "text": "Vietnamese Dong – VND"},
    {"code": "VUV", "text": "Vanuatu Vatu – VUV"},
    {"code": "WST", "text": "Samoan Tala – WST"},
    {"code": "XAF", "text": "CFA Franc BEAC – XAF"},
    {"code": "XCD", "text": "East Caribbean Dollar – XCD"},
    {"code": "XDR", "text": "Special Drawing Rights – XDR"},
    {"code": "XOF", "text": "CFA Franc BCEAO – XOF"},
    {"code": "XPF", "text": "CFP Franc – XPF"},
    {"code": "YER", "text": "Yemeni Rial – YER"},
    {"code": "ZAR", "text": "South African Rand – ZAR"},
    {"code": "ZMW", "text": "Zambian Kwacha – ZMW"},
    {"code": "ZWL", "text": "Zimbabwean Dollar – ZWL"}
]