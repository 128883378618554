import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject, debounceTime } from 'rxjs';
import { MaintenanceTab } from 'src/app/enums/maintenance-tabs.enum';
import { FilterParams, ServerRequest } from 'src/app/interfaces/server-request';
import { SortByOptions } from 'src/app/models/customer.model';
import { User } from 'src/app/models/user.model';
import { WorkOrderModel, MaintenanceStats, WorkOrderStatus } from 'src/app/models/work-order.model';
import { MaintenanceService } from 'src/app/services/maintenance.service';
import { UserService } from 'src/app/services/user.service';
import { WorkOrdersService } from 'src/app/services/work-orders.service';

@Component({
  selector: 'app-maintenance-list',
  templateUrl: './maintenance-list.component.html',
  styleUrls: ['./maintenance-list.component.scss']
})
export class MaintenanceListComponent implements OnInit {
    currentTab: MaintenanceTab = MaintenanceTab.Unassigned;
    tabs = MaintenanceTab;
    stats: MaintenanceStats = new MaintenanceStats();
    searchText: string = '';
    dateFrom: string;
    dateTo: string;
    filterText: string = '';
    mobileUsers: User[] = [];
    tempAssignedUserId: number = 0;
    assignedUserId: number = 0;
    searchDebounceTimer: any = null;
    isDropdownOpened: boolean = false;
    selectedMaintenanceAreaId:number=0;
    selectedMaintenanceId :number= 0
    showSortBy: boolean = false;
    mapSortBy: Record<number, any> = {
        [SortByOptions.NEWEST]: { label: 'COMMON.SORT_BY.NEWEST', arrow: 'no' },
        [SortByOptions.OLDEST]: { label: 'COMMON.SORT_BY.OLDEST', arrow: 'no' },
    };
    sortByLabelValue: any = this.mapSortBy[SortByOptions.NEWEST];
    sortByOptions = SortByOptions;
    sortByValue: SortByOptions = SortByOptions.NEWEST;
    searchTextUpdate = new Subject<string>();
    currentPage: number = 1;
    pageSize: number = 10;
    count: number = 0;

    maintenanceList: WorkOrderModel[] = [];

    constructor(
        private toastr: ToastrService,
        private router: Router,
        private userService: UserService,

        private maintenanceService:MaintenanceService
    ) { }

    ngOnInit(): void {
        this.reloadTable();
        this.getStats();
        this.searchTextUpdate.pipe(debounceTime(500)).subscribe((value) => {
          if (this.searchText == '') this.search();
        });
    }

    changeTab(tab: number) {
        this.currentTab = tab;
        this.searchText = '';
        this.maintenanceList=[];
        this.resetFilter(1);
        this.getStats();
        this.reloadTable();
    }
    resetFilter(e:any){
      this.currentPage=1;
      this.searchText=""
      this.dateFrom="";
      this.dateTo="";
    }
    datesValid() {
        // If the user clear both dates then reload the table
        if (this.dateFrom === '' && this.dateTo === '') {
            this.reloadTable();
            return;
        }
    
        if (this.dateFrom && this.dateTo) {
            const valid: boolean = new Date(this.dateFrom).getTime() < new Date(`${this.dateTo.toString()}T23:59:59`).getTime();
            return valid;
        }
        return false;
    }

    search() {
    this.reloadTable(1);
    }

    sortBy(option: SortByOptions) {
        this.sortByValue = option;
        this.sortByLabelValue = this.mapSortBy[option];
        this.reloadTable();
    }

    download() {
      let obj = this.getFilterObject();
      this.maintenanceService.download(obj).subscribe({
        next: (response) => {
          const data = response.body;
          if (data?.status === 'OK' || data?.status === '200') {
            window.location.href = data.data;
          } else if (data?.status === 'Pending' || data?.status === '600') {
            this.toastr.info(data?.message || '');
          } else this.toastr.error(data?.message || 'Request failed');
        },
        error: (error) => console.log(error),
      });
    }

    reloadTable(page: any = null) {
        if (page) this.currentPage = page;
        let obj: ServerRequest = {
          pageInfo: {
            page: this.currentPage,
            pageSize: this.pageSize,
          },
          filterParams: this.getFilterObject()
        };
        this.getAll(obj);
    }

    getFilterObject(): FilterParams {
        let obj = {
          sortBy: this.sortByValue,
          search: this.searchText,
          dateFrom: this.dateFrom ? this.dateFrom : null,
          dateTo: this.dateTo ? `${this.dateTo}T23:59:59` : null,
          status: this.currentTab,
        };
        return obj;
      }

    private getAll(obj: ServerRequest) {
        console.log(obj);
        this.maintenanceService.getAll(obj).subscribe({
          next: (response) => {
            const responseData = response.body;
            if (responseData?.status === 200 || responseData?.status === 'OK') {
                this.maintenanceList = responseData.data.data;
              this.count = responseData.data.count;
              if (responseData.message != '') {
                this.toastr.warning(responseData.message);
              }
              this.setCurrentTabStatsCount()
            } else if (response?.status == 204) {
              this.toastr.warning('No content');
            } else {
              this.toastr.error(responseData?.message);
            }
          },
          error: (_) => this.toastr.error('Error occured'),
        });
    }

    private getStats() {
        this.maintenanceService.getStats().subscribe({
          next: (response) => {
            const data = response.body;
            if (data?.status === 200 || data?.status === 'OK') {
              this.stats = new MaintenanceStats(data.data);
            } else {
              this.toastr.error(data?.message);
            }
          },
          error: (_) => {
            this.toastr.error('Error occured');
          },
        });
    }

  checkAllCheckBox(event: any) {}

  setFilterStorage() {}

  selectMaintenance(id: number) {
    this.router.navigate([`work-orders/work-order-details/${id}`]);
    this.setFilterStorage();
  }

  pageChange(value: any) {
    this.currentPage = value;
    this.reloadTable();
  }

  assign() {}
  onModalAssignFade() {
    this.filterText =  '';
}
selectMaintenanceForAssign(areaId:any,id:number){
  this.selectedMaintenanceAreaId=areaId;
  this.selectedMaintenanceId=id;
  this.getMobileUsers();
}
selectFieldAgent(id: number) {
  this.isDropdownOpened = false;
  this.assignedUserId = id;
  this.tempAssignedUserId = id;
  var mobUser = this.mobileUsers.find((x) => x.id == id);
  this.filterText = mobUser!.fullName;
}
filterMobileUsers() {

  if (this.searchDebounceTimer) {
      clearTimeout(this.searchDebounceTimer);
  }

  this.searchDebounceTimer = setTimeout(() => {
      this.isDropdownOpened = true;
      this.tempAssignedUserId = 0;
      this.getMobileUsers();
      this.searchDebounceTimer = null;
  }, 500);

}
getMobileUsers() {
  this.userService
    .getMobileUsers(this.selectedMaintenanceAreaId, this.filterText)
    .subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.mobileUsers = responseData?.data ? responseData.data.map((el: any) => new User(el)) : [];
          if (this.mobileUsers.length == 0) {
            this.isDropdownOpened = false;
          } else {
            this.isDropdownOpened = true;
          }
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
}
assignTask(): void {
  if(this.tempAssignedUserId != 0) {
    this.maintenanceService.assignMaintenance(this.selectedMaintenanceId, this.assignedUserId).subscribe({
      next: response => {
        const responseData = response.body;
        if(responseData?.status === 200 || responseData?.status === "OK"){
          this.toastr.success("Successfully assigned user");
          window.location.reload();
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: _ => {
          this.toastr.error("Error occured");
        }
    });
  }
  else {
    this.toastr.warning('Please select valid user.');
  }
}

    setCurrentTabStatsCount () {
        switch (this.currentTab) {
            case MaintenanceTab.Unassigned:
                this.stats.unassigned = this.count;
                break;
            case MaintenanceTab.Assigned:
                this.stats.assigned = this.count;
                break;
            case MaintenanceTab.Completed:
                this.stats.completed = this.count;
                break;
            case MaintenanceTab.Canceled:
                this.stats.canceled = this.count;
                break;
            case MaintenanceTab.Incomplete:
                this.stats.incomplete = this.count;
        }
    }
}
