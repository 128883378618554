<div class="content-card" [ngClass]="{ 'popup-content-card': isPopUp }">
  <div class="collection-wrapper">
    <div class="card">
      <div class="row">
        <div class="col-md-6 title">
          {{ isEditMode ? ('COMMON.EDIT' | translate) : ('COMMON.NEW' | translate) }}
          {{ "ROLE_MANAGEMENT.ROLE" | translate | lowercase }}
        </div>
        <div class="col-md-6 close" (click)="closeModal()">{{ "COMMON.CLOSE" | translate }}</div>
      </div>
      <div class="form-wrapper">
        <form
          ngNativeValidate
          class="form-wrapper"
          id="buildingForm"
          (submit)="submit()"
        >
          <div class="form-item form-floating mb-3">
            <input
              name="owner"
              [(ngModel)]="role.name"
              type="text"
              class="form-control"
              id="floatingInput"
              placeholder=" "
              required
            />
            <label for="floatingInput">{{ "ROLE_MANAGEMENT.ROLE" | translate }}</label>
          </div>
          <div class="form-item form-floating mb-3">
            <select
              name="systemComponent"
              required
              class="form-select"
              [ngClass]="{
                'valid selected-option': role.systemComponent != -1
              }"
              (change)="selectOption($event)"
            >
              <option value="" disabled [selected]="role.systemComponent == -1">
                {{ "ROLE_MANAGEMENT.SYSTEM_COMPONENT" | translate }}
              </option>
              <option value="0" [selected]="role.systemComponent == 0">
                {{ "ROLE_MANAGEMENT.ADMIN" | translate }}
              </option>
              <option value="1" [selected]="role.systemComponent == 1">
                {{ "ROLE_MANAGEMENT.MOBILE" | translate }}
              </option>
            </select>
          </div>
        </form>
        <br />
        <button type="submit" class="btn btn-primary submit" (click)="submit()">
          {{ "COMMON.SAVE" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
