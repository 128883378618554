import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Customer } from 'src/app/models/customer.model';
import { SurveySave } from 'src/app/models/survey.model';
import { User } from 'src/app/models/user.model';
import { CustomerService } from 'src/app/services/customer.service';
import { SurveyService } from 'src/app/services/survey.service';
import { TranslationService } from 'src/app/services/translation.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-add-survey',
  templateUrl: './add-survey.component.html',
  styleUrls: ['./add-survey.component.scss']
})
export class AddSurveyComponent implements OnInit {
  @ViewChild('dropdownContainer') dropdownContainer: ElementRef;

  public searchCustomers: string = '';
  public customerLoadCount: number = 10;

  @Input() isPopUp: boolean = false
  @Output() closePopUpChange = new EventEmitter()

  mobileUsersForSelect : any [];
  customersForSelectAll: Customer [];
  customersForSelect: Customer [];
  survey: SurveySave = new SurveySave();
  contractNumber: string = this.translationService.getByKeyFromStorage('Contract number');

  filterText: string = "";
  isDropdownOpened: boolean = false;
  filteredMobileUsers: User[] = [];
  tempAssignedUserId: number = 0;
  
  constructor(
    private toastr: ToastrService, private userService : UserService,
    private customerService : CustomerService, private surveyService : SurveyService,
    private translationService: TranslationService
  ) {
  }

  ngOnInit(): void {
    this.loadCustomersForOptions();
  }

  selectOption(e: any){
    this.survey.assignedUserId = +e.target.value;
  }

  loadCustomersForOptions(){

    let obj = {
      pageInfo:{
        page: 1,
        pageSize: this.customerLoadCount
      },
      filterParams: {
        search: this.searchCustomers
      }
    };

    this.customerService.getCustomersForSurveyAssignOptions(obj).subscribe(response => {
      this.customersForSelect = response.body.data;
      this.customersForSelectAll = response.body.data;
      console.log(response.body.data)
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  loadAgentsForOptions(){
    this.userService.getMobileUsersForSelect(this.filterText).subscribe(
      data => {
        this.mobileUsersForSelect = data.data;
        if(this.mobileUsersForSelect.length == 0) {
          this.isDropdownOpened = false;
        }
        else {
          this.isDropdownOpened = true;
        }
      },
      error => {
        this.toastr.error('An error ocurred.');
      });
  }
  
  closeModal(){
    this.closePopUpChange.emit()
  }

  submit(){
    if(this.mobileUsersForSelect.filter(x => x.fullName == this.filterText.trim()).length > 0) {
      this.survey.assignedUserId = this.mobileUsersForSelect.filter(x => x.fullName == this.filterText.trim())[0].id;
      this.tempAssignedUserId = this.survey.assignedUserId;
    }
    else{
      this.tempAssignedUserId = 0
      this.toastr.warning('Please select valid user.');
      return;
    }
    if(this.survey.customers.length == 0)
    {
      this.toastr.error("Please, select customers for survey.");
      return;
    }
    if(this.survey.assignedUserId == 0)
    {
      this.toastr.error("Please, select assign agent for survey.");
      return;
    }

    this.surveyService.save(this.survey).subscribe(
      response => {
        const responseData = response.body;
        if(responseData?.status === 200 || responseData?.status === "OK"){
          this.toastr.success("Successfully added survey!");
          this.survey = new SurveySave();
          this.closeModal();
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error => {
          this.toastr.error("Error occured");
      }
    );
  }
  isChecked(val:number){
    if(this.survey.customers.includes(val))
      return true;
    else
      return false;

  }

  onChangeBu(val: number){
    if(this.survey.customers.includes(val))
      this.survey.customers = this.survey.customers.filter(item => item != val);
    else
      this.survey.customers.push(val)
  }

  search(){
    this.loadCustomersForOptions();
  }

  selectFieldAgent(id: number) {
    this.isDropdownOpened = false;
    this.survey.assignedUserId = id;
    this.tempAssignedUserId = id;
    var mobUser = this.mobileUsersForSelect.find(x => x.id == id);
    this.filterText = mobUser!.fullName;
  }

  filterMobileUsers() {
    if(this.filterText.length < 3) {
      return;
    }
    this.isDropdownOpened = true;
    this.tempAssignedUserId = 0;
    this.loadAgentsForOptions();
  }

  @HostListener('document:click', ['$event'])
  handleDocumentClick(event: MouseEvent) {
    const clickedInside = this.isDropdownOpened && this.dropdownContainer?.nativeElement?.contains(event.target);
    if (!clickedInside) {
      this.isDropdownOpened = false;
    }
  }
}
