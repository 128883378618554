<div class="container-fluid regular-tab-content">
  <div class="green-table">
    <div
      class="table-action-wrapper d-flex align-items-center justify-content-between"
    >
      <div class="search-input">
        <img
          src="../../assets/icons/search.svg"
          alt="Search icon"
          (click)="search()"
        />
        <input
          class="smaller-font"
          type="text"
          placeholder="Search"
          [(ngModel)]="searchText"
          (keydown.enter)="search()"
          (ngModelChange)="this.searchTextUpdate.next($event)"
        />
      </div>
      <div class="actions-box ml-auto d-flex">
        <div>
          <div class="date-filters d-flex">
            <div class="datetype-picker">
              <div
                class="dropdown date-type-dropdown smaller-font"
                (click)="showSelectDateType = !showSelectDateType"
                click-stop-propagation
              >
                <span class="smaller-font"
                  >{{ "COMMON.DATE_TYPE.TITLE" | translate }}:</span
                >
                <span class="smaller-font">{{ selectedDateTypeDisplay }}</span>
                <i class="arrow-custom --small down"></i>
              </div>
              <div class="dropdown-opened" *ngIf="showSelectDateType">
                <div
                  (click)="
                    selectDateType(dateType);
                    datesValid() == true && (dateTo || dateFrom) && search()
                  "
                  *ngFor="let dateType of dateTypes"
                >
                  <div>{{ dateType.value }}</div>
                </div>
              </div>
            </div>
            <div class="datepicker-box space-right smaller-font">
              <label class="smaller-font" for="dateFrom">
                {{ "COMMON.FROM" | translate }}:
              </label>
              <input
                onclick="this.showPicker()"
                type="date"
                id="dateFrom"
                name="dateFrom"
                [ngClass]="
                  !datesValid() ? 'datepicker-input-error' : 'datepicker-input'
                "
                [(ngModel)]="dateFrom"
                (change)="datesValid() == true && search()"
              />
              <i class="arrow-custom --small down"></i>
            </div>

            <div class="datepicker-box space-right smaller-font">
              <label for="dateTo" class="right-label smaller-font">
                {{ "COMMON.TO" | translate }}:
              </label>
              <input
                onclick="this.showPicker()"
                type="date"
                id="dateTo"
                name="dateTo"
                [ngClass]="
                  !datesValid() ? 'datepicker-input-error' : 'datepicker-input'
                "
                [(ngModel)]="dateTo"
                (change)="datesValid() == true && search()"
              />
              <i class="arrow-custom --small down"></i>
            </div>
          </div>
        </div>

        <div
          class="sort-by-root"
          (click)="showSortBy = !showSortBy"
          click-stop-propagation
        >
          <div class="dropdown">
            <span class="smaller-font"
              >{{ "COMMON.SORT_BY.TITLE" | translate }}:</span
            >
            <span class="smaller-font">{{ sortByName }}</span>
            <i class="arrow-custom --small down"></i>
          </div>
          <div class="dropdown-opened" *ngIf="showSortBy">
            <div (click)="sortBy(sortByOptions.NEWEST)">
              {{ "COMMON.SORT_BY.NEWEST" | translate }}
            </div>
            <div (click)="sortBy(sortByOptions.SLRN_ASC)">
              {{ "COMMON.SORT_BY.SLRN_ASC" | translate }}
            </div>
            <div (click)="sortBy(sortByOptions.SLRN_DESC)">
              {{ "COMMON.SORT_BY.SLRN_DESC" | translate }}
            </div>
          </div>
        </div>

        <button class="button primary--white four-radius" (click)="download()">
          <span class="smaller-font">{{ "COMMON.DOWNLOAD" | translate }}</span>
        </button>
      </div>
    </div>
    <div class="filter">
      <div class="filter-list smaller-font">
        <span class="label">{{ "COMMON.FILTER" | translate }}</span>
        <app-multi-select-dropdown
          [borderRadius]="'0.4rem'"
          class="multiselect"
          [clicked]="clicked"
          [items]="regions"
          [label]="buName + 's'"
          [showMore]="false"
          [requireApply]="true"
          (shareIndividualCheckedList)="selectRegion($event)"
        >
        </app-multi-select-dropdown>
        <app-multi-select-dropdown
          class="multiselect"
          [clicked]="clicked"
          [borderRadius]="'0.4rem'"
          [items]="areas"
          [label]="utName + 's'"
          [showMore]="false"
          [requireApply]="true"
          (shareIndividualCheckedList)="selectArea($event)"
        >
        </app-multi-select-dropdown>

        <select
          name="st-select"
          (change)="selectUserType($event)"
          class="form-select"
        >
          <option value="" [selected]="!userType" disabled>
            {{ "COMMON.USER.TYPE.TITLE" | translate }}
          </option>
          <option
            [selected]="userType == o.value"
            *ngFor="let o of userTypeOptions"
            value="{{ o.value }}"
          >
            {{ o.name }}
          </option>
        </select>

        <app-multi-select-dropdown
          class="multiselect"
          [searchFilterActive]="true"
          [borderRadius]="'0.4rem'"
          (filterValue)="applySearchFilter($event)"
          [clicked]="clicked"
          [items]="filteredUsers"
          [label]="'Users'"
          [showMore]="false"
          [requireApply]="true"
          (shareIndividualCheckedList)="selectUsers($event)"
        >
        </app-multi-select-dropdown>
        <select
          name="tag-select"
          (change)="selectTag($event)"
          class="form-select"
        >
          <option value="" [selected]="!tag" disabled>
            {{ "COMMON.TAG.TITLE" | translate }}
          </option>
          <option
            [selected]="tag == t.value"
            *ngFor="let t of tagOptions"
            value="{{ t.value }}"
          >
            {{ t.name }}
          </option>
        </select>
      </div>
      <div *ngIf="selectedFilters.length > 0">
        <app-filter-checklist
              [filters]="selectedFilters"
              (resetFilterEmitter)="resetFilter($event)"
              (removeFilterEmitter)="removeFilter($event)"
              [useApply]="true"
              (applyFilterEmitter)="applyFilter($event)"
        >
        </app-filter-checklist>
      </div>
      <div *ngIf="buildings">
        <table class="table border-bottom">
          <thead class="table-light">
            <tr>
              <th class="checkbox">
                <input
                  type="checkbox"
                  class="form-check-input"
                  [ngModel]="allCheckboxesChecked"
                  (click)="checkAllCheckboxes()"
                />
              </th>
              <th>{{ "COMMON.SLRN" | translate }}</th>
              <th>{{ "COMMON.ADDRESS" | translate }}</th>
              <th>{{ "BUILDINGS.CONNECTED_ACCS" | translate }}</th>
              <th>{{ "COMMON.BU.SINGLE" | translate }}</th>
              <th>{{ "COMMON.UT.SINGLE" | translate }}</th>
              <th>{{ "COMMON.MBC_DETAILS.FEEDER_NAME" | translate }}</th>
              <th>{{ "COMMON.DT_NAME" | translate }}</th>
              <th>{{ "COMMON.VALIDATION_STATUS" | translate }}</th>
              <th>{{ "COMMON.TAG.TITLE" | translate }}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let building of buildings; let i = index"
              (click)="selectDataQuality(building.id)"
            >
              <td>
                <input
                  type="checkbox"
                  class="form-check-input"
                  [ngModel]="building.isChecked"
                  (click)="checkBuilding(building.id)"
                />
              </td>
              <td (click)="setFilterStorage()">
                {{ building?.slrn | hasValue }}
              </td>
              <td (click)="setFilterStorage()">
                {{ building.address | hasValue }}
              </td>
              <td (click)="setFilterStorage()">
                {{ building.customerCount | number | hasValue }}
              </td>
              <td (click)="setFilterStorage()">
                {{ building.region | hasValue }}
              </td>
              <td (click)="setFilterStorage()">
                {{ building.area | hasValue }}
              </td>
              <td (click)="setFilterStorage()">
                {{ building.feederName | hasValue }}
              </td>
              <td (click)="setFilterStorage()">
                {{ building.dtName | hasValue }}
              </td>
              <td (click)="setFilterStorage()">
                <div
                  *ngIf="building.status"
                  class="building-status"
                  [ngClass]="{
                    'complete-building': building.status == 'Complete',
                    'pending-building': building.status == 'Pending',
                    'assigned-building':
                      building.status == 'Assigned' ||
                      building.status == 'Retagged',
                    'dark-red-status v-2': building.status == 'Rejected'
                  }"
                >
                  {{ building.status }}
                </div>
                <div *ngIf="!building.status" class="building-status">
                  --------
                </div>
              </td>
              <td>
                <div
                  *ngIf="!building.issueType"
                  class="green-status v-2"
                  (click)="setFilterStorage()"
                >
                  {{ "COMMON.BUILDING.TYPE.TAGGED" | translate }}
                </div>
                <div
                  *ngIf="building.issueType"
                  class="red-status v-2"
                  (click)="setFilterStorage()"
                >
                  {{ "COMMON.BUILDING.TYPE.INACCESSIBLE" | translate }}
                </div>
              </td>
              <td class="more-icon" (click)="setFilterStorage()">
                <img
                  src="../../assets/icons/more-icon.svg"
                  alt="More icon"
                  class="more-icon"
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div>
          <app-paging
            *ngIf="count > 0"
            [pageSize]="pageSize"
            [count]="count"
            [currentPage]="currentPage"
            [useDirect]="true"
            (pageChange)="pageChange($event)"
          ></app-paging>
        </div>
      </div>
    </div>
  </div>
</div>
