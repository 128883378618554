<div class="page-header d-flex justify-content-end">
    <button class="btn btn-outline-danger" [routerLink]="'/poles'">{{'COMMON.CANCEL' | translate}}</button>
    <button class="btn btn-primary" form="buildingForm" style="width: auto;">{{'COMMON.SAVE' | translate}}</button>
  </div>
  <div class="container-fluid building-information add-new-building">
    <h1 class="page-title">{{'POLES.ADD.POLE_INFO' | translate}}</h1>

    <div class="card ">
      <form ngNativeValidate class="form-wrapper" id="buildingForm" (submit)="handleFormSubmit()" >
        <div class="form-item form-floating mb-3">
            <input name="owner" [(ngModel)]="pole.poleNumber" type="text" class="form-control" id="floatingInput" placeholder=" " required>
            <label for="floatingInput">{{'COMMON.TABLE_HEADERS.LT_POLE_NUM' | translate}}</label>
        </div>
        <div class="form-item form-floating mb-3">
            <input name="closest-landmark" [(ngModel)]="pole.upriserNumber" type="text" class="form-control" id="floatingInput2" placeholder=" " required>
            <label for="floatingInput2">{{'COMMON.TABLE_HEADERS.UPRISER_NUM' | translate}}</label>
        </div>

        <!-- Type of pole -->
        <div class="form-item form-floating mb-3">
            <select name="user" required class="form-select" [ngClass]="{'selected-option valid': pole.poleType !== ''}" (change)="selectPoleType($event)">
              <option value="" disabled [selected]="pole.poleType === ''">{{'POLES.ADD.TYPE_OF_POLE' | translate}}</option>
              <option *ngFor="let option of poleTypesOptions" value="{{option.value}}" [selected]="pole.poleType === option.value">{{ 'COMMON.POLE.TYPE.' + option.value | uppercase | translate }}</option>
            </select>
        </div>


        <div class="form-item form-floating mb-3">
          <input name="address" [(ngModel)]="pole.address" type="text" class="form-control" id="floatingInput3" placeholder=" " required>
          <label for="floatingInput3">{{'POLES.ADDRESS' | translate}}</label>
        </div>
        
        <div class="form-item form-floating mb-3">
          <select name="user" required class="form-select" [ngClass]="{'valid selected-option': pole.assignedUserId !== 0}" (change)="selectOption($event)">
            <option value="" disabled [selected]="pole.assignedUserId === 0">{{'COMMON.FIELD_AGENT' | translate}}</option>
            <option *ngFor="let u of mobileUsers" value="{{u.id}}" [selected]="pole.assignedUserId === u.id">{{u.firstName}} {{u.lastName}}</option>

           <label for="floatingInput">{{'COMMON.LAST_NAME' | translate}}</label>
          </select>
        </div>
      </form>
    </div>

  </div>
