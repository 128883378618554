
export class ValidationRule {
  startsWith: string | null;
  minLength: number | null;
  maxLength: number | null;
  exactLength: number | null;
  isAlfaNumeric: boolean | null;
  propertyType: string;
  numberofLetters:number | null;
}

