
<div class="modal-footer updateStatus">
  <div>
    <h3>{{'AUDIT_REVIEW_CUSTOMER.AUDIT' | translate}}</h3>
  </div>

  <button
    type="button"
    class="btn btn-primary approve"
    (click)="submitAudit()"
  >
    {{'COMMON.YES' | translate}}
  </button>
  <button type="button" (click)="cancel($event)" class="btn btn-danger">
    {{'COMMON.NO' | translate}}
  </button>
</div>
