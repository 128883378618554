import { Injectable } from '@angular/core';
import { PermissionsEnum } from '../models/role.model';
import { DashboardConfiguration } from '../models/utility.model';
import { JwtHelperService } from '@auth0/angular-jwt';
import { RoleService } from './role.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  // Permissions related to the user roles
  hasUtility: boolean = false;
  isCustomerViewAllowed: boolean = false;
  isCustomerCreateEditAllowed: boolean = false;
  isCustomerAssignAllowed: boolean = false;
  isCustomerRetagAllowed: boolean = false;
  isCustomerApproveRejectAllowed: boolean = false;
  isCustomerAuditReviewAllowed: boolean = false;
  isCustomerRecallAssignedAllowed: boolean = false;
  isCustomerRecallValidatedAllowed: boolean = false;
  isCustomerUnvisitedAllowed: boolean = false;
  isCustomerNewEntryBreakdownAllowed: boolean = false;
  isTransformersCreateEditAllowed: boolean = false;
  isTransformersAssignAllowed: boolean = false;
  isTransformersRetagAllowed: boolean = false;
  isTransformersApproveRejectAllowed: boolean = false;
  isTransformersAuditReviewAllowed: boolean = false;
  isTransformersRecallAssignedAllowed: boolean = false;
  isTransformersRecallValidatedAllowed: boolean = false;
  isPoleCreateEditAllowed: boolean = false;
  isPoleDeleteAllowed: boolean = false;
  isPoleAssignAllowed: boolean = false;
  isPoleRetagAllowed: boolean = false;
  isPoleApproveRejectAllowed: boolean = false;
  isPoleAuditReviewAllowed: boolean = false;
  isPoleRecallAssignedAllowed: boolean = false;
  isPoleRecallValidatedAllowed: boolean = false;
  isBuildingDeleteAllowed: boolean = false;
  isSurveyCreateEditAllowed: boolean = false;
  isMetersCreateEditAllowed: boolean = false;
  isMetersDeleteAllowed: boolean = false;
  isMetersApproveRejectAllowed: boolean = false;
  isMetersRetagAllowed: boolean = false;
  isSurveyDeleteAllowed: boolean = false;
  isSurveyApproveRejectAllowed: boolean = false;
  isInstallationCreateEditAllowed: boolean = false;
  isInstallationDeleteAllowed: boolean = false;
  isInstallationApproveRejectAllowed: boolean = false;
  isReportMisalignedAllowed: boolean = false;
  isReportDataQualityRejectionAllowed: boolean = false;
  isReportRegularizationAllowed: boolean = false;
  isUserManagementCreateEditAllowed: boolean = false;
  isUserManagementDeleteAllowed: boolean = false;
  isUserManagementBulkAssignAllowed: boolean = false;
  isRoleManagementCreateEditAllowed: boolean = false;
  isRoleManagementDeleteAllowed: boolean = false;
  isF33CreateEditAllowed: boolean = false;
  isTsDeleteAllowed: boolean = false;
  isF33DeleteAllowed: boolean = false;
  isSsCreateEditAllowed: boolean = false;
  isSsDeleteAllowed: boolean = false;
  isF11CreateEditAllowed: boolean = false;
  isF11DeleteAllowed: boolean = false;
  isRegionCreateEditAllowed: boolean = false;
  isRegionDeleteAllowed: boolean = false;
  isMeterTypeCreateEditAllowed: boolean = false;
  isMeterTypeDeleteAllowed: boolean = false;
  isTariffCreateEditAllowed: boolean = false;
  isTariffDeleteAllowed: boolean = false;
  isF11ViewAllowed: boolean = false;
  isAssignmentAllowed: boolean = false;
  isAdoraWorkOrderAllowed: boolean = false;
  isF33ViewAllowed: boolean = false;
  isTransformersViewAllowed: boolean = false;
  isBuildingViewAllowed: boolean = false;
  isMetersViewAllowed: boolean = false;
  isPoleViewAllowed: boolean = false;
  isSurveyViewAllowed: boolean = false;
  isInstallationViewAllowed: boolean = false;
  isTsViewAllowed: boolean = false;
  isSsViewAllowed: boolean = false;
  isTransformersViewCrudAllowed: boolean = false;
  isAreaViewAllowed: boolean = false;
  isRegionViewAllowed: boolean = false;
  isTariffViewAllowed: boolean = false;
  isUserManagementViewAllowed: boolean = false;
  isRoleManagementViewAllowed: boolean = false;
  isMeterTypeViewAllowed: boolean = false;
  isAssetRatingViewAllowed: boolean = false;
  isMeterPriceViewAllowed: boolean = false;
  isAssetStatusViewAllowed: boolean = false;
  isCustomerCrudViewAllowed: boolean = false;
  isDtMeterTypeViewAllowed: boolean = false;
  isAuditLogViewAllowed: boolean = false;
  isAuditLogDownloadAllowed: boolean = false;
  isBuildingCreateEditAllowed: boolean = false;
  isBuildingRecallAssignedAllowed: boolean = false;
  isBuildingRecallValidatedAllowed: boolean = false;
  isBuildingApproveRejectAllowed: boolean = false;
  isBuildingAuditReviewAllowed: boolean = false;
  isBuildingAssignAllowed: boolean = false;
  isBuildingRetagAllowed: boolean = false;
  isValidationStatusAllowed: boolean = false;
  isAuditStatusAllowed: boolean = false;
  isSiteVerificationAllowed: boolean = false;
  isAreaCreateEditAllowed: boolean = false;
  isAreaDeleteAllowed: boolean = false;
  isAssetRatingCreateEditAllowed: boolean = false;
  isMeterPriceCreateEditAllowed: boolean = false;
  isTsCreateEditAllowed: boolean = false;
  isAssetRatingDeleteAllowed: boolean = false;
  isMeterPriceDeleteAllowed: boolean = false;
  isCreateOrganizationAllowed: boolean = false;

  isAssetStatusCreateEditAllowed: boolean = false;
  isAssetStatusDeleteAllowed: boolean = false;
  isCustomerCreateEditCrudAllowed: boolean = false;
  isCustomerAssignForDecommissionAllowed: boolean = false;
  isCustomerDeleteAllowed: boolean = false;
  isTransformersCreateEditCrudAllowed: boolean = false;
  isTransformersDeleteAllowed: boolean = false;
  isDtMeterTypeCreateEditAllowed: boolean = false;
  isDtMeterTypeDeleteAllowed: boolean = false;
  isWorkOrderMaintenanceAllowed: boolean = false;
  isSltViewAllowed: boolean = false;
  isSltCreateEditAllowed: boolean = false;
  isSltDeleteAllowed: boolean = false;
  isCustomerRegularizationAllowed: boolean = false;

  isMeterStockInventoryViewAllowed: boolean = false;
  isMeterStockInventoryCreateAllowed: boolean = false;

  isMeterStockUtilizationViewAllowed: boolean = false;

  isDecommissionedMeterStockViewAllowed: boolean = false;
  isDecommissionedMeterStockApproveAllowed: boolean = false;
  isDecommissionedMeterStockEditAllowed: boolean = false;

  isMeterReadingAllowed: boolean = false;

  isBillingAccountsCreateEditAllowed: boolean = false;
  isBillingAccountsViewAllowed: boolean = false;
  isBillingAccountsDeleteAllowed: boolean = false;

  // Permissions related to the utility
  hasDashboardConfigBuildings: boolean = false;
  hasDashboardConfigFeeders: boolean = false;
  hasDashboardConfigDTS: boolean = false;
  hasDashboardConfigMeters: boolean = false;
  hasDashboardConfigPoles: boolean = false;
  hasDashboardConfigSurvey: boolean = false;
  hasDashboardConfigInstallation: boolean = false;
  hasDashboardConfigWorkOrder: boolean = false;
  hasDashboardConfigMeterManagement: boolean = false;
  hasDashboardConfigCustomerManagement: boolean = false;
  hasDashboardConfigReadings: boolean = false;

  private jwtService = new JwtHelperService();

  constructor(private toastr: ToastrService, private roleService: RoleService) {
    this.initializePermissions();
  }

  initializePermissions() {
    this.resetPermissions();
    this.hasUtility = Boolean(this.checkIfhasUtility());
    this.initializeRolePermissions();
    this.initializeDashboardConfigPermissions();
  }

  resetPermissions() {
    this.hasUtility = false;
    this.isCustomerViewAllowed = false;
    this.isF11ViewAllowed = false;
    this.isF33ViewAllowed = false;
    this.isTransformersViewAllowed = false;
    this.isBuildingViewAllowed = false;
    this.isMetersViewAllowed = false;
    this.isPoleViewAllowed = false;
    this.isSurveyViewAllowed = false;
    this.isInstallationViewAllowed = false;
    this.isTsViewAllowed = false;
    this.isSsViewAllowed = false;
    this.isTransformersViewCrudAllowed = false;
    this.isAreaViewAllowed = false;
    this.isRegionViewAllowed = false;
    this.isTariffViewAllowed = false;
    this.isUserManagementViewAllowed = false;
    this.isRoleManagementViewAllowed = false;
    this.isMeterTypeViewAllowed = false;
    this.isAssetRatingViewAllowed = false;
    this.isMeterPriceViewAllowed = false;
    this.isAssetStatusViewAllowed = false;
    this.isCustomerCrudViewAllowed = false;
    this.isDtMeterTypeViewAllowed = false;
    this.isAuditLogViewAllowed = false;
    this.isAuditLogDownloadAllowed = false;
    this.isBuildingCreateEditAllowed = false;
    this.isBuildingRecallAssignedAllowed = false;
    this.isBuildingRecallValidatedAllowed = false;
    this.isBuildingApproveRejectAllowed = false;
    this.isBuildingAuditReviewAllowed = false;
    this.isBuildingAssignAllowed = false;
    this.isBuildingRetagAllowed = false;
    this.isValidationStatusAllowed = false;
    this.isAuditStatusAllowed = false;
    this.isSiteVerificationAllowed = false;
    this.isAreaCreateEditAllowed = false;
    this.isAreaDeleteAllowed = false;
    this.isAssetRatingCreateEditAllowed = false;
    this.isMeterPriceCreateEditAllowed = false;
    this.isTsCreateEditAllowed = false;
    this.isAssetStatusCreateEditAllowed = false;
    this.isAssetStatusDeleteAllowed = false;
    this.isCustomerCreateEditCrudAllowed = false;
    this.isCustomerDeleteAllowed = false;
    this.isTransformersCreateEditCrudAllowed = false;
    this.isTransformersDeleteAllowed = false;
    this.isDtMeterTypeCreateEditAllowed = false;
    this.isDtMeterTypeDeleteAllowed = false;
    this.isCustomerAssignAllowed = false;
    this.isCustomerRetagAllowed = false;
    this.isCustomerApproveRejectAllowed = false;
    this.isCustomerAuditReviewAllowed = false;
    this.isCustomerRecallAssignedAllowed = false;
    this.isCustomerRecallValidatedAllowed = false;
    this.isCustomerUnvisitedAllowed = false;
    this.isCustomerNewEntryBreakdownAllowed = false;
    this.isTransformersCreateEditAllowed = false;
    this.isTransformersAssignAllowed = false;
    this.isTransformersRetagAllowed = false;
    this.isTransformersApproveRejectAllowed = false;
    this.isTransformersRecallAssignedAllowed = false;
    this.isTransformersRecallValidatedAllowed = false;
    this.isPoleCreateEditAllowed = false;
    this.isPoleDeleteAllowed = false;
    this.isPoleApproveRejectAllowed = false;
    this.isPoleAuditReviewAllowed = false;
    this.isPoleRecallAssignedAllowed = false;
    this.isPoleRecallValidatedAllowed = false;
    this.isBuildingDeleteAllowed = false;
    this.isSurveyCreateEditAllowed = false;
    this.isMetersCreateEditAllowed = false;
    this.isMetersDeleteAllowed = false;
    this.isMetersApproveRejectAllowed = false;
    this.isMetersRetagAllowed = false;
    this.isSurveyApproveRejectAllowed = false;
    this.isSurveyDeleteAllowed = false;
    this.isInstallationCreateEditAllowed = false;
    this.isInstallationDeleteAllowed = false;
    this.isInstallationApproveRejectAllowed = false;
    this.isReportMisalignedAllowed = false;
    this.isReportDataQualityRejectionAllowed = false;
    this.isReportRegularizationAllowed = false;
    this.isUserManagementDeleteAllowed = false;
    this.isUserManagementBulkAssignAllowed = false;
    this.isRoleManagementCreateEditAllowed = false;
    this.isRoleManagementDeleteAllowed = false;
    this.isTsDeleteAllowed = false;
    this.isF33CreateEditAllowed = false;
    this.isF33DeleteAllowed = false;
    this.isSsCreateEditAllowed = false;
    this.isSsDeleteAllowed = false;
    this.isF11CreateEditAllowed = false;
    this.isF11DeleteAllowed = false;
    this.isRegionCreateEditAllowed = false;
    this.isRegionDeleteAllowed = false;
    this.isMeterTypeCreateEditAllowed = false;
    this.isMeterTypeDeleteAllowed = false;
    this.isTariffCreateEditAllowed = false;
    this.isTariffDeleteAllowed = false;
    this.isTransformersAuditReviewAllowed = false;
    this.isUserManagementCreateEditAllowed = false;
    this.isAssignmentAllowed = false;
    this.isAdoraWorkOrderAllowed = false;
    this.isAssetRatingDeleteAllowed = false;
    this.isMeterPriceDeleteAllowed = false;
    this.isCustomerRegularizationAllowed = false;

    this.isSltViewAllowed = false;
    this.isSltCreateEditAllowed = false;
    this.isSltDeleteAllowed = false;
    this.isCreateOrganizationAllowed = false;

    this.hasDashboardConfigFeeders = false;
    this.hasDashboardConfigBuildings = false;
    this.hasDashboardConfigDTS = false;
    this.hasDashboardConfigMeters = false;
    this.hasDashboardConfigPoles = false;
    this.hasDashboardConfigSurvey = false;
    this.hasDashboardConfigInstallation = false;
    this.hasDashboardConfigWorkOrder = false;
    this.isWorkOrderMaintenanceAllowed = false;
    this.hasDashboardConfigMeterManagement = false;
    this.hasDashboardConfigCustomerManagement = false;
    this.hasDashboardConfigReadings = false;

    this.isCustomerAssignForDecommissionAllowed = false;
    this.isMeterStockInventoryViewAllowed = false;
    this.isMeterStockInventoryCreateAllowed = false;

    this.isMeterStockUtilizationViewAllowed = false;

    this.isDecommissionedMeterStockViewAllowed = false;
    this.isDecommissionedMeterStockApproveAllowed = false;
    this.isDecommissionedMeterStockEditAllowed = false;

    this.isMeterReadingAllowed = false;

    this.isBillingAccountsCreateEditAllowed = false;
    this.isBillingAccountsDeleteAllowed = false;
    this.isBillingAccountsViewAllowed = false;
  }

  checkIfhasUtility() {
    let token = localStorage.getItem('token');
    if (!token) return null;

    let currentUser = this.jwtService.decodeToken(token);
    return currentUser.utilityId != '';
  }

  public getPermissions() {
    this.roleService.getPermissions().subscribe({
      next: (response) => {
        if (response.status === 200) {
          var listaJSON = JSON.stringify(response.body?.data);
          localStorage.setItem('permissions', listaJSON);
          // initializePermissions must be called after seting permissions to local storage
          this.initializePermissions();
        } else this.toastr.error(response.body?.message);
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  initializeRolePermissions() {
    let rolePermissions = localStorage.getItem('permissions');

    if (!rolePermissions) {
      return;
    }

    let permissions: number[] = rolePermissions
      ? JSON.parse(rolePermissions)
      : [];

    for (let index = 0; index < permissions.length; index++) {
      const permission: number = permissions[index];
      // console.log('PermissionsService permission', PermissionsEnum[permission]);

      switch (permission) {
        case PermissionsEnum.Customer_View:
          this.isCustomerViewAllowed = true;
          break;
        case PermissionsEnum.Customer_CreateEdit:
          this.isCustomerCreateEditAllowed = true;
          break;
        case PermissionsEnum.Customer_CreateEditCrud:
          this.isCustomerCreateEditCrudAllowed = true;
          break;
        case PermissionsEnum.Customer_AssignForDecommission:
          this.isCustomerAssignForDecommissionAllowed = true;
          break;
        case PermissionsEnum.Customer_Delete:
          this.isCustomerDeleteAllowed = true;
          break;
        case PermissionsEnum.Customer_Assign:
          this.isCustomerAssignAllowed = true;
          break;
        case PermissionsEnum.Customer_Retag:
          this.isCustomerRetagAllowed = true;
          break;
        case PermissionsEnum.Customer_ApproveReject:
          this.isCustomerApproveRejectAllowed = true;
          break;
        case PermissionsEnum.Customer_AuditReview:
          this.isCustomerAuditReviewAllowed = true;
          break;
        case PermissionsEnum.Customer_RecallAssigned:
          this.isCustomerRecallAssignedAllowed = true;
          break;
        case PermissionsEnum.Customer_RecallValidated:
          this.isCustomerRecallValidatedAllowed = true;
          break;
        case PermissionsEnum.Customer_ViewCrud:
          this.isCustomerCrudViewAllowed = true;
          break;
        case PermissionsEnum.Customer_Unvisited:
          this.isCustomerUnvisitedAllowed = true;
          break;
        case PermissionsEnum.Customer_NewEntryBreakdown:
          this.isCustomerNewEntryBreakdownAllowed = true;
          break;
        case PermissionsEnum.Transformers_CreateEdit:
          this.isTransformersCreateEditAllowed = true;
          break;
        case PermissionsEnum.Transformers_Delete:
          this.isTransformersDeleteAllowed = true;
          break;
        case PermissionsEnum.Transformers_Assign:
          this.isTransformersAssignAllowed = true;
          break;
        case PermissionsEnum.Transformers_Retag:
          this.isTransformersRetagAllowed = true;
          break;
        case PermissionsEnum.Transformers_ApproveReject:
          this.isTransformersApproveRejectAllowed = true;
          break;
        case PermissionsEnum.Transformers_AuditReview:
          this.isTransformersAuditReviewAllowed = true;
          break;
        case PermissionsEnum.Transformers_RecallAssigned:
          this.isTransformersRecallAssignedAllowed = true;
          break;
        case PermissionsEnum.Transformers_RecallValidated:
          this.isTransformersRecallValidatedAllowed = true;
          break;
        case PermissionsEnum.Transformers_ViewCrud:
          this.isTransformersViewCrudAllowed = true;
          break;
        case PermissionsEnum.Transformers_View:
          this.isTransformersViewAllowed = true;
          break;
        case PermissionsEnum.Transformers_CreateEditCrud:
          this.isTransformersCreateEditCrudAllowed = true;
          break;
        case PermissionsEnum.Pole_View:
          this.isPoleViewAllowed = true;
          break;
        case PermissionsEnum.Pole_CreateEdit:
          this.isPoleCreateEditAllowed = true;
          break;
        case PermissionsEnum.Pole_Delete:
          this.isPoleDeleteAllowed = true;
          break;
        case PermissionsEnum.Pole_Assign:
          this.isPoleAssignAllowed = true;
          break;
        case PermissionsEnum.Pole_Retag:
          this.isPoleRetagAllowed = true;
          break;
        case PermissionsEnum.Pole_ApproveReject:
          this.isPoleApproveRejectAllowed = true;
          break;
        case PermissionsEnum.Pole_AuditReview:
          this.isPoleAuditReviewAllowed = true;
          break;
        case PermissionsEnum.Pole_RecallAssigned:
          this.isPoleRecallAssignedAllowed = true;
          break;
        case PermissionsEnum.Pole_RecallValidated:
          this.isPoleRecallValidatedAllowed = true;
          break;
        case PermissionsEnum.Building_View:
          this.isBuildingViewAllowed = true;
          break;
        case PermissionsEnum.Building_CreateEdit:
          this.isBuildingCreateEditAllowed = true;
          break;
        case PermissionsEnum.Building_Delete:
          this.isBuildingDeleteAllowed = true;
          break;
        case PermissionsEnum.Building_Assign:
          this.isBuildingAssignAllowed = true;
          break;
        case PermissionsEnum.Building_Retag:
          this.isBuildingRetagAllowed = true;
          break;
        case PermissionsEnum.Building_ApproveReject:
          this.isBuildingApproveRejectAllowed = true;
          break;
        case PermissionsEnum.Building_AuditReview:
          this.isBuildingAuditReviewAllowed = true;
          break;
        case PermissionsEnum.Building_RecallAssigned:
          this.isBuildingRecallAssignedAllowed = true;
          break;
        case PermissionsEnum.Building_RecallValidated:
          this.isBuildingRecallValidatedAllowed = true;
          break;
        case PermissionsEnum.Survey_CreateEdit:
          this.isSurveyCreateEditAllowed = true;
          break;
        case PermissionsEnum.Meters_View:
          this.isMetersViewAllowed = true;
          break;
        case PermissionsEnum.Meters_CreateEdit:
          this.isMetersCreateEditAllowed = true;
          break;
        case PermissionsEnum.Meters_Delete:
          this.isMetersDeleteAllowed = true;
          break;
        case PermissionsEnum.Meters_ApproveReject:
          this.isMetersApproveRejectAllowed = true;
          break;
        case PermissionsEnum.Meters_Retag:
          this.isMetersRetagAllowed = true;
          break;
        case PermissionsEnum.Survey_View:
          this.isSurveyViewAllowed = true;
          break;
        case PermissionsEnum.Survey_Delete:
          this.isSurveyDeleteAllowed = true;
          break;
        case PermissionsEnum.Survey_ApproveReject:
          this.isSurveyApproveRejectAllowed = true;
          break;
        case PermissionsEnum.Installation_View:
          this.isInstallationViewAllowed = true;
          break;
        case PermissionsEnum.Installation_CreateEdit:
          this.isInstallationCreateEditAllowed = true;
          break;
        case PermissionsEnum.Installation_Delete:
          this.isInstallationDeleteAllowed = true;
          break;
        case PermissionsEnum.Installation_ApproveReject:
          this.isInstallationApproveRejectAllowed = true;
          break;
        case PermissionsEnum.Report_Misaligned:
          this.isReportMisalignedAllowed = true;
          break;
        case PermissionsEnum.Report_DataQualityRejection:
          this.isReportDataQualityRejectionAllowed = true;
          break;
        case PermissionsEnum.Report_Regularization:
          this.isReportRegularizationAllowed = true;
          break;
        case PermissionsEnum.UserManagement_View:
          this.isUserManagementViewAllowed = true;
          break;
        case PermissionsEnum.UserManagement_CreateEdit:
          this.isUserManagementCreateEditAllowed = true;
          break;
        case PermissionsEnum.UserManagement_Delete:
          this.isUserManagementDeleteAllowed = true;
          break;
        case PermissionsEnum.UserManagement_BulkAssign:
          this.isUserManagementBulkAssignAllowed = true;
          break;
        case PermissionsEnum.RoleManagement_View:
          this.isRoleManagementViewAllowed = true;
          break;
        case PermissionsEnum.RoleManagement_CreateEdit:
          this.isRoleManagementCreateEditAllowed = true;
          break;
        case PermissionsEnum.RoleManagement_Delete:
          this.isRoleManagementDeleteAllowed = true;
          break;
        case PermissionsEnum.Ts_View:
          this.isTsViewAllowed = true;
          break;
        case PermissionsEnum.Ts_CreateEdit:
          this.isTsCreateEditAllowed = true;
          break;
        case PermissionsEnum.Ts_Delete:
          this.isTsDeleteAllowed = true;
          break;
        case PermissionsEnum.F33_View:
          this.isF33ViewAllowed = true;
          break;
        case PermissionsEnum.F33_CreateEdit:
          this.isF33CreateEditAllowed = true;
          break;
        case PermissionsEnum.F33_Delete:
          this.isF33DeleteAllowed = true;
          break;
        case PermissionsEnum.Ss_View:
          this.isSsViewAllowed = true;
          break;
        case PermissionsEnum.Ss_CreateEdit:
          this.isSsCreateEditAllowed = true;
          break;
        case PermissionsEnum.Ss_Delete:
          this.isSsDeleteAllowed = true;
          break;
        case PermissionsEnum.F11_View:
          this.isF11ViewAllowed = true;
          break;
        case PermissionsEnum.F11_CreateEdit:
          this.isF11CreateEditAllowed = true;
          break;
        case PermissionsEnum.F11_Delete:
          this.isF11DeleteAllowed = true;
          break;
        case PermissionsEnum.Area_View:
          this.isAreaViewAllowed = true;
          break;
        case PermissionsEnum.Area_CreateEdit:
          this.isAreaCreateEditAllowed = true;
          break;
        case PermissionsEnum.Area_Delete:
          this.isAreaDeleteAllowed = true;
          break;
        case PermissionsEnum.Region_View:
          this.isRegionViewAllowed = true;
          break;
        case PermissionsEnum.Region_CreateEdit:
          this.isRegionCreateEditAllowed = true;
          break;
        case PermissionsEnum.Region_Delete:
          this.isRegionDeleteAllowed = true;
          break;
        case PermissionsEnum.MeterType_View:
          this.isMeterTypeViewAllowed = true;
          break;
        case PermissionsEnum.MeterType_CreateEdit:
          this.isMeterTypeCreateEditAllowed = true;
          break;
        case PermissionsEnum.MeterType_Delete:
          this.isMeterTypeDeleteAllowed = true;
          break;
        case PermissionsEnum.AssetRating_View:
          this.isAssetRatingViewAllowed = true;
          break;
        case PermissionsEnum.AssetRating_CreateEdit:
          this.isAssetRatingCreateEditAllowed = true;
          break;
        case PermissionsEnum.AssetRating_Delete:
          this.isAssetRatingDeleteAllowed = true;
          break;
        case PermissionsEnum.MeterPrice_View:
          this.isMeterPriceViewAllowed = true;
          break;
        case PermissionsEnum.MeterPrice_CreateEdit:
          this.isMeterPriceCreateEditAllowed = true;
          break;
        case PermissionsEnum.MeterPrice_Delete:
          this.isMeterPriceDeleteAllowed = true;
          break;
        case PermissionsEnum.AssetStatus_View:
          this.isAssetStatusViewAllowed = true;
          break;
        case PermissionsEnum.AssetStatus_CreateEdit:
          this.isAssetStatusCreateEditAllowed = true;
          break;
        case PermissionsEnum.AssetStatus_Delete:
          this.isAssetStatusDeleteAllowed = true;
          break;
        case PermissionsEnum.DtMeterType_View:
          this.isDtMeterTypeViewAllowed = true;
          break;
        case PermissionsEnum.DtMeterType_CreateEdit:
          this.isDtMeterTypeCreateEditAllowed = true;
          break;
        case PermissionsEnum.DtMeterType_Delete:
          this.isDtMeterTypeDeleteAllowed = true;
          break;
        case PermissionsEnum.Tariff_View:
          this.isTariffViewAllowed = true;
          break;
        case PermissionsEnum.Tariff_CreateEdit:
          this.isTariffCreateEditAllowed = true;
          break;
        case PermissionsEnum.Tariff_Delete:
          this.isTariffDeleteAllowed = true;
          break;
        case PermissionsEnum.AuditLog_View:
          this.isAuditLogViewAllowed = true;
          break;
        case PermissionsEnum.AuditLog_Download:
          this.isAuditLogDownloadAllowed = true;
          break;
        case PermissionsEnum.Assignment:
          this.isAssignmentAllowed = true;
          break;
        case PermissionsEnum.AdoraWorkOrder:
          this.isAdoraWorkOrderAllowed = true;
          break;
        case PermissionsEnum.AuditStatus:
          this.isAuditStatusAllowed = true;
          break;
        case PermissionsEnum.ValidationStatus:
          this.isValidationStatusAllowed = true;
          break;
        case PermissionsEnum.SiteVerification:
          this.isSiteVerificationAllowed = true;
          break;
        case PermissionsEnum.Maintenance:
          this.isWorkOrderMaintenanceAllowed = true;
          break;
        case PermissionsEnum.Slt_View:
          this.isSltViewAllowed = true;
          break;
        case PermissionsEnum.Slt_CreateEdit:
          this.isSltCreateEditAllowed = true;
          break;
        case PermissionsEnum.Slt_Delete:
          this.isSltDeleteAllowed = true;
          break;
        case PermissionsEnum.Customer_Regularization:
          this.isCustomerRegularizationAllowed = true;
          break;
        case PermissionsEnum.Organization_Create:
          this.isCreateOrganizationAllowed = true;
          break;
        case PermissionsEnum.MeterStockInventory_View:
          this.isMeterStockInventoryViewAllowed = true;
          break;
        case PermissionsEnum.MeterStockInventory_Create:
          this.isMeterStockInventoryCreateAllowed = true;
          break;
        case PermissionsEnum.MeterStockUtilization_View:
          this.isMeterStockUtilizationViewAllowed = true;
          break;
        case PermissionsEnum.DecommissionedMeterStock_View:
          this.isDecommissionedMeterStockViewAllowed = true;
          break;
        case PermissionsEnum.DecommissionedMeterStock_Approve:
          this.isDecommissionedMeterStockApproveAllowed = true;
          break;
        case PermissionsEnum.DecommissionedMeterStock_Edit:
          this.isDecommissionedMeterStockEditAllowed = true;
          break;
        case PermissionsEnum.MeterReading:
          this.isMeterReadingAllowed = true;
          break;
        case PermissionsEnum.BillingAccounts_View:
          this.isBillingAccountsViewAllowed = true;
          break;
        case PermissionsEnum.BillingAccounts_CreateEdit:
          this.isBillingAccountsCreateEditAllowed = true;
          break;
        case PermissionsEnum.BillingAccounts_Delete:
          this.isBillingAccountsDeleteAllowed = true;
          break;
      }
    }
  }

  initializeDashboardConfigPermissions() {
    let configuration = localStorage.getItem('configuration');
    if (!configuration) {
      return;
    }

    let configurations = configuration ? JSON.parse(configuration) : [];
    // console.log('configurations', configurations);

    for (let index = 0; index < configurations.length; index++) {
      const config: number = configurations[index];

      switch (config) {
        case DashboardConfiguration.Buildings:
          this.hasDashboardConfigBuildings = true;
          break;
        case DashboardConfiguration.Meters:
          this.hasDashboardConfigMeters = true;
          break;
        case DashboardConfiguration.Poles:
          this.hasDashboardConfigPoles = true;
          break;
        case DashboardConfiguration.DTs:
          this.hasDashboardConfigDTS = true;
          break;
        case DashboardConfiguration.Feeders:
          this.hasDashboardConfigFeeders = true;
          break;
        case DashboardConfiguration.Survey:
          this.hasDashboardConfigSurvey = true;
          break;
        case DashboardConfiguration.Installation:
          this.hasDashboardConfigInstallation = true;
          break;
        case DashboardConfiguration.WorkOrder:
          this.hasDashboardConfigWorkOrder = true;
          break;
        case DashboardConfiguration.MeterManagement:
          this.hasDashboardConfigMeterManagement = true;
          break;
        case DashboardConfiguration.CustomerManagement:
          this.hasDashboardConfigCustomerManagement = true;
          break;
        case DashboardConfiguration.Readings:
          this.hasDashboardConfigReadings = true;
          break;
      }
    }
  }
}
