import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from 'src/app/config/config';

@Injectable({
    providedIn: 'root'
  })
  export class AssetBpsService {
  
    private pathApi = this.config.setting['pathApi'] + "bps/asset/";
    private headers = new HttpHeaders({'Content-Type': 'application/json'});
    
    constructor(private http: HttpClient, private config: AppConfig) {
  
    }
    
    getAll(data : any){
      return this.http.post<any>(`${this.pathApi}getAll`, data, {headers: this.headers, observe: 'response'});
    }
    download(data : any){
      return this.http.post<any>(`${this.pathApi}download`, data, {headers: this.headers, observe: 'response'});
    }
    
}