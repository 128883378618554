export class MeterStockItem {
  meterNumber: string = '';
  meterPhase: string = '';
  serialNumber: string = '';
  meterMake: string = '';
  meterType: string = '';
  uploadDate?: string = '';
  dispatchDate?: Date;
  region: string = '';
  area: string = '';
  installationDate?: string = '';
  uploadedBy: string = '';
  status: string = '';
  isChecked: boolean = false;
  lifespan: number = 0;
  age: number = 0;
  id: number = 0;
  organization: string = '';
  accountNumber: string = '';
  sealNumber: string = '';
  customerName: string = '';
  customerAddress: string = '';

  constructor(data?: any) {
    this.meterNumber = data?.meterNumber ?? '';
    this.meterPhase = data?.meterPhase ?? '';
    this.serialNumber = data?.serialNumber ?? '';
    this.meterMake = data?.meterMake ?? '';
    this.meterType = data?.meterType ?? '';
    this.uploadDate = data?.uploadDate ?? '';
    this.dispatchDate = data?.dispatchDate ?? null;
    this.region = data?.region ?? '';
    this.area = data?.area ?? '';
    this.installationDate = data?.installationDate ?? '';
    this.uploadedBy = data?.uploadedBy ?? '';
    this.status = data?.status ?? '';
    this.isChecked = data?.isChecked ?? false;
    this.lifespan = data?.lifespan ?? 0;
    this.age = data?.age ?? 0;
    this.id = data?.id ?? 0;
    this.organization = data?.organization ?? '';
    this.accountNumber = data?.accountNumber ?? '';
    this.sealNumber = data?.sealNumber ?? '';
    this.customerName = data?.customerName ?? '';
    this.customerAddress = data?.customerAddress ?? '';
  }
}

export class MeterStockItemStats {
  total: number | undefined;
  singlePhase: number | undefined;
  threePhase: number | undefined;
  inStorage: number | undefined;
  installed: number | undefined;
  averageAge: number | undefined;
  utilization: number | undefined;
  constructor() {
    this.total = undefined;
    this.singlePhase = undefined;
    this.threePhase = undefined;
    this.inStorage = undefined;
    this.installed = undefined;
    this.averageAge = undefined;
    this.utilization = undefined;
  }
}
