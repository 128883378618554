import { Component, OnInit, ViewChild } from '@angular/core';
import { CrudMenuItem } from 'src/app/models/crud.menu.item.model';
import { Subject } from 'rxjs';
import { CrudTsComponent } from '../components/crud/crud-ts/crud-ts.component';
import { RoleService } from '../services/role.service';
import { PermissionsEnum, Role } from '../models/role.model';
import { PermissionMenuComponent } from './permission-menu/permission-menu.component';
import { ToastrService } from 'ngx-toastr';
import { PermissionsService } from '../services/permissions.service';
@Component({
  selector: 'app-role-management',
  templateUrl: './role-management.component.html',
  styleUrls: ['./role-management.component.scss'],
})
export class RoleManagementComponent implements OnInit {
  roles: Role[] = [];
  currentRole: Role = new Role();
  roleForEdit: Role = new Role();
  public PermissionsEnum = PermissionsEnum;
  public detailsShowed: boolean = false;
  public isEditMode: boolean = false;

  public CrudMenuItem = CrudMenuItem;
  crudSelected: CrudMenuItem = CrudMenuItem.TS;
  crudType: string = '';
  searchTextUpdate = new Subject<string>();
  perms = [];
  public searchText: string = '';

  @ViewChild(CrudTsComponent)
  permissionComponent!: PermissionMenuComponent;

  constructor(
    private roleService: RoleService,
    private toastr: ToastrService,
    public permissionsService: PermissionsService
  ) {}

  //infoMessage(){
  //  this.toastr.info("You can't delete this role")
  //}

  ngOnInit(): void {
    this.getRoles();
  }

  ngOnDestroy(): void {
    this.searchTextUpdate.complete();
  }

  setCurrentRole(id: number): void {
    let obj = this.roles.find((x) => x.id == id);
    if (obj != null) {
      this.currentRole = JSON.parse(JSON.stringify(obj));
    }
  }

  getRoles() {
    this.roleService.getAll().subscribe(
      (response) => {
        this.roles = response.body?.data;
        if (this.roles.length > 0)
          this.currentRole = JSON.parse(JSON.stringify(this.roles[0]));
      },
      (error) => {
        this.toastr.error(error.message);
      }
    );
  }

  openCreateModal() {
    this.roleForEdit = new Role();
    this.isEditMode = false;
    this.detailsShowed = !this.detailsShowed;
  }

  closeModal() {
    this.detailsShowed = !this.detailsShowed;
    this.roleForEdit = new Role();
    this.getRoles();
  }

  editRole(roleId: number, isDisabled: boolean) {
    if (!isDisabled) {
      let tempRole = this.roles.find((x) => x.id == roleId);
      if (tempRole != null) this.roleForEdit = tempRole;
      this.isEditMode = true;
      this.detailsShowed = !this.detailsShowed;
    } else this.toastr.info("You can't edit this role");
  }

  saveChanges() {
    this.roleService.save(this.currentRole).subscribe(
      (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.toastr.success(responseData.message);
          this.currentRole = new Role();
          this.getRoles();
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      (error) => {
        this.toastr.error('Error occured');
      }
    );
  }
  deleteRole(roleId: number) {
    this.roleService.delete(roleId).subscribe(
      (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.toastr.success('Successfully deleted role!');
          this.currentRole = new Role();
          this.getRoles();
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      (error) => {
        this.toastr.error('Error occured');
      }
    );
  }
}
