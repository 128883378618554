<div class="title">
  <p>{{ "COMMON.ASSET_RATING.SINGLE" | translate }}</p>
  <button
    type="button"
    class="btn btn-primary"
    data-bs-toggle="modal"
    data-bs-target="#addNewTS"
    (click)="addNew()"
    *ngIf="
      permissionsService.isAssetRatingCreateEditAllowed &&
      utilityId != 0 &&
      bpsAdminLevel != 2
    "
  >
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8 0C7.4477 0 7 0.44772 7 1V7H1C0.44772 7 0 7.4477 0 8C0 8.5523 0.44772 9 1 9H7V15C7 15.5523 7.4477 16 8 16C8.5523 16 9 15.5523 9 15V9H15C15.5523 9 16 8.5523 16 8C16 7.4477 15.5523 7 15 7H9V1C9 0.44772 8.5523 0 8 0Z"
        fill="white"
      />
    </svg>
    {{ "CRUD_SETTINGS.ADD_NEW" | translate }}
  </button>
</div>
<div class="container-fluid border-bottom">
  <table>
    <tr class="header-row">
      <th>{{ "COMMON.ASSET_RATING.KVA_RATING" | translate }}</th>
      <th>{{ "COMMON.ASSET_RATING.ID" | translate }}</th>
      <th></th>
    </tr>
    <tr
      class="content-wrapper"
      *ngFor="let assetRating of assetRatings"
      data-bs-toggle="modal"
      data-bs-target="#addNewTS"
      (click)="
        permissionsService.isTsCreateEditAllowed
          ? selectAssetRating(assetRating)
          : null
      "
    >
      <td>{{ assetRating.kvaRating | hasValue }}</td>
      <td>{{ assetRating.assetRatingId | hasValue }}</td>
      <td class="td-svg">
        <svg
          data-bs-toggle="modal"
          data-bs-target="#deleteAssetRating"
          (click)="selectAssetRating(assetRating)"
          width="22"
          height="22"
          viewBox="0 0 22 22"
          xmlns="http://www.w3.org/2000/svg"
          *ngIf="
            permissionsService.isAssetRatingDeleteAllowed && bpsAdminLevel != 2
          "
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22ZM11 20C15.9706 20 20 15.9706 20 11C20 6.02944 15.9706 2 11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20ZM6 11C6 10.4477 6.44772 10 7 10H15C15.5523 10 16 10.4477 16 11C16 11.5523 15.5523 12 15 12H7C6.44772 12 6 11.5523 6 11Z"
            fill="#DE350B"
          />
        </svg>
      </td>
    </tr>
  </table>
</div>
<div>
  <app-paging
    *ngIf="count > 0"
    [pageSize]="pageSize"
    [count]="count"
    [currentPage]="currentPage"
    (pageChange)="pageChange($event)"
  ></app-paging>
</div>

<div
  class="modal fade"
  id="addNewTS"
  tabindex="-1"
  aria-labelledby="addNewTsTitle"
  aria-hidden="true"
  *ngIf="permissionsService.isAssetRatingCreateEditAllowed"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">{{ "COMMON.ASSET_RATING.NEW" | translate }}</h5>
        <button
          type="button"
          #closeModal
          class="btn-cancel-modal"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          {{ "COMMON.CANCEL" | translate }}
        </button>
      </div>
      <form
        id="addForm"
        class="form-wrapper"
        ngNativeValidate
        (submit)="save()"
      >
        <div class="modal-body">
          <div class="form-item form-floating mb-3">
            <input
              id="kvaRating"
              name="name"
              type="text"
              [(ngModel)]="selectedAssetRating.kvaRating"
              class="form-control"
              required
              placeholder=" "
            />
            <label for="kvaRating">
              {{ "COMMON.ASSET_RATING.SINGLE" | translate }}
            </label>
          </div>
          <div class="form-item form-floating mb-3">
            <input
              id="assetRatingId"
              name="code"
              type="text"
              [(ngModel)]="selectedAssetRating.assetRatingId"
              class="form-control"
            />
            <label for="assetRatingId">{{ "COMMON.ASSET_RATING.ID" | translate }}</label>
          </div>
        </div>
        <div class="modal-footer" *ngIf="bpsAdminLevel != 2">
          <button class="btn btn-primary" form="addForm">{{ "COMMON.SAVE" | translate }}</button>
        </div>
      </form>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="deleteAssetRating"
  tabindex="-1"
  aria-labelledby="deleteAssetRatingTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header delete-modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">
          {{ "CRUD_SETTINGS.ARE_YOU_SURE_DELETE" | translate }}
          {{ "COMMON.ASSET_RATING.SINGLE" | translate }}?
        </h5>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-danger"
          data-bs-dismiss="modal"
          (click)="deleteAssetRating()"
        >
          {{ "COMMON.DELETE" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
