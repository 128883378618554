<div class="page-header d-flex justify-content-end">
    <button class="btn btn-outline-danger" [routerLink]="'/transformers'">{{'COMMON.CANCEL' | translate}}</button>
    <button class="btn btn-primary" form="dtForm" style="width: auto;">{{'COMMON.SAVE' | translate}}</button>
  </div>
  <div class="container-fluid dt-information add-new-dt">
    <h1 class="page-title">{{'TRANSFORMERS.EDIT.INFORMATION' | translate}}</h1>

    <div class="card ">
      <form ngNativeValidate class="form-wrapper" id="dtForm"  (submit)="handleFormSubmit()" >
        <div class="form-item form-floating mb-3">
            <input name="name" [(ngModel)]="dt.name" type="text" class="form-control" id="floatingInput" placeholder=" " required>
            <label for="floatingInput">{{'COMMON.DT_NAME' | translate}}</label>
        </div>
       <div class="form-item form-floating mb-3">
            <input name="number" [(ngModel)]="dt.number" type="text" class="form-control" id="floatingInput2" placeholder=" " required>
            <label for="floatingInput2">{{'COMMON.DT_NUMBER' | translate}}</label>
        </div>
        <div class="form-item form-floating mb-3">
          <input name="closestLandmark" [(ngModel)]="dt.locationLandmark" type="text" class="form-control" id="floatingInput" placeholder=" " required>
          <label for="floatingInput">{{'COMMON.MBC_DETAILS.CLOSEST_LANDMARK' | translate}}</label>
      </div>
      <div class="form-item form-floating mb-3">
        <input name="address" [(ngModel)]="dt.locationAddress" type="text" class="form-control" id="floatingInput" placeholder=" " required>
        <label for="floatingInput">{{'COMMON.ADDRESS' | translate}}</label>
    </div>
        <div class="form-item form-floating mb-3">
            <select [ngClass]="selectedAreaId !== 0 ? 'selected-option valid' : ''" name="selectArea" (change)="selectArea($event)" required class="form-select" >
              <option value="" [selected]="selectedAreaId === 0" disabled>{{utName}}</option>
                <option *ngFor="let area of areas" [selected]="selectedAreaId === area.id" value="{{area.id}}">
                    {{area.name}}
                </option>
            </select>   
            <label *ngIf="selectedAreaId !== 0" class="label-for-select" for="floatingSelect">{{utName}}</label>
            <div *ngIf="selectedAreaId !== 0" class="select-title"></div>
        </div>
        <div class="form-item form-floating mb-3">
            <select [ngClass]="feederType !== -1 ? 'selected-option valid' : ''" name="selectedFeederType" (change)="selectFeederType($event)" required class="form-select" >
                <option value="" [selected]="feederType === -1" disabled>{{'COMMON.FEEDER_TYPE' | translate}}</option>
                <option value="KV11" [selected]="feederType === 0">11KV</option>
                <option value="KV33" [selected]="feederType === 1">33KV</option>
            </select>   
            <label *ngIf="feederType !== -1" class="label-for-select" for="floatingSelect">{{'COMMON.FEEDER_TYPE' | translate}}</label>
            <div *ngIf="feederType !== -1" class="select-title"></div>
        </div>

        <div class="form-item form-floating mb-3">
            <select [ngClass]="selectedFeederId !== 0 ? 'selected-option valid' : ''" name="selectSs" (change)="selectFeeder($event)" required class="form-select" >
                <option value="" [selected]="selectedFeederId === 0" disabled>{{'COMMON.FEEDER' | translate}}</option>
                <option *ngFor="let feeder of feeders" [selected]="selectedFeederId === feeder.id" value="{{feeder.id}}">
                    {{feeder.name}}
                </option>
            </select>   
            <label *ngIf="selectedFeederId !== 0" class="label-for-select" for="floatingSelect">{{'COMMON.FEEDER' | translate}}</label>
            <div *ngIf="selectedFeederId !== 0" class="select-title"></div>
          </div>
        
        <div class="form-item form-floating mb-3">
          <select name="user" required class="form-select" [ngClass]="{'valid selected-option': dt.assignedUserId !== 0}" (change)="selectOption($event)">
            <option value="" disabled [selected]="dt.assignedUserId === 0">{{'COMMON.FIELD_AGENT' | translate}}</option>
            <option *ngFor="let u of mobileUsers" value="{{u.id}}" [selected]="dt.assignedUserId === u.id">{{u.firstName}} {{u.lastName}}</option>

           <label for="floatingInput">{{'COMMON.LAST_NAME' | translate}}</label>
          </select>
        </div>

      </form>
    </div>

  </div>
