import { Rejection } from '../_modules/customers/_models/kyc-rejection';
import { Area } from './area.model';
import { Building, BuildingImage } from './building.model';
import { DtDto } from './dt.model';

export interface KycAccountDetails {
  id: number;
  title: string;
  firstName: string;
  accountNumber?: string;
  surname: string;
  district?: string;
  phoneNumber: string;
  dialCode: string;
  buildingOwnerDialCode: string;
  email: string;
  isNew: boolean;
  geoLocation?: string;
  feederName?: string;
  feederNumber?: string;
  dateOfBirth?: string;
  nameOfEmployer?: string;
  employerAddress: Address;
  addressOfLandlord: Address;
  employerAddressOfBuildingOwner?: Address;
  buildingOwnerEmployerPhoneNumber: string;
  buildingOwnerEmployerDialCode: string;
  meterNumber: string;
  meterNumberLandlord?: string;
  buildingOwnerAccountNumber?: string;
  accountNumberForPremissesLandlord?: string;
  estimatedMaxLoad?: number;
  requestedVoltage?: number;
  idType?: string;
  idNumber?: string;
  bankVerificationNumber?: string;
  supplyAddress?: string;
  typeOfPremises?: string;
  meterNumberPreviousCustomer?: string;
  customerInformation: KycCustomer;
  customerName?: string;
  householdNumber?: number;
  premisesUse?: string;
  nameOfElectricalEngineer?: string;
  idCardNo?: string;
  creationDate?: string;
  firstUser?: string;
  lastUser?: string;
  lastUpdateTime?: string;
  validationUser?: string;
  validationDate?: string;
  // status?: AccountStatus;
  currentStatus?: string;
  lastVendAmount?: number;
  lastVendDate?: string;
  propertyNature?: string;
  typeOfProperty?: string;
  hasPlanningPermissions?: string;
  hasMeter?: boolean;
  customersSignitureUrl: string;
  landlordsSignitureUrl: string;
  electricalContractorsSignitureUrl: string;
  applicantsSignitureUrl: string;
  passportUrl: string;
  buildingOwnerEmail?: string;
  buildingOwnerPhoneNumber: string;
  accountNumberPreviousCustomer?: string;
  addressPreviousCustomer?: Address;
  buildingOwnerAddress?: Address;
  addressElectricalEngineer?: Address;
  nameOfEmployerOfBuildingOwner?: string;
  licenseNumber?: string;
  category?: string;
  engineerDate?: string;
  buildingOwner?: string;
  loadRequirements?: LoadRequirement[];
  identificationOfLandlord?: string;
  personalIdNumberOfLandlord?: string;
  identificationOfBuildingOwner?: string;
  personalIdNumberOfBuildingOwner?: string;
  applicationNumber: string;
  kycRejections?: Rejection[],
  utilityId?: number;
}

export function getInitAccount(): KycAccountDetails {
  return {
    id: 0,
    title: '',
    firstName: '',
    surname: '',
    phoneNumber: '',
    dialCode: '',
    buildingOwnerDialCode: '',
    email: '',
    isNew: false,
    meterNumber: '',
    applicantsSignitureUrl: '',
    customersSignitureUrl: '',
    landlordsSignitureUrl: '',
    electricalContractorsSignitureUrl: '',
    passportUrl: '',
    addressOfLandlord: new Address(),
    employerAddress: new Address(),
    customerInformation: new KycCustomer(),
    buildingOwnerPhoneNumber: '',
    buildingOwnerEmployerDialCode: '',
    buildingOwnerEmployerPhoneNumber: '',
    applicationNumber: ''
    
  };
}

export interface LoadRequirement {
  id: number | null;
  name?: string | null;
  number: number | null;
  wattage: number | null;
  utilityId?: number | null;
}

export interface KycHeader {
  slrn: string;
  firstName: string;
  surname: string;
  applicationNumber: string;
  email: string;
  phoneNumber: string;
  address: string;
}

export function getInitHeader(): KycHeader {
  return {
    slrn: '',
    firstName: '',
    surname: '',
    applicationNumber: '',
    email: '',
    phoneNumber: '',
    address: ''
  }
}
export class KycCustomer {
  building: Building;
  area: Area;
  dt: DtDto;
  buildingType?: string;
  // meter: Meter;
  billImageUrl: string;
  cardImageUrl: string;
  meterImageUrl: string;
  meterNumberImageUrl: string;
  utilitySupplyImageUrl: string;
  supplyFuseImageUrl: string;
  buildingImages: BuildingImage[];
  numberOfHouseholds: string;
  contractNumber: string;
  // typeOfConnection: TypeOfConnection,
  ghanaPostGps?: string;
  postCodeException?: string;
  buldingOwnerName: string;
  // customerType: CustomerType;
  // serviceType: ServiceType;
  // sltStatus: SltStatus;
  // clientType: ClientType;
  // meterCondition: MeterCondition;
  // meterStatus: MeterStatus;
  meterSlrn: string;
  cardNumber: string;
  closestLandmark: string;
  feederId: number;
  dtId: number;
  meterTypeId: number;
  meterNumber: string;
  physicalAddress: string = '';
  remarksAndRecommendations?: string;
  lastUpdateTime?: string;
  guid: string;
  lastPaymentAmount: string;
  lastPaymentDate: string;
  lastVendDate?: string;
  lastVendAmount?: number;
  currentDebtBalance: string;
  numberOfRooms: number;
}

export class Address {
  houseNo: string;
  street: string;
  landmark: string;
  city?: string;
  area?: string;
  areaId?:number;

}
