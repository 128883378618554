<div class="title">
  <p>{{ "COMMON.MBC_DETAILS.SUB_FEEDER" | translate }}</p>
  <button
    type="button"
    class="btn btn-primary"
    data-bs-toggle="modal"
    data-bs-target="#addNewF11"
    (click)="addNew()"
    *ngIf="
      permissionsService.isF11CreateEditAllowed &&
      utilityId != 0 &&
      bpsAdminLevel != 2
    "
  >
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8 0C7.4477 0 7 0.44772 7 1V7H1C0.44772 7 0 7.4477 0 8C0 8.5523 0.44772 9 1 9H7V15C7 15.5523 7.4477 16 8 16C8.5523 16 9 15.5523 9 15V9H15C15.5523 9 16 8.5523 16 8C16 7.4477 15.5523 7 15 7H9V1C9 0.44772 8.5523 0 8 0Z"
        fill="white"
      />
    </svg>
    {{ "CRUD_SETTINGS.ADD_NEW" | translate }}
  </button>
</div>
<div class="container-fluid border-bottom">
  <table>
    <tr class="header-row">
      <th>{{ "COMMON.MBC_DETAILS.FEEDER_NAME" | translate }}</th>
      <th>{{ "COMMON.MBC_DETAILS.FEEDER_NUMBER" | translate }}</th>
      <th>{{ "COMMON.SS.SINGLE" | translate }}</th>
      <th>{{ "COMMON.MBC_DETAILS.MAIN_FEEDER" | translate }}</th>
      <th>{{ "COMMON.BU.SINGLE" | translate }}</th>
      <th>{{ "CRUD_SETTINGS.SUB_FEEDER_NAMEPLATE_RATING" | translate }}</th>
      <th></th>
    </tr>
    <tr
      class="content-wrapper"
      *ngFor="let f11 of feeders11"
      data-bs-toggle="modal"
      data-bs-target="#addNewF11"
      (click)="
        permissionsService.isF11CreateEditAllowed ? selectF11(f11) : null
      "
    >
      <td>{{ f11.name | hasValue }}</td>
      <td>{{ f11.number | hasValue }}</td>
      <td>{{ f11.substation.name | hasValue }}</td>
      <td>{{ f11.feeder33?.name | hasValue }}</td>
      <td>{{ f11.region?.name | hasValue }}</td>
      <td>{{ f11.nameplateRating | hasValue }}</td>
      <td class="td-svg">
        <svg
          data-bs-toggle="modal"
          data-bs-target="#deleteF11"
          (click)="selectF11(f11)"
          width="22"
          height="22"
          viewBox="0 0 22 22"
          xmlns="http://www.w3.org/2000/svg"
          *ngIf="permissionsService.isF11DeleteAllowed && bpsAdminLevel != 2"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22ZM11 20C15.9706 20 20 15.9706 20 11C20 6.02944 15.9706 2 11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20ZM6 11C6 10.4477 6.44772 10 7 10H15C15.5523 10 16 10.4477 16 11C16 11.5523 15.5523 12 15 12H7C6.44772 12 6 11.5523 6 11Z"
            fill="#DE350B"
          />
        </svg>
      </td>
    </tr>
  </table>
</div>
<div>
  <app-paging
    *ngIf="count > 0"
    [pageSize]="pageSize"
    [count]="count"
    [currentPage]="currentPage"
    (pageChange)="pageChange($event)"
  ></app-paging>
</div>

<div
  class="modal fade"
  id="addNewF11"
  tabindex="-1"
  aria-hidden="true"
  *ngIf="permissionsService.isF11CreateEditAllowed"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">
          {{ actionLabel$ | async }} {{ "COMMON.MBC_DETAILS.SUB_FEEDER" | translate }}
        </h5>
        <button
          type="button"
          #closeAddModal
          class="btn-cancel-modal"
          data-bs-dismiss="modal"
        >
          {{ "COMMON.CANCEL" | translate }}
        </button>
      </div>
      <form
        id="addForm"
        class="form-wrapper"
        ngNativeValidate
        (submit)="save()"
      >
        <div class="modal-body">
          <div class="form-item form-floating mb-3">
            <input
              id="feederName"
              name="name"
              type="text"
              [(ngModel)]="selectedF11.name"
              class="form-control"
              required
              placeholder=" "
            />
            <label for="feederName">{{
              "COMMON.MBC_DETAILS.FEEDER_NAME" | translate
            }}</label>
          </div>
          <div class="form-item form-floating mb-3">
            <input
              id="feederNumber"
              name="number"
              type="text"
              [(ngModel)]="selectedF11.number"
              class="form-control"
              required
              placeholder=" "
            />
            <label for="feederNumber">{{
              "COMMON.MBC_DETAILS.FEEDER_NUMBER" | translate
            }}</label>
          </div>
          <div class="form-item form-floating mb-3">
            <select
              [ngClass]="selectedSsId !== 0 ? 'selected-option' : ''"
              name="selectSs"
              (change)="selectSs($event)"
              required
              class="form-select"
            >
              <option value="" [selected]="selectedSsId === 0" disabled>
                {{ "COMMON.SS.SINGLE" | translate }}
              </option>
              <option
                *ngFor="let ss of substations"
                [selected]="selectedSsId === ss.id"
                value="{{ ss.id }}"
              >
                {{ ss.name }}
              </option>
            </select>
            <label
              *ngIf="selectedSsId !== 0"
              class="label-for-select"
              for="floatingSelect"
              >{{ "COMMON.SS.SINGLE" | translate }}</label
            >
            <div *ngIf="selectedSsId !== 0" class="select-title"></div>
          </div>
          <div class="form-item form-floating mb-3">
            <select
              [ngClass]="selectedF33Id !== -1 ? 'selected-option' : ''"
              name="selectF33"
              (change)="selectF33($event)"
              required
              class="form-select"
            >
              <option value="" [selected]="selectedF33Id === -1" disabled>
                {{ "COMMON.MBC_DETAILS.MAIN_FEEDER" | translate }}
              </option>
              <option
                *ngFor="let feeder33 of f33s"
                [selected]="selectedF33Id === feeder33.id"
                value="{{ feeder33.id }}"
              >
                {{ feeder33.name }}
              </option>
            </select>
            <label
              *ngIf="selectedF33Id !== -1"
              class="label-for-select"
              for="floatingSelect"
              >{{ "COMMON.MBC_DETAILS.MAIN_FEEDER" | translate }}</label
            >
            <div *ngIf="selectedF33Id !== -1" class="select-title"></div>
          </div>
          <div class="form-item form-floating mb-3">
            <select
              [ngClass]="selectedRegionId !== 0 ? 'selected-option' : ''"
              name="selectRegion"
              (change)="selectRegion($event)"
              required
              class="form-select"
            >
              <option value="" [selected]="selectedRegionId === 0" disabled>
                {{ "COMMON.SS.SINGLE" | translate }}
              </option>
              <option
                *ngFor="let region of regions"
                [selected]="selectedRegionId === region.key"
                value="{{ region.key }}"
              >
                {{ region.value }}
              </option>
            </select>
            <label
              *ngIf="selectedRegionId !== 0"
              class="label-for-select"
              for="floatingSelect"
              >{{ "COMMON.SS.SINGLE" | translate }}</label
            >
            <div *ngIf="selectedRegionId !== 0" class="select-title"></div>
          </div>
          <div class="form-item form-floating mb-3">
            <select
              [ngClass]="selectedF11.nameplateRating ? 'selected-option' : ''"
              name="nameplateRating"
              (change)="selectNameplateRating($event)"
              required
              class="form-select"
            >
              <option
                value=""
                [selected]="!selectedF11.nameplateRating"
                disabled
              >
                {{ "CRUD_SETTINGS.SUB_FEEDER_NAMEPLATE_RATING" | translate }}
              </option>
              <option
                *ngFor="let nameplate of nameplateRatingOptions"
                [selected]="selectedF11.nameplateRating === nameplate"
                value="{{ nameplate }}"
              >
                {{ nameplate }}
              </option>
            </select>
            <label
              *ngIf="selectedF11.nameplateRating"
              class="label-for-select"
              for="floatingSelect"
              >{{
                "CRUD_SETTINGS.SUB_FEEDER_NAMEPLATE_RATING" | translate
              }}</label
            >
            <div *ngIf="selectedF11.nameplateRating" class="select-title"></div>
          </div>
        </div>
        <div class="modal-footer" *ngIf="bpsAdminLevel != 2">
          <button class="btn btn-primary" form="addForm">
            {{ "COMMON.SAVE" | translate }}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

<div class="modal fade" id="deleteF11" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header delete-modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">
          {{ "CRUD_SETTINGS.ARE_YOU_SURE_DELETE" | translate }}
          {{ "COMMON.FEEDER" | translate }}?
        </h5>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="deleteF11()">
          {{ "COMMON.DELETE" | translate }}
        </button>
        <button
          #closeDeleteModal
          hidden="true"
          data-bs-dismiss="modal"
        ></button>
      </div>
    </div>
  </div>
</div>
