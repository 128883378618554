import { TypeOfConnection } from "src/app/models/customer.model";
import { KycStatus } from "../_enums/kyc-status";
import { Title } from "../_enums/title.enum";
import { KycAccountInformation } from "./kyc-account-information";
import { KycBuildingOwnerInformation } from "./kyc-building-owner-information";
import { Rejection } from "./kyc-rejection";

export class KycAccountData {
    id: number;
    accountNumber?: string | null;
    applicationNumber?: string | null;
    email: string | null;
    phoneNumber: string | null;
    dialCode: string | null;
    buildingOwnerDialCode: string | null;
    buildingOwnerEmployerDialCode: string | null;
    // password: string | null;
    utilityId?: number | null;
    lastUpdateTime?: string | null;
    title: number | null;
    surname: string | null;
    firstName?: string | null;
    dateOfBirth: Date | null;
    nameOfEmployer: string | null;
    employerAddress: AddressModel
    meterNumberLandlord: string | null;
    accountNumberForPremissesLandlord: string | null;
    identificationOfLandlord: number | null;
    personalIdNumberOfLandlord: string | null;
    addressOfLandlord: AddressModel
    typeOfPremises: number | null;
    useOfPremises: number | null;
    addressPreviousCustomer: AddressModel
    meterNumberPreviousCustomer: string | null;
    accountNumberPreviousCustomer: string | null;
    buildingOwner: string | null;
    buildingOwnerAddress: AddressModel;
    buildingOwnerPhoneNumber: string | null;
    identificationOfBuildingOwner: number | null;
    personalIdNumberOfBuildingOwner: string | null;
    nameOfEmployerOfBuildingOwner: string | null;
    employerAddressOfBuildingOwner: AddressModel
    buildingOwnerEmployerPhoneNumber: string | null;
    buildingOwnerEmail: string | null;
    loadRequirements: LoadRequirement[];
    propertyNature: number | null;
    typeOfProperty: number | null;
    hasPlanningPermissions: boolean;
    hasMeter: boolean;
    meterNumber: string | null;
    buildingOwnerAccountNumber: string | null;
    estimatedMaxLoad: number | null;
    requestedVoltage: number | null;
    nameOfElectricalEngineer?: string | null;
    addressElectricalEngineer: AddressModel
    licenseNumber: string | null;
    category: string | null;
    engineerDate: Date | null;
    meterNumberRequestingForChange: string | null;
    accountNumberRequestingForChange: string | null;
    customersSignitureUrl: string | null;
    landlordsSignitureUrl: string | null;
    electricalContractorsSignitureUrl: string | null;
    applicantsSignitureUrl: string | null;
    passportUrl: string | null;
    customersSignitureName: string | null;
    landlordsSignitureName: string | null;
    electricalContractorsSignitureName: string | null;
    applicantsSignitureName: string | null;
    passportName: string | null;
    balanceAmountAgreement?: number | null;
    debtRepaymentOption?: number | null;
    debtAmountAcceptance: boolean;
    meterRequest?: MeterRequest;
    currentPage: number;
    accountInformation?: KycAccountInformation;
    buildingOwnerInformation?: KycBuildingOwnerInformation;
    activityProgressPill?: ActivityProgressPill[];
    currentStatus?: KycStatus;
    rejections?: Rejection[];
    profilePictureUrl: string | null;
    lastPaymentAmount?: string | null;
    lastPaymentDate?: string | null;
    currentDebtBalance?: string | null;
    bankVerificationNumber?: string | null;
    instalmentPlan?: InstalmentPlan

    constructor(data: any) {
        this.id = data.id;
        this.accountNumber = data.accountNumber ?? '';
        this.applicationNumber = data.applicationNumber ?? '';
        this.email = data.email ?? '';
        this.phoneNumber = data.phoneNumber ?? '';
        this.utilityId = data.utilityId ?? '';
        this.lastUpdateTime = data.lastUpdateTime ?? '';
        this.title = data.title ?? Title.Mr;
        this.surname = data.surname ?? '';
        this.firstName = data.firstName ?? '';
        this.dateOfBirth = data.dateOfBirth ?? '';
        this.nameOfEmployer = data.nameOfEmployer ?? '';
        this.employerAddress = new AddressModel(data.employerAddress);
        this.addressPreviousCustomer = new AddressModel(data.addressPreviousCustomer);
        this.addressOfLandlord = new AddressModel(data.addressOfLandlord);
        this.buildingOwnerAddress = new AddressModel(data.buildingOwnerAddress);
        this.employerAddressOfBuildingOwner = new AddressModel(data.employerAddressOfBuildingOwner);
        this.addressElectricalEngineer = new AddressModel(data.addressElectricalEngineer);
        this.meterNumberLandlord = data.meterNumberLandlord ?? '';
        this.accountNumberForPremissesLandlord = data.accountNumberForPremissesLandlord ?? '';
        this.identificationOfLandlord = data.identificationOfLandlord;
        this.personalIdNumberOfLandlord = data.personalIdNumberOfLandlord ?? '';
        this.typeOfPremises = data.typeOfPremises ?? '';
        this.useOfPremises = data.useOfPremises ?? '';
        this.meterNumberPreviousCustomer = data.meterNumberPreviousCustomer ?? '';
        this.meterNumber = data.meterNumber ?? '';
        this.accountNumberPreviousCustomer = data.accountNumberPreviousCustomer ?? '';
        this.buildingOwnerPhoneNumber = data.buildingOwnerPhoneNumber ?? '';
        this.identificationOfBuildingOwner = data.identificationOfBuildingOwner;
        this.personalIdNumberOfBuildingOwner = data.personalIdNumberOfBuildingOwner ?? '';
        this.nameOfEmployerOfBuildingOwner = data.nameOfEmployerOfBuildingOwner ?? '';
        this.employerAddressOfBuildingOwner = data.employerAddressOfBuildingOwner ?? '';
        this.buildingOwnerEmployerPhoneNumber = data.buildingOwnerEmployerPhoneNumber ?? '';
        this.buildingOwnerEmail = data.buildingOwnerEmail ?? '';
        this.loadRequirements = data.loadRequirements ? data.loadRequirements.map((el: any) => new LoadRequirement(el)) : [];
        this.propertyNature = data.propertyNature;
        this.typeOfProperty = data.typeOfProperty ?? '';
        this.hasPlanningPermissions = data.hasPlanningPermissions ?? false;
        this.hasMeter = data.hasMeter ?? false;
        this.buildingOwnerAccountNumber = data.buildingOwnerAccountNumber ?? '';
        this.estimatedMaxLoad = data.estimatedMaxLoad;
        this.requestedVoltage = data.requestedVoltage;
        this.nameOfElectricalEngineer = data.nameOfElectricalEngineer ?? '';
        this.buildingOwner = data.buildingOwner ?? '';
        this.licenseNumber = data.licenseNumber ?? '';
        this.category = data.category ?? '';
        this.engineerDate = data.engineerDate ?? '';
        this.meterNumberRequestingForChange = data.meterNumberRequestingForChange ?? '';
        this.accountNumberRequestingForChange = data.accountNumberRequestingForChange ?? '';
        this.customersSignitureUrl = data.customersSignitureUrl ?? '';
        this.landlordsSignitureUrl = data.landlordsSignitureUrl ?? '';
        this.electricalContractorsSignitureUrl = data.electricalContractorsSignitureUrl ?? '';
        this.applicantsSignitureUrl = data.applicantsSignitureUrl ?? '';
        this.passportUrl = data.passportUrl ?? '';
        this.customersSignitureName = data.customersSignitureName ?? '';
        this.landlordsSignitureName = data.landlordsSignitureName ?? '';
        this.electricalContractorsSignitureName = data.electricalContractorsSignitureName ?? '';
        this.applicantsSignitureName = data.applicantsSignitureName ?? '';
        this.passportName = data.passportName ?? '';
        this.balanceAmountAgreement = data.balanceAmountAgreement ?? 0;
        this.debtRepaymentOption = data.debtRepaymentOption ?? null;
        this.debtAmountAcceptance = data.debtAmountAcceptance ?? false;
        this.currentPage = data.currentPage ?? 1;
        this.dialCode = data.dialCode;
        this.buildingOwnerDialCode = data.buildingOwnerDialCode;
        this.buildingOwnerEmployerDialCode = data.buildingOwnerEmployerDialCode;
        this.accountInformation = new KycAccountInformation(data.accountInformation);
        this.buildingOwnerInformation = new KycBuildingOwnerInformation(data.buildingOwnerInformation);
        this.currentStatus = data.currentStatus ?? KycStatus.Incomplete;
        this.rejections = data.rejections ? data.rejections.map((el: any) => new Rejection(el)) : [];
        this.profilePictureUrl = data.profilePictureUrl ?? '';
        this.lastPaymentAmount = data.lastPaymentAmount ?? '';
        this.lastPaymentDate = data.lastPaymentDate ?? '';
        this.currentDebtBalance = data.currentDebtBalance ?? '';
        this.bankVerificationNumber = data.bankVerificationNumber ?? '';
        this.activityProgressPill = data.activityProgressPill ?? '';
        this.meterRequest = data.meterRequest ?? null;
        this.instalmentPlan = data.instalmentPlan ?? null;
    }
}
  
export class AddressModel {
    houseNo: string | null;
    street: string | null;
    landmark: string | null;
    city: string | null;
    area: string | null;
    areaId:number|null;

    constructor(data: any) {
        this.houseNo = data?.houseNo ?? '';
        this.street = data?.street ?? '';
        this.landmark = data?.landmark ?? '';
        this.city = data?.city ?? '';
        this.area = data?.area ?? '';
        this.areaId = data.areaId??null;
    }
}

export class LoadRequirement {
    id: number | null;
    name?: string | null;
    number: number | null;
    wattage: number | null;
    utilityId?: number | null;

    constructor(data: any) {
        this.id = data?.id ?? 0;
        this.name = data?.name ?? '';
        this.number = data?.number ?? 0;
        this.wattage = data?.wattage ?? 0;
        this.utilityId = data?.utilityId ?? 0;
    }
}

export enum CurrentPage
{
    PersonalDataI = 1,
    PersonalDataII = 2,
    PersonalDataIII = 3,
    PersonalDataIV = 4,
    LoadRequirements = 5,
    PersonalDataV = 6,
    PersonalDataVI = 7,
    UploadFile = 8,
    AccountInformation = 9,
    UpdateMeterRequestI = 10,
    UpdateMeterRequestII = 11,
    ReviewApplication = 12
}

export class RelatedAccount {
    accountNumber?: string;
    accountType?: string;
    meterTally?: number;
    serialNo?: number;

    constructor(data: any) {
        this.accountNumber = data?.accountNumber ?? '';
        this.accountType = data?.accountType ?? '';
        this.meterTally = data?.meterTally;
        this.serialNo = data?.serialNo;
    } 
}

export class MeterRequest {
    meterCount?: number;
    phaseRequested?: TypeOfConnection;
    friendlyPowerPhase?: string;
    constructor(meterCount: number, phaseRequested: TypeOfConnection, friendlyPowerPhase?: string) {
        this.meterCount = meterCount;
        this.phaseRequested = phaseRequested;
        this.friendlyPowerPhase = friendlyPowerPhase;
    } 
}

export class ActivityProgressPill
{
    name: string;
    onboardingStatus: OnboardinActivityStatus;
    constructor(data: any) {
        this.name = data?.name ?? '';
        this.onboardingStatus = data?.onboardingStatus ?? null;
    }
}

export enum OnboardinActivityStatus
{
    Completed,
    Pending,
    Incomplete
}

export enum InstalmentPlan {
    ThreeMonths = 0,
    SixMonths = 1,
    NineMonths = 2
}
