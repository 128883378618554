export class AssetsCounts {
    public customersCount: number = 0;
    public surveysCount: number = 0;
    public installationsCounts: number = 0;

    constructor(data?: any) {
        this.customersCount = data?.customersCount ?? 0;
        this.surveysCount = data?.surveysCount ?? 0;
        this.installationsCounts = data?.installationsCounts ?? 0;
    }
}