import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DecommissionedMeterDetails, DecommissioningStockItemStatus } from 'src/app/models/decommissioning';
import { Image, ImageType } from 'src/app/models/image.model';
import { DecommissioningService } from 'src/app/services/decommissioning.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-meter-stock-decommissioned-details',
  templateUrl: './meter-stock-decommissioned-details.component.html',
  styleUrls: ['./meter-stock-decommissioned-details.component.scss'],
})
export class MeterStockDecommissionedDetailsComponent implements OnInit {
  selectedMeter: DecommissionedMeterDetails = new DecommissionedMeterDetails();
  selectedImage: any;
  id: string = '';
  imgElement: any;
  imgZoomFullContainer: any;
  currentRotation: number = 0;
  buName: string = this.translationService.getByKeyFromStorage('BU');
  utName: string = this.translationService.getByKeyFromStorage('UT');
  
  DecommissioningStockItemStatus = DecommissioningStockItemStatus;
  public editActivated = false;
  status?: number;
  stockItemStatusOptions = [
    { name: 'In Storage', value: DecommissioningStockItemStatus.InStorage },
    { name: 'Awaiting Recycling', value: DecommissioningStockItemStatus.AwaitingRecycling },
    { name: 'Recycled', value: DecommissioningStockItemStatus.Recycled },
  ];

  public ImageType = ImageType;
  constructor(
    private route: ActivatedRoute,
    public permissionsService: PermissionsService,
    private el: ElementRef,
    private renderer: Renderer2,
    private translationService: TranslationService,
    private decommissionService: DecommissioningService,
    private toastr: ToastrService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id') || '';
    this.imgElement = this.el?.nativeElement?.querySelector(
      '.ngxImageZoomThumbnail'
    );
    this.imgZoomFullContainer =
      this.el?.nativeElement?.querySelector('.ngxImageZoomFull');

    this.getSelectedDecommission(parseInt(this.id));
  }

  selectStatus(e: any) {
    if (e.target.value != '') {
      this.status = parseInt(e.target.value);
    } else {
      this.status = undefined;
    }
  }

  private getSelectedDecommission(id: any) {
    this.decommissionService.getDetailsById(id).subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.selectedMeter = new DecommissionedMeterDetails(
            responseData.data
          );
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  onModalFade() {
    this.currentRotation = 0;

    if (this.imgZoomFullContainer) {
      var displayValue = getComputedStyle(this.imgZoomFullContainer).display;

      if (displayValue == 'block') {
        this.imgElement.click();
      }
    }
  }

  rotateLeft() {
    this.currentRotation -= 90;
  }

  rotateRight() {
    this.currentRotation += 90;
  }

  onModalOpen() {
    this.renderer.setStyle(document.body, 'overflow', 'auto');
    this.renderer.setStyle(document.body, 'overflow-x', 'hidden');
    this.renderer.setStyle(document.body, 'padding-right', '0');
  }

  showImage(url?: string) {
    this.selectedImage = url;
  }

  getImage(type: ImageType) {
    return this.selectedMeter.images?.filter(
      (x) => x.imageType === ImageType[type]
    );
  }

  approve() {
    this.decommissionService.approve(parseInt(this.id)).subscribe({
      next: (response) => {
        if (response?.status === 200 || response?.status === 'OK') {
          this.toastr.success('Meter Decommission Approved');
          this.router.navigate(['/meter-stock-decommissioned']);
        } else {
          this.toastr.error(response?.message);
        }
      },
    });
  }

  saveChanges(){
    if (this.status){
      this.decommissionService.saveDetails({ DecommissioningStatus: this.status, DecommissioningId: parseInt(this.id) }).subscribe({
        next: (response) => {
          if (response?.status === 200 || response?.status === 'OK') {
            this.toastr.success('Success');
            this.editActivated = false;
            this.status = undefined;
            this.getSelectedDecommission(parseInt(this.id));
          } else {
            this.toastr.error(response?.message);
          }
        },
      });
    }
  }
}
