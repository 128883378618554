import { Injectable } from '@angular/core';
import { ReportMenuItem } from '../../../models/report.menu.item.model';
import { DataQualityRejectionTabs } from '../../../enums/data-quality-rejection-tabs.enum';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { RegularizationTabs } from 'src/app/enums/regularization-tabs.enum';

@Injectable({
  providedIn: 'root'
})
export class ReportStateService {
    reportSelected: ReportMenuItem = ReportMenuItem.Customers;
    dataQualityRejectionTab: DataQualityRejectionTabs = DataQualityRejectionTabs.Customers;

    private reportsTabKey: string = 'reportsTab';
    private dataQualityRejectionTabKey: string = 'dataQualityRejectionTab';

    public customersTabKey: string = 'filterRejectedCustomer';
    public buildingsTabKey: string = 'filterRejectedBuilding';
    public ltPoleTabKey: string = 'filterRejectedLtPole';
    public transformersTabKey: string = 'filterRejectedTransformers';

    constructor(
        private router: Router,
        private location: Location,
    ) {}

    mainTabSelected(tab: ReportMenuItem) {
        this.reportSelected = tab;
        localStorage.setItem(this.reportsTabKey, JSON.stringify(this.reportSelected));
        this.location.replaceState(`/reports/${this.reportSelected}`);

        // Clear the filters from localstorage
        this.clearAllReportFilters({clearMainTabSelection: false, clearDataQualityTabsSelection: true });
    }

    preselectMainTab() {
        let mainTab = localStorage.getItem(this.reportsTabKey);

        if (mainTab) {
            this.reportSelected = JSON.parse(mainTab);
            this.router.navigate([`/reports/${this.reportSelected}`]);
        } else {
            this.mainTabSelected(ReportMenuItem.Customers);
        }
    }

    qualityRejectionPageTabSelected(tab: DataQualityRejectionTabs) {
        this.dataQualityRejectionTab = tab;
        localStorage.setItem(this.dataQualityRejectionTabKey, JSON.stringify(this.dataQualityRejectionTab));

        // Clear the filters from localstorage
        this.clearAllReportFilters({clearMainTabSelection: false, clearDataQualityTabsSelection: false });
    }

    preselectDataQualityRejectionTab() {
        let tab = localStorage.getItem(this.dataQualityRejectionTabKey);

        if (tab) {
            this.dataQualityRejectionTab = JSON.parse(tab);
        } else {
            this.qualityRejectionPageTabSelected(DataQualityRejectionTabs.Customers);
        }
    }

    clearAllReportFilters({clearMainTabSelection, clearDataQualityTabsSelection}: { clearMainTabSelection: boolean, clearDataQualityTabsSelection: boolean }) {
        // Clear filters on all data quality tabs
        localStorage.removeItem(this.customersTabKey);
        localStorage.removeItem(this.buildingsTabKey);
        localStorage.removeItem(this.ltPoleTabKey);
        localStorage.removeItem(this.transformersTabKey);

        // Clear tabs selection on the data quality page
        if (clearDataQualityTabsSelection) {
            localStorage.removeItem(this.dataQualityRejectionTabKey);            
        }

        // Clear main tab selection (Missaligned and Data quality rejection)
        if (clearMainTabSelection) {
            localStorage.removeItem(this.reportsTabKey);
        }
    }
}
