import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { AssetRating } from 'src/app/models/assetRating.model';
import { PermissionsEnum } from 'src/app/models/role.model';
import { BPSAdminLevel } from 'src/app/models/user.model';
import { AssetRatingService } from 'src/app/services/asset-rating.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { TranslationUtils } from 'src/assets/i18n/translation.utils';

@Component({
  selector: 'crud-asset-rating',
  templateUrl: './crud-asset-rating.component.html',
  styleUrls: ['./crud-asset-rating.component.scss'],
})
export class CrudAssetRatingComponent implements OnInit {
  public PermissionsEnum = PermissionsEnum;
  assetRatings: AssetRating[] = [];

  public currentPage: number = 1;
  public pageSize: number = 5;
  public count: number = 0;

  selectedAssetRating: AssetRating = new AssetRating();
  actionLabel$!: Observable<string>;
  isEditMode: boolean = false;

  @ViewChild('closeModal') closeModal: ElementRef;

  @Input() searchText!: string;
  @Input() utilityId: number = 0;
  @Input() bpsAdminLevel!: BPSAdminLevel;

  constructor(
    private assetRatingService: AssetRatingService,
    public permissionsService: PermissionsService,
    private toastr: ToastrService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    if (this.utilityId != 0) {
      this.reloadTable();
    }

    this.updateActionLabel();
  }

  private updateActionLabel(): void {
    const key = this.isEditMode ? TranslationUtils.EDIT_KEY : TranslationUtils.NEW_KEY;
    this.actionLabel$ = this.translateService.stream(key);
  }

  filter() {
    this.currentPage = 1;
    let dataIn = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize,
      },
      filterParams: {
        SearchValue: this.searchText,
      },
      utilityId: this.utilityId,
    };
    this.getAllAssetRatings(dataIn);
  }

  reloadTable(searchText?: string, page: any = null) {
    if (page) {
      this.currentPage = page;
    }

    let dataIn = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize,
      },
      filterParams: {
        SearchValue: searchText,
      },
      utilityId: this.utilityId,
    };

    this.getAllAssetRatings(dataIn);
  }

  addNew() {
    this.isEditMode = false;
    this.selectedAssetRating = new AssetRating();
    this.updateActionLabel();
  }

  selectAssetRating(assetRating: AssetRating) {
    this.isEditMode = true;
    this.selectedAssetRating = { ...assetRating };
    this.updateActionLabel();
  }

  pageChange(value: any) {
    this.currentPage = value;
    this.reloadTable(this.searchText);
  }

  save() {
    this.selectedAssetRating.utilityId = this.utilityId;
    this.assetRatingService.save(this.selectedAssetRating).subscribe({
      next: (response) => {
        if (response.status === 200 || response.status === 'OK') {
          this.closeModal?.nativeElement?.click();
          this.toastr.success(response.message);
          if (this.selectedAssetRating.id === 0) {
            this.pageChange(1);
            return;
          }
          this.reloadTable(this.searchText);
        } else {
          this.toastr.error(response.message);
        }
      },
      error: (_) => this.toastr.error('Error occured'),
    });
  }

  deleteAssetRating() {
    this.assetRatingService.delete(this.selectedAssetRating.id).subscribe({
      next: (response) => {
        this.closeModal?.nativeElement?.click();
        if (response.status === 200 || response.status === 'OK') {
          this.pageChange(1);
          this.toastr.success(response.message);
        } else {
          this.toastr.error(response.message);
        }
      },
      error: (_) => this.toastr.error('Error occured'),
    });
  }

  private getAllAssetRatings(dataIn: any) {
    this.assetRatingService.getAll(dataIn).subscribe({
      next: (response) => {
        this.assetRatings = response.data.data;
        this.count = response.data.count;
        if (this.count == 0) {
          this.toastr.info('No data for given input.');
        }
      },
      error: (_) => this.toastr.error('An error ocurred.'),
    });
  }
}
