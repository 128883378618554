import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Role, SystemComponents } from 'src/app/models/role.model';
import { DashboardConfiguration } from 'src/app/models/utility.model';
import { RoleService } from 'src/app/services/role.service';

@Component({
  selector: 'app-create-role',
  templateUrl: './create-role.component.html',
  styleUrls: ['./create-role.component.scss'],
})
export class CreateRoleComponent implements OnInit {
  @Input() role: Role = new Role();
  @Input() isPopUp: boolean = false;
  @Input() isEditMode: boolean = false;
  @Output() closePopUpChange = new EventEmitter();
  public sysComp = SystemComponents;

  constructor(
    private toastr: ToastrService,
    private roleService: RoleService
  ) {}

  ngOnInit(): void {}

  submit() {
    if (this.role.name.length == 0) {
      this.toastr.error('Please, enter role name.');
      return;
    }
    if (this.role.systemComponent == -1) {
      this.toastr.error('Please, select system component.');
      return;
    }
    if (this.role.systemComponent == 1) {
      var x = localStorage.getItem('configuration');
      let configurations = x ? JSON.parse(x) : [];
      console.log(configurations);
      if (
        configurations.length == 1 &&
        configurations.includes(DashboardConfiguration.WorkOrder)
      ) {
        this.toastr.error(
          "Utility doesn't have any mobile configurations enabled."
        );
        return;
      }
    }
    this.role.permissions = []; // clear permissions
    this.roleService.save(this.role).subscribe(
      (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.toastr.success(responseData.message);
          this.role = new Role();
          this.closeModal();
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      (error) => {
        this.toastr.error('Error occured');
      }
    );
  }

  closeModal() {
    this.closePopUpChange.emit();
  }

  selectOption(e: any) {
    this.role.systemComponent = +e.target.value;
  }
}
