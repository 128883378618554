import { Component, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-regular-checkbox',
  template: `
      <label class="checkbox">
        <ng-content select="[checkboxLabel]"></ng-content>
        <input type="checkbox" checked="checked" [(ngModel)]="isChecked" (ngModelChange)="onModelChange($event)"  />
        <span class="checkmark"></span>
      </label>
  `,
  styleUrls: ['./regular-checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RegularCheckboxComponent),
      multi: true
    }
  ]
})
export class RegularCheckboxComponent {
  @Input() isChecked: boolean = false;

  onChange: any = (checked: boolean) => {};
  onTouch: any = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(checked: boolean) {
    this.isChecked = checked;
  }

  onModelChange(e: boolean) {
    // Bind the changes to the local value
    this.isChecked = e;

    // Handle what should happen on the outside, if something changes on the inside
    this.onChange(e);
  }
}
