import { BuildingType, CustomerType, ServiceType, SltStatus } from "./customer.model";

export interface Tariff {
    id: number;
    name: string;
    class: string;
    rate: number;
    utilityId: number;
    serviceTypes?: ServiceType[];
    customerTypes?: CustomerType[];
    useOfPremises?: BuildingType[];
    sltStatusId?: number
}

export function getInitTariff(): Tariff{
    return {
        id: 0,
        name: '',
        class: '',
        rate: 0,
        utilityId: 0
    }
}