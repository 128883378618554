export class AssetStatus {
    id: number = 0;
    status: string = "";
    name: string = "";
    lastUpdateTime : Date = new Date();
    utilityId: number = 0;
}

export enum AssetStatusEnum {
    Pending = 0, //each asset comes as pending from mobile
    Complete = 1,//admin approved asset
    Incomplete = 2,//admin reject the asset
    Assigned = 3,//admin re-assigned asset
    Rejected = 4, //DataQuality Reject
    Retagged = 5,
}