import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UtilityBasicInfo } from 'src/app/models/utility.model';
import { AccountKycService } from 'src/app/services/kyc/account-kyc.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  email: string = '';
  step: number = 1;
  utility: UtilityBasicInfo;

  constructor(private toastr: ToastrService,
              private accountKycService: AccountKycService,
              private route: ActivatedRoute,
              private utilityService: UtilityService,
              private router: Router ) { }

  ngOnInit(): void {
    var utilityRoute = this.route.snapshot.paramMap.get('utility') || '';

    if (utilityRoute) {
      this.tryGetUtilityByName(utilityRoute.replace(new RegExp("-", "g"), " "));
    } else this.router.navigate(['']);
  }

  resetPassword() {
    if(!this.email) {
      this.toastr.warning('Please enter email address!');
      return;
    }
    else {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
   
      if (!emailRegex.test(this.email)) {
        this.toastr.warning("Invalid Email format.");
        return;
      }
    }
    this.sendMail();
  }

  sendMail():void {
    let obj = {
      email: this.email,
      utilityName: this.utility.name
    }
    
    this.accountKycService.sendEmail(obj).subscribe({
      next: (response) => {
        if (response?.status === 200 || response?.status === 'OK') {
          this.toastr.success(response.message);
          this.step++;
        } else {
          this.toastr.error(response?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  tryGetUtilityByName(name: string) {
    this.utilityService.getByName(name).subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK' && responseData.data) {
          this.utility = responseData.data;
        } else {
          this.router.navigate(['']);
        }
      },
      error: (_) => {
        this.router.navigate(['']);
      },
    });
  }
}
