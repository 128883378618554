<div class="edit-customer-root">
  <div class="edit-customers-header">
    <div class="action-buttons">
      <button class="btn btn-header" (click)="downloadInvoice()">
        {{ "COMMON.DOWNLOAD_INVOICE" | translate }}
      </button>
      <button
        *ngIf="customer.isPaid != null && customer.isPaid == false"
        class="btn btn-header"
        (click)="resendInvoice()"
      >
        {{ "COMMON.RESEND_INVOICE" | translate }}
      </button>
      <button
        *ngIf="customer.isPaid != null && customer.isPaid == false"
        class="btn btn-header"
        (click)="confirmPayment()"
      >
        {{ "COMMON.CONFIRM_PAYMENT" | translate }}
      </button>
    </div>
  </div>
  <div class="edit-customers-body">
    <div class="card container">
      <div class="row">
        <div class="col-5">
          {{ "COMMON.MBC_DETAILS.CUSTOMER_NAME" | translate }}
        </div>
        <div class="col-7">{{ customer.customerName | hasValue }}</div>
      </div>
      <div class="row">
        <div class="col-5">
          {{ "COMMON.TABLE_HEADERS.APP_NUM" | translate }}
        </div>
        <div class="col-7">{{ customer.applicationNumber | hasValue }}</div>
      </div>
      <div class="row">
        <div class="col-5">
          {{ "COMMON.CUSTOMER.ACCOUNT_NUMBER" | translate }}
        </div>
        <div class="col-7">{{ customer.accountNumber | hasValue }}</div>
      </div>
      <div class="row">
        <div class="col-5">{{ contractNumber }}</div>
        <div class="col-7">
          {{ customer.spn | hasValue }}
        </div>
      </div>
      <div class="row">
        <div class="col-5">{{ "COMMON.USER.EMAIL" | translate }}</div>
        <div class="col-7">
          {{ customer.email | hasValue }}
        </div>
      </div>
      <div class="row">
        <div class="col-5">{{ "COMMON.MBC_DETAILS.PHONE_NO" | translate }}</div>
        <div class="col-7">
          {{ customer.phoneNumber | hasValue }}
        </div>
      </div>
      <div class="row">
        <div class="col-5">{{ "COMMON.BU.SINGLE" | translate }}</div>
        <div class="col-7">
          {{ customer.area.regionName | hasValue }}
        </div>
      </div>
      <div class="row">
        <div class="col-5">{{ "COMMON.UT.SINGLE" | translate }}</div>
        <div class="col-7">
          {{ customer.area.name | hasValue }}
        </div>
      </div>
    </div>
    <div class="card container">
      <div class="row">
        <div class="col-5">
          {{ "ROLE_MANAGEMENT.DESCRIPTION_SERVICE_CONNECTION" | translate }}
        </div>
        <div class="col-7">
          {{ customer.desctiptionOfServiceConnection | hasValue }}
        </div>
      </div>
      <div class="row">
        <div class="col-5">{{ "COMMON.QUANTITY" | translate }}</div>
        <div class="col-7">
          {{ customer.quantity | hasValue }}
        </div>
      </div>
      <div class="row">
        <div class="col-5">{{ "COMMON.AMOUNT" | translate }}</div>
        <div class="col-7">{{ customer.amount | hasValue }}</div>
      </div>

      <div class="row">
        <div class="col-5">{{ "COMMON.DATE_INVOICE" | translate }}</div>
        <div class="col-7">{{ customer.dateInvoiceWasSent | hasValue }}</div>
      </div>
    </div>
  </div>
</div>
