import { Area } from "./area.model";

export class ServiceConnection {
    id: number;
    customerName: string;
    applicationNumber: string;
    accountNumber?: string | null;
    spn?: string;
    email?: string;
    phoneNumber?: string;
    area: Area;
    desctiptionOfServiceConnection?: string;
    quantity?: string;
    amount?: string;
    dateInvoiceWasSent?: string;
    invoiceUrl?: string;
    siteVerificationId: number;
    isPaid?: boolean = true;

    constructor(data?: any) {
        this.id = data?.id ?? 0;
        this.customerName = data?.customerName ?? 0;
        this.applicationNumber = data?.applicationNumber ?? '';
        this.spn = data?.spn ?? '';
        this.email = data?.email ?? '';
        this.phoneNumber = data?.phoneNumber ?? '';
        this.area = new Area(data?.area);
        this.desctiptionOfServiceConnection = data?.desctiptionOfServiceConnection ?? '';
        this.quantity = data?.quantity ?? '';
        this.amount = data?.amount ?? '';
        this.dateInvoiceWasSent = data?.dateInvoiceWasSent ?? '';
        this.invoiceUrl = data?.invoiceUrl ?? '';
        this.siteVerificationId = data?.siteVerificationId ?? 0;
        this.isPaid = data?.isPaid;
    }
}