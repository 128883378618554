import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {
  KycRejectedImage,
  KycRejectionHeader,
  Rejection,
} from 'src/app/_modules/customers/_models/kyc-rejection';
import { ImageType } from 'src/app/models/image.model';
import {
  KycAccountDetails,
  KycHeader,
  getInitAccount,
  getInitHeader,
} from 'src/app/models/kyc.model';
import { PermissionsEnum } from 'src/app/models/role.model';
import { AccountKycService } from 'src/app/services/kyc/account-kyc.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-kyc-details',
  templateUrl: './kyc-details.component.html',
  styleUrls: ['./kyc-details.component.scss'],
})
export class KycDetailsComponent implements OnInit {
  PermissionsEnum = PermissionsEnum;
  kycHeaderInfo: KycHeader = getInitHeader();

  accountInfo: KycAccountDetails;
  editEnabled: boolean = false;
  onlyView: boolean = false;
  customerId = 0;
  buName: string = this.translationService.getByKeyFromStorage('BU');
  utName: string = this.translationService.getByKeyFromStorage('UT');
  selectedImage: string = '';
  openApproveModal: boolean = false;
  openRejectModal: boolean = false;
  imgElement: any;
  imgZoomFullContainer: any;
  currentRotation: number = 0;
  showApprove: boolean = false;
  showReject: boolean = false;
  informationMissing: boolean = false;
  photosMissing: boolean = false;
  signatureMissing: boolean = false;
  landlordSignatureMissing: boolean = false;
  contractorSignatureMissing: boolean = false;
  applicantSignatureMissing: boolean = false;
  passportPhotoMissing: boolean = false;
  addressWrong: boolean = false;
  loadWrong: boolean = false;
  addressRejection?: string;
  loadRejection?: string;
  filesRejection?: string;
  dtName: string = this.translationService.getByKeyFromStorage('DTNumber');

  constructor(
    public permissionsService: PermissionsService,
    private activatedRoute: ActivatedRoute,
    private accountKycService: AccountKycService,
    private toastr: ToastrService,
    private translationService: TranslationService,
    private el: ElementRef,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    const customerId = this.activatedRoute.snapshot.paramMap.get('id');
    this.customerId = Number(customerId);
    this.imgElement = this.el?.nativeElement?.querySelector(
      '.ngxImageZoomThumbnail'
    );
    this.imgZoomFullContainer =
      this.el?.nativeElement?.querySelector('.ngxImageZoomFull');
    this.getKycAccount(customerId);
  }

  edit() {
    this.editEnabled = true;
  }

  openEditModal() {}

  getMobileUsers() {}

  rotateLeft() {
    this.currentRotation -= 90;
  }
  cancelModal() {
    this.showApprove = false;
    this.showReject = false;
  }
  rotateRight() {
    this.currentRotation += 90;
  }
  approve(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.showApprove = true;
    this.showReject = false;
  }

  reject(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.showApprove = false;
    this.showReject = true;
  }

  showImage(num: number) {
    if (num === 0) {
      this.selectedImage = this.getFrontViewImage(0) || '';
    } else if (num === 1) {
      this.selectedImage = this.getFrontViewImage(1) || '';
    } else if (num === 2) {
      this.selectedImage = this.getFrontViewImage(2) || '';
    } else if (num === 3) {
      this.selectedImage = this.getBuildingMountedImage() || '';
    } else if (num === 4) {
      this.selectedImage =
        this.accountInfo.customerInformation?.cardImageUrl || '';
    } else if (num === 5) {
      this.selectedImage = this.accountInfo.customersSignitureUrl;
    } else if (num === 6) {
      this.selectedImage = this.accountInfo.landlordsSignitureUrl;
    } else if (num === 7) {
      this.selectedImage = this.accountInfo.electricalContractorsSignitureUrl;
    } else if (num === 8) {
      this.selectedImage = this.accountInfo.applicantsSignitureUrl;
    } else if (num === 9) {
      this.selectedImage = this.accountInfo.passportUrl;
    }
  }

  getBuildingMountedImage() {
    return this.accountInfo.customerInformation?.buildingImages?.find(
      (x) => x.imageType === 'MountedTag'
    )?.url;
  }

  getFrontViewImage(id: number) {
    return this.accountInfo.customerInformation?.buildingImages?.filter(
      (x) => x.imageType === 'Frontview'
    )[id]?.url;
  }

  isVisibleField(field: string) {
    var notVisibleFields = localStorage.getItem('notVisibleFields');
    return notVisibleFields?.includes(field) ? false : true;
  }

  onModalFade() {
    this.currentRotation = 0;

    if (this.imgZoomFullContainer) {
      var displayValue = getComputedStyle(this.imgZoomFullContainer).display;

      if (displayValue == 'block') {
        this.imgElement.click();
      }
    }
  }

  onModalOpen() {
    this.renderer.setStyle(document.body, 'overflow', 'auto');
    this.renderer.setStyle(document.body, 'overflow-x', 'hidden');
    this.renderer.setStyle(document.body, 'padding-right', '0');
  }

  private getKycAccount(kycId: any) {
    this.accountKycService.getKycAccountById(kycId).subscribe({
      next: (response) => {
        if (response?.status === 200 || response?.status === 'OK') {
          this.accountInfo = response.data;
          this.populateHeaderInfo();
        } else {
          this.toastr.error(response?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  private populateHeaderInfo() {
    this.kycHeaderInfo.firstName = this.accountInfo.firstName ?? '';
    this.kycHeaderInfo.surname = this.accountInfo.surname;
    this.kycHeaderInfo.slrn =
      this.accountInfo.customerInformation?.building?.slrn;
    this.kycHeaderInfo.address =
      'No.' +
      this.accountInfo.employerAddress?.houseNo +
      ' ' +
      this.accountInfo.employerAddress?.street +
      ', ' +
      this.accountInfo.employerAddress?.landmark;
    this.kycHeaderInfo.phoneNumber =
      '+' +
      this.accountInfo.dialCode +
      this.accountInfo.phoneNumber.replace(/\s/g, '');
    this.kycHeaderInfo.email = this.accountInfo.email;
    this.kycHeaderInfo.applicationNumber = this.accountInfo.applicationNumber;
  }

  cancel(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.showApprove = false;
    this.showReject = false;
  }
  submitApproval() {
    this.accountKycService.approveKycAccount(this.accountInfo.id).subscribe({
      next: (response) => {
        if (response?.status === 200 || response?.status === 'OK') {
          this.toastr.success('Successfully approved customer.');
          this.cancelModal();
          window.location.reload();
        } else {
        }
      },
      error: (_) => {
        this.toastr.error('Error occured.');
      },
    });
  }

  trySubmitRejection() {
    if (this.validateRejection()) {
      this.populateRejections();

      this.accountKycService
        .saveKycRejection({
          accountId: this.accountInfo.id,
          rejections: this.accountInfo.kycRejections,
        })
        .subscribe({
          next: (response) => {
            if (response?.status === 200 || response?.status === 'OK') {
              this.toastr.success('Successfully rejected customer.');
              this.cancelModal();
              window.location.reload();
            } else {
            }
          },
          error: (_) => {
            this.toastr.error('Error occured.');
          },
        });
    }
  }

  onSelectChange(value: string, selectType: string) {
    if (selectType === 'missingInfo') {
      this.informationMissing = value === 'Yes' ? true : false;
    } else if (selectType === 'missingPhotos') {
      this.photosMissing = value === 'Yes' ? true : false;
    }
  }

  populateRejections() {
    this.accountInfo.kycRejections = [];
    if (this.informationMissing) {
      if (this.addressWrong) {
        var rejection = {
          rejectedPage: KycRejectionHeader.CustomerInformation,
          reason: this.addressRejection,
          stepNumber: 2,
        };

        this.accountInfo.kycRejections.push(new Rejection(rejection));
      }

      if (this.loadWrong) {
        var rejection = {
          rejectedPage: KycRejectionHeader.CustomerLoadEstimate,
          reason: this.loadRejection,
          stepNumber: 5,
        };

        this.accountInfo.kycRejections.push(new Rejection(rejection));
      }
    }

    if (this.photosMissing) {
      var rejection = {
        rejectedPage: KycRejectionHeader.CustomerInformation,
        reason: this.filesRejection,
        stepNumber: 8,
      };

      var rejectedImages: KycRejectedImage[] = [];

      if (this.signatureMissing)
        rejectedImages.push(KycRejectedImage.Signature);

      if (this.landlordSignatureMissing)
        rejectedImages.push(KycRejectedImage.LandlordsSignature);
      if (this.contractorSignatureMissing)
        rejectedImages.push(KycRejectedImage.ElectricalContractorsSignature);
      if (this.applicantSignatureMissing)
        rejectedImages.push(KycRejectedImage.ApplicantsSignature);
      if (this.passportPhotoMissing)
        rejectedImages.push(KycRejectedImage.ApplicantsPassportPhotograph);

      if (rejectedImages.length) {
        var newRejection = new Rejection(rejection);
        newRejection.rejectedImages = rejectedImages;
        this.accountInfo.kycRejections.push(newRejection);
      }
    }
  }

  save() {
    this.accountKycService.editKycAccount(this.accountInfo).subscribe({
      next: (response) => {
        if (response?.status === 200 || response?.status === 'OK') {
          this.toastr.success('Successfully updated account details.');
          window.location.reload();
        } else {
        }
      },
      error: (_) => {
        this.toastr.error('Error occured.');
      },
    });
    this.editEnabled = false;
  }
  public hideWord(w: any) {
    if (w) {
      if (w.length < 3) return w;
      return w.substring(0, 3) + '*'.repeat(w.length - 3);
    }
  }
  validateRejection() {
    if (!this.addressWrong && !this.loadWrong && !this.photosMissing) {
      this.toastr.error(
        'You have to specify why you are rejecting this account.'
      );
      return false;
    } else {
      if (this.addressWrong && !this.addressRejection) {
        this.toastr.error(
          'You have to specify the reason for address being wrong.'
        );
        return false;
      }

      if (this.loadWrong && !this.loadRejection) {
        this.toastr.error(
          'You have to specify the reason for Load Requirement being wrong.'
        );
        return false;
      }

      if (this.photosMissing) {
        if (
          !this.signatureMissing &&
          !this.landlordSignatureMissing &&
          !this.contractorSignatureMissing &&
          !this.applicantSignatureMissing &&
          !this.passportPhotoMissing
        ) {
          this.toastr.error(
            'You have to specify the images that need to be re-uploaded.'
          );
          return false;
        } else if (!this.filesRejection) {
          this.toastr.error(
            'You have to specify the reason for the rejection of the uploaded images.'
          );
          return false;
        }
      }
    }

    return true;
  }
}
