<div class="registration">
  <app-login-header></app-login-header>
  <div *ngIf="!typeOfCustomer">
    <div class="container text-center">
      <div class="select-category">Select your Category</div>
      <div class="select-option">Select an option to continue</div>
      <div class="row new-existing">
        <div class="col-6 option option-new" (click)="setCategory(1)">
          <div>
            <svg width="39" height="38" viewBox="0 0 39 38" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="0.5" width="38" height="38" rx="12" fill="#00423E" fill-opacity="0.1"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M15.5 18C17.7091 18 19.5 16.2091 19.5 14C19.5 11.7909 17.7091 10 15.5 10C13.2909 10 11.5 11.7909 11.5 14C11.5 16.2091 13.2909 18 15.5 18ZM15.5 16C16.6046 16 17.5 15.1046 17.5 14C17.5 12.8954 16.6046 12 15.5 12C14.3954 12 13.5 12.8954 13.5 14C13.5 15.1046 14.3954 16 15.5 16ZM18.5 21C19.0523 21 19.5 21.4477 19.5 22V28H21.5V22C21.5 20.3431 20.1569 19 18.5 19H12.5C10.8432 19 9.5 20.3431 9.5 22V28H11.5V22C11.5 21.4477 11.9477 21 12.5 21H18.5ZM27.5 14H25.5V16H23.5V18H25.5V20H27.5V18H29.5V16H27.5V14Z" fill="#171717"/>
            </svg>
          </div>
          New Customer
        </div>
        <div class="col-6 option option-existing" (click)="setCategory(2)"> 
          <div>
            <svg width="39" height="38" viewBox="0 0 39 38" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="0.5" width="38" height="38" rx="12" fill="#8F92A1" fill-opacity="0.1"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M23.5 14C23.5 16.2091 21.7091 18 19.5 18C17.2909 18 15.5 16.2091 15.5 14C15.5 11.7909 17.2909 10 19.5 10C21.7091 10 23.5 11.7909 23.5 14ZM21.5 14C21.5 15.1046 20.6046 16 19.5 16C18.3954 16 17.5 15.1046 17.5 14C17.5 12.8954 18.3954 12 19.5 12C20.6046 12 21.5 12.8954 21.5 14Z" fill="#171717"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M23.5 22C23.5 21.4477 23.0523 21 22.5 21H16.5C15.9477 21 15.5 21.4477 15.5 22V28H13.5V22C13.5 20.3431 14.8432 19 16.5 19H22.5C24.1569 19 25.5 20.3431 25.5 22V28H23.5V22Z" fill="#171717"/>
            </svg>
          </div>
          Existing Customer
        </div>
      </div>
      <div class="help text-size" *ngIf="utility">
        Already have an account?
        <a class="link" [routerLink]="['/new-connection/' + utility.name + '/login']">Log in</a>
      </div>
    </div>
  </div>
  <div *ngIf="typeOfCustomer">
    <div class="register-container">
    
      <div class="register-page">
        <div class="page-wrap">
          <div class="form-wrapper">
            <h1>Create a new account</h1>
    
            <div class="info row">
              <div class="col-3">
                <svg
                  class="icon-bg"
                  clip-rule="evenodd"
                  fill-rule="evenodd"
                  stroke-linejoin="round"
                  stroke-miterlimit="2"
                  height="32"
                  width="32"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="m12.002 2.005c5.518 0 9.998 4.48 9.998 9.997 0 5.518-4.48 9.998-9.998 9.998-5.517 0-9.997-4.48-9.997-9.998 0-5.517 4.48-9.997 9.997-9.997zm0 1.5c-4.69 0-8.497 3.807-8.497 8.497s3.807 8.498 8.497 8.498 8.498-3.808 8.498-8.498-3.808-8.497-8.498-8.497zm0 6.5c-.414 0-.75.336-.75.75v5.5c0 .414.336.75.75.75s.75-.336.75-.75v-5.5c0-.414-.336-.75-.75-.75zm-.002-3c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1z"
                    fill-rule="nonzero"
                  />
                </svg>
              </div>
              <span class="col-8"
                >Complete the fields below to create your security credentials which
                will be required for subsequent login.</span
              >
            </div>
            <form ngNativeVAlidate (submit)="register()">
              <div *ngIf="typeOfCustomer == 2">
                <div class="form-item" style="position: relative">
                  <label for="floatingInput">Account Number <span class="red">*</span></label>
                  <input
                    [ngClass]="{ invalidAcc: invalidAccNo == true }"
                    name="accountNumber"
                    type="text"
                    class="form-control"
                    id="floatingInput"
                    placeholder="Account Number"
                    [(ngModel)]="accountNumber"
                    (blur)="checkAccountNumber()"
                  />
                  <div class="check-approved">
                    <ng-template [ngIf]="validAccNo">
                      <img
                        alt="Check icon"
                        width="22"
                        height="22"
                        src="../../../assets/icons/check-circle.svg"
                      />
                    </ng-template>
                  </div>
                </div>
                <ng-template [ngIf]="invalidAccNo">
                  <div>
                    <span class="invalid-text">This account doesn't exist.</span>
                  </div>
                </ng-template>
      
                <ng-template [ngIf]="validAccNo">
                  <div class="validAcc">
                    <span class="text"> Customer Name</span>
                    <br />
                    <span class="customer-name">{{ customerName }}</span>
                  </div>
                </ng-template>
              </div>
              <div class="form-item">
                <label for="floatingInput">Email <span class="red">*</span> </label>
                <input
                  name="email"
                  type="text"
                  class="form-control"
                  id="floatingInput"
                  placeholder="Email"
                  [(ngModel)]="email"
                  required
                />
              </div>
              <div class="form-item" style="display: block !important">
                <label for="floatingInput">Phone Number <span class="red">*</span></label>
                <br />
                <input
                  id="floatingInput"
                  type="tel"
                  class="form-control"
                  (blur)="onBlur()"
                  (keypress)="onInputKeyPress($event)"
                  [(ngModel)]="phoneNumber"
                  #telInput
                  name="tellInput"
                />
              </div>
              <div class="form-item" style="position: relative">
                <label for="floatingPassword">Password <span class="red">*</span></label>
                <input
                  [(ngModel)]="pass"
                  name="password"
                  [type]="showPw ? 'text' : 'password'"
                  class="form-control"
                  id="floatingPassword"
                  placeholder="Password"
                  required
                />
                <div class="show-pw" (click)="showPassword()">
                  <ng-template [ngIf]="showPw">
                    <img
                      src="../../../assets/icons/eye.svg"
                      alt="Show password icon"
                    />
                  </ng-template>
                  <ng-template [ngIf]="!showPw">
                    <img
                      src="../../../assets/icons/eye-slash.svg"
                      alt="Show password icon"
                    />
                  </ng-template>
                </div>
                <app-password-strength
                  [passwordToCheck]="pass"
                  (passwordStrength)="onPasswordStrengthChanged($event)"
                ></app-password-strength>
              </div>
              <button class="btn btn-dark text-size" type="submit">Create an account</button>
            </form>
            <div class="help text-size">
              Already have an account?
              <a class="link" [routerLink]="['/new-connection/' + utility.name + '/login']">Log in</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<button type="button" #openRegisterSuccessModal hidden data-bs-toggle="modal" data-bs-target="#registerSuccess"></button>

<div class="modal fade" id="registerSuccess" tabindex="-1" aria-labelledby="registerSuccessTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header close-reg-modal">
        <div class="header-x" #closeModal data-bs-dismiss="modal" aria-label="Close" (click)="closeSuccessfullRegistrationModal()">
          <img src="../../../assets/solid.svg" alt="" />
        </div>
      </div>
      <div class="content text-center">
        <div>
          <svg width="82" height="81" viewBox="0 0 82 81" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" width="81" height="81" rx="40.5" fill="#D6FFEB"/>
            <path d="M45.308 35.4168L38.5155 42.2252L35.903 39.6127C35.761 39.4469 35.5864 39.3123 35.3899 39.2173C35.1935 39.1222 34.9795 39.0688 34.7615 39.0604C34.5434 39.052 34.326 39.0887 34.1228 39.1683C33.9196 39.2479 33.7351 39.3687 33.5808 39.523C33.4265 39.6773 33.3057 39.8618 33.2261 40.065C33.1465 40.2682 33.1098 40.4856 33.1182 40.7037C33.1266 40.9217 33.18 41.1357 33.2751 41.3321C33.3701 41.5286 33.5047 41.7032 33.6705 41.8452L37.3913 45.5819C37.5393 45.7286 37.7147 45.8447 37.9076 45.9235C38.1005 46.0023 38.3071 46.0422 38.5155 46.041C38.9308 46.0393 39.3289 45.8744 39.6238 45.5819L47.5405 37.6652C47.6889 37.518 47.8067 37.3429 47.8871 37.1499C47.9674 36.957 48.0088 36.75 48.0088 36.541C48.0088 36.332 47.9674 36.125 47.8871 35.9321C47.8067 35.7392 47.6889 35.564 47.5405 35.4168C47.2438 35.122 46.8425 34.9564 46.4242 34.9564C46.0059 34.9564 45.6046 35.122 45.308 35.4168ZM41.0013 24.666C37.8698 24.666 34.8086 25.5946 32.2048 27.3344C29.601 29.0742 27.5716 31.547 26.3732 34.4402C25.1748 37.3334 24.8613 40.5169 25.4722 43.5883C26.0831 46.6596 27.5911 49.4809 29.8055 51.6952C32.0198 53.9095 34.841 55.4175 37.9124 56.0285C40.9837 56.6394 44.1673 56.3258 47.0605 55.1274C49.9536 53.9291 52.4265 51.8997 54.1662 49.2959C55.906 46.6921 56.8346 43.6309 56.8346 40.4993C56.8346 38.4201 56.4251 36.3612 55.6294 34.4402C54.8337 32.5192 53.6674 30.7738 52.1972 29.3035C50.7269 27.8332 48.9815 26.667 47.0605 25.8713C45.1395 25.0756 43.0806 24.666 41.0013 24.666ZM41.0013 53.166C38.4961 53.166 36.0471 52.4231 33.9641 51.0313C31.8811 49.6395 30.2575 47.6612 29.2988 45.3467C28.3401 43.0321 28.0893 40.4853 28.578 38.0282C29.0668 35.5711 30.2732 33.3141 32.0446 31.5427C33.8161 29.7712 36.0731 28.5648 38.5302 28.0761C40.9873 27.5873 43.5341 27.8382 45.8486 28.7969C48.1632 29.7556 50.1414 31.3791 51.5333 33.4621C52.9251 35.5451 53.668 37.9941 53.668 40.4993C53.668 43.8588 52.3335 47.0806 49.958 49.456C47.5825 51.8315 44.3607 53.166 41.0013 53.166Z" fill="#21935C"/>
          </svg>
        </div>
        <div class="reg-title">
          Account created successfully
        </div>
        <div class="proceed-div">
          <button class="btn" (click)="proceedToLogin()">Proceed to Login</button>
        </div>
      </div>
      
        
    </div>
  </div>
</div>