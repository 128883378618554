import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { fromEvent, Observable, Subject, takeUntil } from 'rxjs';
import { AuditLogDto } from 'src/app/models/auditLog.model';
import { PermissionsEnum } from 'src/app/models/role.model';
import { AuditLogService } from 'src/app/services/audit-log.service';
import { PermissionsService } from 'src/app/services/permissions.service';

@Component({
  selector: 'app-audit-log',
  templateUrl: './audit-log.component.html',
  styleUrls: ['./audit-log.component.scss'],
})
export class AuditLogComponent implements OnInit {
  auditLogs: AuditLogDto[] = [];
  selectedUtilities: number[] = [];
  pages: string[] = [];
  actions: string[] = [];

  dateTo: string = new Date().toISOString().slice(0, 10);
  startDate = new Date();
  temp = this.startDate.setDate(this.startDate.getDate() - 7);
  dateFrom: string = this.startDate.toISOString().slice(0, 10);

  count: number = 2;
  pageSize: number = 10;
  currentPage: number = 1;

  searchText: string = '';
  page?: string = '';
  action?: string = '';

  showUpload: boolean = false;
  showSelect: boolean = true;
  clickObservable: Observable<Event> = fromEvent(document, 'click');
  private ngUnsubscribe = new Subject<void>();
  public PermissionsEnum = PermissionsEnum;

  currentTab: number = 0;
  reset: boolean = false;

  constructor(
    private auditLogService: AuditLogService,
    private toastr: ToastrService,
    public permissionsService: PermissionsService,
  ) {}

  ngOnInit(): void {
    this.checkUtilityId();
    this.subscribeToClickEvent();
    this.getPagesAndActions();
  }

  changeTab(page: number) {
    this.currentTab = page;
    this.searchText = '';
    this.reloadTable();
  }

  checkUtilityId() {
    var x = localStorage.getItem('utilityId');
    if (x) {
      var utility = +x;
      if(utility!=0){
        this.showSelect=false;
        this.selectedUtilities = [utility];
        this.reloadTable(1);
      }
    }
  }
  selectUtilities(event: any) {
    this.selectedUtilities = event;
    this.reloadTable(1);
  }

  selectPage(e: any) {
    if (e.target.value != '') {
      this.page = e.target.value;
    } else {
      this.page = undefined;
    }
    this.reloadTable(1);
  }
  selectAction(e: any) {
    if (e.target.value != '') {
      this.action = e.target.value;
    } else {
      this.action = undefined;
    }
    this.reloadTable(1);
  }

  private getAll(obj: any) {
    this.auditLogService.getAll(obj).subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.auditLogs = responseData.data.data;
          this.count = responseData.data.count;
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => this.toastr.error('Error occured'),
    });
  }

  private getPagesAndActions(){
    this.auditLogService.getPagesAndActions().subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {  
          this.pages = responseData.data.pages;
          this.actions = responseData.data.actions;
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => this.toastr.error('Error occured'),
    });
  }

  reloadTable(page: any = null) {
    if (page) this.currentPage = page;

    let obj = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize,
      },
      filterParams: this.getFilterParams(),
    };

    this.getAll(obj);
  }

  getFilterParams() {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    console.log(`Client's Timezone: ${timeZone}`);
    return {
      isMobileType: this.currentTab == 0 ? false : true,
      dateFrom: this.dateFrom ? this.dateFrom : null,
      dateTo: this.dateTo ? `${this.dateTo}T23:59:59` : null,
      utilityIds: this.selectedUtilities,
      timezoneValue: timeZone,
      page: this.page,
      action: this.action,
    };
  }

  search() {
    this.reloadTable(1);
  }

  pageChange(value: any) {
    this.currentPage = value;
    this.reloadTable();
  }

  private subscribeToClickEvent() {
    this.clickObservable
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((e: any) => {
        this.showUpload = false;
      });
  }

  download() {
    if(this.datesValid()) {
      let obj = {
        pageInfo: {
          page: -1,
          pageSize: this.pageSize,
        },
        filterParams: this.getFilterParams(),
      };    
      this.auditLogService.download(obj).subscribe({
        next: (response) => {
          const data = response.body;
          if (data?.status === 'OK' || data?.status === '200') {
            window.location.href = data.data;
          } else this.toastr.error(data?.message || 'Request failed');
        },
        error: (error) => console.log(error),
      });
    }
  }

  datesValid() {
    if (this.dateFrom && this.dateTo) {
      const dateFrom = new Date(this.dateFrom);
      const dateTo = new Date(`${this.dateTo.toString()}T23:59:59`);
  
      const valid = dateFrom.getTime() < dateTo.getTime();
      const dayDifference = Math.floor((dateTo.getTime() - dateFrom.getTime()) / (1000 * 3600 * 24));

      if(dayDifference > 7) {
        this.toastr.warning('Unable to get results for more than 7 days. Please specify your filter.')
      }
      return valid && dayDifference <= 7;
    }

    return false;
  }
}
