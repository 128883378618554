<div class="edit-account-root">
    <div class="edit-account-header">
      <div class="user-header-info">
        <div class="user-info">
          <div class="name">
            {{
              kycHeaderInfo?.firstName + " " + kycHeaderInfo?.surname | hasValue
            }}
          </div>
          <div class="slrn">
            {{ kycHeaderInfo?.slrn ? kycHeaderInfo?.slrn : ("COMMON.NO_SLRN" | translate) }}
          </div>
          <div class="application-number">
            {{ kycHeaderInfo?.applicationNumber }}
          </div>
          <div class="row">
            <div
              class="entry-status new-entry col-12"
              *ngIf="!accountInfo?.accountNumber"
            >
              {{'COMMON.NEW_ENTRY' | translate}}
            </div>
            <div
              class="entry-status existing-entry col-12"
              *ngIf="accountInfo?.accountNumber"
            >
              {{'COMMON.EXISTING' | translate}}
            </div>
          </div>
          <div *ngIf="accountInfo?.customerInformation" class="row">
            <div class="customer-type col-12">
              {{ accountInfo?.customerInformation?.buildingType | hasValue }}
            </div>
          </div>
          <div class="row">
            <div
              [ngClass]="{
                'pending-account': accountInfo?.currentStatus === 'Pending',
                'rejected-account': accountInfo?.currentStatus === 'Rejected',
                'complete-account': accountInfo?.currentStatus === 'Complete',
                'incomplete-account': accountInfo?.currentStatus === 'Incomplete' || accountInfo?.currentStatus === 'InProgress'
              }"
              class="col-12 account-status"
              *ngIf="accountInfo?.currentStatus"
            >
              {{ 'COMMON.KYC_STATUS_OPTIONS.' + accountInfo?.currentStatus | uppercase | translate }}
            </div>
          </div>
        </div>
        <div class="user-address">
          <div>{{ kycHeaderInfo?.email | hasValue }}</div>
          <div>
            {{ kycHeaderInfo?.phoneNumber | hasValue }}
          </div>
          <div>
            {{ kycHeaderInfo?.address | hasValue }}
          </div>
        </div>
      </div>
      <div class="action-buttons" *ngIf="!onlyView">
        <button
          class="btn btn-header"
          data-bs-toggle="modal"
          data-bs-target="#updateStatus"
          [disabled]="
            !permissionsService.isCustomerApproveRejectAllowed ||
            accountInfo?.currentStatus !== 'Pending'
          "
        >
          {{'COMMON.UPDATE_STATUS' | translate}}
        </button>
        <button
          class="btn btn-header"
          *ngIf="
            !editEnabled &&
            permissionsService.isCustomerCreateEditAllowed
          "
          (click)="edit()"
        >
          {{'COMMON.EDIT' | translate}}
        </button>
        <button class="btn btn-primary" *ngIf="editEnabled" (click)="save()">
          {{'COMMON.SAVE' | translate}}
        </button>
      </div>
    </div>
    <div class="edit-accounts-body row">
      <div class="col-4">
        <div class="row">
          <div class="card container col-11">
            <div class="row">
              <div class="col-5">{{'COMMON.SLRN' | translate}}</div>
              <div class="col-7">
                {{ accountInfo?.customerInformation?.building?.slrn | hasValue }}
              </div>
            </div>
            <div class="row">
              <div class="col-4">{{'COMMON.MBC_DETAILS.CUSTOMER_ADDRESS' | translate}}</div>
              <div *ngIf="!editEnabled" class="col-8">
                <ng-container *ngIf="accountInfo?.employerAddress?.houseNo">
                  {{'COMMON.NO' | translate}}.
                </ng-container>
                {{ accountInfo?.employerAddress?.houseNo | hasValue }}
                {{ accountInfo?.employerAddress?.street | hasValue }},
                {{ accountInfo?.employerAddress?.landmark | hasValue }}
              </div>
              <input
                [placeholder]="('COMMON.NO' | translate) + '.'"
                *ngIf="editEnabled"
                class="info-edit col-2"
                [(ngModel)]="accountInfo.employerAddress.houseNo"
              />
              <input
                [placeholder]="'COMMON.STREET_NAME' | translate"
                *ngIf="editEnabled"
                class="col-6 info-edit address-edit-street"
                [(ngModel)]="accountInfo.employerAddress.street"
              />
            </div>
            <div class="row">
              <div class="col-5">{{'COMMON.BU.SINGLE' | translate}}</div>
              <div class="col-7">
                {{
                  accountInfo?.customerInformation?.area?.regionName | hasValue
                }}
              </div>
            </div>
            <div class="row">
              <div class="col-5">{{'COMMON.UT.SINGLE' | translate}}</div>
              <div class="col-7">
                {{ accountInfo?.customerInformation?.area?.name | hasValue }}
              </div>
            </div>
            <div class="row">
              <div class="col-5">{{'COMMON.MBC_DETAILS.PHYSICAL_ADDRESS' | translate}}</div>
              <div
                *ngIf="
                  !editEnabled || !accountInfo?.customerInformation
                "
                class="col-7"
              >
                {{ accountInfo?.customerInformation?.physicalAddress | hasValue }}
              </div>
              <input
                *ngIf="editEnabled && accountInfo?.customerInformation"
                class="col-7 info-edit"
                [(ngModel)]="accountInfo.customerInformation.physicalAddress"
              />
            </div>
            <div class="row">
              <div class="col-5">{{'COMMON.MBC_DETAILS.GEO_LOCATION' | translate}}</div>
              <div class="locationText col-6">
                {{ accountInfo?.customerInformation?.building?.latitude }},
                {{ accountInfo?.customerInformation?.building?.longitude }}
              </div>
              <div class="location col-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 500 500"
                  data-bs-toggle="modal"
                  data-bs-target="#showMap"
                >
                  <path
                    d="M256 0c17.7 0 32 14.3 32 32V66.7C368.4 80.1 431.9 143.6 445.3 224H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H445.3C431.9 368.4 368.4 431.9 288 445.3V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V445.3C143.6 431.9 80.1 368.4 66.7 288H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H66.7C80.1 143.6 143.6 80.1 224 66.7V32c0-17.7 14.3-32 32-32zM128 256c0 70.7 57.3 128 128 128s128-57.3 128-128s-57.3-128-128-128s-128 57.3-128 128zm128 80c-44.2 0-80-35.8-80-80s35.8-80 80-80s80 35.8 80 80s-35.8 80-80 80z"
                    fill="#026962"
                  />
                </svg>
                <!-- <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addNewF33">Locate</button> -->
              </div>
            </div>
            <div class="row">
              <div class="col-5">{{'COMMON.MBC_DETAILS.FEEDER_NAME' | translate}}</div>
              <div class="col-7">
                {{
                  accountInfo?.customerInformation?.dt?.feeder?.name | hasValue
                }}
              </div>
            </div>
            <div class="row">
              <div class="col-5">{{'COMMON.MBC_DETAILS.FEEDER_NUMBER' | translate}}</div>
              <div class="col-7">
                {{
                  accountInfo?.customerInformation?.dt?.feeder?.number | hasValue
                }}
              </div>
            </div>
            <div class="row">
              <div class="col-4">{{'COMMON.DT_NAME' | translate}}</div>
              <div class="col-8">
                {{ accountInfo?.customerInformation?.dt?.name | hasValue }}
              </div>
            </div>
            <div class="row">
              <div class="col-5">{{'COMMON.DT_NUMBER' | translate}}</div>
              <div class="col-7">
                {{ accountInfo?.customerInformation?.dt?.number | hasValue }}
              </div>
            </div>
          </div>
          <div class="card container col-11">
            <label class="section-label">{{'PERSONAL_DATA.TITLE' | translate}}</label>
            <div class="row">
              <div class="col-4">{{'PERSONAL_DATA.BIRTH_DATE' | translate}}</div>
  
              <div class="col-8">
                {{ accountInfo?.dateOfBirth | date : "dd/MM/yyyy" }}
              </div>
            </div>
            <div class="row">
              <div class="col-5">{{'PERSONAL_DATA.CUSTOMER_PHONE_NO' | translate}}</div>
              <div *ngIf="!editEnabled" class="col-7">
                <p>
                  <ng-container *ngIf="accountInfo?.buildingOwnerDialCode">
                    +
                  </ng-container>
                  {{ accountInfo?.dialCode | hasValue }}
                  {{ accountInfo?.phoneNumber | hasValue }}
                </p>
              </div>
              <input
                [placeholder]="'PERSONAL_DATA.CODE' | translate"
                *ngIf="editEnabled"
                class="info-edit col-2"
                [(ngModel)]="accountInfo.dialCode"
              />
              <input
                [placeholder]="'PERSONAL_DATA.PHONE_NO' | translate"
                *ngIf="editEnabled"
                class="info-edit col-5"
                [(ngModel)]="accountInfo.phoneNumber"
              />
            </div>
            <div class="row">
              <div class="col-5">{{'PERSONAL_DATA.CUSTOMER_EMAIL_ADDRESS' | translate}}</div>
              <div *ngIf="!editEnabled" class="col-7">
                {{ accountInfo?.email }}
              </div>
              <input
                [placeholder]="'PERSONAL_DATA.CODE' | translate"
                *ngIf="editEnabled"
                class="info-edit col-7"
                [(ngModel)]="accountInfo.email"
              />
            </div>
            <div class="row">
              <div class="col-5">{{'PERSONAL_DATA.EMPLOYER_NAME' | translate}}</div>
              <div class="col-7">
                {{ accountInfo?.nameOfEmployer | hasValue }}
              </div>
            </div>
  
            <div class="row">
              <div class="col-5">{{'PERSONAL_DATA.EMPLOYER_ADDRESS' | translate}}</div>
  
              <div class="col-7">
                <ng-container *ngIf="accountInfo?.employerAddress?.houseNo">
                  {{'COMMON.NO' | translate}}.
                </ng-container>
                {{ accountInfo?.employerAddress?.houseNo | hasValue }}
                {{ accountInfo?.employerAddress?.street | hasValue }},
                {{ accountInfo?.employerAddress?.landmark | hasValue }}
              </div>
            </div>
            <div class="row">
              <div class="col-5">{{'PERSONAL_DATA.CUSTOMER_METER_NO' | translate}}</div>
  
              <div *ngIf="!editEnabled" class="col-7">
                {{ accountInfo?.meterNumberLandlord | hasValue }}
              </div>
              <input
                [placeholder]="('COMMON.NO' | translate) + '.'"
                *ngIf="editEnabled"
                class="info-edit col-7"
                [(ngModel)]="accountInfo.meterNumberLandlord"
              />
            </div>
            <div class="row">
              <div class="col-7">{{'PERSONAL_DATA.CUSTOMER_ACC_NO_FOR_PREMISES' | translate}}</div>
              <div *ngIf="!editEnabled" class="col-5">
                {{ accountInfo?.accountNumberForPremissesLandlord | hasValue }}
              </div>
              <input
                [placeholder]="('COMMON.NO' | translate) + '.'"
                *ngIf="editEnabled"
                class="info-edit col-5"
                [(ngModel)]="accountInfo.accountNumberForPremissesLandlord"
              />
            </div>
            <div class="row">
              <div class="col-5">{{'PERSONAL_DATA.CUSTOMER_ID' | translate}}</div>
  
              <div class="col-7">
                {{ accountInfo?.identificationOfLandlord | hasValue }}
              </div>
            </div>
            <div class="row">
              <div class="col-5">{{'PERSONAL_DATA.PERSONAL_ID_NO' | translate}}</div>
  
              <div class="col-7">
                {{ accountInfo?.personalIdNumberOfLandlord | hasValue }}
              </div>
            </div>
            <div class="row">
              <div class="col-5">{{'PERSONAL_DATA.BANK_VERIFICATION_NO' | translate}}</div>
  
              <div class="col-7">
                {{ hideWord(accountInfo?.bankVerificationNumber) | hasValue }}
              </div>
            </div>
            <div class="row">
              <div [ngClass]="{ 'col-4': editEnabled, 'col-6': !editEnabled }">
                {{'PERSONAL_DATA.REQUIRED_SUPPLY_ADDRESS' | translate}}
              </div>
              <div *ngIf="!editEnabled" class="col-6">
                <ng-container *ngIf="accountInfo?.addressOfLandlord?.houseNo">
                  {{'COMMON.NO' | translate}}.
                </ng-container>
                {{ accountInfo?.addressOfLandlord?.houseNo | hasValue }}
                {{ accountInfo?.addressOfLandlord?.street | hasValue }},
                {{ accountInfo?.addressOfLandlord?.landmark | hasValue }}
              </div>
              <input
                [placeholder]="('COMMON.NO' | translate) + '.'"
                *ngIf="editEnabled"
                class="info-edit col-2"
                [(ngModel)]="accountInfo.addressOfLandlord.houseNo"
              />
              <input
                [placeholder]="'COMMON.STREET_NAME' | translate"
                *ngIf="editEnabled"
                class="col-6 info-edit address-edit-street"
                [(ngModel)]="accountInfo.addressOfLandlord.street"
              />
            </div>
            <div class="row">
              <div class="col-4">{{'PERSONAL_DATA.PREMISES_TYPE' | translate}}</div>
  
              <div class="col-8">
                {{ accountInfo?.typeOfPremises?.toString() | hasValue }}
              </div>
            </div>
            <div class="row">
              <div class="col-6">{{'PERSONAL_DATA.CUSTOMER_PREV_ADDRESS' | translate}}</div>
  
              <div class="col-6">
                <ng-container
                  *ngIf="accountInfo?.addressPreviousCustomer?.houseNo"
                >
                  {{'COMMON.NO' | translate}}.
                </ng-container>
                {{ accountInfo?.addressPreviousCustomer?.houseNo | hasValue }}
                {{ accountInfo?.addressPreviousCustomer?.street | hasValue }},
                {{ accountInfo?.addressPreviousCustomer?.landmark | hasValue }}
              </div>
            </div>
            <div class="row">
              <div class="col-5">{{'COMMON.PREV_METER_NUM' | translate}}</div>
  
              <div class="col-7">
                {{ accountInfo?.meterNumberPreviousCustomer | hasValue }}
              </div>
            </div>
            <div class="row">
              <div class="col-5">{{'PERSONAL_DATA.PREV_ACC_NO' | translate}}</div>
  
              <div class="col-7">
                {{ accountInfo?.accountNumberPreviousCustomer | hasValue }}
              </div>
            </div>
            <div class="row">
              <div class="col-6">{{'PERSONAL_DATA.BUILDING_OWNER_NAME' | translate}}</div>
  
              <div *ngIf="!editEnabled" class="col-6">
                {{ accountInfo?.buildingOwner | hasValue }}
              </div>
              <input
                [placeholder]="'PERSONAL_DATA.BUILDING_OWNER_NAME' | translate"
                *ngIf="editEnabled"
                class="info-edit col-6"
                [(ngModel)]="accountInfo.buildingOwner"
              />
            </div>
            <div class="row">
              <div class="col-6">{{'PERSONAL_DATA.BUILDING_OWNER_ADDRESS' | translate}}</div>
  
              <div class="col-6">
                <ng-container *ngIf="accountInfo?.buildingOwnerAddress?.houseNo">
                  {{'COMMON.NO' | translate}}.
                </ng-container>
                {{ accountInfo?.buildingOwnerAddress?.houseNo | hasValue }}
                {{ accountInfo?.buildingOwnerAddress?.street | hasValue }},
                {{ accountInfo?.buildingOwnerAddress?.landmark | hasValue }}
              </div>
            </div>
            <div class="row">
              <div class="col-7">
                {{'PERSONAL_DATA.BUILDING_OWNER_PHONE_NO' | translate}}
              </div>
  
              <div class="col-5">
                <p>
                  <ng-container *ngIf="accountInfo?.buildingOwnerDialCode">
                    +
                  </ng-container>
                  {{ accountInfo?.buildingOwnerDialCode | hasValue }}
                  {{ accountInfo?.buildingOwnerPhoneNumber | hasValue }}
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-5">{{'PERSONAL_DATA.BUILDING_OWNER_ID' | translate}}</div>
  
              <div class="col-7">
                {{ accountInfo?.identificationOfBuildingOwner | hasValue }}
              </div>
            </div>
            <div class="row">
              <div class="col-6">{{'PERSONAL_DATA.BUILDING_OWNER_ID_NO' | translate}}</div>
  
              <div class="col-6">
                {{ accountInfo?.personalIdNumberOfBuildingOwner | hasValue }}
              </div>
            </div>
            <div class="row">
              <div class="col-8">{{'PERSONAL_DATA.EMPLOYER_BUILDING_OWNER_NAME' | translate}}</div>
  
              <div class="col-4">
                {{ accountInfo?.nameOfEmployerOfBuildingOwner | hasValue }}
              </div>
            </div>
            <div class="row">
              <div class="col-7">{{'PERSONAL_DATA.EMPLOYER_BUILDING_OWNER_ADDRESS' | translate}}</div>
  
              <div class="col-5">
                <ng-container
                  *ngIf="accountInfo?.employerAddressOfBuildingOwner?.houseNo"
                >
                  {{'COMMON.NO' | translate}}.
                </ng-container>
                {{ accountInfo?.employerAddressOfBuildingOwner?.houseNo | hasValue }}
                {{ accountInfo?.employerAddressOfBuildingOwner?.street | hasValue }},
                {{ accountInfo?.employerAddressOfBuildingOwner?.landmark | hasValue }}
              </div>
            </div>
            <div class="row">
              <div class="col-6">{{'PERSONAL_DATA.EMPLOYER_PHONE_NO' | translate}}</div>
  
              <div class="col-6">
                <p>
                  <ng-container *ngIf="accountInfo?.buildingOwnerEmployerDialCode">
                    +
                  </ng-container>
                  {{ accountInfo?.buildingOwnerEmployerDialCode | hasValue }}
                  {{ accountInfo?.buildingOwnerEmployerPhoneNumber | hasValue }}
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-6">{{'PERSONAL_DATA.EMPLOYER_EMAIL' | translate}}</div>
  
              <div class="col-6">
                {{ accountInfo?.buildingOwnerEmail | hasValue }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="row">
          <div class="card container col-11">
            <div class="row">
              <div class="col-4">{{'COMMON.MBC_DETAILS.CUSTOMER_NAME' | translate}}</div>
              <div *ngIf="!editEnabled" class="col-8">
                {{
                  (accountInfo?.firstName ?? '') + " " + accountInfo?.surname | hasValue
                }}
              </div>
              <ng-container *ngIf="editEnabled">
                <input
                  [placeholder]="'COMMON.FIRST_NAME' | translate"
                  class="col-4 info-edit"
                  type="text"
                  [(ngModel)]="accountInfo.firstName"
                />
                <input
                  [placeholder]="'COMMON.SURNAME' | translate"
                  class="col-4 info-edit"
                  type="text"
                  [(ngModel)]="accountInfo.surname"
                />
              </ng-container>
            </div>
            <div class="row">
              <div class="col-4">{{'COMMON.MBC_DETAILS.NUMBER_IN_HOUSE' | translate}}</div>
  
              <div class="col-8">
                {{
                  accountInfo?.customerInformation?.numberOfHouseholds | hasValue
                }}
              </div>
            </div>
            <div class="row">
              <div class="col-4">{{'COMMON.USE_OF_PREMISES' | translate}}</div>
  
              <div class="col-8">
                {{ accountInfo?.customerInformation?.buildingType | hasValue }}
              </div>
            </div>
            <div class="row">
              <div class="col-4">{{'COMMON.MBC_DETAILS.ID_CARD_NUM' | translate}}</div>
  
              <div class="col-8">
                {{ accountInfo?.customerInformation?.cardNumber | hasValue }}
              </div>
            </div>
            <div class="row">
              <div class="col-4">{{'COMMON.CREATION_DATE' | translate}}</div>
  
              <div class="col-8">
                {{
                  accountInfo?.customerInformation?.building?.creationDate
                    | hasValue
                }}
              </div>
            </div>
            <div class="row">
              <div class="col-4">{{'COMMON.USER.TYPE.FIRST_CAPTURED' | translate}}</div>
  
              <div class="col-8">
                {{
                  accountInfo?.customerInformation?.building?.firstCapturedUser
                    | hasValue
                }}
              </div>
            </div>
            <div class="row">
              <div class="col-4">{{'COMMON.USER.TYPE.LAST_CAPTURED' | translate}}</div>
  
              <div class="col-8">
                {{
                  accountInfo?.customerInformation?.building?.lastUpdateUser
                    | hasValue
                }}
              </div>
            </div>
            <div class="row">
              <div class="col-4">{{'COMMON.MBC_DETAILS.UPDATED_DATE' | translate}}</div>
  
              <div class="col-8">
                {{
                  accountInfo?.customerInformation?.building?.lastUpdateTime
                    | hasValue
                }}
              </div>
            </div>
            <div class="row">
              <div class="col-4">{{'COMMON.USER.TYPE.VALIDATED' | translate}}</div>
  
              <div class="col-8">
                {{ accountInfo?.validationUser }}
              </div>
            </div>
            <div class="row">
              <div class="col-4">{{'COMMON.DATE_TYPE.VALIDATED' | translate}}</div>
  
              <div class="col-8">
                {{ accountInfo?.validationDate | date : "dd/MM/yyyy" | hasValue }}
              </div>
            </div>
            <div class="row">
              <div class="col-4">{{'COMMON.STATUS' | translate}}</div>
  
              <div class="col-8">
                {{ accountInfo?.currentStatus | hasValue }}
              </div>
            </div>
            <div class="row">
              <div class="col-4">{{'COMMON.MBC_DETAILS.LAST_VEND_AMOUNT' | translate}}</div>
  
              <div class="col-8">
                {{ accountInfo?.customerInformation?.lastVendAmount | hasValue }}
              </div>
            </div>
            <div class="row">
              <div class="col-4">{{'COMMON.MBC_DETAILS.LAST_VEND_DATE' | translate}}</div>
  
              <div class="col-8">
                {{
                  accountInfo?.customerInformation?.lastVendDate | date : "dd/MM/yyyy" | hasValue
                }}
              </div>
            </div>
          </div>
          <div class="card container col-11">
            <label class="section-label">{{'PERSONAL_DATA.TITLE_CONTINUED' | translate}}</label>
            <div class="row">
              <div class="col-4">{{'PERSONAL_DATA.PROPERTY_NATURE' | translate}}</div>
  
              <div class="col-8">
                {{ accountInfo?.propertyNature | hasValue }}
              </div>
            </div>
            <div class="row">
              <div class="col-4">{{'PERSONAL_DATA.PROPERTY_TYPE' | translate}}</div>
  
              <div class="col-8">
                {{ accountInfo?.typeOfProperty?.toString() | hasValue }}
              </div>
            </div>
            <div class="row">
              <div class="col-9">
                {{'PERSONAL_DATA.PROPERTY_PLANNING_PERMISSION' | translate}}?
              </div>
  
              <div class="col-3">
                {{
                  accountInfo?.hasPlanningPermissions ? "Yes" : ("No" | hasValue)
                }}
              </div>
            </div>
            <div class="row">
              <div class="col-6">{{'PERSONAL_DATA.PROPERTY_METER' | translate}}?</div>
  
              <div class="col-6">
                {{ accountInfo?.hasMeter ? "Yes" : ("No" | hasValue) }}
              </div>
            </div>
            <div class="row">
              <div class="col-7">{{'PERSONAL_DATA.BUILDING_OWNER_METER_NO' | translate}}</div>
  
              <div class="col-5">
                {{ accountInfo?.meterNumber | hasValue }}
              </div>
            </div>
            <div class="row">
              <div class="col-8">{{'PERSONAL_DATA.BUILDING_OWNER_ACC_NO' | translate}}</div>
  
              <div class="col-4">
                {{ accountInfo?.buildingOwnerAccountNumber | hasValue }}
              </div>
            </div>
            <div class="row">
              <div class="col-8">{{'PERSONAL_DATA.EST_MAX_LOAD' | translate}}</div>
  
              <div class="col-4">
                {{ accountInfo?.estimatedMaxLoad | hasValue }}
              </div>
            </div>
            <div class="row">
              <div class="col-7">{{'PERSONAL_DATA.REQ_VOLTAGE_SUPPLY_TYPE' | translate}}</div>
  
              <div class="col-5">
                {{ accountInfo?.requestedVoltage | hasValue }}
              </div>
            </div>
            <div class="row">
              <div class="col-7">{{'PERSONAL_DATA.REG_ELECT_ENGINEER_NAME' | translate}}</div>
  
              <div class="col-5">
                {{ accountInfo?.nameOfElectricalEngineer | hasValue }}
              </div>
            </div>
            <div class="row">
              <div class="col-7">
                {{'PERSONAL_DATA.REG_ELECT_ENGINEER_ADDRESS' | translate}}
              </div>
  
              <div class="col-5">
                <ng-container
                  *ngIf="accountInfo?.addressElectricalEngineer?.houseNo"
                >
                {{'COMMON.NO' | translate}}.
                </ng-container>
                {{ accountInfo?.addressElectricalEngineer?.houseNo | hasValue }}
                {{ accountInfo?.addressElectricalEngineer?.street | hasValue }},
                {{ accountInfo?.addressElectricalEngineer?.landmark | hasValue }}
              </div>
            </div>
            <div class="row">
              <div class="col-4">{{'PERSONAL_DATA.LICENSE_NO' | translate}}</div>
  
              <div class="col-8">
                {{ accountInfo?.licenseNumber | hasValue }}
              </div>
            </div>
            <div class="row">
              <div class="col-4">{{'COMMON.CATEGORY' | translate}}</div>
  
              <div class="col-8">
                {{ accountInfo?.category | hasValue }}
              </div>
            </div>
            <div class="row">
              <div class="col-5">{{'COMMON.DATE' | translate}}</div>
  
              <div class="col-7">
                {{ accountInfo?.engineerDate | date : "dd/MM/yyyy" }}
              </div>
            </div>
          </div>
          <div class="card container col-11">
            <label class="section-label"
              >{{'PERSONAL_DATA.DECLARATION' | translate}}</label
            >
            <div
              class="row"
              *ngFor="let loadItem of this.accountInfo?.loadRequirements"
            >
              <div class="col-6">
                {{ loadItem.name }}
              </div>
  
              <div class="col-6">
                {{ this.loadItem.number }}
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <div class="image-container col-4">
        <div
          *ngIf="accountInfo?.customerInformation?.building"
          class="card container"
        >
          <div>{{'COMMON.PICTURES.FRONT' | translate}}</div>
          <div class="images-list">
            <div *ngIf="getFrontViewImage(0)">
              <img
                src="{{ getFrontViewImage(0) }}"
                (click)="showImage(0)"
                data-bs-toggle="modal"
                data-bs-target="#modalImage"
              />
            </div>
  
            <div *ngIf="getFrontViewImage(1)">
              <img
                src="{{ getFrontViewImage(1) }}"
                (click)="showImage(1)"
                data-bs-toggle="modal"
                data-bs-target="#modalImage"
              />
            </div>
  
            <div *ngIf="getFrontViewImage(2)">
              <img
                src="{{ getFrontViewImage(2) }}"
                (click)="showImage(2)"
                data-bs-toggle="modal"
                data-bs-target="#modalImage"
              />
            </div>
          </div>
        </div>
        <div
          *ngIf="accountInfo?.customerInformation?.building"
          class="card container"
        >
          <div>{{'COMMON.PICTURES.TAG_MOUNTED' | translate}}</div>
          <div>
            <div *ngIf="getBuildingMountedImage()">
              <img
                src="{{ getBuildingMountedImage() }}"
                (click)="showImage(3)"
                data-bs-toggle="modal"
                data-bs-target="#modalImage"
              />
            </div>
          </div>
        </div>
        <div class="card container">
          <div>{{'COMMON.PICTURES.CUSTOMER_ID_CARD' | translate}}</div>
          <div class="images-list">
            <div *ngIf="accountInfo?.customerInformation?.cardImageUrl">
              <img
                src="{{ accountInfo?.customerInformation?.cardImageUrl }}"
                (click)="showImage(4)"
                data-bs-toggle="modal"
                data-bs-target="#modalImage"
              />
            </div>
          </div>
        </div>
        <div class="card container">
          <div>{{'COMMON.PICTURES.CUSTOMER_SIGNATURE' | translate}}</div>
          <div class="images-list">
            <div *ngIf="accountInfo?.customersSignitureUrl">
              <img
                src="{{ accountInfo?.customersSignitureUrl }}"
                (click)="showImage(5)"
                data-bs-toggle="modal"
                data-bs-target="#modalImage"
              />
            </div>
          </div>
        </div>
        <div class="card container">
          <div>{{'COMMON.PICTURES.LANDLORD_SIGNATURE' | translate}}</div>
          <div class="images-list">
            <div *ngIf="accountInfo?.landlordsSignitureUrl">
              <img
                src="{{ accountInfo?.landlordsSignitureUrl }}"
                (click)="showImage(6)"
                data-bs-toggle="modal"
                data-bs-target="#modalImage"
              />
            </div>
          </div>
        </div>
        <div class="card container">
          <div>{{'COMMON.PICTURES.ELECT_CONTRACTOR_SIGNATURE' | translate}}</div>
          <div class="images-list">
            <div *ngIf="accountInfo?.electricalContractorsSignitureUrl">
              <img
                src="{{ accountInfo?.electricalContractorsSignitureUrl }}"
                (click)="showImage(7)"
                data-bs-toggle="modal"
                data-bs-target="#modalImage"
              />
            </div>
          </div>
        </div>
        <div class="card container">
          <div>{{'COMMON.PICTURES.APPLICANT_SIGNATURE' | translate}}</div>
          <div class="images-list">
            <div *ngIf="accountInfo?.applicantsSignitureUrl">
              <img
                src="{{ accountInfo?.applicantsSignitureUrl }}"
                (click)="showImage(8)"
                data-bs-toggle="modal"
                data-bs-target="#modalImage"
              />
            </div>
          </div>
        </div>
        <div class="card container">
          <div>{{'COMMON.PICTURES.APPLICANT_PASSPORT' | translate}}</div>
          <div class="images-list">
            <div *ngIf="accountInfo?.passportUrl">
              <img
                src="{{ accountInfo?.passportUrl }}"
                (click)="showImage(9)"
                data-bs-toggle="modal"
                data-bs-target="#modalImage"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    id="modalImage"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
    (hidden.bs.modal)="onModalFade()"
    (shown.bs.modal)="onModalOpen()"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content modal-image">
        <div class="modal-body modal-body-image">
          <div
            class="image-container"
            [style.transform]="'rotate(' + currentRotation + 'deg)'"
          >
            <lib-ngx-image-zoom
              [zoomMode]="'hover-freeze'"
              [fullImage]="selectedImage"
              [thumbImage]="selectedImage"
              [magnification]="3"
            >
            </lib-ngx-image-zoom>
          </div>
          <div class="rotate-div">
            <button class="btn btn-primary left-button" (click)="rotateLeft()">
              &lt;
            </button>
            <button class="btn btn-primary right-button" (click)="rotateRight()">
              &gt;
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <div
    class="modal"
    id="updateStatus"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
    (hidden.bs.modal)="cancelModal()"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{'COMMON.DATA_VALID_REVIEW' | translate}}</h5>
          <button
            type="button"
            #closeDQModal
            class="btn-cancel-modal"
            data-bs-dismiss="modal"
            aria-label="Close"
            (click)="cancelModal()"
          >
          {{'COMMON.CANCEL' | translate}}
          </button>
        </div>
        <div
          *ngIf="!showApprove && !showReject"
          class="modal-footer updateStatus"
        >
          <button
            type="button"
            class="btn btn-primary approve"
            (click)="approve($event)"
          >
            {{'COMMON.APPROVE' | translate}}
          </button>
          <button type="button" class="btn btn-danger" (click)="reject($event)">
            {{'COMMON.REJECT' | translate}}
          </button>
        </div>
        <div *ngIf="showApprove" class="modal-footer updateStatus">
          <div>
            <h3>{{'DATA_QUALITY.APPROVE' | translate}}</h3>
          </div>
  
          <button
            type="button"
            class="btn btn-primary approve"
            (click)="submitApproval()"
          >
            {{'COMMON.YES' | translate}}
          </button>
          <button type="button" (click)="cancel($event)" class="btn btn-danger">
            {{'COMMON.NO' | translate}}
          </button>
        </div>
        <div class="modal-body">
          <div *ngIf="showReject">
            <form class="form-wrapper">
              <div class="row">
                <div class="rejection-section rejection-select col-12">
                  <label class="select-label">{{'NEW_SERVICE_KYC.INCOMPLETE_INFO' | translate}}</label>
                  <div class="form-item form-floating mb-3">
                    <select
                      #missingInformation
                      (change)="
                        onSelectChange(missingInformation.value, 'missingInfo')
                      "
                      class="form-select"
                    >
                      <option disabled selected>{{'COMMON.SELECT_OPTION' | translate}}</option>
                      <option value="Yes">{{'COMMON.YES' | translate}}</option>
                      <option value="No">{{'COMMON.NO' | translate}}</option>
                    </select>
                  </div>
                </div>
                <div
                  *ngIf="informationMissing"
                  class="rejection-section missing-info-checkboxes col-12"
                >
                  <label class="select-label">{{'NEW_SERVICE_KYC.INFO_TO_COMPLETE' | translate}}</label>
                  <div class="row rejection-checkbox">
                    <div class="col-1 rejection-checkbox-input">
                      <input
                        [(ngModel)]="addressWrong"
                        [ngModelOptions]="{ standalone: true }"
                        type="checkbox"
                      />
                    </div>
                    <div class="col-11">
                      <label class="checkbox-title"
                        >{{'PERSONAL_DATA.REQUIRED_SUPPLY_ADDRESS' | translate}}</label
                      >
                      <div class="row">
                        <div class="col-12 rejection-reason">
                          <label>{{'COMMON.REASON_FOR_REJECT' | translate}}</label>
                          <div class="form-item form-floating mb-3">
                            <input
                              [readOnly]="!addressWrong"
                              class="rejection-input"
                              [(ngModel)]="addressRejection"
                              name="addressRejection"
                              type="text"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row rejection-checkbox">
                    <div class="col-1 rejection-checkbox-input">
                      <input
                        [(ngModel)]="loadWrong"
                        [ngModelOptions]="{ standalone: true }"
                        type="checkbox"
                      />
                    </div>
                    <div class="col-11">
                      <label class="checkbox-title"
                        >{{'NEW_SERVICE_KYC.LOAD_REQUIREMENT' | translate}}</label
                      >
                      <div class="row">
                        <div class="col-12 rejection-reason">
                          <label>{{'COMMON.REASON_FOR_REJECT' | translate}}</label>
                          <div class="form-item form-floating mb-3">
                            <input
                              [readOnly]="!loadWrong"
                              class="rejection-input"
                              [(ngModel)]="loadRejection"
                              name="loadRejection"
                              type="text"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="rejection-section rejection-select col-12">
                  <label class="select-label">{{'NEW_SERVICE_KYC.FILES_UPLOAD' | translate}}</label>
                  <div class="form-item form-floating mb-3">
                    <select
                      #missingPhotos
                      (change)="
                        onSelectChange(missingPhotos.value, 'missingPhotos')
                      "
                      class="form-select"
                    >
                      <option disabled selected>{{'COMMON.SELECT_OPTION' | translate}}</option>
                      <option value="Yes">{{'COMMON.YES' | translate}}</option>
                      <option value="No">{{'COMMON.NO' | translate}}</option>
                    </select>
                  </div>
                </div>
                <div
                  *ngIf="photosMissing"
                  class="rejection-section missing-info-checkboxes col-12"
                >
                  <label class="select-label">{{'NEW_SERVICE_KYC.FILES_REUPLOAD' | translate}}</label>
                  <div class="row rejection-checkbox">
                    <div class="col-1 rejection-checkbox-input">
                      <input
                        [(ngModel)]="signatureMissing"
                        [ngModelOptions]="{ standalone: true }"
                        type="checkbox"
                      />
                    </div>
                    <div class="col-11">
                      <label class="checkbox-title">{{'COMMON.SIGNATURE' | translate}}</label>
                    </div>
                  </div>
                  <div class="row rejection-checkbox">
                    <div class="col-1 rejection-checkbox-input">
                      <input
                        [(ngModel)]="landlordSignatureMissing"
                        [ngModelOptions]="{ standalone: true }"
                        type="checkbox"
                      />
                    </div>
                    <div class="col-11">
                      <label class="checkbox-title">{{'NEW_SERVICE_KYC.LANDLORD_SIGNATURE' | translate}}</label>
                    </div>
                  </div>
                  <div class="row rejection-checkbox">
                    <div class="col-1 rejection-checkbox-input">
                      <input
                        [(ngModel)]="contractorSignatureMissing"
                        [ngModelOptions]="{ standalone: true }"
                        type="checkbox"
                      />
                    </div>
                    <div class="col-11">
                      <label class="checkbox-title"
                        >{{'NEW_SERVICE_KYC.ELECT_CONTRACTOR_SIGNATURE' | translate}}</label
                      >
                    </div>
                  </div>
  
                  <div class="row rejection-checkbox">
                    <div class="col-1 rejection-checkbox-input">
                      <input
                        [(ngModel)]="applicantSignatureMissing"
                        [ngModelOptions]="{ standalone: true }"
                        type="checkbox"
                      />
                    </div>
                    <div class="col-11">
                      <label class="checkbox-title">{{'NEW_SERVICE_KYC.APPLICANT_SIGNATURE' | translate}}</label>
                    </div>
                  </div>
                  <div class="row rejection-checkbox">
                    <div class="col-1 rejection-checkbox-input">
                      <input
                        [(ngModel)]="passportPhotoMissing"
                        [ngModelOptions]="{ standalone: true }"
                        type="checkbox"
                      />
                    </div>
                    <div class="col-11">
                      <label class="checkbox-title"
                        >{{'NEW_SERVICE_KYC.APPLICANT_PASSPORT' | translate}}</label
                      >
                    </div>
                  </div>
  
                  <div class="row">
                    <div class="col-12 rejection-reason">
                      <label>{{'COMMON.REASON_FOR_REJECT' | translate}}</label>
                      <div class="form-item form-floating mb-3">
                        <input
                          class="rejection-input"
                          [(ngModel)]="filesRejection"
                          name="filesRejection"
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div class="buttons">
              <button class="btn btn-primary" (click)="trySubmitRejection()">
                {{'DATA_QUALITY.SUBMIT_REJECTION' | translate}}
              </button>
              <button
                class="btn btn-danger"
                style="margin-left: 1rem"
                (click)="cancel($event)"
              >
                {{'COMMON.CANCEL' | translate}}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  