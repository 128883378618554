import { UseOfPremises } from '../_modules/customers/_enums/use-of-premises';
import { Area } from './area.model';
import { BuildingImage } from './building.model';
import {
  ClientType,
  Neighborhood,
  SltStatus,
  TypeOfConnection,
} from './customer.model';
import { DtDto, getInitDtDto } from './dt.model';

export interface SiteVerificationList {
  id: number;
  applicationNumber: string;
  buildingOwner: string;
  address: string;
  region: string;
  area: string;
  tag: string;
  status: SiteVerificationStatus;
}

export enum SiteVerificationStatus {
  New = 0,
  Assigned = 1,
  Submited = 2,
  Approved = 3,
  Rejected = 4,
}

export enum AccountCreationStatus {
  Pending = 0,
  Approved = 1
}

export interface SiteVerificationsStats {
  total?: number;
  new?: number;
  assigned?: number;
  submited?: number;
  approved?: number;
  rejected?: number;
}

export interface SiteVerificationDetails {
  id: number;
  status: SiteVerificationStatus;
  useOfPremises: UseOfPremises;
  typeOfConnection: TypeOfConnection;
  clientType: ClientType;
  creationDate: string;
  firstCapturedUser: string;
  lastCapturedUser: string;
  reviewedBy: string;
  area: Area;
  neighborhood: Neighborhood;
  numberOfRooms: number;
  sltStatusValue: string;
  tariff: string;
  remarksAndRecommendation: string;
  ghanaPostGPS: string;
  geoCode: string;
  phoneNumber: string;
  email: string;
  buildingOwner: string;
  address: string;
  accountKyc: SiteVerificationKyc;
  latitude: number;
  longitude: number;
  dt: DtDto;
  lastUpdateTime: string;
  reviewDate: string;
  assignedUser: string;
  permiseCategory: string;
  permiseType: string;
  subActivity: string;
  activity: string;
  images: BuildingImage[];
  accountNumber?: string;
  accountCreationStatus?: AccountCreationStatus;
  meterBrand: string;
}

export interface SiteVerificationKyc {
  accountNumber?: string | null;
  email: string | null;
  phoneNumber: string | null;
  dialCode: string | null;
  buildingOwnerDialCode: string | null;
  buildingOwnerEmployerDialCode: string | null;
  // password: string | null;
  utilityId?: number | null;
  lastUpdateTime?: string | null;
  title: number | null;
  surname: string | null;
  firstName?: string | null;
  dateOfBirth: string | null;
  nameOfEmployer: string | null;
  employerAddress: AddressModel;
  meterNumberLandlord: string | null;
  accountNumberForPremissesLandlord: string | null;
  identificationOfLandlord: number | null;
  personalIdNumberOfLandlord: string | null;
  addressOfLandlord: AddressModel;
  typeOfPremises: number | null;
  useOfPremises: number | null;
  addressPreviousCustomer: AddressModel;
  meterNumberPreviousCustomer: string | null;
  accountNumberPreviousCustomer: string | null;
  buildingOwner: string | null;
  buildingOwnerAddress: AddressModel;
  buildingOwnerPhoneNumber: string | null;
  identificationOfBuildingOwner: number | null;
  personalIdNumberOfBuildingOwner: string | null;
  nameOfEmployerOfBuildingOwner: string | null;
  employerAddressOfBuildingOwner: AddressModel;
  buildingOwnerEmployerPhoneNumber: string | null;
  buildingOwnerEmail: string | null;
  loadRequirements: LoadRequirement[];
  propertyNature: number | null;
  typeOfProperty: number | null;
  hasPlanningPermissions: boolean;
  hasMeter: boolean;
  meterNumber: string | null;
  buildingOwnerAccountNumber: string | null;
  estimatedMaxLoad: number | null;
  requestedVoltage: number | null;
  nameOfElectricalEngineer?: string | null;
  addressElectricalEngineer: AddressModel;
  licenseNumber: string | null;
  category: string | null;
  engineerDate: Date | null;
  meterNumberRequestingForChange: string | null;
  accountNumberRequestingForChange: string | null;
  customersSignitureUrl: string;
  landlordsSignitureUrl: string;
  electricalContractorsSignitureUrl: string;
  applicantsSignitureUrl: string;
  passportUrl: string;
  customersSignitureName: string | null;
  landlordsSignitureName: string | null;
  electricalContractorsSignitureName: string | null;
  applicantsSignitureName: string | null;
  passportName: string | null;
  balanceAmountAgreement?: number | null;
  debtRepaymentOption?: number | null;
  debtAmountAcceptance: boolean;
  profilePictureUrl: string | null;
  applicationNumber: string | null;
  hasCustomer: boolean;
  bankVerificationNumber: string | null;

}

export class AddressModel {
  houseNo: string | null;
  street: string | null;
  landmark: string | null;
  city: string | null;
  area: string | null;
  areaId: number | null;
}

export class LoadRequirement {
  id: number | null;
  name?: string | null;
  number: number | null;
  wattage: number | null;
  utilityId?: number | null;
}

export function getInitAddress(): AddressModel {
  return {
    houseNo: '',
    street: '',
    landmark: '',
    city: '',
    area: '',
    areaId: 0,
  };
}
export function getInitKyc(): SiteVerificationKyc {
  return {
    accountNumber: '',
    email: '',
    phoneNumber: '',
    dialCode: '',
    buildingOwnerDialCode: '',
    buildingOwnerEmployerDialCode: '',
    // password: '',
    utilityId: 0,
    lastUpdateTime: '',
    title: 0,
    surname: '',
    firstName: '',
    dateOfBirth: null,
    nameOfEmployer: '',
    employerAddress: getInitAddress(),
    meterNumberLandlord: '',
    accountNumberForPremissesLandlord: '',
    identificationOfLandlord: 0,
    personalIdNumberOfLandlord: '',
    addressOfLandlord: getInitAddress(),
    typeOfPremises: 0,
    useOfPremises: 0,
    addressPreviousCustomer: getInitAddress(),
    meterNumberPreviousCustomer: '',
    accountNumberPreviousCustomer: '',
    buildingOwner: '',
    buildingOwnerAddress: getInitAddress(),
    buildingOwnerPhoneNumber: '',
    identificationOfBuildingOwner: 0,
    personalIdNumberOfBuildingOwner: '',
    nameOfEmployerOfBuildingOwner: '',
    employerAddressOfBuildingOwner: getInitAddress(),
    buildingOwnerEmployerPhoneNumber: '',
    buildingOwnerEmail: '',
    loadRequirements: [],
    propertyNature: 0,
    typeOfProperty: 0,
    hasPlanningPermissions: false,
    hasMeter: false,
    meterNumber: '',
    buildingOwnerAccountNumber: '',
    estimatedMaxLoad: 0,
    requestedVoltage: 0,
    nameOfElectricalEngineer: '',
    addressElectricalEngineer: getInitAddress(),
    licenseNumber: '',
    category: '',
    engineerDate: null,
    meterNumberRequestingForChange: '',
    accountNumberRequestingForChange: '',
    customersSignitureUrl: '',
    landlordsSignitureUrl: '',
    electricalContractorsSignitureUrl: '',
    applicantsSignitureUrl: '',
    passportUrl: '',
    customersSignitureName: '',
    landlordsSignitureName: '',
    electricalContractorsSignitureName: '',
    applicantsSignitureName: '',
    passportName: '',
    balanceAmountAgreement: 0,
    debtRepaymentOption: 0,
    debtAmountAcceptance: false,
    profilePictureUrl: '',
    applicationNumber: '',
    hasCustomer: false,
    bankVerificationNumber: ''
  };
}
export function getInitDetails(): SiteVerificationDetails {
  return {
    id: 0,
    status: SiteVerificationStatus.New,
    useOfPremises: UseOfPremises.Commercial,
    typeOfConnection: TypeOfConnection.NotSelected,
    clientType: ClientType.Company,
    creationDate: '',
    firstCapturedUser: '',
    lastCapturedUser: '',
    reviewedBy: '',
    area: new Area(),
    neighborhood: Neighborhood.PeriUrban,
    numberOfRooms: 0,
    sltStatusValue: '',
    tariff: '',
    remarksAndRecommendation: '',
    ghanaPostGPS: '',
    geoCode: '',
    phoneNumber: '',
    email: '',
    buildingOwner: '',
    address: '',
    accountKyc: getInitKyc(),
    latitude: 0,
    longitude: 0,
    dt: getInitDtDto(),
    lastUpdateTime: '',
    reviewDate: '',
    assignedUser: '',
    permiseCategory: '',
    permiseType: '',
    subActivity: '',
    activity: '',
    images: [],
    meterBrand: '',
  };
}
