import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hasValue'
})
export class HasValuePipe implements PipeTransform{
    transform(value: any)  {
        if(value === undefined || value === null){
            return "----";
        }

        if(value === '' || value === " " || value === '/'){
            return "----";
        }

        return value;
    }
}