import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '../config/config';
import { Translation } from '../models/translation.model';
import { TransmissionStation } from '../models/transmission.station.model';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  private pathApi = this.config.setting['pathApi'] + "transmissionStation/";

  constructor(private http: HttpClient, private config: AppConfig) { }

  getByKeyFromStorage(key: string) {
    var translations: Translation[] = JSON.parse(localStorage.getItem('translation') as string);
    if (translations != null && translations.filter(x => x.key == key).length != 0) {
      return translations.filter(x => x.key == key)[0].value;
    }
    else {
      return key;
    }
  }
}
