import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-utility-layout',
  templateUrl: './utility-layout.component.html',
  styleUrls: ['./utility-layout.component.scss']
})
export class UtilityLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
