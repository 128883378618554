import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '../config/config';
import { AuditLogDto } from '../models/auditLog.model';
import { PaginationDto, ResponsePackage } from '../models/util.model';

@Injectable({
  providedIn: 'root'
})
export class AuditLogService {

  private pathApi = this.config.setting['pathApi'] + "bps/auditLog/";
  private headers = new HttpHeaders({'Content-Type': 'application/json'});

  constructor(private http: HttpClient, private config: AppConfig) {}

  getAll(obj: any) : Observable<any> {
    return this.http.post(`${this.pathApi}getAll`, obj, {headers: this.headers, observe: 'response'});
  }

  getPagesAndActions() : Observable<any> {
    return this.http.get(`${this.pathApi}getPagesAndActions`, {headers: this.headers, observe: 'response'});
  }

  download(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}downloadFile`, obj, {headers: this.headers, observe: 'response'});
  }
}
