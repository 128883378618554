<div class="container-fluid customers-root">



  <div class="view-details-container" *ngIf="detailsShowed">
    <app-add-installation
        [isPopUp]="true"
        (closePopUpChange)="detailsShowed = false; reloadTable()"
    ></app-add-installation>
  </div>
  <app-card-analytics [stats]="stats" [reset]="reset" (shareFilterValue)="onFilterBy($event)"></app-card-analytics>

  <div class="table-root">
    <div class="d-flex justify-content-start switch-toggle transparent">
      <a
        class="separated-toggle-item"
        [ngClass]="currentTab == 4 ? 'active' : ''"
        (click)="changeTab(4);reset = !reset"
        ><div>
          <span class="bubble">{{ countAssigned | number }}</span> {{'COMMON.ASSET_STATUS.ASSIGNED' | translate}}
        </div></a
      >
      <a class="separated-toggle-item" [ngClass]="currentTab == 1 ? 'active' : ''" (click)="changeTab(1);reset = !reset"><div><span class="bubble">{{countSubmited | number}}</span> {{'COMMON.ASSET_STATUS.SUBMITTED' | translate}}</div></a>
          <a class="separated-toggle-item" [ngClass]="currentTab == 2 ? 'active' : ''" (click)="changeTab(2);reset = !reset"><div><span class="bubble">{{countApproved | number}}</span> {{'COMMON.ASSET_STATUS.APPROVED' | translate}}</div></a>
          <a class="separated-toggle-item" [ngClass]="currentTab == 3 ? 'active' : ''" (click)="changeTab(3);reset = !reset"><div><span class="bubble">{{countRejected | number}}</span> {{'COMMON.ASSET_STATUS.REJECTED' | translate}}</div></a>
    </div>
    <div class="table-root-child">
      <div
        class="table-action-wrapper d-flex align-items-center justify-content-between"
      >
        <div class="search-input">
          <img
            src="../../assets/icons/search.svg"
            alt="Search icon"
            (click)="search()"
          />
          <input
            type="text"
            [placeholder]="'COMMON.SEARCH' | translate"
            [(ngModel)]="searchText"
            (keydown.enter)="search()"
            (ngModelChange)="this.searchTextUpdate.next($event)"
          />
        </div>

        <div class="ml-auto d-flex">
          <div>
            <div class="d-flex">
              <div class="datepicker-container" style="width: 24rem;">
                <label for="dateFrom"> {{'COMMON.FROM' | translate}}: </label>
                <input
                  type="date"
                  id="dateFrom"
                  name="dateFrom"
                  [ngClass]="
                    dateFrom > dateTo
                      ? 'btn datepicker-icon-error'
                      : 'btn datepicker-icon'
                  "
                  [(ngModel)]="dateFrom"
                  (change)="datesValid() == true && search()"
                />
              </div>

              <div class="datepicker-container">
                <label for="dateTo" class="right-label"> {{'COMMON.TO' | translate}}: </label>
                <input
                  type="date"
                  id="dateTo"
                  name="dateTo"
                  [ngClass]="
                    dateFrom > dateTo
                      ? 'btn datepicker-icon-error'
                      : 'btn datepicker-icon'
                  "
                  [(ngModel)]="dateTo"
                  (change)="datesValid() == true && search()"
                />
              </div>
            </div>
          </div>
          <div
            class="sort-by-root"
            (click)="showSortBy = !showSortBy"
            click-stop-propagation
          >
            <div class="dropdown">
              <span>{{'COMMON.SORT_BY.TITLE' | translate}}:</span>
              <span>{{ sortByName | translate }}</span>
              <span
                ><img
                  src="../../assets/icons/gray-arrow-down.svg"
                  alt="Gray arrow down"
              /></span>
            </div>
            <div class="dropdown-opened" *ngIf="showSortBy">
              <div (click)="sortBy(sortByOptions.NEWEST)">{{'COMMON.SORT_BY.NEWEST' | translate}}</div>
              <div (click)="sortBy(sortByOptions.OLDEST)">{{'COMMON.SORT_BY.OLDEST' | translate}}</div>
            </div>
          </div>
          <button
           *ngIf="currentTab != 4"
            class="btn btn-icon"
            (click)="download()"
          >
            <span>{{'COMMON.DOWNLOAD' | translate}}</span>
          </button>
          <div *ngIf="currentTab == 4 && permissionsService.isInstallationCreateEditAllowed">
            <button
              class="btn btn-primary btn-upload"
              (click)="showUpload = !showUpload"
              click-stop-propagation
            >
              <img
                src="../../assets/icons/chevron-down.svg"
                alt="Chevron down"
              />
            </button>
            <div *ngIf="showUpload" class="xlsx-upload">
              <div><img src="../../assets/icons/upload-arrow.svg" alt="Upload arrow" /></div>
              <div>{{'COMMON.UPLOAD_XLSX' | translate}}</div>
            </div>
          </div>
          <button class="btn btn-primary btn-add-new" (click)="openCreateModal()" *ngIf="currentTab == 4 && permissionsService.isInstallationCreateEditAllowed">
            <img src="../../assets/icons/plus.svg" alt="Plus icon" />
            <span>{{'INSTALLATIONS.NEW' | translate}}</span>
          </button>
        </div>
      </div>

      <div class="filter">
        <div class="filter-list">
          {{'COMMON.FILTER' | translate}}
          <app-multi-select-dropdown class="multiselect"
                                     [clicked] = "clicked"
                                     [items]="regions" 
                                     [label]="'COMMON.BU.PLURAL'"
                                     [showMore]="false"
                                     [requireApply]="true"
                                     (shareIndividualCheckedList)="selectRegion($event)"
                                     (applyFilterEmitter)="reloadTable(1)"
                                     >
          </app-multi-select-dropdown>
          <app-multi-select-dropdown class="multiselect"
                                     [clicked] = "clicked"
                                     [items]="areas" 
                                     [label]="'COMMON.UT.PLURAL'"
                                     [showMore]="false"
                                     [requireApply]="true"
                                     (shareIndividualCheckedList)="selectArea($event)"
                                     (applyFilterEmitter)="reloadTable(1)"
                                     >
          </app-multi-select-dropdown>
          <select name="tariff-select" (change)="selectTariff($event)" class="form-select" >
            <option value="0" [selected]="tariff == 0" disabled>{{'COMMON.TARIFF.SINGLE' | translate}}</option>
            <option [selected]="tariff == o.id" *ngFor="let o of tariffs" value="{{o.id}}">
                {{o.name}}
            </option>
          </select>  



          <select name="type-select" (change)="selectCustomerType($event)" class="form-select" >
            <option value="0" [selected]="customerType == 0" disabled>{{'COMMON.MBC_DETAILS.CUSTOMER_TYPE' | translate}}</option>
            <option [selected]="customerType == o.value" *ngFor="let o of customerTypeOptions" value="{{o.value}}">
                {{o.translation | translate}}
            </option>
          </select>  
          <select name="phase-select" (change)="selectCustomerPhase($event)" class="form-select" >
            <option value="0" [selected]="customerPhase == -1" disabled>{{'COMMON.CUSTOMER.CUSTOMER_PHASE' | translate}}</option>
            <option [selected]="customerPhase == o.value" *ngFor="let o of customerPhaseOptions" value="{{o.value}}">
                {{o.translation | translate}}
            </option>
          </select>  
          <select name="status-select" (change)="selectMeterStatus($event)" class="form-select" >
            <option value="0" [selected]="meterStatus == 0" disabled>{{'COMMON.MBC_DETAILS.METER_STATUS' | translate}}</option>
            <option [selected]="meterStatus == o.value" *ngFor="let o of meterStatusOptions" value="{{o.value}}">
                {{o.translation | translate}}
            </option>
          </select>  
        </div>
        <div *ngIf="selectedFilters.length > 0">
          <app-filter-checklist [filters]="selectedFilters" 
                                (resetFilterEmitter)="resetFilter($event)"
                                (removeFilterEmitter)="removeFilter($event)"
                                >
          </app-filter-checklist>
        </div>
      </div>
      <div class="table">
        <div *ngIf="installations">
          <table class="table border-bottom">
            <thead class="table-light">
              <tr>
                <th>
                  <input
                    type="checkbox"
                    class="form-check-input"
                    [checked]="isAllCheckBoxChecked()"
                    (change)="checkAllCheckBox($event)"
                  />
                </th>
                <th>{{'COMMON.MBC_DETAILS.ACCOUNT_NO' | translate}}</th>
                <th>{{'COMMON.TABLE_HEADERS.BUILDING_OWNER' | translate}}</th>
                <th>{{'COMMON.ADDRESS' | translate}}</th>
                <th>{{'COMMON.CURRENT_TARIFF' | translate}}</th>
                <th>{{'COMMON.BU.SINGLE' | translate}}</th>
                <th>{{'COMMON.UT.SINGLE' | translate}}</th>
                <th>{{'COMMON.FEEDER' | translate}}</th>
                <th>{{'TRANSFORMERS.DT' | translate}}</th>
                <th>{{'COMMON.MBC_DETAILS.METER_NO' | translate}}</th>
                <th *ngIf="currentTab==1 || currentTab==4">{{'COMMON.TABLE_HEADERS.ASSIGNED_DATE' | translate}}</th>
                <th *ngIf="currentTab==3">{{'COMMON.TABLE_HEADERS.REJECTED_DATE' | translate}}</th>
                <th *ngIf="currentTab==2">{{'COMMON.TABLE_HEADERS.APPROVED_DATE' | translate}}</th>
                <th>{{'COMMON.MBC_DETAILS.ASSIGNED' | translate}}</th>
                <th *ngIf="currentTab==1 || currentTab==4">{{'COMMON.TABLE_HEADERS.ASSIGNED_BY' | translate}}</th>
                <th *ngIf="currentTab==3">{{'COMMON.TABLE_HEADERS.REJECTED_BY' | translate}}</th>
                <th *ngIf="currentTab==2">{{'COMMON.TABLE_HEADERS.APPROVED_BY' | translate}}</th>
                <th>{{'COMMON.TABLE_HEADERS.CURRENT_STATUS' | translate}}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let installation of installations; let i = index">
                <td>
                  <input
                    type="checkbox"
                    class="form-check-input"
                    value="{{ installation.id }}"
                  />
                </td>
                <td (click)="setFilterStorage()" [routerLink]="['details', installation.id]">{{ installation.accountNumber | hasValue }}</td>
                <td (click)="setFilterStorage()" [routerLink]="['details', installation.id]">{{ installation.accountName | hasValue }}</td>
                <td (click)="setFilterStorage()" [routerLink]="['details', installation.id]">{{ installation.address | hasValue }}</td>
                <td (click)="setFilterStorage()" [routerLink]="['details', installation.id]">{{ installation.currentTariff | hasValue }}</td>
                <td (click)="setFilterStorage()" [routerLink]="['details', installation.id]">{{ installation.region | hasValue }}</td>
                <td (click)="setFilterStorage()" [routerLink]="['details', installation.id]">{{ installation.district | hasValue }}</td>
                <td (click)="setFilterStorage()" [routerLink]="['details', installation.id]">{{ installation.feederName | hasValue }}</td>
                <td (click)="setFilterStorage()" [routerLink]="['details', installation.id]">{{ installation.dtName | hasValue }}</td>
                <td (click)="setFilterStorage()" [routerLink]="['details', installation.id]">{{ installation.oldMeterNo | hasValue }}</td>
                <td (click)="setFilterStorage()" [routerLink]="['details', installation.id]" *ngIf="currentTab==1 || currentTab==4">{{installation.assignedDate | hasValue}} </td>
                <td (click)="setFilterStorage()" [routerLink]="['details', installation.id]" *ngIf="currentTab==3">{{installation.rejectedDate | hasValue}} </td>
                <td (click)="setFilterStorage()" [routerLink]="['details', installation.id]" *ngIf="currentTab==2">{{installation.approvedDate | hasValue}} </td>
                <td (click)="setFilterStorage()" [routerLink]="['details', installation.id]">{{installation.assignedUser | hasValue}} </td>
                <td (click)="setFilterStorage()" [routerLink]="['details', installation.id]" *ngIf="currentTab==1 || currentTab==4">{{installation.assignedBy | hasValue}} </td>
                <td (click)="setFilterStorage()" [routerLink]="['details', installation.id]" *ngIf="currentTab==3">{{installation.rejectedBy | hasValue}} </td>
                <td (click)="setFilterStorage()" [routerLink]="['details', installation.id]" *ngIf="currentTab==2" >{{installation.approvedBy | hasValue}} </td>
                <td (click)="setFilterStorage()" [routerLink]="['details', installation.id]">
                  <div
                    *ngIf="installation.currentStatus"
                    class="building-status"
                    [ngClass]="{
                      'assigned-building':
                        installation.currentStatus == 'Assigned',
                      'rejected-building':
                        installation.currentStatus == 'Rejected',
                      'approved-building':
                        installation.currentStatus == 'Approved',
                      'submitted-building':
                        installation.currentStatus == 'Submitted'
                    }"
                  >
                    {{ 'COMMON.ASSET_STATUS.' + installation.currentStatus | uppercase | translate }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div>
            <app-paging
              *ngIf="count > 0"
              [pageSize]="pageSize"
              [count]="count"
              [currentPage]="currentPage"
              [useDirect]="true"
              (pageChange)="pageChange($event)"
            ></app-paging>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

