import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { PersonalDataStateService } from 'src/app/_modules/customers/components/personal-data/services/personal-data-state.service';
import { RegularTab } from 'src/app/_modules/shared/_interfaces/regular-tab';
import { HelperService } from 'src/app/_modules/shared/_services/helper.service';
import { ModalService } from 'src/app/_modules/shared/_services/modal.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-agreement-dialog',
  templateUrl: './agreement-dialog.component.html',
  styleUrls: ['./agreement-dialog.component.scss']
})
export class AgreementDialogComponent implements OnInit {
    desktopTabs: RegularTab[] = [
        {
            name: 'Consent',
            selected: true
        },
        {
            name: 'Condition For Request',
            selected: false
        },
        {
            name: 'Further Undertaking',
            selected: false
        },
        {
            name: 'Conditions & Regulations of supply',
            selected: false
        },
    ];

    mobileTopTabs: RegularTab[] = [
        {
            name: 'Consent',
            selected: true
        },
        {
            name: 'Condition For Request',
            selected: false
        },
    ];
    mobileBottomTabs: RegularTab[] = [
        {
            name: 'Further Undertaking',
            selected: false
        },
        {
            name: 'Conditions & Regulations of supply',
            selected: false
        },
    ];

    selectedTab: RegularTab = { name: 'Consent', selected: true };
    isInitialLoad: boolean = true;
    utilityName: string = '';
    utilityInfo: any;

    @ViewChild('dialog') dialogRef!: ElementRef;
    @HostListener('document:mousedown', ['$event'])
    onOutsideClick(event: any): void {
      if (!this.dialogRef?.nativeElement?.contains(event.target)) {
        this.modalService.closeModal();
      }
    }
    constructor(
        public modalService: ModalService,
        public personalDataStateService: PersonalDataStateService,
        public utilityService: UtilityService
    ) { }

    ngOnInit(): void {
        this.utilityInfo = this.utilityService.get().subscribe({
            next: (response) => {
              this.utilityInfo = response.body?.data;
              console.log(this.utilityInfo)
        }});
    }

    mobileTabSelected(selectedTab: RegularTab) {
        this.selectedTab = selectedTab;
        this.mobileTopTabs.forEach(((tab: RegularTab) => {
            tab.selected = selectedTab.name === tab.name ? true : false;
        }));

        this.mobileBottomTabs.forEach(((tab: RegularTab) => {
            tab.selected = selectedTab.name === tab.name ? true : false;
        }));
    }

    downloadPdfVersion() {
        // TODO: Will be implemented
    }

    agree() {
        if (!this.personalDataStateService.isAgreed) {
            return;
        }
        this.modalService.closeModal();
    }
}
