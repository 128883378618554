import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, fromEvent, Observable, Subject, takeUntil } from 'rxjs';
import { JobHistory} from 'src/app/models/jobHistory.model';
import { KeyValueItem } from 'src/app/models/keyValueItem.model';
import { MeterListElement, SortByOptions } from 'src/app/models/meter.model';
import { PermissionsEnum } from 'src/app/models/role.model';
import { AuthService } from 'src/app/services/auth.service';
import { JobHistoryBpsService } from 'src/app/services/bps/job-history.service';
import { CommonService } from 'src/app/services/common.service';
import { MeterService } from 'src/app/services/meter.service';
import { UtilityService } from 'src/app/services/utility.service';


@Component({
  selector: 'app-bps-admin-job-history',
  templateUrl: './bps-admin-job-history.component.html',
  styleUrls: ['./bps-admin-job-history.component.scss']
})
export class BpsAdminJobHistoryComponent implements OnInit {

  stats: KeyValueItem[] = [];
  public PermissionsEnum = PermissionsEnum;
  jobs : JobHistory[] = [];
  utilityList: any[];

  // mapSortBy: Record<number, string> = {
  //   [SortByOptions.NEWEST]: 'Newest',
  //   [SortByOptions.SLRN_ASC]: 'SLRN A',
  //   [SortByOptions.SLRN_DESC]: 'SLRN D',
  //   [SortByOptions.NUMBER_ASC]: 'NUMBER A',
  //   [SortByOptions.NUMBER_DESC]: 'NUMBER D',
  // }
  
  dateFrom: string;
  dateTo: string;
  count: number = 2;
  pageSize: number = 10;
  currentPage: number = 1;
  searchText: string = '';
  searchTextUpdate = new Subject<string>();

  // sortByValue: SortByOptions = SortByOptions.NEWEST;
  // sortByOptions = SortByOptions;
  // sortByName: string = this.mapSortBy[SortByOptions.NEWEST];
  currentStatus: number = 0;
  currentUtility: number = 0;

  showUpload: boolean = false;
  showSortBy: boolean = false;
  showUtilityDropdown: boolean = false;
  clickObservable: Observable<Event> = fromEvent(document,'click');

  private ngUnsubscribe = new Subject<void>();

  constructor(private jobHistoryService : JobHistoryBpsService, private toastr : ToastrService, public authService : AuthService, public utilityService : UtilityService) { }

  ngOnInit(): void {
    this.getData();
    this.subscribeToClickEvent();

    this.searchTextUpdate.pipe(
      debounceTime(500))
      .subscribe(value => {
        if(this.searchText=="")
          this.search();
      });
  }

  ngOnDestroy(): void {
    this.searchTextUpdate.complete();
  }

  private getData() {
    this.getUtilities();
    this.reloadTable(); 
  }

  reloadTable(page: any = null) {
    if(page)
    this.currentPage = page;
  
    let obj = {
      pageInfo:{
        page: this.currentPage,
        pageSize: this.pageSize
      },
      filterParams: this.getFilterParams()
    };
    console.log(obj);
    this.getAll(obj);
  }

  private getAll(obj: any){
    obj.isCrud = false;
    console.log(obj);
    this.jobHistoryService.getAll(obj).subscribe({
      next: response => {
        const responseData = response.body;
        console.log(responseData);
        if(responseData?.status === 200 || responseData?.status === "OK"){
          this.jobs = responseData.data.data;
          this.count = responseData.data.count;
          console.log(this.jobs);
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: _ => this.toastr.error("Error occured")
    })
  }
  
  search(){
    this.reloadTable(1);
  }

  sortBy(option: SortByOptions) {
    // this.sortByValue = option;
    // this.sortByName = this.mapSortBy[option];
    this.reloadTable();
  }

  selectFeederVoltage(e: any) {
    this.currentStatus = e;
    this.reloadTable(1);
  }

  selectUtility(e: any) {
    this.currentUtility = e;
    this.reloadTable(1);
  }

  pageChange(value: any) {
    this.currentPage = value;
    this.reloadTable();
  }

  private subscribeToClickEvent() {
    this.clickObservable
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((e: any) => { 
      this.showUpload = false;
      this.showSortBy = false;
    })
  }

  getFilterParams(){
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return {
      search: this.searchText,
      dateFrom: this.dateFrom? this.dateFrom : null,
      dateTo: this.dateTo ? `${this.dateTo}T23:59:59` : null,
      timezoneValue : timeZone,
      status: this.currentStatus,
      utility: this.currentUtility
    };
  }

  download(){
    let obj = this.getFilterParams();

    this.jobHistoryService.download(obj).subscribe({
      next: (response) => {
        const data = response.body;
        if(data?.status === "OK" || data?.status === "200" ) {
          window.location.href = data.data;
        }
        else if (data?.status === 'Pending' || data?.status === '600'){
          this.toastr.info(data?.message || '');
        }
        else this.toastr.error(data?.message || 'Request failed');
      },
      error: (error) => console.log(error) 
    });
  }

  datesValid() {
    if (this.dateFrom && this.dateTo) {
        const valid: boolean = new Date(this.dateFrom).getTime() < new Date(`${this.dateTo.toString()}T23:59:59`).getTime();
        return valid;
    }
    return false;
  }

  getUtilities() {
    this.utilityService.getUtilitiesForSelect().subscribe({
      next: (data) => {
        this.utilityList = data.data;
        // this.selectedUtilities = this.utilityList.map(s=>s.key);
      },
      error: (error) => console.log(error.statusText)
    }); 
  }
}
