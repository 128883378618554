<div class="reset-password-div">
    <app-login-header></app-login-header>
    <div *ngIf="step == 2">
        <div class="div-center">
            <div class="login-form col-md-3 col-12">
                <div class="password-reset-notification">
                    <div class="reset-password-title">
                        Password reset sent
                    </div>
                    <div>
                        <img src="../../../../assets/Img/survey-home.dd93bd93.png.png" alt="Password icon"/>
                    </div>
                    <div class="reset-sent">
                        A password reset email has been sent to "<span>{{this.email}}</span>".
                        Follow the direction in the email to set new password.
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="login-container" *ngIf="step == 1">
        <div class="div-center">
            <div class="login-form col-md-3 col-12">
            <div class="login-title">Reset password</div>
            <div class="form-group">
                <label for="email">Email <span>*</span></label>
                <input
                [(ngModel)]="email"
                type="text"
                id="email"
                name="email"
                placeholder="Email address"
                />
            </div>
            <button class="login-btn" (click)="resetPassword()">Reset password</button>
    
            <div class="no-account-container">
                <div class="no-account">Go back to</div>
                <div class="sign-up" [routerLink]="['/new-connection/' + utility.name + '/login']">Log in</div>
            </div>
            </div>
        </div>
    </div>
</div>

  