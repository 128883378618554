<div class="reports">
  <div class="reports-column">
    <nav class="sidenav">
      <div class="sidenav-items">
        <a
          class="nav-item"
          (click)="switchMenuItem(ReportMenuItem.Customers)"
          *ngIf="permissionsService.isReportMisalignedAllowed"
          [ngClass]="{
            'item-active':
              reportStateService.reportSelected === ReportMenuItem.Customers
          }"
        >
          <span>{{ "COMMON.MISALIGNED" | translate }}</span>
        </a>
        <a
          class="nav-item full-height"
          (click)="switchMenuItem(ReportMenuItem.DataQualityRejection)"
          *ngIf="permissionsService.isReportDataQualityRejectionAllowed"
          [ngClass]="{
            'item-active':
              reportStateService.reportSelected ===
              ReportMenuItem.DataQualityRejection
          }"
        >
          <span>{{ "ROLE_MANAGEMENT.DATA_QUALITY_REJECTION" | translate }}</span>
        </a>
        <a
          class="nav-item full-height"
          (click)="switchMenuItem(ReportMenuItem.Regularization)"
          *ngIf="permissionsService.isReportRegularizationAllowed"
          [ngClass]="{
            'item-active':
              reportStateService.reportSelected ===
              ReportMenuItem.Regularization
          }"
        >
          <span>{{ "COMMON.REGULARIZATION" | translate }}</span>
        </a>
      </div>
    </nav>
  </div>
  <div class="selected-crud">
    <ng-container [ngSwitch]="reportStateService.reportSelected">
      <report-customers
        *ngSwitchCase="ReportMenuItem.Customers"
      ></report-customers>
      <data-quality-rejection
        *ngSwitchCase="ReportMenuItem.DataQualityRejection"
      ></data-quality-rejection>
      <regularization
        *ngSwitchCase="ReportMenuItem.Regularization"
      ></regularization>
    </ng-container>
  </div>
</div>
