<div class="add-utility-root">
  <div class="ac-header">
    <button (click)="save()" class="btn btn-primary" [ngClass]="saveDisabled ? 'btn btn-disabled' : ''">
      Save
    </button>
    <button class="btn btn-cancel" (click)="cancelClicked()">Cancel</button>
  </div>
  <div class="ac-body section-line" [ngClass]="
      activeCard === 5
        ? 'section-line-last-active'
        : activeCard === 6
        ? 'section-line-finished'
        : ''
    ">
    <form ngNativeValidate id="card1Form" (submit)="continue()" class="card-1"
      [ngClass]="activeCard > 1 ? 'item-line' : ''">
      <div class="card-header">
        <div class="num" [ngClass]="activeCard > 1 ? 'card-number-done' : ''">
          1
        </div>
        <div>Utility information</div>
        <div *ngIf="activeCard > 1" (click)="setActiveCard(1)">Edit</div>
      </div>
      <div *ngIf="activeCard === 1">
        <div class="container card form-wrapper">
          <div class="row">
            <div class="col-6 form-item form-floating mb-3">
              <input [(ngModel)]="name" name="name" type="text" class="form-control" id="floatingInput2" placeholder=" "
                required>
              <label for="floatingInput2">Name</label>
            </div>
          </div>
          <label class="logo-label">Utility logo</label>
          <div *ngIf="!hasImage; else image" class="drag-drop col-12" appDragAndDrop
            (fileDropped)="onFileDropped($event)">
            <input type="file" id="fileDropRef" name="utilityImage" #utilityImage
              accept="image/png, image/jpg, image/jpeg" (change)="fileBrowserHandler($event)" />
            <div class="upload-img">
              <img src="../../assets/icons/upload-arrow.svg" alt="Upload arrow"/>
            </div>
            <label for="fileDropRef">Drag and drop file here or <span class="browse">Browse</span> to upload</label>

          </div>
          <ng-template #image>
            <div class="pb-4">
              <img style="max-width: 100%;" src="{{ blobUrl || imageSrc }}" alt="Uploaded image"/>
              <span (click)="removeImage()">x</span>
            </div>
          </ng-template>
          <div class="row">
            <div class="col-6 form-item form-floating mb-3">
              <input id="color-test" type="color" [(ngModel)]="color" name="floatingInput2" class="input-color" />
              <input name="color" [(ngModel)]="color" cpOutputFormat="hex" class="form-control" id="floatingInput2"
                placeholder=" " readonly>
              <label *ngIf="color===''" for="floatingInput2">Color code</label>
              <label *ngIf="color!=''" for="floatingInput2">{{color}}</label>
  
            </div>
            <select  name="currency-select" (change)="selectCurrency($event)"class="col-6 form-item">
            <option value="" [selected]="!currency" disabled>Currency</option>
            <option [selected]="currency == c.code" *ngFor="let c of currencyList" value="{{ c.code }}">
              {{ c.text }}
            </option>
          </select>
          </div>
         
        </div>
        <div class="btn-continue-container col-6">
          <button class="btn btn-continue" form="card1Form">Continue</button>
        </div>
      </div>
      <div *ngIf="activeCard > 1" class="filled-card">
        <div class="container card">
          <div class="row">
            <div class="col-4">Name: {{name}}</div>
            <div class="col-4">Color code: {{color}}</div>
            <div class="col-4">Currency: {{currency}}</div>
          </div>
        </div>
      </div>
    </form>
    <div class="card-4 item" [ngClass]="activeCard > 2 ? 'item-line' : ''">
      <div class="card-header">
        <div class="num" [ngClass]="
            activeCard < 2
              ? 'card-number-gray'
              : activeCard > 2
              ? 'card-number-done'
              : ''
          ">2</div>
        <div [ngClass]="activeCard < 2 ? 'title-gray' : ''">
          Dashboard Configuration
        </div>
        <div *ngIf="activeCard > 2" (click)="setActiveCard(2)">Edit</div>

      </div>
      <div *ngIf="activeCard === 2" class="filled-card">
        <div class="container card">
          <div class="row">
            <div class="col-6">
              <span class="col-title">Asset</span>
              <div class="headerComponent">
                <div>
                  <label class="switch">
                    <input type="checkbox" [checked]="isChecked([Configuration.Feeders])"
                      (change)="onChange([Configuration.Feeders], $event);">
                    <span class="slider round"></span>
                  </label>
                </div>
                <div>
                  <label class="title">Feeders(33KV,11KV)</label>
                </div>
              </div>
              <div class="headerComponent">
                <div>
                  <label class="switch">
                    <input type="checkbox" [checked]="isChecked([Configuration.DTs])"
                      (change)="onChange([Configuration.DTs], $event);">
                    <span class="slider round"></span>
                  </label>
                </div>
                <div>
                  <label class="title">Distribution Transformers</label>
                </div>
              </div>
              <div class="headerComponent">
                <div>
                  <label class="switch">
                    <input type="checkbox" [checked]="isChecked([Configuration.Poles])"
                      (change)="onChange([Configuration.Poles], $event);">
                    <span class="slider round"></span>
                  </label>
                </div>
                <div>
                  <label class="title">LT Poles</label>
                </div>
              </div>
              <div class="headerComponent">
                <div>
                  <label class="switch">
                    <input type="checkbox" [checked]="isChecked([Configuration.Buildings])"
                      (change)="onChange([Configuration.Buildings], $event);">
                    <span class="slider round"></span>
                  </label>
                </div>
                <div>
                  <label class="title">Buildings</label>
                </div>
              </div>
              <div class="headerComponent">
                <div>
                  <label class="switch">
                    <input type="checkbox" [checked]="isChecked([Configuration.Meters])"
                      (change)="onChange([Configuration.Meters], $event);">
                    <span class="slider round"></span>
                  </label>
                </div>
                <div>
                  <label class="title">Meters</label>
                </div>
              </div>
            </div>
            <div class="col-6">
              <span class="col-title">Operations</span>
              <div class="headerComponent">
                <div>
                  <label class="switch">
                    <input type="checkbox" [checked]="isChecked([Configuration.Survey])"
                      (change)="onChange([Configuration.Survey], $event);">
                    <span class="slider round"></span>
                  </label>
                </div>
                <div>
                  <label class="title">Survey</label>
                </div>
              </div>
              <div class="headerComponent">
                <div>
                  <label class="switch">
                    <input type="checkbox" [checked]="isChecked([Configuration.Installation])"
                      (change)="onChange([Configuration.Installation], $event);">
                    <span class="slider round"></span>
                  </label>
                </div>
                <div>
                  <label class="title">Installation</label>
                </div>
              </div>
              <div class="headerComponent">
                <div>
                  <label class="switch">
                    <input type="checkbox" [checked]="isChecked([Configuration.WorkOrder])"
                      (change)="onChange([Configuration.WorkOrder], $event);">
                    <span class="slider round"></span>
                  </label>
                </div>
                <div>
                  <label class="title">Work order</label>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="btn-continue-container col-6">
          <button class="btn btn-continue" type="button" (click)="continue()">Continue</button>
        </div>
      </div>

      <div *ngIf="activeCard > 2" class="filled-card">
        <div class="container card">
          <div class="row">
            <div class="col-6">
              <span class="col-title row">Asset</span>
              <span class="col-subtitle row">Feeders(33KV,11KV): {{isSelected(Configuration.Feeders)}}</span>
              <span class="col-subtitle row">Distribution Transformers: {{isSelected(Configuration.DTs)}}</span>
              <span class="col-subtitle row">LT Poles: {{isSelected(Configuration.Poles)}}</span>
              <span class="col-subtitle row">Buildings: {{isSelected(Configuration.Buildings)}}</span>
              <span class="col-subtitle row">Meters: {{isSelected(Configuration.Meters)}}</span>

            </div>
            <div class="col-6">
              <span class="col-title row ">Operations</span>
              <span class="col-subtitle row ">Survey: {{isSelected(Configuration.Survey)}}</span>
              <span class="col-subtitle row ">Installation: {{isSelected(Configuration.Installation)}}</span>
              <span class="col-subtitle row ">Work order: {{isSelected(Configuration.WorkOrder)}}</span>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="card-3 item" [ngClass]="activeCard > 3 ? 'item-line' : ''">
      <div class="card-header">
        <diV class="num" [ngClass]="
            activeCard < 3
              ? 'card-number-gray'
              : activeCard > 3
              ? 'card-number-done'
              : ''
          ">3</diV>
        <div [ngClass]="activeCard < 3 ? 'title-gray' : ''">
          Translation
        </div>
        <div *ngIf="activeCard > 3" (click)="setActiveCard(3)">Edit</div>

      </div>
      <div *ngIf="activeCard === 3" class="filled-card">
        <div class="container card">
          <div class="row">
            <div class="container-fluid border-bottom">
              <table>
                <tr class="header-row">
                  <th>KEY</th>
                  <th>VALUE</th>
                </tr>
                <tr class="content-wrapper" (click)="selectTranslation(item)" *ngFor="let item of translationKeys"
                  data-bs-toggle="modal" data-bs-target="#changeTranslation">
                  <td>{{item.key}}</td>
                  <td>{{item.value}}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="btn-continue-container col-6">
          <button class="btn btn-continue" type="button" (click)="continue()">Continue</button>
        </div>
      </div>
      <div *ngIf="activeCard > 3" class="filled-card">
        <div class="container card">
          <span class="col-title">Translations</span>
          <div class="row translations">
            <div class="col-6">
              <div class="col-subtitle row" *ngFor="let item of translationKeys">
                {{item.key}} : {{item.value}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <form ngNativeValidate id="card4Form" (submit)="continue()" class="card-4"
      [ngClass]="activeCard > 4 ? 'item-line' : ''">
      <div class="card-header">
        <div class="num" [ngClass]="
            activeCard < 4
              ? 'card-number-gray'
              : activeCard > 4
              ? 'card-number-done'
              : ''
          ">4</div>
        <div [ngClass]="activeCard < 4 ? 'title-gray' : ''">
          New Connection Consent Configuration
        </div>
        <div *ngIf="activeCard > 4" (click)="setActiveCard(4)">Edit</div>
      </div>
      <div *ngIf="activeCard === 4">
        <div class="container card form-wrapper">
          <div class="row">
            <div class="col-12 form-item form-floating mb-3">
              <input [(ngModel)]="commission" name="commission" type="text" class="form-control" id="floatingInput2"
                placeholder=" ">
              <label for="floatingInput2">Regulatory Commission</label>
            </div>
            <div class="col-12 form-item form-floating mb-3">
              <input [(ngModel)]="reform" name="reform" type="text" class="form-control" id="floatingInput2"
                placeholder=" ">
              <label for="floatingInput2">Electricity Reform</label>
            </div>
            <div class="col-12 form-item form-floating mb-3">
              <input [(ngModel)]="regulation" name="regulation" type="text" class="form-control" id="floatingInput2"
                placeholder=" ">
              <label for="floatingInput2">Network Regulation</label>
            </div>
            <div class="col-12 form-item form-floating mb-3">
              <input [(ngModel)]="management" name="management" type="text" class="form-control" id="floatingInput2"
                placeholder=" ">
              <label for="floatingInput2">Electricity Management</label>
            </div>
            <div class="col-12 form-item form-floating mb-3">
              <input [(ngModel)]="country" name="country" type="text" class="form-control" id="floatingInput2"
                placeholder=" ">
              <label for="floatingInput2">Country</label>
            </div>
          </div>

        </div>
        <div class="btn-continue-container col-6">
          <button class="btn btn-continue" form="card4Form">Continue</button>
        </div>
      </div>
      <div *ngIf="activeCard > 4" class="filled-card">
        <div class="container card">
          <div class="row">
            <div class="col-12">Regulatory Commission: {{commission}}</div>
            <div class="col-12">Electricity Reform: {{reform}}</div>
            <div class="col-12">Network Regulation: {{regulation}}</div>
            <div class="col-12">Electricity Management: {{management}}</div>
            <div class="col-12">Country: {{country}}</div>
          </div>
        </div>
      </div>
    </form>
    <form ngNativeValidate id="card5Form" (submit)="continue()" class="card-5"
      [ngClass]="activeCard > 5 ? 'item-line' : ''">
      <div class="card-header">
        <div class="num" [ngClass]="
            activeCard < 5
              ? 'card-number-gray'
              : activeCard > 5
              ? 'card-number-done'
              : ''
          ">5</div>
        <div [ngClass]="activeCard < 5 ? 'title-gray' : ''">
          Bank Account Information
        </div>
        <div *ngIf="activeCard > 5" (click)="setActiveCard(5)">Edit</div>
      </div>
      <div *ngIf="activeCard === 5">
        <div class="container card form-wrapper">
          <div class="row">
            <div class="col-12 form-item form-floating mb-3">
              <input [(ngModel)]="bankName" name="bankName" type="text" class="form-control" id="floatingInput2"
                placeholder=" ">
              <label for="floatingInput2">Bank Name</label>
            </div>
            <div class="col-12 form-item form-floating mb-3">
              <input [(ngModel)]="accountName" name="accountName" type="text" class="form-control" id="floatingInput2"
                placeholder=" ">
              <label for="floatingInput2">Account Name</label>
            </div>
            <div class="col-12 form-item form-floating mb-3">
              <input [(ngModel)]="accountNumber" name="accountNumber" type="text" class="form-control" id="floatingInput2"
                placeholder=" ">
              <label for="floatingInput2">Account Number</label>
            </div>
          </div>

        </div>
        <div class="btn-continue-container col-6">
          <button class="btn btn-continue" form="card5Form">Continue</button>
        </div>
      </div>
      <div *ngIf="activeCard > 5" class="filled-card">
        <div class="container card">
          <div class="row">
            <div class="col-12">Bank Name: {{bankName}}</div>
            <div class="col-12">Account Name: {{accountName}}</div>
            <div class="col-12">Account Number: {{accountNumber}}</div>
          </div>
        </div>
      </div>
    </form>
    <form ngNativeValidate id="card6Form" (submit)="continue()" class="card-6">
      <div class="card-header">
        <div class="num" [ngClass]="
            activeCard < 6
              ? 'card-number-gray'
              : activeCard > 6
              ? 'card-number-done'
              : ''
          ">6</div>
        <div [ngClass]="activeCard < 6 ? 'title-gray' : ''">
          Contact Person </div>
        <div *ngIf="activeCard > 6" (click)="setActiveCard(6)">Edit</div>
      </div>
      <div *ngIf="activeCard === 6">
        <div class="container card form-wrapper">
          <div class="row">
            <div class="col-12 form-item form-floating mb-3">
              <input [(ngModel)]="firstName" name="firstName" type="text" class="form-control" id="floatingInput2"
                placeholder=" " required>
              <label for="floatingInput2">First Name</label>
            </div>
            <div class="col-12 form-item form-floating mb-3">
              <input [(ngModel)]="lastName" name="lastNamne" type="text" class="form-control" id="floatingInput2"
                placeholder=" " required>
              <label for="floatingInput2">Last Name</label>
            </div>
            <div class="col-12 form-item form-floating mb-3">
              <input [(ngModel)]="eMail" name="eMail" type="text" class="form-control" id="floatingInput2"
                placeholder=" " required>
              <label for="floatingInput2">E-Mail</label>
            </div>


          </div>

        </div>
        <div class="btn-continue-container col-6">
          <button class="btn btn-continue" form="card6Form">Continue</button>
        </div>
      </div>
      <div *ngIf="activeCard > 6" class="filled-card">
        <div class="container card">
          <div class="row">
            <div class="col-12">First Name: {{ firstName}}</div>
            <div class="col-12">Last Name: {{lastName}}</div>
            <div class="col-12">E-Mail: {{eMail}}</div>
          </div>
        </div>
      </div>
    </form>


  </div>

  <div class="modal fade" id="changeTranslation" tabindex="-1" aria-labelledby="changeTranslationTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">Change translation</h5>
          <button type="button" #closeModal class="btn-cancel-modal" data-bs-dismiss="modal" aria-label="Close">
            Cancel
          </button>
        </div>
        <form id="addForm" class="form-wrapper" ngNativeValidate (submit)="saveTranslation()">
          <div class="modal-body">
            <div class="form-item form-floating mb-3">
              <input name="name" type="text" [(ngModel)]="selectedTranslation.key" [disabled]="true"
                class="form-control" required placeholder=" " id="selectedTtranslationKey"/>
              <label for="selectedTtranslationKey">Key</label>
            </div>
            <div class="form-item form-floating mb-3">
              <input name="code" type="text" [(ngModel)]="selectedTranslation.value" class="form-control" required
                placeholder=" " id="selectedTtranslationVal"/>
              <label for="selectedTtranslationVal">Value</label>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-primary" form="addForm">Save</button>
          </div>
        </form>
      </div>
    </div>
  </div>