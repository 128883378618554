import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AppConfig } from "../config/config";
import { Tariff } from "../models/tariff.model";
import { ResponsePackage } from "../models/util.model";

@Injectable({
    providedIn: 'root'
  })
export class TariffService {
    
    private pathApi = this.config.setting['pathApi'] + "tariff/";
    private headers = new HttpHeaders({'Content-Type': 'application/json'});

    constructor(private http: HttpClient, private config: AppConfig) {}

    public getAllForSelect(utilityId: number | null = null) {
      if(utilityId) {
        return this.http.get<ResponsePackage<Tariff[]>>(`${this.pathApi}getAllForSelect/${utilityId}`,{headers: this.headers, observe: 'response'});
      }
      else {
        return this.http.get<ResponsePackage<Tariff[]>>(`${this.pathApi}getAllForSelect`,{headers: this.headers, observe: 'response'});
      }
    }

    getAll(dataIn: any): Observable<any> {
        return this.http.post(`${this.pathApi}getAll`, dataIn, {observe: 'response'});
      }
    
    save(tariff: Tariff): Observable<any> {
      return this.http.post(`${this.pathApi}save`, tariff, {observe: 'response'});
    }
  
    delete(id: number): Observable<any> {
      return this.http.delete(`${this.pathApi}delete/${id}`, {observe: 'response'});
    }
}