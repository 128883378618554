import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-utility-dropdown',
  templateUrl: './utility-dropdown.component.html',
  styleUrls: ['./utility-dropdown.component.scss']
})
export class UtilityDropdownComponent implements OnInit {
  utilityList: any[];
  selectedUtilities: number[] = [];
  selectedUtilityId: number = 0;
  @Output() selectUtility = new EventEmitter();

  constructor(private utilityService: UtilityService) { }

  ngOnInit(): void {
    this.getUtilities();
  }

  getUtilities(){
    this.utilityService.getUtilitiesForSelect().subscribe({
      next: (data) => {
        this.utilityList = data.data;
        this.selectedUtilities = this.utilityList.map(s=>s.key);
        this.selectUtility.emit(this.selectedUtilities)
      },
      error: (error) => console.log(error.statusText)
    }); 
  }

  selectUtilityChild(e: any) {
    if(e.target.value != '') {
      this.selectedUtilityId = parseInt(e.target.value);
      if(this.selectedUtilityId == 0)
        this.selectedUtilities = this.utilityList.map(s=>s.key)
      else  
        this.selectedUtilities = [this.selectedUtilityId]
    }
    else {
      this.selectedUtilityId = 0;
    }  
    this.selectUtility.emit(this.selectedUtilities)
  }

}
