import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ToastrService } from 'ngx-toastr';
import { Subject, debounceTime } from 'rxjs';
import { BPSAdminLevel } from 'src/app/models/user.model';
import { UtilityList } from 'src/app/models/utility.model';
import { AuthService } from 'src/app/services/auth.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-utility-list',
  templateUrl: './utility-list.component.html',
  styleUrls: ['./utility-list.component.scss']
})
export class UtilityListComponent implements OnInit {
  private jwtService = new JwtHelperService();

  utilities:UtilityList[]=[];
  count: number = 2;
  pageSize: number = 5;
  currentPage: number = 1;
  searchText: string = '';
  dateFrom: string;
  dateTo: string;
  searchTextUpdate = new Subject<string>();
  bpsAdminLevel: BPSAdminLevel;

  constructor(private utilityService:UtilityService,    
              private toastr: ToastrService,
              private authService: AuthService,
              public permissionsService: PermissionsService,
              private router: Router
    ) { }

  ngOnInit(): void {
    this.bpsAdminLevel = parseInt(localStorage.getItem('bpsAdminLevel') || "0");
    this.reloadTable();
    this.searchTextUpdate.pipe(
      debounceTime(500))
      .subscribe(value => {
        if(this.searchText=="")
          this.search();
      });
  }
  search() {
    this.reloadTable(1);
  }
 onSetStatus(id: number, status: string){

  let newStatus = '';
      if (status.toLowerCase() === "active") {
        newStatus = "Inactive"
      } else {
        newStatus = "Active"
      }
      let obj = {
        "id": id,
        "utilityStatus": newStatus
      };
  this.utilityService.setStatus(obj).subscribe(x => {
    if (x.status === "200" || x.status === "OK") {
      this.toastr.success('Status changed successfully.');
      this.reloadTable();
    }
    else
      this.toastr.error(x.message);
  }, error => {
    this.toastr.error('An error occurred.');
  });
 }
  reloadTable(page: any = null) {
    if (page) this.currentPage = page;
    let obj = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize,
      },
      filterParams: {
        search: this.searchText,
        dateTo: this.dateTo ? `${this.dateTo}T23:59:59` : null,
        dateFrom: this.dateFrom
      },
    };

    this.getAll(obj);
  }

  private getAll(obj : any){
    this.utilityService.getall(obj).subscribe({
      next: (response) => {
        const responseData = response;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.utilities = responseData.data.data;
          console.log(responseData.data.data)
          this.count = responseData.data.count;
          if(responseData.message !="")
          {
            this.toastr.warning(responseData.message);
          }
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => this.toastr.error('Error occured'),
    });
  }
  pageChange(value: any) {
    this.currentPage = value;
    this.reloadTable();
  }
  datesValid() {
    if (this.dateFrom && this.dateTo) {
        const valid: boolean = new Date(this.dateFrom).getTime() < new Date(`${this.dateTo.toString()}T23:59:59`).getTime();
        return valid;
    }
    return false;
  }

  openUtilityPortal(utilityId: number, subDomain: string) {
    let token = localStorage.getItem('token');
    if (!token) {
      this.toastr.info('Please log in again.');
      return;
    }

    let currentUser = this.jwtService.decodeToken(token);
    let email = currentUser.email;

    this.authService.bpsLoginFirstStep(email, utilityId).subscribe(
      loginResponse => {

        const protocol = window.location.protocol;
        const url = `${protocol}//${subDomain}/login?email=${loginResponse.data.email}&p=${loginResponse.data.hp}&utilityId=${loginResponse.data.utilityId}`;
        window.location.href = url;
      },
      (error) => {
        this.toastr.error('An error occurred.');
      }
    );
  }

  loginCommon(x: any, subDomain: string) {
    if (x.status === 'OK' || x.status === '200') {
      localStorage.setItem('token', x.data.token);
      localStorage.setItem(
        'translation',
        JSON.stringify(x.data.translation)
      );
      
      const user: any = this.authService.decodeJWTToken();
      localStorage.setItem('utilityId', user?.utilityId ? user?.utilityId : 0);
      localStorage.setItem('isAdmin' , '1');
      localStorage.setItem('bpsAdminLevel' , user?.bpsAdminLevel ? user?.bpsAdminLevel : 0);
      if (this.permissionsService.checkIfhasUtility()) {
        if (x.data?.utility?.dashboardConfiguration) {
            localStorage.setItem(
                'configuration',
                JSON.stringify(x.data.utility.dashboardConfiguration)
            );
        }

        if (x.data?.utility?.colorCode) {
            localStorage.setItem('colorCode', x.data.utility.colorCode);
            this.setColors(x.data.utility.colorCode);
        }

        if (x.data?.utility?.imageUrl) {
            localStorage.setItem('logoUrl', x.data.utility.imageUrl);
        }

        if (x.data?.utility?.longitude) {
            localStorage.setItem('longitude', x.data.utility.longitude);
        }

        if (x.data?.utility?.latitude) {
            localStorage.setItem('latitude', x.data.utility.latitude);
        }

        // this.router.navigate(['dashboard']);
        const protocol = window.location.protocol;
        const url = `${protocol}//${subDomain}/dashboard`;
        window.location.href = url;

        let notVisibleFields: string[] = x.data.utility.notVisibleFields || [];

        if (x.data?.utility?.notVisibleFields && x.data?.utility?.notVisibleFields !== 'undefined') {
            localStorage.setItem('notVisibleFields', JSON.stringify(notVisibleFields));
        }
      } //bps admin user
      else 
      {
        localStorage.setItem('longitude', '0.381388470567412');
        localStorage.setItem('latitude','5.906986824806585');
        this.router.navigate(['dashboard-bps']);
        this.setBpsAdminColors();
      }

      this.permissionsService.getPermissions();
    } else this.toastr.error(x.message);
  }

  setBpsAdminColors() {
    document.documentElement.style.setProperty('--primary-color', '#03423E');
    document.documentElement.style.setProperty('--bg-color', '#F5FBFA');
    document.documentElement.style.setProperty(
      '--table-root-bg-color',
      '#EBF1F1'
    );
    document.documentElement.style.setProperty('--card-color', '#e4ecea');
    document.documentElement.style.setProperty('--bubble-color', '#688F8D');
  }

  setColors(color: string) {
    document.documentElement.style.setProperty('--primary-color', color);
    document.documentElement.style.setProperty('--card-color', color + '22');
    document.documentElement.style.setProperty('--bg-color', color + '08');
    document.documentElement.style.setProperty('--bubble-color', color + '95');
    document.documentElement.style.setProperty('--bubble-color', color + '95');
  }

  openDetailsInNewTab(id: any) {
    const currentUrl = this.router.url;
    const url = currentUrl + '/details/' + id;
    window.open(url, '_blank');
  }
}
