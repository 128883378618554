import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'year'
})
export class YearPipe implements PipeTransform{
    transform(value: any)  {
        if(value === undefined || value === null){
            return "----";
        }
        if(value === 1){
            return value + " year";
        }

        if(value > 1 || value === 0){
            return value + " years";
        }

        return value;
    }
}