import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from 'src/app/config/config';
import { KycAccountData } from '../_models/kyc-account-data';

@Injectable({
  providedIn: 'root',
})
export class KycCustomerApiService {
  private pathApi = this.config.setting['pathApi'] + 'kyc/DataKyc/';
  constructor(private http: HttpClient, private config: AppConfig) {}

  getCustomer() {
    return this.http.get<any>(`${this.pathApi}getKycAccountData`);
  }

  getKycPdf() {
    return this.http.get<any>(`${this.pathApi}getKycPdf`);
  }

  saveCustomer(data: KycAccountData) {
    data.engineerDate = !data.engineerDate ? null : data.engineerDate;
    return this.http.post<any>(`${this.pathApi}saveKycAccountData`, data);
  }

  submitRegistration(id: number){
    return this.http.get<any>(`${this.pathApi}submitRegistration`);
  }

  updatePassword(obj: any) {
    return this.http.post(`${this.pathApi}updatePassword`, obj);
  }

  changeProfilePicture(image: File) {
    let formData: FormData = new FormData();
    formData.append('Image', image, image.name);
    return this.http.post(`${this.pathApi}changeProfilePicture`, formData);
  }

  setAccountKYCStep(stepNumber: number){
    return this.http.post<any>(`${this.pathApi}setAccountKYCStep`, stepNumber);
  }
}
