import { Component, Input, OnInit, Output, EventEmitter, ElementRef, HostListener, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Customer } from 'src/app/models/customer.model';
import { InstallationAssign } from 'src/app/models/installation.model';
import { User } from 'src/app/models/user.model';
import { CustomerService } from 'src/app/services/customer.service';
import { InstallationService } from 'src/app/services/installation.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-add-installation',
  templateUrl: './add-installation.component.html',
  styleUrls: ['./add-installation.component.scss'],
})
export class AddInstallationComponent implements OnInit {
  @Input() isPopUp: boolean = false;
  @Output() closePopUpChange = new EventEmitter();
  @ViewChild('dropdownContainer') dropdownContainer: ElementRef;

  public searchCustomers: string = '';
  public customerLoadCount: number = 6;
  mobileUsersForSelect: any[];
  customersForSelectAll: Customer[];
  customersForSelect: Customer[];

  installation: InstallationAssign = new InstallationAssign();

  filterText: string = "";
  isDropdownOpened: boolean = false;
  filteredMobileUsers: User[] = [];
  tempAssignedUserId: number = 0;
  
  constructor(
    private toastr: ToastrService,
    private userService: UserService,
    private customerService: CustomerService,
    private installationService: InstallationService,
    private elementRef: ElementRef
  ) {}

  ngOnInit(): void {
    this.loadCustomersForOptions();
  }
  isChecked(val: number) {
    if (this.installation.customers.includes(val)) return true;
    else return false;
  }
  closeModal() {
    this.closePopUpChange.emit();
  }
  search() {
    this.loadCustomersForOptions();
  }
  loadAgentsForOptions() {
    this.userService.getMobileUsersForSelect(this.filterText).subscribe(
      (data) => {
        this.mobileUsersForSelect = data.data;
        if(this.mobileUsersForSelect.length == 0) {
          this.isDropdownOpened = false;
        }
        else {
          this.isDropdownOpened = true;
        }
      },
      (error) => {
        this.toastr.error('An error ocurred.');
      }
    );
  }
  loadCustomersForOptions() {
    let obj = {
      pageInfo: {
        page: 1,
        pageSize: this.customerLoadCount,
      },
      filterParams: {
        search: this.searchCustomers,
      },
    };

    this.customerService
      .getCustomersForInstallationAssignOptions(obj)
      .subscribe(
        (response) => {
          this.customersForSelect = response.body.data;
          this.customersForSelectAll = response.body.data;
        },
        (error) => {
          this.toastr.error('An error occurred.');
        }
      );
  }
  submit() {
    if(this.mobileUsersForSelect.filter(x => x.fullName == this.filterText.trim()).length > 0) {
      this.installation.assignedUserId = this.mobileUsersForSelect.filter(x => x.fullName == this.filterText.trim())[0].id;
      this.tempAssignedUserId = this.installation.assignedUserId;
    }
    else {
      this.tempAssignedUserId = 0;
      this.toastr.warning('Please select valid user.');
      return;
    }

    this.installationService.assignInstalations(this.installation).subscribe(
      (response) => {
        this.toastr.success('Installation reassigned');
        this.closePopUpChange.emit();

      },
      (error) => {
        this.toastr.error('An error occurred.');
      }
    );
  }
  onChangeBu(val: number) {
    if (this.installation.customers.includes(val))
      this.installation.customers = this.installation.customers.filter(
        (item) => item != val
      );
    else this.installation.customers.push(val);
  }

  selectFieldAgent(id: number) {
    this.isDropdownOpened = false;
    this.installation.assignedUserId = id;
    this.tempAssignedUserId = id;
    var mobUser = this.mobileUsersForSelect.find(x => x.id == id);
    this.filterText = mobUser!.fullName;
  }

  filterMobileUsers() {
    if(this.filterText.length < 3) {
      return;
    }
    this.isDropdownOpened = true;
    this.tempAssignedUserId = 0;
    this.loadAgentsForOptions();
  }

  @HostListener('document:click', ['$event'])
  handleDocumentClick(event: MouseEvent) {
    const clickedInside = this.isDropdownOpened && this.dropdownContainer?.nativeElement?.contains(event.target);
    if (!clickedInside) {
      this.isDropdownOpened = false;
    }
  }
}
