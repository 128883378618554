import { Component } from '@angular/core';
import { ModalService } from '../../_services/modal.service';

@Component({
  selector: 'app-modal',
  template: `
    <div class="modal-container">
        <div [ngSwitch]="modalService.currentModal">
            <div *ngSwitchCase="'agreement-dialog'">
                <app-agreement-dialog ></app-agreement-dialog>
            </div>
            <div *ngSwitchDefault>
                <p>This is default content</p>
            </div>
        </div>
    </div>
    `,
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent {
  constructor(public modalService: ModalService) { }
}
