import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {
  UploadFileInfo,
  UploadFileType,
} from 'src/app/models/uploadFileInfo.model';
import { BuildingService } from 'src/app/services/building.service';
import { CommonService } from 'src/app/services/common.service';
import { CustomerService } from 'src/app/services/customer.service';
import { DtService } from 'src/app/services/dt.service';
import { PoleService } from 'src/app/services/pole.service';
import { MeterService } from 'src/app/services/meter.service';

import { UploadFileInfoService } from 'src/app/services/uploadFileInfo.service';
import { SurveyService } from 'src/app/services/survey.service';
import { UserService } from 'src/app/services/user.service';
import { PermissionsEnum } from 'src/app/models/role.model';
import { PermissionsService } from 'src/app/services/permissions.service';
import { MeterManagementService } from 'src/app/services/meter-management.service';
import { BillingAccountService } from 'src/app/services/billing-account.service';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
})
export class UploadComponent implements OnInit {
  uploads: UploadFileInfo[];
  uploadType: string = '';

  uploadFile: File | null;
  file: string | ArrayBuffer;
  action: string = '';
  pageSize: number = 3;
  currentPage: number = 1;
  count: number = 2;
  utilityId: number = 0;

  constructor(
    private customersService: CustomerService,
    private toastr: ToastrService,
    private commonService: CommonService,
    private uploadFileService: UploadFileInfoService,
    private route: ActivatedRoute,
    private buildingService: BuildingService,
    private surveyService: SurveyService,
    private poleService: PoleService,
    private dtService: DtService,
    private userService: UserService,
    private meterService: MeterService,
    private permissionsService: PermissionsService,
    private meterStockService: MeterManagementService,
    private billingAccountService:BillingAccountService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.uploadType = this.route.snapshot.paramMap.get('type') || '';
    if((this.uploadType === '8' && !this.permissionsService.isUserManagementBulkAssignAllowed) || (this.uploadType === '9' && !this.permissionsService.isUserManagementCreateEditAllowed)){
      this.router.navigate([''])
      this.toastr.error("You don't have permission");
    }
    this.utilityId = parseInt(
      this.route.snapshot.paramMap.get('utilityId') || ''
    );
    this.reloadTable();
  }

  fileBrowserHandler($event: any) {
    this.uploadFile = $event.target.files.item(0);
    const reader = new FileReader();
    this.file = this.uploadFile!.name;
    reader.readAsDataURL(this.uploadFile!);

    this.handleUploadForm();
  }

  onFileDropped($event: any) {
    this.uploadFile = $event.item(0);
    const reader = new FileReader();

    this.file = this.uploadFile!.name;

    reader.readAsDataURL(this.uploadFile!);
    this.handleUploadForm();
  }

  handleUploadForm() {
    let data = new FormData();
    data.append('file', this.uploadFile!, this.uploadFile?.name);

    switch (this.uploadType) {
      case '1':
        this.uploadBuildingsFile(data);
        break;
      case '2':
        this.uploadCustomersFile(data);
        break;
      case '3':
        this.uploadPolesFile(data);
        break;
      case '4':
        this.uploadDTFile(data);
        break;
      case '5':
        this.uploadMetersFile(data);
        break;
      case '6':
        this.uploadSurveysFile(data);
        break;
      case '7':
        this.uploadDTCrudFile(data);
        break;
      case '8':
        this.usersUploadTemplate(data, true);
        break;
      case '9':
        this.usersUploadTemplate(data, false);
        break;
      case '10':
        this.uploadMetersStockFile(data);
        break;
      case '11':
        this.uploadRegularizationFile(data);
        break;
      case '021':
        this.uploadBillAccounts(data);
        break;
      default:
        break;
    }
  }
  uploadBillAccounts(data: FormData) {
    this.billingAccountService.uploadFile(data, this.utilityId).subscribe({
      next: (response: any) => {
        if (response.status === 'OK') {
          this.toastr.success(response.message);
        } else if (
          response.status === 'Pending' ||
          response.status === '600'
        ) {
          this.toastr.info(response.message);
        } else {
          this.toastr.error(response.message);
          if (response.data && response.data.length > 0)
            window.location.href = response.data;
        }

        this.reloadTable();
        this.uploadFile = null;
        this.file = '';
        setTimeout(() => {
          window.location.reload();
        }, 4000);
      },
      error: (error) => console.log(error),
    });
  }
  uploadMetersStockFile(data: FormData) {
    this.meterStockService.uploadFile(data, this.utilityId).subscribe(
      (response) => {
        if (response.status === 'OK') {
          this.toastr.success(response.message);
        } else if (response.status === 'Pending' || response.status === '600') {
          this.toastr.info(response.message);
          if(response.data && response.data.path)
            window.location.href = response.data.path;
        } else {
          this.toastr.error(response.message);
          if (response.data && response.data.length > 0) {
            window.location.href = response.data;
          }
        }

        this.reloadTable();
        this.uploadFile = null;
        this.file = '';
        setTimeout(() => {
          window.location.reload();
        }, 4000);
      },
      (error) => {
        this.toastr.error('An error occurred.');
      }
    );
  }

  uploadDTFile(data: FormData) {
    this.dtService.uploadFile(data, this.utilityId).subscribe({
      next: (response: any) => {
        if (response.status === 'OK') {
          this.toastr.success(response.message);
        } else if (
          response.status === 'Pending' ||
          response.status === '600'
        ) {
          this.toastr.info(response.message);
        } else {
          this.toastr.error(response.message);
          if (response.data && response.data.length > 0)
            window.location.href = response.data;
        }

        this.reloadTable();
        this.uploadFile = null;
        this.file = '';
        setTimeout(() => {
          window.location.reload();
        }, 4000);
      },
      error: (error) => console.log(error),
    });
  }

  uploadDTCrudFile(data: FormData) {
    this.dtService.uploadFile(data, this.utilityId, 'true').subscribe({
      next: (response: any) => {
        if (response.status === 'OK') {
          this.toastr.success(response.message);
        }else if (
          response.status === 'Pending' ||
          response.status === '600'
        ) {
          this.toastr.info(response.message);
          if(response.data && response.data.path)
            window.location.href = response.data.path;
        } else {
          this.toastr.error(response.message);
          if (response.data && response.data.length > 0)
            window.location.href = response.data;
        }

        this.reloadTable();
        this.uploadFile = null;
        this.file = '';
        setTimeout(() => {
          window.location.reload();
        }, 4000);
      },
      error: (error) => console.log(error),
    });
  }

  usersUploadTemplate(data: FormData, isBulkReassign: boolean) {
    this.userService.uploadFile(data, this.utilityId, isBulkReassign).subscribe(
      (response) => {
        if (response.status === 'OK') {
          this.toastr.success(response.message);
        } else {
          this.toastr.error(response.message);
          if (response.data && response.data.length > 0)
            window.location.href = response.data;
        }

        this.reloadTable();
        this.uploadFile = null;
        this.file = '';
        setTimeout(() => {
          window.location.reload();
        }, 4000);
      },
      (error) => {
        this.toastr.error('An error occurred.');
      }
    );
  }

  uploadCustomersFile(data: FormData) {
    let isNewConnection =
      this.route.snapshot.paramMap.get('isNewConnection') || '';
    this.customersService
      .uploadFile(data, isNewConnection, this.utilityId)
      .subscribe({
        next: (response: any) => {
          console.log(response);
          if (response.status === 'OK') {
            this.toastr.success(response.message);
          } else if (
            response.status === 'Pending' ||
            response.status === '600'
          ) {
            this.toastr.info(response.message);
            if(response.data && response.data.path)
              window.location.href = response.data.path;

          } else {
            this.toastr.error(response.message);
            if (response.data && response.data.length > 0)
              window.location.href = response.data;
          }

          this.reloadTable();
          this.uploadFile = null;
          this.file = '';
          setTimeout(() => {
            window.location.reload();
          }, 4000);
        },
        error: (error) => console.log(error),
      });
  }

  uploadRegularizationFile(data: FormData) {
    this.customersService
      .uploadRegularizationFile(data, this.utilityId)
      .subscribe({
        next: (response: any) => {
          if (response.status === 'OK') {
            this.toastr.success(response.message);
          } else if (
            response.status === 'Pending' ||
            response.status === '600'
          ) {
            this.toastr.info(response.message);
            if(response.data && response.data.path)
              window.location.href = response.data.path;

          } else {
            this.toastr.error(response.message);
            if (response.data && response.data.length > 0)
              window.location.href = response.data;
          }

          this.reloadTable();
          this.uploadFile = null;
          this.file = '';
          setTimeout(() => {
            window.location.reload();
          }, 4000);
        },
        error: (error) => console.log(error),
      });
  }

  uploadPolesFile(data: FormData) {
    this.poleService.uploadFile(data, this.utilityId).subscribe(
      (response) => {
        if (response.status === 'OK') {
          this.toastr.success(response.message);
        } else if (
          response.status === 'Pending' ||
          response.status === '600'
        ) {
          this.toastr.info(response.message);
          if(response.data && response.data.path)
            window.location.href = response.data.path;
        } else {
          this.toastr.error(response.message);
          if (response.data && response.data.length > 0)
            window.location.href = response.data;
        }

        this.reloadTable();
        this.uploadFile = null;
        this.file = '';
        setTimeout(() => {
          window.location.reload();
        }, 4000);
      },
      (error) => {
        this.toastr.error('An error occurred.');
      }
    );
  }

  uploadBuildingsFile(data: FormData) {
    this.buildingService.uploadFile(data, this.utilityId).subscribe(
      (response) => {
        console.log(response);
        if (response.status === 'OK') {
          this.toastr.success(response.message);
        } else if (response.status === 'Pending' || response.status === '600') {
          this.toastr.info(response.message);
          if(response.data && response.data.path)
            window.location.href = response.data.path;
        } else {
          this.toastr.error(response.message);
          if (response.data && response.data.length > 0) {
            window.location.href = response.data;
          }
        }

        this.reloadTable();
        this.uploadFile = null;
        this.file = '';
        setTimeout(() => {
          window.location.reload();
        }, 4000);
      },
      (error) => {
        this.toastr.error('An error occurred.');
      }
    );
  }
  uploadMetersFile(data: FormData) {
    this.meterService.uploadFile(data, this.utilityId).subscribe(
      (response) => {
        if (response.status === 'OK') {
          this.toastr.success(response.message);
        } else if (response.status === 'Pending' || response.status === '600') {
          this.toastr.info(response.message);
        } else {
          this.toastr.error(response.message);
          if (response.data && response.data.length > 0) {
            window.location.href = response.data;
          }
        }

        this.reloadTable();
        this.uploadFile = null;
        this.file = '';
        setTimeout(() => {
          window.location.reload();
        }, 4000);
      },
      (error) => {
        this.toastr.error('An error occurred.');
      }
    );
  }

  uploadSurveysFile(data: FormData) {
    this.surveyService.uploadFile(data, this.utilityId).subscribe(
      (response) => {
        if (response.status === 'OK') {
          this.toastr.success(response.message);
        } else {
          this.toastr.error(response.message);
          if (response.data && response.data.length > 0) {
            window.location.href = response.data;
          }
        }

        this.reloadTable();
        this.uploadFile = null;
        this.file = '';
        setTimeout(() => {
          window.location.reload();
        }, 4000);
      },
      (error) => {
        this.toastr.error('An error occurred.');
      }
    );
  }
  generateTemplate() {
    switch (this.uploadType) {
      case '1':
        this.getBuildingsTemplate();
        break;
      case '2':
        this.getCustomersTemplate();
        break;
      case '3':
        this.getPolesTemplate();
        break;
      case '4':
        this.getDtTemplate();
        break;
      case '5':
        this.getMetersTemplate();
        break;
      case '6':
        this.getSurveysTemplate();
        break;
      case '7':
        this.getDtCrudTemplate();
        break;
      case '8':
        this.getUsersUploadTemplate(true);
        break;
      case '9':
        this.getUsersUploadTemplate(false);
        break;
      case '10':
        this.getMeterStockUploadTemplate();
        break;
      case '11':
        this.getRegularizationTemplate();
        break;
      case '021':
        this.getBillingAccountTemplate();
        break;
    }
  }

  getMeterStockUploadTemplate() {
    this.meterStockService.generateTemplate(this.utilityId).subscribe({
      next: (data) => {
        if (data?.status === 'OK' || data?.status === '200') {
          window.location.href = data.data;
        } else this.toastr.error(data?.message || 'Request failed');
      },
      error: (error) => console.log(error),
    });
  }

  getUsersUploadTemplate(isBulkReassign: boolean) {
    this.userService.generateTemplate(this.utilityId, isBulkReassign).subscribe({
      next: (data) => {
        if (data?.status === 'OK' || data?.status === '200') {
          window.location.href = data.data;
        } else this.toastr.error(data?.message || 'Request failed');
      },
      error: (error) => console.log(error),
    });
  }
  getBillingAccountTemplate() {
    this.billingAccountService.generateTemplate(this.utilityId).subscribe({
      next: (data) => {
        if (data?.status === 'OK' || data?.status === '200') {
          window.location.href = data.data;
        } else this.toastr.error(data?.message || 'Request failed');
      },
      error: (error) => console.log(error),
    });
  }

  getDtTemplate() {
    this.dtService.generateTemplate(this.utilityId).subscribe({
      next: (data) => {
        if (data?.status === 'OK' || data?.status === '200') {
          window.location.href = data.data;
        } else this.toastr.error(data?.message || 'Request failed');
      },
      error: (error) => console.log(error),
    });
  }

  getDtCrudTemplate() {
    this.dtService.generateTemplate(this.utilityId, 'true').subscribe({
      next: (data) => {
        if (data?.status === 'OK' || data?.status === '200') {
          window.location.href = data.data;
        } else this.toastr.error(data?.message || 'Request failed');
      },
      error: (error) => console.log(error),
    });
  }

  getCustomersTemplate() {
    this.customersService.generateTemplate(this.utilityId).subscribe({
      next: (data) => {
        if (data?.status === 'OK' || data?.status === '200') {
          window.location.href = data.data;
        } else this.toastr.error(data?.message || 'Request failed');
      },
      error: (error) => console.log(error),
    });
  }

  getRegularizationTemplate() {
    this.customersService.generateRegularizationTemplate(this.utilityId).subscribe({
      next: (data) => {
        if (data?.status === 'OK' || data?.status === '200') {
          window.location.href = data.data;
        } else this.toastr.error(data?.message || 'Request failed');
      },
      error: (error) => console.log(error),
    });
  }
  getBuildingsTemplate() {
    this.buildingService.generateTemplate(this.utilityId).subscribe({
      next: (data) => {
        if (data?.status === 'OK' || data?.status === '200') {
          window.location.href = data.data;
        } else this.toastr.error(data?.message || 'Request failed');
      },
      error: (error) => console.log(error),
    });
  }

  getPolesTemplate() {
    this.poleService.generateTemplate(this.utilityId).subscribe({
      next: (data) => {
        if (data?.status === 'OK' || data?.status === '200') {
          window.location.href = data.data;
        } else this.toastr.error(data?.message || 'Request failed');
      },
      error: (error) => console.log(error),
    });
  }

  getSurveysTemplate() {
    this.surveyService.generateTemplate(this.utilityId).subscribe({
      next: (data) => {
        if (data?.status === 'OK' || data?.status === '200') {
          window.location.href = data.data;
        } else this.toastr.error(data?.message || 'Request failed');
      },
      error: (error) => console.log(error),
    });
  }

  getMetersTemplate() {
    this.meterService.generateTemplate(this.utilityId).subscribe({
      next: (data) => {
        if (data?.status === 'OK' || data?.status === '200') {
          window.location.href = data.data;
        } else this.toastr.error(data?.message || 'Request failed');
      },
      error: (error) => console.log(error),
    });
  }
  getByType(obj: any) {
    this.uploadFileService.getByType(obj).subscribe({
      next: (response) => {
        if (response.status === 'OK' || response.status === '200') {
          this.uploads = response.data.data;
          this.count = response.data.count;
        } else {
          this.toastr.error(response.message);
        }
      },
    });
  }

  getUploadStatusColor(status: string) {
    if (status === 'Completed') {
      return 'rgba(0, 136, 90, 1)';
    } else if (status == 'Processing') {
      return 'rgba(251, 159, 48, 1)';
    } else {
      return 'rgba(0, 0, 0, 1)';
    }
  }

  pageChange(value: any) {
    this.currentPage = value;
    this.reloadTable();
  }

  reloadTable(page: any = null) {
    if (page) this.currentPage = page;
    var uploadFileType = UploadFileType.Customers;
    switch (this.uploadType) {
      case '1':
        uploadFileType = UploadFileType.Buildings;
        break;
      case '3':
        uploadFileType = UploadFileType.Poles;
        break;
      case '4':
        uploadFileType = UploadFileType.Dts;
        break;
      case '5':
        uploadFileType = UploadFileType.Meters;
        break;
      case '6':
        uploadFileType = UploadFileType.Surveys;
        break;
      case '7':
        uploadFileType = UploadFileType.DtsCrud;
        break;
      case '8':
        uploadFileType = UploadFileType.BulkReassign;
        break;
      case '9':
        uploadFileType = UploadFileType.BulkUserCreation;
        break;
      case '10':
        uploadFileType = UploadFileType.MetersStock;
        break;
      case '11':
        uploadFileType = UploadFileType.Regularization;
        break;
      case '021':
        uploadFileType = UploadFileType.BillingAccount;
        break;
    }

    let obj = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize,
      },

      UploadFileType: uploadFileType,
      utilityId: this.utilityId,
    };

    this.getByType(obj);
  }
}
