import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { PermissionsService } from 'src/app/services/permissions.service';
import { ReportStateService } from '../reports/services/report-state.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public email: any;
  public pass: any;
  public showPw: boolean = false;
  stepsDone: number = 0;
  token: string = '';
  urlEmail: string = '';
  urlPassword: string = '';
  urlUtilityId: string = '';

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    public permissionsService: PermissionsService,
    private reportStateService: ReportStateService,
    private translateService: TranslateService
  ) {}

  
  ngOnInit(): void {
    this.urlEmail = this.route.snapshot.queryParams['email'];
    this.urlPassword = this.route.snapshot.queryParams['p'];
    this.urlUtilityId = this.route.snapshot.queryParams['utilityId'];
    this.token = this.route.snapshot.queryParams['token'];
    console.log(this.token)
    if (this.token) {
      this.authService.logout(false);
      this.tokenLogin();
    }
    else if (this.urlEmail && this.urlPassword && this.urlUtilityId){
      this.authService.bpsLogin(this.urlEmail, this.urlPassword, this.urlUtilityId).subscribe(
        loginResponse => {
          this.loginCommon(loginResponse);
        },
        (error) => {
          this.toastr.error('An error occurred.');
        }
      );
    }
    else if (this.authService.isLoggedIn()) {
      if (this.permissionsService.checkIfhasUtility()) this.router.navigate(['dashboard']);
      //bps admin user
      else this.router.navigate(['dashboard-bps']);
    }
  }

  tokenLogin() {
    this.authService.tokenLogin(this.token).subscribe(loginResponse => {
      this.loginCommon(loginResponse);
    }, error => {
      this.toastr.error('An error ocurred.');
    });
  }

  loginCommon(x: any) {
    if (x.status === 'OK' || x.status === '200') {
      localStorage.setItem('token', x.data.token);
      localStorage.setItem(
        'translation',
        JSON.stringify(x.data.translation)
      );
      
      const user: any = this.authService.decodeJWTToken();
      this.translateService.use(user?.preferredLanguage);
      localStorage.setItem('preferredLanguage', user?.preferredLanguage)
      localStorage.setItem('utilityId', user?.utilityId ? user?.utilityId : 0);
      localStorage.setItem('isAdmin' , '1');
      localStorage.setItem('bpsAdminLevel' , user?.bpsAdminLevel ? user?.bpsAdminLevel : 0);
      if (this.permissionsService.checkIfhasUtility()) {
        if (x.data?.utility?.dashboardConfiguration) {
            localStorage.setItem(
                'configuration',
                JSON.stringify(x.data.utility.dashboardConfiguration)
            );
        }

        if (x.data?.utility?.validationRules) {
          localStorage.setItem(
              'validationRules',
              JSON.stringify(x.data.utility.validationRules)
          );
        }

        if (x.data?.utility?.colorCode) {
            localStorage.setItem('colorCode', x.data.utility.colorCode);
            this.setColors(x.data.utility.colorCode);
        }

        if (x.data?.utility?.imageUrl) {
            localStorage.setItem('logoUrl', x.data.utility.imageUrl);
        }
        if (x.data?.utility?.currency) {
          localStorage.setItem('currency', x.data.utility.currency);
      }
        if (x.data?.utility?.longitude) {
            localStorage.setItem('longitude', x.data.utility.longitude);
        }

        if (x.data?.utility?.latitude) {
            localStorage.setItem('latitude', x.data.utility.latitude);
        }

        this.router.navigate(['dashboard']);

        let notVisibleFields: string[] = x.data.utility.notVisibleFields || [];

        if (x.data?.utility?.notVisibleFields && x.data?.utility?.notVisibleFields !== 'undefined') {
            localStorage.setItem('notVisibleFields', JSON.stringify(notVisibleFields));
        }
      } //bps admin user
      else 
      {
        localStorage.setItem('longitude', '0.381388470567412');
        localStorage.setItem('latitude','5.906986824806585');
        this.router.navigate(['dashboard-bps']);
        this.setBpsAdminColors();
      }

      this.permissionsService.getPermissions();
    } else this.toastr.error(x.message);
  }

  login() {
    this.authService.login(this.email, this.pass).subscribe(loginResponse => {
      this.loginCommon(loginResponse);
    this.reportStateService.clearAllReportFilters({ clearMainTabSelection: true, clearDataQualityTabsSelection: true });

      },
      (error) => {
        this.toastr.error('An error ocurred.');
      }
    );
  }
  setBpsAdminColors() {
    document.documentElement.style.setProperty('--primary-color', '#03423E');
    document.documentElement.style.setProperty('--bg-color', '#F5FBFA');
    document.documentElement.style.setProperty(
      '--table-root-bg-color',
      '#EBF1F1'
    );
    document.documentElement.style.setProperty('--card-color', '#e4ecea');
    document.documentElement.style.setProperty('--bubble-color', '#688F8D');
  }

  setColors(color: string) {
    console.log('fdsfsdfsd')
    document.documentElement.style.setProperty('--primary-color', color);
    document.documentElement.style.setProperty('--card-color', color + '22');
    document.documentElement.style.setProperty('--bg-color', color + '08');
    document.documentElement.style.setProperty('--bubble-color', color + '95');
    document.documentElement.style.setProperty('--bubble-color', color + '95');

  }

  showPassword() {
    this.showPw = !this.showPw;
  }

  checkIfLocalStorageReady() {
    this.stepsDone++;
    if (this.stepsDone == 2) {
      this.stepsDone = 0;
      this.router.navigate(['dashboard']);
    }
  }
}

