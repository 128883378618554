import {
  ApplicationRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CurrencyList } from 'src/app/models/currency.model';
import { KycConsentConfig } from 'src/app/models/kycConsentConfig.model';
import { Translation } from 'src/app/models/translation.model';
import { BPSAdminLevel } from 'src/app/models/user.model';
import {
  BankDetails,
  DashboardConfiguration,
  UtilityDetails,
} from 'src/app/models/utility.model';
import { ValidationRule } from 'src/app/models/validationRule.model';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-utility-details',
  templateUrl: './utility-details.component.html',
  styleUrls: ['./utility-details.component.scss'],
})
export class UtilityDetailsComponent implements OnInit {
  @ViewChild('closeModal') closeModal: ElementRef;
  @ViewChild('closeValidationModal') closeValidationModal: ElementRef;

  @ViewChild('utilityImage') utilityImageInput: ElementRef;

  id: number;
  editEnabled = false;
  public Configuration = DashboardConfiguration;
  selectedUtility: UtilityDetails;
  utilityImage: File | null;
  imageSrc: string | ArrayBuffer;
  selectedTranslation: Translation = new Translation();
  selectedValidationRule: ValidationRule = new ValidationRule();
  selectedProperty: string;
  startsWith: string;
  letterNo: number;
  isNumber: boolean;
  currencyList = CurrencyList;

  addNew = true;
  validationRuleTypeOptions: string[] = [
    'AccountNumber',
    'BuildingSlrn',
    'MeterNumber',
    'MeterSLRN',
    'PoleSlrn',
    'TransformerSlrn',
  ];
  bpsAdminLevel: BPSAdminLevel;

  constructor(
    private utilityService: UtilityService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private router: Router,
    private appRef: ApplicationRef
  ) {}

  ngOnInit(): void {
    const routeId = this.route.snapshot.paramMap.get('id');
    this.id = parseInt(routeId == null ? '0' : routeId);
    this.get(this.id);
    this.bpsAdminLevel = parseInt(localStorage.getItem('bpsAdminLevel') || '0');
  }
  selectProperty(e: any) {
    this.selectedProperty = e.target.value;
    this.selectedValidationRule.propertyType = e.target.value;
  }

  selectValidationRule(rule: ValidationRule) {
    this.selectedValidationRule = { ...rule };
  }
  saveValidationRule() {
    if (this.selectedProperty == undefined) {
      this.toastr.warning('Please select property type.');
      return;
    }

    if (this.selectedUtility.validationRules == undefined)
      this.selectedUtility.validationRules = [];
    this.selectedUtility.validationRules.forEach((element) => {
      if (element.propertyType == this.selectedValidationRule.propertyType) {
        element = { ...this.selectedValidationRule };
        this.addNew = false;
      }
    });
    if (this.addNew) {
      this.selectedUtility.validationRules.push(this.selectedValidationRule);
    }
    this.selectedValidationRule = new ValidationRule();
    this.closeValidationModal?.nativeElement?.click();
    console.log(this.selectedUtility.validationRules);
  }
  selectCurrency(e: any) {
    if (e.target.value != '') {
      this.selectedUtility.currency = e.target.value;
    } else {
      this.selectedUtility.currency = '';
    }
  }
  get(id: number) {
    this.utilityService.get(id).subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.selectedUtility = responseData.data;
          this.selectedUtility.fullName =
            this.selectedUtility.fullName ?? this.selectedUtility.name;
          this.selectedUtility.kycConsentConfiguration =
            this.selectedUtility.kycConsentConfiguration ??
            new KycConsentConfig();
          this.selectedUtility.bankDetails =
            this.selectedUtility.bankDetails ?? new BankDetails();
          console.log(this.selectedUtility);
          this.imageSrc = this.selectedUtility.imageUrl;
          this.resolveEnum();
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }
  isChecked(listConfig: any) {
    let checked = true;
    listConfig.forEach((val: any) => {
      if (!this.selectedUtility.dashboardConfiguration.includes(val))
        checked = false;
    });
    return checked;
  }
  onChange(configuration: any, event: any = null) {
    configuration.forEach((value: any) => {
      if (event != null) {
        if (!this.isChecked([value]) && event.target.checked == true)
          this.selectedUtility.dashboardConfiguration.push(value);
        else if (this.isChecked([value]) && event.target.checked == false)
          this.selectedUtility.dashboardConfiguration =
            this.selectedUtility.dashboardConfiguration.filter(
              (item: any) => item != value
            );
      } else {
        if (this.selectedUtility.dashboardConfiguration.includes(value))
          this.selectedUtility.dashboardConfiguration =
            this.selectedUtility.dashboardConfiguration.filter(
              (item: any) => item != value
            );
        else this.selectedUtility.dashboardConfiguration.push(value);
      }
    });
  }
  edit() {
    this.editEnabled = true;
  }

  validate() {
    if (this.selectedUtility.subdomain === '') {
      this.toastr.warning('Please fill out the subdomain field.');
      return;
    }
    if (this.selectedUtility.colorCode === '') {
      this.toastr.warning('Please select color code.');
      return;
    }
    if (this.selectedUtility.name === '') {
      this.toastr.warning('Please fill out the name field.');
      return;
    }
    if (this.selectedUtility.imageUrl == '' && this.utilityImage == null) {
      this.toastr.warning('Please add the utility logo.');
      return;
    }
    if (this.selectedUtility.dashboardConfiguration.length === 0) {
      this.toastr.warning('Please select some Assets/Operations.');
      return;
    }
    this.save();
  }
  save() {
    this.utilityService.add(this.selectedUtility, this.utilityImage).subscribe({
      next: (response) => {
        const responseData = response;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          if (responseData.message === '') {
            this.toastr.success('Successfully added utility');
            this.router.navigate(['/utility-management']);
          } else {
            this.toastr.warning(responseData.message);
          }
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  removeImage() {
    this.utilityImage = null;
    this.imageSrc = '';
  }
  onFileDropped($event: any) {
    this.utilityImage = $event.item(0);
    if (
      this.utilityImage?.type != 'image/png' &&
      this.utilityImage?.type != 'image/jpg' &&
      this.utilityImage?.type != 'image/jpeg'
    ) {
      this.toastr.warning('Only PNG/JPG/JPEG images are allowed');
      if (this.utilityImageInput?.nativeElement) {
        this.utilityImageInput.nativeElement.value = '';
      }
    } else {
      const reader = new FileReader();
      var width = 0;
      var height = 0;
      reader.onload = (e) => {
        this.imageSrc = reader.result!;
        const img = new Image();
        img.src = e.target!.result as string;

        img.onload = () => {
          width = img.width;
          height = img.height;
          var ratio = width / height;
          if (ratio > 2 || ratio < 0.5) {
            this.toastr.warning('Unsuported image size.');
            this.imageSrc = '';
            this.utilityImage = null;
            this.appRef.tick();
          }
        };
      };
      reader.readAsDataURL(this.utilityImage!);
    }
  }
  fileBrowserHandler($event: any) {
    this.utilityImage = $event.target.files.item(0);
    if (
      this.utilityImage?.type != 'image/png' &&
      this.utilityImage?.type != 'image/jpg' &&
      this.utilityImage?.type != 'image/jpeg'
    ) {
      this.toastr.warning('Only PNG/JPG/JPEG images are allowed');
      if (this.utilityImageInput?.nativeElement) {
        this.utilityImageInput.nativeElement.value = '';
      }
    } else {
      const reader = new FileReader();
      var width = 0;
      var height = 0;
      reader.onload = (e) => {
        this.imageSrc = reader.result!;
        const img = new Image();
        img.src = e.target!.result as string;
        img.onload = () => {
          width = img.width;
          height = img.height;
          var ratio = width / height;
          if (ratio > 2 || ratio < 0.5) {
            this.toastr.warning('Unsuported image size.');
            this.imageSrc = '';
            this.utilityImage = null;
            this.appRef.tick();
          }
        };
      };

      reader.readAsDataURL(this.utilityImage!);
    }
  }
  resolveEnum() {
    var enums: any[] = [];
    var enumm: DashboardConfiguration;
    this.selectedUtility.dashboardConfiguration.forEach((obj) => {
      enumm = obj as DashboardConfiguration;
      enums.push(DashboardConfiguration[enumm]);
    });
    this.selectedUtility.dashboardConfiguration = enums;
  }

  saveTranslation() {
    var indexToChange = this.selectedUtility.translations.findIndex(
      (item) => item.key == this.selectedTranslation.key
    );
    this.selectedUtility.translations[indexToChange] = this.selectedTranslation;
    this.closeModal?.nativeElement?.click();
  }

  selectTranslation(item: Translation) {
    this.selectedTranslation = { ...item };
  }
}
