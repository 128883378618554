import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from '../config/config';
import { Validation } from '../models/validation.model';

@Injectable({
    providedIn: 'root'
})
export class ValidationService {

    constructor(private http: HttpClient, private config: AppConfig) { }

    validateValue(value: string, propertyType: string) {
        var validations: Validation[] = JSON.parse(localStorage.getItem('validationRules') as string);
        validations = validations.filter(x => x.propertyType == propertyType);
        if (validations != null && validations.length != 0) {
            if (validations[0].startsWith && !value.startsWith(validations[0].startsWith))
                return false;
            else if (validations[0].exactLength && value.length != validations[0].exactLength)
                return false;
            else if (validations[0].isAlfaNumeric && !/^[a-zA-Z0-9]+$/.test(value))
                return false;
            else if (validations[0].numberofLetters) {
                var temp = value.replace(/[0-9]/g, '');
                return temp.length <= validations[0].numberofLetters;
            }
            else
                return true;
        }
        else {
            return true;
        }
    }
}
