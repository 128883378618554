import { Region } from "./region.model";
import { TransmissionStation } from "./transmission.station.model";

export interface Feeder33 {
    id: number;
    name: string;
    number: string;
    transmissionStation?: string;
}

export interface F33Crud {
    id: number;
    name: string;
    number: string;
    transmissionStation: TransmissionStation;
    checked: boolean;
    utilityId: number;
    regions: Region[];
    currentRegions: string;
}

export interface F33CrudCreate {
    id: number;
    name: string;
    number: string;
    transmissionStationId: number;
    utilityId: number;
    regionIds: number[];
}

export function initF33Crud(): F33Crud {
    return{
        id: 0,
        name: '',
        number: '',
        transmissionStation: new TransmissionStation(),
        checked : false,
        utilityId: 0,
        regions: [],
        currentRegions: ''
        };
}

export function initF33SsCrud(): F33Crud[] {
    return [
        // {
        // id: 0,
        // name: '',
        // number: '',
        // transmissionStation: new TransmissionStation(),
        // checked:false
        // }
    ];
}

export function mapObjToCreateObj(f33: F33Crud): F33CrudCreate {
    return {
        id: f33.id,
        name: f33.name,
        number: f33.number,
        transmissionStationId: f33.transmissionStation.id,
        utilityId: f33.utilityId,
        regionIds: f33.regions.map(x => x.id)
    };
}
