<div class="container-fluid customers-root">
  <div class="table-root">
    <div
      class="table-action-wrapper d-flex align-items-center justify-content-between"
    >
      <div class="search-input">
        <img
          src="../../assets/icons/search.svg"
          alt="Search icon"
          (click)="search()"
        />
        <input
          type="text"
          placeholder="Search"
          [(ngModel)]="searchText"
          (keydown.enter)="search()"
          (ngModelChange)="this.searchTextUpdate.next($event)"
        />
      </div>
      <div class="ml-auto d-flex">
        <div>
          <div class="d-flex">
            <div class="datepicker-container">
              <label for="dateFrom"> From: </label>
              <input
                type="date"
                id="dateFrom"
                name="dateFrom"
                [ngClass]="
                  dateFrom > dateTo
                    ? 'btn datepicker-icon-error'
                    : 'btn datepicker-icon'
                "
                [(ngModel)]="dateFrom"
                (change)="datesValid() == true && search()"
              />
            </div>

            <div class="datepicker-container">
              <label for="dateTo" class="right-label"> To: </label>
              <input
                type="date"
                id="dateTo"
                name="dateTo"
                [ngClass]="
                  dateFrom > dateTo
                    ? 'btn datepicker-icon-error'
                    : 'btn datepicker-icon'
                "
                [(ngModel)]="dateTo"
                (change)="datesValid() == true && search()"
              />
            </div>
          </div>
        </div>
        <button *ngIf="bpsAdminLevel != 2" class="btn btn-primary btn-add-new" routerLink="add">
          <img src="../../assets/icons/plus.svg" alt="Plus icon" />
          <span>New utility</span>
        </button>
      </div>
  
    </div>
    <div class="table utility-list-table">
      <div *ngIf="true">
        <table class="table">
          <thead class="table-light">
            <tr>
              <th></th>
              <th>Name</th>
              <th>Subdomain</th>
              <th>Color code</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let util of utilities; let i=index;">
              <td [routerLink]="['details', util.id]"><img *ngIf="util.imageUrl!=''" [src]="util.imageUrl" height="50" width="50" alt="Image"/> </td>
              <td [routerLink]="['details', util.id]">{{util.name | hasValue}}</td>
              <td><a (click)="openUtilityPortal(util.id, util.subDomain)">{{util.subDomain | hasValue}}</a></td>
              <td [routerLink]="['details', util.id]"><div ><svg *ngIf="util.colorCode!=''" width="24" height="24"  fill-rule="evenodd" clip-rule="evenodd"><path attr.fill="{{util.colorCode}}" serif:id="shape 11" d="M24 6c0-3.311-2.689-6-6-6h-12c-3.311 0-6 2.689-6 6v12c0 3.311 2.689 6 6 6h12c3.311 0 6-2.689 6-6v-12z"/></svg>  <span >{{util.colorCode| hasValue}}</span></div>  </td>
              <td [routerLink]="['details', util.id]"><div class="status {{ util.status | lowercase }}">
                {{ util.status }}
              </div></td>
              <td class="more-icon">
                <div
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false">
                  <img
                    src="../../assets/icons/more-icon.svg"
                    alt="More icon"
                    class="more-icon"
                  />
                </div>
                <ul
                  class="dropdown-menu"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li (click)="openDetailsInNewTab(util.id)">
                    <img
                      src="../../assets/icons/open-utility.svg"
                      alt="Open utility icon"
                    />
                    <span>Open utility</span>
                  </li>
                  <li [routerLink]="['details', util.id]">
                    <img
                      src="../../assets/icons/edit-user.svg"
                      alt="Edit user icon"
                    />
                    <span>Edit utility</span>
                  </li>
                  <li
                    *ngIf="util.status === 'Active'; else activateUser"
                    (click)="onSetStatus(util.id,util.status)"
                    class="color-red"
                  >
                    <img
                      src="../../assets//icons/deactivate-user.svg"
                      alt="Deactivate user icon"
                    />
                    <span>Deactivate utility</span>
                  </li>
                  <ng-template #activateUser>
                    <li
                      (click)="onSetStatus(util.id,util.status)"
                      class="color-green"
                    >
                      <img
                        src="../../assets//icons/activate-user.svg"
                        alt="Activate user icon"
                      />
                      <span>Activate utility</span>
                    </li>
                  </ng-template>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    <div>
      <app-paging
        *ngIf="count > 0"
        [pageSize]="pageSize"
        [useDirect]="true"
        [count]="count"
        [currentPage]="currentPage"
        (pageChange)="pageChange($event)"
      ></app-paging>
    </div>
  </div>

  </div>
</div>
