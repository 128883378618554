import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-service-layout',
  templateUrl: './new-service-layout.component.html'
})
export class NewServiceLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
