import { Injectable } from '@angular/core';
import { AppConfig } from '../config/config';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrganizationTeamApiService {
    // TODO: ORG/TEAM Change the api when it is added on the backend
    private pathApi = this.config.setting['pathApi'] + "organization/";

    constructor(
        private http: HttpClient,
         private config: AppConfig,
    ) { }

    getAll(obj: any): Observable<any> {
        return this.http.post(`${this.pathApi}getAll`, obj);
    }

    getAllForSelect(): Observable<any> {
        return this.http.get(`${this.pathApi}getAllForSelect`);
    }

    setStatus(obj: any): Observable<any> {
        return this.http.post(`${this.pathApi}setStatus`, obj);
    }

    saveOrganizationTeam(obj: any): Observable<any> {
        return this.http.post(`${this.pathApi}save`, obj);
    }

    getTabsCount(): Observable<any> {
        return this.http.get(`${this.pathApi}getAllTabsCount`);
    }
}
