import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject, Observable, fromEvent, debounceTime, takeUntil } from 'rxjs';
import { Area } from '../../../../models/area.model';
import { ChecklistItem } from '../../../../models/checklist-item.model';
import { UserType, SortByOptions } from '../../../../models/customer.model';
import { DTDetails } from '../../../../models/dt.model';
import { Region } from '../../../../models/region.model';
import { PermissionsEnum, SystemComponents } from '../../../../models/role.model';
import { TransmissionStation } from '../../../../models/transmission.station.model';
import { UserForFilter } from '../../../../models/user.model';
import { AreaService } from '../../../../services/area.service';
import { DtService } from '../../../../services/dt.service';
import { PermissionsService } from '../../../../services/permissions.service';
import { RegionService } from '../../../../services/region.service';
import { TranslationService } from '../../../../services/translation.service';
import { TsService } from '../../../../services/ts.service';
import { UserService } from '../../../../services/user.service';
import { TransformersStatus } from '../../../../enums/transformers-status.enum';
import { ReportStateService } from '../../services/report-state.service';

@Component({
  selector: 'transformers-tab',
  templateUrl: './transformers-tab.component.html',
  styleUrls: ['./../data-quality-rejection.component.scss', ]
})
export class TransformersTabComponent implements OnInit {
    PermissionsEnum = PermissionsEnum;
    dts: DTDetails[] = [];
    @Output() dtsCount = new EventEmitter<any>();
    mapSortBy: Record<number, string> = {
      [SortByOptions.NEWEST]: 'Newest',
      [SortByOptions.SLRN_ASC]: 'SLRN A',
      [SortByOptions.SLRN_DESC]: 'SLRN D',
    };
    dateFrom: string;
    dateTo: string;
    count: number = 2;
    pageSize: number = 10;
    currentPage: number = 1;
  
    searchText: string = '';
    searchTextUpdate = new Subject<string>();
    kvaRatingUpdate = new Subject<string>();
  
    sortByValue: SortByOptions = SortByOptions.NEWEST;
    sortByOptions = SortByOptions;
    sortByName: string = this.mapSortBy[SortByOptions.NEWEST];
  
    showUpload: boolean = false;
    showSortBy: boolean = false;
    clickObservable: Observable<Event> = fromEvent(document, 'click');
    buName: string = this.translationService.getByKeyFromStorage('BU');
    utName: string = this.translationService.getByKeyFromStorage('UT');
    allCheckboxesChecked: boolean = false;
  
    private ngUnsubscribe = new Subject<void>();
    selectedFilters: ChecklistItem[] = [];
    clicked: boolean = false;
    regions: Region[] = [];
    areas: Area[] = [];
    allAreas: Area[] = [];
    ts: TransmissionStation[] = [];
    selectedTs: number = 0;
    feederVoltageOptions = [
      { name: '11KV', value: 1 },
      { name: '33KV', value: 2 },
    ];
    feederVoltage: number = 0;
    mountPositionOptions = [
      { name: 'Ground', value: 1 },
      { name: 'Pole', value: 2 },
    ];
    mountPosition: number = 0;
    dtTypeOptions = [
      { name: 'Private', value: 1 },
      { name: 'Public', value: 2 },
    ];
    dtType: number = 0;
    kvaRating: string = '';
    dtStatusOptions = [
      { name: 'Active', value: 1 },
      { name: 'Inactive', value: 2 },
      { name: 'Suspended', value: 3 },
      { name: 'Decommissioned', value: 4 },
    ];
  
    kvaRatingOptions = [
      '50KVA',
      '100KVA',
      '200KVA',
      '300KVA',
      '500KVA',
      '750KVA',
      '1000KVA',
      '2000KVA',
      'OTHER',
    ];
    selectedAreas: number[] = [];
    selectedRegions: number[] = [];
    dtStatus: number = 0;
    meterStatusOptions = [
      { name: 'Unmetered', value: 1 },
      { name: 'Metered', value: 2 },
    ];
    meterStatus: number = 0;
    utilityId: number = 0;
    dateTypes = [
      { name: 'ValidatedDate', value: 'Validated Date' },
      { name: 'LastUpdateTime', value: 'Last Update Time' },
      { name: 'TaggedDate', value: 'Tagged Date' },
    ];
    selectedDateType: string;
    selectedDateTypeDisplay: string;
    showSelectDateType: boolean = false;
    
    usersFilter: any; // Stores value of filter
    mobileUsers: UserForFilter[] = []; // Store only mobile users
    adminUsers: UserForFilter[] = []; // Store only admin users
    allUsers: UserForFilter[] = []; // Store mobile or admin users depending on filter
    filteredUsers: UserForFilter[] = []; // Used for display and for filtering users list
    selectedUsers: number[] = [];
  
    userType?: number;
    userTypeOptions = [
      { name: 'First Captured User', value: UserType.FirstCapturedUser },
      { name: 'Last Captured User', value: UserType.LastCapturedUser },
      { name: 'Reassigned By', value: UserType.ReassignedBy },
      { name: 'Validated By', value: UserType.ValidatedBy },
      { name: 'Audited By', value: UserType.AuditedBy },
    ];
    mobileUserType: UserType[] = [
      UserType.FirstCapturedUser,
      UserType.LastCapturedUser,
    ];
    adminUserType: UserType[] = [
      UserType.ValidatedBy,
      UserType.ReassignedBy,
      UserType.AuditedBy,
    ];  
    constructor(
      private dtService: DtService,
      public permissionsService: PermissionsService,
      private toastr: ToastrService,
      private regionService: RegionService,
      private areaService: AreaService,
      private tsService: TsService,
      private translationService: TranslationService,
      private userService: UserService,
      private router:Router,
      private reportStateService: ReportStateService,
    ) {}
  
    ngOnInit(): void {
      this.utilityId = parseInt(localStorage.getItem('utilityId') || '');
     this.setFilters();
      this.getRegions();
      this.setCheckedUsers();
      this.getData();
      this.subscribeToClickEvent();
      this.searchTextUpdate.pipe(debounceTime(500)).subscribe((value) => {
        if (this.searchText == '') this.search();
      });
      this.kvaRatingUpdate.pipe(debounceTime(500)).subscribe((value) => {
        if (this.kvaRating == '') this.reloadTable(1);
      });
      this.selectedDateType = this.selectedDateType ?? this.dateTypes[0].name;
      this.selectedDateTypeDisplay =
        this.selectedDateTypeDisplay ?? this.dateTypes[0].value;
    }
    ngOnDestroy(): void {
      this.searchTextUpdate.complete();
      this.kvaRatingUpdate.complete();
    }
    private getData(): void {
    //   this.getDTsStats();
      this.getTsForSelect();
      this.reloadTable();
    }
    setFilters() {
      var filter = localStorage.getItem(this.reportStateService.transformersTabKey);
      if (!filter) return;
      var filterObject = JSON.parse(filter);
      if (filterObject.type != 'Transformer') return;
      this.currentPage = filterObject.filter.pageInfo.page;
      this.pageSize = filterObject.filter.pageInfo.pageSize;
      this.selectedUsers = filterObject.filter.filterParams.selectedUsers
      ? filterObject.filter.filterParams.selectedUsers
      : [];
      this.count = filterObject.filter.pageInfo.count;
      this.sortByValue = filterObject.filter.filterParams.sortBy;
      this.searchText = filterObject.filter.filterParams.search;
      this.selectedTs = filterObject.filter.filterParams.transmissionStation;
      this.feederVoltage = filterObject.filter.filterParams.feederVoltage;
      this.mountPosition = filterObject.filter.filterParams.mountPosition;
      this.dtType = filterObject.filter.filterParams.dtType;
      this.dtStatus = filterObject.filter.filterParams.dtStatus;
      this.meterStatus = filterObject.filter.filterParams.meterStatus;
      this.kvaRating = filterObject.filter.filterParams.kvaRating;
      this.selectedAreas = filterObject.filter.filterParams.selectedAreas
        ? filterObject.filter.filterParams.selectedAreas
        : [];
      this.selectedRegions = filterObject.filter.filterParams.selectedRegions
        ? filterObject.filter.filterParams.selectedRegions
        : [];
      this.sortByName = this.mapSortBy[this.sortByValue];
    }
    private getRegions() {
      this.regionService.getAllForSelect().subscribe({
        next: (response) => {
          const responseData = response.body;
          if (responseData?.status === 200 || responseData?.status === 'OK') {
            this.regions = responseData?.data ?? [];
            this.setCheckedRegions();
            this.getAreas();
          } else if (response?.status == 204) {
          } else {
            this.toastr.error(responseData?.message);
          }
        },
        error: (_) => {
          this.toastr.error('Error occured');
        },
      });
    }
    private getAreas() {
      this.areaService.getAllForSelect().subscribe({
        next: (response) => {
          const responseData = response.body;
          if (responseData?.status === 200 || responseData?.status === 'OK') {
            this.allAreas = responseData?.data ?? [];
            this.areas = this.allAreas.filter((area) =>
            this.regions.some(
                (region) => region.id == area.regionId && region.checked
              )
            );
            this.setCheckedAreas();
            this.createFilterCards();
          } else if (response?.status == 204) {
          } else {
            this.toastr.error(responseData?.message);
          }
        },
        error: (_) => {
          this.toastr.error('Error occured');
        },
      });
    }
    getFilterObject(isForStorage: boolean = false) {
      let obj = {
        sortBy: this.sortByValue,
        search: this.searchText,
        // filterBy: this.filterByValue,
        dateFrom: this.dateFrom ? this.dateFrom : null,
        dateTo: this.dateTo ? (isForStorage ? this.dateTo : `${this.dateTo}T23:59:59`) : null,
        dateType: this.selectedDateType,
        selectedRegions: this.selectedRegions,
        selectedAreas: this.selectedAreas,
        userType: isForStorage ? this.userType ? this.userType : null : (this.userType
            ? this.selectedUsers.length > 0
              ? this.userType
              : null
            : null),
        selectedUsers: this.selectedUsers.length > 0 ? this.selectedUsers : null,
        feederVoltage: this.feederVoltage,
        transmissionStation: this.selectedTs,
        mountPosition: this.mountPosition,
        dtType: this.dtType,
        dtStatus: this.dtStatus,
        meterStatus: this.meterStatus,
        kvaRating: this.kvaRating,
        status: TransformersStatus.Rejected,
        isCrud: 0,
        selectedIds: this.dts.filter((x) => x.isChecked == true).map((x) => x.id),
      };
      return obj;
    }
    setFilterStorage() {
      let obj = {
        pageInfo: {
          page: this.currentPage,
          pageSize: this.pageSize,
          count: this.count
        },
        filterParams: this.getFilterObject(true),
        utilityId: this.utilityId,
      };
  
      localStorage.setItem(
        this.reportStateService.transformersTabKey,
        JSON.stringify({ type: 'Transformer', filter: obj })
      );
    }

    private getTsForSelect() {
      this.tsService.getAllForSelect().subscribe({
        next: (response) => {
          const data = response.body;
          if (data?.status === 200 || data?.status === 'OK') {
            this.ts = data?.data ?? [];
            this.createFilterCards();
          } else {
            this.toastr.error(data?.message);
          }
        },
        error: (_) => {
          this.toastr.error('Error occured');
        },
      });
    }
    private getAll(obj: any) {
      obj.isCrud = false;
      this.dtService.getAllRejectionReports(obj).subscribe({
        next: (response) => {
          const responseData = response.body;
          if (responseData?.status === 200 || responseData?.status === 'OK') {
            this.dts = responseData?.data?.data ?? [];
            this.count = responseData?.data?.count ?? this.count;
            this.dtsCount.emit(this.count);
            if (responseData.message != '') {
              this.toastr.warning(responseData.message);
            }
          } else {
            this.toastr.error(responseData?.message);
          }
        },
        error: (_) => this.toastr.error('Error occured'),
      });
    }
  
    checkDt(id: number) {
      const dt = this.dts.find((x) => x.id === id);
      if (dt) {
        dt.isChecked = !dt.isChecked;
        this.areAllCheckboxesChecked();
      }
    }
  
    areAllCheckboxesChecked() {
      this.allCheckboxesChecked = this.dts.every((x) => x.isChecked);
    }
  
    checkAllCheckboxes() {
      this.dts.forEach((x) => (x.isChecked = !this.allCheckboxesChecked));
      this.allCheckboxesChecked = !this.allCheckboxesChecked;
    }
  
    reloadTable(page: any = null) {
      this.allCheckboxesChecked = false;
      if (page) this.currentPage = page;
      let obj = {
        pageInfo: {
          page: this.currentPage,
          pageSize: this.pageSize,
          count: this.count
        },
        filterParams: this.getFilterObject(),
        utilityId: this.utilityId,
      };
      this.getAll(obj);
    }
  
    search() {
      this.createFilterCards();
    }
  
    datesValid() {
      if (this.dateFrom && this.dateTo && this.dateTo?.toString() != '') {
        const valid: boolean =
          new Date(this.dateFrom).getTime() <
          new Date(`${this.dateTo.toString()}T23:59:59`).getTime();
        return valid;
      }
  
      return true;
    }
  
    sortBy(option: SortByOptions) {
      this.sortByValue = option;
      this.sortByName = this.mapSortBy[option];
      this.reloadTable();
    }
  
    pageChange(value: any) {
      this.currentPage = value;
      this.reloadTable();
    }
  
    private subscribeToClickEvent() {
      this.clickObservable
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((e: any) => {
          this.showUpload = false;
          this.showSortBy = false;
          this.showSelectDateType = false;
        });
    }
  
    download() {
      let obj = this.getFilterObject();
      this.dtService.downloadRejectionReports(obj).subscribe({
        next: (response) => {
          const data = response.body;
          if (data?.status === 'OK' || data?.status === '200') {
            window.location.href = data.data;
          } else if (data?.status === 'Pending' || data?.status === '600') {
            this.toastr.info(data?.message || '');
          } else this.toastr.error(data?.message || 'Request failed');
        },
        error: (error) => console.log(error),
      });
    }
    selectTS(e: any) {
      this.selectedTs = +e.target.value;
      this.createFilterCards();
    }
    selectFeederVoltage(e: any) {
      this.feederVoltage = +e.target.value;
      this.createFilterCards();
    }
    selectMountPosition(e: any) {
      this.mountPosition = +e.target.value;
      this.createFilterCards();
    }
    selectDtType(e: any) {
      this.dtType = +e.target.value;
      this.createFilterCards();
    }
    selectDtStatus(e: any) {
      this.dtStatus = +e.target.value;
      this.createFilterCards();
    }
    selectMeterStatus(e: any) {
      this.meterStatus = +e.target.value;
      this.createFilterCards();
    }
    selectKVARating(e: any) {
      this.kvaRating = e.target.value;
      this.createFilterCards();
    }
    selectRegion(e: any) {
      //list of areas for selected regions
      this.areas = this.allAreas.filter((area) =>
        this.regions.some(
          (region) => region.id == area.regionId && region.checked
        )
      );
      this.allAreas.forEach((area) => {
        if (!this.areas.some((a) => a.id === area.id)) {
          area.checked = false; //uncheck areas for unchecked regions
        }
      });
  
      this.createFilterCards();
    }
    selectArea(e: any) {
      this.createFilterCards();
    }

    applyFilter(item: ChecklistItem){
      this.reloadTable(1);
    }

    createFilterCards() {
      this.selectedFilters = [];
      let selectedUsers = this.allUsers.filter((x) => x.checked);
      let selectedRegions = this.regions.filter((x) => x.checked);
      let selectedAreas = this.areas.filter((x) => x.checked);
      this.selectedAreas = this.areas.filter((x) => x.checked).map((x) => x.id);
      this.selectedUsers = this.allUsers
        .filter((x) => x.checked)
        .map((x) => x.id);
      this.selectedRegions = this.regions
        .filter((x) => x.checked)
        .map((x) => x.id);
      //areas
      for (var i = 0; i < selectedAreas.length; i++) {
        this.selectedFilters.push(
          new ChecklistItem(this.utName, selectedAreas[i].name)
        );
      }
      
      if (this.userType) {
        var userTypeName = UserType[this.userType!];
        this.selectedFilters.push(new ChecklistItem('User type', userTypeName));
      }

       //search
       if (this.searchText){
        this.selectedFilters.push(
          new ChecklistItem('Search', this.searchText)
        );
      }

      //dates
      if (this.dateFrom){
        this.selectedFilters.push(
          new ChecklistItem('Date From', this.dateFrom)
        );
      }
      
      if (this.dateTo){
        this.selectedFilters.push(
          new ChecklistItem('Date To', this.dateTo)
        );
      }
  
      //users
      for (var i = 0; i < selectedUsers.length; i++) {
        this.selectedFilters.push(
          new ChecklistItem('User', selectedUsers[i].name)
        );
      }
  
      if (this.selectedTs != 0) {
        let tsName = this.ts.filter((x) => x.id == this.selectedTs)[0].name;
        this.selectedFilters.push(
          new ChecklistItem('Transmission Station', tsName)
        );
      }
      if (this.feederVoltage != 0) {
        let feeder = this.feederVoltageOptions.filter(
          (x) => x.value == this.feederVoltage
        )[0].name;
        this.selectedFilters.push(new ChecklistItem('Feeder Voltage', feeder));
      }
      if (this.mountPosition != 0) {
        let pos = this.mountPositionOptions.filter(
          (x) => x.value == this.mountPosition
        )[0].name;
        this.selectedFilters.push(new ChecklistItem('Mount Position', pos));
      }
      if (this.dtType != 0) {
        let type = this.dtTypeOptions.filter((x) => x.value == this.dtType)[0]
          .name;
        this.selectedFilters.push(new ChecklistItem('DT Type', type));
      }
      if (this.dtStatus != 0) {
        let status = this.dtStatusOptions.filter(
          (x) => x.value == this.dtStatus
        )[0].name;
        this.selectedFilters.push(new ChecklistItem('DT Status', status));
      }
      if (this.meterStatus != 0) {
        let meter = this.meterStatusOptions.filter(
          (x) => x.value == this.meterStatus
        )[0].name;
        this.selectedFilters.push(new ChecklistItem('Meter Status', meter));
      }
      if (this.kvaRating != '') {
        this.selectedFilters.push(
          new ChecklistItem('KVA Rating', this.kvaRating)
        );
      }
    }
    removeFilter(item: ChecklistItem) {
     if (item.property == this.utName) {
        this.areas.forEach((area) => {
          if (area.name === item.selectedValue) {
            area.checked = false;
          }
        });
        this.clicked = !this.clicked;
      } else if (item.property == 'User') {
        this.allUsers.forEach((user) => {
          if (user.name === item.selectedValue) {
            user.checked = false;
          }
        });
        this.clicked = !this.clicked;
      } else if (item.property == 'User type') {
        this.userType = undefined;
        this.allUsers.forEach((user) => {
          // Clear list if he selects same filter again to get all cleared
          user.checked = false;
        });
        this.allUsers = this.filteredUsers = [...[]];
      } else if (item.property == 'Transmission Station') {
        this.selectedTs = 0;
      } else if (item.property == 'Feeder Voltage') {
        this.feederVoltage = 0;
      } else if (item.property == 'Mount Position') {
        this.mountPosition = 0;
      } else if (item.property == 'DT Type') {
        this.dtType = 0;
      } else if (item.property == 'DT Status') {
        this.dtStatus = 0;
      } else if (item.property == 'Meter Status') {
        this.meterStatus = 0;
      } else if (item.property == 'KVA Rating') {
        this.kvaRating = '';
      } else if (item.property == 'Search'){
        this.searchText = '';
      } else if (item.property == 'Date From'){
        this.dateFrom = '';
      } else if (item.property == 'Date To'){
        this.dateTo = '';
      }
      
      this.createFilterCards();
      this.setFilterStorage();

      if(this.selectedFilters.length == 0){
        this.regions.forEach((region) => {       
          region.checked = false;        
        });
        this.reloadTable(1);
      }
    }
  
    resetFilter(e: any) {
      this.selectedFilters = [];
  
      this.regions.forEach((region) => {
        region.checked = false;
      });
      this.selectedRegions = [];
      this.areas.forEach((area) => {
        area.checked = false;
      });
      this.areas = [];
      this.allUsers.forEach((user) => {
        user.checked = false;
      });
      this.allUsers = this.filteredUsers = [...[]];
      this.selectedAreas = [];
      this.selectedRegions = [];
      this.userType = undefined;
      this.feederVoltage = 0;
      this.selectedTs = 0;
      this.mountPosition = 0;
      this.dtType = 0;

      this.searchText = '';
      this.dateFrom = '';
      this.dateTo = '';

      this.dtStatus = 0;
      this.meterStatus = 0;
      this.kvaRating = '';
    //   this.status = 0;
      this.clicked = !this.clicked;
      this.setFilterStorage();
      this.reloadTable(1);
    }
  
    setCheckedAreas() {
      var filter = localStorage.getItem(this.reportStateService.transformersTabKey);
      if (!filter) return;
      var filterObject = JSON.parse(filter);
      if (filterObject.type != 'Transformer') return;
      if (filterObject.filter.filterParams.selectedAreas) {
        this.selectedAreas = filterObject.filter.filterParams.selectedAreas;
        this.areas.forEach((element) => {
          if (this.selectedAreas.includes(element.id)) element.checked = true;
          else element.checked = false;
        });
      }
    }
    setCheckedRegions() {
      var filter = localStorage.getItem(this.reportStateService.transformersTabKey);
      if (!filter) return;
      var filterObject = JSON.parse(filter);
      if (filterObject.type != 'Transformer') return;
      if (filterObject.filter.filterParams.selectedRegions) {
        this.selectedRegions = filterObject.filter.filterParams.selectedRegions;
        this.regions.forEach((element) => {
          if (this.selectedRegions.includes(element.id)) element.checked = true;
          else element.checked = false;
        });
      }
    }
  
    selectDateType(dateType: any) {
      this.selectedDateType = dateType.name;
      this.selectedDateTypeDisplay = dateType.value;
      this.showSelectDateType = false;
    }

    async setCheckedUsers() {
      var filter = localStorage.getItem(this.reportStateService.transformersTabKey);
      if (!filter) return;
      var filterObject = JSON.parse(filter);
      if (filterObject.type != 'Transformer') return;
      if (
        filterObject.filter.filterParams.selectedUsers &&
        filterObject.filter.filterParams.userType
      ) {
        this.userType = filterObject.filter.filterParams.userType;
        this.getUsersForFilter(true, filterObject);
      }
    }
    
    resetUserList() {
      this.allUsers.forEach((user) => {
        user.checked = false;
      });
      this.allUsers = [];
    }
  
    selectUserType(e: any) {
      if (this.userType)
        this.resetUserList();
  
      if (e.target.value != '') {
        this.userType = e.target.value;
        this.getUsersForFilter(false);
      } else {
        this.userType = undefined;
      }
  
      this.createFilterCards();
    }
  
    getUsersForFilter(isFromCache: boolean, filterObject?: any) {
      if (
        this.userType == UserType.FirstCapturedUser ||
        this.userType == UserType.LastCapturedUser
      ) {
        // Dobavi mobilne usere iz baze ako nisi
        if (this.mobileUsers.length === 0) {
          this.userService
            .getUsersForFilter(SystemComponents.Mobile)
            .subscribe((resp) => {
              this.mobileUsers = resp?.data ?? [];
              this.allUsers = this.filteredUsers = this.mobileUsers;
              if (isFromCache) {
                this.selectedUsers =
                  filterObject.filter.filterParams.selectedUsers;
                this.allUsers.forEach((element) => {
                  if (this.selectedUsers.includes(element.id))
                    element.checked = true;
                  else element.checked = false;
                });
              }
              this.createFilterCards();
            });
        } else {
          this.allUsers = this.filteredUsers = [...this.mobileUsers];
        }
      } else {
        if (this.adminUsers.length === 0) {
          this.userService
            .getUsersForFilter(SystemComponents.Admin)
            .subscribe((resp) => {
              this.adminUsers = resp?.data ?? [];
              this.allUsers = this.filteredUsers = this.adminUsers;
              if (isFromCache) {
                this.selectedUsers =
                  filterObject.filter.filterParams.selectedUsers;
                this.allUsers.forEach((element) => {
                  if (this.selectedUsers.includes(element.id))
                    element.checked = true;
                  else element.checked = false;
                });
                this.createFilterCards();
              }
            });
        } else {
          this.allUsers = this.filteredUsers = [...this.adminUsers];
        }
      }
    }
  
    applySearchFilter(e: any) {
      this.filteredUsers = this.allUsers.filter((user) => user.name.includes(e));
    }
    selectDataQuality(id: number) {
      this.router.navigate([`/transformers/data-quality/${id}`]);
    }
    selectUsers(e: any) {
      if (e.status) {
        this.allUsers.push(e.value);
      } else {
        var index = this.allUsers.indexOf(e.value);
        this.allUsers.splice(index, 1);
      }
      this.createFilterCards();
    }
}
