import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { InstallationDetails } from 'src/app/models/installation.model';
import { PermissionsEnum } from 'src/app/models/role.model';
import { AuthService } from 'src/app/services/auth.service';
import { InstallationService } from 'src/app/services/installation.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { TranslationService } from 'src/app/services/translation.service';


@Component({
  selector: 'app-installation-details',
  templateUrl: './installation-details.component.html',
  styleUrls: ['./installation-details.component.scss'],
})
export class InstallationDetailsComponent implements OnInit {
  public PermissionsEnum = PermissionsEnum;

  id: string = '';
  rejectionNote : string='';
  selectedImage: any;
  buName: string = this.translationService.getByKeyFromStorage('BU');
  utName: string = this.translationService.getByKeyFromStorage('UT');
  contractNumber: string = this.translationService.getByKeyFromStorage('Contract number');
  imgElement: any;
  imgZoomFullContainer: any;
  currentRotation: number = 0;

  selectedInstallation :InstallationDetails = new InstallationDetails();
  isGhanaPostFieldsVisible: boolean = true;
  isMapVisible: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private installationService: InstallationService,
    public permissionsService: PermissionsService,
    private translationService: TranslationService,
    private el: ElementRef, 
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.setFieldsVisibility();
    this.id = this.route.snapshot.paramMap.get('id') || '';
    this.imgElement = this.el?.nativeElement?.querySelector('.ngxImageZoomThumbnail');
    this.imgZoomFullContainer = this.el?.nativeElement?.querySelector('.ngxImageZoomFull');

    this.getSelectedInstallation(parseInt(this.id));
  }


  approve(){
    this.installationService.approveInstallation(parseInt(this.id)).subscribe(
      (data) => {
        this.toastr.success('Installation Approved');
        window.location.reload();
    },
    (error) => {
      this.toastr.error('An error ocurred.');
    }
  );
  }
  reject(){
    let obj={
      id:parseInt(this.id),
      note:this.rejectionNote
    }
    this.installationService.rejectInstallation(obj).subscribe(
      (data) => {
        this.toastr.success('Installation Rejected');
        window.location.reload();
    },
    (error) => {
      this.toastr.error('An error ocurred.');
    }
  );
  }

  getSelectedInstallation(id: number) {
    this.installationService.getDetailsById(id).subscribe(
      (data) => {
        var response = data.body;
        console.log(response)
        if(response?.status==='OK') {
            console.log(response);
            this.selectedInstallation = new InstallationDetails(data?.body?.data);
        }

        if(response?.status==='BadRequest'){
          this.toastr.error(response.message);
        }
      },
      (error) => {
        this.toastr.error('An error ocurred.');
      }
    );
  }
  showImage(num: number) {
    if (num === 1) {
     this.selectedImage = this.getCustomersSignature();
    } else if (num == 2) {
      this.selectedImage = this.getFieldRepSignature();
    }else if (num == 3) {
      this.selectedImage = this.getFrontInstallationImage();
    }else if (num == 4) {
      this.selectedImage = this.getCustomerBillImage();
    }else if (num == 5) {
      this.selectedImage = this.getProposedMeterPointBeforeInstallationImage();
    }else if (num == 6) {
      this.selectedImage = this.getInstallationInProgressImage();
    }else if (num == 7) {
      this.selectedImage = this.getSealImage();
    }else if (num == 8) {
      this.selectedImage = this.getInstallationWithSealImage();
    }else if (num == 9) {
      this.selectedImage = this.getMeterCardImage();
    }else if (num == 10) {
      this.selectedImage = this.getFullViewOfSupplyImage();
    }else if (num == 11) {
      this.selectedImage = this.getSupplyCableImage();
    }else if (num == 12) {
      this.selectedImage = this.getRetrievedMeterImage();
    }else if (num == 13) {
      this.selectedImage = this.getMonitorUIUImage();
    }
}

getCustomersSignature() {
  return this.selectedInstallation.images?.find(x => x.imageType === 'CustomerSignature')?.url;
}
getFieldRepSignature() {
  return this.selectedInstallation.images?.find(x => x.imageType === 'FieldRepSignature')?.url;
 
}

getFrontInstallationImage() {
  return this.selectedInstallation.images?.find(x => x.imageType === 'FrontInstallationImage')?.url;
}
getCustomerBillImage() {
  return this.selectedInstallation.images?.find(x => x.imageType === 'CustomerBillImage')?.url;
}
getProposedMeterPointBeforeInstallationImage() {
  return this.selectedInstallation.images?.find(x => x.imageType === 'ProposedMeterPointBeforeInstallationImage')?.url;
}
getInstallationInProgressImage() {
  return this.selectedInstallation.images?.find(x => x.imageType === 'InstallationInProgressImage')?.url;
}
getSealImage() {
  return this.selectedInstallation.images?.find(x => x.imageType === 'SealImage')?.url;
}
getInstallationWithSealImage() {
  return this.selectedInstallation.images?.find(x => x.imageType === 'InstallationWithSealImage')?.url;
}
getMeterCardImage() {
  return this.selectedInstallation.images?.find(x => x.imageType === 'MeterCardImage')?.url;
}
getFullViewOfSupplyImage() {
  return this.selectedInstallation.images?.find(x => x.imageType === 'FullViewOfSupplyImage')?.url;
}
getSupplyCableImage() {
  return this.selectedInstallation.images?.find(x => x.imageType === 'SupplyCableImage')?.url;
}
getRetrievedMeterImage() {
  return this.selectedInstallation.images?.find(x => x.imageType === 'RetrievedMeterImage')?.url;
}
getMonitorUIUImage() {
  return this.selectedInstallation.images?.find(x => x.imageType === 'MonitorUIUImage')?.url;
}

onModalFade() {
  this.currentRotation = 0;

  if(this.imgZoomFullContainer) {
    var displayValue = getComputedStyle(this.imgZoomFullContainer).display;

    if(displayValue == 'block') {
      this.imgElement.click();
    }
  }
}

rotateLeft() {
  this.currentRotation -= 90;
}

rotateRight() {
  this.currentRotation += 90;
}

onModalOpen() {
  this.renderer.setStyle(document.body, 'overflow', 'auto');
  this.renderer.setStyle(document.body, 'overflow-x', 'hidden');
  this.renderer.setStyle(document.body, 'padding-right', '0');
}

setFieldsVisibility() {
    let notVisibleFields: any = localStorage.getItem('notVisibleFields');

    if (!notVisibleFields) {
        return;
    }

    notVisibleFields = JSON.parse(notVisibleFields);
    this.isGhanaPostFieldsVisible = notVisibleFields.find((el: any) => el === 'ghanaPostCode') ? false : true;
}
}
