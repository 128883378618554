import {
  Component,
  Input,
  OnInit,
  ElementRef,
  ViewChild,
  SimpleChanges,
  EventEmitter,
  Output,
} from '@angular/core';
import { ChartConfiguration, ChartOptions, ChartType } from 'chart.js';

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss'],
})
export class LineChartComponent implements OnInit {
  @ViewChild('lineChart') private canvas: ElementRef = {} as ElementRef;

  @Input() lineGraphData: ChartConfiguration<'line'>['data'] = {
    datasets: [],
    labels: ['-'],
  };
  @Input() lineGraphColors: any[] = [];
  @Output() getChartData = new EventEmitter<[number, string]>();

  graphType: string = 'Daily';
  page: number = 0;
  graphData: any = { labels: ['-'] };

  public lineChartOptions: ChartOptions<'line'> = {
    responsive: true,
    backgroundColor: '#fff',
    scales: {
      y: {
        grid: {
          display: false,
        },
        ticks: {
          precision: 0
        }
      },
    },
    plugins: {
      tooltip: {
        backgroundColor: '#fff',
        titleColor: '#8083A3',
        padding: 10,
        callbacks: {
          label: function (context) {
            return context.formattedValue;
          },

          labelTextColor: function (context) {
            return '#000';
          },
        },
        boxWidth: 108,
        boxHeight: 60,
        boxPadding: 17,
        displayColors: false,
      },
    },
  };

  public lineChartLegend = false;

  constructor() {}

  ngOnInit(): void {
    if (this.lineGraphData === undefined) {
      this.lineGraphData = { datasets: [], labels: ['-'] };
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.canvas.nativeElement) {
      var ctx = this.canvas?.nativeElement?.getContext('2d');

      this.lineGraphData.datasets.forEach((value, index) => {
        var gradient = ctx.createLinearGradient(0, 0, 0, 575);

        gradient.addColorStop(0, this.lineGraphColors[index] as String);
        gradient.addColorStop(1, 'rgba(255, 255, 255, 0)');

        value['backgroundColor'] = gradient;
      });
    }
  }
  changeGraphType(type: string) {
    if (this.graphData != type) {
      this.graphType = type;
      this.page = 1;
      this.getChartData.emit([this.page, this.graphType]);
    }
  }

  increasePage(val: number) {
    if (this.page + val >= 0) {
      this.page += val;
      this.getChartData.emit([this.page, this.graphType]);
    }
  }
}
