export enum KycRejectionHeader
{
    CustomerInformation = 0,
    CustomerLoadEstimate = 1,
    BuildingOwnerInformation = 2,
    AccountInformation = 3,
    MeterRequest = 4
}

export enum KycRejectedImage
{
    Signature = 0,
    LandlordsSignature = 1,
    ElectricalContractorsSignature = 2,
    ApplicantsSignature = 3,
    ApplicantsPassportPhotograph = 4
}

export class Rejection {
    rejectedPage: KycRejectionHeader;
    reason: string;
    rejectedImages: KycRejectedImage[];
    stepNumber: number;

    constructor(data: any) {
        this.rejectedPage = data?.rejectedPage;
        this.reason = data?.reason;
        this.rejectedImages = data?.rejectedImages ?? null;
        this.stepNumber = data?.stepNumber
    }
}