import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from 'src/app/config/config';
import { AccountKycList } from 'src/app/models/accountKyc.model';
import { KycAccountDetails } from 'src/app/models/kyc.model';
import { SetNewPassword } from 'src/app/models/setNewPassword.model';
import { PaginationDto, ResponsePackage } from 'src/app/models/util.model';

@Injectable({
  providedIn: 'root',
})
export class AccountKycService {
  private pathApi = this.config.setting['pathApi'] + 'kyc/account/';
  private dataApi = this.config.setting['pathApi'] + 'kyc/DataKyc/';
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  constructor(private http: HttpClient, private config: AppConfig) {}

  login(obj: any) {
    return this.http.post<any>(`${this.pathApi}login`, obj, {
      headers: this.headers,
      observe: 'response',
    });
  }
  register(obj: any) {
    return this.http.post<any>(`${this.pathApi}register`, obj, {
      headers: this.headers,
      observe: 'response',
    });
  }
  checkAccountNumber(obj: any) {
    return this.http.post<any>(`${this.pathApi}checkAccountNumber`, obj, {
      headers: this.headers,
      observe: 'response',
    });
  }
  sendEmail(obj: any): Observable<any> {
    return this.http.post(
      `${this.pathApi}sendResetPasswordEmail`, obj
      
    );
  }

  setNewPassword(newPasswordRequest: SetNewPassword): Observable<any> {
    return this.http.post(`${this.pathApi}setNewPassword`, newPasswordRequest, {
      headers: this.headers,
      observe: 'response',
    });
  }
  getAll(obj: any) {
    return this.http.post<ResponsePackage<PaginationDto<AccountKycList>>>(
      `${this.pathApi}getAll`,
      obj,
      { headers: this.headers, observe: 'response' }
    );
  }
  
  getAllServiceConnection(obj: any) {
    return this.http.post<ResponsePackage<PaginationDto<AccountKycList>>>(
      `${this.pathApi}getAllServiceConnection`,
      obj,
      { headers: this.headers, observe: 'response' }
    );
  }

  download(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}downloadFile`, obj, {
      headers: this.headers,
      observe: 'response',
    });
  }

  sendKycEmail(id: number): Observable<any> {
    return this.http.post(`${this.pathApi}sendKycEmail?id=${id}`, null);
  }

  getKycAccountById(id: number) {
    return this.http.get<any>(`${this.dataApi}getKycAccountDetails?id=${id}`);
  }

  approveKycAccount(id: number): Observable<any> {
    return this.http.post(`${this.pathApi}approveKycAccount`, id);
  }

  saveKycRejection(obj: any): Observable<any> {
    return this.http.post(`${this.dataApi}saveKYCRejection`, obj);
  }

  editKycAccount(obj: KycAccountDetails): Observable<any> {
    return this.http.post(`${this.dataApi}editKycAccount`, obj);
  }

  getServiceConnectionById(id: number) {
    return this.http.get<any>(`${this.pathApi}getServiceConnectionDetails?id=${id}`);
  }

  confirmPayment(kycId: number, verificationId: number){
    return this.http.post<any>(`${this.pathApi}confirmPayment`, {"kycId":kycId, "verificationId": verificationId });
  }
}
