import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { ToastrService } from 'ngx-toastr';
import { PermissionsService } from '../permissions.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private toastr: ToastrService,
    private authService: AuthService,
    public permissionsService: PermissionsService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot) {
    let result = false;
    if (!this.authService.isLoggedIn()) {
      this.router.navigate(['']);
      return false;
    }

    if (!this.permissionsService.checkIfhasUtility()) {
      if (
        route.routeConfig?.path === 'user-management' ||
        route.routeConfig?.path === 'audit-log' ||
        route.routeConfig?.path?.includes('crud') ||
        route.routeConfig?.path?.includes('add') ||
        route.routeConfig?.path?.includes('crud-edit') ||
        route.routeConfig?.path?.includes('upload')
      ) {
        return true;
      }
      this.toastr.error("You don't have permission.");
      this.router.navigate(['']);
      return false;
    }
    let configuration = route.data['configuration'];
    if (configuration) {
      if (!this.authService.hasConfiguration(configuration)) {
        this.toastr.error('Utility does not have this configuration.');
        this.router.navigate(['']);
        return false;
      }
    }
    var admin = localStorage.getItem('isAdmin');
    if (admin != '1') {
      this.router.navigate(['']);
      return false;
    }
    let permsFromRoute = route.data['permission'];
    if (permsFromRoute && permsFromRoute.length > 0) {
      permsFromRoute.forEach((x: any) => {
        if (this.authService.isAuthorized(x)) result = true;
      });

      if (!result) {
        this.toastr.error("You don't have permission.");
        this.router.navigate(['']);
      }
      return result;
    }
    return true;
  }
}
