export interface ResponsePackage<T> {
  status: any;
  message: string;
  data: T;
}

export interface PaginationDto<T> {
  count: number;
  data: T[];
}

export interface OptionObj {
  key: number;
  value: string;
  description?: string;
}

export function getInitOptionObj(): OptionObj {
  return {
    key: 0,
    value: '',
  };
}

export interface MultiSelectObject {
    id: number,
    checked: boolean,
    name: string
}