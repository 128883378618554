import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '../config/config';
import { F33CrudCreate } from '../models/feeder33.model';

@Injectable({
  providedIn: 'root'
})
export class F33Service {

  private pathApi = this.config.setting['pathApi'] + "feeder33/";

  constructor(private http: HttpClient, private config: AppConfig) { }

  getAll(dataIn: any): Observable<any> {
    return this.http.post(`${this.pathApi}getAll`, dataIn, {observe: 'response'});
  }

  save(f33: F33CrudCreate): Observable<any> {
    return this.http.post(`${this.pathApi}save`, f33, {observe: 'response'});
  }

  delete(id: number): Observable<any> {
    return this.http.delete(`${this.pathApi}delete/${id}`, {observe: 'response'});
  }

  getAllForSelect(utilityId: number | null = null): Observable<any> {
    if(utilityId) {
      return this.http.get(`${this.pathApi}getAllForSelect/${utilityId}`, {observe: 'response'});
    }
    else {
      return this.http.get(`${this.pathApi}getAllForSelect`, {observe: 'response'});
    }
  }
}
