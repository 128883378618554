import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfig } from '../config/config';
import { AssetData } from '../models/assetData.model';
import { ActivityLog } from '../models/activityLog.model';
import { TopPerforming } from '../models/topPerforming.model';
import { MapFilter } from '../models/mapFilter.model';
import { AssetsCounts } from '../models/assetsCounts';
import { Coordinates } from '../models/coordinates.model';

@Injectable({
    providedIn: 'root'
  })
  export class DashboardService {
  
    private pathApi = this.config.setting['pathApi'] + "dashboard/";
    private headers = new HttpHeaders({'Content-Type': 'application/json'});
    
    constructor(private http: HttpClient, private config: AppConfig) {
  
    }
    
    // getAnalyticsOveriview(){
    //   return this.http.get<Map<number,AssetData>>(`${this.pathApi}getAnalyticsOverview`,{headers: this.headers, observe: 'response'});
    // }

    
    getAnalyticsOveriviewBuilding(areaIds : number[]){
      return this.http.post<any>(`${this.pathApi}getAnalyticsOverviewBuilding`,areaIds,{headers: this.headers, observe: 'response'});
    }
    getAnalyticsOveriviewCustomer(areaIds : number[]){
      return this.http.post<any>(`${this.pathApi}getAnalyticsOverviewCustomer`,areaIds,{headers: this.headers, observe: 'response'});
    }
    getAnalyticsOveriviewDt(areaIds : number[]){
      return this.http.post<any>(`${this.pathApi}getAnalyticsOverviewDt`,areaIds,{headers: this.headers, observe: 'response'});
    }
    getAnalyticsOveriviewPole(areaIds : number[]){
      return this.http.post<any>(`${this.pathApi}getAnalyticsOverviewPole`,areaIds,{headers: this.headers, observe: 'response'});
    }

    getAssetsCounts(areaIds : number[]) {
      return this.http.post<any>(`${this.pathApi}getAssetsCounts`,areaIds,{headers: this.headers, observe: 'response'});
    }

    getGraphData(request : any) {
      let param = JSON.stringify(request);
      return this.http.post<any>(`${this.pathApi}getGraphData`,param,{headers: this.headers, observe: 'response'});
    }

    getOperationsAnalytics(obj : any) {
      return this.http.post<any>(`${this.pathApi}getOperationsAnalytics`, obj, {headers: this.headers, observe: 'response'});
    }

    getMapData(obj: any) {
      let param = JSON.stringify(obj);
      return this.http.post<any>(`${this.pathApi}getMapData`,param,{headers: this.headers, observe: 'response'});
    }

    getDefaultCoordinates(utilityId: number) {
      return this.http.get<Coordinates>(`${this.pathApi}getDefaultCoordinates?utilityId=${utilityId}`,{headers: this.headers, observe: 'response'});
    }
}