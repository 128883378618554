import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { UseOfPremises } from 'src/app/_modules/customers/_enums/use-of-premises';
import {
  CustomerType,
  ServiceType,
  BuildingType,
} from 'src/app/models/customer.model';
import { PermissionsEnum } from 'src/app/models/role.model';
import { getInitTariff, Tariff } from 'src/app/models/tariff.model';
import { BPSAdminLevel } from 'src/app/models/user.model';
import {
  MultiSelectObject,
  OptionObj,
  getInitOptionObj,
} from 'src/app/models/util.model';
import { CustomerService } from 'src/app/services/customer.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { TariffService } from 'src/app/services/tariff.service';
import { TranslationService } from 'src/app/services/translation.service';
import { TranslationUtils } from 'src/assets/i18n/translation.utils';

@Component({
  selector: 'crud-tariff',
  templateUrl: './crud-tariff.component.html',
  styleUrls: ['./crud-tariff.component.scss'],
})
export class CrudTariffComponent implements OnInit {
  public PermissionsEnum = PermissionsEnum;
  @Input() searchText!: string;
  @Input() utilityId: number = 0;
  @Input() bpsAdminLevel!: BPSAdminLevel;

  public currentPage: number = 1;
  public pageSize: number = 5;
  public count: number = 0;

  tariffs: Tariff[] = [];
  selectedTariff: Tariff = getInitTariff();
  actionLabel$!: Observable<string>;
  isEditMode: boolean = false;
  customerTypes: MultiSelectObject[] = [];
  serviceTypes: MultiSelectObject[] = [];
  useOfPremisesTypes: MultiSelectObject[] = [];

  selectedCustomerTypes: CustomerType[] = [];
  selectedServiceTypes: ServiceType[] = [];
  selectedUseOfPremisesTypes: BuildingType[] = [];
  @ViewChild('closeAddModal') closeAddModal: ElementRef;
  @ViewChild('closeDeleteModal') closeDeleteModal: ElementRef;
  sltStatusName: string =
    this.translationService.getByKeyFromStorage('SltStatus');

  clicked: boolean = false;

  sltOptions: OptionObj[] = [];
  selectedSlt: OptionObj = getInitOptionObj();
  constructor(
    private tariffService: TariffService,
    public permissionsService: PermissionsService,
    private toastr: ToastrService,
    private translationService: TranslationService,
    private customerService: CustomerService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    if (this.utilityId != 0) {
      this.reloadTable();
    }

    this.updateActionLabel();
    this.getEnumData();
  }

  private updateActionLabel(): void {
    const key = this.isEditMode ? TranslationUtils.EDIT_KEY : TranslationUtils.NEW_KEY;
    this.actionLabel$ = this.translateService.stream(key);
  }

  addNew() {
    this.isEditMode = false;
    this.selectedTariff = getInitTariff();
    this.updateActionLabel();
    this.clicked = !this.clicked;
  }

  selectTariff(tariff: Tariff) {
    this.isEditMode = true;
    this.selectedTariff = { ...tariff };
    if (this.selectedTariff.sltStatusId) {
      this.selectedSlt = this.findSltById(tariff.sltStatusId)!;
    }
    this.customerTypes.forEach(
      (x) =>
        (x.checked =
          this.selectedTariff.customerTypes?.find(
            (y) => y.toString() === CustomerType[x.id]
          ) != undefined)
    );
    this.serviceTypes.forEach(
      (x) =>
        (x.checked =
          this.selectedTariff.serviceTypes?.find(
            (y) => y.toString() === ServiceType[x.id]
          ) != undefined)
    );
    this.useOfPremisesTypes.forEach(
      (x) =>
        (x.checked =
          this.selectedTariff.useOfPremises?.find(
            (y) => y.toString() === BuildingType[x.id]
          ) != undefined)
    );
    this.updateActionLabel();
    this.clicked = !this.clicked;
  }

  pageChange(value: any) {
    this.currentPage = value;
    this.reloadTable(this.searchText);
  }

  reloadTable(searchText?: string, page: any = null) {
    if (page) {
      this.currentPage = page;
    }

    let dataIn = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize,
      },
      filterParams: {
        searchValue: searchText,
      },
      utilityId: this.utilityId,
    };
    this.selectedSlt = getInitOptionObj();
    this.getSltStatuses();
    this.getAllTariffs(dataIn);
  }

  filter() {
    this.currentPage = 1;
    let dataIn = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize,
      },
      filterParams: {
        SearchValue: this.searchText,
      },
      utilityId: this.utilityId,
    };
    this.getSltStatuses();
    this.getAllTariffs(dataIn);
  }

  private getAllTariffs(dataIn: any) {
    this.tariffService.getAll(dataIn).subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.tariffs = responseData.data.data;
          this.count = responseData.data.count;
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  save() {
    this.mapSaveData();
    if (this.validateMultiSelectFields()) {
      this.tariffService.save(this.selectedTariff).subscribe({
        next: (response) => {
          const responseData = response.body;
          if (responseData?.status === 200 || responseData?.status === 'OK') {
            this.closeAddModal?.nativeElement?.click();
            this.toastr.success(responseData?.message);
            if (this.selectedTariff.id === 0) {
              this.pageChange(1);
              return;
            }
            this.reloadTable(this.searchText);
          } else {
            this.toastr.error(responseData?.message);
          }
        },
        error: (_) => {
          this.toastr.error('Error occured');
        },
      });
    }
  }

  mapSaveData() {
    this.selectedTariff.utilityId = this.utilityId;
    this.selectedTariff.sltStatusId =
      this.selectedSlt?.key && this.selectedSlt.key !== 0
        ? this.selectedSlt?.key
        : this.selectedTariff.sltStatusId;
    this.selectedTariff.customerTypes =
      this.selectedCustomerTypes.length > 0
        ? this.selectedCustomerTypes
        : this.selectedTariff.customerTypes;
    this.selectedTariff.serviceTypes =
      this.selectedServiceTypes.length > 0
        ? this.selectedServiceTypes
        : this.selectedTariff.serviceTypes;
    this.selectedTariff.useOfPremises =
      this.selectedUseOfPremisesTypes.length > 0
        ? this.selectedUseOfPremisesTypes
        : this.selectedTariff.useOfPremises;
  }
  deleteTariff() {
    this.tariffService.delete(this.selectedTariff.id).subscribe({
      next: (response) => {
        const responseData = response.body;
        this.closeDeleteModal?.nativeElement?.click();
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.pageChange(1);
          this.toastr.success(responseData?.message);
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  joinEnumerations(obj?: Array<any>) {
    var joined = obj?.map((x) => x).join(', ');
    return joined;
  }

  getSltStatuses() {
    this.customerService.getSltStatusesForOptions(this.utilityId).subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.sltOptions = responseData.data;
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  findSltById(id?: number) {
    return this.sltOptions.find((x) => x.key === id);
  }

  selectCustomerType(e: any) {
    this.selectedCustomerTypes = [];
    this.customerTypes
      .filter((x) => x.checked)
      .forEach((y) => {
        this.selectedCustomerTypes.push(y.id);
      });
  }

  selectServiceType(e: any) {
    this.selectedServiceTypes = [];
    this.serviceTypes
      .filter((x) => x.checked)
      .forEach((y) => {
        this.selectedServiceTypes.push(y.id);
      });
  }

  selectUseOfPremisesType(e: any) {
    this.selectedUseOfPremisesTypes = [];
    this.useOfPremisesTypes
      .filter((x) => x.checked)
      .forEach((y) => {
        this.selectedUseOfPremisesTypes.push(y.id);
      });
  }

  getEnumData() {
    this.getCustomerTypes();
    this.getServiceTypes();
    this.getUseOfPremisesTypes();
  }
  getCustomerTypes() {
    Object.values(CustomerType)
      .filter((v) => !isNaN(Number(v)))
      .forEach((key) => {
        const optionObject: MultiSelectObject = {
          id: Number(key),
          name: CustomerType[key as keyof typeof CustomerType].toString(),
          checked: false,
        };
        this.customerTypes.push(optionObject);
      });
  }

  getServiceTypes() {
    Object.values(ServiceType)
      .filter((v) => !isNaN(Number(v)))
      .forEach((key) => {
        const optionObject: MultiSelectObject = {
          id: Number(key),
          name: ServiceType[key as keyof typeof ServiceType].toString(),
          checked: false,
        };
        this.serviceTypes.push(optionObject);
      });
  }

  getUseOfPremisesTypes() {
    Object.values(BuildingType)
      .filter((v) => !isNaN(Number(v)))
      .forEach((key) => {
        const optionObject: MultiSelectObject = {
          id: Number(key),
          name: BuildingType[key as keyof typeof BuildingType].toString(),
          checked: false,
        };
        this.useOfPremisesTypes.push(optionObject);
      });
  }

  onModalFade() {
    this.customerTypes.forEach((x) => (x.checked = false));
    this.serviceTypes.forEach((x) => (x.checked = false));
    this.useOfPremisesTypes.forEach((x) => (x.checked = false));
    this.selectedSlt = getInitOptionObj();
  }

  validateMultiSelectFields() {
    if (
      !this.selectedTariff.sltStatusId ||
      this.selectedTariff.sltStatusId == 0
    ) {
      this.toastr.error(
        'You have to select the ' +
          this.sltStatusName +
          ' for which this tariff applies.'
      );
      return false;
    } else if (
      !this.selectedTariff.customerTypes ||
      this.selectedTariff.customerTypes.length == 0
    ) {
      this.toastr.error(
        'You have to select at least one Customer type for which this tariff applies.'
      );
      return false;
    } else if (
      !this.selectedTariff.serviceTypes ||
      this.selectedTariff.serviceTypes.length == 0
    ) {
      this.toastr.error(
        'You have to select at least one Service type for which this tariff applies.'
      );
      return false;
    } else if (
      !this.selectedTariff.useOfPremises ||
      this.selectedTariff.useOfPremises.length == 0
    ) {
      this.toastr.error(
        'You have to select at least one Use of premises type for which this tariff applies.'
      );
      return false;
    }

    return true;
  }
}
