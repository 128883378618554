import {
  Component,
  OnInit,
  ViewChild,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import { MapFilterDataOut } from 'src/app/models/mapFilterDataOut.model';
import { MapFilter } from 'src/app/models/mapFilter.model';
import { DashboardService } from 'src/app/services/dashboard.service';
import { SelectItem } from 'src/app/models/mapFilter.model';
import { AdditionalInfo } from 'src/app/models/additionalInfo.model';
import { DashboardBpsService } from 'src/app/services/bps/dashboard.service';
import { Coordinates } from 'src/app/models/coordinates.model';
import { MapEntityType } from 'src/app/enums/map-entity-type.enum';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit, OnDestroy {
  public agmMap: google.maps.Map;
  mapData: MapFilterDataOut[] = [];
  @Input() selectedAssets: SelectItem[] = [];
  @Input() areaIds: number[] = [];
  @Input() utilityIds: number[] = [];
  @Input() reloadMapTrigger: boolean = true;
  @Input() defaultCoordinates: Coordinates;

  regions: SelectItem[] = [];

  searchValue: string = '';

  northEastLat: number = 0;
  northEastLng: number = 0;
  southWestLat: number = 0;
  southWestLng: number = 0;
  zoom: number = 6;

  // Accra, Ghana capital
  lat: number = 5.6026283219970585;
  lng: number = -0.18522295756972676;

  lastSentLongitude: number = 0;
  lastSentLatitude: number = 0;

  drag = false;
  mapStep = 0.00005;

  isMenuOpen = false;
  isSearch: boolean = true;
  isMenuOpenRegion = false;
  buName: string = '';

  entity: string = '';
  additionalInfo: string = '';
  buildingCustomers: any;
  tooltipCustomer: any;
  tooltipBuilding: any;
  tooltipPole: any;
  tooltipDt: any;
  tooltipPositionX: number = 0;
  tooltipPositionY: number = 0;
  tooltipHidden: boolean = true;
  isInitialLoad: boolean = true;
  coordinatesChanged: boolean = false;
  isStreetViewVisible: boolean = false;
  isMapExpanded: boolean = false;
  isStreetViewLayerVisible: boolean = false;

  @Output() mapExpandedChanged: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('streetviewSmall') streetviewSmall: any;
  pegmanOption: Element | null;
  streetViewLayer: google.maps.StreetViewCoverageLayer;
  panorama!: google.maps.StreetViewPanorama;

  // Tooltip options
  hoveredMapItemId: string | null = null;
  moveTooltipBottom: boolean = false;
  moveTooltipTop: boolean = false;
  moveTooltipRight: boolean = false;
  moveTooltipLeft: boolean = true;
  moveTooltipTopRight: boolean = false;
  moveTooltipBottomRight: boolean = false;

  constructor(
    private dashboardService: DashboardService,
    private dashboardBpsService: DashboardBpsService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    //this.getMapData();
  }

  ngOnDestroy(): void {
    if (this.pegmanOption) {
      this.pegmanOption?.removeEventListener(
        'click',
        this.streetViewToggleListener
      );
    }
  }

  private getMapFilter(
    mapEntityType: MapEntityType,
    fromSearch: boolean = false
  ) {
    let mapFilter = new MapFilter();
    mapFilter.Assets = this.selectedAssets;
    for (var i = 0; i < this.regions.length; i++) {
      if (this.regions[i].checked) {
        mapFilter.BuID = this.regions[i].Value;
        break;
      }
    }
    if (fromSearch) {
      this.zoom = 6;
      var latitude = localStorage.getItem('latitude');
      var longitude = localStorage.getItem('longitude');
      this.lat = latitude != null ? parseFloat(latitude) : 5.906986824806585;
      this.lng = longitude != null ? parseFloat(longitude) : 0.381388470567412;
    }

    mapFilter.SearchValue = this.searchValue;
    mapFilter.NorthEastLat = this.northEastLat;
    mapFilter.NorthEastLng = this.northEastLng;

    mapFilter.SouthWestLat = this.southWestLat;
    mapFilter.SouthWestLng = this.southWestLng;
    mapFilter.Zoom = this.zoom;
    mapFilter.mapEntityType = mapEntityType;
    this.lastSentLongitude = this.northEastLng;
    this.lastSentLatitude = this.northEastLat;
    return mapFilter;
  }
  public getMapData(fromSearch: boolean = false) {
    if (
      this.defaultCoordinates &&
      (this.isInitialLoad || this.coordinatesChanged)
    ) {
      this.lat = this.defaultCoordinates.latitude!;
      this.lng = this.defaultCoordinates.longitude!;
      this.zoom = 11;
      this.isInitialLoad = false;
      this.coordinatesChanged = false;
    } else {
      this.mapData=[]
      this.getCustomerMapData(fromSearch);
      this.getBuildingMapData(fromSearch);
      this.getTransformerMapData(fromSearch);
      this.getPoleMapData(fromSearch);
    }
  }

  getCustomerMapData(fromSearch: boolean = false) {
    let obj = {
      mapFilter: this.getMapFilter(MapEntityType.CUSTOMER, fromSearch),
      areaIds: this.areaIds,
      utilityIds: this.utilityIds,
    };
    this.mapRequest(obj);
  }
  getBuildingMapData(fromSearch: boolean = false) {
    let obj = {
      mapFilter: this.getMapFilter(MapEntityType.BUILDING, fromSearch),
      areaIds: this.areaIds,
      utilityIds: this.utilityIds,
    };
    this.mapRequest(obj);
  }
  getPoleMapData(fromSearch: boolean = false) {
    let obj = {
      mapFilter: this.getMapFilter(MapEntityType.POLE, fromSearch),
      areaIds: this.areaIds,
      utilityIds: this.utilityIds,
    };
    this.mapRequest(obj);
  }
  getTransformerMapData(fromSearch: boolean = false) {
    let obj = {
      mapFilter: this.getMapFilter(MapEntityType.TRANSFORMER, fromSearch),
      areaIds: this.areaIds,
      utilityIds: this.utilityIds,
    };
    this.mapRequest(obj);
  }

  private mapRequest(obj: any) {
    if (this.utilityIds.length > 0) {
      this.dashboardBpsService.getMapData(obj).subscribe({
        next: (data) => {
          var mapData = data?.body?.data?.length
            ? data?.body?.data.map((el: any) => new MapFilterDataOut(el))
            : [];
          this.cdr.detectChanges(); // Trigger change detection
          this.mapData=[...this.mapData ,...mapData];
        },
        error: (error) => console.log(error.statusText),
      });
    } else {
      this.dashboardService.getMapData(obj).subscribe({
        next: (data) => {
          var mapData = data?.body?.data?.length
            ? data?.body?.data.map((el: any) => new MapFilterDataOut(el))
            : [];
          this.cdr.detectChanges();
          this.mapData=[...this.mapData ,...mapData];
        },
        error: (error) => console.log(error.statusText),
      });
    }
  }
  onZoomChange(event: any) {
    this.zoom = event;
  }

  mapBoundsChange(event: any) {
    this.northEastLat = event.getNorthEast().lat();
    this.northEastLng = event.getNorthEast().lng();
    this.southWestLat = event.getSouthWest().lat();
    this.southWestLng = event.getSouthWest().lng();
    //   console.log('mapBoundsChange');

    this.hideTooltip();
  }

  mapReady(map: google.maps.Map) {
    this.agmMap = map;
    this.streetViewLayer = new google.maps.StreetViewCoverageLayer();

    setTimeout(() => {
      this.pegmanOption = window?.document.querySelector('button.gm-svpc');

      if (this.pegmanOption) {
        this.pegmanOption?.addEventListener(
          'click',
          this.streetViewToggleListener
        );
      }
      // console.log('pegman', this.pegmanOption);
    }, 1500);
  }

  loaded() {
    if (!this.drag) {
      this.getMapData();
    } else {
      this.drag = false;
    }
  }

  zoomPoint(event: any, data: MapFilterDataOut) {
    this.isStreetViewVisible = true;
    this.showSmallStreetView(data.latitude, data.longitude);
    // this.showTooltip(event, data.additionalInfo, data.type);
  }

  showTooltip(event: any, additionalInfo: AdditionalInfo, entity: string) {
    this.tooltipHidden = false;

    this.placeTooltip(entity, additionalInfo, event);
  }

  placeTooltip(entity: string, additionalInfo: AdditionalInfo, event: any) {
    this.entity = entity;
    this.additionalInfo = additionalInfo.id;
    this.buildingCustomers = additionalInfo.customers;
    this.tooltipCustomer = additionalInfo.tooltipCustomer;
    this.tooltipBuilding = additionalInfo.tooltipBuilding;
    this.tooltipPole = additionalInfo.tooltipPole;
    this.tooltipDt = additionalInfo.tooltipDt;
    this.hoveredMapItemId = additionalInfo.id;
    // console.log('placeTooltip', this.hoveredMapItemId);
    this.calculateTooltipPosition(event);
  }

  hideTooltip() {
    this.hoveredMapItemId = null;
  }

  zoomCluster(data: any) {
    if (this.zoom + 4 > 22) {
      this.zoom = 22;
    } else {
      this.zoom += 4;
    }

    this.lat = data.latitude;
    this.lng = data.longitude;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['defaultCoordinates']) {
      this.coordinatesChanged = true;
      this.getMapData();
    }
  }

  /**
   * It will show a street view container in the bottom right of the map
   * @param lat Location latitude
   * @param lng Location longitude
   */
  showSmallStreetView(lat: number, lng: number) {
    let streetViewPosition: google.maps.LatLngLiteral = { lat: lat, lng: lng };
    this.panorama = new window['google'].maps.StreetViewPanorama(
      this.streetviewSmall.nativeElement,
      {
        position: streetViewPosition,
        scrollwheel: true,
        zoom: 1,
        fullscreenControl: true,
        showRoadLabels: true,
        visible: true,
        addressControl: false,
        panControl: true,
      }
    );

    this.agmMap.setStreetView(this.panorama);
    this.hidePegmanContainer();
  }

  /**
   * It will listen for click events on the "pegman" option on the map and
   * display/hide street view layer (blue line showing where street view is available)
   * @param event An event which takes place in the DOM
   */
  streetViewToggleListener: EventListener = (event: Event) => {
    this.isStreetViewLayerVisible = !this.isStreetViewLayerVisible;
    // console.log('PEGMAN CLICKED');

    if (this.isStreetViewLayerVisible) {
      this.streetViewLayer.setMap(this.agmMap);
    } else {
      this.streetViewLayer.setMap(null);
    }
  };

  toggleMapExpand() {
    this.isMapExpanded = !this.isMapExpanded;
    this.mapExpandedChanged.emit(this.isMapExpanded);
  }

  onAssetMouseEnter(event: any, data: MapFilterDataOut) {
    this.showTooltip(event, data.additionalInfo, data.type);
  }

  onAssetMouseLeave() {
    this.hideTooltip();
  }

  /**
   * It will hide a pegman outer container that disable a hover effect on the current asset (pin)
   */
  hidePegmanContainer() {
    setTimeout(() => {
      let pegmanImg: Element | null = window?.document.querySelector(
        "[src='https://maps.gstatic.com/mapfiles/transparent.png']"
      );

      if (!pegmanImg) {
        return;
      }

      let parentDiv = pegmanImg.parentElement;
      // console.log('parent', parent);

      parentDiv?.setAttribute('style', 'width: 0px !important;');
    }, 500);
  }

  calculateTooltipPosition(event: any) {
    const mapContainer = document.getElementById('map-component')!;
    // const pageContainer = document.getElementById('page-wraper')!;
    const mapContainerRect = mapContainer.getBoundingClientRect();
    // const pageContainerRect = pageContainer.getBoundingClientRect();

    let popupWidth: number = 170;
    let isOnLeftEdge: boolean =
      event.clientX - mapContainerRect.left < popupWidth;
    // let isOnRightEdge: boolean = (pageContainerRect.width / devicePixelRatio - event.screenX) < popupWidth;
    let isOnTopEdge: boolean = false;
    let isOnBottomEdge: boolean =
      Math.abs(event.screenY - mapContainerRect.bottom) < popupWidth;

    if (mapContainerRect.top > 0) {
      isOnTopEdge = event.clientY - mapContainerRect.top < popupWidth;
    }

    // Tooltip is moved left by default
    this.resetTooltipPositions();

    if (isOnLeftEdge && isOnBottomEdge) {
      this.moveTooltipLeft = false;
      this.moveTooltipTopRight = true;
      return;
    }

    if (isOnLeftEdge && isOnTopEdge) {
      this.moveTooltipLeft = false;
      this.moveTooltipBottomRight = true;
      return;
    }

    if (isOnTopEdge) {
      this.moveTooltipBottom = true;
    }

    if (isOnLeftEdge) {
      this.moveTooltipRight = true;
      this.moveTooltipLeft = false;
    }

    if (isOnBottomEdge) {
      this.moveTooltipTop = true;
    }
  }

  resetTooltipPositions() {
    this.moveTooltipBottom = false;
    this.moveTooltipTop = false;
    this.moveTooltipRight = false;
    this.moveTooltipTopRight = false;
    this.moveTooltipBottomRight = false;
    this.moveTooltipLeft = true;
  }

  /**
   * It will listen for events fired when the map becomes idle after panning or zooming.
   */
  mapIdleListener() {
    if (
      Math.abs(this.northEastLng - this.lastSentLongitude) > this.mapStep ||
      Math.abs(this.northEastLat - this.lastSentLatitude) > this.mapStep
    ) {
      this.getMapData();
      this.drag = true;
    }
  }
}
