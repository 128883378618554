<div class="card-wrapper row" style="margin-bottom: 0;">
    <div class="mb-25 row">
      <div class="col-md-3">
        <h2 class="card-title">{{ 'DASHBOARD.OPERATIONS_ANALYTICS.TITLE' | translate }}</h2>
      </div>
      <div *ngIf="lineGraphData" class="col-md-9 d-flex legend">
        <div  *ngFor="let item of lineGraphData.datasets let i = index" class="d-flex justify-content-center align-items-center me-4">
                <div class="dot operations" [ngClass]="item.label != undefined ? (item.label | lowercase) : ''"></div>
                <span>{{'DASHBOARD.OPERATIONS_ANALYTICS.' + item.label | uppercase | translate}}</span>
        </div>
    </div> 
    </div>
    <div class="row">
      <div class="col-md-3 data">
        <div class="card customers">
          <span class="title">{{ 'DASHBOARD.OPERATIONS_ANALYTICS.CUSTOMERS_COUNT' | translate }}</span>
          <span class="num">{{ customersData.totalCount | number }}</span>
        </div>
        <div class="card mt-4 surveys">
          <span class="title">{{ 'DASHBOARD.OPERATIONS_ANALYTICS.SURVEYS_COUNT' | translate }}</span>
          <span class="num">{{counts.surveysCount | number}}</span>
        </div>
        <div class="card mt-4 installations">
          <span class="title">{{ 'DASHBOARD.OPERATIONS_ANALYTICS.INSTALLATIONS_COUNT' | translate }}</span>
          <span class="num">{{counts.installationsCounts | number}}</span>
        </div>
      </div>
      <div class="col-md-9 graph" id="chart">
        <app-line-chart (getChartData)="getOperationsAnalytics($event)" [lineGraphData]="lineGraphData" [lineGraphColors]="lineGraphColors"></app-line-chart>
      </div>
    </div>
  </div>