import { Pipe, PipeTransform } from '@angular/core';
import { DecommissioningStatus, DecommissioningStockItemStatus } from '../models/decommissioning';

@Pipe({
  name: 'stockItemValue'
})
export class StockItemValuePipe implements PipeTransform {
  transform(value: any): unknown {
    switch(value){
      case 0: 
        return "In Storage";
      case 1: 
      return "Awaiting Recycling";
      case 2: 
        return "Recycled";
      case DecommissioningStockItemStatus[DecommissioningStockItemStatus.InStorage]:
        return "In Storage";
      case DecommissioningStockItemStatus[DecommissioningStockItemStatus.AwaitingRecycling]:
        return "Awaiting Recycling";
      case DecommissioningStockItemStatus[DecommissioningStockItemStatus.Recycled]:
        return "Recycled";
      default:
        return value;
    }
  }
}
