import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PermissionsEnum } from 'src/app/models/role.model';
import { DashboardConfiguration } from 'src/app/models/utility.model';
import { PermissionsService } from 'src/app/services/permissions.service';

@Component({
  selector: 'app-permission-menu',
  templateUrl: './permission-menu.component.html',
  styleUrls: ['./permission-menu.component.scss'],
})
export class PermissionMenuComponent implements OnInit {
  @Input() permissions: any;
  @Input() isMobile: boolean = false;
  @Input() isDisabled: boolean;
  @Output() permissionsChange: EventEmitter<any> = new EventEmitter<any>();
  public PermissionsEnum = PermissionsEnum;
  public DashboardConfiguration = DashboardConfiguration;
  isRegularizationUploadVisible: boolean = true;

  constructor(
    public permissionsService: PermissionsService,
  ) {}

  ngOnInit(): void {
    this.setFieldsVisibility();
  }

  isChecked(listPermissions: any) {
    let checked = true;
    listPermissions.forEach((val: any) => {
      if (!this.permissions.includes(val)) checked = false;
    });
    return checked;
  }

  onChangePermission(listPermissions: any, event: any = null) {
    if (
      listPermissions.includes(PermissionsEnum.MeterReading) ||
      this.permissions.includes(PermissionsEnum.MeterReading)
    ) {
      this.permissions = [];
    } 
    listPermissions.forEach((value: any) => {
      if (event != null) {
        if (!this.isChecked([value]) && event.target.checked == true)
          this.permissions.push(value);
        else if (this.isChecked([value]) && event.target.checked == false)
          this.permissions = this.permissions.filter(
            (item: any) => item != value
          );
      } else {
        if (this.permissions.includes(value))
          this.permissions = this.permissions.filter(
            (item: any) => item != value
          );
        else this.permissions.push(value);
      }
    });
    this.permissionsChange.emit(this.permissions);
  }

  setFieldsVisibility() {
    let notVisibleFields: any = localStorage.getItem('notVisibleFields');

    if (!notVisibleFields) {
      return;
    }
    notVisibleFields = JSON.parse(notVisibleFields);

    this.isRegularizationUploadVisible = notVisibleFields.find(
      (el: any) => el === 'regularizationUpload'
    )
      ? false
      : true;
  }
}
