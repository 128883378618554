import { Component, OnInit, Input } from '@angular/core';
import { DashboardService } from 'src/app/services/dashboard.service';
import { ChartConfiguration, ChartComponentLike, TooltipItem} from "chart.js";
import { Color } from 'chart.js';

@Component({
  selector: 'bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss']
})
export class BarChartComponent implements OnInit {
  
  @Input() graphData: ChartConfiguration<'bar'>['data']= {datasets:[]};
  @Input() colors: any[] = [];

  public barChartLegend = false;
  public barChartPlugins =  [];
  

  public barChartOptions: ChartConfiguration<'bar'>['options'] = {
    responsive: true,
    backgroundColor: "#fff",
    scales: {
      x: {
        grid: {
          display: false
        }
      },
      y: {
        grid: {
          display: false
        }
      }
    },
    
    plugins: {
      tooltip: {
        multiKeyBackground: "#fff",
        borderWidth: 0,
        borderColor: "#fff",
        usePointStyle: true,
        position: 'average',
        intersect: false,
        callbacks: {
          labelColor: function(context){
            return {
              backgroundColor: context.element.options['backgroundColor'] as Color,
              borderColor: '',
              borderWidth: 0
            }
          },
          labelPointStyle: function(context) {
            return {
                pointStyle: 'circle',
                rotation: 0
            };
          },
          label: function(context){
            return context.formattedValue;
          },
          title: function(){
            return '';
          },
        },
        backgroundColor: "#f9f9fa",
        bodyColor: "#000"
      }
    },
    //hover: {mode: undefined},
    interaction: {
      mode: 'index',
      intersect: false
    },
    
  };

  constructor() {
  }

  ngOnInit() {
  }


}
