import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Toast } from 'ngx-toastr';
import { AssetData } from 'src/app/models/assetData.model';
import { AssetsCounts } from 'src/app/models/assetsCounts';
import { User } from 'src/app/models/user.model';

@Component({
  selector: 'operations-analytics',
  templateUrl: './operations-analytics.component.html',
  styleUrls: ['./operations-analytics.component.scss']
})
export class OperationsAnalyticsComponent implements OnInit {

  @Input() lineGraphData: any;
  @Input() counts: AssetsCounts;
  @Output() getData = new EventEmitter<[number, string]>();
  @Input() customersData: AssetData = new AssetData();

  lineGraphColors: any [] = [];
  pageSize: number = 7;
  constructor() {
    
  }

  ngOnInit(): void {
    this.lineGraphColors.push("#ff6347");
    this.lineGraphColors.push("#E072A4");
    this.lineGraphColors.push("#8F00FF");
    
  }

  getOperationsAnalytics(eventData: [number, string]){
    this.getData.emit(eventData);
  }
}
