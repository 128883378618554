export enum TypeOfPremises {
    TwoBedRoomFlat = 1,
    TenementHouse = 2,
    ThreeBedRoomFlat = 3,
    SingleRoom = 4,
    ShopBoysQt = 5,
    BlockOfFlats = 6,
    Duplex = 7,
    Others = 8,
}
