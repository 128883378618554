export enum CustomerStatsOptions
{
    Existing,
    New,
    Regularized,
    Direct,
    Onboarded,
    Retagged,
    Unvalidated,
    WithAccNo,
    MeterNumberStartsWith,
    AlphaMeterType,
    InvalidMeterNumber,
    WithSpn
}
