export class DecommissioningStats {
  total: number | undefined;
  undefined: number | undefined;
  faulty: number | undefined;
  burnt: number | undefined;
  upgrade: number | undefined;

  constructor() {
    this.total = undefined;
    this.undefined = undefined;
    this.faulty = undefined;
    this.burnt = undefined;
    this.upgrade = undefined;
  }
}

export class Decommissioning {
  meterNumber: string = '';
  meterPhase: string = '';
  meterMake: string = '';
  meterType: string = '';
  accountNumber: string = '';
  address: string = '';
  region: string = '';
  area: string = '';
  decommissionDate?: Date;
  reasonForDecommissioning?: ReasonForDecommission;
  decommissioningStatus?: DecommissioningStatus; 
  stockItemStatus?: DecommissioningStockItemStatus; 
  
  customer?: any;
  customerId?: number;
  guid?: string; // Assuming `Guid` is represented as a string in TypeScript
  currentReadingOnMeter?: number;
  latitude?: number;
  id: number = 0;
  isChecked: boolean = false;
  longitude?: number;
  assignedDate?: Date;
  createdDate?: Date;
  approvedDate?: Date;
  assignedUserId?: number; // Mobile/Field user that needs to remove meter
  decommissionedBy?: number; // User that Submitted from mobile
  approvedBy?: number; // User that approved from Web App
  comments?: string;
}

export class DecommissionedMeterDetails {
  id: number = 0;
  customer: DecommissionedMeterCustomer = new DecommissionedMeterCustomer();
  meterSlrn: string = '';
  number: string = '';
  make: string = '';
  meterType: string = '';
  currentReading: string = '';
  latitude: number = 0;
  longitude: number = 0;
  decommissionedBy: string = '';
  decommissionDate: string = '';
  approvedBy: string = '';
  approvalDate: string = '';
  images: DecommissionedMeterImages[];
  reasonForDecommission: string = '';
  comments: string = '';
  decommissioningStatus?: DecommissioningStatus;
  stockItemStatus?: DecommissioningStockItemStatus;

  constructor(data?: any) {
    this.id = data?.id ?? 0;
    this.currentReading = data?.id ?? 0;
    this.meterSlrn = data?.meter?.slrn ?? '';
    this.number = data?.meter?.number ?? '';
    this.make = data?.meter?.make ?? '';
    this.meterType = data?.meter?.meterType ?? '';
    this.currentReading = data?.meter?.readingOnMeter ?? '';
    this.latitude = data?.latitude ?? '';
    this.longitude = data?.longitude ?? '';
    this.decommissionedBy = data?.decommissionedBy ?? '';
    this.decommissionDate = data?.decommissionDate ?? '';
    this.approvalDate = data?.approvedDate ?? '';
    this.approvedBy = data?.approvedBy ?? '';
    this.reasonForDecommission = data?.reasonForDecommission ?? '';
    this.comments = data?.comments ?? '';
    this.decommissioningStatus =
      data?.decommissioningStatus ?? DecommissioningStatus.Pending;
    this.stockItemStatus =
    data?.stockItemStatus ?? DecommissioningStockItemStatus.AwaitingRecycling;
    this.customer = data?.customer ?? new DecommissionedMeterCustomer();
    this.images = data?.images;
  }
}

export class DecommissionedMeterCustomer {
  accountNumber: string = '';
  slrn: string = '';
  physicalAddress: string = '';
  areaName: string = '';
  buildingOwner: string = '';
  id: 3;
  typeOfConnection: string = '';
  customerType: string = '';
  regionName: string = '';
  tariffName: string = '';
}

export interface DecommissionedMeterImages {
  url: string;
  imageType: any;
}

export enum DecommissioningStatus {
  Pending = 0,
  Submitted = 1, //Completed on mobile
  Approved = 2,
  Assigned = 3,
}

export enum ReasonForDecommission {
  FaultyMeter = 0,
  Burnt = 1,
  Upgrade = 2,
}

export enum DecommissioningStockItemStatus {
  InStorage = 1,
  AwaitingRecycling = 2,
  Recycled = 3
}