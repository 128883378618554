import { Component } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { ModalService } from '../_modules/shared/_services/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'CAIMS.Ng';

  constructor(private authService: AuthService, public modalService: ModalService, private translate: TranslateService) {}

  ngOnInit():void {

    var color= localStorage.getItem('colorCode')
    if(color!=null){
      this.setUtilityColors(color);
    }
    else{
      this.setBpsAdminColors();
    }
    this.setLanguage();

  }
  setUtilityColors(color:string){
    document.documentElement.style.setProperty('--primary-color', color);
    document.documentElement.style.setProperty('--bg-color', color+'08');
    document.documentElement.style.setProperty('--table-root-bg-color', color+'15');
    document.documentElement.style.setProperty('--card-color', color+'22');
    document.documentElement.style.setProperty('--bubble-color', color+'95');
  }
  setBpsAdminColors(){
    document.documentElement.style.setProperty('--primary-color', '#03423E');
    document.documentElement.style.setProperty('--bg-color', '#F5FBFA');
    document.documentElement.style.setProperty('--table-root-bg-color', '#EBF1F1');
    document.documentElement.style.setProperty('--card-color', '#e4ecea');
    document.documentElement.style.setProperty('--bubble-color', '#688F8D');
  }
  setLanguage() {
    this.translate.setDefaultLang('en');
    const lang = localStorage.getItem('preferredLanguage') ?? 'en';
    this.translate.use(lang);
  }
}
