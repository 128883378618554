import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-reading-text-box',
  templateUrl: './reading-text-box.component.html',
  styleUrls: ['./reading-text-box.component.scss']
})
export class ReadingTextBoxComponent{

  constructor() { }

  @Input() header: string = '';
  @Input() items: any = '';
}
