import { Injectable } from '@angular/core';
import { Analytics, getAnalytics, logEvent } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  public firebaseConfig: any;
  public analytics: Analytics;

  constructor() {
    this.firebaseConfig = {
      apiKey: "AIzaSyASVbpuf6Im-ytvQpSlZANQkdtAwn0fCAE",
      authDomain: "caims-web.firebaseapp.com",
      projectId: "caims-web",
      storageBucket: "caims-web.appspot.com",
      messagingSenderId: "682219027084",
      appId: "1:682219027084:web:56f864064d7b41658ed9a9",
      measurementId: "G-ZZY3KQBV4R"
    };

    const app = initializeApp(this.firebaseConfig);
    this.analytics = getAnalytics(app);
  }

  public logCustomEvent(eventName: string, eventParams: { [key: string]: any }) {
    logEvent(this.analytics, eventName, eventParams);
  }
}
