<header class="header-top">
  <div class="logo-wrapper" [routerLink]="['/']">
    <img [src]="logoUrl" height="45" width="45" alt="BPS Manager Logo">
    <span class="header-title" style="margin-left: 2rem;">CAIMS</span>
  </div>
  <h1 class="page-title">
    <div class="header-title" [ngClass]="pageBreadcrumb ? 'clickable' : ''" (click)="goBack()">
      {{pageTitle | translate}}
    </div>
    <span *ngIf="pageBreadcrumb">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.3335 4.27484L6.27636 3.33203L10.9904 8.04608L6.27636 12.7602L5.3335 11.8173L9.10476 8.04608L5.3335 4.27484Z" fill="#8F92A1"/>
      </svg>
      {{pageBreadcrumb | translate}}  
    </span>
  </h1>
  <div class="user-wrapper">
    <div class="language-selector-root">
      <div class="dropdown" (click)="showLanguageDropdown= !showLanguageDropdown" click-stop-propagation>
        <span><img [src]="'../../assets/Img/' + selectedLanguage + '-flag.png'" alt="Language flag" /></span>
        <img src="../../assets/icons/gray-arrow-down.svg" alt="Arrow down icon" />
      </div>
      <div class="dropdown-opened" *ngIf="showLanguageDropdown">
        <div (click)="changeLanguage(0)">
          <img src="../../assets/Img/en-flag.png" alt="English flag" />
          <span>English</span>
        </div>
        <div (click)="changeLanguage(1)">
          <img src="../../assets/Img/fr-flag.png" alt="French flag" />
          <span>French</span>
        </div>
      </div>
    </div>
    <div class="icon">
      <div class="icon-badge">1</div>
      <img src="../../../assets/icons/bell.svg" alt="Bell icon">
    </div>
    <div class="user-wrapper" [routerLink]="['profile']">
      <span>{{loggedUser}}</span>
    </div>
  </div>
</header>
