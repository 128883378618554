export interface DtMeterType {
    id: number;
    type: string;
    dtMeterBrandId: string;
    utilityId: number;
}

export function getInitDtMeterType(): DtMeterType{
    return {
        id: 0,
        type: '',
        dtMeterBrandId: '',
        utilityId: 0
    }
}