<div class="login-page">
  <div class="page-wrap">
    <div class="logo">
      <img src="../../../assets/logo-md.svg" alt="CAIMS Logo">
    </div>
    <div class="form-wrapper">
      <h1>Password reset</h1>
      <p class="welcome-text">Restore your account access</p>
      <form ngNativeValidate (submit)="resetPasswrod()" *ngIf="showEmailForm">
        <div class="form-item form-floating mb-3">
          <input [(ngModel)]="email" name="email" type="email" class="form-control" id="floatingInput" placeholder=" " pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" required>
          <label for="floatingInput">Email address</label>
        </div>
        <button type="submit" class="btn btn-primary">Reset password</button>
      </form>

      <form ngNativeValidate (submit)="checkCode()" *ngIf="showCodeForm">
        <div class="form-item form-floating mb-3">
          <input [(ngModel)]="code" name="code" type="text" class="form-control" id="floatingInput" placeholder=" " required>
          <label for="floatingInput">Enter code</label>
        </div>
        <button type="submit" class="btn btn-primary">Submit</button>
      </form>

      <form ngNativeValidate (submit)="submitNewPw()" *ngIf="showPasswordForm">
        <div class="form-item form-floating">
          <input [(ngModel)]="pw" name="password" [type]="showPw ? 'text' : 'password'" class="form-control" id="floatingPassword" placeholder=" " required>
          <label for="floatingPassword">New password</label>
          <div class="show-pw" (click)="showPassword()">
            <ng-template [ngIf]="showPw">
              <img src="../../../assets/icons/eye.svg" alt="Show password icon">
            </ng-template>
            <ng-template [ngIf]="!showPw">
              <img src="../../../assets/icons/eye-slash.svg" alt="Show password icon">
            </ng-template>
          </div>
        </div>

        <div class="form-item form-floating">
          <input [(ngModel)]="confirmPw" name="password" [type]="showConfirmPw ? 'text' : 'password'" class="form-control" id="floatingPassword" placeholder=" " required>
          <label for="floatingPassword">Confirm password</label>
          <div class="show-pw" (click)="showConfirmPassword()">
            <ng-template [ngIf]="showConfirmPw">
              <img src="../../../assets/icons/eye.svg" alt="Show password icon">
            </ng-template>
            <ng-template [ngIf]="!showConfirmPw">
              <img src="../../../assets/icons/eye-slash.svg" alt="Show password icon">
            </ng-template>
          </div>
        </div>
        <button type="submit" class="btn btn-primary">Save Password</button>

      </form>

      <div class="help"><a class="link" [routerLink]="''">Back to Log in screen</a></div>
    </div>
  </div>

  
</div>


<div class="message-sent-wrapper" *ngIf="resetMessageSent" (click)="closeModal();">
  <div class="message-sent">
    <div class="close-btn" (click)="closeModal()"><div></div></div>
    <img src="../../../assets/animations/envelop-animation.gif" alt="Envelop animation">
    <h1>Reset message sent!</h1>
    <p>A password reset email has been sent to: <br/><strong>{{email}}</strong></p>
    <button class="btn btn-primary" (click)="closeModal()">Enter code</button>
    <div class="help"><a class="link" [routerLink]="''">Back to Log in screen</a></div>
  </div>
</div>
