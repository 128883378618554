<div class="login-container">
    <app-login-header></app-login-header>
    <div class="div-center">
      <div class="login-form col-md-3 col-12">
        <div class="login-title">Log in to your account</div>
        <div class="form-group">
          <label for="email">Email/Account number *</label>
          <input
            [(ngModel)]="email"
            type="text"
            id="email"
            name="email"
            placeholder="Enter Email or account number"
          />
        </div>
        <div class="form-group mt-3 position-relative">
          <span [routerLink]="['/new-connection/' + utility.name + '/reset-password-request']">Forgot password?</span>
          <label for="password">Password *</label>
          <input
            [(ngModel)]="password"
            type="password"
            id="password"
            name="password"
            placeholder="Enter password"
            [type]="showPw ? 'text' : 'password'"
  
          />
          <div class="show-pw" (click)="showPassword()">
              <ng-template [ngIf]="showPw">
                <img
                  src="../../../../assets/icons/eye.svg"
                  alt="Show password icon"
                />
              </ng-template>
              <ng-template [ngIf]="!showPw">
                <img
                  src="../../../../assets/icons/eye-slash.svg"
                  alt="Show password icon"
                />
              </ng-template>
            </div>
        </div>
        <button class="login-btn" (click)="login()">Login</button>
  
        <div class="no-account-container">
          <div class="no-account">Don`t have an account?</div>
          <div class="sign-up" [routerLink]="['/new-connection/' + utility.name + '/register']"> Sign up</div>
        </div>
      </div>
    </div>
  </div>
