<div class="edit-customer-root">
  <div class="edit-customers-header">
    <div class="user-header-info">
      <div class="user-info">
        <div class="name">
          {{
          customerHeaderInfo.tenantName
          ? customerHeaderInfo.tenantName
          : ("COMMON.NO_NAME" | translate)
          }}
        </div>
        <div class="slrn">
          {{ customer?.meterSlrn ? customer?.meterSlrn : ("COMMON.NO_SLRN" | translate) }}
        </div>
        <div class="row">
          <div class="tag-new-customer col-2" *ngIf="customer.buildingType">{{ 'COMMON.BUILDING_TYPE.' + customer.buildingType | uppercase | translate }}</div>
          <div class="col-2 customer-status complete-customer" *ngIf="
              this.customer.dataQualityId !== 0 &&
              customer.dataQualityStatus!.toString() == 'Approved' &&
              customer.assetStatus !== 'Assigned'
            ">
            {{'COMMON.ASSET_STATUS.COMPLETE' | translate}}
          </div>
          <div class="col-2 customer-status rejected-customer" *ngIf="
              this.customer.dataQualityId !== 0 &&
              customer.dataQualityStatus!.toString() == 'Rejected' &&
              customer.assetStatus !== 'Assigned'
            ">
            {{'COMMON.ASSET_STATUS.REJECTED' | translate}}
          </div>
          <div class="col-2 customer-status pending-customer" *ngIf="this.customer.dataQualityId === 0  &&
            customer.assetStatus !== 'Assigned'">
            {{'COMMON.ASSET_STATUS.PENDING' | translate}}
          </div>
        </div>
      </div>
      <div class="user-address">
        <div>{{ customerHeaderInfo?.email | hasValue }}</div>
        <div>{{ customerHeaderInfo?.phoneNumber | hasValue }}</div>
        <div>{{ customerHeaderInfo?.address | hasValue }}</div>
      </div>
    </div>
    <div class="action-buttons">
      <button class="btn btn-header" data-bs-toggle="modal"
        (click)="getMobileUsers(); setModalType(AssetAssignType.Retagged);" data-bs-target="#assignToFieldAgent"
        *ngIf="permissionsService.isMetersRetagAllowed">
        {{'COMMON.RETAG' | translate}}
      </button>
      <div class="recall-div" *ngIf="
          permissionsService.isCustomerRecallAssignedAllowed ||
          permissionsService.isCustomerRecallValidatedAllowed
        ">
        <button class="btn btn-header recall-button" style="color: black" (click)="showRecallMenu = !showRecallMenu">
          <span class="recall-span">{{'COMMON.RECALL' | translate}}</span>
        </button>
        <div *ngIf="showRecallMenu" class="recall-options">
          <div class="recall-type" [ngClass]="[
              !recallAssignedAllowed
                ? 'recall-type-disabled'
                : 'recall-type-enabled'
            ]" (click)="
              recallAssignedAllowed
                ? recallAssigned()
                : null
            ">
            <span>{{'COMMON.ASSIGNED_TASK' | translate}}</span>
          </div>
          <div class="recall-type" [ngClass]="[
              !recallValidatedAllowed
                ? 'recall-type-disabled'
                : 'recall-type-enabled'
            ]" (click)="
              recallValidatedAllowed
                ? recallValidation()
                : null
            ">
            <span>{{'COMMON.VALIDATED_TASK' | translate}}</span>
          </div>
        </div>
      </div>
      <button class="btn btn-header" data-bs-toggle="modal" data-bs-target="#updateStatus"
        [disabled]="this.customer.dataQualityId === 0" *ngIf="permissionsService.isCustomerApproveRejectAllowed">
        {{'COMMON.UPDATE_STATUS' | translate}}
      </button>
      <button class="btn btn-header" data-bs-toggle="modal"
        (click)="getMobileUsers(); setModalType(AssetAssignType.Assigned);" data-bs-target="#assignToFieldAgent"
        *ngIf="permissionsService.isCustomerAssignAllowed">
        {{'COMMON.ASSIGN_AGENT' | translate}}
      </button>
      <button class="btn btn-header" *ngIf="
          !editEnabled &&
          permissionsService.isCustomerCreateEditAllowed
        " (click)="edit()">
        {{'COMMON.EDIT' | translate}}
      </button>
      <button class="btn btn-primary" *ngIf="editEnabled" (click)="openEditModal()">
        {{'COMMON.SAVE' | translate}}
      </button>
    </div>
  </div>
  <div class="edit-customers-body">
    <div class="main-col">
      <div class="card container">
        <div class="row">
          <div class="col-5">{{'COMMON.MBC_DETAILS.BUILDING_SLRN' | translate}}</div>
          <div class="col-7">{{ customer.building.slrn | hasValue }}</div>
        </div>
        <div class="row">
          <div class="col-5">{{'COMMON.BU.SINGLE' | translate}}</div>
          <div class="col-7">{{ customer.area?.regionName | hasValue }}</div>
        </div>
        <div class="row">
          <div class="col-5">{{'COMMON.UT.SINGLE' | translate}}</div>
          <div class="col-7">{{ customer.area?.name | hasValue }}</div>
        </div>
        <div class="row">
          <div class="col-5">{{'COMMON.MBC_DETAILS.CUSTOMER_ADDRESS' | translate}}</div>
          <div *ngIf="!editEnabled" class="col-7">
            {{ customer.address | hasValue }}
          </div>
          <input *ngIf="editEnabled" class="col-7" [(ngModel)]="customer.address" />
        </div>
        <div class="row">
          <div class="col-5">{{'COMMON.MBC_DETAILS.PHYSICAL_ADDRESS' | translate}}</div>
          <div *ngIf="!editEnabled" class="col-7">
            {{ customer.physicalAddress | hasValue }}
          </div>
          <input *ngIf="editEnabled" class="col-7" [(ngModel)]="customer.physicalAddress" />
        </div>
        <div class="row">
          <div class="col-5">{{'COMMON.MBC_DETAILS.CLOSEST_LANDMARK' | translate}}</div>
          <div *ngIf="!editEnabled" class="col-7">
            {{ customer.building?.closestLandmark | hasValue }}
          </div>
          <input *ngIf="editEnabled" class="col-7" [(ngModel)]="customer.closestLandmark" />
        </div>
        <div class="row">
          <div class="col-5">{{'COMMON.MBC_DETAILS.ACCOUNT_NO' | translate}}</div>
          <div *ngIf="!editEnabled" class="col-7">
            {{ customer.accountNumber | hasValue }}
          </div>
          <input *ngIf="editEnabled" class="col-7" [(ngModel)]="customer.accountNumber" />
        </div>
        <div class="row">
          <div class="col-5">{{'COMMON.MBC_DETAILS.BUILDING_OWNER' | translate}}</div>

          <div *ngIf="!editEnabled" class="col-7">
            {{ customer.buildingOwnerName | hasValue }}
          </div>
          <input *ngIf="editEnabled" class="col-7" [(ngModel)]="customer.buildingOwnerName" />
        </div>
        <div class="row">
          <div class="col-5">{{'COMMON.MBC_DETAILS.CUSTOMER_NAME' | translate}}</div>
          <div *ngIf="!editEnabled" class="col-7">
            {{ customer.tenantName | hasValue }}
          </div>
          <input *ngIf="editEnabled" class="col-7" [(ngModel)]="customer.tenantName" />
        </div>
        <div class="row">
          <div class="col-5">{{'COMMON.MBC_DETAILS.BUILDING_STRUCTURE.NAME' | translate}}</div>
          <div *ngIf="!editEnabled" class="col-7">
            {{ customer.building.buildingStructure | hasValue }}
          </div>
          <input *ngIf="editEnabled" class="col-7" [(ngModel)]="customer.building.buildingStructure" />
        </div>
        <div class="row">
          <div class="col-5">{{'COMMON.MBC_DETAILS.BUILDING_DEVELOPMENT.NAME' | translate}}</div>
          <div *ngIf="!editEnabled" class="col-7">
            {{ customer.building.buildingDevelopmentStage | hasValue }}
          </div>
          <input *ngIf="editEnabled" class="col-7" [(ngModel)]="customer.building.buildingDevelopmentStage" />
        </div>
        <div class="row">
          <div class="col-4">{{'COMMON.MBC_DETAILS.FEEDER_NAME' | translate}}</div>

          <div *ngIf="!editEnabled" class="col-8">
            {{ customer.dt?.feeder?.name | hasValue }}
          </div>
          <div *ngIf="editEnabled" class="col-8">
            <select *ngIf="customer.dt.feeder.id !== 0" class="form-control" [(ngModel)]="customer.feederId"
              (change)="selectFeeder()">
              <option *ngFor="let option of f11Options" value="{{ option.id }}">
                {{ option.name }}
              </option>
            </select>
            <select *ngIf="customer.dt.feeder.id !== 0" class="form-control" [(ngModel)]="customer.feederId"
              (change)="selectFeeder()">
              <option *ngFor="let option of f33Options" value="{{ option.id }}">
                {{ option.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="row">
          <div class="col-5">{{'COMMON.MBC_DETAILS.FEEDER_NUMBER' | translate}}</div>
          <div class="col-7">{{ customer.dt?.feeder?.number | hasValue }}</div>
        </div>
        <div class="row">
          <div class="col-4">{{'COMMON.DT_NAME' | translate}}</div>
          <div *ngIf="!editEnabled" class="col-8">
            {{ customer.dt?.name | hasValue }}
          </div>
          <div *ngIf="editEnabled" class="col-8">
            <select class="form-control" [(ngModel)]="customer.dtId">
              <option hidden selected>{{'TRANSFORMERS.SELECT' | translate}}</option>
              <option *ngFor="let option of dtOptions" value="{{ option.key }}">
                {{ option.value }}
              </option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-5">{{'COMMON.DT_NUMBER' | translate}}</div>
          <div class="col-7">{{ customer.dt?.number | hasValue }}</div>
        </div>
        <div class="row">
          <div class="col-5">{{'COMMON.MBC_DETAILS.NUMBER_IN_HOUSE' | translate}}</div>
          <div *ngIf="!editEnabled" class="col-7">
            {{ customer?.numberOfHouseholds | hasValue }}
          </div>
          <input *ngIf="editEnabled" class="col-7" [(ngModel)]="customer.numberOfHouseholds" pattern="[0-9]+" />
        </div>
        <div class="row">
          <div class="col-5">{{'COMMON.MBC_DETAILS.TYPE_OF_CONNECTION' | translate}}</div>
          <div class="col-7">{{ customer?.typeOfConnection | hasValue }}</div>
        </div>
        <div class="row">
          <div class="col-5">{{'COMMON.USE_OF_PREMISES' | translate}}</div>
          <div class="col-7">{{mapUseOfPremise(customer?.buildingType)| hasValue }}</div>
        </div>
        <div class="row" *ngIf="customerType == 'Postpaid'">
          <div class="col-5">{{'COMMON.MBC_DETAILS.LAST_PAYMENT_AMOUNT' | translate}}</div>
          <div class="col-7">{{ customer?.lastPaymentAmount | hasValue }}</div>
        </div>
        <div class="row" *ngIf="customerType == 'Postpaid'">
          <div class="col-5">{{'COMMON.MBC_DETAILS.LAST_PAYMENT_DATE' | translate}}</div>
          <div class="col-7">{{ customer?.lastPaymentDate | date : "dd/MM/yyyy" | hasValue }}</div>
        </div>
        <div class="row" *ngIf="customerType == 'Postpaid'">
          <div class="col-5">{{'COMMON.MBC_DETAILS.CURRENT_DEBT' | translate}}</div>
          <div class="col-7">{{ customer?.currentDebtBalance | hasValue }}</div>
        </div>
      </div>
      <div *ngIf="customer.customerHistories.length > 0" class="history-card container">
        <h4 style="text-align: center">{{'COMMON.HISTORY' | translate}}</h4>

        <div *ngFor="let history of customer.customerHistories; let i = index">
          <div class="row history-item" *ngIf="history.values.length > 0">
            <div class="col-6 label-name">
              {{history.label}}
            </div>
            <div class="col-6">
              <div class="row" *ngFor="let item of history.values">
                {{item}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card container">
        <div class="row">
          <div class="col-5">{{'METERS.EDIT.ENERGY' | translate}} (KWH)</div>
          <div *ngIf="!editEnabled" class="col-7">
            {{ customer?.meter?.energy | hasValue }}
          </div>
          <input *ngIf="editEnabled" class="col-7" [(ngModel)]="customer.meter.energy" />
        </div>
        <div class="row">
          <div class="col-5">{{'METERS.EDIT.AVG_CURRENT' | translate}}</div>
          <div *ngIf="!editEnabled" class="col-7">
            {{ customer?.meter?.avgCurrent | hasValue }}
          </div>
          <input *ngIf="editEnabled" class="col-7" [(ngModel)]="customer.meter.avgCurrent" />
        </div>
        <div class="row">
          <div class="col-5">{{'METERS.EDIT.POWER' | translate}}</div>
          <div *ngIf="!editEnabled" class="col-7">
            {{ customer?.meter?.power | hasValue }}
          </div>
          <input *ngIf="editEnabled" class="col-7" [(ngModel)]="customer.meter.power" />
        </div>
        <div class="row">
          <div class="col-5">{{'METERS.EDIT.VOLTAGE' | translate}}</div>
          <div *ngIf="!editEnabled" class="col-7">
            {{ customer?.meter?.voltage | hasValue }}
          </div>
          <input *ngIf="editEnabled" class="col-7" [(ngModel)]="customer.meter.voltage" />
        </div>
        <div class="row">
          <div class="col-5">{{'METERS.EDIT.CREDIT_BALANCE' | translate}} (KWH)</div>
          <div *ngIf="!editEnabled" class="col-7">
            {{ customer?.meter?.creditBalance | hasValue }}
          </div>
          <input *ngIf="editEnabled" class="col-7" [(ngModel)]="customer.meter.creditBalance" />
        </div>
        <div class="row">
          <div class="col-5">{{'METERS.EDIT.CREDIT_BALANCE_TIME' | translate}}</div>
          <div *ngIf="!editEnabled" class="col-7">
            {{ customer?.meter?.creditBalanceTime | date : "M/d/Y HH:mm:ss" | hasValue }}
          </div>
          <input *ngIf="editEnabled" class="col-7" type="datetime-local"
            [(ngModel)]="customer.meter.creditBalanceTime" />
        </div>
        <div class="row">
          <div class="col-5">{{'METERS.EDIT.LAST_VP' | translate}} (KWH)</div>
          <div *ngIf="!editEnabled" class="col-7">
            {{ customer?.meter?.lastVendPaymentKWH | hasValue }}
          </div>
          <input *ngIf="editEnabled" class="col-7" [(ngModel)]="customer.meter.lastVendPaymentKWH" />
        </div>
        <div class="row">
          <div class="col-5">{{'METERS.EDIT.AVG_DAILY_AVAILABILITY' | translate}}</div>
          <div *ngIf="!editEnabled" class="col-7">
            {{ customer?.meter?.avgDailyAvailability | hasValue }}
          </div>
          <input *ngIf="editEnabled" class="col-7" [(ngModel)]="customer.meter.avgDailyAvailability" />
        </div>
      </div>
    </div>

    <div class="card container main-col">
      <div class="row">
        <div class="col-5">{{'COMMON.MBC_DETAILS.METER_TYPE.SINGLE' | translate}}</div>

        <div *ngIf="!editEnabled" class="col-7">
          {{ customer?.meter?.meterType?.name | hasValue }}
        </div>
        <div *ngIf="editEnabled" class="col-7">
          <select class="select-dropdown form-control" [(ngModel)]="customer.meterTypeId">
            <option *ngFor="let option of meterTypeOptions" [ngValue]="option.key">
              {{ option.value }}
            </option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-5">{{'COMMON.MBC_DETAILS.METER_NO' | translate}}</div>
        <div *ngIf="!editEnabled" class="col-7">
          {{ customer?.meter?.number | hasValue }}
        </div>
        <input *ngIf="editEnabled" class="col-7" [(ngModel)]="customer.meter.number" />
      </div>
      <div class="row">
        <div class="col-5">{{'COMMON.MBC_DETAILS.OLD_METER_NO' | translate}}</div>
        <div *ngIf="!editEnabled" class="col-7">
          {{ customer?.oldMeterNumber | hasValue }}
        </div>
      </div>
      <div class="row">
        <div class="col-5">{{'COMMON.CONTRACT_NUMBER' | translate}}</div>
        <div class="col-7">{{ customer?.contractNumber | hasValue }}</div>
      </div>

      <div class="row" *ngIf="isServiceTypeFieldVisible">
        <div class="col-5">{{'COMMON.SERVICE_TYPE.TITLE' | translate}}</div>
        <div class="col-7">{{ customer?.serviceType | hasValue }}</div>
      </div>
      <div class="row">
        <div class="col-5">{{'COMMON.MBC_DETAILS.CUSTOMER_TYPE' | translate}}</div>
        <div class="col-7">
          {{ customer?.customerType | hasValue }}
        </div>
      </div>

      <div class="row">
        <div class="col-5">{{'COMMON.SLT_STATUS.SINGLE' | translate}}</div>
        <div *ngIf="!editEnabled" class="col-7">
          {{ customer?.sltStatus?.value | hasValue }}
        </div>
        <div *ngIf="editEnabled" class="col-7">
          <select class="select-dropdown form-control" [(ngModel)]="selectedSltStatus.key">
            <option *ngFor="let option of sltOptions" [ngValue]="option.key">
              {{ option.value }}
            </option>
          </select>
        </div>
      </div>

      <div class="row">
        <div class="col-5">{{'COMMON.MBC_DETAILS.CLIENT_TYPE' | translate}}</div>
        <div class="col-7">{{ customer?.clientType | hasValue }}</div>
      </div>
      <div class="row">
        <div class="col-5">{{'COMMON.MBC_DETAILS.METER_STATUS' | translate}}</div>
        <div class="col-7">{{ customer?.meterStatus | hasValue }}</div>
      </div>
      <div class="row">
        <div class="col-5">{{'COMMON.MBC_DETAILS.METER_CONDITION' | translate}}</div>
        <div class="col-7">{{ customer?.meterCondition | hasValue }}</div>
      </div>
      <div *ngIf="isMeterSlrnFieldVisible" class="row">
        <div class="col-5">{{'COMMON.MBC_DETAILS.METER_SLRN' | translate}}</div>
        <div class="col-7">{{ customer?.meterSlrn | hasValue }}</div>
      </div>
      <div class="row">
        <div class="col-5">{{'COMMON.MBC_DETAILS.ID_CARD_NUM' | translate}}</div>
        <div class="col-7">{{ customer?.cardNumber | hasValue }}</div>
      </div>

      <div class="row">
        <div class="col-5">{{'COMMON.MBC_DETAILS.PHONE_NO' | translate}}</div>
        <div *ngIf="!editEnabled" class="col-7">
          {{ customer.phoneNumber | hasValue }}
        </div>
        <input *ngIf="editEnabled" class="col-7" [(ngModel)]="customer.phoneNumber" pattern="[0-9]+" />
      </div>
      <div class="row">
        <div class="col-5">{{'COMMON.MBC_DETAILS.EMAIL' | translate}}</div>
        <div *ngIf="!editEnabled" class="col-7">
          {{ customer?.email | hasValue }}
        </div>
        <input *ngIf="editEnabled" class="col-7" [(ngModel)]="customer.email" type="email" />
      </div>
      <div class="row">
        <div class="col-5">{{'METERS.EDIT.METER_CREATION_DATE' | translate}}</div>
        <div class="col-7">
          {{ customer.meter?.creationDate | date:'short' | hasValue }}
        </div>
      </div>
      <div class="row">
        <div class="col-5">{{'COMMON.MBC_DETAILS.ASSIGNED' | translate}}</div>
        <div class="col-7">
          {{ customer.meter?.assignedUser | hasValue }}
        </div>
      </div>
      <div class="row">
        <div class="col-5">{{'COMMON.MBC_DETAILS.LAST_UPDATED' | translate}}</div>
        <div class="col-7">
          {{ customer.meter?.lastUpdateUser | hasValue  }}
        </div>
      </div>
      <div class="row">
        <div class="col-5">{{'COMMON.USER.TYPE.FIRST_CAPTURED' | translate}}</div>
        <div class="col-7">
          {{ customer.meter?.firstCapturedUser | hasValue }}
        </div>
      </div>
      <div class="row">
        <div class="col-5">{{'COMMON.USER.TYPE.REASSIGNED' | translate}}</div>
        <div class="col-7">
          {{ customer.meter?.reassignedBy| hasValue }}
        </div>
      </div>
      <div class="row">
        <div class="col-5">{{'COMMON.MBC_DETAILS.UPDATED_DATE' | translate}}</div>
        <div class="col-7">{{ customer.meter?.lastUpdateTime | date:'short' | hasValue}}</div>
      </div>
      <div class="row">
        <div class="col-5">{{'COMMON.USER.TYPE.VALIDATED' | translate}}</div>
        <div class="col-7">{{ customer.validatedBy | hasValue }}</div>
      </div>
      <div class="row">
        <div class="col-5">{{'COMMON.DATE_TYPE.VALIDATED' | translate}}</div>
        <div class="col-7">{{ customer.validatedDate | hasValue }}</div>
      </div>
      <div class="row">
        <div class="col-5">{{'COMMON.MBC_DETAILS.AUDITED_STATUS' | translate}}</div>
        <div class="col-7">{{ customer.auditedStatus | hasValue }}</div>
      </div>
      <div class="row">
        <div class="col-5">{{'COMMON.USER.TYPE.AUDITED' | translate}}</div>
        <div class="col-7">{{ customer.auditedBy | hasValue }}</div>
      </div>
      <div class="row">
        <div class="col-5">{{'COMMON.MBC_DETAILS.AUDITED_DATE' | translate}}</div>
        <div class="col-7">{{ customer.auditedDate | hasValue }}</div>
      </div>
      <div class="row">
        <div class="col-5">{{'COMMON.STATUS' | translate}}</div>
        <div class="col-7">{{ customer.building?.status | hasValue }}</div>
      </div>
      <div class="row">
        <div class="col-5">{{'METERS.EDIT.LAST_VP' | translate}} (NGN)</div>
        <div *ngIf="!editEnabled" class="col-7">
          {{ customer?.meter?.lastVendPaymentNGN | hasValue }}
        </div>
        <input *ngIf="editEnabled" class="col-7" [(ngModel)]="customer.meter.lastVendPaymentNGN" />
      </div>
      <div class="row">
        <div class="col-5">{{'METERS.EDIT.LAST_VP_TIME' | translate}}</div>
        <div *ngIf="!editEnabled" class="col-7">
          {{ customer?.meter?.lastVendPaymentTime | date : "M/d/Y HH:mm:ss" | hasValue }}
        </div>
        <input *ngIf="editEnabled" class="col-7" type="datetime-local"
          [(ngModel)]="customer.meter.lastVendPaymentTime" />
      </div>
      <div class="row">
        <div class="col-5">{{'COMMON.POST_CODE' | translate}}</div>
        <div class="col-7">{{ customer.ghanaPostGps | hasValue }}</div>
      </div>
      <div class="row" *ngIf="customer.postCodeException">
        <div class="col-5">{{'COMMON.POST_CODE_EXC' | translate}}</div>
        <div class="col-7">{{ customer.postCodeException | hasValue }}</div>
      </div>
      <div class="row">
        <div class="col-5">{{'COMMON.MBC_DETAILS.GEO_LOCATION' | translate}}</div>
        <div class="locationText col-6">
          {{ customer.building.latitude }}, {{ customer.building.longitude }}
        </div>
        <div class="location col-1" (click)="isMapVisible = !isMapVisible">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" data-bs-toggle="modal"
            data-bs-target="#showMap">
            <path
              d="M256 0c17.7 0 32 14.3 32 32V66.7C368.4 80.1 431.9 143.6 445.3 224H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H445.3C431.9 368.4 368.4 431.9 288 445.3V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V445.3C143.6 431.9 80.1 368.4 66.7 288H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H66.7C80.1 143.6 143.6 80.1 224 66.7V32c0-17.7 14.3-32 32-32zM128 256c0 70.7 57.3 128 128 128s128-57.3 128-128s-57.3-128-128-128s-128 57.3-128 128zm128 80c-44.2 0-80-35.8-80-80s35.8-80 80-80s80 35.8 80 80s-35.8 80-80 80z"
              fill="#026962" />
          </svg>
        </div>
      </div>
      <div class="row">
        <div class="col-5">{{'COMMON.MBC_DETAILS.REMARKS' | translate}}</div>
        <div *ngIf="!editEnabled" class="col-7">
          {{ customer.remarksAndRecommendations | hasValue }}
        </div>
      </div>
    </div>
    <div class="image-container main-col">
      <div class="card container">
        <div>{{'COMMON.PICTURES.FRONT' | translate}}</div>
        <div class="images-list">
          <div *ngIf="getFrontViewImage(0)">
            <img src="{{ getFrontViewImage(0) }}" (click)="showImage(0)" data-bs-toggle="modal"
              data-bs-target="#modalImage" alt="Front image" />
          </div>
          <div *ngIf="!getFrontViewImage(0)">
            <input [disabled]="
                !permissionsService.isCustomerCreateEditAllowed
              " hidden id="fileFw1" type="file" (change)="fileBrowserHandler($event, ImageType.Frontview)" />
            <label for="fileFw1" [ngClass]="{
                'disabled-label': !permissionsService.isCustomerCreateEditAllowed
              }">
              <img src="../../../../assets/missing_image.png" alt="Missing image"/>
            </label>
          </div>
          <div *ngIf="getFrontViewImage(1)">
            <img src="{{ getFrontViewImage(1) }}" (click)="showImage(1)" data-bs-toggle="modal"
              data-bs-target="#modalImage" alt="Front image" />
          </div>
          <div *ngIf="!getFrontViewImage(1)">
            <input [disabled]="
                !permissionsService.isCustomerCreateEditAllowed
              " hidden id="fileFw2" type="file" (change)="fileBrowserHandler($event, ImageType.Frontview)" />
            <label for="fileFw2" [ngClass]="{
                'disabled-label': !permissionsService.isCustomerCreateEditAllowed
              }">
              <img src="../../../../assets/missing_image.png" alt="Missing image"/>
            </label>
          </div>
          <div *ngIf="getFrontViewImage(2)">
            <img src="{{ getFrontViewImage(2) }}" (click)="showImage(2)" data-bs-toggle="modal"
              data-bs-target="#modalImage" alt="Front image" />
          </div>
          <div *ngIf="!getFrontViewImage(2)">
            <input [disabled]="
                !permissionsService.isCustomerCreateEditAllowed
              " hidden id="fileFw3" type="file" (change)="fileBrowserHandler($event, ImageType.Frontview)" />
            <label for="fileFw3" [ngClass]="{
                'disabled-label': !permissionsService.isCustomerCreateEditAllowed
              }">
              <img src="../../../../assets/missing_image.png" alt="Missing image"/>
            </label>
          </div>
        </div>
      </div>
      <div class="card container">
        <div>{{'COMMON.PICTURES.MOUNTED_TAG' | translate}}</div>
        <div>
          <div *ngIf="getBuildingMountedImage()">
            <img src="{{ getBuildingMountedImage() }}" (click)="showImage(3)" data-bs-toggle="modal"
              data-bs-target="#modalImage" alt="Building image" />
          </div>
          <div *ngIf="!getBuildingMountedImage()">
            <input [disabled]="
                !permissionsService.isCustomerCreateEditAllowed
              " hidden id="fileMounted" type="file" (change)="fileBrowserHandler($event, ImageType.MountedTag)" />
            <label for="fileMounted" [ngClass]="{
                'disabled-label': !permissionsService.isCustomerCreateEditAllowed
              }">
              <img src="../../../../assets/missing_image.png" alt="Missing image"/>
            </label>
          </div>
        </div>
      </div>
      <div class="card container">
        <div class="title-list">
          <div>{{'COMMON.PICTURES.BILL' | translate}}</div>
          <div>{{'COMMON.PICTURES.ID_CARD' | translate}}</div>
          <div>{{'COMMON.PICTURES.METER_SLRN' | translate}}</div>
        </div>
        <div class="images-list">
          <div *ngIf="customer.billImageUrl">
            <img src="{{ customer.billImageUrl }}" (click)="showImage(4)" data-bs-toggle="modal"
              data-bs-target="#modalImage" alt="Item image" />
          </div>
          <div *ngIf="!customer.billImageUrl">
            <input [disabled]="
                !permissionsService.isCustomerCreateEditAllowed
              " hidden id="fileBill" type="file" (change)="fileBrowserHandler($event, ImageType.Bill)" />
            <label for="fileBill" [ngClass]="{
                'disabled-label': !permissionsService.isCustomerCreateEditAllowed
              }">
              <img src="../../../../assets/missing_image.png" alt="Missing image"/>
            </label>
          </div>

          <div *ngIf="customer.cardImageUrl">
            <img src="{{ customer.cardImageUrl }}" (click)="showImage(5)" data-bs-toggle="modal"
              data-bs-target="#modalImage" alt="Item image" />
          </div>
          <div *ngIf="!customer.cardImageUrl">
            <input [disabled]="
                !permissionsService.isCustomerCreateEditAllowed
              " hidden id="filecard" type="file" (change)="fileBrowserHandler($event, ImageType.Card)" />
            <label for="filecard" [ngClass]="{
                'disabled-label': !permissionsService.isCustomerCreateEditAllowed
              }">
              <img src="../../../../assets/missing_image.png" alt="Missing image"/>
            </label>
          </div>

          <div *ngIf="customer.meterImageUrl">
            <img src="{{ customer.meterImageUrl }}" (click)="showImage(6)" data-bs-toggle="modal"
              data-bs-target="#modalImage" alt="Item image" />
          </div>
          <div *ngIf="!customer.meterImageUrl">
            <input [disabled]="
                !permissionsService.isCustomerCreateEditAllowed
              " hidden id="fileMeter" type="file" (change)="fileBrowserHandler($event, ImageType.Meter)" />
            <label for="fileMeter" [ngClass]="{
                'disabled-label': !permissionsService.isCustomerCreateEditAllowed
              }">
              <img src="../../../../assets/missing_image.png" alt="Missing image"/>
            </label>
          </div>
        </div>
        <div class="title-list">
          <div class="title-list-margin">{{'COMMON.PICTURES.METER_NUMBER' | translate}}</div>
          <div style="margin-right: 17rem; font-weight: 800">
            {{'COMMON.PICTURES.CARD_BACK' | translate}}
          </div>
        </div>
        <div class="images-list">
          <div *ngIf="customer.meterNumberImageUrl" style="margin-right: 3rem">
            <img src="{{ customer.meterNumberImageUrl }}" (click)="showImage(7)" data-bs-toggle="modal"
              data-bs-target="#modalImage" alt="Item image" />
          </div>
          <div *ngIf="!customer.meterNumberImageUrl" style="margin-right: 3rem">
            <input [disabled]="
                !permissionsService.isCustomerCreateEditAllowed
              " hidden id="fileMeterNumber" type="file" (change)="fileBrowserHandler($event, ImageType.MeterNumber)" />
            <label for="fileMeterNumber" [ngClass]="{
                'disabled-label': !permissionsService.isCustomerCreateEditAllowed
              }">
              <img src="../../../../assets/missing_image.png" alt="Missing image"/>
            </label>
          </div>
          <div *ngIf="customer.backCardViewImageUrl">
            <img src="{{ customer.backCardViewImageUrl }}" (click)="showImage(8)" data-bs-toggle="modal"
              data-bs-target="#modalImage" alt="Item image"/>
          </div>
          <div *ngIf="!customer.backCardViewImageUrl">
            <input [disabled]="!permissionsService.isCustomerCreateEditAllowed" hidden id="fileCardBackView" type="file"
              (change)="fileBrowserHandler($event, ImageType.CardBackViewImage)" />
            <label for="fileCardBackView" [ngClass]="{
                'disabled-label':
                  !permissionsService.isCustomerCreateEditAllowed
              }">
              <img src="../../../../assets/missing_image.png" alt="Missing image"/>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="assignToFieldAgent" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 *ngIf="assetAssignType == AssetAssignType.Assigned" class="modal-title">{{'COMMON.ASSIGN_AGENT' | translate}}</h5>
        <h5 *ngIf="assetAssignType == AssetAssignType.Retagged" class="modal-title">{{'COMMON.RETAG_TASK' | translate}}</h5>
        <button type="button" #closeModal class="btn-cancel-modal" data-bs-dismiss="modal" aria-label="Close">
          {{'COMMON.CANCEL' | translate}}
        </button>
      </div>
      <div class="modal-body">
        <div class="form-floating form-item">
          <input #userFilterInput autocomplete="off" type="text" name="filterText" [(ngModel)]="filterText"
            class="form-control filter-field" [ngClass]="{ 'valid-input': tempAssignedUserId != 0 }" id="floatingInput"
            (ngModelChange)="filterMobileUsers()" (click)="openDropdown()" placeholder=" " />
          <label for="floatingInput">{{'COMMON.FIELD_AGENT' | translate}}</label>

          <div class="filtered-agent-list" *ngIf="isDropdownOpened" #dropdownContainer>
            <div click-stop-propagation class="agent-name" (click)="selectFieldAgent(u.id)"
              *ngFor="let u of mobileUsers">
              {{ u.firstName }} {{ u.lastName }}
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="assignTask()">
          <span *ngIf="assetAssignType == AssetAssignType.Assigned">{{'COMMON.ASSIGN_TASK' | translate}}</span>
          <span *ngIf="assetAssignType == AssetAssignType.Retagged">{{'COMMON.RETAG_TASK' | translate}}</span>
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="modalImage" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content modal-image">
      <div class="modal-body modal-body-image">
        <img src="{{ selectedImage }}" alt="Selected image"/>
      </div>
    </div>
  </div>
</div>

<div class="modal modalMap" id="showMap" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" #closeAddModal class="close" data-bs-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <app-building-map [setMapVisibility]="isMapVisible" [longitude]="longitude"
          [latitude]="latitude"></app-building-map>
      </div>
    </div>
  </div>
</div>

<div class="modal" id="updateStatus" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{'COMMON.DATA_VALID_REVIEW' | translate}}</h5>
        <button type="button" #closeDQModal class="btn-cancel-modal" data-bs-dismiss="modal" aria-label="Close">
          {{'COMMON.CANCEL' | translate}}
        </button>
      </div>
      <div class="modal-body">
        <app-data-quality (closeModal)="cancelModal($event)" assetType="Customer" [assetId]="customerId"
          [areaId]="customer.area.id"></app-data-quality>
      </div>
    </div>
  </div>
</div>
<button class="btn btn-primary" #openUpdateDescriptionModal data-bs-toggle="modal" data-bs-target="#history"
  hidden></button>
<app-history (update)="updateHistory($event)"></app-history>