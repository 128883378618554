<div class="container-fluid customers-root">
  <div class="table-root">
    <div class="table-action-wrapper d-flex align-items-center justify-content-between">
      <div class="search-input">
        <img src="../../assets/icons/search.svg" alt="Search icon" (click)="search()">
        <input type="text" placeholder="Search" [(ngModel)]="searchText" (keydown.enter)="search()"
          (ngModelChange)="this.searchTextUpdate.next($event)">
      </div>
      <div class="ml-auto d-flex">
        <div>
          <div class="d-flex">
            <div class="datepicker-container">
              <label for="dateFrom"> From: </label>
              <input
                type="date"
                id="dateFrom"
                name="dateFrom"
                [ngClass]="
                  dateFrom > dateTo
                    ? 'btn datepicker-icon-error'
                    : 'btn datepicker-icon'
                "
                [(ngModel)]="dateFrom"
                (change)="datesValid() == true && search()"
              />
            </div>

            <div class="datepicker-container">
              <label for="dateTo" class="right-label"> To: </label>
              <input
                type="date"
                id="dateTo"
                name="dateTo"
                [ngClass]="
                  dateFrom > dateTo
                    ? 'btn datepicker-icon-error'
                    : 'btn datepicker-icon'
                "
                [(ngModel)]="dateTo"
                (change)="datesValid() == true && search()"
              />
            </div>
          </div>
        </div>
        <div class="sort-by-root" (click)="showSortBy=!showSortBy;showUtilityDropdown = false;" click-stop-propagation>
          <div class="dropdown">
            <span>Filter</span>
            <span class="filter-status"><img src="../../assets/icons/gray-arrow-down.svg" alt="Gray arrow down"></span>
          </div>
          <div class="dropdown-opened" *ngIf="showSortBy">
            <div (click)="selectFeederVoltage(1)">Completed</div>
            <div (click)="selectFeederVoltage(2)">Pending</div>
          </div>
        </div>
        <div class="sort-by-root" (click)="showUtilityDropdown=!showUtilityDropdown;showSortBy = false;" click-stop-propagation>
          <div class="dropdown">
            <span>Utilities</span>
            <span><img src="../../assets/icons/gray-arrow-down.svg" alt="Gray arrow down"></span>
          </div>
          <div class="dropdown-utilities-opened dropdown-opened" *ngIf="showUtilityDropdown">
            <div (click)="selectUtility(utility.key)" *ngFor="let utility of utilityList">{{utility.value}}</div>
          </div>
        </div>
        <button class="btn btn-icon" (click)="download()">
          <span>Download</span>
        </button>
      </div>
    </div>
    <div class="table">
      <div *ngIf="jobs">
        <table class="table border-bottom">
          <thead class="table-light">
            <tr>
              <th>Utility</th>
              <th>Process Name</th>
              <th>User Name</th>
              <th>Processed Images</th>
              <th>Total Images</th>
              <th>First Execution</th>
              <th>Last Execution</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let j of jobs; let i=index;">
              <td>{{j.utilityName | hasValue}}</td>
              <td>{{j?.processName | hasValue}}</td>
              <td>{{j.userName | hasValue}}</td>
              <td>{{j.current | number | hasValue}}</td>
              <td>{{j.length | number | hasValue}}</td>
              <td>{{j.firstExecution | date:'d MMM Y, hh:mm a'}}</td>
              <td>{{j.lastUpdateTime | date:'d MMM Y, hh:mm a'}}</td>
              <td>
                <div class="job-status" [ngClass]="{'complete-job' : j.status === 'Completed',
                        'pending-job': j.status === 'Pending'}">
                  {{j.status}}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div>
          <app-paging *ngIf="count > 0" [pageSize]="pageSize" [count]="count" [currentPage]="currentPage"
            (pageChange)="pageChange($event)"></app-paging>
        </div>
      </div>
    </div>
  </div>
</div>