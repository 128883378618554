import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-building-map',
  templateUrl: './building-map.component.html',
  styleUrls: ['./building-map.component.scss']
})
export class BuildingMapComponent implements OnDestroy {

  @Input() longitude: any;
  @Input() latitude: any;

  @ViewChild('streetviewSmall') streetviewSmall: any;
  pegmanOption: Element | null;
  isStreetViewLayerVisible: boolean = false;
  isStreetViewVisible: boolean = false;
  streetViewLayer: google.maps.StreetViewCoverageLayer;
  panorama!: google.maps.StreetViewPanorama;
  public agmMap: google.maps.Map | null = null;

  @Input() set setMapVisibility(isMapVisible: boolean) {
    // console.log('setMapVisibility', isMapVisible);
    this.removeEventListeners();
    this.setPegmanClickListener();
    this.showSmallStreetView(this.latitude, this.longitude);
  }
  constructor() { }

  ngOnDestroy(): void {
    this.removeEventListeners();
  }

  mapReady(map: any) {
    this.agmMap = map;
    this.streetViewLayer = new google.maps.StreetViewCoverageLayer();
  }

  /**
   * It will show a street view container in the bottom right of the map
   * @param lat Location latitude
   * @param lng Location longitude
   */
    showSmallStreetView(lat: number, lng: number) {
        this.isStreetViewVisible = true;
        if (!this.streetviewSmall) {
        return;
        }

        let streetViewPosition: google.maps.LatLngLiteral = { lat: lat, lng: lng };

        try {
        this.panorama = new google.maps.StreetViewPanorama(this.streetviewSmall.nativeElement, {
            position: streetViewPosition,
            scrollwheel: true,
            zoom: 1,
            fullscreenControl: true,
            showRoadLabels: true,
            visible: true,
            addressControl: false,
            panControl: true
        });

        this.agmMap?.setStreetView(this.panorama);
        } catch (error) {
            console.error('Error initializing Street View Panorama:', error);
        }
    }
    
    /**
     * It will listen for click events on the "pegman" option on the map and
     * display/hide street view layer (blue line showing where street view is available)
     * @param event An event which takes place in the DOM
     */
    streetViewToggleListener: EventListener = (event: Event) => {
        this.isStreetViewLayerVisible = !this.isStreetViewLayerVisible;
        // console.log('PEGMAN CLICKED');
    
        if (this.isStreetViewLayerVisible) {
            this.streetViewLayer.setMap(this.agmMap);
        } else {
            this.streetViewLayer.setMap(null);
        }
    }

    removeEventListeners() {
        if (this.pegmanOption) {
            this.pegmanOption?.removeEventListener('click', this.streetViewToggleListener);
        }
    }

    setPegmanClickListener() {
        try {
          setTimeout(() => {
            this.pegmanOption = window.document.querySelector('button.gm-svpc');
    
            if (this.pegmanOption) {
              this.pegmanOption.addEventListener('click', this.streetViewToggleListener);
            }
          }, 1200);
        } catch (error) {
          console.error('Error setting Pegman click listener:', error);
        }
      }
}
