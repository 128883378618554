import { AnalyticsService } from './../services/analytics.service';
import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { CustomerService } from '../services/customer.service';
import {
  NextDataQuality,
} from '../models/dataQuality.model';
import { ToastrService } from 'ngx-toastr';
import { BuildingService } from '../services/building.service';
import { PoleService } from '../services/pole.service';
import { DtService } from '../services/dt.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-audit-review',
  templateUrl: './audit-review.component.html',
  styleUrls: ['./audit-review.component.scss'],
})
export class AuditReviewComponent implements OnInit {
  @Input() assetType: string;
  @Input() assetId: number;
  @Input() areaId: number;
  @Input() fieldAuditDisabled: boolean = false;
  @Output() closeModal = new EventEmitter<NextDataQuality | null>();

  constructor(
    private customerService: CustomerService,
    private buildingService: BuildingService,
    private toastr: ToastrService,
    private poleService: PoleService,
    private dtService: DtService,
    private router: Router,
    private analyticsService: AnalyticsService
  ) {}

  ngOnInit(): void {}

  submitAudit() {
    switch (this.assetType) {
      case 'Customer': {
        this.analyticsService.logCustomEvent('CUSTOMER DETAILS: Field Audit', {
          customer_id: this.assetId,
        });
        this.customerService.auditCustomer(this.assetId).subscribe({
          next: (response) => {
            const responseData = response.body;
            if (responseData?.status === 200 || responseData?.status === 'OK') {
              this.toastr.success('Successfully audited customer.');
              this.closeModal.emit(responseData.data);
            } else {
              this.toastr.error(responseData?.message);
            }
          },
          error: (_) => {
            this.toastr.error('Error occured.');
          },
        });
        break;
      }
      case 'Building': {
        this.analyticsService.logCustomEvent('BUILDINGS DETAILS: Field Audit', {
          customer_id: this.assetId,
        });
        this.buildingService.auditBuilding(this.assetId).subscribe({
          next: (response) => {
            const responseData = response.body;
            if (responseData?.status === 200 || responseData?.status === 'OK') {
              this.toastr.success('Successfully audited building.');
              this.closeModal.emit(responseData.data);
              this.handleReturn();
            } else {
              this.toastr.error(responseData?.message);
            }
          },
          error: (_) => {
            this.toastr.error('Error occured.');
          },
        });
        break;
      }
      case 'Pole': {
        this.analyticsService.logCustomEvent('POLES DETAILS: Field Audit', {
          customer_id: this.assetId,
        });
        this.poleService.auditPole(this.assetId).subscribe({
          next: (response) => {
            const responseData = response.body;
            if (responseData?.status === 200 || responseData?.status === 'OK') {
              this.toastr.success('Successfully audited pole.');
              setTimeout(() => {
                window.location.reload();
              }, 500);
            } else {
              this.toastr.error(responseData?.message);
            }
          },
          error: (_) => {
            this.toastr.error('Error occured.');
          },
        });
        break;
      }
      case 'Dt': {
        this.analyticsService.logCustomEvent('DT DETAILS: Field Audit', {
          customer_id: this.assetId,
        });
        this.dtService.auditDt(this.assetId).subscribe({
          next: (response) => {
            const responseData = response.body;
            if (responseData?.status === 200 || responseData?.status === 'OK') {
              this.toastr.success('Successfully audited transformer.');
              setTimeout(() => {
                window.location.reload();
              }, 500);
            } else {
              this.toastr.error(responseData?.message);
            }
          },
          error: (_) => {
            this.toastr.error('Error occured.');
          },
        });
        break;
      }
      default: {
        break;
      }
    }
  }

  cancel(event: Event) {
    this.closeModal.emit(null);
  }

  handleReturn() {
    var previous = localStorage.getItem('cameFrom');
    if (!previous) {
      setTimeout(() => {
        window.location.reload();
      }, 500);
      return;
    }

    if (previous == 'Customer') {
      this.router.navigate(['/customers']);
    } else if (previous == 'Building') {
      this.router.navigate(['/buildings']);
    }
  }
}
