import {
  Component,
  ElementRef,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import {
  AreaCrud,
  getInitAreaCrud,
  mapObjToCreateObj,
} from 'src/app/models/area.model';
import { PermissionsEnum } from 'src/app/models/role.model';
import { BPSAdminLevel } from 'src/app/models/user.model';
import { AreaService } from 'src/app/services/area.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { RegionService } from 'src/app/services/region.service';
import { TranslationUtils } from 'src/assets/i18n/translation.utils';

interface OptionObj {
  key: number;
  value: string;
}
@Component({
  selector: 'crud-area',
  templateUrl: './crud-area.component.html',
  styleUrls: ['./crud-area.component.scss'],
})
export class CrudAreaComponent implements OnInit {
  public PermissionsEnum = PermissionsEnum;
  @Input() searchText!: string;
  @Input() utilityId: number = 0;
  @Input() bpsAdminLevel!: BPSAdminLevel;

  public currentPage: number = 1;
  public pageSize: number = 5;
  public count: number = 0;

  areas: AreaCrud[] = [];
  regionOptions: OptionObj[] = [];

  selectedRegionId: number = 0;

  selectedArea: AreaCrud = getInitAreaCrud();
  actionLabel$!: Observable<string>;
  isEditMode: boolean = false;

  @ViewChild('closeAddModal') closeAddModal: ElementRef;
  @ViewChild('closeDeleteModal') closeDeleteModal: ElementRef;

  constructor(
    private areaService: AreaService,
    private toastr: ToastrService,
    private regionService: RegionService,
    public permissionsService: PermissionsService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    if (this.utilityId != 0) {
      this.reloadTable();
    }

    this.updateActionLabel();
  }

  private updateActionLabel(): void {
    const key = this.isEditMode ? TranslationUtils.EDIT_KEY : TranslationUtils.NEW_KEY;
    this.actionLabel$ = this.translateService.stream(key);
  }

  private getRegions() {
    this.regionService.getRegionsForOptions(this.utilityId).subscribe({
      next: (response) => {
        const responseData = response.data;
        console.log(response);
        if (response?.status === 200 || response?.status === 'OK') {
          this.regionOptions = responseData;
        } else {
          this.toastr.error(response.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  addNew() {
    this.isEditMode = false;
    this.selectedArea = getInitAreaCrud();
    this.selectedRegionId = 0;
    this.updateActionLabel();
  }

  selectArea(area: AreaCrud) {
    this.isEditMode = true;
    this.selectedArea = { ...area };
    this.selectedRegionId = this.selectedArea.region.id;
    this.updateActionLabel();
  }

  pageChange(value: any) {
    this.currentPage = value;
    this.reloadTable(this.searchText);
  }

  reloadTable(searchText?: string, page: any = null) {
    if (page) {
      this.currentPage = page;
    }

    let dataIn = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize,
      },
      filterParams: {
        searchValue: searchText,
      },
      utilityId: this.utilityId,
    };
    this.getAllAreas(dataIn);
  }

  filter() {
    this.currentPage = 1;
    let dataIn = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize,
      },
      filterParams: {
        SearchValue: this.searchText,
      },
      utilityId: this.utilityId,
    };
    this.getAllAreas(dataIn);
  }

  private getAllAreas(dataIn: any) {
    this.areaService.getAll(dataIn).subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.areas = responseData.data.data;
          this.count = responseData.data.count;
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  save() {
    this.selectedArea.utilityId = this.utilityId;
    this.areaService.save(mapObjToCreateObj(this.selectedArea)).subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.closeAddModal?.nativeElement?.click();
          this.toastr.success(responseData?.message);
          if (this.selectedArea.id === 0) {
            this.pageChange(1);
            return;
          }
          this.reloadTable(this.searchText);
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  deleteArea() {
    this.areaService.delete(this.selectedArea.id).subscribe({
      next: (response) => {
        const responseData = response.body;
        this.closeDeleteModal?.nativeElement?.click();
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.pageChange(1);
          this.toastr.success(responseData?.message);
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  selectRegion(e: any) {
    this.selectedRegionId = +e.target.value;
    this.selectedArea.region.id = this.selectedRegionId;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['utilityId']) {
      if (this.utilityId != 0) {
        this.getRegions();
      }
    }
  }
}
