<div class="edit-customer-root">
  <div class="edit-customers-header">
    <div class="user-header-info">
      <div class="user-info">
        <div class="name">
          {{
          customerHeaderInfo.tenantName
          ? customerHeaderInfo.tenantName
          : "No Name"
          }}
        </div>
        <div class="slrn">
          {{ customerHeaderInfo.slrn ? customerHeaderInfo.slrn : "No SLRN" }}
        </div>
        <div class="row">
          <div class="tag-new-customer col-2">{{ customer.buildingType }}</div>
          <div class="col-2 customer-status complete-customer" *ngIf="
              disableUpdateStatusButton() &&
              customer.dataQualityStatus!.toString() == 'Approved' &&
              customer.assetStatus !== 'Assigned'
            ">
            Complete
          </div>
          <div class="col-2 customer-status rejected-customer" *ngIf="
              disableUpdateStatusButton() &&
              customer.dataQualityStatus!.toString() == 'Rejected' &&
              customer.assetStatus !== 'Assigned'
            ">
            Rejected
          </div>
          <div class="col-2 customer-status pending-customer" *ngIf="
              !disableUpdateStatusButton() &&
              customer.assetStatus !== 'Assigned'
            ">
            Pending
          </div>
          <div class="col-2 customer-status assigned-customer" *ngIf="customer.assetStatus === 'Assigned'">
            Assigned
          </div>
        </div>
      </div>
      <div class="user-address">
        <div>{{ customerHeaderInfo?.email | hasValue }}</div>
        <div>{{ customerHeaderInfo?.phoneNumber | hasValue }}</div>
        <div>{{ customerHeaderInfo?.address | hasValue }}</div>
      </div>
    </div>
    <div class="action-buttons">
      <button class="btn btn-header" data-bs-toggle="modal"
        (click)="setModalType(AssetAssignType.Retagged);" data-bs-target="#assignToFieldAgent"
        *ngIf="permissionsService.isCustomerRetagAllowed">
        Retag
      </button>
      <div>
        <button class="btn btn-header" style="color: black" (click)="showSendKyc = !showSendKyc">
          <span class="send-kyc-span">Send new connection</span>
          <svg *ngIf="!showSendKyc" width="16" height="10" viewBox="0 0 16 10" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M2.34292 0.757812L0.928711 2.17202L7.9998 9.24307L15.0708 2.17205L13.6566 0.757842L7.9998 6.41467L2.34292 0.757812Z"
              fill="black" />
          </svg>
          <svg *ngIf="showSendKyc" width="16" height="10" viewBox="0 0 16 10" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M2.34292 9.24219L0.928711 7.82798L7.9998 0.756927L15.0708 7.82799L13.6566 9.2422L7.9998 3.58537L2.34292 9.24219Z"
              fill="black" />
          </svg>
        </button>
        <div *ngIf="showSendKyc" class="notification-options">
          <div class="notification-type" (click)="sendKycMail()">
            <span class="">via Email</span>
          </div>
          <div class="notification-type">
            <span>via Text</span>
          </div>
        </div>
      </div>
      
      <button class="btn btn-header" data-bs-toggle="modal" data-bs-target="#updateStatus"
        *ngIf="permissionsService.isCustomerApproveRejectAllowed" [disabled]="disableUpdateStatusButton()">
        Update status
      </button>
      <button class="btn btn-header" data-bs-toggle="modal" 
      (click)="setModalType(AssetAssignType.Assigned);" data-bs-target="#assignToFieldAgent">
        Assign to field agent
      </button>
      <button class="btn btn-header" *ngIf="
          !editEnabled &&
          permissionsService.isCustomerCreateEditAllowed
        " (click)="edit()">
        Edit
      </button>
      <button class="btn btn-primary" *ngIf="editEnabled" (click)="openEditModal()">
        Save
      </button>
    </div>
  </div>
  <div class="edit-customers-body">
    <div class="card container main-col">
      <div class="row">
        <div class="col-5">Slrn</div>
        <div class="col-7">{{ customer.building.slrn | hasValue }}</div>
      </div>
      <div class="row">
        <div class="col-5">Closest landmark</div>
        <div *ngIf="!editEnabled" class="col-7">
          {{ customer.building?.closestLandmark | hasValue }}
        </div>
        <input *ngIf="editEnabled" class="col-7" [(ngModel)]="customer.building.closestLandmark" />
      </div>

      <div class="row">
        <div class="col-5">{{ buName }}</div>
        <div class="col-7">{{ customer.area?.regionName | hasValue }}</div>
      </div>
      <div class="row">
        <div class="col-5">{{ utName }}</div>
        <div class="col-7">{{ customer.area?.name | hasValue }}</div>
      </div>
      <div class="row">
        <div class="col-5">Customer address</div>
        <div *ngIf="!editEnabled" class="col-7">
          {{ customer.address | hasValue }}
        </div>
        <input *ngIf="editEnabled" class="col-7" [(ngModel)]="customer.address" />
      </div>
      <div class="row">
        <div class="col-5">Geolocation</div>
        <div class="locationText col-6">
          {{ customer.building.latitude }}, {{ customer.building.longitude }}
        </div>
        <div class="location col-1" (click)="isMapVisible = !isMapVisible">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" data-bs-toggle="modal"
            data-bs-target="#showMap">
            <path
              d="M256 0c17.7 0 32 14.3 32 32V66.7C368.4 80.1 431.9 143.6 445.3 224H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H445.3C431.9 368.4 368.4 431.9 288 445.3V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V445.3C143.6 431.9 80.1 368.4 66.7 288H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H66.7C80.1 143.6 143.6 80.1 224 66.7V32c0-17.7 14.3-32 32-32zM128 256c0 70.7 57.3 128 128 128s128-57.3 128-128s-57.3-128-128-128s-128 57.3-128 128zm128 80c-44.2 0-80-35.8-80-80s35.8-80 80-80s80 35.8 80 80s-35.8 80-80 80z"
              fill="#026962" />
          </svg>
        </div>
      </div>
      <div class="row">
        <div class="col-4">Feeder name</div>

        <div *ngIf="!editEnabled" class="col-8">
          {{ customer.dt?.feeder?.name | hasValue }}
        </div>
        <div *ngIf="editEnabled" class="col-8">
          <select *ngIf="customer.dt?.feeder != null" class="form-control" [(ngModel)]="customer.feederId"
            (change)="selectFeeder()">
            <option *ngFor="let option of f11Options" value="{{ option.id }}">
              {{ option.name }}
            </option>
          </select>
          <select *ngIf="customer.dt?.feeder != null" class="form-control" [(ngModel)]="customer.feederId"
            (change)="selectFeeder()">
            <option *ngFor="let option of f33Options" value="{{ option.id }}">
              {{ option.name }}
            </option>
          </select>
        </div>
      </div>

      <div class="row">
        <div class="col-5">Feeder number</div>
        <div class="col-7">{{ customer.dt?.feeder?.number | hasValue }}</div>
      </div>
      <div class="row">
        <div class="col-4">DT name</div>
        <div *ngIf="!editEnabled" class="col-8">
          {{ customer.dt?.name | hasValue }}
        </div>
        <div *ngIf="editEnabled" class="col-8">
          <select class="form-control" [(ngModel)]="customer.dtId">
            <option hidden selected>Select dt</option>
            <option *ngFor="let option of dtOptions" value="{{ option.key }}">
              {{ option.value }}
            </option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-5">{{ dtName }}</div>
        <div class="col-7">{{ customer.dt?.number | hasValue }}</div>
      </div>
    </div>
    <div class="card container main-col">
      <div class="row">
        <div class="col-5">Customer name</div>
        <div *ngIf="!editEnabled" class="col-7">
          {{ customer.buildingOwnerName | hasValue }}
        </div>
        <input *ngIf="editEnabled" class="col-7" [(ngModel)]="customer.buildingOwnerName" />
      </div>
      <div class="row">
        <div class="col-5">Number in household</div>
        <div *ngIf="!editEnabled" class="col-7">
          {{ customer?.numberOfHouseholds | hasValue }}
        </div>
        <input *ngIf="editEnabled" class="col-7" [(ngModel)]="customer.numberOfHouseholds" pattern="[0-9]+" />
      </div>
      <div class="row">
        <div class="col-5">Use of premises</div>
        <div class="col-7">{{ mapUseOfPremise(customer?.buildingType)| hasValue }}</div>
      </div>
      <div class="row">
        <div class="col-5">Phone number</div>
        <div *ngIf="!editEnabled" class="col-7">
          {{ customer.phoneNumber | hasValue }}
        </div>
        <input *ngIf="editEnabled" class="col-7" [(ngModel)]="customer.phoneNumber" pattern="[0-9]+" />
      </div>
      <div class="row">
        <div class="col-5">Email address</div>
        <div *ngIf="!editEnabled" class="col-7">
          {{ customer?.email | hasValue }}
        </div>
        <input *ngIf="editEnabled" class="col-7" [(ngModel)]="customer.email" type="email" />
      </div>
      <div class="row">
        <div class="col-5">ID Card Number</div>
        <div class="col-7">{{ customer?.cardNumber | hasValue }}</div>
      </div>
      <div class="row">
        <div class="col-5">Comment</div>
        <div class="col-7">{{ customer?.comment | hasValue }}</div>
      </div>
      <div class="row">
        <div class="col-5">Building's Creation Date</div>
        <div class="col-7">
          {{ customer.building?.creationDate | hasValue }}
        </div>
      </div>
      <div class="row">
        <div class="col-5">Tagged Date</div>
        <div class="col-7">
          {{ customer.taggedDate | hasValue }}
        </div>
      </div>
      <div class="row">
        <div class="col-5">First captured user</div>
        <div class="col-7">
          {{ customer.building?.firstCapturedUser | hasValue }}
        </div>
      </div>
      <div class="row">
        <div class="col-5">Last updated user</div>
        <div class="col-7">
          {{ customer.building?.lastUpdateUser | hasValue }}
        </div>
      </div>
      <div class="row">
        <div class="col-5">Updated Date</div>
        <div class="col-7">{{ customer.lastUpdateTime | hasValue }}</div>
      </div>
      <div class="row">
        <div class="col-5">Reassigned By</div>
        <div class="col-7">{{ customer.building.reassignedBy | hasValue }}</div>
      </div>
      <div class="row">
        <div class="col-5">Validated By</div>
        <div class="col-7">{{ customer.validatedBy | hasValue }}</div>
      </div>
      <div class="row">
        <div class="col-5">Validated Date</div>
        <div class="col-7">{{ customer.validatedDate | hasValue }}</div>
      </div>
      <div class="row">
        <div class="col-5">Audited Status</div>
        <div class="col-7">{{ customer.auditedStatus | hasValue }}</div>
      </div>
      <div class="row">
        <div class="col-5">Audited By</div>
        <div class="col-7">{{ customer.auditedBy | hasValue }}</div>
      </div>
      <div class="row">
        <div class="col-5">Audited Date</div>
        <div class="col-7">{{ customer.auditedDate | hasValue }}</div>
      </div>
      <div class="row">
        <div class="col-5">Validation Status</div>
        <div class="col-7">{{ customer.building?.status | hasValue }}</div>
      </div>
      <div class="row">
        <div class="col-5">Last vend amount</div>
        <div class="col-7">{{ customer.lastVendAmount | hasValue }}</div>
      </div>
      <div class="row">
        <div class="col-5">Last vend date</div>
        <div class="col-7">{{ customer.lastVendDate | hasValue }}</div>
      </div>

    </div>
    <div class="image-container main-col">
      <div class="card container">
        <div>Front view pictures</div>
        <div class="images-list">
          <div *ngIf="getFrontViewImage(0)">
            <img src="{{ getFrontViewImage(0) }}" (click)="showImage(0)" data-bs-toggle="modal"
              data-bs-target="#modalImage" alt="Front image" />
          </div>
          <div *ngIf="!getFrontViewImage(0)">
            <input hidden id="fileFw1" type="file" [disabled]="
                !permissionsService.isCustomerCreateEditAllowed
              " (change)="fileBrowserHandler($event, ImageType.Frontview)" />
            <label for="fileFw1" [ngClass]="{
                'disabled-label': !permissionsService.isCustomerCreateEditAllowed
              }">
              <img src="../../../../assets/missing_image.png" alt="Missing image"/>
            </label>
          </div>
          <div *ngIf="getFrontViewImage(1)">
            <img src="{{ getFrontViewImage(1) }}" (click)="showImage(1)" data-bs-toggle="modal"
              data-bs-target="#modalImage" alt="Front image" />
          </div>
          <div *ngIf="!getFrontViewImage(1)">
            <input hidden id="fileFw2" type="file" [disabled]="
                !permissionsService.isCustomerCreateEditAllowed
              " (change)="fileBrowserHandler($event, ImageType.Frontview)" />
            <label for="fileFw2" [ngClass]="{
                'disabled-label': !permissionsService.isCustomerCreateEditAllowed
              }">
              <img src="../../../../assets/missing_image.png" alt="Missing image"/>
            </label>
          </div>
          <div *ngIf="getFrontViewImage(2)">
            <img src="{{ getFrontViewImage(2) }}" (click)="showImage(2)" data-bs-toggle="modal"
              data-bs-target="#modalImage" alt="Front image" />
          </div>
          <div *ngIf="!getFrontViewImage(2)">
            <input hidden id="fileFw3" type="file" [disabled]="
                !permissionsService.isCustomerCreateEditAllowed
              " (change)="fileBrowserHandler($event, ImageType.Frontview)" />
            <label for="fileFw3" [ngClass]="{
                'disabled-label': !permissionsService.isCustomerCreateEditAllowed
              }">
              <img src="../../../../assets/missing_image.png" alt="Missing image"/>
            </label>
          </div>
        </div>
      </div>
      <div class="card container">
        <div>Mounted tag pictures</div>
        <div>
          <div *ngIf="getBuildingMountedImage()">
            <img src="{{ getBuildingMountedImage() }}" (click)="showImage(3)" data-bs-toggle="modal"
              data-bs-target="#modalImage" alt="Building image"/>
          </div>
          <div *ngIf="!getBuildingMountedImage()">
            <input hidden id="fileMounted" type="file" [disabled]="
                !permissionsService.isCustomerCreateEditAllowed
              " (change)="fileBrowserHandler($event, ImageType.MountedTag)" />
            <label for="fileMounted" [ngClass]="{
                'disabled-label': !permissionsService.isCustomerCreateEditAllowed
              }">
              <img src="../../../../assets/missing_image.png" alt="Missing image"/>
            </label>
          </div>
        </div>
      </div>
      <div class="card container">
        <div>Pictures of Customer ID card</div>
        <div>
          <div *ngIf="customer.cardImageUrl">
            <img src="{{ customer.cardImageUrl }}" (click)="showImage(5)" data-bs-toggle="modal"
              data-bs-target="#modalImage" alt="Card image" />
          </div>
          <div *ngIf="!customer.cardImageUrl">
            <input hidden id="filecard" type="file" [disabled]="
                !permissionsService.isCustomerCreateEditAllowed
              " (change)="fileBrowserHandler($event, ImageType.Card)" />
            <label for="filecard" [ngClass]="{
                'disabled-label': !permissionsService.isCustomerCreateEditAllowed
              }">
              <img src="../../../../assets/missing_image.png" alt="Missing image"/>
            </label>
          </div>
        </div>
      </div>
      <div class="card container">
        <div>Pictures of conection to utility supply</div>
        <div>
          <div *ngIf="customer.utilitySupplyImageUrl">
            <img src="{{ customer.utilitySupplyImageUrl }}" (click)="showImage(7)" data-bs-toggle="modal"
              data-bs-target="#modalImage" alt="Utility supply image" />
          </div>
          <div *ngIf="!customer.utilitySupplyImageUrl">
            <input hidden id="fileUtilitySupply" type="file" [disabled]="
                !permissionsService.isCustomerCreateEditAllowed
              " (change)="fileBrowserHandler($event, ImageType.UtilitySupply)" />
            <label for="fileUtilitySupply" [ngClass]="{
                'disabled-label': !permissionsService.isCustomerCreateEditAllowed
              }">
              <img src="../../../../assets/missing_image.png" alt="Missing image"/>
            </label>
          </div>
        </div>
      </div>
      <div class="card container">
        <div>Pictures of supply cut-out fuse</div>
        <div>
          <div *ngIf="customer.supplyFuseImageUrl">
            <img src="{{ customer.supplyFuseImageUrl }}" (click)="showImage(8)" data-bs-toggle="modal"
              data-bs-target="#modalImage" alt="Utility supply image" />
          </div>
          <div *ngIf="!customer.supplyFuseImageUrl">
            <input hidden id="fileCutOutFuse" type="file" [disabled]="
                !permissionsService.isCustomerCreateEditAllowed
              " (change)="fileBrowserHandler($event, ImageType.CutOutFuse)" />
            <label for="fileCutOutFuse" [ngClass]="{
                'disabled-label': !permissionsService.isCustomerCreateEditAllowed
              }">
              <img src="../../../../assets/missing_image.png" alt="Missing image"/>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="assignToFieldAgent" tabindex="-1" aria-hidden="true"
  (hidden.bs.modal)="onModalAssignFade()">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 *ngIf="assetAssignType == AssetAssignType.Assigned" class="modal-title">Assign to field agent</h5>
        <h5 *ngIf="assetAssignType == AssetAssignType.Retagged" class="modal-title">Retag task</h5>
        <button type="button" #closeModal class="btn-cancel-modal" data-bs-dismiss="modal" aria-label="Close">
          Cancel
        </button>
      </div>
      <div class="modal-body">
        <div class="form-floating form-item">
          <input #userFilterInput autocomplete="off" type="text" name="filterText" [(ngModel)]="filterText"
            class="form-control filter-field" [ngClass]="{ 'valid-input': tempAssignedUserId != 0 }" id="floatingInput"
            (ngModelChange)="filterMobileUsers()" (click)="openDropdown()" placeholder=" " />
          <label for="floatingInput">Field agent</label>

          <div class="filtered-agent-list" *ngIf="isDropdownOpened" #dropdownContainer>
            <div click-stop-propagation class="agent-name" (click)="selectFieldAgent(u.id)"
              *ngFor="let u of mobileUsers">
              {{ u.fullName }}
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="assignTask()">
          <span *ngIf="assetAssignType == AssetAssignType.Assigned">Assign task</span>
          <span *ngIf="assetAssignType == AssetAssignType.Retagged">Retag task</span>
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="modalImage" tabindex="-1" aria-hidden="true" (hidden.bs.modal)="onModalFade()"
  (shown.bs.modal)="onModalOpen()">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content modal-image">
      <div class="modal-body modal-body-image">
        <div class="image-container" [style.transform]="'rotate(' + currentRotation + 'deg)'">
          <lib-ngx-image-zoom [zoomMode]="'hover-freeze'" [fullImage]="selectedImage" [thumbImage]="selectedImage"
            [magnification]="3">
          </lib-ngx-image-zoom>
        </div>
        <div class="rotate-div">
          <button class="btn btn-primary left-button" (click)="rotateLeft()">
            &lt;
          </button>
          <button class="btn btn-primary right-button" (click)="rotateRight()">
            &gt;
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal modalMap" id="showMap" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" #closeAddModal class="close" data-bs-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <app-building-map [setMapVisibility]="isMapVisible" [longitude]="longitude"
          [latitude]="latitude"></app-building-map>
      </div>
    </div>
  </div>
</div>

<div class="modal" id="updateStatus" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Data Validation Review</h5>
        <button type="button" #closeDQModal class="btn-cancel-modal" data-bs-dismiss="modal" aria-label="Close">
          Cancel
        </button>
      </div>
      <div class="modal-body">
        <app-data-quality (closeModal)="cancelModal($event)" assetType="Customer"
          [assetId]="customer.id"></app-data-quality>
      </div>
    </div>
  </div>
</div>
<button class="btn btn-primary" #openUpdateDescriptionModal data-bs-toggle="modal" data-bs-target="#history"
  hidden></button>
<app-history (update)="updateHistory($event)"></app-history>