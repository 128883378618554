import { Injectable } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { IOption } from 'src/app/_modules/shared/_interfaces/option';
import { KycCustomerApiService } from 'src/app/_modules/customers/_services/kyc-customer-api.service';
import {
  KycAccountData,
  LoadRequirement,
} from '../../../_models/kyc-account-data';
import { Title } from '../../../_enums/title.enum';
import { Identification } from '../../../_enums/identification.enum';
import { NatureOfProperty } from '../../../_enums/nature-of-property';
import { TypeOfPremises } from '../../../_enums/type-of-premises';
import { UseOfPremises } from '../../../_enums/use-of-premises';
import { TypeOfProperty } from '../../../_enums/type-of-property';
import { Router } from '@angular/router';
import { Region } from 'src/app/models/region.model';
import { Area } from 'src/app/models/area.model';
import { AreaService } from 'src/app/services/area.service';
import { RegionService } from 'src/app/services/region.service';
import { Rejection } from '../../../_models/kyc-rejection';

export type PersonalDataFormSteps = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | number;

@Injectable({
  providedIn: 'root',
})
export class PersonalDataStateService {
  // Personal state
  step: PersonalDataFormSteps = 0; // Set to 0 initialy to hide all forms while loading
  personalDataTitle = 'Personal Data';
  customerId: number | null;
  // Maximum selectable date must be 18 years ago
  maxSelectableDate: Date = new Date(new Date().setFullYear(new Date().getFullYear() - 18));

  // 1. step props
  titles: IOption[] = [
    { displayValue: 'Mr.', value: Title.Mr },
    { displayValue: 'Mrs.', value: Title.Mrs },
    { displayValue: 'Miss.', value: Title.Miss },
    { displayValue: 'Dr.', value: Title.Dr },
  ];
  public selectedTitle: IOption | null = this.titles[0];
  public surname: FormControl = new FormControl('', Validators.required);
  public firstName: FormControl = new FormControl('', Validators.required);
  public phone: FormControl = new FormControl('', Validators.required);
  public dialCode: string | null = null;
  public buildingOwnerDialCode: string | null = null;
  public buildingOwnerEmployerDialCode: string | null = null;
  public email: FormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);
  public dateOfBirth: FormControl<Date | null> = new FormControl(
    null,
    Validators.required
  );
  public employerName: FormControl = new FormControl('', Validators.required);
  public houseNo: FormControl = new FormControl('', Validators.required);
  public streetName: FormControl = new FormControl('', Validators.required);
  public landmark: FormControl = new FormControl('');
  public city: FormControl = new FormControl('');
  public area: FormControl = new FormControl('');
  public areaId: number | null = null;
  public selectedRegion: IOption | null;
  public selectedArea: Area | null;

  // 2. step props
  public meterNumber: FormControl<string | null> = new FormControl('');
  public numberOfPremises: FormControl<string | null> = new FormControl<string>(
    ''
  );
  public identification: IOption[] = [
    { displayValue: 'Driver’s License', value: Identification.DriversLicense },
    {
      displayValue: 'International Passport',
      value: Identification.InternationalPassport,
    },
    { displayValue: 'National ID Card', value: Identification.NationalIdCard },
    { displayValue: "Voters' Card", value: Identification.VotersCard },
  ];
  public isLandlord: IOption[] = [
    { displayValue: 'Yes', value: 'true' },
    {
      displayValue: 'No',
      value: 'false',
    },
  ];
  public selectedIdentificationOfLandlord: IOption | null;
  public personnelIDNumOfLandlord: FormControl<string | null> = new FormControl(
    ''
  );
  public supplyHouseNo: FormControl<string | null> = new FormControl(
    '',
    Validators.required
  );
  public supplyStreetName: FormControl<string | null> = new FormControl(
    '',
    Validators.required
  );
  public supplyLandmark: FormControl<string | null> = new FormControl('');
  public supplyCity: FormControl<string | null> = new FormControl('');
  public supplyArea: FormControl<string | null> = new FormControl('');
  public bankVerificationNumber: FormControl<string | null> = new FormControl('');
  public supplyAreaId: number | null = null;
  public selectedSupplyRegion: IOption | null;
  public selectedSupplyArea: Area | null;
  public typeOfPermises: IOption[] = [
    { displayValue: '2Bed Room Flat', value: TypeOfPremises.TwoBedRoomFlat },
    { displayValue: 'Tenement House', value: TypeOfPremises.TenementHouse },
    { displayValue: '3Bed Room Flat', value: TypeOfPremises.ThreeBedRoomFlat },
    { displayValue: 'Single Room', value: TypeOfPremises.SingleRoom },
    { displayValue: 'Shop/Boys Qt', value: TypeOfPremises.ShopBoysQt },
    { displayValue: 'Block of Flats', value: TypeOfPremises.BlockOfFlats },
    { displayValue: 'Duplex', value: TypeOfPremises.Duplex },
    { displayValue: 'Others', value: TypeOfPremises.Others },
  ];
  public selectedTypeOfPermises: IOption | null;
  public useOfPermises: IOption[] = [
    { displayValue: 'Residential', value: UseOfPremises.Residential },
    { displayValue: 'Commercial', value: UseOfPremises.Commercial },
    { displayValue: 'Special', value: UseOfPremises.Special },
    { displayValue: 'Industrial', value: UseOfPremises.Industrial },
    { displayValue: 'Others', value: UseOfPremises.Others },
  ];
  public selectedUseOfPermises: IOption | null;

  // 3. step props
  public previousCustomerHouseNo: FormControl<string | null> = new FormControl(
    ''
  );
  public previousCustomerStreet: FormControl<string | null> = new FormControl(
    ''
  );
  public previousCustomerLandMark: FormControl<string | null> = new FormControl(
    ''
  );
  public previousCustomerCity: FormControl<string | null> = new FormControl('');
  public previousCustomerArea: FormControl<string | null> = new FormControl('');
  public previousCustomerAreaId: number | null = null;

  public previousMeterNumber: FormControl<string | null> = new FormControl('');
  public previousAccountNumber: FormControl<string | null> = new FormControl(
    ''
  );
  public selectedLandlordCheck: IOption | null;

  public buildingOwner: FormControl<string | null> = new FormControl('', Validators.required);
  public buildingOwnerHouseNo: FormControl<string | null> = new FormControl('', Validators.required);
  public buildingOwnerStreet: FormControl<string | null> = new FormControl('', Validators.required);
  public buildingOwnerLandMark: FormControl<string | null> = new FormControl('');
  public buildingOwnerCity: FormControl<string | null> = new FormControl('');
  public buildingOwnerArea: FormControl<string | null> = new FormControl('');
  public buildingOwnerAreaId: number | null = null;
  selectedBuildingOwnerRegion: IOption | null;
  selectedBuildingOwnerArea: Area | null;
  selectedPreviousCustomerRegion: IOption | null;
  selectedPreviousCustomerArea: Area | null;

  // 4. step props
  public buildingOwnerPhoneNumber: FormControl = new FormControl('');
  public selectedBuildingOwnerIdentification: IOption | null;
  public personalIDNumber: FormControl<string | null> = new FormControl('');
  public nameOfEmployerOfBuildingOwner: FormControl<string | null> =
    new FormControl('');
  public employerBusinessHouseNo: FormControl<string | null> = new FormControl(
    ''
  );
  public employerBusinessStreet: FormControl<string | null> = new FormControl(
    ''
  );
  public employerBusinessLandMark: FormControl<string | null> = new FormControl(
    ''
  );
  public employerBusinessCity: FormControl<string | null> = new FormControl('');
  public employerBusinessArea: FormControl<string | null> = new FormControl('');
  public buildingOwnerEmployerPhoneNumber: FormControl = new FormControl('');
  public buildingOwnerEmail: FormControl<string | null> = new FormControl(
    '',
    Validators.email
  );
  public employerBusinessAreaId: number | null = null;
  public selectedEmployerBusinessRegion: IOption | null;
  public selectedEmployerBusinessArea: Area | null;
  // 5. step props
  public lightBulbId: number | null;
  public lightBulbNumber: FormControl<number | null> = new FormControl(
    0,
    Validators.min(0)
  );
  public lightBulbWattage: FormControl<number | null> = new FormControl(
    0,
    Validators.min(0)
  );
  public lightBulbTitle = 'Light bulb';

  public ceilingFanId: number | null;
  public ceilingFanNumber: FormControl<number | null> = new FormControl(
    0,
    Validators.min(0)
  );
  public ceilingFanWattage: FormControl<number | null> = new FormControl(
    0,
    Validators.min(0)
  );
  public ceilingFanTitle = 'Ceiling fan';

  public socketOutlets5Id: number | null;
  public socketOutlets5Number: FormControl<number | null> = new FormControl(
    0,
    Validators.min(0)
  );
  public socketOutlets5Wattage: FormControl<number | null> = new FormControl(
    0,
    Validators.min(0)
  );
  public socketOutlets5Title = 'Socket outlets (5 Amp)';

  public socketOutlets13Id: number | null;
  public socketOutlets13Number: FormControl<number | null> = new FormControl(
    0,
    Validators.min(0)
  );
  public socketOutlets13Wattage: FormControl<number | null> = new FormControl(
    0,
    Validators.min(0)
  );
  public socketOutlets13Title = 'Socket outlets (13 Amp)';

  public socketOutlets15Id: number | null;
  public socketOutlets15Number: FormControl<number | null> = new FormControl(
    0,
    Validators.min(0)
  );
  public socketOutlets15Wattage: FormControl<number | null> = new FormControl(
    0,
    Validators.min(0)
  );
  public socketOutlets15Title = 'Socket outlets (15 Amp)';

  public cookerId: number | null;
  public cookerNumber: FormControl<number | null> = new FormControl(
    0,
    Validators.min(0)
  );
  public cookerWattage: FormControl<number | null> = new FormControl(
    0,
    Validators.min(0)
  );
  public cookerTitle = 'Cooker';

  public waterHeaterId: number | null;
  public waterHeaterNumber: FormControl<number | null> = new FormControl(
    0,
    Validators.min(0)
  );
  public waterHeaterWattage: FormControl<number | null> = new FormControl(
    0,
    Validators.min(0)
  );
  public waterHeaterTitle = 'Water heater';

  public airConditionerId: number | null;
  public airConditionerNumber: FormControl<number | null> = new FormControl(
    0,
    Validators.min(0)
  );
  public airConditionerWattage: FormControl<number | null> = new FormControl(
    0,
    Validators.min(0)
  );
  public airConditionerTitle = 'Air conditioner';

  public othersId: number | null;
  public othersNumber: FormControl<number | null> = new FormControl(
    0,
    Validators.min(0)
  );
  public othersWattage: FormControl<number | null> = new FormControl(
    0,
    Validators.min(0)
  );
  public othersTitle = 'Others';

  // 6. step props
  public natureOfProperty: IOption[] = [
    { displayValue: 'Old House', value: NatureOfProperty.OldHouse },
    { displayValue: 'New House', value: NatureOfProperty.NewHouse },
  ];
  public selectedNatureOfProperty: IOption | null;
  public typeOfProperty: IOption[] = [
    { displayValue: 'Apartment', value: TypeOfProperty.Apartment },
    { displayValue: 'Terrace', value: TypeOfProperty.Terrace },
    { displayValue: 'Pump House', value: TypeOfProperty.PumpHouse },
    { displayValue: 'Single House', value: TypeOfProperty.SingleHouse },
    { displayValue: 'Others', value: TypeOfProperty.Others },
  ];
  public selectedtypeOfProperty: IOption | null;
  public propertyHavePlanningPermission: boolean = true;
  public propertyHaveMeter: boolean = true;
  public meterNumber6: FormControl<string | null> = new FormControl('');
  public customerAccountNumberForPremises: FormControl<string | null> =
    new FormControl('');
  public estMaxLoad: FormControl<number | null> = new FormControl(
    null,
    Validators.pattern('([0-9]*[.])?[0-9]+')
  );
  public requestedVoltage: FormControl<number | null> = new FormControl(
    null,
    Validators.pattern('([0-9]*[.])?[0-9]+')
  );
  public engineerName: FormControl<string | null> = new FormControl('');
  public engineerLastName: FormControl<string | null> = new FormControl('');
  public engineerNameDesktop: FormControl<string | null> = new FormControl('');

  // 7. step props
  public engineerHouseNo: FormControl<string | null> = new FormControl('');
  public engineerStreet: FormControl<string | null> = new FormControl('');
  public engineerLandmark: FormControl<string | null> = new FormControl('');
  public engineerCity: FormControl<string | null> = new FormControl('');
  public engineerArea: FormControl<string | null> = new FormControl('');
  public licenseNumber: FormControl<string | null> = new FormControl('');
  public category: FormControl<string | null> = new FormControl('');
  public seventhStepDate: FormControl<Date | null> = new FormControl(null);
  public engineerMeterNumber: FormControl<string | null> = new FormControl('');
  public customerAccountNumber: FormControl<string | null> = new FormControl(
    ''
  );
  public engineerAreaId: number | null = null;
  public selectedEngineerRegion: IOption;
  public selectedEngineerArea: Area | null;

  // 8. step props
  public customersSignitureUrl: string | null;
  public landlordsSignitureUrl: string | null;
  public electricalContractorsSignitureUrl: string | null;
  public applicantsSignitureUrl: string | null;
  public passportUrl: string | null;
  public customersSignitureName: string;
  public landlordsSignitureName: string;
  public electricalContractorsSignitureName: string;
  public applicantsSignitureName: string;
  public passportName: string;
  public isAgreed: boolean = true;
  public rejection: boolean = true;

  public regions: IOption[] = [];
  public areas: Area[] = [];
  public shownAreas: Area[] = [];
  public shownPreviousCustomerAreas: Area[] = [];
  public rejections?: Rejection[];

  constructor(
    private kycCustomerApiService: KycCustomerApiService,
    private toastrService: ToastrService,
    private router: Router,
    private areaService: AreaService,
    private regionService: RegionService
  ) {}

  saveAndContinue() {
    let request: KycAccountData = this.setKycAccountDataForServer();
    this.kycCustomerApiService.saveCustomer(request).subscribe({
      // Test object for beckend, switch with real object
      next: this.handleSaveKycAccountData.bind(this),
      error: this.handleError.bind(this),
    });
  }

  rejectionSaveAndContinue() {
    let request: KycAccountData = this.setKycAccountDataForServer();
    this.kycCustomerApiService.saveCustomer(request).subscribe({
      // Test object for beckend, switch with real object
      next: this.handleRejectionSaveKycAccountData.bind(this),
      error: this.handleError.bind(this),
    });
  }

  rejectionContinue() {
    let request: KycAccountData = this.setKycAccountDataForServer();
    this.kycCustomerApiService.saveCustomer(request).subscribe({
      // Test object for beckend, switch with real object
      next: this.handleRejectionContinueKycAccountData.bind(this),
      error: this.handleError.bind(this),
    });

    // TODO: Only for development until server is finished
    // this.handleContinueKycAccountData(null);
  }

  continue() {
    let request: KycAccountData = this.setKycAccountDataForServer();
    this.kycCustomerApiService.saveCustomer(request).subscribe({
      // Test object for beckend, switch with real object
      next: this.handleContinueKycAccountData.bind(this),
      error: this.handleError.bind(this),
    });

    // TODO: Only for development until server is finished
    // this.handleContinueKycAccountData(null);
  }

  goBack() {
    this.step = this.step > 1 ? this.step - 1 : 1;
    this.setPersonalDataTitle();
  }

  setPersonalDataTitle() {
    switch (this.step) {
      case 1:
        this.personalDataTitle = 'Personal Data';
        break;
      case 2:
        this.personalDataTitle = 'Personal Data - II';
        break;
      case 3:
        this.personalDataTitle = 'Personal Data - III';
        break;
      case 4:
        this.personalDataTitle = 'Personal Data - IV';
        break;
      case 5:
        this.personalDataTitle =
          'Declaration: Load Requirement for the premises';
        break;
      case 6:
        this.personalDataTitle = 'Personal Data - V';
        break;
      case 7:
        this.personalDataTitle = 'Personal Data - VI';
        break;
      case 8:
        this.personalDataTitle = 'Upload File';
        break;
      default:
        this.personalDataTitle = 'Personal Data';
    }
  }

  public resetState() {
    // Personal state
    this.step = 1;
    this.setPersonalDataTitle();
    this.customerId = null;

    // 1. step props
    this.selectedTitle = null;
    this.surname.setValue('');
    this.firstName.setValue('');
    this.phone.setValue('');
    this.dialCode = null;
    this.email.setValue('');
    this.dateOfBirth.setValue(null);
    this.employerName.setValue('');
    this.houseNo.setValue('');
    this.streetName.setValue('');
    this.landmark.setValue('');
    this.city.setValue('');
    this.area.setValue('');
    this.areaId = null;
    this.selectedRegion = null;
    this.selectedArea = null;

    // 2. step props
    this.meterNumber.setValue('');
    this.numberOfPremises.setValue('');
    this.selectedIdentificationOfLandlord = null;
    this.personnelIDNumOfLandlord.setValue('');
    this.supplyHouseNo.setValue('');
    this.supplyStreetName.setValue('');
    this.supplyLandmark.setValue('');
    this.supplyCity.setValue('');
    this.supplyArea.setValue('');
    this.selectedSupplyArea = null;
    this.supplyAreaId = null;
    this.selectedSupplyRegion = null;
    this.selectedTypeOfPermises = null;
    this.selectedUseOfPermises = null;
    this.bankVerificationNumber.setValue('');

    // 3. step props
    this.selectedLandlordCheck = null;
    this.previousCustomerHouseNo.setValue('');
    this.previousCustomerStreet.setValue('');
    this.previousCustomerLandMark.setValue('');
    this.previousCustomerCity.setValue('');
    this.previousCustomerArea.setValue('');
    this.previousMeterNumber.setValue('');
    this.previousAccountNumber.setValue('');
    this.selectedPreviousCustomerRegion = null;
    this.selectedPreviousCustomerArea = null;
    this.previousCustomerAreaId = null
    this.buildingOwner.setValue('');
    this.buildingOwnerHouseNo.setValue('');
    this.buildingOwnerStreet.setValue('');
    this.buildingOwnerLandMark.setValue('');
    this.buildingOwnerCity.setValue('');
    this.buildingOwnerArea.setValue('');
    this.selectedBuildingOwnerRegion = null;
    this.selectedBuildingOwnerArea = null;
    this.buildingOwnerAreaId = null;

    // 4. step props
    this.buildingOwnerPhoneNumber.setValue('');
    this.buildingOwnerDialCode = null;
    this.selectedBuildingOwnerIdentification = null;
    this.personalIDNumber.setValue('');
    this.nameOfEmployerOfBuildingOwner.setValue('');
    this.employerBusinessHouseNo.setValue('');
    this.employerBusinessStreet.setValue('');
    this.employerBusinessLandMark.setValue('');
    this.employerBusinessCity.setValue('');
    this.employerBusinessArea.setValue('');
    this.buildingOwnerEmployerPhoneNumber.setValue('');
    this.buildingOwnerEmployerDialCode = null;
    this.buildingOwnerEmail.setValue('');

    // 5. step props
    this.lightBulbNumber.setValue(0);
    this.lightBulbWattage.setValue(0);
    this.ceilingFanNumber.setValue(0);
    this.ceilingFanWattage.setValue(0);
    this.socketOutlets5Number.setValue(0);
    this.socketOutlets5Wattage.setValue(0);
    this.socketOutlets13Number.setValue(0);
    this.socketOutlets13Wattage.setValue(0);
    this.socketOutlets15Number.setValue(0);
    this.socketOutlets15Wattage.setValue(0);
    this.cookerNumber.setValue(0);
    this.cookerWattage.setValue(0);
    this.waterHeaterNumber.setValue(0);
    this.waterHeaterWattage.setValue(0);
    this.airConditionerNumber.setValue(0);
    this.airConditionerWattage.setValue(0);
    this.othersNumber.setValue(0);
    this.othersWattage.setValue(0);

    // 6. step props
    this.selectedNatureOfProperty = null;
    this.selectedtypeOfProperty = null;
    this.propertyHavePlanningPermission = true;
    this.propertyHaveMeter = true;
    this.meterNumber6.setValue('');
    this.customerAccountNumberForPremises.setValue('');
    this.estMaxLoad.setValue(null);
    this.requestedVoltage.setValue(null);
    this.engineerName.setValue('');
    this.engineerLastName.setValue('');
    this.engineerNameDesktop.setValue('');

    // 7. step props
    this.engineerHouseNo.setValue('');
    this.engineerStreet.setValue('');
    this.engineerLandmark.setValue('');
    this.engineerCity.setValue('');
    this.engineerArea.setValue('');
    this.licenseNumber.setValue('');
    this.category.setValue('');
    this.seventhStepDate.setValue(null);
    this.engineerMeterNumber.setValue('');
    this.customerAccountNumber.setValue('');

    // 8. step props
    this.customersSignitureUrl = null;
    this.landlordsSignitureUrl = null;
    this.electricalContractorsSignitureUrl = null;
    this.applicantsSignitureUrl = null;
    this.passportUrl = null;
    this.isAgreed = true;
  }

  public handleContinueKycAccountData(response: any) {
    // TODO Change this part for next delpoyment, handle other 4 pages also
    this.setServiceStateFromServer(new KycAccountData(response.data));
    this.step = this.step < 10 ? this.step + 1 : 9;

    if (this.step > 8)
      this.router.navigate(['/customers-app/account-information']);
    this.setPersonalDataTitle();
  }

  public handleRejectionContinueKycAccountData(response: any) {
    this.step = 8;
    this.setPersonalDataTitle();
  }

  public handleRejectionSaveKycAccountData(response: any) {
    this.kycCustomerApiService.setAccountKYCStep(13).subscribe({
      next: this.handleRejectionsetAccountKYCStep.bind(this),
      error: this.handleError.bind(this),
    });
  }
  handleRejectionsetAccountKYCStep() {
    this.router.navigate(['/customers-app/profile-incomplete']);
  }

  public handleSaveKycAccountData(response: any) {
    this.setServiceStateFromServer(new KycAccountData(response.data));
    this.resetState();
    this.router.navigate(['/customers-app/profile-incomplete']);
  }

  public handleGetKycAccountData(event: any) {
    this.setServiceStateFromServer(new KycAccountData(event.data));
    if (this.areas?.length > 0 && this.regions?.length > 0) {
      this.mapSelectedRegionAndAreas();
    }
  }

  public handleError(event: any) {
    this.toastrService.error('An error occured');
  }

  setTitle(num: number | null | undefined): IOption {
    return this.titles.find((el) => el.value === num) ?? this.titles[0];
  }

  setIdentification(num: number | null | undefined): IOption | null {
    return this.identification.find((el) => el.value === num) ?? null;
  }

  setIdentificationOfBuildingOwner(
    num: number | null | undefined
  ): IOption | null {
    return this.identification.find((el) => el.value === num) ?? null;
  }

  setNatureOfProperty(num: number | null | undefined): IOption | null {
    return this.natureOfProperty.find((el) => el.value === num) ?? null;
  }

  setTypeOfProperty(num: number | null | undefined): IOption | null {
    return this.typeOfProperty.find((el) => el.value === num) ?? null;
  }

  setUseOfPermises(num: number | null | undefined): IOption | null {
    return this.useOfPermises.find((el) => el.value === num) ?? null;
  }

  setTypeOfPermises(num: number | null | undefined): IOption | null {
    return this.typeOfPermises.find((el) => el.value === num) ?? null;
  }

  setFifthStepFromServer(requirements: LoadRequirement[]) {
    for (let index = 0; index < requirements?.length; index++) {
      const element: LoadRequirement = requirements[index];
      switch (element.name) {
        case this.lightBulbTitle:
          this.lightBulbId = element.id;
          this.lightBulbNumber.setValue(element.number);
          this.lightBulbWattage.setValue(element.wattage);
          break;
        case this.ceilingFanTitle:
          this.ceilingFanId = element.id;
          this.ceilingFanNumber.setValue(element.number);
          this.ceilingFanWattage.setValue(element.wattage);
          break;
        case this.socketOutlets5Title:
          this.socketOutlets5Id = element.id;
          this.socketOutlets5Number.setValue(element.number);
          this.socketOutlets5Wattage.setValue(element.wattage);
          break;
        case this.socketOutlets13Title:
          this.socketOutlets13Id = element.id;
          this.socketOutlets13Number.setValue(element.number);
          this.socketOutlets13Wattage.setValue(element.wattage);
          break;
        case this.socketOutlets15Title:
          this.socketOutlets15Id = element.id;
          this.socketOutlets15Number.setValue(element.number);
          this.socketOutlets15Wattage.setValue(element.wattage);
          break;
        case this.cookerTitle:
          this.cookerId = element.id;
          this.cookerNumber.setValue(element.number);
          this.cookerWattage.setValue(element.wattage);
          break;
        case this.waterHeaterTitle:
          this.waterHeaterId = element.id;
          this.waterHeaterNumber.setValue(element.number);
          this.waterHeaterWattage.setValue(element.wattage);
          break;
        case this.airConditionerTitle:
          this.airConditionerId = element.id;
          this.airConditionerNumber.setValue(element.number);
          this.airConditionerWattage.setValue(element.wattage);
          break;
        case this.othersTitle:
          this.othersId = element.id;
          this.othersNumber.setValue(element.number);
          this.othersWattage.setValue(element.wattage);
      }
    }
  }

  setFifthStepForServer(): LoadRequirement[] {
    const loadRequirements: LoadRequirement[] = [];

    if (this.lightBulbNumber.value || this.lightBulbWattage.value) {
      loadRequirements.push({
        id: this.lightBulbId,
        name: this.lightBulbTitle,
        number: isNaN(Number(this.lightBulbNumber?.value))
          ? null
          : Number(this.lightBulbNumber?.value),
        wattage: isNaN(Number(this.lightBulbWattage?.value))
          ? null
          : Number(this.lightBulbWattage?.value),
      });
    }

    if (this.ceilingFanNumber.value || this.ceilingFanWattage.value) {
      loadRequirements.push({
        id: this.ceilingFanId,
        name: this.ceilingFanTitle,
        number: isNaN(Number(this.ceilingFanNumber?.value))
          ? null
          : Number(this.ceilingFanNumber?.value),
        wattage: isNaN(Number(this.ceilingFanWattage?.value))
          ? null
          : Number(this.ceilingFanWattage?.value),
      });
    }

    if (this.socketOutlets5Number.value || this.socketOutlets5Wattage.value) {
      loadRequirements.push({
        id: this.socketOutlets5Id,
        name: this.socketOutlets5Title,
        number: isNaN(Number(this.socketOutlets5Number?.value))
          ? null
          : Number(this.socketOutlets5Number?.value),
        wattage: isNaN(Number(this.socketOutlets5Wattage?.value))
          ? null
          : Number(this.socketOutlets5Wattage?.value),
      });
    }

    if (this.socketOutlets13Number.value || this.socketOutlets13Wattage.value) {
      loadRequirements.push({
        id: this.socketOutlets13Id,
        name: this.socketOutlets13Title,
        number: isNaN(Number(this.socketOutlets13Number?.value))
          ? null
          : Number(this.socketOutlets13Number?.value),
        wattage: isNaN(Number(this.socketOutlets13Wattage?.value))
          ? null
          : Number(this.socketOutlets13Wattage?.value),
      });
    }

    if (this.socketOutlets15Number.value || this.socketOutlets15Wattage.value) {
      loadRequirements.push({
        id: this.socketOutlets15Id,
        name: this.socketOutlets15Title,
        number: isNaN(Number(this.socketOutlets15Number?.value))
          ? null
          : Number(this.socketOutlets15Number?.value),
        wattage: isNaN(Number(this.socketOutlets15Wattage?.value))
          ? null
          : Number(this.socketOutlets15Wattage?.value),
      });
    }

    if (this.cookerNumber.value || this.cookerWattage.value) {
      loadRequirements.push({
        id: this.cookerId,
        name: this.cookerTitle,
        number: isNaN(Number(this.cookerNumber?.value))
          ? null
          : Number(this.cookerNumber?.value),
        wattage: isNaN(Number(this.cookerWattage?.value))
          ? null
          : Number(this.cookerWattage?.value),
      });
    }

    if (this.waterHeaterNumber.value || this.waterHeaterWattage.value) {
      loadRequirements.push({
        id: this.waterHeaterId,
        name: this.waterHeaterTitle,
        number: isNaN(Number(this.waterHeaterNumber?.value))
          ? null
          : Number(this.waterHeaterNumber?.value),
        wattage: isNaN(Number(this.waterHeaterWattage?.value))
          ? null
          : Number(this.waterHeaterWattage?.value),
      });
    }

    if (this.airConditionerNumber.value || this.airConditionerWattage.value) {
      loadRequirements.push({
        id: this.airConditionerId,
        name: this.airConditionerTitle,
        number: isNaN(Number(this.airConditionerNumber?.value))
          ? null
          : Number(this.airConditionerNumber?.value),
        wattage: isNaN(Number(this.airConditionerWattage?.value))
          ? null
          : Number(this.airConditionerWattage?.value),
      });
    }

    if (this.othersNumber.value || this.othersWattage.value) {
      loadRequirements.push({
        id: this.othersId,
        name: this.othersTitle,
        number: isNaN(Number(this.othersNumber?.value))
          ? null
          : Number(this.othersNumber?.value),
        wattage: isNaN(Number(this.othersWattage?.value))
          ? null
          : Number(this.othersWattage?.value),
      });
    }
    return loadRequirements;
  }

  setServiceStateFromServer(model: KycAccountData) {
    if (model.currentPage == 9) {
      this.router.navigate(['/customers-app/account-information']);
      return;
    } else if (model.currentPage == 10) {
      this.router.navigate(['/customers-app/update-meter-request']);
    } else if (model.currentPage == 11) {
      this.router.navigate(['/customers-app/update-meter-request-review']);
    } else if (model.currentPage == 12) {
      this.router.navigate(['/customers-app/review-application']);
    } else if (model.currentPage >= 13) {
      this.router.navigate(['/customers-app/profile-incomplete']);
    }

    // Personal state
    this.step = model.currentPage;
    this.setPersonalDataTitle();
    this.customerId = model.id;

    // 1. step props
    this.selectedTitle = this.setTitle(model.title);
    this.surname.setValue(model.surname ?? '');
    this.firstName.setValue(model.firstName ?? '');
    this.phone.setValue(model.phoneNumber ?? '');
    this.dialCode = model.dialCode;
    this.buildingOwnerDialCode = model.buildingOwnerDialCode;
    this.buildingOwnerEmployerDialCode = model.buildingOwnerEmployerDialCode;
    this.email.setValue(model.email ?? '');
    this.dateOfBirth.setValue(new Date(model.dateOfBirth ?? ''));
    this.employerName.setValue(model.nameOfEmployer ?? '');
    this.houseNo.setValue(model.employerAddress?.houseNo ?? '');
    this.streetName.setValue(model.employerAddress?.street ?? '');
    this.landmark.setValue(model.employerAddress?.landmark ?? '');
    this.city.setValue(model.employerAddress?.city ?? '');
    this.area.setValue(model.employerAddress?.area ?? '');
    this.areaId = model.employerAddress.areaId;
    // 2. step props
    this.meterNumber.setValue(model.meterNumberLandlord ?? '');
    this.bankVerificationNumber.setValue(model.bankVerificationNumber ?? '');
    this.numberOfPremises.setValue(
      model.accountNumberForPremissesLandlord ?? ''
    );
    this.selectedIdentificationOfLandlord = this.setIdentification(
      model.identificationOfLandlord
    );
    this.personnelIDNumOfLandlord.setValue(
      model.personalIdNumberOfLandlord ?? ''
    );
    this.supplyHouseNo.setValue(model.addressOfLandlord?.houseNo ?? '');
    this.supplyStreetName.setValue(model.addressOfLandlord?.street ?? '');
    this.supplyLandmark.setValue(model.addressOfLandlord?.landmark ?? '');
    this.supplyCity.setValue(model.addressOfLandlord?.city ?? '');
    this.supplyArea.setValue(model.addressOfLandlord?.area ?? '');
    this.selectedTypeOfPermises = this.setTypeOfPermises(model.typeOfPremises);
    this.selectedUseOfPermises = this.setUseOfPermises(model.useOfPremises);
    this.supplyAreaId = model.addressOfLandlord.areaId;
    // 3. step props
    this.previousCustomerHouseNo.setValue(
      model.addressPreviousCustomer?.houseNo ?? ''
    );
    this.previousCustomerStreet.setValue(
      model.addressPreviousCustomer?.street ?? ''
    );
    this.previousCustomerLandMark.setValue(
      model.addressPreviousCustomer?.landmark ?? ''
    );
    this.previousCustomerCity.setValue(
      model.addressPreviousCustomer?.city ?? ''
    );
    this.previousCustomerArea.setValue(
      model.addressPreviousCustomer?.area ?? ''
    );
    this.previousMeterNumber.setValue(model.meterNumberPreviousCustomer ?? '');
    this.previousAccountNumber.setValue(
      model.accountNumberPreviousCustomer ?? ''
    );
    this.buildingOwner.setValue(model.buildingOwner ?? '');
    this.buildingOwnerHouseNo.setValue(
      model.buildingOwnerAddress?.houseNo ?? ''
    );
    this.buildingOwnerStreet.setValue(model.buildingOwnerAddress?.street ?? '');
    this.buildingOwnerLandMark.setValue(
      model.buildingOwnerAddress?.landmark ?? ''
    );
    this.buildingOwnerCity.setValue(model.buildingOwnerAddress?.city ?? '');
    this.buildingOwnerArea.setValue(model.buildingOwnerAddress?.area ?? '');
    this.buildingOwnerAreaId = model.buildingOwnerAddress.areaId;
    this.previousCustomerAreaId = model.addressPreviousCustomer.areaId;
    // 4. step props
    this.buildingOwnerPhoneNumber.setValue(model.buildingOwnerPhoneNumber);
    this.selectedBuildingOwnerIdentification =
      this.setIdentificationOfBuildingOwner(
        model.identificationOfBuildingOwner
      );
    this.personalIDNumber.setValue(model.personalIdNumberOfBuildingOwner ?? '');
    this.nameOfEmployerOfBuildingOwner.setValue(
      model.nameOfEmployerOfBuildingOwner ?? ''
    );
    this.employerBusinessHouseNo.setValue(
      model.employerAddressOfBuildingOwner?.houseNo ?? ''
    );
    this.employerBusinessStreet.setValue(
      model.employerAddressOfBuildingOwner?.street ?? ''
    );
    this.employerBusinessLandMark.setValue(
      model.employerAddressOfBuildingOwner?.landmark ?? ''
    );
    this.employerBusinessCity.setValue(
      model.employerAddressOfBuildingOwner?.city ?? ''
    );
    this.employerBusinessArea.setValue(
      model.employerAddressOfBuildingOwner?.area ?? ''
    );
    this.buildingOwnerEmployerPhoneNumber.setValue(
      model.buildingOwnerEmployerPhoneNumber ?? ''
    );
    this.buildingOwnerEmail.setValue(model.buildingOwnerEmail ?? '');
    this.employerBusinessAreaId = model.employerAddressOfBuildingOwner.areaId;
    // 5. step props
    this.setFifthStepFromServer(model.loadRequirements ?? []);

    // 6. step props
    this.selectedNatureOfProperty = this.setNatureOfProperty(
      model.propertyNature
    );
    this.selectedtypeOfProperty = this.setTypeOfProperty(model.typeOfProperty);
    this.propertyHavePlanningPermission = model.hasPlanningPermissions ?? false;
    this.propertyHaveMeter = model.hasMeter ?? false;
    this.meterNumber6.setValue(model.meterNumber ?? '');
    this.customerAccountNumberForPremises.setValue(
      model.buildingOwnerAccountNumber ?? ''
    );
    this.estMaxLoad.setValue(model.estimatedMaxLoad);
    this.requestedVoltage.setValue(model.requestedVoltage);
    if (model.nameOfElectricalEngineer) {
      this.engineerNameDesktop.setValue(model.nameOfElectricalEngineer ?? '');
      this.engineerName.setValue(model.nameOfElectricalEngineer.split(' ')[0]);
      this.engineerLastName.setValue(
        model.nameOfElectricalEngineer.split(' ').slice(1).join(' ')
      );
    }

    // 7. step props
    this.engineerHouseNo.setValue(
      model.addressElectricalEngineer?.houseNo ?? ''
    );
    this.engineerStreet.setValue(model.addressElectricalEngineer?.street ?? '');
    this.engineerLandmark.setValue(
      model.addressElectricalEngineer?.landmark ?? ''
    );
    this.engineerCity.setValue(model.addressElectricalEngineer?.city ?? '');
    this.engineerArea.setValue(model.addressElectricalEngineer?.area ?? '');
    this.licenseNumber.setValue(model.licenseNumber ?? '');
    this.category.setValue(model.category ?? '');
    this.seventhStepDate.setValue(new Date(model.engineerDate ?? ''));
    this.engineerMeterNumber.setValue(
      model.meterNumberRequestingForChange ?? ''
    );
    this.customerAccountNumber.setValue(
      model.accountNumberRequestingForChange ?? ''
    );
    this.engineerAreaId = model.addressElectricalEngineer.areaId;
    // 8. step props
    this.customersSignitureUrl = model.customersSignitureUrl;
    this.landlordsSignitureUrl = model.landlordsSignitureUrl;
    this.electricalContractorsSignitureUrl =
      model.electricalContractorsSignitureUrl;
    this.applicantsSignitureUrl = model.applicantsSignitureUrl;
    this.passportUrl = model.passportUrl;
    this.customersSignitureName = model.customersSignitureName ?? '';
    this.landlordsSignitureName = model.landlordsSignitureName ?? '';
    this.electricalContractorsSignitureName =
      model.electricalContractorsSignitureName ?? '';
    this.applicantsSignitureName = model.applicantsSignitureName ?? '';
    this.passportName = model.passportName ?? '';
    this.isAgreed = model.debtAmountAcceptance ?? false;
    this.rejections = model.rejections;
  }

  setKycAccountDataForServer(): KycAccountData {
    // If value is a string then it is set from server, otherwise user has changed the value manually in phone input
    const isPhoneNumberStringOrNull =
      typeof this.phone.value === 'string' ||
      this.phone.value === null ||
      this.phone.value === undefined;
    const isOcupierPhoneNumberStringOrNull =
      typeof this.buildingOwnerPhoneNumber.value === 'string' ||
      this.buildingOwnerPhoneNumber.value === null ||
      this.buildingOwnerPhoneNumber.value === undefined;
    const isOcupierPhoneNumber2StringOrNull =
      typeof this.buildingOwnerEmployerPhoneNumber.value === 'string' ||
      this.buildingOwnerEmployerPhoneNumber.value === null ||
      this.buildingOwnerEmployerPhoneNumber.value === undefined;

    this.dialCode = isPhoneNumberStringOrNull
      ? this.dialCode
      : this.phone.value?.dialCode.replace('+', '') ?? null;
    this.buildingOwnerDialCode = isOcupierPhoneNumberStringOrNull
      ? this.buildingOwnerDialCode
      : this.buildingOwnerPhoneNumber.value?.dialCode.replace('+', '') ?? null;
    this.buildingOwnerEmployerDialCode = isOcupierPhoneNumber2StringOrNull
      ? this.buildingOwnerEmployerDialCode
      : this.buildingOwnerEmployerPhoneNumber.value?.dialCode.replace(
        '+',
        ''
      ) ?? null;

    const model: KycAccountData = {
      id: this.customerId ?? 0,
      currentPage: this.step !== 0 ? this.step : 1,
      // 1. step props
      title: Number(this.selectedTitle?.value),
      surname: this.surname.value,
      firstName: this.firstName.value,
      phoneNumber: isPhoneNumberStringOrNull
        ? this.phone.value
        : this.phone.value?.number,
      dialCode: this.dialCode,
      buildingOwnerDialCode: this.buildingOwnerDialCode,
      buildingOwnerEmployerDialCode: this.buildingOwnerEmployerDialCode,
      email: this.email.value,
      dateOfBirth: this.dateOfBirth.value,
      nameOfEmployer: this.employerName.value,
      employerAddress: {
        houseNo: this.houseNo.value,
        street: this.streetName.value,
        landmark: this.landmark.value,
        city: this.city.value,
        area: this.area.value,
        areaId: this.areaId != 0 ? this.areaId : null,
      },

      // 2. step props
      meterNumberLandlord: this.meterNumber?.value ?? '',
      accountNumberForPremissesLandlord: this.numberOfPremises?.value ?? '',
      identificationOfLandlord: isNaN(
        Number(this.selectedIdentificationOfLandlord?.value)
      )
        ? null
        : Number(this.selectedIdentificationOfLandlord?.value),
      personalIdNumberOfLandlord: this.personnelIDNumOfLandlord?.value ?? '',
      addressOfLandlord: {
        houseNo: this.supplyHouseNo.value,
        street: this.supplyStreetName.value,
        landmark: this.supplyLandmark.value,
        city: this.supplyCity.value,
        area: this.supplyArea.value,
        areaId: this.supplyAreaId != 0 ? this.supplyAreaId : null,
      },
      bankVerificationNumber: this.bankVerificationNumber?.value ?? '',
      typeOfPremises: isNaN(Number(this.selectedTypeOfPermises?.value))
        ? null
        : Number(this.selectedTypeOfPermises?.value),
      useOfPremises: isNaN(Number(this.selectedUseOfPermises?.value))
        ? null
        : Number(this.selectedUseOfPermises?.value),

      // 3. step props
      addressPreviousCustomer: {
        houseNo: this.previousCustomerHouseNo.value,
        street: this.previousCustomerStreet.value,
        landmark: this.previousCustomerLandMark.value,
        city: this.previousCustomerCity.value,
        area: this.previousCustomerArea.value,
        areaId:
          this.previousCustomerAreaId != 0 ? this.previousCustomerAreaId : null,
      },
      meterNumberPreviousCustomer: this.previousMeterNumber.value,
      accountNumberPreviousCustomer: this.previousAccountNumber.value,
      buildingOwner: this.buildingOwner.value,
      buildingOwnerAddress: {
        houseNo: this.buildingOwnerHouseNo.value,
        street: this.buildingOwnerStreet.value,
        landmark: this.buildingOwnerLandMark.value,
        city: this.buildingOwnerCity.value,
        area: this.buildingOwnerArea.value,
        areaId: this.buildingOwnerAreaId != 0 ? this.buildingOwnerAreaId : null,
      },

      // 4. step props
      identificationOfBuildingOwner: isNaN(
        Number(this.selectedBuildingOwnerIdentification?.value)
      )
        ? null
        : Number(this.selectedBuildingOwnerIdentification?.value),
      personalIdNumberOfBuildingOwner: this.personalIDNumber.value,
      nameOfEmployerOfBuildingOwner: this.nameOfEmployerOfBuildingOwner.value,
      employerAddressOfBuildingOwner: {
        houseNo: this.employerBusinessHouseNo.value,
        street: this.employerBusinessStreet.value,
        landmark: this.employerBusinessLandMark.value,
        city: this.employerBusinessCity.value,
        area: this.employerBusinessArea.value,
        areaId:
          this.employerBusinessAreaId != 0 ? this.employerBusinessAreaId : null,
      },
      buildingOwnerPhoneNumber: isOcupierPhoneNumberStringOrNull
        ? this.buildingOwnerPhoneNumber.value
        : this.buildingOwnerPhoneNumber.value?.number,
      buildingOwnerEmployerPhoneNumber: isOcupierPhoneNumber2StringOrNull
        ? this.buildingOwnerEmployerPhoneNumber.value
        : this.buildingOwnerEmployerPhoneNumber.value?.number,
      buildingOwnerEmail: this.buildingOwnerEmail.value,

      // 5. step props
      loadRequirements: this.setFifthStepForServer(),

      // 6. step props
      propertyNature: isNaN(Number(this.selectedNatureOfProperty?.value))
        ? null
        : Number(this.selectedNatureOfProperty?.value),
      typeOfProperty: isNaN(Number(this.selectedtypeOfProperty?.value))
        ? null
        : Number(this.selectedtypeOfProperty?.value),
      hasPlanningPermissions: this.propertyHavePlanningPermission,
      hasMeter: this.propertyHaveMeter,
      meterNumber: this.meterNumber6.value,
      buildingOwnerAccountNumber:
        this.customerAccountNumberForPremises.value ?? '',
      estimatedMaxLoad: isNaN(Number(this.estMaxLoad.value))
        ? null
        : Number(this.estMaxLoad.value),
      requestedVoltage: isNaN(Number(this.requestedVoltage.value))
        ? null
        : Number(this.requestedVoltage.value),

      // 7. step props
      addressElectricalEngineer: {
        houseNo: this.engineerHouseNo.value,
        street: this.engineerStreet.value,
        landmark: this.engineerLandmark.value,
        city: this.engineerCity.value,
        area: this.engineerArea.value,
        areaId: this.engineerAreaId != 0 ? this.engineerAreaId : null,
      },
      licenseNumber: this.licenseNumber.value,
      category: this.category.value,
      engineerDate: this.seventhStepDate.value,
      meterNumberRequestingForChange: this.engineerMeterNumber.value,
      accountNumberRequestingForChange: this.customerAccountNumber.value,

      // 8. step props // TODO:
      customersSignitureUrl: this.customersSignitureUrl,
      landlordsSignitureUrl: this.landlordsSignitureUrl,
      electricalContractorsSignitureUrl: this.electricalContractorsSignitureUrl,
      applicantsSignitureUrl: this.applicantsSignitureUrl,
      passportUrl: this.passportUrl,

      customersSignitureName: this.customersSignitureName,
      landlordsSignitureName: this.landlordsSignitureName,
      electricalContractorsSignitureName:
        this.electricalContractorsSignitureName,
      applicantsSignitureName: this.applicantsSignitureName,
      passportName: this.passportName,
      debtAmountAcceptance: this.isAgreed,
      rejections: this.rejections,
      profilePictureUrl: null,
    };

    if (this.engineerNameDesktop.value) {
      model.nameOfElectricalEngineer = this.engineerNameDesktop.value;
    } else if (this.engineerName.value || this.engineerLastName.value) {
      model.nameOfElectricalEngineer = `${this.engineerName.value} ${this.engineerLastName.value}`;
    }
    return model;
  }

  finish() { }
  handleGetRegionsForSelect(event: any) {
    this.regions = event.body.data.map(function (region: any) {
      return {
        value: region.id,
        displayValue: region.name,
      };
    });
    if (this.areas?.length > 0 && this.regions?.length > 0) {
      this.mapSelectedRegionAndAreas();
    }
  }
  handleGetAreasForSelect(event: any) {
    this.areas = event.body.data;
    this.shownAreas = event.body.data;
    this.shownPreviousCustomerAreas = event.body.data;
    if (this.areas?.length > 0 && this.regions?.length > 0) {
      this.mapSelectedRegionAndAreas();
    }
  }
  mapSelectedRegionAndAreas() {
    if (this.areaId != 0) {
      this.selectedArea = this.areas.find((x) => x.id === this.areaId)!;
      this.selectedRegion = this.regions.find(
        (x) => x.value === this.selectedArea?.regionId
      )!;
    }
    if (this.supplyAreaId != 0) {
      this.selectedSupplyArea = this.areas.find(
        (x) => x.id === this.supplyAreaId
      )!;
      this.selectedSupplyRegion = this.regions.find(
        (x) => x.value === this.selectedSupplyArea?.regionId
      )!;
    }
    if (this.buildingOwnerAreaId != 0) {
      this.selectedBuildingOwnerArea = this.areas.find(
        (x) => x.id === this.buildingOwnerAreaId
      )!;
      this.selectedBuildingOwnerRegion = this.regions.find(
        (x) => x.value === this.selectedBuildingOwnerArea?.regionId
      )!;
    }
    if (this.previousCustomerAreaId != 0) {
      this.selectedPreviousCustomerArea = this.areas.find(
        (x) => x.id === this.previousCustomerAreaId
      )!;
      this.selectedPreviousCustomerRegion = this.regions.find(
        (x) => x.value === this.selectedArea?.regionId
      )!;
    }
    if (this.employerBusinessAreaId != 0) {
      this.selectedEmployerBusinessArea = this.areas.find(
        (x) => x.id === this.employerBusinessAreaId
      )!;
      this.selectedEmployerBusinessRegion = this.regions.find(
        (x) => x.value === this.selectedEmployerBusinessArea?.regionId
      )!;
    }
    if (this.engineerAreaId != 0) {
      this.selectedEngineerArea = this.areas.find(
        (x) => x.id === this.engineerAreaId
      )!;
      this.selectedEngineerRegion = this.regions.find(
        (x) => x.value === this.selectedArea?.regionId
      )!;
    }
  }
  initState() {
    this.kycCustomerApiService.getCustomer().subscribe({
      next: this.handleGetKycAccountData.bind(this),
      error: this.handleError.bind(this),
    });
    this.regionService.getAllForSelectNoUser().subscribe({
      next: this.handleGetRegionsForSelect.bind(this),
      error: this.handleError.bind(this),
    });
    this.areaService.getAllForOptions().subscribe({
      next: this.handleGetAreasForSelect.bind(this),
      error: this.handleError.bind(this),
    });
  }
}
