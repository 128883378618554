import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from '../config/config';
import { Observable } from 'rxjs';
import { MeterStockItemStats } from '../models/meterStockItem.model';
import { ResponsePackage } from '../models/util.model';
import { NewMeterStockItemDto } from '../models/newMeterStockItemDto.model';

@Injectable({
  providedIn: 'root',
})
export class MeterManagementService {
  private pathApi = this.config.setting['pathApi'] + 'meterManagement/';
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(private http: HttpClient, private config: AppConfig) {}

  getAll(dataIn: any): Observable<any> {
    return this.http.post(`${this.pathApi}getAll`, dataIn);
  }

  getStats(obj: any) {
    return this.http.post<ResponsePackage<MeterStockItemStats>>(
      `${this.pathApi}getStats`,
      obj,
      { headers: this.headers, observe: 'response' }
    );
  }

  download(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}downloadFile`, obj, {
      headers: this.headers,
      observe: 'response',
    });
  }

  save(m: NewMeterStockItemDto): Observable<any> {
    return this.http.post(`${this.pathApi}save`, m, { observe: 'response' });
  }

  uploadFile(obj: any, utilityId: number): Observable<any> {
    return this.http.post(
      `${this.pathApi}uploadFile?utilityId=${utilityId}`,
      obj
    );
  }

  generateTemplate(utilityId: number): Observable<any> {
    return this.http.get(
      `${this.pathApi}generateTemplate?utilityId=${utilityId}`
    );
  }

  getAllUtilized(dataIn: any): Observable<any> {
    return this.http.post(`${this.pathApi}getAllUtilization`, dataIn);
  }

  downloadUtilized(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}downloadFileUtilization`, obj, {
      headers: this.headers,
      observe: 'response',
    });
  }
}
