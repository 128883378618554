import { Component, OnInit } from '@angular/core';
import { CustomerService } from 'src/app/services/customer.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { UploadFileInfoService } from 'src/app/services/uploadFileInfo.service';
import { UploadFileInfo, UploadFileType } from 'src/app/models/uploadFileInfo.model';
import { stat } from 'fs';

@Component({
  selector: 'upload-customers',
  templateUrl: './upload-customers.component.html',
  styleUrls: ['./upload-customers.component.scss']
})
export class UploadCustomersComponent implements OnInit {

  uploads: UploadFileInfo[];

  customersFile: File | null;
  file: string | ArrayBuffer ;
  action: string = '';
  pageSize: number = 3;
  currentPage: number = 1;
  count: number = 2;

  constructor(private customersService: CustomerService, private toastr: ToastrService, private commonService: CommonService, private uploadFileService: UploadFileInfoService) { }

  ngOnInit(): void {
    this.reloadTable();
  }

  fileBrowserHandler($event: any) {
   
    this.customersFile = $event.target.files.item(0);
    const reader = new FileReader();
    this.file = this.customersFile!.name;
    reader.readAsDataURL(this.customersFile!);
    
    this.handleUploadCustomersForm();
  }

  onFileDropped($event: any) {
    this.customersFile = $event.item(0);
    const reader = new FileReader();

    this.file = this.customersFile!.name;

    reader.readAsDataURL(this.customersFile!);
    this.handleUploadCustomersForm();
  }

  handleUploadCustomersForm() {
    let data = new FormData();
    data.append('file', this.customersFile!, this.customersFile?.name);

    // this.customersService.uploadFile(data).subscribe({
    //   next: (data: any) => {
    //     this.toastr.success(data.message);
    //     this.reloadTable();
    //     this.customersFile = null;
    //     this.file = '';
    //   },
    //   error: (error) => console.log(error) 
    // });
  }

  generateTemplate() {
    console.log('123121');
    // this.customersService.generateTemplate().subscribe({
    //   next: (data) => {
    //     if(data?.status === "OK" || data?.status === "200" ) {
    //       console.log(data.data);
    //       window.location.href = data.data;
    //     }else
    //       this.toastr.error(data?.message || 'Request failed');
    //   },
    //   error: (error) => console.log(error) 
    // });
  }

  getByType(obj: any) {
    this.uploadFileService.getByType(obj).subscribe({
      next: (response) => {
        if(response.status === "OK" || response.status === "200" ) {
          this.uploads = response.data.data;
          this.count = response.data.count;
        }else {
          this.toastr.error(response.message);
        }
      }
    });
  }

  getUploadStatusColor(status: string) {
    if(status === 'Completed') {
      return 'rgba(0, 136, 90, 1)';
    } else if(status == 'Processing') {
      return 'rgba(251, 159, 48, 1)';
    } else {
      return 'rgba(0, 0, 0, 1)';
    }
  }

  pageChange(value: any) {
    this.currentPage = value;
    this.reloadTable();
  }

  reloadTable(page: any = null){
    if(page)
      this.currentPage = page;

    let obj = {
      pageInfo:{
        page: this.currentPage,
        pageSize: this.pageSize,
      },
      UploadFileType: UploadFileType.Customers
    };

    this.getByType(obj);
  }
}
