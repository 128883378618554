<div class="container-fluid customers-root">
  <div class="stats-root">
    <div class="stats-item row regularized-tab">
      <div class="col-11 regularized">
        <div class="regularized-title">
          {{ "COMMON.TOTAL" | translate }}
          {{ "COMMON.MBC_DETAILS.METER_STOCK" | translate }}
        </div>
        <div *ngIf="stats.total == undefined" class="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div *ngIf="stats.total != undefined" class="count-number">
          {{ stats.total | number }}
        </div>
      </div>
      <div class="col-1">
        <div class="chevron" (click)="totalInfoOpen = !totalInfoOpen">
          <svg
            *ngIf="!totalInfoOpen"
            width="16"
            height="10"
            viewBox="0 0 16 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2.34292 0.757812L0.928711 2.17202L7.9998 9.24307L15.0708 2.17205L13.6566 0.757842L7.9998 6.41467L2.34292 0.757812Z"
              fill="black"
            />
          </svg>
          <svg
            *ngIf="totalInfoOpen"
            width="16"
            height="10"
            viewBox="0 0 16 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2.34292 9.24219L0.928711 7.82798L7.9998 0.756927L15.0708 7.82799L13.6566 9.2422L7.9998 3.58537L2.34292 9.24219Z"
              fill="black"
            />
          </svg>
        </div>
      </div>
      <div class="total-stats-root" *ngIf="totalInfoOpen">
        <div class="stats-item regularized-extra-info">
          <div>{{ "COMMON.MBC_DETAILS.SINGLE_PHASE" | translate }}</div>
          <div *ngIf="stats.singlePhase != undefined" class="count-number">
            <span class="revenue">{{ stats.singlePhase | number }}</span>
          </div>
        </div>
        <div class="stats-item regularized-extra-info">
          <div>{{ "COMMON.MBC_DETAILS.THREE_PHASE" | translate }}</div>
          <div *ngIf="stats.threePhase != undefined" class="count-number">
            <span class="revenue">{{ stats.threePhase | number }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="stats-item">
      <div>
        {{ "COMMON.TOTAL" | translate }}
        {{ "COMMON.MBC_DETAILS.IN_STORAGE" | translate }}
      </div>
      <div *ngIf="stats.inStorage == undefined" class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div *ngIf="stats.inStorage != undefined" class="count-number">
        {{ stats.inStorage | number }}
      </div>
    </div>
    <div class="stats-item">
      <div>
        {{ "COMMON.TOTAL" | translate }}
        {{ "COMMON.MBC_DETAILS.INSTALLED" | translate }}
      </div>
      <div *ngIf="stats.installed == undefined" class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div *ngIf="stats.installed != undefined" class="count-number">
        {{ stats.installed | number }}
      </div>
    </div>
    <div class="stats-item">
      <div>{{ "COMMON.MBC_DETAILS.AVERAGE_AGE" | translate }}</div>
      <div *ngIf="stats.averageAge == undefined" class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div *ngIf="stats.averageAge != undefined" class="count-number">
        {{ stats.averageAge | number }}
      </div>
    </div>
  </div>

  <div class="table-root">
    <div
      class="table-action-wrapper d-flex align-items-center justify-content-between"
    >
      <div class="d-flex searches">
        <div class="search-input">
          <img
            src="../../assets/icons/search.svg"
            alt="Search icon"
            (click)="search()"
          />
          <input
            type="text"
            [placeholder]="'COMMON.SEARCH' | translate"
            [(ngModel)]="searchText"
            (keydown.enter)="search()"
            (ngModelChange)="this.searchTextUpdate.next($event)"
          />
        </div>
        <div class="search-type-picker">
          <div [title]="selectedSearchTypeDisplay | translate"
            class="dropdown"
            (click)="showSelectSearchType = !showSelectSearchType"
            click-stop-propagation
          >
            <span>{{ "COMMON.BY" | translate }}:</span>
            <span>{{ selectedSearchTypeDisplay | translate }}</span>
            <span class="arrow"
              ><img
                src="../../assets/icons/gray-arrow-down.svg"
                alt="Gray arrow down"
            /></span>
          </div>
          <div class="dropdown-opened" *ngIf="showSelectSearchType">
            <div
              (click)="selectSearchType(searchType)"
              *ngFor="let searchType of searchByOptions"
            >
              <div>{{ searchType.translation | translate }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="ml-auto d-flex">
        <div>
          <div class="d-flex">
            <div class="datetype-picker">
              <div [title]="selectedDateTypeDisplay | translate"
                class="dropdown date-type-dropdown"
                (click)="showSelectDateType = !showSelectDateType"
                click-stop-propagation
              >
                <span>{{ "COMMON.DATE_TYPE.TITLE" | translate }}:</span>
                <span style="margin-right: 0.5rem;">{{ selectedDateTypeDisplay | translate }}</span>
                <span class="arrow"
                  ><img
                    src="../../assets/icons/gray-arrow-down.svg"
                    alt="Gray arrow down"
                /></span>
              </div>
              <div class="dropdown-opened" *ngIf="showSelectDateType">
                <div
                  (click)="
                    selectDateType(dateType);
                    datesValid() == true && (dateTo || dateFrom) && search()
                  "
                  *ngFor="let dateType of dateTypes"
                >
                  <div>{{ dateType.translation | translate }}</div>
                </div>
              </div>
            </div>
            <div class="datepicker-container" style="width: 23rem;">
              <label> {{ "COMMON.FROM" | translate }}: </label>
              <input
                type="date"
                id="dateFrom"
                name="dateFrom"
                [ngClass]="
                  !datesValid()
                    ? 'btn datepicker-icon-error'
                    : 'btn datepicker-icon'
                "
                [(ngModel)]="dateFrom"
                (change)="datesValid() == true && search()"
              />
            </div>

            <div class="datepicker-container">
              <label class="right-label"> {{ "COMMON.TO" | translate }}: </label>
              <input
                type="date"
                id="dateTo"
                name="dateTo"
                [ngClass]="
                  !datesValid()
                    ? 'btn datepicker-icon-error'
                    : 'btn datepicker-icon'
                "
                [(ngModel)]="dateTo"
                (change)="datesValid() == true && search()"
              />
            </div>
          </div>
        </div>
        <button class="btn btn-icon" (click)="download()">
          <span>{{ "COMMON.DOWNLOAD" | translate }}</span>
        </button>
        <ng-container
          *ngIf="permissionsService.isMeterStockInventoryCreateAllowed"
        >
          <div>
            <button
              class="btn btn-primary btn-upload"
              (click)="showUpload = !showUpload"
              click-stop-propagation
            >
              <img
                src="../../assets/icons/chevron-down.svg"
                alt="Chevron down"
              />
            </button>
            <div
              *ngIf="showUpload"
              class="xlsx-upload"
              [routerLink]="'/upload/10/' + utilityId"
            >
              <div><img src="../../assets/icons/upload-arrow.svg" /></div>
              <div>{{ "COMMON.UPLOAD_XLSX" | translate }}</div>
            </div>
          </div>
          <button
            (click)="initializeNewMeter()"
            class="btn btn-primary btn-add-new"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasRight"
            aria-controls="offcanvasRight"
          >
            <!-- routerLink="add" -->
            <img src="../../assets/icons/plus.svg" alt="Plus icon" />
            <span>{{ "METERS.NEW" | translate }}</span>
          </button>
        </ng-container>
      </div>
    </div>
    <div class="filter">
      <div class="filter-list">
        {{ "COMMON.FILTER" | translate }}
        <app-multi-select-dropdown
          class="multiselect"
          [clicked]="clicked"
          [items]="regions"
          [label]="'COMMON.BU.PLURAL'"
          [showMore]="false"
          [requireApply]="true"
          (shareIndividualCheckedList)="selectRegion($event)"
          (applyFilterEmitter)="reloadTable(1)"
        >
        </app-multi-select-dropdown>
        <app-multi-select-dropdown
          class="multiselect"
          [clicked]="clicked"
          [items]="areas"
          [label]="'COMMON.UT.PLURAL'"
          [showMore]="false"
          [requireApply]="true"
          (shareIndividualCheckedList)="selectArea($event)"
          (applyFilterEmitter)="reloadTable(1)"
        >
        </app-multi-select-dropdown>

        <select
          name="ct-select"
          (change)="selectCustomerType($event)"
          class="form-select"
        >
          <option value="" [selected]="!customerType" disabled>
            {{ "COMMON.MBC_DETAILS.METER_TYPE.SINGLE" | translate }}
          </option>
          <option
            [selected]="customerType == o.value"
            *ngFor="let o of customerTypeOptions"
            value="{{ o.value }}"
          >
            {{ o.translation | translate }}
          </option>
        </select>
        <select
          name="ct-select"
          (change)="selectMeterPhase($event)"
          class="form-select"
        >
          <option value="" [selected]="!meterPhase" disabled>
            {{ "COMMON.MBC_DETAILS.METER_PHASE" | translate }}
          </option>
          <option
            [selected]="meterPhase == o.value"
            *ngFor="let o of meterPhaseOptions"
            value="{{ o.value }}"
          >
            {{ o.translation | translate }}
          </option>
        </select>
      </div>
      <div *ngIf="selectedFilters.length > 0">
        <app-filter-checklist
          [filters]="selectedFilters"
          (resetFilterEmitter)="resetFilter($event)"
          (removeFilterEmitter)="removeFilter($event)"
        >
        </app-filter-checklist>
      </div>
    </div>
    <div class="table">
      <div *ngIf="meterStockItems">
        <!-- Add property later -->
        <table class="table border-bottom">
          <thead class="table-light">
            <tr>
              <th>
                <input
                  type="checkbox"
                  class="form-check-input"
                  [ngModel]="allCheckboxesChecked"
                  (click)="checkAllCheckboxes()"
                />
              </th>
              <th>{{ "COMMON.MBC_DETAILS.METER_NO" | translate }}</th>
              <th>{{ "COMMON.MBC_DETAILS.METER_PHASE" | translate }}</th>
              <th>{{ "COMMON.MBC_DETAILS.SERIAL_NUMBER" | translate }}</th>
              <th>{{ "COMMON.MBC_DETAILS.METER_MAKE" | translate }}</th>
              <th>{{ "COMMON.MBC_DETAILS.METER_TYPE.SINGLE" | translate }}</th>
              <th>{{ "COMMON.MBC_DETAILS.METER_LIFESPAN" | translate }}</th>
              <th>{{ "COMMON.MBC_DETAILS.METER_AGE" | translate }}</th>
              <th>{{ "COMMON.MBC_DETAILS.UPLOAD_DATE" | translate }}</th>
              <th>{{ "COMMON.MBC_DETAILS.DISPATCH_DATE" | translate }}</th>
              <th>{{ "COMMON.BU.SINGLE" | translate }}</th>
              <th>{{ "COMMON.UT.SINGLE" | translate }}</th>
              <th>{{ "COMMON.MBC_DETAILS.INSTALLATION_DATE" | translate }}</th>
              <th>{{ "COMMON.UPLOADED_BY" | translate }}</th>
              <th>{{ "COMMON.STATUS" | translate }}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let m of meterStockItems; let i = index">
              <td>
                <input
                  type="checkbox"
                  class="form-check-input"
                  [ngModel]="m.isChecked"
                  (click)="checkMeterStockItem(m.id)"
                />
              </td>
              <td>
                {{ m.meterNumber | hasValue }}
              </td>
              <td>
                {{ m.meterPhase | hasValue }}
              </td>
              <td>
                {{ m.serialNumber | hasValue }}
              </td>
              <td>
                {{ m.meterMake | hasValue }}
              </td>
              <td>
                {{ m.meterType | hasValue }}
              </td>
              <td>
                {{ m.lifespan | hasValue | year }}
              </td>
              <td>
                <div class="stock-status {{ getAgeClass(m.lifespan, m.age) }}">
                  {{ m.age | hasValue | year }}
                </div>
              </td>
              <td>
                {{
                  m.uploadDate ? (m.uploadDate | date : "dd/MM/yyyy") : "----"
                }}
              </td>
              <td>
                {{
                  m.dispatchDate
                    ? (m.dispatchDate | date : "dd/MM/yyyy")
                    : "----"
                }}
              </td>
              <td>
                {{ m.region | hasValue }}
              </td>
              <td>
                {{ m.area | hasValue }}
              </td>
              <td>
                {{
                  m.installationDate
                    ? (m.installationDate | date : "dd/MM/yyyy")
                    : "----"
                }}
              </td>
              <td>
                {{ m.uploadedBy | hasValue }}
              </td>
              <td>
                {{ m.status | hasValue }}
              </td>
              <td class="more-icon">
                <img
                  src="../../assets/icons/more-icon.svg"
                  alt="More icon"
                  class="more-icon"
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div>
          <app-paging
            *ngIf="count > 0"
            [pageSize]="pageSize"
            [count]="count"
            [currentPage]="currentPage"
            (pageChange)="pageChange($event)"
          ></app-paging>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="offcanvas offcanvas-end"
  tabindex="-1"
  id="offcanvasRight"
  aria-labelledby="offcanvasRightLabel"
>
  <div class="offcanvas-header">
    <h3 id="offcanvasRightLabel">
      {{ "COMMON.MBC_DETAILS.METER_INFO" | translate }}
    </h3>
    <button
      type="button"
      class="btn-close text-reset"
      data-bs-dismiss="offcanvas"
      aria-label="Close"
      #closeCanvas
    ></button>
  </div>
  <div class="offcanvas-body">
    <div class="canvas-form">
      <label for="meter-number">{{
        "COMMON.MBC_DETAILS.METER_NO" | translate
      }}</label>
      <input
        class="form-control form-control-lg mb-4"
        [(ngModel)]="newMeter.meterNumber"
        name="mn"
        type="text"
        id="meter-number"
        placeholder=""
        required
      />
    </div>

    <div class="canvas-form">
      <label for="meter-phase">{{
        "COMMON.MBC_DETAILS.METER_PHASE" | translate
      }}</label>
      <select
        name="mp-select"
        id="meter-phase"
        class="form-select form-select-lg mb-4"
        [(ngModel)]="newMeter.meterPhase"
      >
        <option
          [ngValue]="undefined"
          [selected]="!newMeter.meterPhase"
          value=""
          disabled
        >
          {{ "COMMON.SELECT_OPTION" | translate }}
        </option>
        <option
          [selected]="newMeter.meterPhase == o.value"
          *ngFor="let o of meterPhaseOptions"
          [ngValue]="o.value"
        >
          {{ o.name }}
        </option>
      </select>
    </div>

    <div class="canvas-form">
      <label for="meter-make">Meter make</label>
      <select
        name="mm-select"
        id="meter-make"
        class="form-select form-select-lg mb-4"
        [(ngModel)]="newMeter.meterMakeId"
      >
        <option [selected]="newMeter.meterMakeId == 0" [ngValue]="0" disabled>
          {{ "COMMON.SELECT_OPTION" | translate }}
        </option>
        <option
          [selected]="newMeter.meterMakeId == option.key"
          *ngFor="let option of meterBrandOptions"
          [ngValue]="option.key"
        >
          {{ option.value }}
        </option>
      </select>
    </div>

    <div class="canvas-form">
      <label for="meter-type">{{
        "COMMON.MBC_DETAILS.METER_TYPE.SINGLE" | translate
      }}</label>
      <select
        name="mt-select"
        id="meter-type"
        class="form-select form-select-lg mb-4"
        [(ngModel)]="newMeter.meterType"
      >
        <option [ngValue]="undefined" [selected]="!newMeter.meterType" disabled>
          {{ "COMMON.SELECT_OPTION" | translate }}
        </option>
        <option
          *ngFor="let o of customerTypeOptions"
          [ngValue]="o.value"
          [selected]="newMeter.meterType === o.value"
        >
          {{ o.name }}
        </option>
      </select>
    </div>

    <div class="canvas-form">
      <label for="destination-region">{{
        "COMMON.MBC_DETAILS.DESTINATION_REGION" | translate
      }}</label>
      <select
        name="dr-select"
        id="destination-region"
        class="form-select form-select-lg mb-4"
        [(ngModel)]="newMeter.regionId"
      >
        <option [selected]="newMeter.regionId == 0" [ngValue]="0" disabled>
          {{ "COMMON.SELECT_OPTION" | translate }}
        </option>
        <option
          [selected]="newMeter.regionId == r.id"
          *ngFor="let r of regions"
          [ngValue]="r.id"
        >
          {{ r.name }}
        </option>
      </select>
    </div>

    <div class="canvas-form">
      <label for="lifespan">{{
        "COMMON.MBC_DETAILS.LIFESPAN" | translate
      }}</label>
      <input
        class="form-control form-control-lg mb-4"
        [(ngModel)]="newMeter.lifespan"
        name="ls"
        type="number"
        id="lifespan"
        placeholder=""
        required
      />
    </div>

    <div class="date-picker-container">
      <label for="dispatch-date">{{
        "COMMON.MBC_DETAILS.DATE_OF_DISPATCH_TO_REGION" | translate
      }}</label>
      <div class="date-picker-wrapper mb-4">
        <input
          type="date"
          id="dispatch-date"
          class="date-input form-control"
          name="dd"
          [(ngModel)]="newMeter.dispatchDate"
        />
      </div>
    </div>

    <button class="btn btn-primary submit-btn" (click)="submitMeterStockItem()">
      {{ "COMMON.SUBMIT" | translate }}
    </button>
  </div>
</div>
