<div class="page-header d-flex justify-content-end">
    <a class="btn btn-outline-danger" [routerLink]="['/profile']" >{{'COMMON.CANCEL' | translate}}</a>
    <button class="btn btn-primary" form="userForm" style="width: auto;">{{'COMMON.SAVE' | translate}}</button>
 </div>
<div class="container-fluid page update-password">
    <div class="page-title">
      <p>{{'HEADER_BREADCRUMB.UPDATE_PASSWORD' | translate}}</p>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="card card-div">
          <form ngNativeValidate class="form-wrapper" id="userForm" (submit)="handleFormSubmit()">
            <div class="input form-item form-floating mb-3">
              <input class="w-100 form-control" [type]="showPwCurrent ? 'text' : 'password'" name="currentPassword" [(ngModel)]="currentPassword" placeholder=" " required>
              <div class="show-pw" (click)="showPasswordCurrent()">
                <ng-template [ngIf]="showPwCurrent">
                  <img src="../../../assets/icons/eye.svg" alt="Show password icon">
                </ng-template>
                <ng-template [ngIf]="!showPwCurrent">
                  <img src="../../../assets/icons/eye-slash.svg" alt="Show password icon">
                </ng-template>
              </div>
              <label for="floatingInput">{{'UPDATE_PASSWORD.CURRENT' | translate}}</label>
            </div>
            <div class="input form-item form-floating">
              <input class="w-100 form-control" [type]="showPwNew ? 'text' : 'password'" name="newPassword" [(ngModel)]="newPassword" placeholder=" " required>
              <div class="show-pw" (click)="showPasswordNew()">
                <ng-template [ngIf]="showPwNew">
                  <img src="../../../assets/icons/eye.svg" alt="Show password icon">
                </ng-template>
                <ng-template [ngIf]="!showPwNew">
                  <img src="../../../assets/icons/eye-slash.svg" alt="Show password icon">
                </ng-template>
              </div>
              <label for="floatingInput">{{'UPDATE_PASSWORD.NEW' | translate}}</label>
            </div>
            <div class="input form-item form-floating">
              <input class="w-100 form-control" [type]="showPwConfirm ? 'text' : 'password'" name="confrimPassword" [(ngModel)]="confirmPassword" placeholder=" " required>
              <div class="show-pw" (click)="showPasswordConfirm()">
                <ng-template [ngIf]="showPwConfirm">
                  <img src="../../../assets/icons/eye.svg" alt="Show password icon">
                </ng-template>
                <ng-template [ngIf]="!showPwConfirm">
                  <img src="../../../assets/icons/eye-slash.svg" alt="Show password icon">
                </ng-template>
              </div>
              <label for="floatingInput">{{'UPDATE_PASSWORD.CONFIRM' | translate}}</label>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>