export class HistoryOutput {
    public UserId: number = 0;
    public UpdateDescription: string = ""; 
    public ServiceOrderUpload: string = "";

    constructor(data?: any) {
        this.UserId = data?.UserId ?? 0;
        this.UpdateDescription = data?.UpdateDescription ?? '';
        this.ServiceOrderUpload = data?.ServiceOrderUpload ?? '';
    }
}

export class HistoryInput {
    public Date: Date;
    public Username: string = '';
    public UpdateDescription: string = ""; 
    public ServiceOrderUpload: string = "";

    constructor(data?: any) {
        this.Date = data?.Date;
        this.Username = data?.Username ?? '';
        this.UpdateDescription = data?.UpdateDescription ?? '';
        this.ServiceOrderUpload = data?.ServiceOrderUpload ?? '';
    }
}