import { Component, EventEmitter, Input, Output, SimpleChange } from '@angular/core';
import { KeyValueItem } from 'src/app/models/keyValueItem.model';

@Component({
  selector: 'app-card-analytics',
  templateUrl: './card-analytics.component.html',
  styleUrls: ['./card-analytics.components.scss'],
  
})
export class CardAnalyticsComponent {
  @Input() stats: KeyValueItem[] = [];
  @Input() reset: boolean;
  @Output() shareFilterValue = new EventEmitter();
  public dropdownOpened: boolean = false;

  constructor() { }

  filterByValue: string = '';

  onFilterBy(option: any, IsClickable : boolean){
    if(IsClickable){
      this.filterByValue = option;
      this.shareFilterValue.emit(this.filterByValue);}
  }

  ngOnChanges(changes: { [property: string]: SimpleChange }) {
    if(this.stats.length > 0){
      this.filterByValue = this.stats[0].key;
    }
  }
}
