import {Component,Input,Output,EventEmitter, SimpleChange, OnInit} from '@angular/core';


@Component({
  selector: 'app-multi-select-dropdown',
  templateUrl: './multi-select-dropdown.component.html',
  styleUrls: ['./multi-select-dropdown.component.scss']
})
export class MultiSelectDropdownComponent {
  @Input() clicked:any; 
  @Input() items:any[]; 
  @Input() label:string;
  @Input() showMore:boolean;
  @Input() requireApply:boolean;
  @Input() searchFilterActive: boolean | null;
  @Input() borderRadius: string = '0.8rem';
  @Input() alternateLook: boolean = false;

  @Output() shareCheckedList = new EventEmitter();
  @Output() shareIndividualCheckedList = new EventEmitter();
  @Output() applyFilterEmitter = new EventEmitter();
  @Output() filterValue = new EventEmitter();
  
  
  checkedList : any;
  currentSelected : any;
  seletedLabel : any;
  public searchValue: any;

  constructor() {
    this.checkedList = [];
    this.label = "Select"
  }

  applySearchFilter(value: any){
    this.filterValue.emit(value);
  }

  getSelectedValue(status:Boolean,value:String, id: number){
    if(status){
      this.checkedList.push(value);  
    }else{
        var index = this.checkedList.indexOf(value);
        this.checkedList.splice(index,1);
    }

    this.seletedLabel = this.checkedList.join(', ');
    this.currentSelected = {checked : status,name:value, id: id};

    //share checked list
    this.shareCheckedlist();
    
    //share individual selected item
    this.shareIndividualStatus();
  }

  shareCheckedlist(){
    this.shareCheckedList.emit(this.checkedList);
  }

  shareIndividualStatus(){
    this.shareIndividualCheckedList.emit(this.currentSelected);
  }

  applyFilter() {
    this.applyFilterEmitter.emit();
  }

  ngOnChanges(changes: { [property: string]: SimpleChange }) {
    this.checkedList = this.items.filter(x => x.checked).map(x => x.name);
    this.seletedLabel = this.checkedList.join(', ');
  }
}