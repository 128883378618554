import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SurveyDetailsPage } from 'src/app/models/survey.model';
import { SurveyService } from 'src/app/services/survey.service';
import { DtService } from 'src/app/services/dt.service';
import { MeterService } from 'src/app/services/meter.service';
import { PermissionsEnum } from 'src/app/models/role.model';
import { TranslationService } from 'src/app/services/translation.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { OptionObj } from 'src/app/models/util.model';

@Component({
  selector: 'app-survey-details',
  templateUrl: './survey-details.component.html',
  styleUrls: ['./survey-details.component.scss'],
})
export class SurveyDetailsComponent implements OnInit {
  public PermissionsEnum = PermissionsEnum;

  id: string = '';
  selectedSurvey: SurveyDetailsPage = new SurveyDetailsPage();
  selectedImage: any;
  rejectionNote: string = '';
  editEnabled: boolean = false;

  dtOptions: OptionObj[] = [];
  meterBrandOptions: OptionObj[] = [];
  buName: string = this.translationService.getByKeyFromStorage('BU');
  utName: string = this.translationService.getByKeyFromStorage('UT');
  contractNumber: string =
    this.translationService.getByKeyFromStorage('Contract number');
  imgElement: any;
  imgZoomFullContainer: any;
  currentRotation: number = 0;
  isGhanaPostFieldsVisible: boolean = true;
  isMapVisible: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private surveyService: SurveyService,
    private toastr: ToastrService,
    public permissionsService: PermissionsService,
    private dtService: DtService,
    private meterService: MeterService,
    private translationService: TranslationService,
    private el: ElementRef,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.setFieldsVisibility();
    this.id = this.route.snapshot.paramMap.get('id') || '';
    this.imgElement = this.el?.nativeElement?.querySelector(
      '.ngxImageZoomThumbnail'
    );
    this.imgZoomFullContainer =
      this.el?.nativeElement?.querySelector('.ngxImageZoomFull');

    this.getSelectedSurvey(parseInt(this.id));
    this.getMeterBrandsForOptions();
  }

  getMeterBrandsForOptions() {
    this.meterService.getMeterTypesForOptions().subscribe(
      (response) => {
        this.meterBrandOptions = response.body.data;
      },
      (error) => {
        this.toastr.error('An error occurred.');
      }
    );
  }

  getSelectedSurvey(id: number) {
    this.surveyService.getDetailsById(id).subscribe(
      (data) => {
        this.selectedSurvey = data.body!.data;
        if (this.selectedSurvey.dt.feederId)
          this.getDtsForOptions(parseInt(this.selectedSurvey.dt.feederId));
        console.log(this.selectedSurvey);
      },
      (error) => {
        this.toastr.error('An error ocurred.');
      }
    );
  }

  showImage(num: number) {
    if (num === 8) {
      this.selectedImage = this.getMeterNumber();
    } else if (num === 7) {
      this.selectedImage = this.getCustomersSignature();
    } else if (num == 6) {
      this.selectedImage = this.getFieldRepSignature();
    } else if (num == 5) {
      this.selectedImage = this.getReceipts();
    } else if (num == 4) {
      this.selectedImage = this.getRecommendedMeterPoint();
    } else if (num == 3) {
      this.selectedImage = this.getExistingMeterPoint();
    } else if (num == 2) {
      this.selectedImage = this.getServiceCable();
    } else if (num == 1) {
      this.selectedImage = this.getFrontView();
    }
  }

  approve() {
    this.surveyService.approveSurvey(parseInt(this.id)).subscribe(
      (data) => {
        this.toastr.success('Survey Approved');
        window.location.reload();
      },
      (error) => {
        this.toastr.error('An error ocurred.');
      }
    );
  }
  reject() {
    let obj = {
      id: parseInt(this.id),
      note: this.rejectionNote,
    };
    this.surveyService.rejectSurvey(obj).subscribe(
      (data) => {
        this.toastr.success('Survey Rejected');
        window.location.reload();
      },
      (error) => {
        this.toastr.error('An error ocurred.');
      }
    );
  }

  validate() {
    let obj = {
      id: parseInt(this.id),
      customer: this.selectedSurvey.customer,
    };
    this.surveyService.validateSurvey(obj).subscribe(
      (data) => {
        this.toastr.success('Survey Validated');
        window.location.reload();
      },
      (error) => {
        this.toastr.error('An error ocurred.');
      }
    );
  }

  getCustomersSignature() {
    return this.selectedSurvey.images?.find(
      (x) => x.imageType === 'CustomerSignature'
    )?.url;
  }
  getFieldRepSignature() {
    return this.selectedSurvey.images?.find(
      (x) => x.imageType === 'FieldRepSignature'
    )?.url;
  }
  getMeterNumber() {
    return this.selectedSurvey.images?.find(
      (x) => x.imageType === 'MeterNumber'
    )?.url;
  }
  getReceipts() {
    return this.selectedSurvey.images?.find(
      (x) => x.imageType === 'BillOrPaymentReceipts'
    )?.url;
  }
  getRecommendedMeterPoint() {
    return this.selectedSurvey.images?.find(
      (x) => x.imageType === 'RecommendedMeteringPoint'
    )?.url;
  }
  getExistingMeterPoint() {
    return this.selectedSurvey.images?.find(
      (x) => x.imageType === 'ExistingMeteringPoint'
    )?.url;
  }
  getFrontView() {
    return this.selectedSurvey.images?.find((x) => x.imageType === 'Frontview')
      ?.url;
  }
  getServiceCable() {
    return this.selectedSurvey.images?.find(
      (x) => x.imageType === 'ServiceCableFromPole'
    )?.url;
  }

  getDtsForOptions(feederId: number) {
    this.dtService
      .getAllForSelectByFeeder(
        feederId,
        this.selectedSurvey.dt?.feederType?.toString()
      )
      .subscribe(
        (response) => {
          this.dtOptions = response.body.data;
        },
        (error) => {
          this.toastr.error('An error occurred.');
        }
      );
  }

  edit() {
    this.editEnabled = !this.editEnabled;
  }

  onModalFade() {
    this.currentRotation = 0;

    if (this.imgZoomFullContainer) {
      var displayValue = getComputedStyle(this.imgZoomFullContainer).display;

      if (displayValue == 'block') {
        this.imgElement.click();
      }
    }
  }

  rotateLeft() {
    this.currentRotation -= 90;
  }

  rotateRight() {
    this.currentRotation += 90;
  }

  onModalOpen() {
    this.renderer.setStyle(document.body, 'overflow', 'auto');
    this.renderer.setStyle(document.body, 'overflow-x', 'hidden');
    this.renderer.setStyle(document.body, 'padding-right', '0');
  }

  setFieldsVisibility() {
    let notVisibleFields: any = localStorage.getItem('notVisibleFields');

    if (!notVisibleFields) {
      return;
    }

    notVisibleFields = JSON.parse(notVisibleFields);
    this.isGhanaPostFieldsVisible = notVisibleFields.find(
      (el: any) => el === 'ghanaPostCode'
    )
      ? false
      : true;
  }
}
