<div class="container-fluid regular-tab-content">
  <div class="green-table">
    <div
      class="table-action-wrapper d-flex align-items-center justify-content-between"
    >
      <div class="search-input">
        <img
          src="../../assets/icons/search.svg"
          alt="Search icon"
          (click)="search()"
        />
        <input
          class="smaller-font"
          type="text"
          placeholder="Search"
          [(ngModel)]="searchText"
          (keydown.enter)="search()"
          (ngModelChange)="this.searchTextUpdate.next($event)"
        />
      </div>
      <div class="ml-auto d-flex">
        <div>
          <div class="d-flex">
            <div class="datetype-picker">
              <div
                class="dropdown smaller-font"
                (click)="showSelectDateType = !showSelectDateType"
                click-stop-propagation
              >
                <span class="smaller-font">{{ "COMMON.DATE_TYPE.TITLE" | translate }}:</span>
                <span class="smaller-font">{{ selectedDateTypeDisplay }}</span>
                <i class="arrow-custom --small down"></i>
              </div>
              <div class="dropdown-opened" *ngIf="showSelectDateType">
                <div
                  (click)="
                    selectDateType(dateType);
                    datesValid() == true && (dateTo || dateFrom) && search()
                  "
                  *ngFor="let dateType of dateTypes"
                >
                  <div>{{ dateType.value }}</div>
                </div>
              </div>
            </div>
            <div class="datepicker-box space-right smaller-font">
              <label class="smaller-font" for="dateFrom"> {{ "COMMON.FROM" | translate }}: </label>
              <input
                onclick="this.showPicker()"
                type="date"
                id="dateFrom"
                name="dateFrom"
                [ngClass]="
                  !datesValid() ? 'datepicker-input-error' : 'datepicker-input'
                "
                [(ngModel)]="dateFrom"
                (change)="datesValid() == true && search()"
              />
              <i class="arrow-custom --small down"></i>
            </div>

            <div class="datepicker-box space-right smaller-font">
              <label for="dateTo" class="right-label smaller-font">
                {{ "COMMON.TO" | translate }}:
              </label>
              <input
                onclick="this.showPicker()"
                type="date"
                id="dateTo"
                name="dateTo"
                [ngClass]="
                  !datesValid() ? 'datepicker-input-error' : 'datepicker-input'
                "
                [(ngModel)]="dateTo"
                (change)="datesValid() == true && search()"
              />
              <i class="arrow-custom --small down"></i>
            </div>
          </div>
        </div>
        <div
          class="sort-by-root"
          (click)="showSortBy = !showSortBy"
          click-stop-propagation
        >
          <div class="dropdown">
            <span class="smaller-font">Sort by:</span>
            <span class="smaller-font">{{ sortByName }}</span>
            <i class="arrow-custom --small down"></i>
          </div>
          <div class="dropdown-opened" *ngIf="showSortBy">
            <div (click)="sortBy(sortByOptions.ID)">
              {{ "COMMON.SORT_BY.ID" | translate }}
            </div>
            <div (click)="sortBy(sortByOptions.NEWEST)">
              {{ "COMMON.SORT_BY.NEWEST" | translate }}
            </div>
            <div (click)="sortBy(sortByOptions.SLRN_ASC)">
              {{ "COMMON.SORT_BY.SLRN_ASC" | translate }}.
            </div>
            <div (click)="sortBy(sortByOptions.SLRN_DESC)">
              {{ "COMMON.SORT_BY.SLRN_DESC" | translate }}.
            </div>
          </div>
        </div>

        <button class="button primary--white four-radius" (click)="download()">
          <span class="smaller-font">{{ "COMMON.DOWNLOAD" | translate }}</span>
        </button>
      </div>
    </div>
    <div class="filter">
      <div class="filter-list smaller-font">
        <span class="label">{{ "COMMON.FILTER" | translate }}</span>
        <app-multi-select-dropdown
          class="multiselect"
          [clicked]="clicked"
          [borderRadius]="'0.4rem'"
          [items]="regions"
          [label]="buName + 's'"
          [showMore]="false"
          [requireApply]="true"
          (shareIndividualCheckedList)="selectRegion($event)"
        >
        </app-multi-select-dropdown>
        <app-multi-select-dropdown
          class="multiselect"
          [clicked]="clicked"
          [borderRadius]="'0.4rem'"
          [items]="areas"
          [label]="utName + 's'"
          [showMore]="false"
          [requireApply]="true"
          (shareIndividualCheckedList)="selectArea($event)"
        >
        </app-multi-select-dropdown>

        <select
          name="st-select"
          (change)="selectUserType($event)"
          class="form-select"
        >
          <option value="" [selected]="!userType" disabled>
            {{ "COMMON.USER.TYPE.TITLE" | translate }}
          </option>
          <option
            [selected]="userType == o.value"
            *ngFor="let o of userTypeOptions"
            value="{{ o.value }}"
          >
            {{ o.name }}
          </option>
        </select>

        <app-multi-select-dropdown
          class="multiselect"
          [searchFilterActive]="true"
          [borderRadius]="'0.4rem'"
          (filterValue)="applySearchFilter($event)"
          [clicked]="clicked"
          [items]="filteredUsers"
          [label]="'Users'"
          [showMore]="false"
          [requireApply]="true"
          (shareIndividualCheckedList)="selectUsers($event)"
        >
        </app-multi-select-dropdown>

        <select name="ts" (change)="selectTS($event)" class="form-select">
          <option value="0" [selected]="selectedTs == 0" disabled>
            {{ "COMMON.TS.SINGLE" | translate }}
          </option>
          <option
            [selected]="selectedTs == o.id"
            *ngFor="let o of ts"
            value="{{ o.id }}"
          >
            {{ o.name }}
          </option>
        </select>

        <select
          name="voltage"
          (change)="selectFeederVoltage($event)"
          class="form-select"
        >
          <option value="0" [selected]="feederVoltage == 0" disabled>
            {{ "COMMON.FEEDER_VOLTAGE" | translate }}
          </option>
          <option
            [selected]="feederVoltage == o.value"
            *ngFor="let o of feederVoltageOptions"
            value="{{ o.value }}"
          >
            {{ o.name }}
          </option>
        </select>

        <select
          name="mount"
          (change)="selectMountPosition($event)"
          class="form-select"
        >
          <option value="0" [selected]="mountPosition == 0" disabled>
            {{ "TRANSFORMERS.MOUNT_POS" | translate }}
          </option>
          <option
            [selected]="mountPosition == o.value"
            *ngFor="let o of mountPositionOptions"
            value="{{ o.value }}"
          >
            {{ o.name }}
          </option>
        </select>
        <select name="type" (change)="selectDtType($event)" class="form-select">
          <option value="0" [selected]="dtType == 0" disabled>DT Type</option>
          <option
            [selected]="dtType == o.value"
            *ngFor="let o of dtTypeOptions"
            value="{{ o.value }}"
          >
            {{ o.name }}
          </option>
        </select>
        <select
          name="status"
          (change)="selectDtStatus($event)"
          class="form-select"
        >
          <option value="0" [selected]="dtStatus == 0" disabled>
            DT Status
          </option>
          <option
            [selected]="dtStatus == o.value"
            *ngFor="let o of dtStatusOptions"
            value="{{ o.value }}"
          >
            {{ o.name }}
          </option>
        </select>
        <select
          name="meter-status"
          (change)="selectMeterStatus($event)"
          class="form-select"
        >
          <option value="0" [selected]="meterStatus == 0" disabled>
            {{ "COMMON.MBC_DETAILS.METER_STATUS" | translate }}
          </option>
          <option
            [selected]="meterStatus == o.value"
            *ngFor="let o of meterStatusOptions"
            value="{{ o.value }}"
          >
            {{ o.name }}
          </option>
        </select>
        <select
          name="kva-rating"
          (change)="selectKVARating($event)"
          class="form-select"
        >
          <option value="0" [selected]="!kvaRating" disabled>
            {{ "TRANSFORMERS.KVA_RATING" | translate }}
          </option>
          <option
            [selected]="kvaRating == kva"
            *ngFor="let kva of kvaRatingOptions"
            value="{{ kva }}"
          >
            {{ kva }}
          </option>
        </select>
      </div>

      <div *ngIf="selectedFilters.length > 0">
        <app-filter-checklist
          [filters]="selectedFilters"
          (resetFilterEmitter)="resetFilter($event)"
          (removeFilterEmitter)="removeFilter($event)"
          [useApply]="true"
          (applyFilterEmitter)="applyFilter($event)"
        >
        </app-filter-checklist>
      </div>
    </div>
    <div class="table">
      <div *ngIf="dts">
        <table class="table border-bottom">
          <thead class="table-light">
            <tr>
              <th class="checkbox">
                <input
                  type="checkbox"
                  class="form-check-input"
                  [ngModel]="allCheckboxesChecked"
                  (click)="checkAllCheckboxes()"
                />
              </th>
              <th>{{ "COMMON.SLRN" | translate }}</th>
              <th class="th-dt-name">{{ "COMMON.DT_NAME" | translate }}</th>
              <th>{{ "TRANSFORMERS.DT_RATING_KVA" | translate }}</th>
              <th class="th-address">
                {{ "TRANSFORMERS.DT_ADDRESS" | translate }}
              </th>
              <th>{{ "COMMON.BU.SINGLE" | translate }}</th>
              <th>{{ "COMMON.UT.SINGLE" | translate }}</th>
              <th>{{ "COMMON.MBC_DETAILS.FEEDER_NAME" | translate }}</th>
              <th>{{ "COMMON.FEEDER_VOLTAGE" | translate }}</th>
              <th>
                {{ "COMMON.MBC_DETAILS.CONNECTED_CUSTOMERS" | translate }}
              </th>
              <th>{{ "COMMON.DT_METER_TYPE.TYPE" | translate }}</th>
              <th>{{ "COMMON.VALIDATION_STATUS" | translate }}</th>
              <th>{{ "COMMON.TAG.TITLE" | translate }}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let dt of dts; let i = index"
              (click)="selectDataQuality(dt.id)"
            >
              <td>
                <input
                  type="checkbox"
                  class="form-check-input"
                  value="{{ dt.id }}"
                  [ngModel]="dt.isChecked"
                  (click)="checkDt(dt.id)"
                />
              </td>
              <td (click)="setFilterStorage()">
                {{ dt?.slrn | hasValue }}
              </td>
              <td (click)="setFilterStorage()">
                {{ dt.name | hasValue }}
              </td>
              <td (click)="setFilterStorage()">
                {{ dt.kvaRating | hasValue }}
              </td>

              <td (click)="setFilterStorage()">
                {{ dt.locationAddress | hasValue }}
              </td>
              <td (click)="setFilterStorage()">
                {{ dt.area.name | hasValue }}
              </td>
              <td (click)="setFilterStorage()">
                {{ dt.area.regionName | hasValue }}
              </td>
              <td (click)="setFilterStorage()">
                {{ dt.feeder.name | hasValue }}
              </td>
              <td (click)="setFilterStorage()">
                {{
                  dt.feeder.feederType == "KV11" ? "11KV" : ("33KV" | hasValue)
                }}
              </td>
              <td (click)="setFilterStorage()">
                {{ dt.connectedCustomers | number | hasValue }}
              </td>

              <td (click)="setFilterStorage()">
                {{ dt.type | hasValue }}
              </td>

              <td (click)="setFilterStorage()">
                <div
                  *ngIf="dt.status"
                  class="building-status"
                  [ngClass]="{
                    'complete-building': dt.status == 'Complete',
                    'incomplete-building': dt.status == 'Incomplete',
                    'pending-building': dt.status == 'Pending',
                    'assigned-building':
                      dt.status == 'Assigned' || dt.status == 'Retagged',
                    'dark-red-status v-2': dt.status == 'Rejected'
                  }"
                >
                  {{ dt.status }}
                </div>
                <div *ngIf="!dt.status" class="building-status">--------</div>
              </td>
              <td>
                <div (click)="setFilterStorage()" class="dark-green-status v-2">
                  {{ "COMMON.EXISTING" | translate }}
                </div>
              </td>
              <td class="more-icon" (click)="setFilterStorage()">
                <img
                  src="../../assets/icons/more-icon.svg"
                  alt="More icon"
                  class="more-icon"
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div>
          <app-paging
            *ngIf="count > 0"
            [pageSize]="pageSize"
            [useDirect]="true"
            [count]="count"
            [currentPage]="currentPage"
            (pageChange)="pageChange($event)"
          ></app-paging>
        </div>
      </div>
    </div>
  </div>
</div>
