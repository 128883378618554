import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../config/config';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
  })
  export class CommonService {

    public pathApi = this.config.setting['pathApi'];

    constructor(private http: HttpClient, private config: AppConfig) { }

    replaceAll(data: string, searchText: string, replaceText: string): string {
        while (data.includes(searchText)) {
        data = data.replace(searchText, replaceText);
        }
        return data;
    }

    combinePaths(path1: string, path2: string): string {
        path1 = this.replaceAll(path1, '\\', '/');
        path2 = this.replaceAll(path2, '\\', '/');
        if (path1[path1.length - 1] === '/' && path2[0] === '/') path1 = path1.slice(0, -1);

        console.log(path1 + path2);
        return path1 + path2;
      }
  }