import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, filter } from 'rxjs';
import { AppConfig } from '../config/config';
import { HttpHeaders } from '@angular/common/http';
import { User } from '../models/user.model';
import { ResponsePackage } from '../models/util.model';
import { SystemComponents } from '../models/role.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private pathApi = this.config.setting['pathApi'] + "user/";


  constructor(private http: HttpClient, private config: AppConfig) {

  }
  
  getUsersForFilter(type: SystemComponents): Observable<any>{
    return this.http.get(`${this.pathApi}getUsersForFilter?userType=${type}`);
  }

  forgotPasswordRequest(email: any): Observable<any> {
    return this.http.post(`${this.pathApi}forgotPasswordRequest?email=${email}`, null)
  } 

  resetPassword(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}resetPassword`, obj);
  } 

  getAll(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}getAll`, obj);
  }

  getById(id: number): Observable<any> {
    return this.http.get(`${this.pathApi}getById?id=${id}`);
  }

  setStatus(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}setStatus`, obj);
  }

  saveUser(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}save`, obj);
  }

  updatePassword(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}updatePassword`, obj);
  }

  updateUserProfile(obj: any, image: File, blobUrl: string){  

    let formData: FormData = new FormData();
    formData.append('firstName', obj.firstName);
    formData.append('image', image, image.name);
    
    formData.append('lastName', obj.lastName);
    formData.append('email', obj.email);
    formData.append('blobUrl', blobUrl);

    return this.http.post(`${this.pathApi}updateUser`, formData);
  }

  getUser(obj: any): Observable<any> {
    return this.http.get(`${this.pathApi}getByEmail?email=${obj}`);
  }

  uploadFile(obj: any, utilityId: number, isBulkReassign: boolean): Observable<any> {
    return this.http.post(`${this.pathApi}uploadFile?utilityId=${utilityId}&isBulkReassign=${isBulkReassign}`, obj);
  }  

  generateTemplate(utilityId: number, isBulkReassign: boolean): Observable<any> {
    return this.http.get(`${this.pathApi}generateTemplate?utilityId=${utilityId}&isBulkReassign=${isBulkReassign}`);
  }

  getMobileUsers(areaId: number, filterText?: string) {
    if(filterText) {
      return this.http.get<ResponsePackage<User[]>>(`${this.pathApi}getFieldAgents?areaId=${areaId}&filterText=${filterText}`, {observe: 'response'});
    }
    else {
      return this.http.get<ResponsePackage<User[]>>(`${this.pathApi}getFieldAgents?areaId=${areaId}`, {observe: 'response'});
    }
  }

  getReaderMobileUsers(areaId: number, filterText?: string) {
    if(filterText) {
      return this.http.get<ResponsePackage<User[]>>(`${this.pathApi}getReaderFieldAgents?areaId=${areaId}&filterText=${filterText}`, {observe: 'response'});
    }
    else {
      return this.http.get<ResponsePackage<User[]>>(`${this.pathApi}getReaderFieldAgents?areaId=${areaId}`, {observe: 'response'});
    }
  }

  getMobileUsersForSelect(filterText?: string): Observable<any> {
    if(filterText) {
      return this.http.get(`${this.pathApi}getMobileUsersForSelect?filterText=${filterText}`);
    }
    else {
      return this.http.get(`${this.pathApi}getMobileUsersForSelect`);
    }
  }

  getAreasByUserId(): Observable<any> {
    return this.http.get(`${this.pathApi}getAreasByUserId`);
  }

  getRegionsByUserId(): Observable<any> {
    return this.http.get(`${this.pathApi}getRegionsByUserId`);
  }
  
}
