import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AppConfig } from "../config/config";
import { Region } from "../models/region.model";
import { ResponsePackage } from "../models/util.model";

@Injectable({
    providedIn: 'root'
  })
export class RegionService {
    
    private pathApi = this.config.setting['pathApi'] + "region/";
    private headers = new HttpHeaders({'Content-Type': 'application/json'});

    constructor(private http: HttpClient, private config: AppConfig) {}

    public getAllForSelect(utilityId: number | null = null) {
        if(utilityId) {
            return this.http.get<ResponsePackage<Region[]>>(`${this.pathApi}getAllForSelect/${utilityId}`,{headers: this.headers, observe: 'response'});
        }
        else {
            return this.http.get<ResponsePackage<Region[]>>(`${this.pathApi}getAllForSelect`,{headers: this.headers, observe: 'response'});
        }
    }
    
    public getAllForSelectNoUser(utilityId: number | null = null) {
        if(utilityId) {
            return this.http.get<ResponsePackage<Region[]>>(`${this.pathApi}getAllForSelectNoUser/${utilityId}`,{headers: this.headers, observe: 'response'});
        }
        else {
            return this.http.get<ResponsePackage<Region[]>>(`${this.pathApi}getAllForSelectNoUser`,{headers: this.headers, observe: 'response'});
        }
    }

    getAll(dataIn: any): Observable<any> {
        return this.http.post(`${this.pathApi}getAll`, dataIn, {observe: 'response'});
    }
    
    save(region: Region): Observable<any> {
        return this.http.post(`${this.pathApi}save`, region, {observe: 'response'});
    }

    delete(id: number): Observable<any> {
        return this.http.delete(`${this.pathApi}delete/${id}`, {observe: 'response'});
    }

    getRegionsForOptions(utilityId: number | null = null): Observable<any> {
        if(utilityId) {
            return this.http.get(`${this.pathApi}getRegionsForOptions/${utilityId}`);

        }
        else{
        return this.http.get(`${this.pathApi}getRegionsForOptions`);
      }
    }
}