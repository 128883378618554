import {
  Component,
  ElementRef,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { Area } from 'src/app/models/area.model';
import { ChecklistItem } from 'src/app/models/checklist-item.model';
import {
  AssetStatus,
  ClientType,
  CustomerCrudTable,
  ServiceType,
  TypeOfConnection,
} from 'src/app/models/customer.model';
import { Region } from 'src/app/models/region.model';
import { PermissionsEnum } from 'src/app/models/role.model';
import { Tariff } from 'src/app/models/tariff.model';
import { BPSAdminLevel } from 'src/app/models/user.model';
import { OptionObj } from 'src/app/models/util.model';
import { AreaService } from 'src/app/services/area.service';
import { CustomerService } from 'src/app/services/customer.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { RegionService } from 'src/app/services/region.service';
import { TariffService } from 'src/app/services/tariff.service';
import { TranslationService } from 'src/app/services/translation.service';
import { TranslationUtils } from 'src/assets/i18n/translation.utils';

@Component({
  selector: 'crud-customer',
  templateUrl: './crud-customer.component.html',
  styleUrls: ['./crud-customer.component.scss'],
})
export class CrudCustomerComponent implements OnInit {
  constructor(
    private router: Router,
    private toastr: ToastrService,
    public permissionsService: PermissionsService,
    private customerService: CustomerService,
    private regionService: RegionService,
    private areaService: AreaService,
    private tariffService: TariffService,
    private translationService: TranslationService,
    private translateService: TranslateService
  ) {}

  @Input() searchText!: string;
  @Input() searchType!: string;

  @Input() utilityId: number = 0;
  @Input() bpsAdminLevel!: BPSAdminLevel;

  public PermissionsEnum = PermissionsEnum;
  public currentPage: number = 1;
  public pageSize: number = 5;
  public count: number = 0;
  public showUpload: boolean = false;
  regions: Region[] = [];
  allAreas: Area[] = [];
  areas: Area[] = [];
  customers: CustomerCrudTable[] = [];
  actionLabel$!: Observable<string>;
  isEditMode: boolean = false;
  isNewConnection: boolean = false;
  customerForDeleteId: number = 0;
  buName: string = this.translationService.getByKeyFromStorage('BU');
  utName: string = this.translationService.getByKeyFromStorage('UT');
  contractNumber: string =
    this.translationService.getByKeyFromStorage('Contract number');
  selectedAreas: number[] = [];
  selectedRegions: number[] = [];

  historyItems: any[] = [];
  isViewHistory: boolean = true;

  @ViewChild('closeAddModal') closeAddModal: ElementRef;
  @ViewChild('closeDeleteModal') closeDeleteModal: ElementRef;

  tariff: number = 0;
  tariffs: Tariff[] = [];

  serviceType?: number;
  serviceTypeOptions = [
    { name: 'Large', value: ServiceType.Large },
    { name: 'Small', value: ServiceType.Small },
    { name: 'Prepaid', value: ServiceType.Prepaid },
    { name: 'AMR', value: ServiceType.AMR },
  ];

  status?: number;
  statusOptions = [
    { name: 'Pending setup', value: 0 },
    { name: 'Complete', value: 1 },
  ];
  sltStatus?: number;

  sltOptions: OptionObj[] = [];
  clientType?: number;
  clientTypeOptions = [
    { name: 'Company', value: ClientType.Company },
    {
      name: 'Embassy Foreign Institution',
      value: ClientType.EmbassyForeignInstitution,
    },
    { name: 'Individual', value: ClientType.Individual },
    { name: 'Government', value: ClientType.Government },
    { name: 'Assembly', value: ClientType.Assembly },
  ];

  customerPhase?: number;
  customerPhaseOptions = [
    { name: 'SinglePhase', value: TypeOfConnection.SinglePhase },
    { name: 'ThreePhase', value: TypeOfConnection.ThreePhase },
  ];

  clicked: boolean = false;

  selectedFilters: ChecklistItem[] = [];
  isGhanaPostFieldsVisible: boolean = true;

  ngOnInit(): void {
    this.setFieldsVisibility();
    if (this.utilityId != 0) {
      this.getSltStatuses();
      this.setFilters();
      this.reloadTable();
    }

    this.updateActionLabel();
  }

  private updateActionLabel(): void {
    const key = this.isEditMode ? TranslationUtils.EDIT_KEY : TranslationUtils.NEW_KEY;
    this.actionLabel$ = this.translateService.stream(key);
  }

  setNewConnectionPage(ncp: boolean) {
    this.isNewConnection = ncp;
    this.currentPage = 1;
    this.reloadTable();
  }

  selectCustomer(id: number) {
    this.router.navigate([`/customers/crud-edit/${id}/${this.utilityId}`]);
    this.setFilterStorage();
  }

  pageChange(value: any) {
    this.currentPage = value;
    this.reloadTable();
  }

  setCustomerForDeleteId(id: number) {
    this.customerForDeleteId = id;
  }

  reloadTable(page: any = null) {
    if (page) {
      this.currentPage = page;
    }

    let dataIn = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize,
        count: this.count,
      },
      filterParams: this.getFilterObject(),
      utilityId: this.utilityId,
    };
    this.getAllCustomers(dataIn);
  }

  getFilterObject() {
    let obj = {
      search: this.searchText,
      isNewConnection: this.isNewConnection,
      tariffId: this.tariff != 0 ? this.tariff : null,
      serviceType: this.serviceType ? this.serviceType : null,
      customerStatus: this.status ? this.status : null,
      clientType: this.clientType ? this.clientType : null,
      sltStatus: this.sltStatus ? this.sltStatus : null,
      customerPhase: this.customerPhase ? this.customerPhase : null,
      selectedAreas: this.selectedAreas.length > 0 ? this.selectedAreas : null,
      selectedRegions:
        this.selectedRegions.length > 0 ? this.selectedRegions : null,
    };

    return obj;
  }
  setFilterStorage() {
    let obj = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize,
        count: this.count,
      },
      filterParams: this.getFilterObject(),
      utilityId: this.utilityId,
    };
    localStorage.setItem(
      'filter',
      JSON.stringify({ type: 'CustomerCrud', filter: obj })
    );
  }
  filter() {
    this.currentPage = 1;
    let dataIn = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize,
        count: this.count,
      },
      filterParams: {
        search: this.searchText,
        isNewConnection: this.isNewConnection,
        searchType: this.searchType,
      },
      utilityId: this.utilityId,
    };
    this.getAllCustomers(dataIn);
  }

  private getAllCustomers(dataIn: any) {
    this.customerService.getAllCrud(dataIn).subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.customers = responseData.data.data;
          this.count = responseData.data.count ?? this.count;
          this.createFilterCards();
          localStorage.removeItem('filter');
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  deleteCustomer() {
    this.customerService.delete(this.customerForDeleteId).subscribe({
      next: (response) => {
        const responseData = response.body;
        this.closeDeleteModal?.nativeElement?.click();
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.pageChange(1);
          this.toastr.success(responseData?.message);
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  viewHistory(id: number) {
    this.customerService.getHistory(id).subscribe({
      next: (response) => {
        console.log(response);
        this.historyItems = response?.body?.data ?? [];
      },
    });
  }

  private getRegions() {
    this.regionService.getAllForSelectNoUser(this.utilityId).subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.regions = responseData.data;
          this.setCheckedRegions();

          this.getAreas();
        } else if (response?.status == 204) {
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  private getAreas() {
    this.areaService.getAllForOptions(this.utilityId).subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.allAreas = responseData.data;
          this.areas = this.allAreas.filter((area) =>
            this.regions.some(
              (region) => region.id == area.regionId && region.checked
            )
          );
          this.setCheckedAreas();
          this.createFilterCards();
          this.removeFilterStorage();
        } else if (response?.status == 204) {
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  getTariffs() {
    this.tariffService.getAllForSelect(this.utilityId).subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.tariffs = responseData.data;
          this.removeFilterStorage();
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  getSltStatuses() {
    this.customerService.getSltStatusesForOptions().subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.sltOptions = responseData.data;
          this.removeFilterStorage();
        } else if (response?.status == 204) {
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  selectTariff(e: any) {
    this.tariff = +e.target.value;
    this.createFilterCards();
  }

  selectCustomerPhase(e: any) {
    if (e.target.value != '') {
      this.customerPhase = e.target.value;
    } else {
      this.customerPhase = undefined;
    }
    this.createFilterCards();
  }

  selectClientType(e: any) {
    if (e.target.value != '') {
      this.clientType = e.target.value;
    } else {
      this.clientType = undefined;
    }
    this.createFilterCards();
  }

  selectServiceType(e: any) {
    if (e.target.value != '') {
      this.serviceType = e.target.value;
    } else {
      this.serviceType = undefined;
    }
    this.createFilterCards();
  }

  selectStatus(e: any) {
    if (e.target.value != '') {
      this.status = e.target.value;
    } else {
      this.status = undefined;
    }
    this.createFilterCards();
  }

  selectSltStatus(e: any) {
    if (e.target.value != '') {
      this.sltStatus = parseInt(e.target.value);
    } else {
      this.sltStatus = undefined;
    }
    this.createFilterCards();
  }

  selectRegion(e: any) {
    //list of areas for selected regions
    this.areas = this.allAreas.filter((area) =>
      this.regions.some(
        (region) => region.id == area.regionId && region.checked
      )
    );
    this.allAreas.forEach((area) => {
      if (!this.areas.some((a) => a.id === area.id)) {
        area.checked = false; //uncheck areas for unchecked regions
      }
    });
    this.createFilterCards();
  }

  selectArea(e: any) {
    this.createFilterCards();
  }

  removeFilter(item: ChecklistItem) {
    if (item.property == this.buName) {
      this.regions.forEach((region) => {
        if (region.name === item.selectedValue) {
          region.checked = false;
        }
      });
      this.selectRegion(event);
      this.clicked = !this.clicked;
    } else if (item.property == this.utName) {
      this.areas.forEach((area) => {
        if (area.name === item.selectedValue) {
          area.checked = false;
        }
      });
      this.clicked = !this.clicked;
    } else if (item.property == 'Tariff') {
      this.tariff = 0;
    } else if (item.property == 'Client type') {
      this.clientType = undefined;
    } else if (item.property == 'Service type') {
      this.serviceType = undefined;
    } else if (item.property == 'Status') {
      this.status = undefined;
    } else if (item.property == 'Slt status') {
      this.sltStatus = undefined;
    } else if (item.property == 'Customer phase') {
      this.customerPhase = undefined;
    }
    this.createFilterCards();

    if (this.selectedFilters.length == 0) {
      this.regions.forEach((region) => {
        region.checked = false;
      });
      this.reloadTable(1);
    }
  }

  resetFilter(e: any) {
    this.selectedFilters = [];

    this.regions.forEach((region) => {
      region.checked = false;
    });

    this.areas.forEach((area) => {
      area.checked = false;
    });
    this.selectedAreas = [];
    this.selectedRegions = [];

    this.selectedAreas = [];
    this.selectedRegions = [];
    this.tariff = 0;
    this.serviceType = undefined;
    this.status = undefined;
    this.sltStatus = undefined;
    this.clientType = undefined;
    this.customerPhase = undefined;

    this.clicked = !this.clicked;

    this.reloadTable(1);
  }

  createFilterCards() {
    this.selectedFilters = [];

    let selectedAreas = this.areas.filter((x) => x.checked);
    this.selectedAreas = this.areas.filter((x) => x.checked).map((x) => x.id);
    this.selectedRegions = this.regions
      .filter((x) => x.checked)
      .map((x) => x.id);

    //areas
    for (var i = 0; i < selectedAreas.length; i++) {
      this.selectedFilters.push(
        new ChecklistItem(this.utName, selectedAreas[i].name)
      );
    }

    //tariff
    if (this.tariff != 0) {
      let tariffName = this.tariffs.filter((x) => x.id == this.tariff)[0].name;
      this.selectedFilters.push(new ChecklistItem('Tariff', tariffName));
    }

    //client type
    if (this.clientType) {
      var clientTypeName = ClientType[this.clientType!];
      this.selectedFilters.push(
        new ChecklistItem('Client type', clientTypeName)
      );
    }

    //service type
    if (this.serviceType) {
      var serviceTypeName = ServiceType[this.serviceType!];
      this.selectedFilters.push(
        new ChecklistItem('Service type', serviceTypeName)
      );
    }

    //slt
    if (this.sltStatus) {
      var sltStatusName = this.sltOptions.find(
        (x) => x.key == this.sltStatus
      )!.value;
      this.selectedFilters.push(new ChecklistItem('Slt status', sltStatusName));
    }

    //status
    if (this.status) {
      var statusName = AssetStatus[this.status!];
      this.selectedFilters.push(new ChecklistItem('Status', statusName));
    }

    //customer phase
    if (this.customerPhase) {
      var customerPhaseName =
        this.customerPhaseOptions[this.customerPhase!].name;
      this.selectedFilters.push(
        new ChecklistItem('Customer phase', customerPhaseName)
      );
    }
  }
  stopPropagation(event: Event) {
    event.stopPropagation();
  }
  setFilters() {
    var filter = localStorage.getItem('filter');
    if (!filter) return;
    var filterObject = JSON.parse(filter);
    if (filterObject.type != 'CustomerCrud') return;
    this.currentPage = filterObject.filter.pageInfo.page;
    this.pageSize = filterObject.filter.pageInfo.pageSize;
    this.count = filterObject.filter.pageInfo.count;
    this.searchText = filterObject.filter.filterParams.search;
    this.tariff = filterObject.filter.filterParams.tariffId
      ? filterObject.filter.filterParams.tariffId
      : 0;
    this.serviceType = filterObject.filter.filterParams.serviceType
      ? filterObject.filter.filterParams.serviceType
      : undefined;
    this.status = filterObject.filter.filterParams.customerStatus
      ? filterObject.filter.filterParams.customerStatus
      : undefined;
    this.isNewConnection = filterObject.filter.filterParams.isNewConnection;
    this.sltStatus = filterObject.filter.filterParams.sltStatus
      ? filterObject.filter.filterParams.sltStatus
      : undefined;
    this.clientType = filterObject.filter.filterParams.clientType
      ? filterObject.filter.filterParams.clientType
      : undefined;
    this.customerPhase = filterObject.filter.filterParams.customerPhase
      ? filterObject.filter.filterParams.customerPhase
      : undefined;
    this.selectedAreas = filterObject.filter.filterParams.selectedAreas
      ? filterObject.filter.filterParams.selectedAreas
      : [];
    this.selectedRegions = filterObject.filter.filterParams.selectedRegions
      ? filterObject.filter.filterParams.selectedRegions
      : [];
  }

  setCheckedAreas() {
    var filter = localStorage.getItem('filter');
    if (!filter) return;
    var filterObject = JSON.parse(filter);
    if (filterObject.type != 'CustomerCrud') return;
    if (filterObject.filter.filterParams.selectedAreas) {
      this.selectedAreas = filterObject.filter.filterParams.selectedAreas;
      this.areas = this.allAreas.filter((area) =>
        this.regions.some(
          (region) => region.id == area.regionId && region.checked
        )
      );
      this.areas.forEach((element) => {
        if (this.selectedAreas.includes(element.id)) element.checked = true;
        else element.checked = false;
      });
    }
  }
  setCheckedRegions() {
    var filter = localStorage.getItem('filter');
    if (!filter) return;
    var filterObject = JSON.parse(filter);
    if (filterObject.type != 'CustomerCrud') return;
    if (filterObject.filter.filterParams.selectedRegions) {
      this.selectedRegions = filterObject.filter.filterParams.selectedRegions;
      this.regions.forEach((element) => {
        if (this.selectedRegions.includes(element.id)) element.checked = true;
        else element.checked = false;
      });
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    console.log(changes);
    console.log(this.searchType);
    if (changes['utilityId']) {
      if (this.utilityId != 0) {
        this.getRegions();
        this.getTariffs();
      }
    }
  }

  removeFilterStorage() {
    if (
      this.allAreas.length > 0 &&
      this.regions.length > 0 &&
      this.tariffs.length > 0
    )
      localStorage.removeItem('filterCustomer');
  }

  setFieldsVisibility() {
    let notVisibleFields: any = localStorage.getItem('notVisibleFields');

    if (!notVisibleFields) {
      return;
    }

    notVisibleFields = JSON.parse(notVisibleFields);
    this.isGhanaPostFieldsVisible = notVisibleFields.find(
      (el: any) => el === 'ghanaPostCode'
    )
      ? false
      : true;
  }

  applyFilter(item: ChecklistItem) {
    this.reloadTable(1);
  }
}
