import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'historiesHasValue'
})
export class HistoriesHasValuePipe implements PipeTransform{
    transform(value: any)  {
        if(value === undefined || value === null){
            return "Not Changed";
        }

        if(value === '' || value === " " || value === '/'){
            return "Not Changed";
        }

        return value;
    }
}