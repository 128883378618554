import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { ToastrService } from 'ngx-toastr';
import { PermissionsService } from '../permissions.service';

@Injectable({
  providedIn: 'root',
})
export class BpsAuthGuard implements CanActivate {
  constructor(
    private toastr: ToastrService,
    private authService: AuthService,
    public permissionsService: PermissionsService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot) {
    if (this.permissionsService.checkIfhasUtility()) {
      this.toastr.error("You don't have permission.");
      this.router.navigate(['']);
      return false;
    }
    var admin = localStorage.getItem('isAdmin');
    if (admin != '1') {
      this.router.navigate(['']);
      return false;
    }
    if (!this.authService.isLoggedIn()) {
      this.toastr.error("You don't have permission.");
      this.router.navigate(['']);
      return false;
    }

    return true;
  }
}
