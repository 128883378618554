<div class="add-utility-root">
  <div class="action-buttons" *ngIf="bpsAdminLevel != 2">
    <button class="btn btn-header" *ngIf="!editEnabled" (click)="edit()">
      Edit
    </button>
    <button class="btn btn-primary" *ngIf="editEnabled" (click)="validate()">
      Save
    </button>
  </div>
  <div *ngIf="selectedUtility != null" class="ac-body">
    <div *ngIf="!editEnabled" class="corner">
      <img height="50" width="50" src="{{ selectedUtility.imageUrl }}" alt="Utitlity image"/>
      <h2>{{ selectedUtility.subdomain }}</h2>
      <span>{{ selectedUtility.colorCode }}</span>
      <br />
      <span>Currency : {{ selectedUtility.currency }}</span>
    </div>
    <div class="padding" *ngIf="editEnabled">
      <div class="container card form-wrapper">
        <div class="row">
          <div class="col-6 form-item form-floating mb-3">
            <input
              [(ngModel)]="selectedUtility.subdomain"
              name="subdomain"
              type="text"
              class="form-control"
              id="floatingInput2"
              placeholder=" "
              required
            />
            <label for="floatingInput2">Subdomain</label>
          </div>
          <div class="col-6 form-item form-floating mb-3">
            <input
              [(ngModel)]="selectedUtility.name"
              name="name"
              type="text"
              class="form-control"
              id="floatingInput2"
              placeholder=" "
              required
            />
            <label for="floatingInput2">Name</label>
          </div>
        </div>
        <label class="logo-label">Utility logo</label>
        <div
          *ngIf="imageSrc == ''; else image"
          class="drag-drop col-12"
          appDragAndDrop
          (fileDropped)="onFileDropped($event)"
        >
          <input
            type="file"
            id="fileDropRef"
            name="utilityImage"
            #utilityImage
            accept="image/png, image/jpg, image/jpeg"
            (change)="fileBrowserHandler($event)"
          />
          <div class="upload-img">
            <img src="../../assets/icons/upload-arrow.svg" alt="Upload arrow"/>
          </div>
          <label for="fileDropRef"
            >Drag and drop file here or <span class="browse">Browse</span> to
            upload</label
          >
        </div>
        <ng-template #image>
          <div class="pb-4">
            <img
              alt="Upload image"
              height="75"
              width="75"
              style="max-width: 100%"
              src="{{ imageSrc }}"
            />
            <span (click)="removeImage()">x</span>
          </div>
        </ng-template>
        <div class="row">
          <div class="col-6 form-item form-floating mb-3">
            <input
              id="color-test"
              type="color"
              [(ngModel)]="selectedUtility.colorCode"
              name="floatingInput2"
              class="input-color"
            />
            <input
              name="color"
              [(ngModel)]="selectedUtility.colorCode"
              cpOutputFormat="hex"
              class="form-control"
              id="floatingInput2"
              placeholder=" "
              readonly
            />

            <label
              *ngIf="selectedUtility.colorCode != ''"
              for="floatingInput2"
              >{{ selectedUtility.colorCode }}</label
            >
          </div>
          <select  name="currency-select" (change)="selectCurrency($event)"class="col-6 form-item">
            <option value="" [selected]="!selectedUtility.currency" disabled>Currency</option>
            <option [selected]="selectedUtility.currency == c.code" *ngFor="let c of currencyList" value="{{ c.code }}">
              {{ c.text }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="filled-card">
      <div *ngIf="selectedUtility != null" class="container card">
        <div class="row">
          <div class="col-3">
            <span class="col-title">Asset</span>
            <div class="headerComponent">
              <div>
                <label class="switch">
                  <input
                    [disabled]="!editEnabled"
                    type="checkbox"
                    [checked]="isChecked([Configuration.Feeders])"
                    (change)="onChange([Configuration.Feeders], $event)"
                  />
                  <span class="slider round"></span>
                </label>
              </div>
              <div>
                <label class="title">Feeders(33KV,11KV)</label>
              </div>
            </div>
            <div class="headerComponent">
              <div>
                <label class="switch">
                  <input
                    [disabled]="!editEnabled"
                    type="checkbox"
                    [checked]="isChecked([Configuration.DTs])"
                    (change)="onChange([Configuration.DTs], $event)"
                  />
                  <span class="slider round"></span>
                </label>
              </div>
              <div>
                <label class="title">Distribution Transformers</label>
              </div>
            </div>
            <div class="headerComponent">
              <div>
                <label class="switch">
                  <input
                    [disabled]="!editEnabled"
                    type="checkbox"
                    [checked]="isChecked([Configuration.Poles])"
                    (change)="onChange([Configuration.Poles], $event)"
                  />
                  <span class="slider round"></span>
                </label>
              </div>
              <div>
                <label class="title">LT Poles</label>
              </div>
            </div>
            <div class="headerComponent">
              <div>
                <label class="switch">
                  <input
                    [disabled]="!editEnabled"
                    type="checkbox"
                    [checked]="isChecked([Configuration.Buildings])"
                    (change)="onChange([Configuration.Buildings], $event)"
                  />
                  <span class="slider round"></span>
                </label>
              </div>
              <div>
                <label class="title">Buildings</label>
              </div>
            </div>
            <div class="headerComponent">
              <div>
                <label class="switch">
                  <input
                    [disabled]="!editEnabled"
                    type="checkbox"
                    [checked]="isChecked([Configuration.Meters])"
                    (change)="onChange([Configuration.Meters], $event)"
                  />
                  <span class="slider round"></span>
                </label>
              </div>
              <div>
                <label class="title">Meters</label>
              </div>
            </div>
          </div>
          <div class="col-3">
            <span class="col-title">Operations</span>
            <div class="headerComponent">
              <div>
                <label class="switch">
                  <input
                    [disabled]="!editEnabled"
                    type="checkbox"
                    [checked]="isChecked([Configuration.Survey])"
                    (change)="onChange([Configuration.Survey], $event)"
                  />
                  <span class="slider round"></span>
                </label>
              </div>
              <div>
                <label class="title">Survey</label>
              </div>
            </div>
            <div class="headerComponent">
              <div>
                <label class="switch">
                  <input
                    [disabled]="!editEnabled"
                    type="checkbox"
                    [checked]="isChecked([Configuration.Installation])"
                    (change)="onChange([Configuration.Installation], $event)"
                  />
                  <span class="slider round"></span>
                </label>
              </div>
              <div>
                <label class="title">Installation</label>
              </div>
            </div>
            <div class="headerComponent">
              <div>
                <label class="switch">
                  <input
                    [disabled]="!editEnabled"
                    type="checkbox"
                    [checked]="isChecked([Configuration.WorkOrder])"
                    (change)="onChange([Configuration.WorkOrder], $event)"
                  />
                  <span class="slider round"></span>
                </label>
              </div>
              <div>
                <label class="title">Work order</label>
              </div>
            </div>
          </div>

          <div class="col-3">
            <span class="col-title">Meter management</span>
            <div class="headerComponent">
              <div>
                <label class="switch">
                  <input
                    [disabled]="!editEnabled"
                    type="checkbox"
                    [checked]="isChecked([Configuration.MeterManagement])"
                    (change)="onChange([Configuration.MeterManagement], $event)"
                  />
                  <span class="slider round"></span>
                </label>
              </div>
              <div>
                <label class="title">Meter management</label>
              </div>
            </div>
          </div>

          <div class="col-3">
            <span class="col-title">Customer management</span>
            <div class="headerComponent">
              <div>
                <label class="switch">
                  <input
                    [disabled]="!editEnabled"
                    type="checkbox"
                    [checked]="isChecked([Configuration.CustomerManagement])"
                    (change)="onChange([Configuration.CustomerManagement], $event)"
                  />
                  <span class="slider round"></span>
                </label>
              </div>
              <div>
                <label class="title">Customer management</label>
              </div>
            </div>
          </div>

          <div class="col-3 mt-4">
            <span class="col-title">Readings</span>
            <div class="headerComponent">
              <div>
                <label class="switch">
                  <input
                    [disabled]="!editEnabled"
                    type="checkbox"
                    [checked]="isChecked([Configuration.Readings])"
                    (change)="onChange([Configuration.Readings], $event)"
                  />
                  <span class="slider round"></span>
                </label>
              </div>
              <div>
                <label class="title">Customer Meter Reading</label>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div *ngIf="editEnabled" class="filled-card">
      <div class="container card">
        <div class="row">
          <div class="container-fluid border-bottom">
            <table>
              <tr class="header-row">
                <th>KEY</th>
                <th>VALUE</th>
              </tr>
              <tr
                class="content-wrapper"
                (click)="selectTranslation(item)"
                *ngFor="let item of selectedUtility.translations"
                data-bs-toggle="modal"
                data-bs-target="#changeTranslation"
              >
                <td>{{ item.key }}</td>
                <td>{{ item.value }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="!editEnabled" class="filled-card">
      <div class="container card">
        <span class="col-title">Translations</span>
        <div class="row translations">
          <div class="col-6">
            <div
              class="col-subtitle row"
              *ngFor="let item of selectedUtility.translations"
            >
              {{ item.key }} : {{ item.value }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="filled-card">
      <div class="container card">
        <span class="col-title">Validation Rules</span>
        <div class="row">
          <div class="container-fluid border-bottom">
            <table>
              <tr class="header-row">
                <th>Property Type</th>
                <th>Starts with</th>
                <th>Alfanumeric</th>
              </tr>
              <tr
                class="content-wrapper"
                *ngFor="let item of selectedUtility.validationRules"
              >
                <td>{{ item.propertyType }}</td>
                <td>{{ item.startsWith | hasValue }}</td>
                <td>{{ item.isAlfaNumeric == true ? "Yes" : "No" }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="padding" *ngIf="editEnabled">
      <div class="container card form-wrapper">
        <div class="row">
          <div class="col-12 form-item form-floating mb-3">
            <input
              [(ngModel)]="
                selectedUtility.kycConsentConfiguration.regulatoryCommission
              "
              name="regulatoryCommission"
              type="text"
              class="form-control"
              id="floatingInput2"
              placeholder=" "
              required
            />
            <label for="floatingInput2">Regulatory Commission</label>
          </div>
          <div class="col-12 form-item form-floating mb-3">
            <input
              [(ngModel)]="
                selectedUtility.kycConsentConfiguration.electricityReform
              "
              name="electricityReform"
              type="text"
              class="form-control"
              id="floatingInput2"
              placeholder=" "
              required
            />
            <label for="floatingInput2">Electricity Reform</label>
          </div>
          <div class="col-12 form-item form-floating mb-3">
            <input
              [(ngModel)]="
                selectedUtility.kycConsentConfiguration.networkRegulation
              "
              name="networkRegulation"
              type="text"
              class="form-control"
              id="floatingInput2"
              placeholder=" "
              required
            />
            <label for="floatingInput2">Network Regulation</label>
          </div>
          <div class="col-12 form-item form-floating mb-3">
            <input
              [(ngModel)]="
                selectedUtility.kycConsentConfiguration.electricityManagement
              "
              name="electricityManagement"
              type="text"
              class="form-control"
              id="floatingInput2"
              placeholder=" "
              required
            />
            <label for="floatingInput2">Electricity Management</label>
          </div>
          <div class="col-12 form-item form-floating mb-3">
            <input
              [(ngModel)]="selectedUtility.kycConsentConfiguration.country"
              name="country"
              type="text"
              class="form-control"
              id="floatingInput2"
              placeholder=" "
              required
            />
            <label for="floatingInput2">Country</label>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!editEnabled" class="filled-card">
      <div class="container card">
        <span class="col-title">New Connection Consent Configuration</span>
        <div class="row translations">
          <div class="col-12">
            <div class="col-subtitle row">
              Regulatory Commission:
              {{
                this.selectedUtility.kycConsentConfiguration
                  ?.regulatoryCommission
              }}
            </div>
            <div class="col-subtitle row">
              Electricity Reform:
              {{
                this.selectedUtility.kycConsentConfiguration?.electricityReform
              }}
            </div>
            <div class="col-subtitle row">
              Network Regulation:
              {{
                this.selectedUtility.kycConsentConfiguration?.networkRegulation
              }}
            </div>
            <div class="col-subtitle row">
              Electricity Management:
              {{
                this.selectedUtility.kycConsentConfiguration
                  ?.electricityManagement
              }}
            </div>
            <div class="col-subtitle row">
              Country:
              {{ this.selectedUtility.kycConsentConfiguration?.country }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="padding" *ngIf="editEnabled">
      <div class="container card form-wrapper">
        <div class="row">
          <div class="col-12 form-item form-floating mb-3">
            <input
              [(ngModel)]="selectedUtility.bankDetails.bankName"
              name="bankName"
              type="text"
              class="form-control"
              id="floatingInput2"
              placeholder=" "
              required
            />
            <label for="floatingInput2">Bank Name</label>
          </div>
          <div class="col-12 form-item form-floating mb-3">
            <input
              [(ngModel)]="selectedUtility.bankDetails.accountName"
              name="accountName"
              type="text"
              class="form-control"
              id="floatingInput2"
              placeholder=" "
              required
            />
            <label for="floatingInput2">Account Name</label>
          </div>
          <div class="col-12 form-item form-floating mb-3">
            <input
              [(ngModel)]="selectedUtility.bankDetails.accountNumber"
              name="accountNumber"
              type="text"
              class="form-control"
              id="floatingInput2"
              placeholder=" "
              required
            />
            <label for="floatingInput2">Account Number</label>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!editEnabled" class="filled-card">
      <div class="container card">
        <span class="col-title">Bank Account Information</span>
        <div class="row translations">
          <div class="col-12">
            <div class="col-subtitle row">
              Bank Name: {{ this.selectedUtility.bankDetails?.bankName }}
            </div>
            <div class="col-subtitle row">
              Account Name: {{ this.selectedUtility.bankDetails?.accountName }}
            </div>
            <div class="col-subtitle row">
              Account Number:
              {{ this.selectedUtility.bankDetails?.accountNumber }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="filled-card">
      <div class="container card">
        <span class="col-title">Contact Person</span>
        <div class="row translations">
          <div class="col-12">
            <div class="col-subtitle row">
              First Name: {{ this.selectedUtility.contactPerson?.firstName }}
            </div>
            <div class="col-subtitle row">
              Last Name: {{ this.selectedUtility.contactPerson?.lastName }}
            </div>
            <div class="col-subtitle row">
              Email: {{ this.selectedUtility.contactPerson?.eMail }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="changeTranslation"
  tabindex="-1"
  aria-labelledby="changeTranslationTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">
          Change translation
        </h5>
        <button
          type="button"
          #closeModal
          class="btn-cancel-modal"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          Cancel
        </button>
      </div>
      <form
        id="addForm"
        class="form-wrapper"
        ngNativeValidate
        (submit)="saveTranslation()"
      >
        <div class="modal-body">
          <div class="form-item form-floating mb-3">
            <input
            id="translationKey"
              name="name"
              type="text"
              [(ngModel)]="selectedTranslation.key"
              [disabled]="true"
              class="form-control"
              required
              placeholder=" "
            />
            <label for="translationKey">Key</label>
          </div>
          <div class="form-item form-floating mb-3">
            <input
                id="translationVal"
              name="code"
              type="text"
              [(ngModel)]="selectedTranslation.value"
              class="form-control"
              required
              placeholder=" "
            />
            <label for="translationVal">Value</label>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-primary" form="addForm">Save</button>
        </div>
      </form>
    </div>
  </div>
</div>
