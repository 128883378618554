import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BuildingDevelopmentStage, BuildingSave, BuildingStructure, EditBuilding } from 'src/app/models/building.model';
import { EntityType, ImageType } from 'src/app/models/image.model';
import { PermissionsEnum } from 'src/app/models/role.model';
import { User } from 'src/app/models/user.model';
import { BuildingService } from 'src/app/services/building.service';
import { UserService } from 'src/app/services/user.service';
import { TranslationService } from 'src/app/services/translation.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { DtService } from 'src/app/services/dt.service';
import { F11Crud } from 'src/app/models/feeder11.model';
import { F33Crud } from 'src/app/models/feeder33.model';
import { F33Service } from 'src/app/services/f33.service';
import { F11Service } from 'src/app/services/f11.service';
import { RecallType } from 'src/app/models/recallType.model';
import { AssetStatusEnum } from 'src/app/models/assetStatus.model';
import { OptionObj } from 'src/app/models/util.model';
import { FeederType } from '../../../../models/feeder.model';
import { BlobStorageService } from 'src/app/_modules/shared/_services/blob-storage.service';
import { ContainerNames } from 'src/app/models/containerNames.model';


@Component({
  selector: 'app-edit-buildings',
  templateUrl: './edit-buildings.component.html',
  styleUrls: ['./edit-buildings.component.scss'],
})
export class EditBuildingsComponent implements OnInit {
  @ViewChild('dropdownContainer') dropdownContainer: ElementRef;
  @ViewChild('closeAudit') auditReviewModal: ElementRef;

  AssetAssignType = AssetStatusEnum;
  assetAssignType: AssetStatusEnum;

  public PermissionsEnum = PermissionsEnum;

  editEnabled: boolean = false;
  id: string = '';
  selectedBuilding: EditBuilding = new EditBuilding();
  mobileUsers: User[] = [];

  buildingImage: File | null;
  selectedImage: string = '';
  ImageType = ImageType;
  EntityType = EntityType;
  updateStatusDisabled = false;
  fieldAuditDisabled = false;
  buName: string = this.translationService.getByKeyFromStorage('BU');
  utName: string = this.translationService.getByKeyFromStorage('UT');

  @ViewChild('closeModal') closeModal: ElementRef;
  @ViewChild('closeDQModal') closeDQModal: ElementRef;

  @ViewChild('userFilterInput') userFilter: ElementRef;

  filterText: string = '';
  isDropdownOpened: boolean = false;
  filteredMobileUsers: User[] = [];
  tempAssignedUserId: number = 0;
  imgElement: any;
  imgZoomFullContainer: any;
  currentRotation: number = 0;
  isGhanaPostFieldsVisible: boolean = true;
  isMapVisible: boolean = false;
  f11Options: F11Crud[] = [];
  f33Options: F33Crud[] = [];
  dtOptions: OptionObj[] = [];
  structureOptions: OptionObj[] = [];
  developmentStageOptions: OptionObj[] = [];
  structure = BuildingStructure;
  developmentStage = BuildingDevelopmentStage;
  editFieldsPopulated: boolean = false;
  showRecallMenu: boolean = false;
  recallAssignedAllowed: boolean = false;
  recallValidatedAllowed: boolean = false;
  modalRejectionReason?: string = '';
  isDataQuality: boolean = false;

  constructor(
    private buildingService: BuildingService,
    private dtService: DtService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private f33Service: F33Service,
    private f11Service: F11Service,
    public permissionsService: PermissionsService,
    private userService: UserService,
    private translationService: TranslationService,
    private el: ElementRef,
    private renderer: Renderer2,
    private activatedRoute: ActivatedRoute,
    private blobStorageService: BlobStorageService
  ) { }

  ngOnInit(): void {
    this.blobStorageService.setContainer(ContainerNames.Images);
    this.id = this.route.snapshot.paramMap.get('id') || '';
    this.isDataQuality = this.activatedRoute.snapshot.url[0].path == 'data-quality';

    this.imgElement = this.el?.nativeElement?.querySelector(
      '.ngxImageZoomThumbnail'
    );
    this.imgZoomFullContainer =
      this.el?.nativeElement?.querySelector('.ngxImageZoomFull');
    this.getSelectedBuilding(parseInt(this.id));
    this.checkUpdateStatusDisable(parseInt(this.id));
    this.setFieldsVisibility();
  }

  setModalType(type: AssetStatusEnum) {
    this.assetAssignType = type;
  }

  checkFieldAuditDisable() {
    if(this.selectedBuilding.connectedCustomers != null && this.selectedBuilding.connectedCustomers.length > 0) {
      this.fieldAuditDisabled = this.selectedBuilding.connectedCustomers.filter(x => x.isAudited == false).length > 0;
    };
  }

  assignTask(): void {
    if (
      this.mobileUsers.filter((x) => x.fullName == this.filterText.trim())
        .length > 0
    ) {
      this.selectedBuilding.assignedUserId = this.mobileUsers.filter(
        (x) => x.fullName == this.filterText.trim()
      )[0].id;
      this.tempAssignedUserId = this.selectedBuilding.assignedUserId;
    } else {
      this.tempAssignedUserId = 0;
    }
    if (this.tempAssignedUserId != 0) {
      this.buildingService
        .assignBuilding(
          this.selectedBuilding.id,
          this.selectedBuilding.assignedUserId,
          this.assetAssignType,
          this.isDataQuality
        )
        .subscribe({
          next: (response) => {
            const responseData = response.body;
            if (responseData?.status === 200 || responseData?.status === 'OK') {
              this.closeModal?.nativeElement?.click();
              this.toastr.success('Successfully assigned user');
              window.location.reload();
            } else {
              this.toastr.error(responseData?.message);
            }
          },
          error: (_) => {
            this.toastr.error('Error occured');
          },
        });
    } else {
      this.toastr.warning('Please select valid user.');
    }
  }
  checkUpdateStatusDisable(id: number) {
    this.buildingService.getConnectedCustomersDataQualityStatus(id).subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          console.log(responseData.data)
          this.updateStatusDisabled = responseData.data;
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }
  getSelectedBuilding(id: number) {
    this.buildingService.getById(id).subscribe({
      next: (data) => {
        this.selectedBuilding = new EditBuilding(data?.body?.data);
        this.filterText = this.selectedBuilding.assignedUser;
        this.tempAssignedUserId = this.selectedBuilding.assignedUserId;
        this.selectedBuilding.feederId = this.selectedBuilding.dt?.feeder?.id;
        this.selectedBuilding.dtId = this.selectedBuilding.dt
          ? this.selectedBuilding.dt.id
          : 0;

        if (this.permissionsService.isBuildingRecallAssignedAllowed
          && !(this.selectedBuilding.dataQualityStatus?.toString() != 'Rejected'
            && this.selectedBuilding.status != 'Assigned')) {
          this.recallAssignedAllowed = true;
        }

        if (this.permissionsService.isBuildingRecallValidatedAllowed
          && this.selectedBuilding.dataQualityStatus) {
          this.recallValidatedAllowed = true;
        }

        if(this.selectedBuilding.dataValidationReview != null && this.selectedBuilding.dataValidationReview.length > 0) {
          this.modalRejectionReason = this.selectedBuilding.dataValidationReview.find(x => x.key == 'Reason for rejection')?.value;
        }
        this.checkFieldAuditDisable();
      },
      error: (error) => {
        this.toastr.error('An error ocurred.');
      }
    });
  }

  edit() {
    this.editEnabled = true;
    if (!this.editFieldsPopulated) {
      this.getFeedersForOptions();
      this.getBuildingEnums();
      if (this.selectedBuilding?.feederId) {
        this.getDtsForOptions(this.selectedBuilding.feederId);
      }

      this.editFieldsPopulated = true;
    }
  }

  disableAuditReviewButton() {
    if (this.selectedBuilding.auditedStatus === 'Audited' || this.fieldAuditDisabled) return true;
    else return false;
  }

  cancel() {
    this.auditReviewModal?.nativeElement?.click();
  }

  selectOption(e: any) {
    this.selectedBuilding.assignedUserId = +e.target.value;
  }

  save() {
    if (
      !this.selectedBuilding.closestLandmark ||
      !this.selectedBuilding.buildingOwner
    ) {
      this.toastr.error(
        'Closest landmark and building owner can not be empty!'
      );
      return;
    }
    if(!this.selectedBuilding.dtId){
      this.toastr.error(
        'DT name can not be empty!'
      );
      return;
    }

    let saveModel = new BuildingSave();
    saveModel.closestLandmark = this.selectedBuilding.closestLandmark;
    saveModel.buildingOwner = this.selectedBuilding.buildingOwner;
    saveModel.id = this.selectedBuilding.id;
    saveModel.assignedUserId = this.selectedBuilding.assignedUserId;
    saveModel.buildingDevelopmentStage = this.selectedBuilding.buildingDevelopmentStage;
    saveModel.buildingStructure = this.selectedBuilding.buildingStructure;
    saveModel.dtId = this.selectedBuilding.dtId;
    saveModel.address = this.selectedBuilding.address;

    this.buildingService.addOrUpdateBuilding(saveModel).subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.editEnabled = false;
          this.toastr.success('Successfully edited building');
          window.location.reload();
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  getMobileUsers() {
    this.userService
      .getMobileUsers(this.selectedBuilding.areaId, this.filterText)
      .subscribe({
        next: (response) => {
          const responseData = response.body;
          if (responseData?.status === 200 || responseData?.status === 'OK') {
            this.mobileUsers = responseData.data;
            if (this.mobileUsers.length == 0) {
              this.isDropdownOpened = false;
            } else {
              this.isDropdownOpened = true;
            }
          } else {
            this.toastr.error(responseData?.message);
          }
        },
        error: (_) => {
          this.toastr.error('Error occured');
        },
      });
  }

  navigateToCustomer(customer: any) {
    if (customer.isOnboard) {
      window.open(`/customers/edit-onboard/${customer.id}`);
    } else {
      window.open(`/customers/edit/${customer.id}`);
    }
  }
  getBuildingMountedImage() {
    return this.selectedBuilding.images?.find(
      (x) => x.imageType === 'MountedTag'
    )?.url;
  }

  getFrontViewImage(id: number) {
    return this.selectedBuilding.images?.filter(
      (x) => x.imageType === 'Frontview'
    )[id]?.url;
  }

  fileBrowserHandler($event: any, type: ImageType) {
    this.buildingImage = $event.target.files.item(0);
  
    if (!this.buildingImage) {
      this.toastr.error('Image data is missing');
      return;
    }
  
    this.blobStorageService.uploadFile(
      this.buildingImage,
      this.buildingImage.name,
      (uploadedUrl) => {
        const obj = {
          imageType: type,
          buildingId: this.selectedBuilding.id,
          guid: this.selectedBuilding.guid,
          url: uploadedUrl 
        };
  
        this.buildingService.uploadPicture(obj, this.buildingImage!).subscribe({
          next: (response) => {
            const responseData = response.body;
            if (responseData?.status === 200 || responseData?.status === 'OK') {
              this.selectedBuilding.images.push(responseData.data);
              this.toastr.success('Successfully added image');
            } else {
              this.toastr.error(responseData?.message);
            }
          },
          error: (_) => {
            this.toastr.error('Error occurred while saving image metadata');
          },
        });
      },
      (error) => {
        console.error('Error uploading image to blob storage:', error);
        this.toastr.error('Failed to upload image to storage');
      }
    );
  }
  showImage(num: number) {
    if (num === 0) {
      this.selectedImage = this.getFrontViewImage(0);
    } else if (num === 1) {
      this.selectedImage = this.getFrontViewImage(1);
    } else if (num === 2) {
      this.selectedImage = this.getFrontViewImage(2);
    } else if (num === 3) {
      this.selectedImage = this.getBuildingMountedImage() || '';
    }
  }
  showInaccessibleImage(url: string) {
    console.log(url);
    this.selectedImage = url;
  }
  renderMeterStatus() {
    let meteredUsers = this.selectedBuilding.connectedCustomers.filter(
      (x) => x.meterNumber !== ''
    );
    return meteredUsers.length > 0;
  }
  cancelModal() {
    this.closeModal?.nativeElement?.click();
  }

  selectFieldAgent(id: number) {
    this.isDropdownOpened = false;
    this.selectedBuilding.assignedUserId = id;
    this.tempAssignedUserId = id;
    var mobUser = this.mobileUsers.find((x) => x.id == id);
    this.filterText = mobUser!.fullName;
  }

  recallAssigned() {
    this.buildingService.recall(parseInt(this.id), RecallType.Assigned).subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.toastr.success('Successfully recalled building.'); -
            window.location.reload();
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  recallValidation() {
    this.buildingService.recall(parseInt(this.id), RecallType.Validated).subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.toastr.success('Successfully recalled building\'s Data Quality.');
          window.location.reload();
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  filterMobileUsers() {
    this.isDropdownOpened = true;
    this.tempAssignedUserId = 0;
    this.getMobileUsers();
  }
  openDropdown() {
    this.isDropdownOpened = true;
  }

  onModalAssignFade() {
    this.filterText = this.selectedBuilding.assignedUser;
  }

  onModalFade() {
    this.currentRotation = 0;

    if (this.imgZoomFullContainer) {
      var displayValue = getComputedStyle(this.imgZoomFullContainer).display;

      if (displayValue == 'block') {
        this.imgElement.click();
      }
    }
  }

  rotateLeft() {
    this.currentRotation -= 90;
  }

  rotateRight() {
    this.currentRotation += 90;
  }
  closeRedirectModal() {
    this.closeDQModal?.nativeElement?.click();
  }
  onModalOpen() {
    this.renderer.setStyle(document.body, 'overflow', 'auto');
    this.renderer.setStyle(document.body, 'overflow-x', 'hidden');
    this.renderer.setStyle(document.body, 'padding-right', '0');
  }

  @HostListener('document:click', ['$event'])
  handleDocumentClick(event: MouseEvent) {
    const clickedInside =
      this.isDropdownOpened &&
      (this.dropdownContainer?.nativeElement?.contains(event.target) ||
        this.userFilter?.nativeElement?.contains(event.target));
    if (!clickedInside) {
      this.isDropdownOpened = false;
    }
  }

  setFieldsVisibility() {
    let notVisibleFields: any = localStorage.getItem('notVisibleFields');

    if (!notVisibleFields) {
      return;
    }

    notVisibleFields = JSON.parse(notVisibleFields);
    this.isGhanaPostFieldsVisible = notVisibleFields.find(
      (el: any) => el === 'ghanaPostCode'
    )
      ? false
      : true;
  }

  selectFeeder() {
    this.selectedBuilding.dtId = 0;
    this.getDtsForOptions(this.selectedBuilding.feederId);
  }

  getDtsForOptions(feederId: number) {
    if (this.selectedBuilding.dt?.feeder.feederType === FeederType.NOT_SELECTED) {
        return;
    }
    this.dtService
      .getAllForSelectByFeeder(
        feederId,
        this.selectedBuilding.dt?.feeder?.feederType.toString()
      )
      .subscribe({
        next: (response) => {
          const responseData = response.body;
          if (responseData?.status === 200 || responseData?.status === 'OK') {
            this.dtOptions = response.body.data;
          } else {
            this.toastr.error(responseData?.message);
          }
        },
        error: (_) => {
          this.toastr.error('Error occured');
        },
      });
  }

  getFeedersForOptions() {
    this.f11Service.getAllForSelect().subscribe(
      (response) => {
        this.f11Options = response.body.data;
      },
      (error) => {
        this.toastr.error('An error occurred.');
      }
    );

    this.f33Service.getAllForSelect().subscribe(
      (response) => {
        this.f33Options = response.body.data;
      },
      (error) => {
        this.toastr.error('An error occurred.');
      }
    );
  }
  getBuildingEnums() {
    Object.values(BuildingStructure)
      .filter((v) => !isNaN(Number(v)))
      .forEach((key) => {
        const optionObject: OptionObj = {
          key: Number(key),
          value: BuildingStructure[key as number]
        };
        this.structureOptions.push(optionObject);
      });

    Object.values(BuildingDevelopmentStage)
      .filter((v) => !isNaN(Number(v)))
      .forEach((key) => {
        const optionObject: OptionObj = {
          key: Number(key),
          value: BuildingDevelopmentStage[key as number]
        };
        this.developmentStageOptions.push(optionObject);
      });
  }
}
