import {
  ApplicationRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import {
  AddUtility,
  DashboardConfiguration,
} from 'src/app/models/utility.model';
import { UtilityService } from 'src/app/services/utility.service';
import { Router } from '@angular/router';
import { KeyValuePair } from 'src/app/models/keyValueItem.model';
import { Translation } from 'src/app/models/translation.model';
import { CurrencyList } from 'src/app/models/currency.model';

@Component({
  selector: 'app-add-utility',
  templateUrl: './add-utility.component.html',
  styleUrls: ['./add-utility.component.scss'],
})
export class AddUtilityComponent implements OnInit {
  @ViewChild('utilityImage') utilityImageInput: ElementRef;
  @ViewChild('closeModal') closeModal: ElementRef;

  saveDisabled: boolean = true;
  utilityImage: File | null;
  blobUrl: string = '';
  name: string = '';
  hasImage: boolean = false;
  imageSrc: string | ArrayBuffer;
  color: string = '';
  selectedConfiguration: DashboardConfiguration[] = [];
  translationKeys: Translation[] = [];
  selectedTranslation: Translation = new Translation();

  commission: string = '';
  reform: string = '';
  regulation: string = '';
  management: string = '';
  country: string = '';


  firstName: string = '';
  lastName: string = '';
  eMail: string = '';

  bankName: string = '';
  accountName: string = '';
  accountNumber: string = '';
  currencyList = CurrencyList;
  currency : string |undefined = '';
  public Configuration = DashboardConfiguration;

  constructor(
    private location: Location,
    private toastr: ToastrService,
    private utilityService: UtilityService,
    private router: Router,
    private appRef: ApplicationRef
  ) {}

  ngOnInit(): void {
    this.getUtilityKeys();
  }
  
  activeCard = 1;
  readonly cardsCount = 5;

  cancelClicked() {
    this.location.back();
  }

  getUtilityKeys() {
    var jsonTranslations = localStorage.getItem('translation');
    var data = JSON.parse(jsonTranslations!);
    this.translationKeys = data;
  }

  save() {
    let obj = {
      name: this.name,
      currency:this.currency,
      colorCode: this.color,
      dashboardConfiguration: this.selectedConfiguration,
      translations: this.translationKeys,
      kycConsentConfiguration: {
        regulatoryCommission: this.commission,
        electricityReform: this.reform,
        networkRegulation: this.regulation,
        electricityManagement: this.management,
        country: this.country
      },
      contactPerson :{
        firstName:this.firstName,
        lastName:this.lastName,
        eMail:this.eMail
      },
      bankDetails: {
        bankName: this.bankName,
        accountName: this.accountName,
        accountNumber: this.accountNumber
      }
    };
    if (!this.utilityImage) {
        this.toastr.error('Image data is missing');
        return
    }
    this.utilityService.add(obj, this.utilityImage).subscribe({
      next: (response) => {
        const responseData = response;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          if (responseData.message === 'Successfuly added utility') {
            this.toastr.success(responseData.message);
            var utilityId = parseInt(response.data, 10);
            if (!isNaN(utilityId)) {
              this.addNewSubdomain(utilityId);
            } else {
              this.toastr.error('Failed to retrieve valid utility ID.');
            }
          } else {
            this.toastr.warning(responseData.message);
          }
        } else {
          console.log('There was an error, not 200 and NOK ' + responseData?.message);
          this.toastr.error(responseData?.message);
        }
      },
      error: (error) => {
        console.log(error.message);
        this.toastr.error('Error occured');
      },
    });
  }
  
  addNewSubdomain(utilityId: number) {
    this.utilityService.addNewSubdomain(utilityId).subscribe({
      next: (response) => {
        if(response?.status === 'Pending') {
          this.toastr.info(response?.message);
          this.router.navigate(['/utility-management']);
        }
      },
      error: (error) => {
        console.log(error.message);
        this.toastr.error('Error occured');
      }
    })
  }

  setActiveCard(activeCard: number): void {
    this.activeCard = activeCard;
    this.saveDisabled = true;
  }
  removeImage() {
    this.blobUrl = '';
    this.utilityImage = null;
    this.hasImage = false;
  }
  selectCurrency(e: any) {
    if (e.target.value != '') {
      this.currency = e.target.value;
    } else {
      this.currency = undefined;
    }
    
  }
  continue(): void {
    if (this.activeCard === 1) {
      if (this.color === '') {
        this.toastr.warning('Please select color code.');
        return;
      }
      if (this.name === '') {
        this.toastr.warning('Please fill out the name field.');
        return;
      }

      if (this.utilityImage === undefined || this.utilityImage === null) {
        this.toastr.warning('Please add the utility logo.');
        return;
      }
    }
    if (this.activeCard === 2) {
      if (this.selectedConfiguration.length === 0) {
        this.toastr.warning('Please select some Assets/Operations.');
        return;
      }
    }
    this.activeCard++;
    if (this.activeCard > this.cardsCount) {
      this.saveDisabled = false;
    }
  }

  isChecked(listConfig: any) {
    let checked = true;
    listConfig.forEach((val: any) => {
      if (!this.selectedConfiguration.includes(val)) checked = false;
    });
    return checked;
  }
  isSelected(config: any) {
    let checked = 'On';
    if (!this.selectedConfiguration.includes(config)) {
      checked = 'Off';
    }
    return checked;
  }
  fileBrowserHandler($event: any) {
    this.utilityImage = $event.target.files.item(0);
    if (
      this.utilityImage?.type != 'image/png' &&
      this.utilityImage?.type != 'image/jpg' &&
      this.utilityImage?.type != 'image/jpeg'
    ) {
      this.toastr.warning('Only PNG/JPG/JPEG images are allowed');
      if (this.utilityImageInput?.nativeElement) {
        this.utilityImageInput.nativeElement.value = '';
      }
    } else {
      this.hasImage = true;
      const reader = new FileReader();
      var width = 0;
      var height = 0;
      reader.onload = (e) => {
        this.imageSrc = reader.result!;
        const img = new Image();
        img.src = e.target!.result as string;

        img.onload = () => {
          width = img.width;
          height = img.height;
          var ratio = width / height;
          if (ratio > 2 || ratio < 0.5) {
            this.toastr.warning('Unsuported image size.');
            this.imageSrc = '';
            this.hasImage=false;
            this.utilityImage = null;
            this.appRef.tick();
          }
        };
      };
      reader.readAsDataURL(this.utilityImage!);
    }
  }

  onChange(configuration: any, event: any = null) {
    configuration.forEach((value: any) => {
      if (event != null) {
        if (!this.isChecked([value]) && event.target.checked == true)
          this.selectedConfiguration.push(value);
        else if (this.isChecked([value]) && event.target.checked == false)
          this.selectedConfiguration = this.selectedConfiguration.filter(
            (item: any) => item != value
          );
      } else {
        if (this.selectedConfiguration.includes(value))
          this.selectedConfiguration = this.selectedConfiguration.filter(
            (item: any) => item != value
          );
        else this.selectedConfiguration.push(value);
      }
    });
  }
  onFileDropped($event: any) {
    this.utilityImage = $event.item(0);
    if (
      this.utilityImage?.type != 'image/png' &&
      this.utilityImage?.type != 'image/jpg' &&
      this.utilityImage?.type != 'image/jpeg'
    ) {
      this.toastr.warning('Only PNG/JPG/JPEG images are allowed');
      if (this.utilityImageInput?.nativeElement) {
        this.utilityImageInput.nativeElement.value = '';
      }
    } else {
      this.hasImage = true;
      const reader = new FileReader();
      var width = 0;
      var height = 0;
      reader.onload = (e) => {
        this.imageSrc = reader.result!;
        const img = new Image();
        img.src = e.target!.result as string;

        img.onload = () => {
          width = img.width;
          height = img.height;
          var ratio = width / height;
          if (ratio > 2 || ratio < 0.5) {
            this.toastr.warning('Unsuported image size.');
            this.imageSrc = '';
            this.hasImage=false;
            this.utilityImage = null;
            this.appRef.tick();
          }
        };
      };      reader.readAsDataURL(this.utilityImage!);
    }
  }

  selectTranslation(item: Translation) {
    this.selectedTranslation = {...item};
  }

  saveTranslation() {
    var indexToChange = this.translationKeys.findIndex(item => item.key == this.selectedTranslation.key);
    this.translationKeys[indexToChange] = this.selectedTranslation;
    this.closeModal?.nativeElement?.click();
  }
}
