<div *ngIf="graphData" class="bar-legend anti-zoom">
        <div  *ngFor="let item of graphData.datasets let i = index" class="bar-item">
                <div class="dot" [ngClass]="item.label != undefined ? (item.label | lowercase) : ''"></div>
                <span>{{item.label! | translate}}</span>
        </div>
</div>
<div class="w-100">
    <canvas  baseChart
        [data]="graphData"
        [options]="barChartOptions"
        [plugins]="barChartPlugins"
        [legend]="barChartLegend"
        [type]="'bar'">
    </canvas>       
</div>