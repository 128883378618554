import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from '../config/config';
import { Observable, filter } from 'rxjs';
import { ItineraryPart } from '../models/area.model';

@Injectable({
  providedIn: 'root',
})
export class ReadingService {
  private pathApi = this.config.setting['pathApi'] + 'reading/';

  constructor(private http: HttpClient, private config: AppConfig) {}

  assignNewReading(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}assignNewReading`, obj, {
      observe: 'response',
    });
  }
}
