<div class="view-details-container" *ngIf="detailsShowed">
  <app-create-role
    [isPopUp]="true"
    [role]="roleForEdit"
    [isEditMode]="isEditMode"
    (closePopUpChange)="closeModal()"
  ></app-create-role>
</div>
<div class="topBar">
  <button
    class="btn btn-primary btn-add-new"
    (click)="saveChanges()"
    *ngIf="permissionsService.isRoleManagementCreateEditAllowed"
  >
    <span>{{ "COMMON.SAVE_CHANGES" | translate }}</span>
  </button>
</div>
<div class="container">
  <div class="column column-1">
    <div class="crud-search">
      <button
        class="btn btn-primary btn-add-new"
        (click)="openCreateModal()"
        *ngIf="permissionsService.isRoleManagementCreateEditAllowed"
      >
        <img src="../../assets/icons/plus.svg" alt="Plus icon" />
        <span
          >{{ "CRUD_SETTINGS.ADD_NEW" | translate }}
          {{ "ROLE_MANAGEMENT.ROLE_TYPE" | translate | lowercase }}</span
        >
      </button>
    </div>
    <div class="menu">
      <div
        *ngFor="let role of roles"
        class="menu-item"
        [ngClass]="{ 'item-active': currentRole.id == role.id }"
      >
        <a class="nav-item" (click)="setCurrentRole(role.id)">
          <span>{{ role.name }}</span>
          <div class="role-icons" *ngIf="!role.isDisabled">
            <svg
              *ngIf="
                !role.isDisabled &&
                permissionsService.isRoleManagementDeleteAllowed
              "
              data-bs-toggle="modal"
              data-bs-target="#deleteRole"
              width="22"
              height="22"
              viewBox="0 0 22 22"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22ZM11 20C15.9706 20 20 15.9706 20 11C20 6.02944 15.9706 2 11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20ZM6 11C6 10.4477 6.44772 10 7 10H15C15.5523 10 16 10.4477 16 11C16 11.5523 15.5523 12 15 12H7C6.44772 12 6 11.5523 6 11Z"
                fill="#DE350B"
              />
            </svg>
            <img
              src="../../assets/icons/edit-user.svg"
              alt="Edit role icon"
              (click)="editRole(role.id, role.isDisabled)"
              *ngIf="permissionsService.isRoleManagementCreateEditAllowed"
            />
          </div>
        </a>
      </div>
    </div>
  </div>
  <div class="column column-2">
    <span class="title">{{ currentRole.name }}</span>
    <app-permission-menu
      [(permissions)]="currentRole.permissions"
      [isMobile]="currentRole.systemComponent == 1"
      [isDisabled]="currentRole.isDisabled"
    ></app-permission-menu>
  </div>
</div>
<div
  class="modal fade"
  id="deleteRole"
  tabindex="-1"
  aria-labelledby="deleteTsTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header delete-modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">
          {{ "CRUD_SETTINGS.ARE_YOU_SURE_DELETE_SHORT" | translate }}
          {{ currentRole.name }}
          {{ "ROLE_MANAGEMENT.ROLE" | translate | lowercase }}?
        </h5>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-danger"
          data-bs-dismiss="modal"
          (click)="deleteRole(currentRole.id)"
        >
          {{ "COMMON.DELETE" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
