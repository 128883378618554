import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject, debounceTime, fromEvent } from 'rxjs';
import { CustomerStatsOptions } from 'src/app/enums/customer-stats-options.enum';
import { AccountKycList } from 'src/app/models/accountKyc.model';
import { Area } from 'src/app/models/area.model';
import { ChecklistItem } from 'src/app/models/checklist-item.model';
import { AssetStatus, BuildingType,  FilterByOptions, ServiceType, SortByOptions, UserType } from 'src/app/models/customer.model';
import { KeyValueItem } from 'src/app/models/keyValueItem.model';
import { NewServiceStats } from 'src/app/models/new-service-stats.model';
import { Region } from 'src/app/models/region.model';
import { SystemComponents } from 'src/app/models/role.model';
import { Tariff } from 'src/app/models/tariff.model';
import { UserForFilter } from 'src/app/models/user.model';
import { OptionObj } from 'src/app/models/util.model';
import { AreaService } from 'src/app/services/area.service';
import { CustomerService } from 'src/app/services/customer.service';
import { AccountKycService } from 'src/app/services/kyc/account-kyc.service';
import { RegionService } from 'src/app/services/region.service';
import { TariffService } from 'src/app/services/tariff.service';
import { TranslationService } from 'src/app/services/translation.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-new-service',
  templateUrl: './new-service.component.html',
  styleUrls: ['./new-service.component.scss']
})
export class NewServiceComponent  implements OnInit {
  buName: string = this.translationService.getByKeyFromStorage('BU');
  utName: string = this.translationService.getByKeyFromStorage('UT');
  
  tag?: number;
  tagOptions = [
    { name: 'Existing', value: 0, translation: 'COMMON.TAG.EXISTING' },
    { name: 'New entry', value: 1, translation: 'COMMON.TAG.NEW_ENTRY' },
  ];
  status?: number;
  statusOptions = [
    { name: 'Pending', value: AssetStatus.Pending, translation: 'COMMON.ASSET_STATUS.PENDING' },
    { name: 'Complete', value: AssetStatus.Complete, translation: 'COMMON.ASSET_STATUS.COMPLETE' },
    { name: 'Rejected', value: AssetStatus.Rejected, translation: 'COMMON.ASSET_STATUS.REJECTED' },
    { name: 'Assigned', value: AssetStatus.Assigned, translation: 'COMMON.ASSET_STATUS.ASSIGNED' },
  ];
  userType?: number;
  userTypeOptions = [
    { name: 'First Captured User', value: UserType.FirstCapturedUser, translation: 'COMMON.USER.TYPE.FIRST_CAPTURED' },
    { name: 'Last Captured User', value: UserType.LastCapturedUser, translation: 'COMMON.USER.TYPE.LAST_CAPTURED' },
    { name: 'Reassigned By', value: UserType.ReassignedBy, translation: 'COMMON.USER.TYPE.REASSIGNED' },
    { name: 'Validated By', value: UserType.ValidatedBy, translation: 'COMMON.USER.TYPE.VALIDATED' },
    { name: 'Audited By', value: UserType.AuditedBy, translation: 'COMMON.USER.TYPE.AUDITED' },
  ];
  serviceType?: number;
  serviceTypeOptions = [
    { name: 'First Captured User', value: ServiceType.Large, translation: 'COMMON.SERVICE_TYPE.LARGE' },
    { name: 'Last Captured User', value: ServiceType.Small, translation: 'COMMON.SERVICE_TYPE.SMALL' },
    { name: 'Reassigned By', value: ServiceType.Prepaid, translation: 'COMMON.SERVICE_TYPE.PREPAID' },
    { name: 'Validated By', value: ServiceType.AMR, translation: 'COMMON.SERVICE_TYPE.AMR' },
    { name: 'Audited By', value: ServiceType.AMR, translation: 'COMMON.SERVICE_TYPE.AMR' },
  ];
  searchByOptions = [
    { name: 'AccountNumber', value: 'Account No.', translation: 'CUSTOMERS.SEARCH_BY.ACCOUNT_NO' },
    { name: 'Slrn', value: 'SLRN' , translation: 'CUSTOMERS.SEARCH_BY.SLRN' },
    { name: 'MeterNumber', value: 'Meter No.', translation: 'CUSTOMERS.SEARCH_BY.METER_NO' },
    { name: 'BuildingOwner', value: 'Building Owner', translation: 'CUSTOMERS.SEARCH_BY.BUILDING_OWNER' },
    { name: 'PhoneNumber', value: 'Phone No.', translation: 'CUSTOMERS.SEARCH_BY.PHONE_NO' },
    { name: 'Email', value: 'Email', translation: 'CUSTOMERS.SEARCH_BY.EMAIL' },
  ];
  dateTypes = [
    { name: 'LastUpdateTime', value: 'Last Update Time', translation: 'COMMON.DATE_TYPE.LAST_UPDATE' },
    { name: 'TaggedDate', value: 'Tagged Date', translation: 'COMMON.DATE_TYPE.TAGGED' },
    { name: 'ValidatedDate', value: 'Validated Date', translation: 'COMMON.DATE_TYPE.VALIDATED' },
  ];
  kycTagOptions = [
    { name: 'Existing', value: 0, translation: 'COMMON.TAG.EXISTING' },
    { name: 'Onboard', value: 1, translation: 'COMMON.TAG.ONBOARD' },
  ];
  kycStatus?: number;
  kycStatusOptions = [
    { name: 'Pending', value: 0, translation: 'COMMON.KYC_STATUS_OPTIONS.PENDING' },
    { name: 'Rejected', value: 1, translation: 'COMMON.KYC_STATUS_OPTIONS.REJECTED' },
    { name: 'In Progress', value: 2, translation: 'COMMON.KYC_STATUS_OPTIONS.INPROGRESS' },
  ];
  uopOptions = [
    { name: 'Residential', translation: 'COMMON.BUILDING_TYPE.RESIDENTIAL', value: BuildingType.Residential },
    { name: 'Commercial', translation: 'COMMON.BUILDING_TYPE.COMMERCIAL', value: BuildingType.Commercial },
    { name: 'Industrial', translation: 'COMMON.BUILDING_TYPE.INDUSTRIAL', value: BuildingType.Industrial },
    { name: 'Mixed', translation: 'COMMON.BUILDING_TYPE.MIXED', value: BuildingType.Mixed },
    { name: 'Religious House', translation: 'COMMON.BUILDING_TYPE.RELIGIOUSHOUSE', value: BuildingType.ReligiousHouse },
  ];
  mobileUserType: UserType[] = [
    UserType.FirstCapturedUser,
    UserType.LastCapturedUser,
  ];
  adminUserType: UserType[] = [
    UserType.ValidatedBy,
    UserType.ReassignedBy,
    UserType.AuditedBy,
  ];
  sltStatus?: number;
  sltOptions: OptionObj[] = [];
  sltStatusName: string = this.translationService.getByKeyFromStorage('SltStatus');
  showUpload: boolean = false;
  showSortBy: boolean = false;
  mapSortBy: Record<number, any> = {
    [SortByOptions.NEWEST]: { label: 'Newest', translation: 'COMMON.SORT_BY.NEWEST', arrow: 'no' },
    [SortByOptions.OLDEST]: { label: 'Oldest', translation: 'COMMON.SORT_BY.OLDEST', arrow: 'no' },
  };
  
  selectedFilters: ChecklistItem[] = [];
  isServiceTypeFieldVisible: boolean = true;
  useOfPremises?: number;
  kycTag?: number;
  customerStats: NewServiceStats = new NewServiceStats();


  //tab counts
  count: number = 2;
  stats: KeyValueItem[] = [];
  
  isRegularizationUploadVisible: boolean = true;
  withAccNoSelected = 0;
  
  pageSize: number = 10;
  currentPage: number = 1;
  currentTab: number = 1;
  currentTabName: string = 'New Connection';
  
  searchText: string = '';
  searchFilterApplied: boolean = false;
  searchTextUpdate = new Subject<string>();

  showSelectSearchType: boolean = false;
  selectedSearchType: string;
  selectedSearchTypeDisplay: string;
  selectedDateType: string;
  selectedDateTypeDisplay: string;
  showSelectDateType: boolean = false;

  customerType: number = 0;
  showFilterBy: boolean = false;
  clicked: boolean = false;
  utilityId: number = 0;
  reset: boolean = false;
  
  dateFrom: string;
  dateTo: string;
  
  sortByValue: SortByOptions = SortByOptions.NEWEST;
  sortByOptions = SortByOptions;
  sortByLabelValue: any = this.mapSortBy[SortByOptions.NEWEST];
  filterByOptions = FilterByOptions;
  filterByValue?: FilterByOptions = FilterByOptions.ONBOARDED;
  
  usersFilter: any; // Stores value of filter
  mobileUsers: UserForFilter[] = []; // Store only mobile users
  adminUsers: UserForFilter[] = []; // Store only admin users
  allUsers: UserForFilter[] = []; // Store mobile or admin users depending on filter
  filteredUsers: UserForFilter[] = []; // Used for display and for filtering users list
  selectedUsers: number[] = [];
  
  regions: Region[] = [];
  areas: Area[] = [];
  allAreas: Area[] = [];
  tariff: number = 0;
  tariffs: Tariff[] = [];
  selectedAreas: number[] = [];
  selectedRegions: number[] = [];
  
  currency: string = '';
  allCheckboxesChecked: boolean = false;
  accountKycs: AccountKycList[] = [];
  
  clickObservable: Observable<Event> = fromEvent(document, 'click');
  private ngUnsubscribe = new Subject<void>();


  constructor(
    private toastr: ToastrService,
    private translationService: TranslationService,
    private kycAccountService: AccountKycService,
    private customerService: CustomerService,
    private regionService: RegionService,
    private areaService: AreaService,
  ) {}

  ngOnInit(): void {
    this.utilityId = parseInt(localStorage.getItem('utilityId') || '');
    this.currency = localStorage.getItem('currency') || '';
    this.setFilters();
    this.getRegions();
    this.getCustomerStats();
    this.getData();
    this.searchTextUpdate.pipe(debounceTime(500)).subscribe((_) => {
      if (this.searchText == '') this.search();
      this.searchFilterApplied = false;
    });
    this.selectedDateType = this.selectedDateType ?? this.dateTypes[0].name;
    this.selectedDateTypeDisplay =
      this.selectedDateTypeDisplay ?? this.dateTypes[0].translation;

    this.selectedSearchType =
      this.selectedSearchType ?? this.searchByOptions[0].name;
    this.selectedSearchTypeDisplay =
      this.selectedSearchTypeDisplay ?? this.searchByOptions[0].translation;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.searchTextUpdate.complete();
  }

  pageChange(value: any) {
    this.currentPage = value;
    this.reloadTable();
  }

  setFilterStorage() {
    let obj = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize,
        count: this.count,
      },
      filterParams: this.getFilterObjectForStorage(),
      utilityId: this.utilityId,
    };

    localStorage.setItem('cameFrom', 'Customer');
    localStorage.setItem(
      'filterCustomer',
      JSON.stringify({ type: 'Customer', filter: obj })
    );
  }

  getFilterObjectForStorage() {
    let obj = {
      sortBy: this.sortByValue,
      search: this.searchText,
      filterBy: this.filterByValue,
      dateFrom: this.dateFrom ? this.dateFrom : null,
      dateTo: this.dateTo ? this.dateTo : null,
      dateType: this.selectedDateType,
      searchType: this.selectedSearchType,
      selectedAreas: this.selectedAreas.length > 0 ? this.selectedAreas : null,
      selectedRegions:
        this.selectedRegions.length > 0 ? this.selectedRegions : null,
      selectedIds: this.accountKycs
        .filter((x) => x.isChecked == true)
        .map((x) => x.id),
      kycStatus: this.kycStatus ? this.kycStatus : null,
    };
    return obj;
  }

  checkAllCheckboxes() {
    this.accountKycs.forEach((x) => (x.isChecked = !this.allCheckboxesChecked));
    this.allCheckboxesChecked = !this.allCheckboxesChecked;
  }

  private getData(): void {
    this.reloadTable();
  }
  

  private getRegions() {
    this.regionService.getAllForSelect().subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.regions = responseData.data;
          this.setCheckedRegions();
          this.getAreas();
        } else if (response?.status == 204) {
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  private getAreas() {
    this.areaService.getAllForSelect().subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.allAreas = responseData.data;
          this.areas = this.allAreas.filter((area) =>
            this.regions.some(
              (region) => region.id == area.regionId && region.checked
            )
          );
          this.setCheckedAreas();
          this.createFilterCards();
          this.removeFilterStorage();
        } else if (response?.status == 204) {
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  removeFilterStorage() {
    if (
      this.allAreas.length > 0 &&
      this.regions.length > 0 &&
      this.tariffs.length > 0
    )
      localStorage.removeItem('filterCustomer');
  }

  setCheckedAreas() {
    var filter = localStorage.getItem('filterCustomer');
    if (!filter) return;
    var filterObject = JSON.parse(filter);
    if (filterObject.type != 'Customer') return;
    if (filterObject.filter.filterParams.selectedAreas) {
      this.selectedAreas = filterObject.filter.filterParams.selectedAreas;
      this.areas = this.allAreas.filter((area) =>
        this.regions.some(
          (region) => region.id == area.regionId && region.checked
        )
      );
      this.areas.forEach((element) => {
        if (this.selectedAreas.includes(element.id)) element.checked = true;
        else element.checked = false;
      });
    }
  }

  setCheckedRegions() {
    var filter = localStorage.getItem('filterCustomer');
    if (!filter) return;
    var filterObject = JSON.parse(filter);
    if (filterObject.type != 'Customer') return;
    if (filterObject.filter.filterParams.selectedRegions) {
      this.selectedRegions = filterObject.filter.filterParams.selectedRegions;
      this.regions.forEach((element) => {
        if (this.selectedRegions.includes(element.id)) element.checked = true;
        else element.checked = false;
      });
    }
  }

  setFilters() {
    var filter = localStorage.getItem('filterCustomer');
    if (!filter) return;
    var filterObject = JSON.parse(filter);
    if (filterObject.type != 'Customer') return;
    this.currentPage = filterObject.filter.pageInfo.page;
    this.pageSize = filterObject.filter.pageInfo.pageSize;
    this.count = filterObject.filter.pageInfo.count;
    this.sortByValue = filterObject.filter.filterParams.sortBy;
    this.searchText = filterObject.filter.filterParams.search;
    this.filterByValue = filterObject.filter.filterParams.filterBy;
    this.dateFrom = filterObject.filter.filterParams.dateFrom;
    this.dateTo = filterObject.filter.filterParams.dateTo;
    this.selectedDateType = filterObject.filter.filterParams.dateType;
    this.selectedSearchType = filterObject.filter.filterParams.searchType;
    this.selectedDateTypeDisplay =
      this.dateTypes.find(
        (type) => type.name == filterObject.filter.filterParams.dateType
      )?.translation ?? this.dateTypes[0].translation;
    this.selectedSearchTypeDisplay =
      this.searchByOptions.find(
        (type) => type.name == filterObject.filter.filterParams.searchType
      )?.translation ?? this.searchByOptions[0].translation;
    this.selectedAreas = filterObject.filter.filterParams.selectedAreas
      ? filterObject.filter.filterParams.selectedAreas
      : [];
    this.selectedRegions = filterObject.filter.filterParams.selectedRegions
      ? filterObject.filter.filterParams.selectedRegions
      : [];

    this.sortByLabelValue = this.mapSortBy[this.sortByValue];

    this.kycStatus = filterObject.filter.filterParams.kycStatus
      ? filterObject.filter.filterParams.kycStatus
      : undefined;
    
  }

  applySearchFilter(e: any) {
    this.filteredUsers = this.allUsers.filter((user) => user.name.includes(e));
  }

  selectKycStatus(e: any) {
    if (e.target.value != '') {
      this.kycStatus = e.target.value;
    } else {
      this.kycStatus = undefined;
    }
    this.createFilterCards();
  }
  
  createFilterCards() {
    this.selectedFilters = [];

    let selectedAreas = this.areas.filter((x) => x.checked);

    this.selectedAreas = this.areas.filter((x) => x.checked).map((x) => x.id);
    this.selectedUsers = this.allUsers
      .filter((x) => x.checked)
      .map((x) => x.id);
    this.selectedRegions = this.regions
      .filter((x) => x.checked)
      .map((x) => x.id);

    //areas
    for (var i = 0; i < selectedAreas.length; i++) {
      this.selectedFilters.push(
        new ChecklistItem(this.utName, selectedAreas[i].name, 'COMMON.UT.SINGLE')
      );
    }

    if (this.kycStatus) {
      var status = this.kycStatusOptions[this.kycStatus];
      this.selectedFilters.push(
        new ChecklistItem('Kyc Status', status.name, 'COMMON.KYC_STATUS_OPTIONS.SINGLE', status.translation)
      );
    }
  }

  selectRegion(e: any) {
    //list of areas for selected regions
    this.areas = this.allAreas.filter((area) =>
      this.regions.some(
        (region) => region.id == area.regionId && region.checked
      )
    );
    this.allAreas.forEach((area) => {
      if (!this.areas.some((a) => a.id === area.id)) {
        area.checked = false; //uncheck areas for unchecked regions
      }
    });
    this.createFilterCards();
  }

  selectArea(e: any) {
    this.createFilterCards();
  }

  removeFilter(item: ChecklistItem) {
    if (item.property == this.buName) {
      this.regions.forEach((region) => {
        if (region.name === item.selectedValue) {
          region.checked = false;
        }
      });
      this.selectRegion(event);
      this.clicked = !this.clicked;
    } else if (item.property == this.utName) {
      this.areas.forEach((area) => {
        if (area.name === item.selectedValue) {
          area.checked = false;
        }
      });
      this.clicked = !this.clicked;
    } else if (item.property == 'Kyc Status') {
      this.kycStatus = undefined;
    }

    this.createFilterCards();
    this.getCustomerStats();
    if (this.selectedFilters.length == 0) {
      this.reloadTable(1);
    }
  }

  applyRegionFilter() {
    this.getCustomerStats();
    this.reloadTable(1);
  }
  applyAreaFilter() {
    this.getCustomerStats();
    this.reloadTable(1);
  }

  sortBy(option: SortByOptions) {
    this.sortByValue = option;
    this.sortByLabelValue = this.mapSortBy[option];
    this.reloadTable();
  }

  selectDateType(dateType: any) {
    this.selectedDateType = dateType.name;
    this.selectedDateTypeDisplay = dateType.translation;
    this.showSelectDateType = false;
  }

  datesValid() {
    if (this.dateFrom && this.dateTo && this.dateTo?.toString() != '') {
      const valid: boolean =
        new Date(this.dateFrom).getTime() <
        new Date(`${this.dateTo.toString()}T23:59:59`).getTime();
      return valid;
    }

    return true;
  }

  selectSearchType(searchType: any) {
    if (this.searchText && this.searchFilterApplied) {
      this.searchText = '';
      this.search();
    }
    this.selectedSearchType = searchType.name;
    this.selectedSearchTypeDisplay = searchType.translation;
    this.showSelectSearchType = false;
  }

  onFilterBy(option: any) {
    if (option === 'New Connection')
      this.changeTab(1);
    else if (option === 'Onboarded Customers')
      this.changeTab(2);
  }

  changeTab(page: number) {
    this.currentTab = page;
    if(this.currentTab == 1) {
      this.currentTabName = 'New Connection';
    } else if(this.currentTab == 2) {
      this.currentTabName = 'Onboarded Customers';
    }
    this.searchText = '';
    this.resetFilter(1);
    if (this.currentTab == 2)
      this.kycStatus = 3; // Status Complete
    this.reloadTable(1);
  }

  resetFilter(e: any) {
    this.selectedFilters = [];

    this.regions.forEach((region) => {
      region.checked = false;
    });

    this.areas.forEach((area) => {
      area.checked = false;
    });
    this.areas = [];
    this.selectedAreas = [];
    this.selectedRegions = [];
    this.filterByValue = this.filterByOptions.TOTAL;

    this.clicked = !this.clicked;
    this.kycStatus = undefined;
    this.getCustomerStats();
    this.reloadTable(1);
  }

  reloadTable(page: any = null) {
    let obj = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize,
        count: this.count,
      },
      filterParams: this.getKycFilterObject(),
      utilityId: this.utilityId,
    };

    this.getAllKycAccount(obj);
  }

  private getAllKycAccount(obj: any) {
    this.kycAccountService.getAll(obj).subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.accountKycs = responseData.data.data;
          this.count = responseData.data.count;
          if (responseData.message != '') {
            this.toastr.warning(responseData.message);
          }
        } else if (response?.status == 204) {
          this.toastr.warning('No content');
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => this.toastr.error('Error occured'),
    });
  }

  search() {
    this.searchFilterApplied = this.searchText ? true : false;
    this.getCustomerStats();
    this.reloadTable(1);
  }

  download() {
    var kycObj = this.getKycFilterObject();

    this.kycAccountService.download(kycObj).subscribe({
      next: (response) => {
        const data = response.body;
        if (data?.status === 'OK' || data?.status === '200') {
          window.location.href = data.data;
        } else if (data?.status === 'Pending' || data?.status === '600') {
          this.toastr.info(data?.message || '');
        } else this.toastr.error(data?.message || 'Request failed');
      },
      error: (error) => console.log(error),
    });
  }

  getKycFilterObject() {
    let obj = {
      sortBy: this.sortByValue,
      search: this.searchText,
      dateFrom: this.dateFrom ? this.dateFrom : null,
      dateTo: this.dateTo ? `${this.dateTo}T23:59:59` : null,
      kycStatus: this.kycStatus ? Number(this.kycStatus) : null,
      tag: this.kycTag ? this.kycTag : null,
      selectedAreas: this.selectedAreas.length > 0 ? this.selectedAreas : null,
      selectedRegions:
        this.selectedRegions.length > 0 ? this.selectedRegions : null,
      selectedIds: this.accountKycs
        .filter((x) => x.isChecked == true)
        .map((x) => x.id),
    };
    return obj;
  }
  
  private getCustomerStats() {
    var obj = this.getKycFilterObject();
    this.customerStats = new NewServiceStats();
    this.getOnboardedStats(obj);
  }
 
  private getOnboardedStats(obj: any) {
    var filter = {
      filter: obj,
      Option: CustomerStatsOptions.Onboarded,
    };
    this.customerService.getOnboardedCustomersStats(filter).subscribe({
      next: (response) => {
        const data = response.body;
        if (data?.status === 200 || data?.status === 'OK') {
          this.customerStats.newConnection = data.data.newConnection;
          this.customerStats.onboardedCustomers = data.data.onboardedCustomers;
        } else {
          this.toastr.error(data?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  applyFilter(item: ChecklistItem) {
    this.currentPage = 1;
    this.reloadTable(this.currentPage);
  }
}
