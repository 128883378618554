import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-buildings',
  templateUrl: './buildings-layout.component.html',
  styleUrls: ['./buildings-layout.component.scss']
})
export class BuildingsLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
