import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'adminLevel'
})
export class AdminLevelPipe implements PipeTransform{
  transform(value: any)  {
      switch(value){
        case 'Level1':
          return 'Full Control';
        case 'Level2':
          return 'Read Only';
        default:
          return '----'
      }
  }
}
