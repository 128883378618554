export class MaintenanceStats {
    assigned?: number;
    unassigned?: number;
    canceled?: number;
    incomplete?: number;
    completed?: number;

    constructor(data?: any) {
        this.assigned = data?.assigned || 0;
        this.unassigned = data?.unassigned || 0;
        this.canceled = data?.canceled || 0;
        this.incomplete = data?.incomplete || 0;
        this.completed = data?.completed || 0;
    }
}

export class WorkOrderModel {
    id: number;
    workOrderId: string;
    category: MaintenanceCategory | null;
    assetTypeForUI: string;
    description: string;
    status: WorkOrderStatus | '';
    location: string;
    geoCode: number | null;
    comment: string;
    assignedUserId: number;
    summary: string;
    assetType: WorkOrderAssetType | null;
    assetId: number | null;
    dateAssigned: string;
    assignedStaff: string;
    assignee: string;
    reporter: string;
    areaId:number|null;
    guid:string;
    uploadedImages : string[];
    constructor(data?: any) {
        this.id = data?.id || 0;
        this.workOrderId = data?.workOrderId || '';
        this.category = data?.category || null;
        this.assetTypeForUI = convertWorkOrderAssetTypeToString(data?.assetType);
        this.description = data?.description || '';
        this.status = data?.status || '';

        this.location = data?.location || '';
        this.geoCode = data?.geoCode;
        this.comment = data?.comment || '';
        this.assignedUserId = data?.assignedUserId || 0;
        this.summary = data?.summary || '';
        this.assetType = data?.assetType || null;
        this.dateAssigned = data?.dateAssigned || '';
        this.assignedStaff = data?.assignedStaff || '';
        this.assignee = data?.assignee || '';
        this.reporter = data?.reporter || '';
        this.areaId = data?.areaId;
        this.guid=data?.guid;
        this.uploadedImages = data?.uploadedImages;
    }
}

export enum WorkOrderAssetType {
    Building,
    Customer,
    Pole,
    Transformer,
    Feeder
}
export enum MaintenanceCategory {
    Installation
}
export enum WorkOrderStatus {
    Unassigned = 'Unassigned',
    Assigned = 'Assigned', // assigned tab
    InProgress = 'In progress', // assigned tab
    Resolved = 'Resolved', // completed tab
    Canceled = 'Canceled',  // canceled tab
    // Incomplete = 'Incomplete',
}

export function convertWorkOrderAssetTypeToString(assetType?: number): string {
    switch (assetType) {
        case WorkOrderAssetType.Building:
            return 'Building';
        case WorkOrderAssetType.Customer:
            return 'Customer';
        case WorkOrderAssetType.Pole:
            return 'LT Pole';
        case WorkOrderAssetType.Transformer:
            return 'Transformer';
        default:
            return '';
    }
}

export enum CustomerSearchType {
    AccountNo,
    MeterNo,
    SPN,
    Slrn
}