import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import * as intlTelInput from 'intl-tel-input';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {
  @ViewChild('telInput') telInput: any;
  public messageSent: boolean = false;
  public showEmailForm: boolean = true;
  public showCodeForm: boolean = false;
  public showPasswordForm: boolean = false;
  iti: any;
  isInvalid = false;

  //Form input
  public fullName: any;
  public phone: any;
  public email: any;
  public organisation:any;
  public subject: any;
  public message: any;

  constructor(private authService: AuthService,
    private router: Router,
    private toastr: ToastrService) {

  }

  ngOnInit(): void {
    if(this.authService.isLoggedIn()) //disable for logged users
      this.router.navigate(['dashboard']);
  }

  ngAfterViewInit() {
    this.iti = intlTelInput(this.telInput.nativeElement, {
      utilsScript:
        'https://cdn.jsdelivr.net/npm/intl-tel-input@18.2.1/build/js/utils.js',
      initialCountry: 'gh',
      // initialCountry: "auto",
      nationalMode: true,
      separateDialCode: true,
      autoPlaceholder: 'off'
    });
  }

  sendMail():void {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
   
    if (!emailRegex.test(this.email)) {
      this.toastr.error("Invalid Email format.");
      return;
    }

    let obj = {
      fullName: this.fullName,
      phone: this.iti.getSelectedCountryData().dialCode + this.phone,
      email: this.email,
      organisation: this.organisation, 
      subject: this.subject,
      message: this.message
    }

    this.authService.sendEmail(obj).subscribe(res => {
      if (res.status === 200 || res.status === 'OK') {
        //On success
        this.showEmailForm = false;
        this.messageSent = true;
        this.showCodeForm = true;
    } else {
      this.toastr.error(res.message);      
    }}, error => {
      this.toastr.error('An error occurred.');
    });
  }

  onInputKeyPress = (event: KeyboardEvent) => {
    const allowedChars = /[0-9\+\-\ ]/;
    const allowedCtrlChars = /[axcv]/; // Allows copy-pasting
    const allowedOtherKeys = [
      'ArrowLeft',
      'ArrowUp',
      'ArrowRight',
      'ArrowDown',
      'Home',
      'End',
      'Insert',
      'Delete',
      'Backspace',
    ];

    if (
      !allowedChars.test(event.key) &&
      !(event.ctrlKey && allowedCtrlChars.test(event.key)) &&
      !allowedOtherKeys.includes(event.key)
    ) {
      event.preventDefault();
    }
  };
  onBlur = () => {
    this.isInvalid = false;
    if (this.phone != undefined && this.phone.trim()) {
      if (this.iti.isValidNumber()) {
        this.isInvalid = false;
      } else {
        this.isInvalid = true;
      }
    }
  };
}
