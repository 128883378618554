import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfig } from '../config/config';
import { HttpHeaders } from '@angular/common/http';
import { PaginationDto, ResponsePackage } from '../models/util.model';
import { UtilityList } from '../models/utility.model';
import { timeout } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  private pathApi = this.config.setting['pathApi'] + "utility/";
  private headers = new HttpHeaders({'Content-Type': 'application/json'});


  constructor(private http: HttpClient, private config: AppConfig) {

  }
  
  getUtilitiesForSelect(): Observable<any> {
    return this.http.get(`${this.pathApi}getUtilitiesForSelect`);
  }
  add(obj:any, image:File|null){
    let formData: FormData = new FormData();
    if(image!=null)
    formData.append('image', image, image.name);
    formData.append('id',obj.id??0);
    formData.append('name', obj.name);
    formData.append('fullName', obj.fullName);
    formData.append('currency', obj.currency);
    formData.append('subdomain', obj.subdomain);
    formData.append('colorCode', obj.colorCode);
    formData.append('translations', JSON.stringify(obj.translations));
    formData.append('imageUrl',obj.imageUrl??"");
    formData.append('kycDataInString', JSON.stringify(obj.kycConsentConfiguration));
    obj.dashboardConfiguration.forEach((config:string)=>
      formData.append('dashboardConfiguration',config)
    );
    formData.append('contactPersonString', JSON.stringify(obj.contactPerson));
    formData.append('BankDetailsDataInString', JSON.stringify(obj.bankDetails))
    console.log(formData);
    return this.http.post<ResponsePackage<string>>(`${this.pathApi}add`, formData);
  }

  addNewSubdomain(obj: any) : Observable<any> {
    return this.http.post<ResponsePackage<string>>(`${this.pathApi}addNewSubdomain`, obj);
  }

  getall(obj : any ){
    return this.http.post<ResponsePackage<PaginationDto<UtilityList>>>(`${this.pathApi}getAll`, obj);
  }

  get(id?:number){
    return this.http.get<ResponsePackage<any>>(`${this.pathApi}get/${id ?? -1}`,{headers: this.headers, observe: 'response'});
  }

  getByName(name : string){
    return this.http.get<ResponsePackage<any>>(`${this.pathApi}getByName/${name}`,{headers: this.headers, observe: 'response'});
  }
  setStatus(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}setStatus`, obj);
  }
}
