import { Injectable } from '@angular/core';

export type ModalContent = 'agreement-dialog' | '';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
    isModalVisible: boolean = false;
    currentModal: ModalContent | null = null;

    closeModal() {
        this.isModalVisible = false;
    }

    openAgreementDialogModal() {
        this.isModalVisible = true;
        this.currentModal = 'agreement-dialog';
    }
}
