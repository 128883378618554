export enum FeederType {
    NOT_SELECTED = -1,
    KV11 = 0,
    KV33 = 1
} 

export class Feeder {
    id: number;
    name: string;
    number: string;
    feederType: FeederType;

    constructor(data?: any) {
        this.id = data?.id ?? 0;
        this.name = data?.name ?? '';
        this.number = data?.number ?? '';
        this.feederType = data?.feederType ?? FeederType.NOT_SELECTED;
    }
}