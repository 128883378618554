import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from '../config/config';
import { PaginationDto, ResponsePackage } from '../models/util.model';
import {
  SiteVerificationList,
  SiteVerificationsStats,
} from '../models/siteVerification.model';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class SiteVerificationService {
  private pathApi = this.config.setting['pathApi'] + 'siteVerification/';
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  constructor(private http: HttpClient, private config: AppConfig) {}

  getAll(obj: any) {
    return this.http.post<ResponsePackage<PaginationDto<SiteVerificationList>>>(
      `${this.pathApi}getAll`,
      obj,
      { headers: this.headers, observe: 'response' }
    );
  }
  getStats() {
    return this.http.get<ResponsePackage<SiteVerificationsStats>>(
      `${this.pathApi}getStats`,
      { headers: this.headers, observe: 'response' }
    );
  }
  assignVerification(id: number, agentId: number) {
    const obj = { entityId: id, userId: agentId };
    return this.http.post<ResponsePackage<string>>(
      `${this.pathApi}assignVerification`,
      obj,
      { observe: 'response' }
    );
  }
  get(id: number) {
    return this.http.get<ResponsePackage<any>>(`${this.pathApi}${id}`, {
      headers: this.headers,
      observe: 'response',
    });
  }

  approveSiteVerification(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}approveVerification`, obj);
  }

  rejectSiteVerification(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}rejectVerification`, obj);
  }

  editSiteVerification(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}editVerification`, obj);
  }

  approveAccountCreation(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}approveAccountCreation`, obj);
  }

  resendInvoice(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}resendInvoice`, obj);
  }
}
