export interface Region {
    id: number;
    name: string;
    checked: boolean;
    isSelectable: boolean;
    utilityId: number;
    code: string;
    number: string;
}

export function getInitRegion(): Region {
    return {
        id: 0,
        name: '',
        checked : true,
        isSelectable: false,
        utilityId: 0,
        code: '',
        number: ''
    };
}