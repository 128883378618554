import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-poles-layout',
  templateUrl: './poles-layout.component.html',
  styleUrls: ['./poles-layout.component.scss']
})
export class PolesLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
