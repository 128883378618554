import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AppConfig } from "../config/config";
import { Area, AreaCrudCreate } from "../models/area.model";
import { ResponsePackage } from "../models/util.model";

@Injectable({
    providedIn: 'root'
  })
export class AreaService {
    
    private pathApi = this.config.setting['pathApi'] + "area/";
    private headers = new HttpHeaders({'Content-Type': 'application/json'});

    constructor(private http: HttpClient, private config: AppConfig) {}

    public getAllForSelect(utilityId: number | null = null) {
      if(utilityId) {
        return this.http.get<ResponsePackage<Area[]>>(`${this.pathApi}getAllForSelect/${utilityId}`,{headers: this.headers, observe: 'response'});
      }
      else {
        return this.http.get<ResponsePackage<Area[]>>(`${this.pathApi}getAllForSelect`,{headers: this.headers, observe: 'response'});
      }
    }

    getAll(dataIn: any): Observable<any> {
        return this.http.post(`${this.pathApi}getAll`, dataIn, {observe: 'response'});
      }
    
    save(area: AreaCrudCreate): Observable<any> {
      return this.http.post(`${this.pathApi}save`, area, {observe: 'response'});
    }
  
    delete(id: number): Observable<any> {
      return this.http.delete(`${this.pathApi}delete/${id}`, {observe: 'response'});
    }

    getAreasForOptionsByRegion(regionId: number): Observable<any> {
        return this.http.get(`${this.pathApi}getAreasForOptionsByRegion/${regionId}`);
    } 

    getAreasForOptionsByRegionIds(regions: number[]): Observable<any> {
      return this.http.post(`${this.pathApi}getAreasForOptionsByRegionIds`, regions, {observe: 'response'});
  } 
  //Gets all areas doesn't filter by users assigned regions
   getAllForOptions(utilityId: number | null = null) {
    if(utilityId) {
      return this.http.get<ResponsePackage<Area[]>>(`${this.pathApi}getAllForOptions/${utilityId}`,{headers: this.headers, observe: 'response'});
    }
    else {
      return this.http.get<ResponsePackage<Area[]>>(`${this.pathApi}getAllForOptions`,{headers: this.headers, observe: 'response'});
    }
  }
}