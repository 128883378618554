<div class="container-fluid customers-root">
  <app-card-analytics
    [stats]="stats"
    (shareFilterValue)="onFilterBy($event)"
  ></app-card-analytics>

  <div class="table-root">
    <div
      class="table-action-wrapper d-flex align-items-center justify-content-between"
    >
      <div class="search-input">
        <img
          src="../../assets/icons/search.svg"
          alt="Search icon"
          (click)="search()"
        />
        <input
          type="text"
          [placeholder]="'COMMON.SEARCH' | translate"
          [(ngModel)]="searchText"
          (keydown.enter)="search()"
          (ngModelChange)="this.searchTextUpdate.next($event)"
        />
      </div>
      <div class="actions-box ml-auto d-flex">
        <div>
          <div class="date-filters d-flex">
            <div class="datetype-picker sort-by-root">
              <div
                class="dropdown date-type-dropdown"
                (click)="showSelectDateType = !showSelectDateType"
                click-stop-propagation [title]="selectedDateTypeDisplay | translate"
              >
                <span>{{'COMMON.DATE_TYPE.TITLE' | translate}}:</span>
                <span>{{ selectedDateTypeDisplay | translate }}</span>
                <span class="arrow"
                  ><img
                    src="../../assets/icons/gray-arrow-down.svg"
                    alt="Gray arrow down"
                /></span>
              </div>
              <div class="dropdown-opened" *ngIf="showSelectDateType">
                <div
                  (click)="
                    selectDateType(dateType); (datesValid() == true && (dateTo || dateFrom)) && search()
                  "
                  *ngFor="let dateType of dateTypes"
                >
                  <div>{{ dateType.translation | translate }}</div>
                </div>
              </div>
            </div>
            <div class="datepicker-container" style="width: 23rem;">
              <label for="dateFrom"> {{'COMMON.FROM' | translate}}: </label>
              <input
                type="date"
                id="dateFrom"
                name="dateFrom"
                [ngClass]="
                  !datesValid()
                    ? 'btn datepicker-icon-error'
                    : 'btn datepicker-icon'
                "
                [(ngModel)]="dateFrom"
                (change)="datesValid() == true && search()"
              />
            </div>

            <div class="datepicker-container">
              <label for="dateTo" class="right-label"> {{'COMMON.TO' | translate}}: </label>
              <input
                type="date"
                id="dateTo"
                name="dateTo"
                [ngClass]="
                  !datesValid()
                    ? 'btn datepicker-icon-error'
                    : 'btn datepicker-icon'
                "
                [(ngModel)]="dateTo"
                (change)="datesValid() == true && search()"
              />
            </div>
          </div>
        </div>

        <div
          class="sort-by-root"
          (click)="showSortBy = !showSortBy"
          click-stop-propagation
        >
          <div class="dropdown" [title]="sortByName | translate">
            <span>{{'COMMON.SORT_BY.TITLE' | translate}}:</span>
            <span>{{ sortByName | translate }}</span>
            <span
              ><img
                src="../../assets/icons/gray-arrow-down.svg"
                alt="Gray arrow down"
            /></span>
          </div>
          <div class="dropdown-opened" *ngIf="showSortBy">
            <div (click)="sortBy(sortByOptions.NEWEST)">{{'COMMON.SORT_BY.NEWEST' | translate}}</div>
            <div (click)="sortBy(sortByOptions.SLRN_ASC)">{{'COMMON.SORT_BY.SLRN_ASC' | translate}}.</div>
            <div (click)="sortBy(sortByOptions.SLRN_DESC)">{{'COMMON.SORT_BY.SLRN_DESC' | translate}}.</div>
          </div>
        </div>

        <div class="d-block poles">
          <div
            class="d-flex align-items-center data clickable nav-item"
            style="border: 2px solid#00423E"
            (click)="showDownload = !showDownload"
          >
            <span>{{'COMMON.DOWNLOAD' | translate}}</span>
            <div class="ms-auto">
              <img
                class="arrow-icon"
                src="../../../assets/icons/dropdown.svg"
                alt="Arrow Up"
              />
            </div>
          </div>
          <div *ngIf="showDownload" class="download-options">
            <div
              class="asset-grouped"
              [ngClass]="{
                'selected-download': downloadBuilding
              }"
              (click)="downloadBuilding = true; downlaodInaccessible = false;downloadReplacedMeters=false"
            >
              <span class="asset-name">{{'BUILDINGS.INFO' | translate}}</span>
            </div>
            <div
              class="asset-grouped"
              [ngClass]="{
                'selected-download': downlaodInaccessible
              }"
              (click)="downlaodInaccessible = true; downloadBuilding = false;downloadReplacedMeters=false"
            >
              <span class="asset-name">{{'BUILDINGS.INACCESSIBLE_METERS' | translate}}</span>
            </div>
            <div
              class="asset-grouped"
              [ngClass]="{
                'selected-download': downloadReplacedMeters
              }"
              (click)="downloadBuilding = false; downlaodInaccessible = false;downloadReplacedMeters=true"
            >
              <span class="asset-name">{{'CUSTOMERS.DOWNLOAD.SLRN' | translate}}</span>
            </div>
            <button class="btn btn-primary btn-apply" (click)="download()">
              <span>{{'COMMON.APPLY' | translate}}</span>
            </button>
          </div>
        </div>

        <div *ngIf="permissionsService.isBuildingCreateEditAllowed" class="double-action-button">
            <button
              class="btn btn-primary btn-upload"
              (click)="showUpload = !showUpload"
              click-stop-propagation
            >
              <img src="../../assets/icons/chevron-down.svg" alt="Chevron down" />
            </button>
            <div
              *ngIf="showUpload"
              class="xlsx-upload"
              [routerLink]="'/upload/1/' + utilityId"
            >
              <div><img src="../../assets/icons/upload-arrow.svg" alt="Upload arrow"/></div>
              <div>{{'COMMON.UPLOAD_XLSX' | translate}}</div>
            </div>
  
            <button
            class="btn btn-primary btn-add-new"
            routerLink="add"
            *ngIf="permissionsService.isBuildingCreateEditAllowed"
          >
            <img src="../../assets/icons/plus.svg" alt="Plus icon" />
            <span>{{'BUILDINGS.NEW' | translate}}</span>
          </button>
        </div>
      </div>
    </div>
    <div class="filter">
      <div class="filter-list">
        {{'COMMON.FILTER' | translate}}
        <app-multi-select-dropdown class="multiselect" [clicked]="clicked" [items]="regions" [label]="'COMMON.BU.PLURAL'"
          [showMore]="false" [requireApply]="true" (shareIndividualCheckedList)="selectRegion($event)">
        </app-multi-select-dropdown>
        <app-multi-select-dropdown class="multiselect" [clicked]="clicked" [items]="areas" [label]="'COMMON.UT.PLURAL'"
          [showMore]="false" [requireApply]="true" (shareIndividualCheckedList)="selectArea($event)">
        </app-multi-select-dropdown>

        <select name="st-select" (change)="selectUserType($event)" class="form-select">
          <option value="" [selected]="!userType" disabled>
            {{'COMMON.USER.TYPE.TITLE' | translate}}
          </option>
          <option [selected]="userType == o.value" *ngFor="let o of userTypeOptions" value="{{ o.value }}">
            {{ o.translation | translate }}
          </option>
        </select>

        <app-multi-select-dropdown class="multiselect" [searchFilterActive]="true"
          (filterValue)="applySearchFilter($event)" [clicked]="clicked" [items]="filteredUsers" [label]="'COMMON.USER.PLURAL'"
          [showMore]="false" [requireApply]="true" (shareIndividualCheckedList)="selectUsers($event)"
          (applyFilterEmitter)="reloadTable(1)">
        </app-multi-select-dropdown>

        <select
        *ngIf="permissionsService.isValidationStatusAllowed"
          name="status-select"
          (change)="selectStatus($event)"
          class="form-select"
        >
          <option value="" [selected]="!status" disabled>{{'COMMON.VALIDATION_STATUS' | translate}}</option>
          <option
            [selected]="status == s.value"
            *ngFor="let s of statusOptions"
            value="{{ s.value }}"
          >
            {{ s.translation | translate }}
          </option>
        </select>

        <select
          name="tag-select"
          (change)="selectTag($event)"
          class="form-select"
        >
          <option value="" [selected]="!tag" disabled>{{'COMMON.TAG.TITLE' | translate}}</option>
          <option
            [selected]="tag == t.value"
            *ngFor="let t of tagOptions"
            value="{{ t.value }}"
          >
            {{ t.translation | translate }}
          </option>
        </select>
      </div>
      <div *ngIf="selectedFilters.length > 0">
        <app-filter-checklist [filters]="selectedFilters" [useApply]="true" (resetFilterEmitter)="resetFilter($event)"
          (removeFilterEmitter)="removeFilter($event)" (applyFilterEmitter)="applyFilter($event)" >
        </app-filter-checklist>
      </div>
        <div *ngIf="buildings">
          <table class="table border-bottom">
            <thead class="table-light">
              <tr>
                <th>
                  <input
                    type="checkbox"
                    class="form-check-input"
                    [ngModel]="allCheckboxesChecked"
                    (click)="checkAllCheckboxes()"
                  />
                </th>
                <th>{{'COMMON.MBC_DETAILS.BUILDING_SLRN' | translate}}</th>
                <th>{{'COMMON.ADDRESS' | translate}}</th>
                <th>{{'BUILDINGS.CONNECTED_ACCS' | translate}}</th>
                <th>{{'COMMON.BU.SINGLE' | translate}}</th>
                <th>{{'COMMON.UT.SINGLE' | translate}}</th>
                <th>{{'COMMON.MBC_DETAILS.FEEDER_NAME' | translate}}</th>
                <th>{{'COMMON.DT_NAME' | translate}}</th>
                <th *ngIf="permissionsService.isValidationStatusAllowed">{{'COMMON.VALIDATION_STATUS' | translate}}</th>
                <th>{{'COMMON.TAG.TITLE' | translate}}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let building of buildings; let i = index">
                <td>
                  <input
                    type="checkbox"
                    class="form-check-input"
                    [ngModel]="building.isChecked"
                    (click)="checkBuilding(building.id)"
                  />
                </td>
                <td
                  (click)="setFilterStorage()"
                  [routerLink]="['edit', building.id]"
                >
                  {{ building?.slrn | hasValue }}
                </td>
                <td
                  (click)="setFilterStorage()"
                  [routerLink]="['edit', building.id]"
                >
                  {{ building.address | hasValue }}
                </td>
                <td
                  (click)="setFilterStorage()"
                  [routerLink]="['edit', building.id]"
                >
                  {{ building.customerCount | number | hasValue }}
                </td>
                <td
                  (click)="setFilterStorage()"
                  [routerLink]="['edit', building.id]"
                >
                  {{ building.region | hasValue }}
                </td>
                <td
                  (click)="setFilterStorage()"
                  [routerLink]="['edit', building.id]"
                >
                  {{ building.area | hasValue }}
                </td>
                <td
                  (click)="setFilterStorage()"
                  [routerLink]="['edit', building.id]"
                >
                  {{ building.feederName | hasValue }}
                </td>
                <td
                  (click)="setFilterStorage()"
                  [routerLink]="['edit', building.id]"
                >
                  {{ building.dtName | hasValue }}
                </td>
                <td
                  (click)="setFilterStorage()"
                  [routerLink]="['edit', building.id]"
                  *ngIf="permissionsService.isValidationStatusAllowed"
                >
                <div *ngIf="building.dataQualityStatus"
                      class="building-status"
                      [ngClass]="{
                        'complete-building' : building.dataQualityStatus == 'Approved',
                        'rejected-building' : building.dataQualityStatus == 'Rejected'
                      }">
                    {{ building.dataQualityStatus == 'Approved' ? ('COMMON.ASSET_STATUS.COMPLETE' | translate) : ('COMMON.ASSET_STATUS.REJECTED' | translate) }}
                </div>
                <div
                  *ngIf="building.status && !building.dataQualityStatus"
                  class="building-status"
                  [ngClass]="{
                    'pending-building': building.status == 'Pending',
                    'assigned-building': building.status == 'Assigned' || building.status == 'Retagged'
                  }"
                >
                  {{ 'COMMON.ASSET_STATUS.' + building.status | uppercase | translate }}
                </div>
                <div *ngIf="!building.status && !building.dataQualityStatus" class="building-status">--------</div>
                </td>
                <td>
                  <div *ngIf="!building.issueType" class="tag-status">
                    <div
                      (click)="setFilterStorage()"
                      [routerLink]="['edit', building.id]"
                      class="tag-new-customer"
                    >
                      {{'COMMON.TAG.TAGGED' | translate}}
                    </div>
                  </div>
                  <div *ngIf="building.issueType" class="tag-status">
                    <div
                      (click)="setFilterStorage()"
                      [routerLink]="['edit', building.id]"
                      class="tag-inaccessible-customer"
                    >
                    {{'COMMON.BUILDING.TYPE.INACCESSIBLE' | translate}}
                    </div>
                  </div>
                </td>
                <td
                  class="more-icon"
                  (click)="setFilterStorage()"
                  [routerLink]="['edit', building.id]"
                >
                  <img
                    src="../../assets/icons/more-icon.svg"
                    alt="More icon"
                    class="more-icon"
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <div>
            <app-paging
              *ngIf="count > 0"
              [pageSize]="pageSize"
              [count]="count"
              [currentPage]="currentPage"
              [useDirect]="true"
              (pageChange)="pageChange($event)"
            ></app-paging>
          </div>
        </div>
    </div>
  </div>
</div>
