import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '../config/config';
import {
  Dt,
  DtCrudCreate,
  DtCustomerCreate,
  DtEdit,
  DtImage,
  DtStats,
} from '../models/dt.model';
import { KeyValueItem } from '../models/keyValueItem.model';
import { ResponsePackage } from '../models/util.model';
import { AssetStatusEnum } from '../models/assetStatus.model';

@Injectable({
  providedIn: 'root',
})
export class DtService {
  private pathApi = this.config.setting['pathApi'] + 'dt/';
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(private http: HttpClient, private config: AppConfig) {}

  getAll(dataIn: any): Observable<any> {
    return this.http.post(`${this.pathApi}getAll`, dataIn, {
      observe: 'response',
    });
  }
  getAllRejectionReports(dataIn: any): Observable<any> {
    return this.http.post(`${this.pathApi}getAllRejectionReports`, dataIn, {
      observe: 'response',
    });
  }
  get(id: number) {
    return this.http.get<ResponsePackage<any>>(`${this.pathApi}${id}`, {
      headers: this.headers,
      observe: 'response',
    });
  }
  recall(id: number) {
    return this.http.get<ResponsePackage<any>>(`${this.pathApi}recall/${id}`, {
      headers: this.headers,
      observe: 'response',
    });
  }
  getDetails(id: number) {
    return this.http.get<ResponsePackage<any>>(`${this.pathApi}${id}/details`, {
      headers: this.headers,
      observe: 'response',
    });
  }

  generateTemplate(
    utilityId: number,
    isCrud: string = 'false'
  ): Observable<any> {
    return this.http.get(
      `${this.pathApi}generateTemplate?utilityId=${utilityId}&isCrud=` + isCrud
    );
  }

  getAllList(dataIn: any): Observable<any> {
    return this.http.post(`${this.pathApi}getAllList`, dataIn, {
      observe: 'response',
    });
  }

  save(dt: DtCrudCreate): Observable<any> {
    return this.http.post(`${this.pathApi}save`, dt, { observe: 'response' });
  }
  saveCrud(dt: DtCrudCreate): Observable<any> {
    return this.http.post(`${this.pathApi}saveCrud`, dt, {
      observe: 'response',
    });
  }
  updateDetails(dt: DtEdit): Observable<any> {
    return this.http.post(`${this.pathApi}save`, dt, { observe: 'response' });
  }

  delete(id: number): Observable<any> {
    return this.http.delete(`${this.pathApi}delete/${id}`, {
      observe: 'response',
    });
  }

  download(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}downloadFile`, obj, {
      headers: this.headers,
      observe: 'response',
    });
  }

  downloadRejectionReports(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}downloadRejectionReportsFile`, obj, {
      headers: this.headers,
      observe: 'response',
    });
  }

  getAllForSelectByFeeder(
    feederId: number,
    feederType: string
  ): Observable<any> {
    return this.http.get(
      `${this.pathApi}getAllForSelectByFeeder?feederId=${feederId}&feederType=${feederType}`,
      { observe: 'response' }
    );
  }

  getAllByFeeder(
    feederId: number,
    feederType: string,
    utilityId: number | null = null
  ): Observable<any> {
    if (utilityId) {
      return this.http.get(
        `${this.pathApi}getAllByFeeder?feederId=${feederId}&feederType=${feederType}&utilityId=${utilityId}`,
        { observe: 'response' }
      );
    } else {
      return this.http.get(
        `${this.pathApi}getAllByFeeder?feederId=${feederId}&feederType=${feederType}`,
        { observe: 'response' }
      );
    }
  }

  getAllForSelect() {
    return this.http.get<ResponsePackage<Dt[]>>(
      `${this.pathApi}getAllForSelect`,
      { headers: this.headers, observe: 'response' }
    );
  }

  getAllByName(name: string) {
    return this.http.post<ResponsePackage<DtCustomerCreate[]>>(
      `${this.pathApi}getAllByName`,
      { name: name },
      { headers: this.headers, observe: 'response' }
    );
  }

  getDtsStats(obj: any): Observable<any> {
    return this.http.post<ResponsePackage<DtStats>>(
      `${this.pathApi}getStats`,
      obj,
      { headers: this.headers, observe: 'response' }
    );
  }

  uploadFile(
    obj: any,
    utilityId: number,
    isCrud: string = 'false'
  ): Observable<any> {
    return this.http.post(
      `${this.pathApi}uploadFile?utilityId=${utilityId}&isCrud=` + isCrud,
      obj
    );
  }

  reattachDt(oldDtId: number, newDtId: number) {
    const obj = { oldDtId: oldDtId, newDtId: newDtId };
    return this.http.post<ResponsePackage<string>>(
      `${this.pathApi}reattachDt`,
      obj,
      { observe: 'response' }
    );
  }

  searchDt(searchText?: any): Observable<any> {
    return this.http.get(`${this.pathApi}searchDt/${searchText}`, {
      observe: 'response',
    });
  }

  GetDtHistory(dtId?: any): Observable<any> {
    return this.http.get(`${this.pathApi}getHistory/` + dtId, {
      observe: 'response',
    });
  }

  assignDt(
    dtId: number,
    userId: number,
    assetStatus: AssetStatusEnum,
    isFromRejection: boolean
  ) {
    const obj = {
      entityId: dtId,
      userId: userId,
      assetStatus: assetStatus,
      isFromRejection: isFromRejection,
    };
    return this.http.post<ResponsePackage<string>>(
      `${this.pathApi}assignDt`,
      obj,
      { observe: 'response' }
    );
  }

  uploadPicture(obj: any, image: File) {
    let formData: FormData = new FormData();
    formData.append('entityId', obj.entityId);
    formData.append('image', image, image.name);
    formData.append('imageType', obj.imageType);
    formData.append('guid', obj.guid); 
    formData.append('url', obj.url); 

    return this.http.post<ResponsePackage<DtImage>>(
      `${this.pathApi}uploadPicture`,
      formData,
      { observe: 'response' }
    );
  }

  getDtDataQuality(id: number): Observable<any> {
    return this.http.get(`${this.pathApi}getDtDataQuality/${id}`, {
      observe: 'response',
    });
  }
  rejectDt(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}rejectDt`, obj, {
      headers: this.headers,
      observe: 'response',
    });
  }
  approveDt(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}approveDt`, obj, {
      headers: this.headers,
      observe: 'response',
    });
  }
  auditDt(id: number): Observable<any> {
    return this.http.post(`${this.pathApi}auditDt/${id}`, id, {
      headers: this.headers,
      observe: 'response',
    });
  }
}
