import { Pipe, PipeTransform } from '@angular/core';
import { ReadingStatus } from '../models/meterReading';

@Pipe({
  name: 'getReadingStatusClass'
})
export class GetReadingStatusClassPipe implements PipeTransform {
  transform(value: any): any {
    switch (value) {
      case (ReadingStatus.Assigned):
      case "Assigned":
      case 0:
        return 'Usage';
      case (ReadingStatus.Submitted):
      case "Submitted" :
      case 1:
        return 'Usage';
      case (ReadingStatus.Failed):
      case "Failed" :
      case 2:
        return 'Failed';
      case (ReadingStatus.Rejected):
      case "Rejected" :
      case 3:
        return 'Rejected';
      case (ReadingStatus.Inaccessible):
      case "Inaccessible" :
      case 4:
        return 'Inaccessible';
      case (ReadingStatus.Inoperable):
      case "Inoperable" :
      case 5:
        return 'Inoperable';
      default:
        return 'Unknown status.';
    }
  }
}