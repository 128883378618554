import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '../config/config';
import { BuildingDetails, BuildingStats, EditBuilding } from '../models/building.model';
import { BuildingImage } from '../models/building.model';
import { KeyValueItem } from '../models/keyValueItem.model';
import { PaginationDto, ResponsePackage } from '../models/util.model';
import { AssetStatusEnum } from '../models/assetStatus.model';
import { EntityType } from '../models/image.model';

@Injectable({
  providedIn: 'root'
})
export class BuildingService {

  private pathApi = this.config.setting['pathApi'] + "building/";
  private headers = new HttpHeaders({'Content-Type': 'application/json'});

  constructor(private http: HttpClient, private config: AppConfig) {}

  // getBuildingsStats() {
  //   return this.http.get<ResponsePackage<KeyValueItem[]>>(`${this.pathApi}getStats`,{headers: this.headers, observe: 'response'});
  // }
  getBuildingsStats(obj: any): Observable<any> {
    return this.http.post<ResponsePackage<BuildingStats>>(
      `${this.pathApi}getStats`,
      obj,
      { headers: this.headers, observe: 'response' }
    );
  }

  getRejectionReportStats() {
    return this.http.get<ResponsePackage<any>>(`${this.pathApi}getRejectionReportStats`,{headers: this.headers, observe: 'response'});
  }
  getAll(obj: any) {
    return this.http.post<ResponsePackage<PaginationDto<BuildingDetails>>>(`${this.pathApi}getAll`, obj, {headers: this.headers, observe: 'response'});
  }
  getAllRejectionReports(obj: any) {
    return this.http.post<ResponsePackage<PaginationDto<BuildingDetails>>>(`${this.pathApi}getAllRejectionReports`, obj, {headers: this.headers, observe: 'response'});
  }
  getById(id: number) {
    return this.http.get<ResponsePackage<EditBuilding>>(`${this.pathApi}${id}`,{headers: this.headers, observe: 'response'});
  }
  recall(id: number, recallType: number) {
    const obj = {id: id, recallType: recallType};
    return this.http.post<ResponsePackage<any>>(`${this.pathApi}recall`, obj, {headers: this.headers, observe: 'response'});
  }
  getBySlrn(slrn: string): Observable<any> {
    return this.http.get(`${this.pathApi}getBySlrn/${slrn}`, {observe: 'response'});
  }

  uploadPicture(obj: any, image: File){  
    let formData: FormData = new FormData();
    formData.append('entityId', obj.buildingId);
    formData.append('imageType', obj.imageType);
    formData.append('image', image, image.name);
    formData.append('guid', obj.guid);
    formData.append('url', obj.url);

    return this.http.post<ResponsePackage<BuildingImage>>(`${this.pathApi}uploadPicture`, formData, {observe: 'response'});
  }

  assignBuilding(buildingId: number, userId: number, assetStatus: AssetStatusEnum,isFromRejection:boolean) {
    const obj = {entityId: buildingId, userId: userId, assetStatus: assetStatus,isFromRejection:isFromRejection};
    return this.http.post<ResponsePackage<string>>(`${this.pathApi}assignBuilding`, obj, {observe: 'response'});
  }

  addOrUpdateBuilding(obj: any) {
    return this.http.post<ResponsePackage<string>>(`${this.pathApi}addOrUpdate`, obj, {headers: this.headers, observe: 'response'});
  }

  download(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}downloadFile`, obj, {headers: this.headers, observe: 'response'});
  }
  downloadInaccessible(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}downloadInaccessibleFile`, obj, {headers: this.headers, observe: 'response'});
  }
  downloadRejectionReports(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}downloadRejectionReportsFile`, obj, {headers: this.headers, observe: 'response'});
  }
  downloadReplacedSlrn(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}downloadReplacedSlrnFile`, obj, {headers: this.headers, observe: 'response'});
  }
  generateTemplate(utilityId: number): Observable<any> {
    return this.http.get(`${this.pathApi}generateTemplate?utilityId=${utilityId}`);
  }

  uploadFile(obj: any, utilityId: number): Observable<any> {
    return this.http.post(`${this.pathApi}uploadFile?utilityId=${utilityId}`, obj);
  }  
  getBuildingDataQuality(id:number):Observable<any>{
    return this.http.get(`${this.pathApi}getBuildingDataQuality/${id}`, {observe: 'response'});
}
rejectBuilding(obj : any): Observable<any> {
    return this.http.post(`${this.pathApi}rejectBuilding`, obj, {headers: this.headers, observe: 'response'});
}
approveBuilding(obj : any): Observable<any> {
    return this.http.post(`${this.pathApi}approveBuilding`, obj, {headers: this.headers, observe: 'response'});
  }
  auditBuilding(id: number): Observable<any> {
    return this.http.post(`${this.pathApi}auditBuilding/${id}`, id, { headers: this.headers, observe: 'response' });
}
getConnectedCustomersDataQualityStatus(id:number):Observable<any>{
  return this.http.get(`${this.pathApi}getConnectedCustomersDataQualityStatus/${id}`, {observe: 'response'});
}
}
