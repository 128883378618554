import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AppConfig } from "../config/config";
import { DtMeterType } from "../models/dtMeterType.model";

@Injectable({
    providedIn: 'root'
  })
export class DtMeterTypeService {
    
    private pathApi = this.config.setting['pathApi'] + "dtMeterType/";
    private headers = new HttpHeaders({'Content-Type': 'application/json'});

    constructor(private http: HttpClient, private config: AppConfig) {}

    getAllDtMeterTypes(dataIn: any): Observable<any> {
        return this.http.post(`${this.pathApi}getAll`, dataIn, {observe: 'response'});
    }
    
    saveDtMeterType(dtMeterType: DtMeterType): Observable<any> {
        return this.http.post(`${this.pathApi}save`, dtMeterType, {observe: 'response'});
    }

    deleteDtMeterType(id: number): Observable<any> {
        return this.http.delete(`${this.pathApi}delete/${id}`, {observe: 'response'});
    }
}