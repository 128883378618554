import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilityBasicInfo } from 'src/app/models/utility.model';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit {
  show: boolean = false;
  state = 'closed';
  utility: UtilityBasicInfo = new UtilityBasicInfo();
  constructor(
    private utilityService: UtilityService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    var utilityRoute = this.route.snapshot.paramMap.get('utility') || '';
    if (utilityRoute) {
      this.tryGetUtilityByName(utilityRoute.replace(new RegExp("-", "g"), " "));
    } else this.router.navigate(['']);
  }

  showSlider() {
    this.show = false;
  }

  tryGetUtilityByName(name: string) {
    this.utilityService.getByName(name).subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK' && responseData.data) {
          this.utility = responseData.data;
        } else {
          this.router.navigate(['']);
        }
      },
      error: (_) => {
        this.router.navigate(['']);
      },
    });
  }
}
