import { Component, EventEmitter, Input, OnInit, Output, SimpleChange } from '@angular/core';

@Component({
  selector: 'app-dashboard-multi-select-dropdown',
  templateUrl: './dashboard-multi-select-dropdown.component.html',
  styleUrls: ['./dashboard-multi-select-dropdown.component.scss']
})
export class DashboardMultiSelectDropdownComponent {

  @Input() items: any[];
  @Input() label: string; 
  @Input() selectedAll: boolean = true;
  @Output() shareCheckedList = new EventEmitter();
  @Output() shareIndividualCheckedList = new EventEmitter();


  checkedList: any;
  currentSelected: any;


  constructor() {
    this.checkedList = [];
    this.label = "Select"
  }
  getSelectedValue(status: Boolean, value: String) {
    if (status) {
      this.checkedList.push(value);
    } else {
      var index = this.checkedList.indexOf(value);
      this.checkedList.splice(index, 1);
    }
    this.currentSelected = { checked: status, name: value };
    //share checked list
    this.shareCheckedlist();

    //share individual selected item
    this.shareIndividualStatus();
  }
  shareCheckedlist() {
   
    this.shareCheckedList.emit(this.items.filter(x => x.checked == true).map(x => x.id));
  }
  shareIndividualStatus() {
    this.shareIndividualCheckedList.emit(this.currentSelected);
  }
  ngOnChanges(changes: SimpleChange) {
    console.log(this.items)   
    for (const propName in changes) {
      if (propName === 'selectedAll') {
   }
    }
  }
  selectAll() {
    this.selectedAll = true;
    this.items.forEach(item => {
      if (item.isSelectable) {
        item.checked = true;
      }
    });
    console.log(this.selectedAll)
    this.shareCheckedlist();
  }

  deselectAll() {
    this.selectedAll = false;
    this.items.forEach(item => {
      item.checked = false;
    });
    console.log(this.selectedAll)
    this.shareCheckedlist();
  }
}
