import { Injectable } from '@angular/core';
import { ContainerClient, BlobServiceClient } from '@azure/storage-blob';
import { AppConfig } from 'src/app/config/config';
import { ContainerNames } from 'src/app/models/containerNames.model';
import { Guid } from 'typescript-guid';

// setLogLevel("info");

@Injectable({
  providedIn: 'root'
})
export class BlobStorageService {
    private accountName: string = "caimsmsstorage";
    private containerName: string;
    private sasToken: string = "sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2124-11-01T16:54:58Z&st=2024-11-01T08:54:58Z&spr=https,http&sig=d9ApYZMtc4hMv7Cy9zFwTxdru4mewl7rr3PeWYbQLSs%3D";

    constructor() {}

    public setContainer(containerName: ContainerNames) {
        this.containerName = containerName;
    }

    public uploadFile(
        content: Blob, 
        name: string, 
        successHandler: (response: any) => void, 
        errorHandler: (error: any) => void
    ) {
        this.uploadBlob(
            content, 
            `${name}_${Guid.create()}`, 
            this.containerClientWithSas(), 
            successHandler, 
            errorHandler
        );
    }

    private uploadBlob(
        content: Blob, 
        name: string, 
        client: ContainerClient, 
        successHandler: (response: any) => void, 
        errorHandler: (error: any) => void
    ) {
        const blockBlobClient = client.getBlockBlobClient(name);
        blockBlobClient.uploadData(content, { blobHTTPHeaders: { blobContentType: content.type } })
            .then((response) => successHandler(response._response.request.url))
            .catch((error) => errorHandler(error));  // Call error handler if upload fails
    }

    // public uploadFile(content: Blob, name: string, handler: (response: any) => void) {
    //     this.uploadBlob(content, `${name}_${Guid.create()}`, this.containerClientWithSas(), handler);
    // }

    public async listFile(): Promise<string[]> {
        return this.listBlobs(this.containerClient());
    }

    public downloadFile(name: string, handler: (blob: Blob) => void) {
        this.downloadBlob(name, this.containerClient(), handler);
    }

    // private uploadBlob(content: Blob, name: string, client: ContainerClient, handler: (response: any) => void) {
    //     const blockBlobClient = client.getBlockBlobClient(name);
    //     blockBlobClient.uploadData(content, { blobHTTPHeaders: { blobContentType: content.type } })
    //         .then((response) => handler(response._response.request.url));
    // }

    private async listBlobs(client: ContainerClient): Promise<string[]> {
        const result: string[] = [];
        for await (const blob of client.listBlobsFlat()) {
            result.push(blob.name);
        }
        return result;
    }

    private downloadBlob(name: string, client: ContainerClient, handler: (blob: Blob) => void) {
        const blobClient = client.getBlobClient(name);
        blobClient.download().then(resp => {
            if (resp.blobBody) {
                resp.blobBody.then(blob => handler(blob));
            }
        });
    }

    private containerClient(): ContainerClient {
        return new BlobServiceClient(`https://${this.accountName}.blob.core.windows.net`).getContainerClient(this.containerName);
    }

    private containerClientWithSas(): ContainerClient {
        return new BlobServiceClient(`https://${this.accountName}.blob.core.windows.net?${this.sasToken}`).getContainerClient(this.containerName);
    }
}
