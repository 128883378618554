import { CustomerSearchType } from './../models/work-order.model';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppConfig } from "../config/config";
import { PaginationDto, ResponsePackage } from "../models/util.model";
import { MaintenanceStats, WorkOrderAssetType, WorkOrderModel } from "../models/work-order.model";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class MaintenanceService {

  private pathApi = this.config.setting['pathApi'] + "maintenance/";
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  constructor(private http: HttpClient, private config: AppConfig) { }

  save(obj: any) {
    return this.http.post<ResponsePackage<string>>(`${this.pathApi}save`, obj, { headers: this.headers, observe: 'response' });
  }

  getAll(obj: any) {
    return this.http.post<ResponsePackage<PaginationDto<WorkOrderModel>>>(`${this.pathApi}getAll`, obj, { headers: this.headers, observe: 'response' });
  }

  getAssetInfo(obj: any) {
    return this.http.post<ResponsePackage<any>>(`${this.pathApi}getAssetInfo`, obj, { headers: this.headers, observe: 'response' });
  }

  getStats() {
    return this.http.get<ResponsePackage<MaintenanceStats>>(`${this.pathApi}getStats`, { headers: this.headers, observe: 'response' });
  }

  download(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}downloadFile`, obj, { headers: this.headers, observe: 'response' });
  }
  getMaintenanceDetails(id: number) {
    return this.http.get<ResponsePackage<any>>(`${this.pathApi}${id}`, { headers: this.headers, observe: 'response', });
  }
  assignMaintenance(Id: number, userId: number) {
    const obj = { entityId: Id, userId: userId };
    return this.http.post<ResponsePackage<string>>(`${this.pathApi}assignMaintenance`, obj, { observe: 'response' });
  }
  resolveMaintenance(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}resolve`, obj);
  }
  cancelMaintenance(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}cancel`, obj);
  }
  addComment(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}addComment`, obj);
  }
  uploadImages(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}uploadImages`, obj);
  }
}