import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../services/user.service';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { User } from '../../models/user.model';
import jwt_decode from "jwt-decode";
import { Role } from 'src/app/models/role.model';

@Component({
  selector: 'app-update-profile',
  templateUrl: './update-profile.component.html',
  styleUrls: ['./update-profile.component.scss']
})
export class UpdateProfileComponent implements OnInit {

  @ViewChild('userImage') userImageInput: ElementRef;
  constructor(private toastr: ToastrService, private userService: UserService, private authService: AuthService, private router: Router) { }

  userImage: File | null;
  blobUrl: string = '';
  hasImage: boolean = false;
  imageSrc: string | ArrayBuffer ;
  loggedUser: User =   {
    id:  0,
    firstName:  '',
    lastName:'',
    fullName:'',
    email: '',
    role: new Role(),
    status:'',
    profileImage: '',
    regionId: 0,
    userAreas:''
  };

  ngOnInit(): void {
    let user: any = jwt_decode(localStorage.getItem("token")!);
    if(user != undefined) {
      this.loggedUser.firstName = user["name"].split(' ')[0];
      this.loggedUser.email = user['email'];
    }

    this.getUser();
  }

  getUser() {
    this.userService.getUser(this.loggedUser.email).subscribe({
      next: (data) => {
        this.loggedUser = data.data;
        this.blobUrl = this.loggedUser.profileImage ?? '';
        this.hasImage = (this.blobUrl != '' && this.blobUrl != undefined) ? true : false;
      },
      error: (error) => console.log(error) 
    });
  }

  handleUserFormSubmit() {
    if (!this.userImage) {
        this.toastr.error('Image data is missing');
        return
    }
    this.userService.updateUserProfile( this.loggedUser, this.userImage, this.blobUrl).subscribe({
      next: (data: any) => {
        this.router.navigate(['profile']);
        this.toastr.success(data.message);
      },
      error: (error) => console.log(error) 
    });
  }

  
  fileBrowserHandler($event: any) {
    this.userImage = $event.target.files.item(0);
    if(this.userImage?.type!="image/png" &&this.userImage?.type!="image/jpg" &&this.userImage?.type!="image/jpeg" )
    {
      this.toastr.warning("Only PNG/JPG/JPEG images are allowed");
      console.log(this.userImageInput);
      if (this.userImageInput?.nativeElement) {
        this.userImageInput.nativeElement.value = '';
      }
    }
    else
    {
      this.hasImage = true;
      const reader = new FileReader();
      reader.onload = e => this.imageSrc = reader.result!;
      reader.readAsDataURL(this.userImage!);
    }
  }

  onFileDropped($event: any) {
    this.userImage = $event.item(0);
    if(this.userImage?.type!="image/png" &&this.userImage?.type!="image/jpg" &&this.userImage?.type!="image/jpeg" )
    {
      this.toastr.warning("Only PNG/JPG/JPEG images are allowed");
      console.log(this.userImageInput)
      if (this.userImageInput?.nativeElement) {
        this.userImageInput.nativeElement.value = '';
      }
    }
    else
    {
      this.hasImage = true;
      const reader = new FileReader();
      reader.onload = e => this.imageSrc = reader.result!;
      reader.readAsDataURL(this.userImage!);
    }
   
  }

  removeImage() {
    this.blobUrl = '';
    this.userImage = null;
    this.hasImage = false;
  }
}
