import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ServiceConnection } from 'src/app/models/serviceConnection.model';
import { AccountKycService } from 'src/app/services/kyc/account-kyc.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { SiteVerificationService } from 'src/app/services/siteVerification.service';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-service-connection-details',
  templateUrl: './service-connection-details.component.html',
  styleUrls: ['./service-connection-details.component.scss']
})
export class ServiceConnectionDetailsComponent implements OnInit {
  // PermissionsEnum = PermissionsEnum;

  customer: ServiceConnection = new ServiceConnection();

  editEnabled: boolean = false;
  meterEditEnabled: boolean = false;
  // f11Options: F11Crud[] = [];
  // f33Options: F33Crud[] = [];
  // dtOptions: OptionObj[] = [];
  // meterTypeOptions: OptionObj[] = [];
  openApproveModal: boolean = false;
  openRejectModal: boolean = false;
  editFieldsPopulated: boolean = false;
  updateStatusClicked: boolean = false;

  customerId = 0;
  // @ViewChild('closeModal') closeModal: ElementRef;
  // @ViewChild('closeDQModal') closeDQModal: ElementRef;
  // @ViewChild('closeAudit') auditReviewModal: ElementRef;

  // @ViewChild('openUpdateDescriptionModal')
  // openUpdateDescriptionModal: ElementRef;
  // @ViewChild('dropdownContainer') dropdownContainer: ElementRef;
  // @ViewChild('userFilterInput') userFilter: ElementRef;

  tempAssignedUserId: number = 0;
  buName: string = this.translationService.getByKeyFromStorage('BU');
  utName: string = this.translationService.getByKeyFromStorage('UT');
  contractNumber: string =
  this.translationService.getByKeyFromStorage('Contract number');

  constructor(
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    public permissionsService: PermissionsService,
    private router: Router,
    private translationService: TranslationService,
    private kycService: AccountKycService,
    private siteVerificationService: SiteVerificationService,
    // private userService: UserService,
    // private meterService: MeterService,
    // private buildingService: BuildingService,
    // private el: ElementRef,
    // private renderer: Renderer2,
  ) { }

  ngOnInit(): void {
    const customerId = this.activatedRoute.snapshot.paramMap.get('id');
    this.customerId = Number(customerId);
    this.getCustomer(customerId);
  }

  private getCustomer(customerId: any) {
    this.kycService.getServiceConnectionById(customerId).subscribe({
      next: (response) => {
        if (response?.status === 200 || response?.status === 'OK') {
          this.customer = new ServiceConnection(response.data);
        } else {
          this.toastr.error(response?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  downloadInvoice() {
    if (this.customer.invoiceUrl)
      window.location.href = this.customer.invoiceUrl;
  }

  resendInvoice() {
    this.siteVerificationService.resendInvoice(this.customer.siteVerificationId).subscribe({
      next: (response) => {
        if (!response || response?.status === 200 || response?.status === 'OK') {
          this.toastr.success("Invoice is successful resend");
          setTimeout(function () {
            window.location.reload();
          }, 3000);
        } else {
          this.toastr.error(response?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  confirmPayment(){
    this.kycService.confirmPayment(this.customer.id, this.customer.siteVerificationId).subscribe({
      next: (response) => {
        if (response?.status === 200 || response?.status === 'OK') {
          this.toastr.success(response?.message);
          window.location.reload();
        } else {
          this.toastr.error(response?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }
}
