import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { IOption } from 'src/app/_modules/shared/_interfaces/option';
import { CustomerSearchType, MaintenanceCategory, WorkOrderAssetType, WorkOrderModel } from 'src/app/models/work-order.model';
import { User } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user.service';
import { WorkOrderMenuItem } from 'src/app/models/workOrderMenuItem.model';
import { MaintenanceService } from 'src/app/services/maintenance.service';
import { AreaService } from 'src/app/services/area.service';
import { RegionService } from 'src/app/services/region.service';
import { Region } from 'src/app/models/region.model';
import { Area } from 'src/app/models/area.model';
import { TranslationService } from 'src/app/services/translation.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-new-work-order',
  templateUrl: './new-work-order.component.html',
  styleUrls: ['./new-work-order.component.scss']
})
export class NewWorkOrderComponent implements OnInit {
  newWorkOrder: WorkOrderModel = new WorkOrderModel();
  fieldAgents: User[] = [];
  regions: Region[] = [];
  areas: Area[] = [];
  selectableAreas: Area[] = [];
  selectedRegion: number;
  area: string = '';
  region: string = '';
  buName: string = this.translationService.getByKeyFromStorage('BU');
  utName: string = this.translationService.getByKeyFromStorage('UT');
  assetTypes: IOption[] = [
    {
      value: WorkOrderAssetType.Building,
      displayValue: 'Building'
    },
    {
      value: WorkOrderAssetType.Customer,
      displayValue: 'Customer'
    },
    {
      value: WorkOrderAssetType.Pole,
      displayValue: 'LT Pole'
    },
    {
      value: WorkOrderAssetType.Transformer,
      displayValue: 'Transformers'
    },
    {
      value: WorkOrderAssetType.Feeder,
      displayValue: 'Feeders'
    },
  ];
  customerSearchTypes: IOption[] = [
    {
      value: CustomerSearchType.AccountNo,
      displayValue: 'Account no'
    },
    {
      value: CustomerSearchType.MeterNo,
      displayValue: 'Meter no'
    },
    {
      value: CustomerSearchType.SPN,
      displayValue: 'SPN'
    },
    {
      value: CustomerSearchType.Slrn,
      displayValue: 'Meter SLRN'
    }
  ];
  categories: IOption[] = [
    {
      value: MaintenanceCategory.Installation,
      displayValue: 'Installation'
    }
  ];
  maintenanceTab: number = WorkOrderMenuItem.Maintenance;

  WorkOrderAssetType = WorkOrderAssetType;
  customerSearchType: CustomerSearchType | null;
  slrn: string;
  informations: any;

  constructor(
    private toastr: ToastrService,
    private userService: UserService,
    private maintenanceService: MaintenanceService,
    private areaService: AreaService,
    private regionService: RegionService,
    private translationService: TranslationService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.getAreas();
    this.getRegions();
  }

  getRegions() {
    this.regionService.getAllForSelect().subscribe({
      next: response => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === "OK") {
          this.regions = responseData.data;
        } else {
          this.toastr.error(responseData?.message);
        }

      },
      error: _ => {
        this.toastr.error("Error occured");
      }
    });
  }

  getAreas() {
    this.areaService.getAllForSelect().subscribe({
      next: response => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === "OK") {
          this.areas = responseData.data;
          this.selectableAreas = responseData.data;
        } else {
          this.toastr.error(responseData?.message);
        }

      },
      error: _ => {
        this.toastr.error("Error occured");
      }
    });
  }

  selectRegion(e: any) {
    this.selectedRegion = +e.target.value;

    this.selectableAreas = this.areas.filter(area => area.regionId === this.selectedRegion);
    this.area = '';
    this.newWorkOrder.areaId = null;
    this.region = this.regions.filter(x => x.id == this.selectedRegion).toString();
  }

  selectArea(e: any) {
    this.newWorkOrder.areaId = +e.target.value;
    const area = this.areas.find(x => x.id === this.newWorkOrder.areaId);
    this.area = area?.name!;
    const region = this.regions.find(region => region.id === area?.regionId)!;
    this.region = region.name;
    this.getMobileUsers();
  }

  searchForAsset() {
    if (this.newWorkOrder.assetType && this.slrn) {
      if (this.newWorkOrder.assetType == WorkOrderAssetType.Customer && !this.customerSearchType) {
        this.toastr.warning("Please provide Search by field");
        return;
      }
      this.getAssetInfo(this.newWorkOrder.assetType, this.slrn, this.customerSearchType);
    } else {
      this.toastr.warning("Please provide both Asset Category and Asset SLRN");
    }
  }

  getAssetInfo(assetType: WorkOrderAssetType, slrn: any, customerSearchType: CustomerSearchType | null) {
    var obj = {
      assetType: assetType,
      slrn: slrn,
      customerSearchType: customerSearchType
    };
    this.maintenanceService.getAssetInfo(obj).subscribe(response => {
      const responseData = response.body;
      if (responseData?.status === 200 || responseData?.status === "OK") {
        this.informations = responseData.data.information;
        this.newWorkOrder.assetId = responseData.data.id;
        this.toastr.success(responseData?.message);
        console.log(this.newWorkOrder, "newWorkOrder")
      } else {
        this.toastr.error(responseData?.message);
        this.informations = null;
        this.newWorkOrder.assetId = null;
        console.log(this.newWorkOrder, "newWorkOrder")
      }
    },
      error => {
        this.toastr.error("An error occured");
      });
  }

  handleFormSubmit() {
    if (this.newWorkOrder.assetId == null)  {
      this.toastr.error("Please select valid asset");
      return;
    }
    this.maintenanceService.save(this.newWorkOrder).subscribe(
      response => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === "OK") {
          this.toastr.success("Successfully added new work order!");
          this.newWorkOrder = new WorkOrderModel
          this.router.navigate(['work-orders/tab/1']);
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error => {
        this.toastr.error("Error occured");
      }
    );
  }

  getMobileUsers() {
    this.userService.getMobileUsers(this.newWorkOrder.areaId!).subscribe({
      next: response => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === "OK") {
          this.fieldAgents = responseData.data ? responseData.data.map((el: any) => new User(el)) : [];
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: _ => {
        this.toastr.error("Error occured");
      }
    });
  }

  selectOption(e: any) {
    // console.log('selectOption', +e.target.value);
    this.newWorkOrder.assignedUserId = +e.target.value;
  }

  selectAssetType(e: any) {
    // console.log('selectAssetType', e.target.value);
    this.newWorkOrder.assetType = e.target.value;
  }
  selectCategory(e: any) {
    this.newWorkOrder.category = e.target.value;
  }

  selectCustomerSearchType(e: any) {
    this.customerSearchType = e.target.value;
  }
}
