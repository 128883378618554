export class AdditionalInfo {
    public id: string;
    public customers: any;
    public tooltipCustomer: any;
    public tooltipBuilding: any;
    public tooltipPole: any;
    public tooltipDt: any;

    constructor(data?: any) {
        this.id = data?.id ?? '';
        this.customers = data?.customers;
        this.tooltipCustomer = data?.tooltipCustomer;
        this.tooltipBuilding = data?.tooltipBuilding;
        this.tooltipPole = data?.tooltipPole;
        this.tooltipDt = data?.tooltipDt;
    }
}