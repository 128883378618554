import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import {
  InstallationAndPowerSource,
  SltStatus,
} from 'src/app/models/customer.model';
import { PermissionsEnum } from 'src/app/models/role.model';
import { CustomerService } from 'src/app/services/customer.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { ToastrService } from 'ngx-toastr';
import { TranslationService } from 'src/app/services/translation.service';
import { BPSAdminLevel } from 'src/app/models/user.model';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { TranslationUtils } from 'src/assets/i18n/translation.utils';
@Component({
  selector: 'crud-slt-status',
  templateUrl: './crud-slt.component.html',
  styleUrls: ['./crud-slt.component.scss'],
})
export class CrudSltComponent implements OnInit {
  public PermissionsEnum = PermissionsEnum;
  sltStatuses: SltStatus[] = [];
  installationAndPowerSources = [
    {
      label: 'High-Capacity Meter Installation',
      value: InstallationAndPowerSource.High_Capacity,
    },
    {
      label: 'Standard Meter Installation',
      value: InstallationAndPowerSource.Standard,
    },
    { label: 'None', value: InstallationAndPowerSource.None },
  ];
  selectedInstallationAndPowerSource?: InstallationAndPowerSource;

  @ViewChild('closeModal') closeModal: ElementRef;
  @Input() searchText!: string;
  @Input() utilityId: number = 0;
  @Input() bpsAdminLevel!: BPSAdminLevel;

  sltStatusName: string =
    this.translationService.getByKeyFromStorage('SltStatus');
  public currentPage: number = 1;
  public pageSize: number = 5;
  public count: number = 0;
  selectedSlt: SltStatus = new SltStatus();
  actionLabel$!: Observable<string>;
  isEditMode: boolean = false;
  constructor(
    private customerService: CustomerService,
    private toastr: ToastrService,
    public permissionsService: PermissionsService,
    private translationService: TranslationService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    if (this.utilityId != 0) {
      this.reloadTable();
    }

    this.updateActionLabel();
  }

  private updateActionLabel(): void {
    const key = this.isEditMode ? TranslationUtils.EDIT_KEY : TranslationUtils.NEW_KEY;
    this.actionLabel$ = this.translateService.stream(key);
  }

  filter() {
    this.currentPage = 1;
    // this.utilityId = utilityId;
    let dataIn = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize,
      },
      filterParams: {
        SearchValue: this.searchText,
      },
      utilityId: this.utilityId,
    };

    this.getAllSltStatuses(dataIn);
  }

  reloadTable(page: any = null) {
    if (page) {
      this.currentPage = page;
    }

    let dataIn = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize,
      },
      searchText: this.searchText,
      utilityId: this.utilityId,
    };

    this.getAllSltStatuses(dataIn);
  }

  pageChange(value: any) {
    this.currentPage = value;
    this.reloadTable();
  }

  private getAllSltStatuses(dataIn: any) {
    this.customerService.getSltStatusesForCrud(dataIn).subscribe({
      next: (response) => {
        this.sltStatuses = response.data.data;
        this.sltStatuses.forEach((x) => {
          const numericValue = InstallationAndPowerSource[x.installationAndPowerSource.toString() as keyof typeof InstallationAndPowerSource];
          x.friendlyInstallationAndPowerSource = this.getLabelFromValue(numericValue);
        });
        this.count = response.data.count;
        if (this.count == 0) {
          this.toastr.info('No data for given input.');
        }
      },
      error: (_) => this.toastr.error('An error ocurred.'),
    });
  }

  save() {
    if (this.selectedInstallationAndPowerSource == undefined)
      this.toastr.error(
        'Please select the Meter Installation and Power Source Assessment plan from the dropdown'
      );
    else {
      this.selectedSlt.utilityId = this.utilityId;
      this.selectedSlt.installationAndPowerSource =
        this.selectedInstallationAndPowerSource!;
      this.customerService.saveSlt(this.selectedSlt).subscribe({
        next: (response) => {
          if (response.status === 200 || response.status === 'OK') {
            this.closeModal?.nativeElement?.click();
            this.toastr.success(response.message);
            if (this.selectedSlt.id === 0) {
              this.pageChange(1);
              return;
            }
            this.reloadTable();
          } else {
            this.toastr.error(response.message);
          }
        },
        error: (_) => this.toastr.error('Error occured'),
      });

    }
  }

  selectSlt(ts: SltStatus) {
    this.isEditMode = true;
      this.selectedSlt = { ...ts };

    this.selectedInstallationAndPowerSource =
      InstallationAndPowerSource[
        this.selectedSlt.installationAndPowerSource.toString() as keyof typeof InstallationAndPowerSource
      ];
    this.updateActionLabel();
  }

  addNew() {
    this.isEditMode = false;
    this.selectedSlt = new SltStatus();
    this.updateActionLabel();
  }

  deleteSlt() {
    this.customerService.deleteSlt(this.selectedSlt.id).subscribe({
      next: (response) => {
        this.closeModal?.nativeElement?.click();
        if (response.status === 200 || response.status === 'OK') {
          this.pageChange(1);
          this.toastr.success(response.message);
        } else {
          this.toastr.error(response.message);
        }
      },
      error: (_) => this.toastr.error('Error occured'),
    });

    this.selectedInstallationAndPowerSource = undefined;
  }

  onModalFade() {
    this.selectedInstallationAndPowerSource = undefined;
  }

  getLabelFromValue(value: InstallationAndPowerSource): string | undefined {
    return this.installationAndPowerSources.find((y) => y.value === value)?.label;
  }
}
