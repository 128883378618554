import { Component, OnInit,Input, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChartConfiguration } from 'chart.js';

@Component({
  selector: 'app-doughnut-chart',
  templateUrl: './doughnut-chart.component.html',
  styleUrls: ['./doughnut-chart.component.scss']
})
export class DoughnutChartComponent implements OnInit {

  @Input() chartLabels: string[] = [];
  @Input() data: any[] = [];
  @Input() colors: string[] = [];
  @Input() title: string = "";

  chartDatasets:ChartConfiguration<'doughnut'>['data']['datasets'] = [];

  public chartOptions: ChartConfiguration<'doughnut'>['options'] = {
    responsive: true,
    aspectRatio: 1.5,
    cutout: 50,
    plugins: {
      tooltip: {
        position: 'nearest',
        intersect: false
      }
    }
  };
  
  constructor(private translateService: TranslateService) { }

  ngOnInit(): void {
    this.plotGraph();

  }

  ngOnChanges(changes: SimpleChanges) {
     this.plotGraph();
    } 

  plotGraph() {
    let values = this.data.map(function(v){
      return v.share;
    });

    this.chartLabels = this.data.map(v => {
      return this.translateService.instant(v.key);
    });

    this.chartDatasets =  [
      { data: values,
        backgroundColor: this.colors,
        borderWidth: 0,
        hoverBackgroundColor: this.colors },
    ];
  }
}
