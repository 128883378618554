export class KycBuildingOwnerInformation {
    buildingOwner: string;
    phoneNumber: string;
    email: string;
    dateOfBirth: string;
    premisesAccountNumber: string;

    constructor(data: any) {
        this.buildingOwner = data?.buildingOwner ?? '';
        this.phoneNumber = data?.phoneNumber ?? '';
        this.email = data?.email ?? '';
        this.dateOfBirth = data?.dateOfBirth ?? '';
        this.premisesAccountNumber = data?.premisesAccountNumber ?? '';
    }
}
