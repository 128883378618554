export class KycAccountInformation {
    accountNumber: string;
    accountName: string;
    locationAddress: string;
    currentTariff: string;
    feeder: string;
    distributionTransformer: string;
    businessUnit: string;
    undertaking: string;
    balanceAmountAgreement: number | null;
    debtRepaymentOption: number | null;
    tariffId: number | null;
    dtId: number | null;
    undertakingId: number | null;

    constructor(data: any) {
        this.accountNumber = data?.accountNumber ?? '';
        this.accountName = data?.accountName ?? '';
        this.locationAddress = data?.locationAddress ?? '';
        this.currentTariff = data?.currentTariff ?? '';
        this.feeder = data?.feeder ?? '';
        this.distributionTransformer = data?.distributionTransformer ?? '';
        this.businessUnit = data?.businessUnit ?? '';
        this.undertaking = data?.undertaking ?? '';
        this.balanceAmountAgreement = data?.balanceAmountAgreement;
        this.debtRepaymentOption = data?.debtRepaymentOption;
        this.tariffId = data?.tariffId;
        this.dtId = data?.dtId;
        this.undertakingId = data?.undertakingId;
    }
}
