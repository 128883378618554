import { KycConsentConfig } from './kycConsentConfig.model';
import { Translation } from './translation.model';
import { ValidationRule } from './validationRule.model';

export class AddUtility {
  name: string = '';
  subdomain: string = '';
  colorCode: string = '';
  dashboardConfiguration: DashboardConfiguration[] = [];
  image: File | null;
}

export class UtilityList {
  id: number = 0;
  name: string = '';
  subDomain: string = '';
  colorCode: string = '';
  imageUrl: string = '';
  status :string = '';
}
export class UtilityDetails {
  id: number = 0;
  name: string = '';
  fullName: string = '';
  subdomain: string = '';
  colorCode: string = '';
  imageUrl: string = '';
  dashboardConfiguration: DashboardConfiguration[] = [];
  translations: Translation[] = [];
  validationRules: ValidationRule[] = [];
  kycConsentConfiguration: KycConsentConfig = new KycConsentConfig();
  contactPerson: ContactPerson = new ContactPerson();
  bankDetails: BankDetails = new BankDetails();
  currency:string = '';
}
export class ContactPerson {
  firstName: string = '';
  lastName: string = '';
  eMail: string = '';
}
export enum DashboardConfiguration {
  Buildings = 0,
  Meters = 1,
  Poles = 2,
  DTs = 3,
  Feeders = 4,
  Survey = 5,
  Installation = 6,
  WorkOrder = 7,
  MeterManagement = 8,
  CustomerManagement = 9,
  Readings = 10,
}

export class UtilityBasicInfo {
  id: number = 0;
  name: string = '';
  currency:string = '';
}

export class BankDetails {
  bankName: string = '';
  accountName: string = '';
  accountNumber: string = '';
}
