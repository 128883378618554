import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PermissionsEnum } from 'src/app/models/role.model';
import {
  SiteVerificationDetails,
  getInitDetails,
} from 'src/app/models/siteVerification.model';
import { SurveySave } from 'src/app/models/survey.model';
import { User } from 'src/app/models/user.model';
import { PermissionsService } from 'src/app/services/permissions.service';
import { SiteVerificationService } from 'src/app/services/siteVerification.service';
import { SurveyService } from 'src/app/services/survey.service';
import { TranslationService } from 'src/app/services/translation.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-site-verification-details',
  templateUrl: './site-verification-details.component.html',
  styleUrls: ['./site-verification-details.component.scss'],
})
export class SiteVerificationDetailsComponent implements OnInit {
  @ViewChild('closeModal') closeModal: ElementRef;

  constructor(
    private activatedRoute: ActivatedRoute,
    private siteVerificationService: SiteVerificationService,
    private toastr: ToastrService,
    public permissionsService: PermissionsService,
    private translationService: TranslationService,
    private renderer: Renderer2,
    private userService: UserService
  ) {}
  selectedImage: string = '';
  filterText: string = '';
  filteredMobileUsers: User[] = [];
  tempAssignedUserId: number = 0;
  PermissionsEnum = PermissionsEnum;
  editEnabled: boolean = false;
  onlyView: boolean = false;
  buName: string = this.translationService.getByKeyFromStorage('BU');
  utName: string = this.translationService.getByKeyFromStorage('UT');
  siteVerification: SiteVerificationDetails = getInitDetails();
  imgElement: any;
  isDropdownOpened: boolean = false;
  placeholderImage: string = '../../../../assets/no-image.png';
  imgZoomFullContainer: any;
  currentRotation: number = 0;
  assignedUserId: number = 0;
  mobileUsers: User[] = [];
  id: string = '';
  rejectionNote: string = '';
  dtName: string = this.translationService.getByKeyFromStorage('DTNumber');
  survey: SurveySave = new SurveySave();
  customerId: number = 0;

  ngOnInit(): void {
    const verificationId = this.activatedRoute.snapshot.paramMap.get('id');
    this.id = verificationId || '';
    this.getSiteVerification(verificationId);
  }

  getMobileUsers() {
    this.userService
      .getMobileUsers(this.siteVerification.area.id, this.filterText)
      .subscribe({
        next: (response) => {
          const responseData = response.body;
          if (responseData?.status === 200 || responseData?.status === 'OK') {
            this.mobileUsers = responseData.data;
            if (this.mobileUsers.length == 0) {
              this.isDropdownOpened = false;
            } else {
              this.isDropdownOpened = true;
            }
          } else {
            this.toastr.error(responseData?.message);
          }
        },
        error: (_) => {
          this.toastr.error('Error occured');
        },
      });
  }
  filterMobileUsers() {
    this.isDropdownOpened = true;
    this.tempAssignedUserId = 0;
    this.getMobileUsers();
  }
  edit() {
    this.editEnabled = true;
  }
  openEditModal() {}
  rotateRight() {
    this.currentRotation += 90;
  }
  rotateLeft() {
    this.currentRotation -= 90;
  }
  showImage(num: number) {
    if (num === 0) {
      this.selectedImage = this.getFrontViewImage(0) || '';
    } else if (num === 1) {
      this.selectedImage = this.getFrontViewImage(1) || '';
    } else if (num === 2) {
      this.selectedImage = this.getFrontViewImage(2) || '';
    } else if (num === 3) {
      this.selectedImage = this.getBuildingMountedImage() || '';
    } else if (num === 4) {
      this.selectedImage = this.getCardImage() || '';
    } else if (num === 5) {
      this.selectedImage =
        this.siteVerification.accountKyc.customersSignitureUrl;
    } else if (num === 6) {
      this.selectedImage =
        this.siteVerification.accountKyc.landlordsSignitureUrl;
    } else if (num === 7) {
      this.selectedImage =
        this.siteVerification.accountKyc.electricalContractorsSignitureUrl;
    } else if (num === 8) {
      this.selectedImage =
        this.siteVerification.accountKyc.applicantsSignitureUrl;
    } else if (num === 9) {
      this.selectedImage = this.siteVerification.accountKyc.passportUrl;
    } else if (num === 10) {
      this.selectedImage = this.getCutOutImage() || '';
    } else if (num === 11) {
      this.selectedImage = this.getUtilitySupplyImage() || '';
    }
  }
  getBuildingMountedImage() {
    var image = this.siteVerification.images?.find(
      (x) => x.imageType === 'MountedTag'
    )?.url;

    return image;
  }
  getCardImage() {
    var image = this.siteVerification.images?.find(
      (x) => x.imageType === 'Card'
    )?.url;

    return image;
  }
  getUtilitySupplyImage() {
    var image = this.siteVerification.images?.find(
      (x) => x.imageType === 'UtilitySupply'
    )?.url;

    return image;
  }
  getCutOutImage() {
    var image = this.siteVerification.images?.find(
      (x) => x.imageType === 'CutOutFuse'
    )?.url;

    return image;
  }
  getFrontViewImage(id: number) {
    var image = this.siteVerification.images?.filter(
      (x) => x.imageType === 'Frontview'
    )[id]?.url;

    return image;
  }
  onModalFade() {
    this.currentRotation = 0;

    if (this.imgZoomFullContainer) {
      var displayValue = getComputedStyle(this.imgZoomFullContainer).display;

      if (displayValue == 'block') {
        this.imgElement.click();
      }
    }

    this.selectedImage = '';
  }

  onModalOpen() {
    this.renderer.setStyle(document.body, 'overflow', 'auto');
    this.renderer.setStyle(document.body, 'overflow-x', 'hidden');
    this.renderer.setStyle(document.body, 'padding-right', '0');
  }
  openDropdown() {
    this.isDropdownOpened = true;
  }
  onModalAssignFade() {
    this.filterText = this.siteVerification.assignedUser
      ? this.siteVerification.assignedUser
      : '';
  }

  onModalApproveFade() {
    this.isDropdownOpened = false;
  }

  selectFieldAgent(id: number) {
    this.isDropdownOpened = false;
    this.assignedUserId = id;
    this.tempAssignedUserId = id;
    var mobUser = this.mobileUsers.find((x) => x.id == id);
    this.filterText = mobUser!.fullName;
  }
  assignTask(): void {
    if (
      this.mobileUsers.filter((x) => x.fullName == this.filterText.trim())
        .length > 0
    ) {
      this.assignedUserId = this.mobileUsers.filter(
        (x) => x.fullName == this.filterText.trim()
      )[0].id;
      this.tempAssignedUserId = this.assignedUserId;
    } else {
      this.tempAssignedUserId = 0;
    }
    if (this.tempAssignedUserId != 0) {
      this.siteVerificationService
        .assignVerification(parseInt(this.id), this.assignedUserId)
        .subscribe({
          next: (response) => {
            const responseData = response.body;
            if (responseData?.status === 200 || responseData?.status === 'OK') {
              this.closeModal?.nativeElement?.click();
              this.toastr.success('Successfully assigned user');
              window.location.reload();
            } else {
              this.toastr.error(responseData?.message);
            }
          },
          error: (_) => {
            this.toastr.error('Error occured');
          },
        });
    } else {
      this.toastr.warning('Please select valid user.');
    }
  }
  approve() {
    this.siteVerificationService
      .approveSiteVerification(parseInt(this.id))
      .subscribe({
        next: (response) => {
          if (response?.status === 200 || response?.status === 'OK') {
            this.toastr.success('Site Verification Approved');
            window.location.reload();
          } else {
            this.toastr.error(response?.message);
          }
        },
      });
  }
  isEmail(search: string): boolean {
    var serchfind: boolean;

    var regexp = new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

    serchfind = regexp.test(search);
    return serchfind;
  }

  isPhoneNumber(search: string): boolean {
    var serchfind: boolean;

    var regexp = new RegExp(
      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{3,6}$/im
    );

    serchfind = regexp.test(search);
    return serchfind;
  }

  reject() {
    let obj = {
      id: parseInt(this.id),
      note: this.rejectionNote,
    };

    this.siteVerificationService.rejectSiteVerification(obj).subscribe({
      next: (response) => {
        if (response?.status === 200 || response?.status === 'OK') {
          this.toastr.success('Site Verification Approved');
        } else {
          this.toastr.error(response?.message);
        }
      },
    });
  }

  // approveAccountCreation() {
  //   let obj = {
  //     siteVerificationId: parseInt(this.id),
  //     assignedUserId: this.assignedUserId
  //   };

  //   this.siteVerificationService
  //     .approveAccountCreation(obj)
  //     .subscribe({
  //       next: (response) => {
  //         if (response?.status === 200 || response?.status === 'OK') {
  //           this.customerId = response.data;
  //           this.toastr.success('Account Creation has been completed.');
  //           window.location.reload();
  //         } else {
  //           this.toastr.error(response?.message);
  //         }
  //       },
  //     });
  // }

  save() {
    if (!this.isEmail(this.siteVerification.email)) {
      this.toastr.error('Invalid Email.');
      return;
    }
    if (!this.isPhoneNumber(this.siteVerification.phoneNumber)) {
      this.toastr.error('Invalid Phone Number.');
      return;
    }
    this.siteVerificationService
      .editSiteVerification(this.siteVerification)
      .subscribe({
        next: (response) => {
          if (response?.status === 200 || response?.status === 'OK') {
            this.toastr.success(
              'Successfully updated site verification details.'
            );
            window.location.reload();
          } else {
          }
        },
        error: (_) => {
          this.toastr.error('Error occured.');
        },
      });
    this.editEnabled = false;
  }
  private getSiteVerification(Id: any) {
    this.siteVerificationService.get(Id).subscribe({
      next: (response) => {
        console.log(response);
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          this.siteVerification = responseData.data;
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }


  public hideWord(w: any) {
    if (w) {
      if (w.length < 3) return w;
      return w.substring(0, 3) + '*'.repeat(w.length - 3);
    }
  }
}
