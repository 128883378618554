import { Component, OnInit } from '@angular/core';
import { DataQualityRejectionTabs } from '../../../enums/data-quality-rejection-tabs.enum';
import { DataQualityRejectionStats } from '../../../models/data-quality-rejection-stats.model';
import { BuildingService } from 'src/app/services/building.service';
import { ToastrService } from 'ngx-toastr';
import { PoleService } from 'src/app/services/pole.service';
import { ReportStateService } from '../services/report-state.service';

@Component({
  selector: 'data-quality-rejection',
  templateUrl: './data-quality-rejection.component.html',
  styleUrls: ['./data-quality-rejection.component.scss', ]
})
export class DataQualityRejectionComponent implements OnInit {
  tabs = DataQualityRejectionTabs;
  customersCount: number=0;
  buildingsCount: number=0;
  ltPolesCount: number=0;
  transformersCount: number=0;
  searchText: string = '';

  constructor(
    private buildingService: BuildingService,
    private poleService:PoleService,
    private toastr: ToastrService,
    public reportStateService: ReportStateService
  ) {}

  ngOnInit(): void {
    this.getStats()
    this.reportStateService.preselectDataQualityRejectionTab();
  }

  changeTab(tab: number) {
    this.reportStateService.qualityRejectionPageTabSelected(tab);
    this.searchText = '';
    // this.maintenanceList=[];
    // this.resetFilter(1);
    // this.getStats();
    // this.reloadTable();
  }

  public setCustomer(count: any) {
    this.customersCount = +count;
  }
  public setBuildings(count: any) {
    this.buildingsCount = +count;
  }
  public setPoles(count: any) {
    this.ltPolesCount = +count;
  }
  public setDts(count: any) {
    this.transformersCount = +count;
  }
  getStats() {
    this.getBuildingsStats();
    this.getPolesStats();
  }
  private getPolesStats() {
    this.poleService.getPoleRejectionReportsStats().subscribe({
      next: (response) => {
        const data = response.body;
        if (data?.status === 200 || data?.status === 'OK') {
          this.ltPolesCount = data.data.ltPoles;
          this.transformersCount = data.data.transformers;       
         } else {
          this.toastr.error(data?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }
  private getBuildingsStats() {
    this.buildingService.getRejectionReportStats().subscribe({
      next: (response) => {
        const data = response.body;
        if (data?.status === 200 || data?.status === 'OK') {
          console.log(data);
          this.buildingsCount = data.data.buildings;
        } else {
          this.toastr.error(data?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }
}
