<div class="content-card" [ngClass]="{ 'popup-content-card': isPopUp }">
  <div class="collection-wrapper">
    <div class="card">
      <div class="row">
        <div class="col-md-6 title">{{'SURVEYS.ASSIGN' | translate}}</div>
        <div class="col-md-6 close" (click)="closeModal()">{{'COMMON.CLOSE' | translate}}</div>
      </div>
      <div class="form-wrapper">
        <div class="row">
          <div class="col-md-6 title">{{'COMMON.CUSTOMER.SELECT' | translate}}</div>
          <div class="col-md-6 counter">
            {{ survey.customers.length }} {{'COMMON.CUSTOMER.SELECTED' | translate}}
          </div>
        </div>
        <div class="groupSelect">
          <!-- commented search for bu list  -->
          <div class="search-input">
            <img src="../../assets/icons/search.svg" alt="Search icon" (click)="search()" />
            <input type="text" [placeholder]="'COMMON.SEARCH' | translate" [(ngModel)]="searchCustomers" (keydown.enter)="search()"
              name="searchCustomers" />
          </div>
          <!-- show customers -->
          <div class="checkListBox">
            <div *ngFor="let customer of customersForSelect" class="option" (click)="onChangeBu(customer.id)"
              [ngClass]="{ 'selected-bu': isChecked(customer.id) }">
              <div class="row">
                <div class="col-md-1">
                  <input type="checkbox" id="{{ customer.id }}" [checked]="isChecked(customer.id)" disableRipple
                    name="selectedCustomers" />
                </div>
                <div class="col-md-11">
                  <label class="name">{{
                    customer.buildingOwner | hasValue
                    }}</label><br />
                  <label class="address">{{ customer.address | hasValue }} ({{'COMMON.MBC_DETAILS.ACCOUNTNO' | translate}}:
                    {{ customer.accountNumber | hasValue }})</label>
                  <div>
                    <label class="address">{{'COMMON.MBC_DETAILS.METER_NO' | translate}}: {{ customer.oldMeterNumber | hasValue }}
                    </label>
                    <label class="address">&nbsp; {{ 'COMMON.CONTRACT_NUMBER' | translate }}:
                      {{ customer.spn | hasValue }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="form-floating form-item">
          <input type="text" name="filterText" [(ngModel)]="filterText" class="form-control filter-field"
            [ngClass]="{ 'valid-input': tempAssignedUserId != 0 }" id="floatingInput"
            (ngModelChange)="filterMobileUsers()" placeholder=" " />
          <label for="floatingInput">{{'COMMON.FIELD_AGENT' | translate}}</label>

          <div class="filtered-agent-list" *ngIf="filterText.length > 2 && isDropdownOpened" #dropdownContainer>
            <div click-stop-propagation class="agent-name" (click)="selectFieldAgent(u.id)"
              *ngFor="let u of mobileUsersForSelect">
              {{ u.fullName }}
            </div>
          </div>
        </div>
        <button type="submit" class="btn btn-primary submit" (click)="submit()"
          [disabled]="filterText.length === 0 || survey.customers.length === 0">
          {{'SURVEYS.ASSIGN' | translate}}
        </button>
      </div>
    </div>
  </div>
</div>