import { Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';

@Component({
    selector: 'options-tooltip',
    templateUrl: './options-tooltip.component.html',
    styleUrls: ['./options-tooltip.component.scss']
})
export class OptionsTooltipComponent {
    isOpened: boolean;
    @Input() top: string = '0px';
    @Input() right: string = '30px';

    // Props used to close a tooltip when the user clicks somewhere outside
    @ViewChild('tooltipOptionsRef') tooltipOptionsRef!: ElementRef;
    @HostListener('document:mousedown', ['$event'])
    onOutsideClick(event: any): void {
        if (!this.tooltipOptionsRef?.nativeElement.contains(event.target) && this.isOpened) {
            this.isOpened = false;
        }
    }
}
