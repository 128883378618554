<div  class="modal fade" id="history" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" [ngClass]="isViewHistory ? 'view-history-modal-width' : ''"> 
      <div class="modal-content" *ngIf="!isViewHistory">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">{{'HISTORY.UPDATE_DESCRIPTION' | translate}}</h5>
          <button type="button" #closeUpdateHistoryModal class="btn-cancel-modal" data-bs-dismiss="modal">
            {{'COMMON.CANCEL' | translate}}
          </button>
        </div>
        <form id="historyAddForm" class="form-wrapper" ngNativeValidate (submit)="save()">
          <div class="modal-body">
              <div class="form-item form-floating mb-3">
                <input id="updateDesc" name="name" type="text" [(ngModel)]="history.UpdateDescription" class="form-control" required placeholder=" "/>
                <label for="updateDesc">{{'HISTORY.UPDATE_DESCRIPTION' | translate}}</label>
              </div>
              <div class="form-item form-floating mb-3">
                <input id="orderUpload" name="number" type="text" [(ngModel)]="history.ServiceOrderUpload" class="form-control not-required" placeholder=" "/>
                <label for="orderUpload">{{'HISTORY.SERVICE_UPLOAD_ORDER' | translate}}</label>
              </div>            
          </div>
          <div class="modal-footer">
            <button class="btn btn-primary" form="historyAddForm">{{'COMMON.SAVE' | translate}}</button>
          </div>
        </form>
      </div>
      <div class="modal-content" *ngIf="isViewHistory">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">{{'HISTORY.UPDATE_HISTORY' | translate}}</h5>
          <button type="button" #closeUpdateHistoryModal class="btn-cancel-modal" data-bs-dismiss="modal">
            {{'COMMON.CANCEL' | translate}}
          </button>
        </div>
        <div class="container-fluid border-bottom">

        <table>
          <tr class="header-row">
              <th>{{'HISTORY.TIMESTAMP_DATE' | translate}}</th>
              <th>{{'HISTORY.OPERATOR' | translate}}</th>
              <th>{{'HISTORY.UPDATE_DESCRIPTION' | translate}}</th>
              <th>{{'HISTORY.SERVICE_ORDER_UPLOAD' | translate}}</th>
              <th>{{'HISTORY.PREV_PHONE_NUM' | translate}}</th>
              <th>{{'COMMON.PREV_METER_NUM' | translate}}</th>
              <th>{{'HISTORY.PREV_EMAIL' | translate}}</th>
              <th>{{'HISTORY.PREV_REGION' | translate}}</th>
              <th>{{'HISTORY.PREV_AREA' | translate}}</th>
          </tr>
          <tr class="content-wrapper" *ngFor="let item of historyItems">
              <td>{{item.date | date:'short' | hasValue}}</td>
              <td>{{item.username | hasValue}}</td>
              <td>{{item.updateDescription | hasValue}}</td>
              <td>{{item.serviceOrderUpload | hasValue}}</td>
              <td>{{item.phoneNumber | historiesHasValue}}</td>
              <td>{{item.meterNumber | historiesHasValue}}</td>
              <td>{{item.email | historiesHasValue}}</td>
              <td>{{item.area | historiesHasValue}}</td>
              <td>{{item.region | historiesHasValue}}</td>

          </tr>
      </table>
      </div>

    </div>
</div>
