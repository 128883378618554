import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UtilityBasicInfo } from 'src/app/models/utility.model';
import { AuthService } from 'src/app/services/auth.service';
import { AccountKycService } from 'src/app/services/kyc/account-kyc.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-login-kyc',
  templateUrl: './login-kyc.component.html',
  styleUrls: ['./login-kyc.component.scss']
})
export class LoginKycComponent implements OnInit {

  email: string = '';
  password: string = '';
  showPw: boolean = false;
  utility: UtilityBasicInfo = new UtilityBasicInfo();

  constructor(
    private accountService: AccountKycService,
    private toastr: ToastrService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private utilityService: UtilityService
  ) {}

  ngOnInit(): void {
    var utilityRoute = this.route.snapshot.paramMap.get('utility') || '';
    if (utilityRoute) {
      this.tryGetUtilityByName(utilityRoute.replace(new RegExp("-", "g"), " "));
    } else this.router.navigate(['']);
  }
  showPassword() {
    this.showPw = !this.showPw;
  }
  login() {
    if (this.email == '') {
      this.toastr.warning('Please enter your Email.');
      return;
    }
    if (this.password == '') {
      this.toastr.warning('Please enter your Password.');
      return;
    }
    let obj = {
      email: this.email,
      password: this.password,
      utilityId: this.utility.id,
    };

    this.accountService.login(obj).subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK') {
          localStorage.setItem('token', responseData.data.token);
          const account: any = this.authService.decodeJWTToken();
          localStorage.setItem(
            'utilityId',
            account?.utilityId ? account?.utilityId : 0
          );
          localStorage.setItem(
            'translation',
            JSON.stringify(responseData.data.translation)
          );
          console.log(responseData);
          localStorage.setItem('utilityName', account.utilityName)
          localStorage.setItem('isAdmin' , '0');
          this.router.navigate(['customers-app']);
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  tryGetUtilityByName(name: string) {
    this.utilityService.getByName(name).subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK' && responseData.data) {
          this.utility = responseData.data;
          localStorage.setItem('currency' ,this.utility.currency );
        } else {
          this.router.navigate(['']);
        }
      },
      error: (_) => {
        this.router.navigate(['']);
      },
    });
  }

}
