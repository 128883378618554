<div class="container-fluid customers-root">
  <div class="stats-root">
    <div class="stats-item">
      <div>
        {{ "COMMON.TOTAL" | translate }}
        {{ "COMMON.MBC_DETAILS.DECOMMISSIONED_METERS" | translate }}
      </div>
      <div *ngIf="stats.total == undefined" class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div *ngIf="stats.total != undefined" class="count-number">
        {{ stats.total | number }}
      </div>
    </div>
    <div class="stats-item">
      <div>
        {{ "COMMON.TOTAL" | translate }}
        {{ "COMMON.MBC_DETAILS.FAULTY_METERS" | translate }}
      </div>
      <div *ngIf="stats.faulty == undefined" class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div *ngIf="stats.faulty != undefined" class="count-number">
        {{ stats.faulty | number }}
      </div>
    </div>
    <div class="stats-item">
      <div>
        {{ "COMMON.TOTAL" | translate }}
        {{ "COMMON.MBC_DETAILS.BURNT_METERS" | translate }}
      </div>
      <div *ngIf="stats.burnt == undefined" class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div *ngIf="stats.burnt != undefined" class="count-number">
        {{ stats.burnt | number }}
      </div>
    </div>
    <div class="stats-item">
      <div>
        {{ "COMMON.TOTAL" | translate }}
        {{ "COMMON.MBC_DETAILS.UPGRADE" | translate }}
      </div>
      <div *ngIf="stats.upgrade == undefined" class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div *ngIf="stats.upgrade != undefined" class="count-number">
        {{ stats.upgrade | number }}
      </div>
    </div>
  </div>

  <div class="table-root">
    <div
      class="table-action-wrapper d-flex align-items-center justify-content-between"
    >
      <div class="d-flex searches">
        <div class="search-input">
          <img
            src="../../assets/icons/search.svg"
            alt="Search icon"
            (click)="search()"
          />
          <input
            type="text"
            [placeholder]="'COMMON.SEARCH' | translate"
            [(ngModel)]="searchText"
            (keydown.enter)="search()"
            (ngModelChange)="this.searchTextUpdate.next($event)"
          />
        </div>
        <div class="search-type-picker">
          <div [title]="selectedSearchTypeDisplay | translate"
            class="dropdown"
            (click)="showSelectSearchType = !showSelectSearchType"
            click-stop-propagation
          >
            <span>{{'COMMON.BY' | translate}}:</span>
            <span>{{ selectedSearchTypeDisplay | translate }}</span>
            <span class="arrow"
              ><img
                src="../../assets/icons/gray-arrow-down.svg"
                alt="Gray arrow down"
            /></span>
          </div>
          <div class="dropdown-opened" *ngIf="showSelectSearchType">
            <div
              (click)="selectSearchType(searchType)"
              *ngFor="let searchType of searchByOptions"
            >
              <div>{{ searchType.translation | translate }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="ml-auto d-flex">
        <div>
          <div class="d-flex">
            <div class="datetype-picker">
              <div [title]="selectedDateTypeDisplay | translate"
                class="dropdown date-type-dropdown"
                (click)="showSelectDateType = !showSelectDateType"
                click-stop-propagation
              >
                <span>{{ "COMMON.DATE_TYPE.TITLE" | translate }}:</span>
                <span>{{ selectedDateTypeDisplay | translate }}</span>
                <span class="arrow"
                  ><img
                    src="../../assets/icons/gray-arrow-down.svg"
                    alt="Gray arrow down"
                /></span>
              </div>
              <div class="dropdown-opened" *ngIf="showSelectDateType">
                <div
                  (click)="
                    selectDateType(dateType);
                    datesValid() == true && (dateTo || dateFrom) && search()
                  "
                  *ngFor="let dateType of dateTypes"
                >
                  <div>{{ dateType.translation | translate }}</div>
                </div>
              </div>
            </div>
            <div class="datepicker-container" style="width: 24rem;">
              <label> {{ "COMMON.FROM" | translate }}: </label>
              <input
                type="date"
                id="dateFrom"
                name="dateFrom"
                [ngClass]="
                  !datesValid()
                    ? 'btn datepicker-icon-error'
                    : 'btn datepicker-icon'
                "
                [(ngModel)]="dateFrom"
                (change)="datesValid() == true && search()"
              />
            </div>

            <div class="datepicker-container">
              <label class="right-label">
                {{ "COMMON.TO" | translate }}:
              </label>
              <input
                type="date"
                id="dateTo"
                name="dateTo"
                [ngClass]="
                  !datesValid()
                    ? 'btn datepicker-icon-error'
                    : 'btn datepicker-icon'
                "
                [(ngModel)]="dateTo"
                (change)="datesValid() == true && search()"
              />
            </div>
          </div>
        </div>
        <button class="btn btn-icon" (click)="download()">
          <span>{{ "COMMON.DOWNLOAD" | translate }}</span>
        </button>
      </div>
    </div>
    <div class="filter">
      <div class="filter-list">
        {{ "COMMON.FILTER" | translate }}
        <app-multi-select-dropdown
          class="multiselect"
          [clicked]="clicked"
          [items]="regions"
          [label]="'COMMON.BU.PLURAL'"
          [showMore]="false"
          [requireApply]="true"
          (shareIndividualCheckedList)="selectRegion($event)"
        >
        </app-multi-select-dropdown>
        <app-multi-select-dropdown
          class="multiselect"
          [clicked]="clicked"
          [items]="areas"
          [label]="'COMMON.UT.PLURAL'"
          [showMore]="false"
          [requireApply]="true"
          (shareIndividualCheckedList)="selectArea($event)"
        >
        </app-multi-select-dropdown>

        <select
          name="ct-select"
          (change)="selectMeterType($event)"
          class="form-select"
        >
          <option value="" [selected]="!meterType" disabled>
            {{ "COMMON.MBC_DETAILS.METER_TYPE.SINGLE" | translate }}
          </option>
          <option
            [selected]="meterType == o.value"
            *ngFor="let o of meterTypeOptions"
            value="{{ o.value }}"
          >
            {{ o.translation | translate }}
          </option>
        </select>
        <select
          name="ct-select"
          (change)="selectMeterPhase($event)"
          class="form-select"
        >
          <option value="" [selected]="!meterPhase" disabled>
            {{ "COMMON.MBC_DETAILS.METER_PHASE" | translate }}
          </option>
          <option
            [selected]="meterPhase == o.value"
            *ngFor="let o of meterPhaseOptions"
            value="{{ o.value }}"
          >
            {{ o.translation | translate }}
          </option>
        </select>
        <select
          name="ct-select"
          (change)="selectDecommissionedStatus($event)"
          class="form-select"
        >
          <option value="" [selected]="!decommissionedStatus" disabled>
            {{ "COMMON.MBC_DETAILS.DECOMMISSIONED_STATUS" | translate }}
          </option>
          <option
            [selected]="decommissionedStatus == o.value"
            *ngFor="let o of decommissionedStatusOptions"
            value="{{ o.value }}"
          >
            {{ o.translation | translate }}
          </option>
        </select>
        <select
          name="ct-select"
          (change)="selectStockItemStatus($event)"
          class="form-select"
        >
          <option value="" [selected]="!stockItemStatus" disabled>
            {{ "COMMON.MBC_DETAILS.STOCK_ITEM_STATUS" | translate }}
          </option>
          <option
            [selected]="stockItemStatus == o.value"
            *ngFor="let o of stockItemStatusOptions"
            value="{{ o.value }}"
          >
            {{ o.translation | translate }}
          </option>
        </select>
      </div>
      <div *ngIf="selectedFilters.length > 0">
        <app-filter-checklist
          [filters]="selectedFilters"
          [useApply]="true"
          (resetFilterEmitter)="resetFilter($event)"
          (removeFilterEmitter)="removeFilter($event)"
          (applyFilterEmitter)="applyFilter($event)"
        >
        </app-filter-checklist>
      </div>
    </div>
    <div class="table mt-4">
      <div *ngIf="decommissionings">
        <!-- Add property later -->
        <table class="table border-bottom">
          <thead class="table-light">
            <tr>
              <th>
                <input
                  type="checkbox"
                  class="form-check-input"
                  [ngModel]="allCheckboxesChecked"
                  (click)="checkAllCheckboxes()"
                />
              </th>
              <th>{{ "COMMON.MBC_DETAILS.METER_NO" | translate }}</th>
              <th>{{ "COMMON.MBC_DETAILS.METER_PHASE" | translate }}</th>
              <th>{{ "COMMON.MBC_DETAILS.METER_MAKE" | translate }}</th>
              <th>
                {{ "COMMON.MBC_DETAILS.METER_TYPE.SINGLE" | translate }}
              </th>
              <th>{{ "COMMON.CUSTOMER.ACCOUNT_NUMBER" | translate }}</th>
              <th>{{ "COMMON.ADDRESS" | translate }}</th>
              <th>{{ "COMMON.BU.SINGLE" | translate }}</th>
              <th>{{ "COMMON.UT.SINGLE" | translate }}</th>
              <th>
                {{ "COMMON.MBC_DETAILS.DECOMMISSIONED_DATE" | translate }}
              </th>
              <th>
                {{
                  "COMMON.MBC_DETAILS.REASON_FOR_DECOMMISSIONING" | translate
                }}
              </th>
              <th>
                {{ "COMMON.MBC_DETAILS.DECOMMISSIONED_STATUS" | translate }}
              </th>
              <th>{{ "COMMON.MBC_DETAILS.STOCK_ITEM_STATUS" | translate }}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let d of decommissionings; let i = index">
              <td>
                <input
                  type="checkbox"
                  class="form-check-input"
                  [ngModel]="d.isChecked"
                  (click)="checkDecommissioning(d.id)"
                />
              </td>
              <td (click)="setFilterStorage()" [routerLink]="['details', d.id]">
                {{ d.meterNumber | hasValue }}
              </td>
              <td (click)="setFilterStorage()" [routerLink]="['details', d.id]">
                {{ d.meterPhase | hasValue }}
              </td>
              <td (click)="setFilterStorage()" [routerLink]="['details', d.id]">
                {{ d.meterMake | hasValue }}
              </td>
              <td (click)="setFilterStorage()" [routerLink]="['details', d.id]">
                {{ d.meterType | hasValue }}
              </td>
              <td (click)="setFilterStorage()" [routerLink]="['details', d.id]">
                {{ d.accountNumber | hasValue }}
              </td>
              <td (click)="setFilterStorage()" [routerLink]="['details', d.id]">
                {{ d.address | hasValue }}
              </td>
              <td (click)="setFilterStorage()" [routerLink]="['details', d.id]">
                {{ d.region | hasValue }}
              </td>
              <td (click)="setFilterStorage()" [routerLink]="['details', d.id]">
                {{ d.area | hasValue }}
              </td>
              <td (click)="setFilterStorage()" [routerLink]="['details', d.id]">
                {{ d.decommissionDate | date : "dd/MM/yyyy" | hasValue }}
              </td>
              <td (click)="setFilterStorage()" [routerLink]="['details', d.id]">
                {{
                  ReasonForDecommission[d.reasonForDecommissioning | hasValue]
                    | hasValue
                }}
              </td>
              <td (click)="setFilterStorage()" [routerLink]="['details', d.id]">
                <span
                  class="meter-status"
                  [ngClass]="{
                    'pending-meter':
                      d.decommissioningStatus?.toString() ==
                        DecommissioningStatus[DecommissioningStatus.Pending] ||
                      d.decommissioningStatus?.toString() ==
                        DecommissioningStatus[DecommissioningStatus.Submitted],
                    'complete-meter':
                      d.decommissioningStatus?.toString() ==
                      DecommissioningStatus[DecommissioningStatus.Approved],
                    'assigned-meter':
                      d.decommissioningStatus?.toString() ==
                      DecommissioningStatus[DecommissioningStatus.Assigned]
                  }"
                >
                  {{ d.decommissioningStatus | hasValue }}
                </span>
              </td>
              <td (click)="setFilterStorage()" [routerLink]="['details', d.id]">
                <span
                  class="meter-status"
                  [ngClass]="{
                    'pending-meter':
                      d.stockItemStatus?.toString() ==
                      DecommissioningStockItemStatus[
                        DecommissioningStockItemStatus.AwaitingRecycling
                      ],
                    'complete-meter':
                      d.stockItemStatus?.toString() ==
                      DecommissioningStockItemStatus[
                        DecommissioningStockItemStatus.InStorage
                      ],
                    'assigned-meter':
                      d.stockItemStatus?.toString() ==
                      DecommissioningStockItemStatus[
                        DecommissioningStockItemStatus.Recycled
                      ]
                  }"
                >
                  {{ d.stockItemStatus | stockItemValue | hasValue }}
                </span>
              </td>
              <td class="more-icon">
                <img
                  src="../../assets/icons/more-icon.svg"
                  alt="More icon"
                  class="more-icon"
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div>
          <app-paging
            *ngIf="count > 0"
            [pageSize]="pageSize"
            [count]="count"
            [currentPage]="currentPage"
            (pageChange)="pageChange($event)"
          ></app-paging>
        </div>
      </div>
    </div>
  </div>
</div>
