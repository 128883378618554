import { CustomerType, TypeOfConnection } from "./customer.model";

export class NewMeterStockItemDto {
    meterNumber?: string = '';
    lifespan?: number;
    meterPhase?: TypeOfConnection;
    meterMakeId?: number = 0;
    meterType?: number;
    dispatchDate?: string;
    regionId?: number = 0;
}