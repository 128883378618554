import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponsePackage } from '../models/util.model';
import { AppConfig } from '../config/config';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DecommissioningService {
  private pathApi = this.config.setting['pathApi'] + 'decommissioning/';
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(private http: HttpClient, private config: AppConfig) {}

  assignForDecommissioning(customerId: any, assignedUserId: any) {
    return this.http.post<any>(
      `${this.pathApi}assignForDecommissioning`,
      { CustomerId: customerId, AssignedUserId: assignedUserId },
      { headers: this.headers, observe: 'response' }
    );
  }

  getDecommissionedStats(obj: any) {
    return this.http.post<any>(`${this.pathApi}getDecommissioningStats`, obj, {
      headers: this.headers,
      observe: 'response',
    });
  }

  getAll(obj: any) {
    return this.http.post<any>(`${this.pathApi}getAll`, obj, {
      headers: this.headers,
      observe: 'response',
    });
  }

  download(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}downloadFile`, obj, {
      headers: this.headers,
      observe: 'response',
    });
  }

  getDetailsById(id: number) {
    return this.http.get<ResponsePackage<any>>(`${this.pathApi}details/${id}`, {
      headers: this.headers,
      observe: 'response',
    });
  }

  approve(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}approve`, obj);
  }

  saveDetails(obj: any): Observable<any> {
    return this.http.post(`${this.pathApi}saveDetails`, obj);
  }
}
