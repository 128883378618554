import { Injectable } from '@angular/core';
import { PaginationDto, ResponsePackage } from '../models/util.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../config/config';
import { Observable } from 'rxjs';
import { InstallationTableData } from '../models/installation.model';
import { KeyValueItem } from '../models/keyValueItem.model';

@Injectable({
  providedIn: 'root'
})
export class InstallationService {
  private pathApi = this.config.setting['pathApi'] + "installation/";
  private headers = new HttpHeaders({'Content-Type': 'application/json'});
  constructor(private http: HttpClient, private config: AppConfig) { }
  getAll(obj: any) {
    return this.http.post<ResponsePackage<PaginationDto<InstallationTableData>>>(`${this.pathApi}getAll`, obj, {headers: this.headers, observe: 'response'});
  }
  getInstallationStats() {
    return this.http.get<ResponsePackage<KeyValueItem[]>>(`${this.pathApi}getInstallationStats`,{headers: this.headers, observe: 'response'});
  }
  assignInstalations(obj: any) {
    return this.http.post<ResponsePackage<string>>(`${this.pathApi}assignInstallations`, obj, {headers: this.headers, observe: 'response'});
  }
  download(obj:any){
    return this.http.post<ResponsePackage<string>>(`${this.pathApi}downloadFile`, obj, {headers: this.headers, observe: 'response'});
  }
  getDetailsById(id:number){
    return this.http.get<ResponsePackage<any>>(`${this.pathApi}details/${id}`,{headers: this.headers, observe: 'response'});
  }
  approveInstallation(obj:any):Observable<any>{
    return this.http.post(`${this.pathApi}approveInstallation`, obj);
  }
  rejectInstallation(obj:any):Observable<any>{
    return this.http.post(`${this.pathApi}rejectInstallation`, obj);
  }
}
