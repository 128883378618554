import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BuildingSave } from 'src/app/models/building.model';
import { User } from 'src/app/models/user.model';
import { BuildingService } from 'src/app/services/building.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-add-building',
  templateUrl: './add-building.component.html',
  styleUrls: ['./add-building.component.scss']
})
export class AddBuildingComponent implements OnInit {

  building: BuildingSave = new BuildingSave();
  mobileUsers: User[] = [];

  constructor(private userService: UserService, private toastr: ToastrService, private buildingService: BuildingService) { }

  ngOnInit(): void {
    this.getMobileUsers();
  }

  handleFormSubmit() {
    this.buildingService.addOrUpdateBuilding(this.building).subscribe({
      next: response => {
        const responseData = response.body;
        if(responseData?.status === 200 || responseData?.status === "OK"){
          this.toastr.success("Successfully added building!");
          this.building = new BuildingSave();
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: _ => {
          this.toastr.error("Error occured");
        }
    });
  }

  selectOption(e: any){
    this.building.assignedUserId = +e.target.value;
  }

  getMobileUsers(){
    this.userService.getMobileUsers(0).subscribe({
      next: response => {
        const responseData = response.body;
        if(responseData?.status === 200 || responseData?.status === "OK"){
          this.mobileUsers = responseData.data;
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: _ => {
          this.toastr.error("Error occured");
        }
    });
  }

}
