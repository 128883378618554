import { Component, Input, OnInit } from '@angular/core';
import { AssetData } from 'src/app/models/assetData.model';
import { User } from 'src/app/models/user.model';

@Component({
  selector: 'analytics-overview',
  templateUrl: './analytics-overview.component.html',
  styleUrls: ['./analytics-overview.component.scss']
})
export class AnalyticsOverviewComponent implements OnInit {

  @Input() polesData: AssetData = new AssetData();
  @Input() customersData: AssetData = new AssetData();
  @Input() buildingsData:  AssetData = new AssetData();
  @Input() transformersData:  AssetData = new AssetData();
  
  constructor() {
    
  }

  ngOnInit(): void {
  }

}
