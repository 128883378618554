import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import jwt_decode from 'jwt-decode';
import { fromEvent, Observable, Subject, takeUntil } from 'rxjs';
import { Language } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  pageTitle: string = '';
  pageBreadcrumb: string = '';
  loggedUser: string = '--';
  logoUrl: string = '';
  goBackTo : string = '';
  selectedLanguage: string;
  showLanguageDropdown = false;
  clickObservable: Observable<Event> = fromEvent(document, 'click');
  private ngUnsubscribe = new Subject<void>();
  
  constructor(private router: Router, private location: Location, private translate: TranslateService, private authService: AuthService) {
    this.router.events.subscribe((event) => {
      if (
        event instanceof ActivationEnd &&
        event.snapshot.children.length === 0
      ) {
        this.pageTitle = event.snapshot.data['headerTitle'];
        this.pageBreadcrumb = event.snapshot.data['headerBreadcrumb'];
        this.goBackTo =  event.snapshot.data['goBackTo'];
      }
    });
  }

  ngOnInit(): void {
    let user: any = jwt_decode(localStorage.getItem('token') ?? '');
    this.logoUrl = localStorage.getItem('utilityId') === '0' ? 'https://caimsmsstorage.blob.core.windows.net/images/aeaf3e50-a6d3-41a7-a3b7-00f4710d51ac.png' : localStorage.getItem('logoUrl') ?? '';
    if (user != undefined) {
      var firstNameLetter = user['name'].split(' ')[0];
      var lastNameLetter = user['name'].split(' ')[1];
      this.loggedUser = firstNameLetter[0]
        ? firstNameLetter[0]
        : '' + lastNameLetter[0]
        ? lastNameLetter[0]
        : '';
    }
    this.selectedLanguage = localStorage.getItem('preferredLanguage') ?? 'en';
    this.subscribeToClickEvent();
  }

  goBack() {
    if (this.goBackTo && this.goBackTo !== '') {
      this.router.navigate([this.goBackTo]);
    }
  }

  changeLanguage(language: Language) {
    this.authService.changePreferredLanguage(language).subscribe();
    const lang = language == Language.English ? 'en' : 'fr';
    this.selectedLanguage = lang;
    this.translate.use(lang);
    localStorage.setItem('preferredLanguage', lang)
    this.showLanguageDropdown = false;
  }

  private subscribeToClickEvent() {
    this.clickObservable
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((e: any) => {
        this.showLanguageDropdown = false;
      });
  }
}
