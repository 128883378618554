<div class="add-customer-root">
    <div class="ac-header">
        <button (click)="save()" class="btn btn-primary"
            [ngClass]="saveDisabled ? 'btn btn-disabled' : '' ">Save</button>
        <button class="btn btn-cancel" (click)="cancelClicked()">Cancel</button>
        <button *ngIf="billingAccount.id" (click)="getMobileUsers()" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#assignToFieldAgent">Assign Meter Reading</button>
    </div>
    <div class="ac-body section-line"
        [ngClass]="activeCard === 4 ? 'section-line-last-active' : activeCard === 5 ? 'section-line-finished' : ''">
        <form ngNativeValidate id="card1Form" (submit)="continue()" class="card-1"
            [ngClass]="activeCard > 1 ? 'item-line' : ''">
            <div class="card-header">
                <div class="num" [ngClass]="(activeCard > 1) ? 'card-number-done' : ''">1</div>
                <div>Account Information</div>
                <div *ngIf="activeCard > 1" (click)="setActiveCard(1)">Edit</div>
            </div>
            <div *ngIf="activeCard === 1">
                <div class="container card form-wrapper">
                    <div class="row">
                        <div class="col-6 form-item form-floating mb-3">
                            <input [(ngModel)]="billingAccount.accountNumber" name="accountNumber" type="text"
                                class="form-control" id="floatingInput2" placeholder=" " required>
                            <label for="floatingInput2">Account number</label>
                        </div>
                        <div class="col-6 form-item form-floating mb-3">
                            <input [(ngModel)]="billingAccount.contractNumber" name="spn" type="text"
                                class="form-control non-required-field" id="floatingInput13" placeholder=" "
                                pattern="[0-9]+">
                            <label for="floatingInput13">{{contractNumber}}</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 form-item form-floating mb-3">
                            <select [ngClass]="tariff !== '' ? 'selected-option' : ''" name="tariff"
                                (change)="selectTariff($event)" required class="form-select">
                                <option value="" [selected]="tariff === ''" disabled>Tariff</option>
                                <option *ngFor="let o of tariffs" [selected]="tariff === o.name" value="{{o.id}}">
                                    {{o.name}}
                                </option>
                            </select>
                            <label *ngIf="tariff !== ''" class="label-for-select" for="floatingSelect">Tariff</label>
                            <div *ngIf="tariff !== ''" class="select-title"></div>
                        </div>
                        <div class="col-6 form-item form-floating mb-3">
                            <select [ngClass]="selectedSltStatus.key !== 0 ? 'selected-option' : ''" name="sltStatus"
                                id="title" #title="ngModel" [(ngModel)]="selectedSltStatus" class="form-select" [compareWith]="compareByKey">
                                <option [selected]="selectedSltStatus.key === 0" disabled [value]="title"> {{sltStatus}}
                                </option>
                                <option *ngFor="let option of sltOptions" [ngValue]="option" [selected]="selectedSltStatus.key === option.key">
                                    {{option.value}}
                                </option>
                            </select>
                            <label *ngIf="selectedSltStatus.key !== 0" class="label-for-select"
                                for="floatingSelect">{{sltStatus}}</label>
                            <div *ngIf="selectedSltStatus.key !== 0" class="select-title"></div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 form-item form-floating mb-3">
                            <select [ngClass]="accountStatus !== '' ? 'selected-option' : ''" name="accountStatus"
                                (change)="selectAccountStatus($event)" required class="form-select">
                                <option value="" [selected]="accountStatus === ''" disabled>Account Status</option>
                                <option *ngFor="let o of accountStatusOptions" [selected]="accountStatus === o.name"
                                    value="{{o.value}}">
                                    {{o.name}}
                                </option>
                            </select>
                            <label *ngIf="accountStatus !== ''" class="label-for-select" for="floatingSelect">Account Status</label>
                            <div *ngIf="accountStatus !== ''" class="select-title"></div>
                        </div>
                        <div class="col-6 form-item form-floating mb-3">
                            <input [(ngModel)]="billingAccount.address" name="homeAddress" type="text" class="form-control"
                                id="floatingInput9" placeholder=" " required>
                            <label for="floatingInput9">Address</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 form-item form-floating mb-3">
                            <select [ngClass]="region !== '' ? 'selected-option valid' : ''" name="region"
                                (change)="selectRegion($event)" required class="form-select">
                                <option value="" [selected]="region === ''" disabled>{{buName}}</option>
                                <option *ngFor="let o of regions" [selected]="region === o.name" value="{{o.id}}">
                                    {{o.name}}
                                </option>
                            </select>
                            <label *ngIf="region !== ''" class="label-for-select"
                                for="floatingSelect">{{buName}}</label>
                            <div *ngIf="region !== ''" class="select-title"></div>
                        </div>
                        <div class="col-6 form-item form-floating mb-3">
                            <select [ngClass]="area !== '' ? 'selected-option' : ''" name="area"
                                (change)="selectArea($event)" required class="form-select">
                                <option value="" [selected]="area === ''" disabled>{{utName}}</option>
                                <option *ngFor="let o of selectableAreas" [selected]="area === o.name" value="{{o.id}}">
                                    {{o.name}}
                                </option>
                            </select>
                            <label *ngIf="area !== ''" class="label-for-select" for="floatingSelect">{{utName}}</label>
                            <div *ngIf="area !== ''" class="select-title"></div>
                        </div>
                    </div>
                </div>
                <div class="btn-continue-container">
                    <button class="btn btn-continue" form="card1Form">Continue</button>
                </div>
            </div>
            <div *ngIf="activeCard > 1" class="filled-card">
                <div class="container card">
                    <div class="row">
                        <div class="col-6">Account Number: {{billingAccount.accountNumber}}</div>
                        <div class="col-6">{{contractNumber}}: {{billingAccount.contractNumber}}</div>
                    </div>
                    <div class="row">
                        <div class="col-12">Tariff Class: {{tariff}}</div>
                    </div>
                    <div class="row">
                        <div class="col-6">SLT Status: {{selectedSltStatus.value}}</div>
                        <div class="col-6">Account Status: {{accountStatus}}</div>
                    </div>
                    <div class="row">
                        <div class="col-12">Address: {{billingAccount.address}}</div>
                    </div>
                    <div class="row">
                        <div class="col-6">{{buName}}: {{region}}</div>
                        <div class="col-6">{{utName}}: {{area}}</div>
                    </div>
                </div>
            </div>
        </form>

        <form ngNativeValidate id="card2Form" (submit)="continue()" class="card-2 item"
            [ngClass]="activeCard > 2 ? 'item-line' : ''">
            <div class="card-header">
                <diV class="num"
                    [ngClass]="(activeCard < 2) ? 'card-number-gray' : (activeCard > 2) ? 'card-number-done' : '' ">2
                </diV>
                <div [ngClass]="(activeCard < 2) ? 'title-gray' : '' ">Meter Information</div>
                <div *ngIf="activeCard > 2" (click)="setActiveCard(2)">Edit</div>
            </div>
            <div *ngIf="activeCard === 2">
                <div class="container card form-wrapper">
                    <div class="col-12 form-item form-floating mb-3">
                        <input [(ngModel)]="billingAccount.meterNumber" name="meterNumber" type="text" class="form-control"
                            id="floatingInput2" placeholder=" " required>
                        <label for="floatingInput2">Meter number</label>
                    </div>
                    <div class="row">
                        <div class="col-6 form-item form-floating mb-3">
                            <input [(ngModel)]="billingAccount.meterCode" name="meterCode" type="text"
                                class="form-control non-required-field" id="floatingInput4" placeholder=" ">
                            <label for="floatingInput6">Meter Code</label>
                        </div>
                        <div class="col-6 form-item form-floating mb-3">
                            <input [(ngModel)]="billingAccount.meterDigit" name="meterDigit" type="number"
                                class="form-control non-required-field" id="floatingInput6" min="0" max="999999999">
                            <label for="floatingInput3">Meter Digit</label>
                        </div>
                    </div>
                </div>
                <div class="btn-continue-container">
                    <button class="btn btn-continue" form="card2Form">Continue</button>
                </div>
            </div>
            <div *ngIf="activeCard > 2" class="filled-card">
                <div class="container card">
                    <div class="row">
                        <div class="col-12">Meter number: {{billingAccount.meterNumber}}</div>
                    </div>
                    <div class="row">
                        <div class="col-6">Meter code: {{billingAccount.meterCode}}</div>
                        <div class="col-6">Meter Digit: {{billingAccount.meterDigit}}</div>
                    </div>
                </div>
            </div>
        </form>

        <form ngNativeValidate id="card3Form" (submit)="continue()" class="card-3 item"
            [ngClass]="activeCard > 3 ? 'item-line' : ''">
            <div class="card-header">
                <diV class="num"
                    [ngClass]="(activeCard < 3) ? 'card-number-gray' : (activeCard > 3) ? 'card-number-done' : '' ">3
                </diV>
                <div [ngClass]="(activeCard < 3) ? 'title-gray' : '' ">Geolocation Information</div>
                <div *ngIf="activeCard > 3" (click)="setActiveCard(3)">Edit</div>
            </div>
            <div *ngIf="activeCard === 3">
                <div class="container card form-wrapper">
                    <div class="col-12 form-item form-floating mb-3">
                        <input [(ngModel)]="billingAccount.geoCode" name="geoCode" type="text" class="form-control"
                            id="floatingInput2" placeholder="" required (change)="geoCodeChanged()" >
                        <label for="floatingInput2">Geocode</label>
                    </div>
                    <div class="row">
                        <div class="col-6 form-item form-floating mb-3">
                            <input [(ngModel)]="billingAccount.itinerary" name="itinerary" type="text" class="form-control"
                                id="floatingInput2" placeholder="" required disabled>
                            <label for="floatingInput2">Itinerary</label>
                        </div>
                        <div class="col-6 form-item form-floating mb-3">
                            <input [(ngModel)]="billingAccount.round" name="round" type="text" class="form-control"
                                id="floatingInput2" placeholder="" required disabled>
                            <label for="floatingInput2">Round</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 form-item form-floating mb-3">
                            <input [(ngModel)]="billingAccount.block" name="block" type="text" class="form-control"
                                id="floatingInput2" placeholder="" required disabled>
                            <label for="floatingInput2">Block</label>
                        </div>
                        <div class="col-6 form-item form-floating mb-3">
                            <input [(ngModel)]="billingAccount.plot" name="plot" type="text" class="form-control"
                                id="floatingInput2" placeholder="" required disabled>
                            <label for="floatingInput2">Plot</label>
                        </div>
                    </div>
                    
                    <div class="row">
                  
                        <div  class="col-6 form-item form-floating mb-3">
                            <input [(ngModel)]="billingAccount.latitude" name="latitude1" type="number" step="any"
                                class="form-control non-required-field" id="floatingInput4" >
                            <label for="floatingInput6">Latitude</label>
                        </div>
                        <div  class="col-6 form-item form-floating mb-3">
                            <input [(ngModel)]="billingAccount.longitude" name="longitude1" type="number" step="any"
                                class="form-control non-required-field" id="floatingInput4" >
                            <label for="floatingInput6">Longitude</label>
                        </div>
                    </div>
                </div>
                <div class="btn-continue-container">
                    <button class="btn btn-continue" form="card3Form">Continue</button>
                </div>
            </div>
            <div *ngIf="activeCard > 3" class="filled-card">
                <div class="container card">
                    <div class="row">
                        <div class="col-12">Geocode: {{billingAccount.geoCode}}</div>
                    </div>
                    <div class="row">
                        <div class="col-6">Itinerary: {{billingAccount.itinerary}}</div>
                        <div class="col-6">Round: {{billingAccount.round}}</div>
                    </div>
                    <div class="row">
                        <div class="col-6">Block: {{billingAccount.block}}</div>
                        <div class="col-6">Plot: {{billingAccount.plot}}</div>
                    </div>
                    <div class="row">
                        <div class="col-6">Latitude: {{billingAccount.latitude}}</div>
                        <div class="col-6">Longitude: {{billingAccount.longitude}}</div>
                    </div>
                </div>
            </div>
        </form>

        <form ngNativeValidate id="card4Form" (submit)="continue()" class="card-4 item">
            <div class="card-header">
                <diV class="num"
                    [ngClass]="(activeCard < 3) ? 'card-number-gray' : (activeCard > 3) ? 'card-number-done' : '' ">4
                </diV>
                <div [ngClass]="(activeCard < 3) ? 'title-gray' : '' ">Reading Set</div>
            </div>
            <div *ngIf="activeCard === 4">
                <div class="container card form-wrapper">
                    <div class="row d-flex align-items-center">
                        <div class="col-4 form-item form-floating mb-3">
                            <input [(ngModel)]="billingAccount.readingFrequency" name="meterNumber" type="number" class="form-control"
                                id="floatingInput2" required min="0" max="999">
                            <label for="floatingInput2">Reading Frequency</label>
                        </div>
                        Months
                    </div>
                </div>
                <div class="btn-continue-container">
                    <button class="btn btn-continue" form="card4Form">Continue</button>
                </div>
            </div>
            <div *ngIf="activeCard > 4" class="filled-card">
                <div class="container card">
                    <div class="row">
                        <div class="col-12">Reading Frequency: {{billingAccount.readingFrequency}}</div>
                    </div>
                </div>
            </div>
        </form>

    </div>
    <div class="successfull-toast" *ngIf="successfullyAdded">
        <div class="img-container"><img src="../../../../assets/icons/checked_green.svg" alt="Checked icon"/></div>
        <div class="text-container">
            <div>Added</div>
            <div>New billing account has been added</div>
        </div>
        <div class="x-container"><img src="../../../../assets/icons/close.svg" alt="Close icon"/></div>
    </div>
</div>

<div class="modal fade" id="assignToFieldAgent" tabindex="-1" aria-hidden="true"
  (hidden.bs.modal)="onReadingModalFade()">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Assign Meter Reading</h5>
        <button type="button" #closeModal class="btn-cancel-modal" data-bs-dismiss="modal" aria-label="Close">
          Cancel
        </button>
      </div>
      <div class="modal-body">
        <div class="form-floating form-item">
          <input #userFilterInput autocomplete="off" type="text" name="filterText" [(ngModel)]="filterText"
            class="form-control filter-field" [ngClass]="{ 'valid-input': tempAssignedUserId != 0 }" id="floatingInput"
            (ngModelChange)="filterMobileUsers()" (click)="openDropdown()" placeholder=" " />
          <label for="floatingInput">Field agent</label>

          <div class="filtered-agent-list" *ngIf="isDropdownOpened" #dropdownContainer>
            <div click-stop-propagation class="agent-name" (click)="selectFieldAgent(u.id)"
              *ngFor="let u of mobileUsers">
              {{ u.fullName }}
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="assignReading()">
          <span>Assign Reading</span>
        </button>
      </div>
    </div>
  </div>
</div>