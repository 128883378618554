<div class="container-fluid customers-root">
  <div class="table-root">
    <div
      class="table-action-wrapper d-flex align-items-center justify-content-between"
    >
      <div class="d-flex">
        <div class="search-input">
          <img
            src="../../assets/icons/search.svg"
            alt="Search icon"
            (click)="search()"
          />
          <input
            type="text"
            [placeholder]="'COMMON.SEARCH' | translate"
            [(ngModel)]="searchText"
            (keydown.enter)="search()"
            (ngModelChange)="this.searchTextUpdate.next($event)"
          />
        </div>
        <div class="search-type-picker">
          <div [title]="selectedSearchTypeDisplay | translate"
            class="dropdown"
            (click)="showSelectSearchType = !showSelectSearchType"
            click-stop-propagation
          >
            <span>{{ "COMMON.BY" | translate }}:</span>
            <span>{{ selectedSearchTypeDisplay | translate }}</span>
            <span class="arrow"
              ><img
                src="../../assets/icons/gray-arrow-down.svg"
                alt="Gray arrow down"
            /></span>
          </div>
          <div class="dropdown-opened" *ngIf="showSelectSearchType">
            <div
              (click)="selectSearchType(searchType)"
              *ngFor="let searchType of searchByOptions"
            >
              <div>{{ searchType.translation | translate }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="ml-auto d-flex">
        <div>
          <div class="d-flex">
            <div class="datetype-picker">
              <div [title]="selectedDateTypeDisplay | translate"
                class="dropdown"
                (click)="showSelectDateType = !showSelectDateType"
                click-stop-propagation
              >
                <span>{{ "COMMON.DATE_TYPE.TITLE" | translate }}:</span>
                <span>{{ selectedDateTypeDisplay | translate}}</span>
                <span class="arrow"
                  ><img
                    src="../../assets/icons/gray-arrow-down.svg"
                    alt="Gray arrow down"
                /></span>
              </div>
              <div class="dropdown-opened" *ngIf="showSelectDateType">
                <div
                  (click)="
                    selectDateType(dateType);
                    datesValid() == true && (dateTo || dateFrom) && search()
                  "
                  *ngFor="let dateType of dateTypes"
                >
                  <div>{{ dateType.translation | translate }}</div>
                </div>
              </div>
            </div>
            <div class="datepicker-container" style="width: 23rem;">
              <label> {{ "COMMON.FROM" | translate }}: </label>
              <input
                type="date"
                id="dateFrom"
                name="dateFrom"
                [ngClass]="
                  !datesValid()
                    ? 'btn datepicker-icon-error'
                    : 'btn datepicker-icon'
                "
                [(ngModel)]="dateFrom"
                (change)="datesValid() == true && search()"
              />
            </div>

            <div class="datepicker-container" style="width: 20rem;">
              <label class="right-label">
                {{ "COMMON.TO" | translate }}:
              </label>
              <input
                type="date"
                id="dateTo"
                name="dateTo"
                [ngClass]="
                  !datesValid()
                    ? 'btn datepicker-icon-error'
                    : 'btn datepicker-icon'
                "
                [(ngModel)]="dateTo"
                (change)="datesValid() == true && search()"
              />
            </div>
          </div>
        </div>
        <div
          class="sort-by-root" style="width: 16.5rem;"
          (click)="showSortBy = !showSortBy"
          click-stop-propagation
        >
          <div class="dropdown" [title]="sortByLabelValue.label | translate">
            <span>{{ "COMMON.SORT_BY.TITLE" | translate }}:</span>
            <span style="white-space: wrap;"
              >{{ sortByLabelValue.label | translate
              }}<img
                *ngIf="sortByLabelValue.arrow !== 'no'"
                [ngClass]="{ 'arrow-up': sortByLabelValue.arrow === 'up' }"
                src="../../../assets/icons/arrow.svg"
            /></span>
            <span
              ><img
                src="../../assets/icons/gray-arrow-down.svg"
                alt="Gray arrow down"
            /></span>
          </div>
          <div
            class="dropdown-opened v-2" style="width: 130%;"
            *ngIf="showSortBy && filterByValue != filterByOptions.ONBOARDED"
          >
            <div (click)="sortBy(sortByOptions.NEWEST)">
              <div>{{ "COMMON.SORT_BY.NEWEST" | translate }}</div>
            </div>
            <div (click)="sortBy(sortByOptions.SLRN_ASC)">
              <div>{{ "COMMON.SLRN" | translate }}</div>
              <img
                class="arrow-up"
                src="../../../assets/icons/arrow.svg"
                alt="Arrow icon"
              />
            </div>
            <div (click)="sortBy(sortByOptions.SLRN_DESC)">
              <div>{{ "COMMON.SLRN" | translate }}</div>
              <img src="../../../assets/icons/arrow.svg" alt="Arrow icon" />
            </div>
            <div (click)="sortBy(sortByOptions.ACCNO_ASC)">
              <div>{{ "COMMON.SORT_BY.ACCOUNT_NO" | translate }}</div>
              <img
                class="arrow-up"
                src="../../../assets/icons/arrow.svg"
                alt="Arrow icon"
              />
            </div>
            <div (click)="sortBy(sortByOptions.ACCNO_DESC)">
              <div>{{ "COMMON.SORT_BY.ACCOUNT_NO" | translate }}</div>
              <img src="../../../assets/icons/arrow.svg" alt="Arrow icon" />
            </div>
          </div>
          <div
            class="dropdown-opened"
            *ngIf="showSortBy && filterByValue == filterByOptions.ONBOARDED"
          >
            <div (click)="sortBy(sortByOptions.NEWEST)">
              <div>{{ "COMMON.SORT_BY.NEWEST" | translate }}</div>
            </div>
            <div (click)="sortBy(sortByOptions.OLDEST)">
              <div>{{ "COMMON.SORT_BY.OLDEST" | translate }}</div>
            </div>
          </div>
        </div>
        <button class="btn btn-icon" (click)="download()">
          <span>{{ "COMMON.DOWNLOAD" | translate }}</span>
        </button>
        <div
          *ngIf="
            filterByValue === filterByOptions.NEW &&
            withAccNoSelected === 2 &&
            isRegularizationUploadVisible
          "
        >
          <button
            [routerLink]="'/upload/11/' + utilityId"
            class="btn btn-primary"
            click-stop-propagation
          >
            <div>
              <img
                style="margin-right: 0rem !important"
                src="../../assets/icons/upload-arrow.svg"
              />
            </div>
          </button>
        </div>
      </div>
    </div>
    <div class="filter">
      <div class="filter-list">
        {{ "COMMON.FILTER" | translate }}
        <app-multi-select-dropdown
          class="multiselect"
          [clicked]="clicked"
          [items]="regions"
          [label]="'COMMON.BU.PLURAL'"
          [showMore]="false"
          [requireApply]="true"
          (shareIndividualCheckedList)="selectRegion($event)"
        >
        </app-multi-select-dropdown>
        <app-multi-select-dropdown
          class="multiselect"
          [clicked]="clicked"
          [items]="areas"
          [label]="'COMMON.UT.PLURAL'"
          [showMore]="false"
          [requireApply]="true"
          (shareIndividualCheckedList)="selectArea($event)"
        >
        </app-multi-select-dropdown>

        <app-multi-select-dropdown
          class="multiselect"
          [searchFilterActive]="true"
          (filterValue)="applySearchFilter($event)"
          [clicked]="clicked"
          [items]="filteredUsers"
          [label]="'COMMON.USER.PLURAL'"
          [showMore]="false"
          [requireApply]="true"
          (shareIndividualCheckedList)="selectUsers($event)"
          (applyFilterEmitter)="applyUserFilter()"
        >
        </app-multi-select-dropdown>

        <select
          name="status-select"
          (change)="selectKycStatus($event)"
          class="form-select"
        >
          <option value="" [selected]="!kycStatus" disabled>
            {{ "NEW_SERVICE.NEW_CONN_STATUS" | translate }}
          </option>
          <option
            [selected]="kycStatus == s.value"
            *ngFor="let s of kycStatusOptions"
            value="{{ s.value }}"
          >
            {{ s.translation | translate }}
          </option>
        </select>

        <select
          name="tag-select"
          (change)="selectKycTag($event)"
          class="form-select"
        >
          <option value="" [selected]="!kycTag" disabled>
            {{ "COMMON.TAG.TITLE" | translate }}
          </option>
          <option
            [selected]="kycTag == t.value"
            *ngFor="let t of kycTagOptions"
            value="{{ t.value }}"
          >
            {{ t.translation | translate }}
          </option>
        </select>
      </div>

      <div *ngIf="selectedFilters.length > 0">
        <app-filter-checklist
          [filters]="selectedFilters"
          [useApply]="true"
          (resetFilterEmitter)="resetFilter($event)"
          (removeFilterEmitter)="removeFilter($event)"
          (applyFilterEmitter)="applyFilter($event)"
        >
        </app-filter-checklist>
      </div>
    </div>

    <div class="table">
      <div *ngIf="customers">
        <table class="table">
          <thead class="table-light">
            <tr>
              <th>
                <input
                  type="checkbox"
                  class="form-check-input"
                  [ngModel]="allCheckboxesChecked"
                  (click)="checkAllCheckboxes()"
                />
              </th>
              <th>{{ "COMMON.TABLE_HEADERS.APP_NUM" | translate }}</th>
              <th>{{ "COMMON.TABLE_HEADERS.BUILDING_OWNER" | translate }}</th>
              <th>{{ "COMMON.BU.SINGLE" | translate }}</th>
              <th>{{ "COMMON.UT.SINGLE" | translate }}</th>
              <th>{{ "COMMON.ADDRESS" | translate }}</th>
              <th>{{ "COMMON.PAYMENT_STATUS" | translate }}</th>
              <th>{{ "COMMON.TAG.TITLE" | translate }}</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let accountKyc of accountKycs; let i = index"
              (click)="selectServiceConnection(accountKyc.id)"
            >
              <td><input type="checkbox" class="form-check-input" /></td>
              <td>
                {{ accountKyc.applicationNumber | hasValue }}
              </td>
              <td>
                {{ accountKyc.buildingOwner | hasValue }}
              </td>
              <td>
                {{ accountKyc.region | hasValue }}
              </td>
              <td>
                {{ accountKyc.area | hasValue }}
              </td>
              <td>
                {{ accountKyc.address | hasValue }}
              </td>
              <td>
                <div *ngIf="!accountKyc.isPaid" class="gray-status v-2">
                  {{ "COMMON.KYC_STATUS_OPTIONS.PENDING" | translate }}
                </div>
                <div *ngIf="accountKyc.isPaid" class="green-status v-2">
                  {{ "COMMON.KYC_STATUS_OPTIONS.PAID" | translate }}
                </div>
              </td>
              <td>
                <div
                  class="building-status"
                  [ngClass]="{
                    'orange-status v-2': accountKyc.tag == 'Onboard',
                    'green-status v-2': accountKyc.tag == 'Existing'
                  }"
                >
                  {{ accountKyc.tag | hasValue }}
                </div>
              </td>
              <td class="td-svg">
                <!-- data-bs-toggle="modal" data-bs-target="#history" -->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  width="22"
                  height="22"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M504 255.531c.253 136.64-111.18 248.372-247.82 248.468-59.015.042-113.223-20.53-155.822-54.911-11.077-8.94-11.905-25.541-1.839-35.607l11.267-11.267c8.609-8.609 22.353-9.551 31.891-1.984C173.062 425.135 212.781 440 256 440c101.705 0 184-82.311 184-184 0-101.705-82.311-184-184-184-48.814 0-93.149 18.969-126.068 49.932l50.754 50.754c10.08 10.08 2.941 27.314-11.313 27.314H24c-8.837 0-16-7.163-16-16V38.627c0-14.254 17.234-21.393 27.314-11.314l49.372 49.372C129.209 34.136 189.552 8 256 8c136.81 0 247.747 110.78 248 247.531zm-180.912 78.784l9.823-12.63c8.138-10.463 6.253-25.542-4.21-33.679L288 256.349V152c0-13.255-10.745-24-24-24h-16c-13.255 0-24 10.745-24 24v135.651l65.409 50.874c10.463 8.137 25.541 6.253 33.679-4.21z"
                  />
                </svg>
              </td>
              <td class="more-icon">
                <img
                  src="../../assets/icons/more-icon.svg"
                  alt="More icon"
                  class="more-icon"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div>
      <app-paging
        *ngIf="count > 0"
        [pageSize]="pageSize"
        [useDirect]="true"
        [count]="count"
        [currentPage]="currentPage"
        (pageChange)="pageChange($event)"
      ></app-paging>
    </div>
  </div>
</div>
<button
  class="btn btn-primary"
  #openUpdateDescriptionModal
  data-bs-toggle="modal"
  data-bs-target="#history"
  hidden
></button>

<app-history
  [isViewHistory]="isViewHistory"
  [historyItems]="historyItems"
></app-history>
