<div class="dropdown multi-select-dropdown">
  <div class="label-top-left" *ngIf="checkedList.length > 0 && !showMore && !alternateLook">{{label | translate}}</div>
  <button class="btn button-common" [ngClass]="{'button-regular': !alternateLook, 'button-alternate': alternateLook}" type="button" data-bs-toggle="dropdown" aria-expanded="false" [disabled]="items.length == 0"
    [ngStyle]="{ 'border-radius': borderRadius }">
    <span class="value-text" *ngIf="checkedList.length <= 0">{{label | translate}}</span>
    <span class="value-text" *ngIf="checkedList.length > 0 && showMore">{{seletedLabel}}</span>
    <span class="value-text" *ngIf="checkedList.length > 0 && !showMore">{{seletedLabel.split(',')}}</span>
    <div class="arrow-box">
      <i *ngIf="!alternateLook" class="arrow-custom --xsmall down"></i>
      <img *ngIf="alternateLook" src="../../assets/icons/gray-arrow-down.svg" alt="Gray arrow down"/>
    </div>
  </button>
  <ul class="dropdown-menu">
    <div class="search-input" *ngIf="searchFilterActive === true">
      <img src="../../assets/icons/search.svg" alt="Search icon"/>
      <input type="text" placeholder="Search" [(ngModel)]="searchValue" (input)="applySearchFilter(searchValue)"/>
    </div>
    <div *ngIf="searchFilterActive" class="dropdown-items">
      <label *ngFor="let item of items | slice:0:50" click-stop-propagation>
        <input type="checkbox" [(ngModel)]="item.checked" (change)="getSelectedValue(item.checked,item.name, item.id)" />
        <span>{{item.name}}</span>
      </label>
    </div>
    <div *ngIf="!searchFilterActive" class="dropdown-items">
      <label *ngFor="let item of items" click-stop-propagation>
        <input type="checkbox" [(ngModel)]="item.checked" (change)="getSelectedValue(item.checked,item.name, item.id)" />
        <span>{{item.name}}</span>
      </label>
    </div>
    <div class="apply" (click)="applyFilter()" *ngIf="requireApply">{{'COMMON.APPLY' | translate}}</div>
  </ul>
</div>