<div class="box" [class.opened]="show">
    <svg
      class="close row g-0"
      (click)="show = !show"
      clip-rule="evenodd"
      width="24"
      height="24"
      fill="white"
      fill-rule="evenodd"
      stroke-linejoin="round"
      stroke-miterlimit="2"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m12 10.93 5.719-5.72c.146-.146.339-.219.531-.219.404 0 .75.324.75.749 0 .193-.073.385-.219.532l-5.72 5.719 5.719 5.719c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.385-.073-.531-.219l-5.719-5.719-5.719 5.719c-.146.146-.339.219-.531.219-.401 0-.75-.323-.75-.75 0-.192.073-.384.22-.531l5.719-5.719-5.72-5.719c-.146-.147-.219-.339-.219-.532 0-.425.346-.749.75-.749.192 0 .385.073.531.219z"
      />
    </svg>
    <div class="nav-container" >
  <div class="nav">
    <div class="nav-items">
      <a class="nav-item"> <span>Home</span> </a>
      <a class="nav-item"> <span>Features </span> </a>
      <a class="nav-item"> <span>Pricing</span> </a>
      <a class="nav-item"> <span>Pages</span></a>
      <a class="nav-item"> <span>Blog </span> </a>
      <a class="nav-item"> <span>FAQ</span> </a>
    </div>
  </div>
  <div class="buttons row">
    <button class="btn btn-dark"  >Get Started</button>
    <button class="btn btn-outline-dark">Sign in</button>
  </div>
</div>
</div>
<div class="main" [class.opened]="show">
  <div class="row header g-0">
    <div class="img-container col-2">
      <img src="../../../assets/logo.svg" alt="Logo" />
    </div>
    <div class="col-8">
      <button type="button" class="btn btn-dark button-position">
        Book a Demo
      </button>
    </div>
  </div>
  <div class="row mobile-header g-0">
    <div class="img-container  col-11">
      <img src="../../../assets/logo.svg" alt="Logo" />
      <svg
        class="menu"
        (click)="show = !show"
        clip-rule="evenodd"
        style="float: right; margin-top: 10px; cursor: pointer"
        height="24"
        width="24"
        fill-rule="evenodd"
        stroke-linejoin="round"
        stroke-miterlimit="2"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="m11 16.745c0-.414.336-.75.75-.75h9.5c.414 0 .75.336.75.75s-.336.75-.75.75h-9.5c-.414 0-.75-.336-.75-.75zm-9-5c0-.414.336-.75.75-.75h18.5c.414 0 .75.336.75.75s-.336.75-.75.75h-18.5c-.414 0-.75-.336-.75-.75zm4-5c0-.414.336-.75.75-.75h14.5c.414 0 .75.336.75.75s-.336.75-.75.75h-14.5c-.414 0-.75-.336-.75-.75z"
          fill-rule="nonzero"
        />
      </svg>
    </div>
  </div>
</div>
<div class="content-background">
  <div class="row content g-0">
    <div
      class="col-sm-12 col-12 order-2 col-lg-6 order-sm-2 order-lg-1 upper-padding left-col"
    >
      <div class="big-text">
        Upgrade
        <span class="green"
          ><span id="linebreak"> Your Utility </span> Service
        </span>
        Journey
      </div>
      <div class="medium-text">
        <span id="linebreak"
          >Improving how things operate. Now, you can easily fill
        </span>
        <span>
          out forms and enjoy smoother processes with Customer and
        </span>
        Asset Information Management.
      </div>
      <div class="row" style="margin-top: 2rem">
        <button  class="btn btn-dark" style="width: fit-content" [routerLink]="['/new-connection/' + utility.name + '/register']">
          Get Started
        </button>
        <button
          class="btn btn-outline-dark"
          style="margin-left: 1rem; width: fit-content"
          [routerLink]="['/new-connection/' + utility.name + '/login']"
        >
          Sign In
        </button>
      </div>
    </div>
    <div class="col-sm-12 col-lg-6 col-12 order-1 order-sm-1 order-lg-2 right-col">
      <img
        class="lady-img-size"
        src="../../../assets/landing_page_lady.png"
        alt="Lady icon"
      />
      <div class="row payment payment-position">
        <div class="col-8">
          <div class="row">
            <div class="col-2">
              <svg class="circle" viewBox="0 0 24 24">
                <path
                  d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12z"
                />
              </svg>
            </div>
            <div class="col-10 g-0">
              <span class="upper-text">Bill payment successfull</span>
              <br />
              <h4 class="lower-text number-lower-text">{{utility.currency}}50,000.00</h4>
            </div>
          </div>
        </div>
        <div class="col-4 right-col-payment-pos" style="float: right">
          <div class="row">
            <span class="col-12 upper-text g-0">Account status</span>
          </div>
          <div class="row">
            <svg
              class="col-4 approved"
              clip-rule="evenodd"
              fill-rule="evenodd"
              stroke-linejoin="round"
              stroke-miterlimit="2"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill="#379951"
                d="m11.998 2.005c5.517 0 9.997 4.48 9.997 9.997 0 5.518-4.48 9.998-9.997 9.998-5.518 0-9.998-4.48-9.998-9.998 0-5.517 4.48-9.997 9.998-9.997zm-5.049 10.386 3.851 3.43c.142.128.321.19.499.19.202 0 .405-.081.552-.242l5.953-6.509c.131-.143.196-.323.196-.502 0-.41-.331-.747-.748-.747-.204 0-.405.082-.554.243l-5.453 5.962-3.298-2.938c-.144-.127-.321-.19-.499-.19-.415 0-.748.335-.748.746 0 .205.084.409.249.557z"
                fill-rule="nonzero"
              />
            </svg>
            <span class="col-8 approved-text">Approved</span>
          </div>
        </div>
        <div class="corner-check">
          <svg
            clip-rule="evenodd"
            fill-rule="evenodd"
            stroke-linejoin="round"
            stroke-miterlimit="2"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill="#379951"
              d="m11.998 2.005c5.517 0 9.997 4.48 9.997 9.997 0 5.518-4.48 9.998-9.997 9.998-5.518 0-9.998-4.48-9.998-9.998 0-5.517 4.48-9.997 9.998-9.997zm-5.049 10.386 3.851 3.43c.142.128.321.19.499.19.202 0 .405-.081.552-.242l5.953-6.509c.131-.143.196-.323.196-.502 0-.41-.331-.747-.748-.747-.204 0-.405.082-.554.243l-5.453 5.962-3.298-2.938c-.144-.127-.321-.19-.499-.19-.415 0-.748.335-.748.746 0 .205.084.409.249.557z"
              fill-rule="nonzero"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
</div>
