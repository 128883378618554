import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { AssetData } from 'src/app/models/assetData.model';
import { ChartConfiguration } from 'chart.js';
import jwt_decode from "jwt-decode";
import { RegionService } from 'src/app/services/region.service';
import { ToastrService } from 'ngx-toastr';
import { SelectItem } from 'src/app/models/mapFilter.model';
import { AssetsCounts } from 'src/app/models/assetsCounts';
import { TranslationService } from 'src/app/services/translation.service';
import { SalesManagementComponent } from '../../dashboard/sales-management/sales-management.component';
import { DashboardBpsService } from 'src/app/services/bps/dashboard.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-bps-admin-dashboard',
  templateUrl: './bps-admin-dashboard.component.html',
  styleUrls: ['./bps-admin-dashboard.component.scss']
})
export class BpsAdminDashboardComponent implements OnInit, AfterViewInit {
  @ViewChild(SalesManagementComponent) public agmMap: SalesManagementComponent | any = false;
  pageTitle: string = '';
  loggedUser: string = "";
  //dropdwon filter  
  selectedUtilities: number[] = [];
  //count cards
  cardsData: any[] = [];
  //graph
  assetsCounts: AssetsCounts = new AssetsCounts();
  lineGraphData: any;
  customersData: AssetData = new AssetData(null, 'Customers');
  graphType : string = "Daily";
  //map
  @ViewChild('childRef', { static: false }) childComponent: SalesManagementComponent;
  polesData: AssetData = new AssetData(null, 'Poles');
  buildingsSalesManagementData: AssetData = new AssetData();;
  transformersData: AssetData = new AssetData(null, 'Transformers');
  increasement: number = 0;
  selectedAssets: SelectItem[] = [
    new SelectItem("Customers", "Customers", "Customers", 1, true, true),
    new SelectItem("Buildings", "Buildings", "Buildings", 2, true, true),
    new SelectItem("Transformers", "Transformers", "Transformers", 3, true, true),
    new SelectItem("LT Poles", "LT Poles", "LT Poles", 4, true, true)
  ];
  reloadMapTrigger : boolean = true;
  //tagget asset breakdown
  buildingsColors = ['#FF4500', '#cc5500'];
  dtColors = ['#89CFF0', '#191970'];
  poleColors = ['#4b5320', '#B2AC88', '#355E3B'];



  analyticsOverviewData: any;
  analyticsOverview: Map<number, AssetData> = new Map<number, AssetData>();
  buildingsDoughnutData: AssetData = new AssetData();;
  graphData: any = { labels: ['-'] };
  formatedGraphData: ChartConfiguration<'bar'>['data'] = { datasets: [] };
  buildingsData: AssetData = new AssetData(null, 'Buildings');
  pageSize : number = 7;
  page : number = 0;
  mapLoaded: boolean = false;
  graphDateType : string = "LastUpdateTime";



  constructor(private dashboardBpsService: DashboardBpsService, private cdr: ChangeDetectorRef, private utilityService: UtilityService, private regionService: RegionService, private toastr: ToastrService, private translationService: TranslationService) {
  }

  ngAfterViewInit(): void {
    if (this.agmMap && this.agmMap.map) {
      this.mapLoaded = true;
      this.cdr.detectChanges();
    }
  }

  ngOnInit(): void {
    let user: any = jwt_decode(localStorage.getItem("token")!);
    if (user != undefined) {
      this.loggedUser = user["name"].split(' ')[0];
    }
  }

  selectUtilities(event : any ){
    this.selectedUtilities = event;
    this.reloadDashboard();
  }

  reloadDashboard() {
    this.getCountCards(); //count cards
    this.getGraphData();
    this.getOperationsAnalytics([0, 'Daily']); //graph
    this.getAnalyticsOverview();

  }

  increasePage(val: number) {
    if (this.page + val >= 0) {
      this.page += val;
      this.getGraphData();
    }
  }

  changeGraphType(type: string) {
    if (this.graphData != type) {
      this.graphType = type;
      this.page = 0;
      this.getGraphData();
    }

  }

  getCountCards(){
    this.dashboardBpsService.getAnalyticsOveriview(this.selectedUtilities).subscribe({
      next: (data) => {
        this.cardsData = data.body.data;
        console.log(this.cardsData)
      },
      error: (error) => console.log(error.statusText)
    });  
  }

  getAnalyticsOverview() {
    this.dashboardBpsService.getAnalyticsOveriviewBuilding(this.selectedUtilities).subscribe({
      next: (data) => {
        this.buildingsData = new AssetData(data?.body?.data, 'Buildings');

        this.buildingsData.totalCount = this.buildingsData.items.find(x => x.key == 'Tagged')?.value! + this.buildingsData.items.find(x => x.key == 'Inaccessible')?.value!;
        this.buildingsData.totalCount = Number.isNaN(this.buildingsData.totalCount) ? 0 : this.buildingsData.totalCount;
        this.buildingsSalesManagementData = JSON.parse(JSON.stringify(this.buildingsData))
        this.buildingsDoughnutData = JSON.parse(JSON.stringify(this.buildingsData))
        this.buildingsSalesManagementData.items = this.buildingsSalesManagementData.items.filter(x => x.key != 'New' && x.key != 'Existing');
    
        this.buildingsDoughnutData.items = this.buildingsDoughnutData.items.filter(x => x.key === 'Tagged' || x.key === 'Inaccessible');
    
        this.calculateIncreasement();
      },
      error: (error) => console.log(error.statusText)
    });
    this.dashboardBpsService.getAnalyticsOveriviewCustomer(this.selectedUtilities).subscribe({
      next: (data) => {
        this.customersData = new AssetData(data?.body?.data, 'Customers');
      },
      error: (error) => console.log(error.statusText)
    });
    this.dashboardBpsService.getAnalyticsOveriviewPole(this.selectedUtilities).subscribe({
      next: (data) => {
        this.polesData = new AssetData(data?.body?.data, 'Poles');
      },
      error: (error) => console.log(error.statusText)
    });
    this.dashboardBpsService.getAnalyticsOveriviewDt(this.selectedUtilities).subscribe({
      next: (data) => {
        this.transformersData = new AssetData(data?.body?.data, 'Transformers');
      },
      error: (error) => console.log(error.statusText)
    });
  }

  calculateIncreasement() {

    let totalCount = this.buildingsData.totalCount + this.customersData.totalCount + this.transformersData.totalCount + this.polesData.totalCount;
    let monthCount = this.buildingsData.lastMonthCount + this.customersData.lastMonthCount + this.transformersData.lastMonthCount + this.polesData.lastMonthCount;

    let totalDiff = totalCount - monthCount;

    this.increasement = (monthCount / totalDiff) * 100;

  }

  getGraphData() {
    var request = {
      page: this.page,
      graphDataType: this.graphType,
      graphDateType : this.graphDateType,
      areaIds: [],
      utilityIds: this.selectedUtilities,
      isBps: true,
    }
    this.dashboardBpsService.getGraphData(request).subscribe({
      next: (data) => {
        this.graphData = this.formatGraphData(data.body);
      },
      error: (error) => console.log(error.statusText)
    });
  }

  // graph data
  getOperationsAnalytics(eventData: [number, string]) {
    const [chartPage, period] = eventData
    let obj = {
      page: chartPage,
      pageSize: this.pageSize,
      graphType: period,
      utilityIds: this.selectedUtilities,
    };
    this.dashboardBpsService.getOperationsAnalytics(obj).subscribe({  //graph
      next: (data) => {
        this.lineGraphData = this.formatLineGraphData(data.body);
      },
      error: (error) => console.log(error.statusText)
    });

    this.dashboardBpsService.getAssetsCounts(this.selectedUtilities).subscribe({ // left graph count
      next: (data) => {
        this.assetsCounts = new AssetsCounts(data?.body?.data);
      },
      error: (error) => console.log(error.statusText)
    });
  }
  formatLineGraphData(data: any) {
    let formatedGraphData = {
        labels: data?.labels ? data.labels : [],
      datasets: [
        // { data: data.customersCount, label: 'Customers', fill: true, tension: 0.4, backgroundColor: '#ff6347', pointBackgroundColor: "#ff6347", borderColor: "#ff6347", pointRadius: 4 },
        { data: data?.surveysCount ? data.surveysCount : [], label: 'Surveys', fill: true, tension: 0.4,backgroundColor:"#E072A4 ", pointBackgroundColor: "#E072A4 ", borderColor: "#E072A4 ", pointRadius: 4 },
        { data: data?.installationsCount ? data.installationsCount : [], label: 'Installations', fill: true, tension: 0.4,backgroundColor:"#8F00FF", pointBackgroundColor: "#8F00FF", borderColor: "#8F00FF", pointRadius: 4 }
      ]
    };
    return formatedGraphData;
  }



  formatGraphData(data: any) {
    let formatedGraphData = {
      labels: data?.data?.labels ? data?.data?.labels : [],
      datasets: [
        { data: data?.data?.customersCount ? data.data.customersCount : [], label: 'Customers', borderRadius: 10, borderWidth: 0, backgroundColor: "#171717", hoverBackgroundColor: "#171717", barPercentage: 0.6 },
        { data: data?.data?.buildingsCount ? data.data.buildingsCount : [], label: 'Buildings', borderRadius: 10, borderWidth: 0, backgroundColor: "#FF991F", hoverBackgroundColor: "#FF991F", barPercentage: 0.6 },
        { data: data?.data?.dtsCount ? data.data.dtsCount : [], label: 'Transformers', borderRadius: 10, borderWidth: 0, backgroundColor: "#0052CC", hoverBackgroundColor: "#0052CC", barPercentage: 0.6 },
        { data: data?.data?.polesCount ? data.data.polesCount : [], label: 'Poles', borderRadius: 10, borderWidth: 0, backgroundColor: "#00875A", hoverBackgroundColor: "#00875A", barPercentage: 0.6 },
      ]
    };
    return formatedGraphData;
  }


}
