import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PoleSave } from 'src/app/models/pole.model';
import { User } from 'src/app/models/user.model';
import { OptionObj } from 'src/app/models/util.model';
import { PoleService } from 'src/app/services/pole.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-add-pole',
  templateUrl: './add-pole.component.html',
  styleUrls: ['./add-pole.component.scss']
})
export class AddPoleComponent implements OnInit {

  pole: PoleSave = new PoleSave();
  mobileUsers: User[] = [];
  poleTypesOptions: OptionObj[] = [];

  constructor(private userService: UserService, private toastr: ToastrService, private poleService: PoleService) { }

  ngOnInit(): void {
    this.getMobileUsers();
    this.getPoleTypesForOptions();
  }

  getPoleTypesForOptions(){
    this.poleService.getPoleTypesForOptions().subscribe(response => {
      this.poleTypesOptions = response.data;
    }, error => {
      this.toastr.error('An error occurred.');
    });
  }

  handleFormSubmit() {
    this.poleService.addOrUpdatePole(this.pole).subscribe({
      next: response => {
        const responseData = response.body;
        if(responseData?.status === 200 || responseData?.status === "OK"){
          this.toastr.success("Successfully added pole!");
          this.pole = new PoleSave();
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: _ => {
          this.toastr.error("Error occured");
        }
    });
  }

  selectOption(e: any){
    this.pole.assignedUserId = +e.target.value;
  }

  selectPoleType(e: any){
    this.pole.poleType = e.target.value;
  }

  getMobileUsers(){
    this.userService.getMobileUsers(0).subscribe({
      next: response => {
        const responseData = response.body;
        if(responseData?.status === 200 || responseData?.status === "OK"){
          this.mobileUsers = responseData.data;
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: _ => {
          this.toastr.error("Error occured");
        }
    });
  }


}
