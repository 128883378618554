import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChange, ViewChild } from '@angular/core';
import { HistoryOutput } from 'src/app/models/history.model';
import { DtService } from 'src/app/services/dt.service';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {
  @Output() update = new EventEmitter();
  @Input() historyItems : any[] = [];
  @Input() isViewHistory : boolean = false;

  history: HistoryOutput = new HistoryOutput();
  
  constructor() { }

  ngOnInit(): void {
  }

  save() {
    this.update.emit(this.history);
  }

  ngOnChanges(changes: { [property: string]: SimpleChange }) {
    console.log(this.historyItems);
  }
}
