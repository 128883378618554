<div class="add-customer-root">
    <div class="ac-header">
        <button (click)="saveOrUpdate()" class="btn btn-primary"
            [ngClass]="saveDisabled ? 'btn btn-disabled' : '' ">Save</button>
        <button class="btn btn-cancel" (click)="cancelClicked()">Cancel</button>
        <button *ngIf="customer.id != 0" class="btn btn-assign" data-bs-toggle="modal" data-bs-target="#assignForDecommission">Assign for decommision</button>
    </div>
    <div class="ac-body section-line"
        [ngClass]="activeCard === 4 ? 'section-line-last-active' : activeCard === 5 ? 'section-line-finished' : ''">
        <form ngNativeValidate id="card1Form" (submit)="continue()" class="card-1"
            [ngClass]="activeCard > 1 ? 'item-line' : ''">
            <div class="card-header">
                <div class="num" [ngClass]="(activeCard > 1) ? 'card-number-done' : ''">1</div>
                <div>Customer information</div>
                <div *ngIf="activeCard > 1" (click)="setActiveCard(1)">Edit</div>
            </div>
            <div *ngIf="activeCard === 1">
                <div class="container card form-wrapper">
                    <div class="form-item form-floating mb-3">
                        <input [(ngModel)]="customer.buildingOwner" name="customerName" type="text" class="form-control"
                            id="floatingInput1" placeholder=" " required>
                        <label for="floatingInput1">Building owner/Account name</label>
                    </div>
                    <div class="row">
                        <div class="col-6 form-item form-floating mb-3">
                            <select [ngClass]="customerType !== '' ? 'selected-option' : ''" name="customerType"
                                (change)="selectCustomerType($event)" required class="form-select">
                                <option value="" [selected]="customerType === ''" disabled>Customer type</option>
                                <option *ngFor="let o of customerTypeOptions" [selected]="customerType === o.name"
                                    value="{{o.value}}">
                                    {{o.name}}
                                </option>
                            </select>
                            <label *ngIf="customerType !== ''" class="label-for-select" for="floatingSelect">Customer
                                type</label>
                            <div *ngIf="customerType !== ''" class="select-title"></div>
                        </div>
                        <div class="col-6 form-item form-floating mb-3">
                            <input [(ngModel)]="customer.accountNumber" name="accountNumber" type="text"
                                class="form-control" id="floatingInput2" placeholder=" " [required]="customer.id===0">
                            <label for="floatingInput2">Account number</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 form-item form-floating mb-3">
                            <input [(ngModel)]="customer.phoneNumber" name="phoneNumber" type="text"
                                class="form-control" id="floatingInput11" placeholder=" " pattern="[- +()0-9]+">
                            <label for="floatingInput11">Phone number</label>
                        </div>
                        <div class="col-6 form-item form-floating mb-3">
                            <input [(ngModel)]="customer.email" name="emailAddress" type="email" class="form-control"
                                id="floatingInput10" placeholder=" ">
                            <label for="floatingInput10">Email address</label>
                        </div>
                    </div>
                    <div class="form-item form-floating mb-3">
                        <input [(ngModel)]="customer.address" name="homeAddress" type="text" class="form-control"
                            id="floatingInput9" placeholder=" " required>
                        <label for="floatingInput9">Address</label>
                    </div>
                    <div class="row">
                        <div class="col-6 form-item form-floating mb-3">
                            <select [ngClass]="region !== '' ? 'selected-option valid' : ''" name="region"
                                (change)="selectRegion($event)" required class="form-select">
                                <option value="" [selected]="region === ''" disabled>{{buName}}</option>
                                <option *ngFor="let o of regions" [selected]="region === o.name" value="{{o.id}}">
                                    {{o.name}}
                                </option>
                            </select>
                            <label *ngIf="region !== ''" class="label-for-select"
                                for="floatingSelect">{{buName}}</label>
                            <div *ngIf="region !== ''" class="select-title"></div>
                        </div>
                        <div class="col-6 form-item form-floating mb-3">
                            <select [ngClass]="area !== '' ? 'selected-option' : ''" name="area"
                                (change)="selectArea($event)" required class="form-select">
                                <option value="" [selected]="area === ''" disabled>{{utName}}</option>
                                <option *ngFor="let o of selectableAreas" [selected]="area === o.name" value="{{o.id}}">
                                    {{o.name}}
                                </option>
                            </select>
                            <label *ngIf="area !== ''" class="label-for-select" for="floatingSelect">{{utName}}</label>
                            <div *ngIf="area !== ''" class="select-title"></div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 form-item form-floating mb-3">
                            <select [ngClass]="buildingType !== '' ? 'selected-option' : ''" name="buildingType"
                                (change)="selectBuildingType($event)" required class="form-select">
                                <option value="" [selected]="buildingType === ''" disabled>Use of premises</option>
                                <option *ngFor="let o of buildingTypeOptions" [selected]="buildingType === o.name"
                                    value="{{o.value}}">
                                    {{o.name}}
                                </option>
                            </select>
                            <label *ngIf="buildingType !== ''" class="label-for-select" for="floatingSelect">Use of
                                permises</label>
                            <div *ngIf="buildingType !== ''" class="select-title"></div>
                        </div>
                        <div class="col-6 form-item form-floating mb-3">
                            <select [ngClass]="typeOfConnection !== '' ? 'selected-option' : ''" name="typeOfConnection"
                                (change)="selectCustomerPhase($event)" class="form-select">
                                <option value="" [selected]="typeOfConnection === ''" disabled>Customer phase</option>
                                <option *ngFor="let o of customerPhaseOptions" [selected]="typeOfConnection === o.name"
                                    value="{{o.value}}">
                                    {{o.name}}
                                </option>
                            </select>
                            <label *ngIf="typeOfConnection !== ''" class="label-for-select"
                                for="floatingSelect">Customer phase</label>
                            <div *ngIf="typeOfConnection !== ''" class="select-title"></div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-item form-floating mb-3"
                            [ngClass]="isServiceTypeFieldVisible ? 'col-6' : 'col-12'">
                            <select [ngClass]="clientType !== '' ? 'selected-option' : ''" name="buildingType"
                                (change)="selectClientType($event)" class="form-select">
                                <option value="" [selected]="clientType === ''" disabled>Client type</option>
                                <option *ngFor="let o of clientTypeOptions" [selected]="clientType === o.name"
                                    value="{{o.value}}">
                                    {{o.name}}
                                </option>
                            </select>
                            <label *ngIf="clientType !== ''" class="label-for-select" for="floatingSelect">Client
                                type</label>
                            <div *ngIf="clientType !== ''" class="select-title"></div>
                        </div>
                        <div class="col-6 form-item form-floating mb-3" [hidden]="!isServiceTypeFieldVisible">
                            <select [ngClass]="serviceType !== '' ? 'selected-option' : ''" name="serviceType"
                                (change)="selectServiceType($event)" class="form-select">
                                <option value="" [selected]="serviceType === ''" disabled>Service type</option>
                                <option *ngFor="let o of serviceTypeOptions" [selected]="serviceType === o.name"
                                    value="{{o.value}}">
                                    {{o.name}}
                                </option>
                            </select>
                            <label *ngIf="serviceType !== ''" class="label-for-select" for="floatingSelect">Service
                                type</label>
                            <div *ngIf="serviceType !== ''" class="select-title"></div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 form-item form-floating mb-3" [hidden]="!isSpnFieldVisible">
                            <input [(ngModel)]="customer.spn" name="spn" type="text"
                                class="form-control non-required-field" id="floatingInput13" placeholder=" "
                                pattern="[0-9]+">
                            <label for="floatingInput13">{{contractNumber}}</label>
                        </div>
                        <div class="col-6 form-item form-floating mb-3" [hidden]="!isBlockNumberFieldVisible">
                            <input [(ngModel)]="customer.blockNumber" name="blockNumber" type="number" max="2147483647"
                                min="-2147483647" class="form-control non-required-field" id="floatingInput5"
                                placeholder=" " pattern="[0-9]+">
                            <label for="floatingInput5">Block number</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 form-item form-floating mb-3" [hidden]="!isGeoCodeFieldVisible">
                            <input [(ngModel)]="customer.geoCode" name="geoCode" type="number"
                                class="form-control non-required-field" id="floatingInput6" placeholder=" ">
                            <label for="floatingInput3">Geo code</label>
                        </div>
                        <div class="col-6 form-item form-floating mb-3" [hidden]="!isActivityCodeFieldVisible">
                            <input [(ngModel)]="customer.activityCode" name="activityCode" type="text"
                                class="form-control non-required-field" id="floatingInput4" placeholder=" ">
                            <label for="floatingInput6">Activity code</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 form-item form-floating mb-3" [hidden]="!isCurrentPlotFieldVisible">
                            <input [(ngModel)]="customer.currentPlotCode" name="currentPlotCode" type="text"
                                class="form-control non-required-field" id="floatingInput7" placeholder=" ">
                            <label for="floatingInput7">Current Plot Code</label>
                        </div>
                        <div class="col-6 form-item form-floating mb-3" [hidden]="!isGhanaPostFieldsVisible">
                            <input [(ngModel)]="customer.ghanaPostGPS" name="ghanaPostGPS" type="text"
                                class="form-control non-required-field" id="floatingInput8" placeholder=" ">
                            <label for="floatingInput8">Ghana post GPS</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 form-item form-floating mb-3">
                            <select [ngClass]="customer.sltStatus && customer.sltStatus.id !== 0 ? 'selected-option' : ''" name="sltStatus"
                                id="title" #title="ngModel" [(ngModel)]="selectedSltStatus" class="form-select">
                                <option [selected]="!customer.sltStatus || customer.sltStatus.id === 0" disabled [value]="title"> {{sltStatus}}
                                </option>
                                <option *ngFor="let option of sltOptions" [ngValue]="option" [selected]="selectedSltStatus.key === option.key">
                                    {{option.value}}
                                </option>
                            </select>
                            <label *ngIf="customer.sltStatus && customer.sltStatus.id !== 0" class="label-for-select"
                                for="floatingSelect">{{sltStatus}}</label>
                            <div *ngIf="customer.sltStatus && customer.sltStatus.id !== 0" class="select-title"></div>
                        </div>
                    </div>
                </div>
                <div class="btn-continue-container">
                    <button class="btn btn-continue" form="card1Form">Continue</button>
                </div>
            </div>
            <div *ngIf="activeCard > 1" class="filled-card">
                <div class="container card">
                    <div class="row">
                        <div class="col-6">Building owner/Account name: {{customer.buildingOwner}}</div>
                        <div class="col-6">Customer type: {{customer.customerType}}</div>
                    </div>
                    <div class="row">
                        <div class="col-6">Phone number: {{customer.phoneNumber}}</div>
                        <div class="col-6">Email address: {{customer.email}}</div>
                    </div>
                    <div class="row">
                        <div class="col-12">Address: {{customer.address}}</div>
                    </div>
                </div>
            </div>
        </form>

        <form ngNativeValidate id="card2Form" (submit)="continue()" class="card-2 item"
            [ngClass]="activeCard > 2 ? 'item-line' : ''">
            <div class="card-header">
                <diV class="num"
                    [ngClass]="(activeCard < 2) ? 'card-number-gray' : (activeCard > 2) ? 'card-number-done' : '' ">2
                </diV>
                <div [ngClass]="(activeCard < 2) ? 'title-gray' : '' ">Tariff, Feeder, DT information</div>
                <div *ngIf="activeCard > 2" (click)="setActiveCard(2)">Edit</div>
            </div>
            <div *ngIf="activeCard === 2">
                <div class="container card form-wrapper">
                    <div class="form-item form-floating mb-3">
                        <select [ngClass]="tariff !== '' ? 'selected-option' : ''" name="tariff"
                            (change)="selectTariff($event)" required class="form-select">
                            <option value="" [selected]="tariff === ''" disabled>Tariff</option>
                            <option *ngFor="let o of tariffs" [selected]="tariff === o.name" value="{{o.id}}">
                                {{o.name}}
                            </option>
                        </select>
                        <label *ngIf="tariff !== ''" class="label-for-select" for="floatingSelect">Tariff</label>
                        <div *ngIf="tariff !== ''" class="select-title"></div>
                    </div>
                    <div class="form-item form-floating mb-3">
                        <select [ngClass]="feederType !== -1 ? 'selected-option' : ''" name="selectArea"
                            (change)="selectFeederType($event)" required class="form-select">
                            <option value="" [selected]="feederType === -1" disabled>Feeder type</option>
                            <option value="KV11" [selected]="feederType === 0">11KV</option>
                            <option value="KV33" [selected]="feederType === 1">33KV</option>
                        </select>
                        <label *ngIf="feederType !== -1" class="label-for-select" for="floatingSelect">Feeder
                            type</label>
                        <div *ngIf="feederType !== -1" class="select-title"></div>
                    </div>
                    <div class="form-item form-floating mb-3">
                        <select [ngClass]="selectedFeederId !== 0 ? 'selected-option' : ''" name="selectSs"
                            (change)="selectFeeder($event)" required class="form-select">
                            <option value="" [selected]="selectedFeederId === 0" disabled>Feeder</option>
                            <option *ngFor="let feeder of feeders" [selected]="selectedFeederId === feeder.id"
                                value="{{feeder.id}}">
                                {{feeder.name}}
                            </option>
                        </select>
                        <label *ngIf="selectedFeederId !== 0" class="label-for-select"
                            for="floatingSelect">Feeder</label>
                        <div *ngIf="selectedFeederId !== 0" class="select-title"></div>
                    </div>
                    <div class="form-item form-floating mb-3">
                        <input [ngClass]="selectedDt.connectedFeederNumber.length > 0 ? 'filled-input' : ''"
                            [(ngModel)]="selectedDt.connectedFeederNumber" name="feederNumber" type="text"
                            class="form-control" id="floatingInput2" placeholder=" " required disabled>
                        <label for="floatingInput2">Feeder number</label>
                    </div>

                    <div class="form-item form-floating mb-3">
                        <select [ngClass]="selectedDt.externalId !== 0 ? 'selected-option' : ''" name="selectDt"
                            (change)="selectDt($event)" required class="form-select">
                            <option value="" [selected]="selectedDt.externalId === 0" disabled>DT name</option>
                            <option *ngFor="let dt of dts" [selected]="selectedDt.externalId === dt.externalId"
                                value="{{dt.externalId}}">
                                {{dt.name}}
                            </option>
                        </select>
                        <label *ngIf="selectedDt.externalId !== 0" class="label-for-select" for="floatingSelect">DT
                            name</label>
                        <div *ngIf="selectedDt.externalId !== 0" class="select-title"></div>
                    </div>
                    <div class="form-item form-floating mb-3">
                        <input [ngClass]="selectedDt.number.length > 0 ? 'filled-input' : ''"
                            [(ngModel)]="selectedDt.number" name="dtNumber" type="text" class="form-control"
                            id="floatingInput2" placeholder=" " required disabled>
                        <label for="floatingInput2">{{ dtName }}</label>
                    </div>
                </div>
                <div class="btn-continue-container">
                    <button class="btn btn-continue" form="card2Form">Continue</button>
                </div>
            </div>
            <div *ngIf="activeCard > 2" class="filled-card">
                <div class="container card">
                    <div class="row">
                        <div class="col-6">Tariff: {{ getTariffName(customer.tariffId) }}</div>
                        <div class="col-6">DT name: {{selectedDt.name}}</div>
                    </div>
                    <div class="row">
                        <div class="col-6">{{ dtName }}: {{selectedDt.number}}</div>
                        <div class="col-6">Feeder: {{selectedDt.connectedFeederNumber}}</div>
                    </div>
                </div>
            </div>
        </form>

        <form ngNativeValidate id="card3Form" (submit)="continue()" class="card-3 item"
            [ngClass]="activeCard > 3 ? 'item-line' : ''">
            <div class="card-header">
                <diV class="num"
                    [ngClass]="(activeCard < 3) ? 'card-number-gray' : (activeCard > 3) ? 'card-number-done' : '' ">3
                </diV>
                <div [ngClass]="(activeCard < 3) ? 'title-gray' : '' ">Meter information</div>
                <div *ngIf="activeCard > 3" (click)="setActiveCard(3)">Edit</div>
            </div>
            <div *ngIf="activeCard === 3">
                <div class="container card form-wrapper">
                    <div class="form-item form-floating mb-3">
                        <select [ngClass]="meterStatus !== '' ? 'selected-option' : ''"
                            (change)="selectMeterStatus($event)" name="meterStatus" required class="form-select">
                            <option value="" [selected]="meterStatus === ''" disabled>Meter status</option>
                            <option *ngFor="let o of meterStatusOptions" [selected]="meterStatus === o.name"
                                value="{{o.value}}">
                                {{o.name}}
                            </option>
                        </select>
                    </div>
                    <div class="row" *ngIf="meterStatus === 'Metered'">
                        <div class="col-6 form-item form-floating mb-3">
                            <select [ngClass]="meterType !== '' ? 'selected-option' : ''"
                                (change)="selectMeterType($event)" name="meterType" required class="form-select">
                                <option value="" [selected]="meterType === ''" disabled>Meter type</option>
                                <option *ngFor="let o of meterTypes" [selected]="meterType=== o.name" value="{{o.id}}">
                                    {{o.name}}
                                </option>
                            </select>
                        </div>
                        <div class="col-6 form-item form-floating mb-3">
                            <select [ngClass]="billingType !== '' ? 'selected-option' : ''"
                                (change)="selectBillingType($event)" name="billingType" required class="form-select">
                                <option value="" [selected]="billingType === ''" disabled>Customer type</option>
                                <option *ngFor="let o of billingTypeOptions" [selected]="billingType === o.name"
                                    value="{{o.value}}">
                                    {{o.name}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="form-item form-floating mb-3" *ngIf="meterStatus === 'Metered'">
                        <select [ngClass]="meterCondition !== '' ? 'selected-option' : ''"
                            (change)="selectMeterCondition($event)" name="meterCondition" required class="form-select">
                            <option value="" [selected]="meterCondition === ''" disabled>Meter condition</option>
                            <option *ngFor="let o of meterConditionOptions" [selected]="meterCondition === o.name"
                                value="{{o.value}}">
                                {{o.name}}
                            </option>
                        </select>
                    </div>
                    <div class="form-item form-floating mb-3" *ngIf="meterStatus === 'Metered'">
                        <input [(ngModel)]="customer.meter.number" name="meterNumber" type="text" class="form-control"
                            id="floatingInput2" placeholder=" " required>
                        <label for="floatingInput2">Meter number</label>
                    </div>
                </div>
                <div class="btn-continue-container">
                    <button class="btn btn-continue" form="card3Form">Continue</button>
                </div>
            </div>
            <div *ngIf="activeCard > 3" class="filled-card">
                <div class="container card">
                    <div class="row">
                        <div class="col-6">Meter type: {{getMeterTypeName(customer.meter.typeId)}}</div>
                        <div class="col-6">Meter status: {{MeterStatus[customer.meter.status]}}</div>
                    </div>
                    <div class="row">
                        <div class="col-6">Meter condition: {{MeterCondition[customer.meter.condition]}}</div>
                        <div class="col-6">Meter number: {{customer.meter.number}}</div>
                    </div>
                </div>
            </div>
        </form>

        <div class="card-4 item">
            <div class="card-header">
                <diV class="num"
                    [ngClass]="(activeCard < 3) ? 'card-number-gray' : (activeCard > 3) ? 'card-number-done' : '' ">4
                </diV>
                <div [ngClass]="(activeCard < 3) ? 'title-gray' : '' ">Status</div>
            </div>
            <div *ngIf="activeCard === 4" class="filled-card">
                <div class="container card">
                    <div class="row">
                        <div class="col-6">Accessibility Status: {{customer.buildingId ? "Completed" : "Pending Setup"}}
                        </div>
                        <div class="col-6">Survey Status: {{customer.surveyStatus}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="successfull-toast" *ngIf="successfullyAdded">
        <div class="img-container"><img src="../../../../assets/icons/checked_green.svg" alt="Checked icon"/></div>
        <div class="text-container">
            <div>Added</div>
            <div>New customer has been added</div>
        </div>
        <div class="x-container"><img src="../../../../assets/icons/close.svg" alt="Close icon"/></div>
    </div>
</div>
<button class="btn btn-primary" #openUpdateDescriptionModal data-bs-toggle="modal" data-bs-target="#history"
    hidden></button>
<app-history (update)="updateHistory($event)"></app-history>

<div class="modal fade" id="assignForDecommission" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Assign to field agent</h5>
        <button type="button" #closeModal class="btn-cancel-modal" data-bs-dismiss="modal" aria-label="Close">
          Cancel
        </button>
      </div>
      <div class="modal-body">
        <div class="form-floating form-item">
            
          <input #userFilterInput autocomplete="off" type="text" name="filterText" [(ngModel)]="filterText"
            class="form-control filter-field" [ngClass]="{ 'valid-input': tempAssignedUserId != 0 }" id="floatingInput"
            (ngModelChange)="filterMobileUsers()" (click)="isDropdownOpened = true" placeholder=" " />
          <label for="floatingInput">Field agent</label>

          <div class="filtered-agent-list" *ngIf="isDropdownOpened" #dropdownContainer>
            <div click-stop-propagation class="agent-name" (click)="selectFieldAgent(u.id)"
              *ngFor="let u of mobileUsers">
              {{ u.fullName }}
            </div>
          </div>
          
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#assignForDecommissionSuccess" (click)="assignForDecommission()">
          <span data-bs-dismiss="modal">Assign task</span>
        </button>
      </div>
    </div>
  </div>
</div>









<div class="modal fade" id="assignForDecommissionSuccess" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Assign to decommision successfull</h5>
          <button type="button" #closeModal class="btn-cancel-modal" data-bs-dismiss="modal" aria-label="Close">
            Cancel
          </button>
        </div>
        <div class="modal-body">
            Task successfully assigned for Decommissioning.
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-primary" data-bs-dismiss="modal">
              <span >Ok</span>
            </button>
          </div>
      </div>
    </div>
  </div>


