import { Component, OnInit, OnDestroy } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import jwt_decode from "jwt-decode";
import { UserService } from '../../services/user.service';
import { AuthService } from '../../services/auth.service';
import {  Router } from '@angular/router';
import { User } from '../../models/user.model';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit  {
   
  
  loggedUser: User = new User;
  
  constructor(private toastr: ToastrService, private userService: UserService, private authService: AuthService, 
              private router: Router, private commonService: CommonService) { }

  ngOnInit(): void {

    let user: any = jwt_decode(localStorage.getItem("token")!);
    if(user != undefined) {
      this.loggedUser.firstName = user["name"].split(' ')[0];
      this.loggedUser.email = user['email'];
    }
    
    this.getUser();
  }
  
  getUser() {
    this.userService.getUser( this.loggedUser.email).subscribe({
      next: (data) => {
        this.loggedUser = data.data;
      },
      error: (error) => console.log(error) 
    });
  }

}
