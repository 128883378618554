import { BuildingImage, Slrn } from './building.model';
import { History } from './customer.model';
import { HistoryOutput } from './history.model';
import { KeyValuePair } from './keyValueItem.model';

export class PoleDetails {
  id: number = 0;
  slrn: Slrn;
  guid: string = '';
  dtName: string = '';
  address: string = '';
  upriserNumber: string = '';
  poleNumber: string = '';
  feederName: string = '';
  poleType: string = '';
  status: string = '';
  dataQualityStatus: string = '';
  poleId: string = '';
  buildingsSLRN: string = '';
  buildingsSLRNList: string[] = [];
  isChecked:boolean;
  area:string='';
  region:string = '';
  showDropdown: boolean = false;

  constructor(data?: any) {
    this.id = data?.id ?? 0;
    this.slrn = data?.slrn ?? '';
    this.guid = data?.guid ?? '';
    this.dtName = data?.dtName ?? '';
    this.address = data?.address ?? '';
    this.upriserNumber = data?.upriserNumber ?? '';
    this.poleNumber = data?.poleNumber ?? '';
    this.feederName = data?.feederName ?? '';
    this.poleType = data?.poleType ?? '';
    this.status = data?.status ?? '';
    this.dataQualityStatus = data?.dataQualityStatus ?? '';
    this.poleId = data?.poleId ?? '';
    this.buildingsSLRN = data?.buildingsSLRN ?? '';
    this.isChecked = data?.isChecked ?? false;
    this.area = data?.area ?? '';
    this.region = data?.region ?? '';
    this.buildingsSLRNList = data?.buildingsSLRN ? data?.buildingsSLRN.split(",") : [];
    this.showDropdown = false;
  }
}

export class EditPole {
  id: number = 0;
  slrn: string = '';
  latitude: number = 0;
  longitude: number = 0;
  address: string = '';
  guid: string = '';
  dtName: string = '';
  dtId: string = '';
  upriserNumber: string = '';
  poleNumber: string = '';
  feederName: string = '';
  feederNumber: string = '';
  region: string = '';
  district: string = '';
  districtId: number = 0;
  dtNumber: string = '';
  poleType: string = '';
  status: string = '';
  connectedBuildings: PolesBuilding[] = [];
  assignedUser: string = '';
  assignedUserId: number = 0;
  dataQualityId : number = 0;
  dataQualityStatus:string ="";
  images: BuildingImage[];
  poleShape: string = '';
  poleCondition: string = '';
  poleNote: string = '';
  poleStructure: string = '';
  crossArm: string = '';
  crossArmCondition: string = '';
  poleId: string = '';
  lastUpdateUser: string = '';
  creationDate: string = '';
  lastUpdate: string = '';
  reassignedBy: string = '';
  validatedBy: string = '';
  validationDate: string = '';
  auditedBy: string = '';
  auditedDate: string = '';
  auditedStatus: string = '';
  postCode?: string;
  postCodeException?: string;
  history: HistoryOutput;
  firstCapturedUser : string;
  streetLight :string;
  streetLightSource:string;
  wattage :string;
  meansOfIsolation :string;
  state :string;
  comment :string = '';
  poleHistories:History[];
  dataValidationReview: KeyValuePair[] = [];

  constructor(data?: any) {
    this.id = data?.id ?? 0;
    this.slrn = data?.slrn ?? '';
    this.latitude = data?.latitude ?? 0;
    this.longitude = data?.longitude ?? 0;
    this.address = data?.address ?? '';
    this.guid = data?.guid ?? '';
    this.dtName = data?.dtName ?? '';
    this.dtId = data?.dtId ?? '';
    this.upriserNumber = data?.upriserNumber ?? '';
    this.poleNumber = data?.poleNumber ?? '';
    this.feederName = data?.feederName ?? '';
    this.feederNumber = data?.feederNumber ?? '';
    this.region = data?.region ?? '';
    this.district = data?.district ?? '';
    this.districtId = data?.districtId ?? 0;
    this.dtNumber = data?.dtNumber ?? '';
    this.poleType = data?.poleType ?? '';
    this.status = data?.status ?? '';
    this.connectedBuildings = data?.connectedBuildings?.length ? data?.connectedBuildings.map((el: any) => new PolesBuilding(el)) : [];
    this.assignedUser = data?.assignedUser ?? '';
    this.assignedUserId = data?.assignedUserId ?? 0;
    this.dataQualityId = data?.dataQualityId ?? 0;
    this.dataQualityStatus = data?.dataQualityStatus ?? '';
    this.images = data?.images?.length ? data?.images.map((el: any) => new BuildingImage(el)) : [];
    this.poleShape = data?.poleShape ?? '';
    this.poleCondition = data?.poleCondition ?? '';
    this.poleNote = data?.poleNote ?? '';
    this.poleStructure = data?.poleStructure ?? '';
    this.crossArm = data?.crossArm ?? '';
    this.crossArmCondition = data?.crossArmCondition ?? '';
    this.poleId = data?.poleId ?? '';
    this.lastUpdateUser = data?.lastUpdateUser ?? '';
    this.creationDate = data?.creationDate ?? '';
    this.lastUpdate = data?.lastUpdate ?? '';
    this.reassignedBy = data?.reassignedBy ?? '';
    this.validatedBy = data?.validatedBy ?? '';
    this.validationDate = data?.validationDate ?? '';
    this.auditedBy = data?.auditedBy ?? '';
    this.auditedDate = data?.auditedDate ?? '';
    this.auditedStatus = data?.auditedStatus ?? '';
    this.postCode = data?.postCode ?? '';
    this.postCodeException = data?.postCodeException ?? '';
    this.history = new HistoryOutput(data?.history);
    this.firstCapturedUser = data?.firstCapturedUser ?? '';
    this.streetLight = data?.streetLight ?? '';
    this.streetLightSource = data?.streetLightSource ?? '';
    this.wattage = data?.wattage ?? '';
    this.meansOfIsolation = data?.meansOfIsolation ?? '';
    this.state = data?.state ?? '';
    this.comment = data?.comment ?? '';
    this.poleHistories = data?.poleHistories?.length ? data?.poleHistories.map((el: any) => new History(el)) : [];
    this.dataValidationReview = data?.dataValidationReview ?? [];
  }
}

export class PolesBuilding {
  id: number = 0;
  slrn: string = '';
  area: string = '';
  region: string = '';
  dtName: string = '';
  dtNumber: string = '';
  latitude: number = 0;
  longitude: number = 0;
  meterNumber: string = '';
  contactNumber: string = '';
  connectedCustomers: PolesCustomers[] = [];

  constructor(data?: any) {
    this.id = data?.id ?? 0;
    this.slrn = data?.slrn ?? '';
    this.area = data?.area ?? '';
    this.region = data?.region ?? '';
    this.dtName = data?.dtName ?? '';
    this.dtNumber = data?.dtNumber ?? '';
    this.latitude = data?.latitude ?? 0;
    this.longitude = data?.longitude ?? 0;
    this.meterNumber = data?.meterNumber ?? '';
    this.contactNumber = data?.contactNumber ?? '';
    this.connectedCustomers = data?.connectedCustomers?.length ? data?.connectedCustomers.map((el: any) => new PolesCustomers(el)) : [];
  }
}

export class PolesCustomers {
  id: number = 0;
  name: string = '';
  accountNumber: string = '';
  address: string = '';
  physicalAddress: string = '';
  meterNumber: string = '';
  phoneNumber: string = '';

  constructor(data?: any) {
    this.id = data?.id ?? 0;
    this.name = data?.name ?? '';
    this.accountNumber = data?.accountNumber ?? '';
    this.address = data?.address ?? '';
    this.physicalAddress = data?.physicalAddress ?? '';
    this.meterNumber = data?.meterNumber ?? '';
    this.phoneNumber = data?.phoneNumber ?? '';
  }
}

export class PoleSave {
  id: number = 0;
  upriserNumber: string = '';
  poleNumber: string = '';
  poleType: string = '';
  address: string = '';
  assignedUserId: number = 0;
  history?: HistoryOutput;

  constructor(data?: any) {
    this.id = data?.id ?? 0;
    this.upriserNumber = data?.upriserNumber ?? '';
    this.poleNumber = data?.poleNumber ?? '';
    this.address = data?.address ?? '';
    this.poleType = data?.poleType ?? '';
    this.assignedUserId = data?.assignedUserId ?? 0;
    this.history = new HistoryOutput(data?.history);
  }
}

export class PoleStats {
  count: number;
  statType: PoleStatsOptions;
}
export enum PoleStatsOptions {
  Total,
  AvgCustomerCount,
  Retagged,
  InGoodCondition
}
