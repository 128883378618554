<div class="decommissioned-meter-root">
    <div class="decommissioned-meter-header">
        <div class="meter-header-info row">
            <div class="info-section col-2">
                <div class="row">
                    <div class="col-12 name">{{ selectedMeter.customer.buildingOwner | hasValue }}</div>
                    <div class="col-12 slrn">{{ selectedMeter.customer.slrn | hasValue }}</div>
                    <div class="col-12 slrn">{{ selectedMeter.number | hasValue }}</div>
                    <div class="col-12" [ngClass]="selectedMeter.decommissioningStatus?.toString()?.toLowerCase()">
                        {{ selectedMeter.decommissioningStatus }}
                    </div>
                </div>
            </div>
            <div class="user-address col-6">
                <div class="row">
                    <div>{{ selectedMeter.customer.physicalAddress | hasValue }}</div>
                </div>
            </div>
            <div class="col-4 header-btn">
                <button
                    *ngIf="permissionsService.isDecommissionedMeterStockApproveAllowed && selectedMeter.decommissioningStatus?.toString() == 'Submitted'"
                    class="btn btn-outline-success approve-btn" data-bs-toggle="modal" data-bs-target="#modalApprove">
                    Approve
                </button>
                <button (click)="editActivated = !editActivated"
                    *ngIf="permissionsService.isDecommissionedMeterStockEditAllowed && !editActivated"
                    class="btn btn-outline-success approve-btn">
                    Edit
                </button>
                <button (click)="editActivated = !editActivated; saveChanges()"
                    *ngIf="permissionsService.isDecommissionedMeterStockEditAllowed && editActivated"
                    class="btn btn-outline-success approve-btn">
                    Save
                </button>
            </div>
        </div>
    </div>
    <div class="decommissioned-meter-header decommissioned-meter-section-header">
        <div class="meter-header-info">
            <div class="info-section">
                <div class="name">Customer Details</div>
            </div>
        </div>
    </div>
    <div class="decommissioned-meter-section">
        <div class="row">
            <div class="card col-3">
                <div class="card-row">
                    <div class="col header-title-text">Customer name</div>
                </div>
                <div class="card-row">
                    <div class="green-text">
                        {{ selectedMeter.customer.buildingOwner | hasValue }}
                    </div>
                </div>
            </div>
            <div class="card col-3">
                <div class="card-row">
                    <div class="col header-title-text">Account Number</div>
                </div>
                <div class="card-row">
                    <div class="green-text">
                        {{ selectedMeter.customer.accountNumber | hasValue }}
                    </div>
                </div>
            </div>
            <div class="card col-3">
                <div class="card-row">
                    <div class="col header-title-text">Address</div>
                </div>
                <div class="card-row">
                    <div class="green-text">
                        {{ selectedMeter.customer.physicalAddress | hasValue }}
                    </div>
                </div>
            </div>

            <div class="card col-3">
                <div class="card-row">
                    <div class="col header-title-text">Tariff</div>
                </div>
                <div class="card-row">
                    <div class="green-text">
                        {{ selectedMeter.customer.tariffName | hasValue }}
                    </div>
                </div>
            </div>
            <div class="card col-3">
                <div class="card-row">
                    <div class="col header-title-text">{{ buName }}</div>
                </div>
                <div class="card-row">
                    <div class="green-text">
                        {{ selectedMeter.customer.regionName | hasValue }}
                    </div>
                </div>
            </div>
            <div class="card col-3">
                <div class="card-row">
                    <div class="col header-title-text">{{ utName }}</div>
                </div>
                <div class="card-row">
                    <div class="green-text">
                        {{ selectedMeter.customer.areaName | hasValue }}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="decommissioned-meter-header decommissioned-meter-section-header">
        <div class="meter-header-info">
            <div class="info-section">
                <div class="name">Meter Information</div>
            </div>
        </div>
    </div>
    <div class="decommissioned-meter-section">
        <div class="row">
            <div class="card col-3">
                <div class="card-row">
                    <div class="col header-title-text">Meter Number</div>
                </div>
                <div class="card-row">
                    <div class="green-text">
                        {{ selectedMeter.number | hasValue }}
                    </div>
                </div>
            </div>
            <div class="card col-3">
                <div class="card-row">
                    <div class="col header-title-text">Meter Make</div>
                </div>
                <div class="card-row">
                    <div class="green-text">
                        {{ selectedMeter.make | hasValue }}
                    </div>
                </div>
            </div>
            <div class="card col-3">
                <div class="card-row">
                    <div class="col header-title-text">Meter Type</div>
                </div>
                <div class="card-row">
                    <div class="green-text">
                        {{ selectedMeter.customer.customerType | hasValue }}
                    </div>
                </div>
            </div>

            <div class="card col-3">
                <div class="card-row">
                    <div class="col header-title-text">Meter Phase</div>
                </div>
                <div class="card-row">
                    <div class="green-text">
                        {{ selectedMeter.customer.typeOfConnection.toString() | hasValue }}
                    </div>
                </div>
            </div>
            <div class="card col-3">
                <div class="card-row">
                    <div class="col header-title-text">Current Reading on Meter</div>
                </div>
                <div class="card-row">
                    <div class="green-text">
                        {{ selectedMeter.currentReading | hasValue }}
                    </div>
                </div>
            </div>
            <div class="card col-3">
                <div class="card-row">
                    <div class="col header-title-text">Meter SLRN</div>
                </div>
                <div class="card-row">
                    <div class="green-text">
                        {{ selectedMeter.meterSlrn | hasValue }}
                    </div>
                </div>
            </div>
            <div class="card col-3">
                <div class="card-row">
                    <div class="col header-title-text">Geolocation</div>
                </div>
                <div class="card-row">
                    <div class="green-text">
                        {{ selectedMeter.latitude | hasValue }},{{ selectedMeter.longitude | hasValue }}
                    </div>
                </div>
            </div>
            <div class="card col-3">
                <div class="card-row">
                    <div class="col header-title-text">Meter Decommissioned By</div>
                </div>
                <div class="card-row">
                    <div class="green-text">
                        {{ selectedMeter.decommissionedBy | hasValue }}
                    </div>
                </div>
            </div>
            <div class="card col-3">
                <div class="card-row">
                    <div class="col header-title-text">Meter Decommission Date</div>
                </div>
                <div class="card-row">
                    <div class="green-text">
                        {{ selectedMeter.decommissionDate | date : "dd/MM/yyyy" | hasValue }}
                    </div>
                </div>
            </div>
            <div class="card col-3">
                <div class="card-row">
                    <div class="col header-title-text">Approved By</div>
                </div>
                <div class="card-row">
                    <div class="green-text">
                        {{ selectedMeter.approvedBy | hasValue }}
                    </div>
                </div>
            </div>
            <div class="card col-3">
                <div class="card-row">
                    <div class="col header-title-text">Approved Date</div>
                </div>
                <div class="card-row">
                    <div class="green-text">
                        {{ selectedMeter.approvalDate | date : "dd/MM/yyyy" | hasValue }}
                    </div>
                </div>
            </div>
            <div class="card col-3">
                <div class="card-row">
                    <div class="col header-title-text">Stock Item Status</div>
                </div>
                <div class="card-row" *ngIf="!editActivated">
                    <div class="green-text">
                        {{ selectedMeter.stockItemStatus | stockItemValue | hasValue }}
                    </div>
                </div>
                <div class="card-row" *ngIf="editActivated">
                    <div class="green-text">
                        <select name="status-select" (change)="selectStatus($event)" class="dropdown-edit">
                            <option value="" [selected]="!status" disabled>Stock Item Status</option>
                            <option [selected]="status == s.value" *ngFor="let s of stockItemStatusOptions"
                                value="{{ s.value }}">
                                {{ s.name }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="decommissioned-meter-header decommissioned-meter-section-header">
        <div class="meter-header-info">
            <div class="info-section">
                <div class="name">Reason for Decommissioning</div>
            </div>
        </div>
    </div>
    <div class="decommissioned-meter-section">
        <div class="row">
            <div class="card col-3">
                <div class="card-row">
                    <div *ngIf="selectedMeter.reasonForDecommission" class="green-text">
                        {{ selectedMeter.reasonForDecommission.toString() }}
                    </div>
                    <div *ngIf="!selectedMeter.reasonForDecommission" class="col header-title-text">State your reason
                    </div>
                </div>
            </div>

        </div>
    </div>
    <div class="decommissioned-meter-header decommissioned-meter-section-header">
        <div class="meter-header-info">
            <div class="info-section">
                <div class="name">Location Pictures</div>
            </div>
        </div>
    </div>
    <div class="decommissioned-meter-section">
        <div class="row">
            <div class="card image-card col-4">
                <div class="row">
                    <div class="col header-title-text">Building Front View</div>
                </div>
                <div class="row image-row">
                    <div *ngFor="let image of getImage(ImageType.Frontview)" class="col-3">
                        <img alt="Item image" src="{{ image.url }}" (click)="showImage(image.url)"
                            data-bs-toggle="modal" data-bs-target="#modalImage" />
                    </div>

                    <div *ngIf="getImage(ImageType.Frontview)?.length == 0">
                        <label for="fileFw1" class="disabled-label">
                            <img src="../../../../assets/missing_image.png" alt="Missing image" />
                        </label>
                    </div>
                </div>
            </div>
            <div class="card image-card col-4">
                <div class="row">
                    <div class="col header-title-text">Existing Metering Point</div>
                </div>
                <div class="row image-row">
                    <div *ngFor="let image of getImage(ImageType.ExistingMeteringPoint)" class="col-3">
                        <img alt="Item image" src="{{ image.url }}" (click)="showImage(image.url)"
                            data-bs-toggle="modal" data-bs-target="#modalImage" />
                    </div>

                    <div *ngIf="getImage(ImageType.ExistingMeteringPoint)?.length == 0">
                        <label for="fileFw1" class="disabled-label">
                            <img src="../../../../assets/missing_image.png" alt="Missing image" />
                        </label>
                    </div>
                </div>
            </div>
            <div class="card image-card col-4">
                <div class="row">
                    <div class="col header-title-text">Decommissioned Meter SLRN</div>
                </div>
                <div class="row image-row">
                    <div *ngFor="let image of getImage(ImageType.DecommissionMeterSlrn)" class="col-3">
                        <img alt="Item image" src="{{ image.url }}" (click)="showImage(image.url)"
                            data-bs-toggle="modal" data-bs-target="#modalImage" />
                    </div>

                    <div *ngIf="getImage(ImageType.DecommissionMeterSlrn)?.length == 0">
                        <label for="fileFw1" class="disabled-label">
                            <img src="../../../../assets/missing_image.png" alt="Missing image" />
                        </label>
                    </div>
                </div>
            </div>
            <div class="card image-card col-4">
                <div class="row">
                    <div class="col header-title-text">Current Reading on Meter</div>
                </div>
                <div class="row image-row">
                    <div *ngFor="let image of getImage(ImageType.ReadingOnMeter)" class="col-3">
                        <img alt="Item image" src="{{ image.url }}" (click)="showImage(image.url)"
                            data-bs-toggle="modal" data-bs-target="#modalImage" />
                    </div>

                    <div *ngIf="getImage(ImageType.ReadingOnMeter)?.length == 0">
                        <label for="fileFw1" class="disabled-label">
                            <img src="../../../../assets/missing_image.png" alt="Missing image" />
                        </label>
                    </div>
                </div>
            </div>
            <div class="card image-card col-4">
                <div class="row">
                    <div class="col header-title-text">Existing Bill or Payment Receipt</div>
                </div>
                <div class="row image-row">
                    <div *ngFor="let image of getImage(ImageType.BillOrPaymentReceipts)">
                        <img alt="Item image" src="{{ image.url }}" (click)="showImage(image.url)"
                            data-bs-toggle="modal" data-bs-target="#modalImage" />
                    </div>

                    <div *ngIf="getImage(ImageType.BillOrPaymentReceipts)?.length == 0">
                        <label for="fileFw1" class="disabled-label">
                            <img src="../../../../assets/missing_image.png" alt="Missing image" />
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="decommissioned-meter-header decommissioned-meter-section-header">
        <div class="meter-header-info">
            <div class="info-section">
                <div class="name">Comments</div>
            </div>
        </div>
    </div>
    <div class="decommissioned-meter-section">
        <div class="row">
            <div class="card comment-card col-3">
                <div class="card-row">
                    <div class="col header-title-text">Comments</div>
                </div>
                <div class="card-row">
                    <div class="green-text">
                        {{ selectedMeter.comments }}
                    </div>
                </div>
            </div>
            <div class="card comment-card col-3">
                <div class="card-row">
                    <div class="col header-title-text">Customer's signature</div>
                </div>
                <div class="card-row">
                    <div *ngFor="let image of getImage(ImageType.CustomerSignature)">
                        <img alt="Item image" src="{{ image.url }}" (click)="showImage(image.url)"
                            data-bs-toggle="modal" data-bs-target="#modalImage" />
                    </div>

                </div>
            </div>
            <div class="card comment-card col-3">
                <div class="card-row">
                    <div class="col header-title-text">Field operator's signature</div>
                </div>
                <div class="card-row">
                    <div *ngFor="let image of getImage(ImageType.FieldRepSignature)">
                        <img alt="Item image" src="{{ image.url }}" (click)="showImage(image.url)"
                            data-bs-toggle="modal" data-bs-target="#modalImage" />
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="modalImage" tabindex="-1" aria-hidden="true" (hidden.bs.modal)="onModalFade()"
    (shown.bs.modal)="onModalOpen()">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content modal-image">
            <div class="modal-body modal-body-image">
                <div class="image-container" [style.transform]="'rotate(' + currentRotation + 'deg)'">
                    <lib-ngx-image-zoom [zoomMode]="'hover-freeze'" [fullImage]="selectedImage"
                        [thumbImage]="selectedImage" [magnification]="3">
                    </lib-ngx-image-zoom>
                </div>
                <div class="rotate-div">
                    <button class="btn btn-primary left-button" (click)="rotateLeft()">&lt;</button>
                    <button class="btn btn-primary right-button" (click)="rotateRight()">&gt;</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="modalApprove" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Approve Decommission</h5>
            </div>
            <div>
                <div>
                    You have opted to approve this meter decommission, please click the confirm
                    button below to proceed.
                </div>
                <div class="row">
                    <div class="col-6">
                        <button type="button" class="btn btn-outline-danger" style="width: 100%;"
                            data-bs-dismiss="modal">
                            Cancel
                        </button>
                    </div>
                    <div class="col-6">
                        <button type="button" class="btn btn-success" style="width: 100%;" (click)="approve()"
                            data-bs-dismiss="modal">
                            Approve
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>