import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-transformers-layout',
  templateUrl: './transformers-layout.component.html',
  styleUrls: ['./transformers-layout.component.scss']
})
export class TransformersLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
