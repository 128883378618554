import { Component, OnInit, ViewChild } from '@angular/core';
import { CrudMenuItem } from 'src/app/models/crud.menu.item.model';
import { CrudAreaComponent } from './crud-area/crud-area.component';
import { CrudDtComponent } from './crud-dt/crud-dt.component';
import { CrudF11Component } from './crud-f11/crud-f11.component';
import { CrudF33Component } from './crud-f33/crud-f33.component';
import { CrudMeterTypeComponent } from './crud-meter-type/crud-meter-type.component';
import { CrudRegionComponent } from './crud-region/crud-region.component';
import { CrudSsComponent } from './crud-ss/crud-ss.component';
import { CrudTariffComponent } from './crud-tariff/crud-tariff.component';
import { CrudTsComponent } from './crud-ts/crud-ts.component';
import { CrudCustomerComponent } from './crud-customer/crud-customer.component';
import { ActivatedRoute, Router } from '@angular/router';
import {Location} from '@angular/common'; 
import { Subject, debounceTime } from 'rxjs';
import { CrudAssetRatingComponent } from './crud-asset-rating/crud-asset-rating.component';
import { CrudDtMeterTypeComponent } from './crud-dt-meter-type/crud-dt-meter-type.component';
import { CrudAssetStatusComponent } from './crud-asset-status/crud-asset-status.component';
import { PermissionsEnum } from 'src/app/models/role.model';
import { TranslationService } from 'src/app/services/translation.service';
import { UtilityService } from 'src/app/services/utility.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { CrudSltComponent } from './crud-slt/crud-slt/crud-slt.component';
import { CrudMeterPriceComponent } from './crud-meter-price/crud-meter-price.component';
import { BPSAdminLevel } from 'src/app/models/user.model';
import { CrudBillingAccountComponent } from './crud-billing-account/crud-billing-account.component';

@Component({
  selector: 'app-crud',
  templateUrl: './crud.component.html',
  styleUrls: ['./crud.component.scss']
})
export class CrudComponent implements OnInit {
  public PermissionsEnum = PermissionsEnum;

  pageTitle:string = '';

  public CrudMenuItem = CrudMenuItem;
  crudSelected: CrudMenuItem = CrudMenuItem.TS;
  crudType: string = "";
  searchTextUpdate = new Subject<string>();
  buName: string = this.translationService.getByKeyFromStorage('BU');
  utName: string = this.translationService.getByKeyFromStorage('UT');
  selectedUtilities: number[] = [];
  utilityList: any[];
  selectedUtilityId: number = 0;
  isBpsAdmin: boolean = false;
  bpsAdminLevel: BPSAdminLevel;
  searchByOptions: { name: string; value: string }[] = [];
  showSelectSearchType: boolean = false;
  selectedSearchTypeDisplay: string='Account No.';
  searchFilterApplied: boolean = false;
  selectedSearchType: string = 'AccountNumber';

  sltStatusName: string =
    this.translationService.getByKeyFromStorage('SltStatus');

  public searchText: string = '';

  @ViewChild(CrudTsComponent)
  crudTsComponent!: CrudTsComponent;
  @ViewChild(CrudF33Component)
  crudF33Component!: CrudF33Component;
  @ViewChild(CrudSsComponent)
  crudSsComponent!: CrudSsComponent;
  @ViewChild(CrudF11Component)
  crudF11Component!: CrudF11Component;
  @ViewChild(CrudDtComponent)
  crudDtComponent!: CrudDtComponent;
  @ViewChild(CrudAreaComponent)
  crudAreaComponent!: CrudAreaComponent;
  @ViewChild(CrudRegionComponent)
  crudRegionComponent!: CrudRegionComponent;
  @ViewChild(CrudTariffComponent)
  crudTariffComponent!: CrudTariffComponent;
  @ViewChild(CrudMeterTypeComponent)
  crudMeterTypeComponent!: CrudMeterTypeComponent;
  @ViewChild(CrudCustomerComponent)
  crudCustomerComponent!: CrudCustomerComponent;
  @ViewChild(CrudAssetRatingComponent)
  crudAssetRatingComponent!: CrudAssetRatingComponent;
  @ViewChild(CrudMeterPriceComponent)
  crudMeterPriceComponent!: CrudMeterPriceComponent;
  @ViewChild(CrudAssetStatusComponent)
  crudAssetStatusComponent!: CrudAssetStatusComponent;
  @ViewChild(CrudDtMeterTypeComponent)
  crudDtMeterTypeComponent!: CrudDtMeterTypeComponent;
  @ViewChild(CrudSltComponent)
  crudSltComponent!: CrudSltComponent;
  @ViewChild(CrudBillingAccountComponent)
  crudBillingAccountComponent!: CrudBillingAccountComponent;

    constructor(private router : Router,
                private route: ActivatedRoute, 
                private location: Location, 
                private translationService: TranslationService, 
                public permissionsService: PermissionsService,
                private utilityService: UtilityService) { }

  ngOnInit(): void {
    var utilityId = parseInt(localStorage.getItem('utilityId')!);
    if(utilityId != 0) {
      this.selectedUtilityId = utilityId;
    }
    if(!this.permissionsService.hasUtility) {
      this.isBpsAdmin = true;
      this.bpsAdminLevel = parseInt(localStorage.getItem('bpsAdminLevel') || "0");
      this.getUtilities();
    }
    else {

      this.crudType = this.route.snapshot.paramMap.get('id') || "";
      if(this.crudType != ""){
        this.crudSelected = Number(this.crudType);
      }
      this.searchTextUpdate.pipe(
        debounceTime(500))
        .subscribe(value => {
          if(this.searchText=="")
            this.search();
        });
        var filter = localStorage.getItem('filter');
      if (filter) {
        var filterObject = JSON.parse(filter);
        if (filterObject.type == 'CustomerCrud') {
          this.crudSelected = CrudMenuItem.Customer;
          this.initializeSearchByOptions(this.crudSelected);
          this.location.replaceState(`/crud/${this.crudSelected}`);
          return;
        }
        else if (filterObject.type == 'BillingAccount') {
          this.crudSelected = CrudMenuItem.BillingAccounts;
          this.initializeSearchByOptions(this.crudSelected);
          this.location.replaceState(`/crud/${this.crudSelected}`);
          return;
        }
      }
      this.selectFirstTab()
    }
  }

  selectFirstTab(){
    if(this.permissionsService.isTsViewAllowed){
      this.router.navigate(['/crud/0']);
      this.crudSelected = 0;
    }
    else if(this.permissionsService.isF33ViewAllowed){
      this.router.navigate(['/crud/1']);
      this.crudSelected = 1;
    }
    else if(this.permissionsService.isSsViewAllowed){
      this.router.navigate(['/crud/2']);
      this.crudSelected = 2;
    }
    else if(this.permissionsService.isF11ViewAllowed){
      this.router.navigate(['/crud/3']);
      this.crudSelected = 3;
    }
    else if(this.permissionsService.isTransformersViewCrudAllowed){
      this.router.navigate(['/crud/4']);
      this.crudSelected = 4;
    }
    else if(this.permissionsService.isAreaViewAllowed){
      this.router.navigate(['/crud/5']);
      this.crudSelected = 5;
    }
    else if(this.permissionsService.isRegionViewAllowed){
      this.router.navigate(['/crud/6']);
      this.crudSelected = 6;
    }
    else if(this.permissionsService.isTariffViewAllowed){
      this.router.navigate(['/crud/7']);
      this.crudSelected = 7;
    }
    else if(this.permissionsService.isMeterTypeViewAllowed){
      this.router.navigate(['/crud/8']);
      this.crudSelected = 8;
    }
    else if(this.permissionsService.isCustomerCrudViewAllowed){
      this.router.navigate(['/crud/9']);
      this.crudSelected = 9;
    }
    else if(this.permissionsService.isAssetRatingViewAllowed){
      this.router.navigate(['/crud/10']);
      this.crudSelected = 10;
    }
    else if(this.permissionsService.isDtMeterTypeViewAllowed){
      this.router.navigate(['/crud/11']);
      this.crudSelected = 11;
    }
    else if(this.permissionsService.isAssetStatusViewAllowed){
      this.router.navigate(['/crud/12']);
      this.crudSelected = 12;
    }
    else if(this.permissionsService.isSltViewAllowed){
      this.router.navigate(['/crud/13']);
      this.crudSelected = 13;
    }
    else if(this.permissionsService.isMeterPriceViewAllowed){
      this.router.navigate(['/crud/14']);
      this.crudSelected = 14;
    }
    else if(this.permissionsService.isBillingAccountsViewAllowed){
      this.router.navigate(['/crud/15']);
      this.crudSelected = 15;
    }
  }

  ngOnDestroy(): void {

    this.searchTextUpdate.complete();
  }

  switchMenuItem(item: CrudMenuItem): void {
    if(item == CrudMenuItem.Customer || item == CrudMenuItem.BillingAccounts) {
      this.initializeSearchByOptions(item);  
    }
    this.searchText = '';
    this.crudSelected = item;
    this.location.replaceState(`/crud/${this.crudSelected}`);
  }

  initializeSearchByOptions(item: CrudMenuItem) {
    if(item == CrudMenuItem.Customer) {
      this.searchByOptions = [
        { name: 'AccountNumber', value: 'Account No.' },
        { name: 'MeterNumber', value: 'Meter No.' },
        { name: 'Slrn', value: 'SLRN' },
        { name: 'BuildingOwner', value: 'Building Owner' },
        { name: 'Address', value: 'Address' }
      ];
    }
    else if(item == CrudMenuItem.BillingAccounts) {
      this.searchByOptions = [
        { name: 'AccountNumber', value: 'Account No.' },
        { name: 'MeterNumber', value: 'Meter No.' },
        { name: 'Itinerary', value: 'Itinerary' }
      ];
    }
  }

  search() : void {
    switch (this.crudSelected) {
      case CrudMenuItem.TS:
        this.crudTsComponent.filter();
        break;
      case CrudMenuItem.MainFeeder:
          this.crudF33Component.filter();
          break;
      case CrudMenuItem.InjectionSubstation:
          this.crudSsComponent.filter();
          break;
      case CrudMenuItem.SubFeeder:
          this.crudF11Component.filter();
          break;
      case CrudMenuItem.DT:
          this.crudDtComponent.filter();
          break;
      case CrudMenuItem.UT:
        this.crudAreaComponent.filter();
        break;
      case CrudMenuItem.BU:
        this.crudRegionComponent.filter();
        break;
      case CrudMenuItem.Tariff:
        this.crudTariffComponent.filter();
        break;
      case CrudMenuItem.MeterType:
        this.crudMeterTypeComponent.filter();
        break;
      case CrudMenuItem.AssetRating:
        this.crudAssetRatingComponent.filter();
        break;
      case CrudMenuItem.MeterPrice:
        this.crudMeterPriceComponent.filter();
        break;
      case CrudMenuItem.AssetStatus:
        this.crudAssetStatusComponent.filter();
        break;
      case CrudMenuItem.Customer:
        setTimeout(() => { //timeout 0 milliseconds, just to have some time to update the utilityId in child component
          this.crudCustomerComponent.filter();
        }, 0);
        break;
      case CrudMenuItem.DtMeterType:
        this.crudDtMeterTypeComponent.filter();
        break;
      case CrudMenuItem.SltStatus:
        this.crudSltComponent.filter();
        break;
      case CrudMenuItem.BillingAccounts:
        this.crudBillingAccountComponent.filter();
        break;
      default:
        break;
    }
  }
  selectSearchType(searchType: any) {    
    this.selectedSearchType = searchType.name;
    this.selectedSearchTypeDisplay = searchType.value;
    this.showSelectSearchType = false;
    this.searchText='';
    this.search();
  }
  selectUtilityChild(e: any) {
    this.selectedUtilityId = parseInt(e.target.value); 
    setTimeout(() => { //timeout 0 milliseconds, just to have some time to update the utilityId in child component
      this.search();
    }, 0);
  }

  getUtilities() {
    this.utilityService.getUtilitiesForSelect().subscribe({
      next: (data) => {
        this.utilityList = data.data;
        this.selectedUtilities = this.utilityList.map(s=>s.key);
      },
      error: (error) => console.log(error.statusText)
    }); 
  }
}
