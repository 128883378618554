import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Area } from 'src/app/models/area.model';
import { DtViewSave } from 'src/app/models/dt.model';
import { Feeder, FeederType } from 'src/app/models/feeder.model';
import { User } from 'src/app/models/user.model';
import { AreaService } from 'src/app/services/area.service';
import { DtService } from 'src/app/services/dt.service';
import { F11Service } from 'src/app/services/f11.service';
import { F33Service } from 'src/app/services/f33.service';
import { UserService } from 'src/app/services/user.service';
import { TranslationService } from 'src/app/services/translation.service';


@Component({
  selector: 'app-add-transformer',
  templateUrl: './add-transformer.component.html',
  styleUrls: ['./add-transformer.component.scss']
})
export class AddTransformerComponent implements OnInit {
  feeder : any;
  area: any;
  dt: DtViewSave = new DtViewSave();
  areas: Area[] = [];
  feeders: Feeder[] = [];
  utName: string = this.translationService.getByKeyFromStorage('UT');




  selectedAreaId: number = 0;
  selectedFeederId: number = 0;
  feederType: FeederType = FeederType.NOT_SELECTED;
  mobileUsers: User[] = [];

  FeederType: FeederType;
  constructor(private dtService: DtService, private areaService: AreaService, private userService: UserService, private toastr: ToastrService, private f33Service: F33Service, private f11Service: F11Service, private translationService: TranslationService) { }

  ngOnInit(): void {
    this.getAreas();
  }

  private getAreas() {
    this.areaService.getAllForSelect().subscribe({
      next: response => {
        const responseData = response.body;
        if(responseData?.status === 200 || responseData?.status === "OK"){
          this.areas = responseData.data;
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: _ => {
          this.toastr.error("Error occured");
        }
    });
  }


  selectOption(e: any){
    this.dt.assignedUserId = +e.target.value;
  }

  handleFormSubmit() {
    this.dtService.save(this.dt).subscribe({
      next: response => {
        const responseData = response.body;
        if(responseData?.status === 200 || responseData?.status === "OK"){
          this.toastr.success(responseData?.message);
          this.dt = new DtViewSave();
          this.selectedAreaId = 0;
          this.selectedFeederId = 0;
          this.feederType = -1;
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: _ => {
        this.toastr.error("Error occured");
      }
    });
  }


  getMobileUsers(areaId: number){
    this.userService.getMobileUsers(areaId).subscribe({
      next: response => {
        const responseData = response.body;
        if(responseData?.status === 200 || responseData?.status === "OK"){
          this.mobileUsers = responseData.data;
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: _ => {
          this.toastr.error("Error occured");
        }
    });
  }
  
  selectArea(e: any) {
    this.selectedAreaId = +e.target.value;
    this.dt.areaId = this.areas.find(x => x.id === this.selectedAreaId)!.id;
    this.getMobileUsers(this.selectedAreaId);
  }


  selectFeederType(e: any) {
    this.feederType = e.target.value;
    this.dt.feederType = e.target.value;
    this.getFeedersByFeederType(+FeederType[this.feederType]);
    this.selectedFeederId = 0;
  }

  private getFeedersByFeederType(type: FeederType) {
    if(type === FeederType.KV11){
      this.getFeeders11();
      return;
    }
    this.getFeeders33();
  }

  
  selectFeeder(e: any) {
    this.selectedFeederId = +e.target.value;
    this.dt.feederId = this.feeders.find(x => x.id === this.selectedFeederId)!.id;
  }

  getFeeders11(){
    this.f11Service.getAllForSelect().subscribe({
      next: response => {
        const responseData = response.body;
        if(responseData?.status === 200 || responseData?.status === "OK"){
          const feedersFromResponse = responseData.data;
          this.feeders = feedersFromResponse.map(
            (el: Feeder) => ({...el, feederType: FeederType.KV11})
          )
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: _ => {
          this.toastr.error("Error occured");
        }
    });
  }

  getFeeders33(){
    this.f33Service.getAllForSelect().subscribe({
      next: response => {
        const responseData = response.body;
        if(responseData?.status === 200 || responseData?.status === "OK"){
          const feedersFromResponse = responseData.data;
          this.feeders = feedersFromResponse.map(
            (el: Feeder) => ({...el, feederType: FeederType.KV33})
          )
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: _ => {
          this.toastr.error("Error occured");
        }
    });
  }

  
}
