export class ChecklistItem {
    property: string = "";
    selectedValue: string = "";
    propertyTranslation: string = "";
    valueTranslation: string = "";

    constructor(property: string, value: string, propertyKey?: string, valueKey?: string) {
        this.property = property;
        this.selectedValue = value;
        this.propertyTranslation = propertyKey != undefined ? propertyKey : property;
        this.valueTranslation = valueKey != undefined ? valueKey : value;
    }
}