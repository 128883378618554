<div class="set-new-password-container">
    <app-login-header></app-login-header>
    <div class="register-container">
    
        <div class="register-page">
          <div class="page-wrap">
            <div class="form-wrapper">
              <h1>Set new password</h1>
      
              <div class="info row">
                <div class="col-3">
                  <svg
                    class="icon-bg"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                    stroke-linejoin="round"
                    stroke-miterlimit="2"
                    height="32"
                    width="32"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="m12.002 2.005c5.518 0 9.998 4.48 9.998 9.997 0 5.518-4.48 9.998-9.998 9.998-5.517 0-9.997-4.48-9.997-9.998 0-5.517 4.48-9.997 9.997-9.997zm0 1.5c-4.69 0-8.497 3.807-8.497 8.497s3.807 8.498 8.497 8.498 8.498-3.808 8.498-8.498-3.808-8.497-8.498-8.497zm0 6.5c-.414 0-.75.336-.75.75v5.5c0 .414.336.75.75.75s.75-.336.75-.75v-5.5c0-.414-.336-.75-.75-.75zm-.002-3c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1z"
                      fill-rule="nonzero"
                    />
                  </svg>
                </div>
                <span class="col-8"
                  >Please ensure the use of a robust password, which may involve a mixture of uppercase 
                   and lowercase letters, numbers, and special characters.</span
                >
              </div>
                <form ngNativeVAlidate>
                    <div class="form-item" style="position: relative">
                        <label for="floatingPassword">New password <span class="red">*</span></label>
                        <input
                            [(ngModel)]="pass"
                            name="password"
                            [type]="showNewPw ? 'text' : 'password'"
                            class="form-control"
                            id="floatingPassword"
                            placeholder="New password"
                            required
                        />
                        <div class="show-new-pw" (click)="showNewPassword()">
                            <ng-template [ngIf]="showNewPw">
                            <img
                                src="../../../assets/icons/eye.svg"
                                alt="Show password icon"
                            />
                            </ng-template>
                            <ng-template [ngIf]="!showNewPw">
                            <img
                                src="../../../assets/icons/eye-slash.svg"
                                alt="Show password icon"
                            />
                            </ng-template>
                        </div>
                        <app-password-strength
                            [passwordToCheck]="pass"
                            (passwordStrength)="onPasswordStrengthChanged($event)"
                        ></app-password-strength>
                    </div>
                    <div class="form-item" style="position: relative">
                        <label for="floatingPassword2">Confirm password <span class="red">*</span></label>
                        <input
                            [(ngModel)]="confirmPass"
                            name="confirmPassword"
                            [type]="showConfirmPw ? 'text' : 'password'"
                            class="form-control"
                            id="floatingPassword2"
                            placeholder="Confirm password"
                            required
                        />
                        <div class="show-confirm-pw" (click)="showConfirmPassword()">
                            <ng-template [ngIf]="showConfirmPw">
                            <img
                                src="../../../assets/icons/eye.svg"
                                alt="Show password icon"
                            />
                            </ng-template>
                            <ng-template [ngIf]="!showConfirmPw">
                            <img
                                src="../../../assets/icons/eye-slash.svg"
                                alt="Show password icon"
                            />
                            </ng-template>
                        </div>
                    </div>
                    <button class="btn btn-dark text-size" type="button" (click)="saveNewPassword()">Save new password</button>
                </form>
            </div>
          </div>
        </div>
      </div>
</div>