export enum ImageType {
  Frontview = 0,
  MountedTag = 1,
  Bill = 2,
  Card = 3,
  NotMatched = 4,
  LTPoleLeft = 5,
  LTPoleRight = 6,
  Location = 7,
  InputCable = 8,
  OutputCable = 9,
  Meter = 10,
  WorkOrder = 11,
  ServiceCableFromPole = 12,
  ExistingMeteringPoint = 13,
  RecommendedMeteringPoint = 14,
  BillOrPaymentReceipts = 15,
  FieldRepSignature = 16,
  CustomerSignature = 17,
  CutOutFuse = 18,
  UtilitySupply = 19,
  MeterNumber = 20,
  ProfilePicture = 21,
  NameplatePicture = 22,
  FrontInstallationImage = 23,
  CustomerBillImage = 24,
  ProposedMeterPointBeforeInstallationImage = 25,
  InstallationInProgressImage = 26,
  SealImage = 27,
  InstallationWithSealImage = 28,
  MeterCardImage = 29,
  FullViewOfSupplyImage = 30,
  SupplyCableImage = 31,
  RetrievedMeterImage = 32,
  MonitorUIUImage = 33,
  CardBackViewImage = 34,
  Maintenance = 35,
  Exception = 36,
  DecommissionMeterSlrn = 37,
  ReadingOnMeter = 38,
}

export enum EntityType {
  Building,
  Pole,
  Dt,
  Meter,
  Customer,
}

export interface Image {
  imageType: ImageType;
  url: string;
}
