import { Component, Input, OnInit } from '@angular/core';
import { History } from 'src/app/models/customer.model';

@Component({
  selector: 'app-history-tracker',
  templateUrl: './history-tracker.component.html',
  styleUrls: ['./history-tracker.component.scss']
})
export class HistoryTrackerComponent implements OnInit {
  @Input() histories: History[];

  constructor() { }

  ngOnInit(): void {
  }

}
