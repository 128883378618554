import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from '../config/config';
import { ResponsePackage } from '../models/util.model';
import { Role } from '../models/role.model';

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  private pathApi = this.config.setting['pathApi'] + "role/";
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  constructor(private http: HttpClient, private config: AppConfig) { }

  getAll(onlyEnabled: boolean = false) { //Add true if you want to return only Enabled roles
    return this.http.get<ResponsePackage<any>>(`${this.pathApi}getAll/${onlyEnabled}`, { headers: this.headers, observe: 'response' });
  }
  delete(id:number) {
    return this.http.get<ResponsePackage<any>>(`${this.pathApi}delete/`+id, { headers: this.headers, observe: 'response' });
  }

  save(obj: Role) {
    return this.http.post<ResponsePackage<string>>(`${this.pathApi}save`, obj, { headers: this.headers, observe: 'response' });
  }

  getPermissions() {
    return this.http.get<ResponsePackage<any>>(`${this.pathApi}getPermissions`, { headers: this.headers, observe: 'response' });
  }
}
