import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class KycAuthGuard implements CanActivate {
  constructor( 
    private toastr: ToastrService,
    private authService: AuthService,
    private router: Router ) {  }

    canActivate(route: ActivatedRouteSnapshot) {
    //   if (this.authService.hasUtility()){
    //     this.toastr.error("You don't have permission.");
    //     this.router.navigate(['']);
    //     return false;
    //   }
      
      if (!this.authService.isLoggedIn()) 
      {
        this.toastr.error("You don't have permission.");
        return false;
      }
      var admin = localStorage.getItem('isAdmin');
      if (admin != '0') {
        return false;
      }
       return true;
    }
}
