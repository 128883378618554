import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ChecklistItem } from 'src/app/models/checklist-item.model';

@Component({
  selector: 'app-filter-checklist',
  templateUrl: './filter-checklist.component.html',
  styleUrls: ['./filter-checklist.component.scss']
})
export class FilterChecklistComponent implements OnInit {

  @Input() filters: ChecklistItem[];
  @Input() useApply: boolean = false;
  @Output() resetFilterEmitter = new EventEmitter();
  @Output() removeFilterEmitter = new EventEmitter();
  @Output() applyFilterEmitter = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  resetFilter() {
    this.filters = [];
    this.resetFilterEmitter.emit(this.filters);
  }

  removeFilter(e: any) {
    this.removeFilterEmitter.emit(e);
  }

  applyFilter() {
    this.applyFilterEmitter.emit(true);
  }
}
