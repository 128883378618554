
  <div class="page-header d-flex justify-content-end">
    <a class="btn btn-outline-danger" [routerLink]="['/profile']" >{{'COMMON.CANCEL' | translate}}</a>
    <button class="btn btn-primary" form="userForm" style="width: auto;">{{'COMMON.SAVE' | translate}}</button>
  </div>
  <div class="container-fluid page edit-profile">
    <div class="page-title">
      <p>{{'COMMON.EDIT' | translate}} {{'SIDENAV.PROFILE' | translate | lowercase}}</p>
      <div class="row">
        <div class="col-md-4">
          <div class="card card-div">
            <form ngNativeValidate class="form-wrapper" enctype="multipart/form-data" id="userForm" (submit)="handleUserFormSubmit()">
              <div *ngIf="!hasImage; else image" class="drag-drop" appDragAndDrop (fileDropped)="onFileDropped($event)">
                <input type="file" id="fileDropRef" name="userImage" #userImage accept="image/png, image/jpg, image/jpeg" (change)="fileBrowserHandler($event)"/>
                <div class="upload-img">
                  <img src="../../assets/icons/upload-arrow.svg" alt="Upload icon"/>
                </div>
                <label for="fileDropRef"
                  >{{ "COMMON.DRAG_MESSAGE" | translate
                  }}<span class="browse">{{ "COMMON.BROWSE" | translate }}</span>
                  {{ "COMMON.TO" | translate | lowercase }}
                  {{ "COMMON.UPLOAD" | translate | lowercase }}</label
                >
              </div>
              <ng-template #image>
                <div class="pb-4">
                  <img style="max-width: 100%;" src="{{ blobUrl || imageSrc }}" alt="Uploaded image"/>
                  <span (click)="removeImage()">x</span>
                </div>
              </ng-template>

              <div class="form-group">
                <div class="form-item form-floating">
                  <input class="w-100 form-control" type="text" name="firstName" id="floatingInput" [value]="loggedUser.firstName" [(ngModel)]="loggedUser.firstName" placeholder=" " required>
                  <label for="floatingInput">{{'COMMON.USER.FIRST_NAME' | translate}}</label>
                </div>
                <div class="form-item form-floating">
                  <input class="w-100 form-control" type="text" name="lastName" id="floatingInput2" [value]="loggedUser.lastName" [(ngModel)]="loggedUser.lastName" placeholder=" " required>
                  <label for="floatingInput">{{'COMMON.USER.LAST_NAME' | translate}}</label>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>