<div class="root-upload">
    <div class="download-sample-root">
        <div class="right">
            <div class="right-outer">
                <div>Sample File</div>
            </div>
            <div>Download the sample file here to populate the customers data.</div>
        </div>
        <div class="left clickable"  (click)="generateTemplate()">
            <div>Download sample here</div>
            <div><img src="../../../../assets/icons/download-arrow.svg" alt="Download arrow" /></div>
        </div>
    </div>
    <div class="upload-root">
        <form ngNativeValidate class="form-wrapper clickable" enctype="multipart/form-data" id="userForm" (submit)="handleUploadCustomersForm()">
            <div class="drag-drop" appDragAndDrop (fileDropped)="onFileDropped($event)">
                <input type="file" id="fileDropRef" name="userImage" (change)="fileBrowserHandler($event)"/>
                <div class="upload-img">
                <img src="../../assets/icons/upload-arrow.svg" alt="Upload arrow" />
                </div>
                <label for="fileDropRef">Drag and drop file here or <span class="browse">Browse</span> to upload</label>
            </div>
            <div class="file-name">{{ this.file }}</div>
        </form>
    </div>
    <div class="card">
        <div class="stats-header">
            <div class="form-item mb-3">
                <select name="action">
                    <option value="" [selected]="action === ''" disabled>Action</option>
                    <option>Tip1</option>
                    <option>Tip2</option>
                </select>   
            </div>
        </div>

        <div class="stats-body">
            <table class="table uploads">
                <thead class="table-light">
                  <tr>
                    <th><input type="checkbox" class="form-check-input"></th>
                    <th>Upload ID</th>
                    <th>Date</th>
                    <th>No. of entries.</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let upload of uploads">
                    <td><input type="checkbox" class="form-check-input"></td>
                    <td>{{upload.id}}</td>
                    <td>{{upload.lastUpdateTime | date: 'd MMMM Y, hh:mm a'}}</td>
                    <td>
                        <div class="success-bar">
                            <progress-bar-angular class="bar" [barColor]="getUploadStatusColor(upload.uploadFileStatus)" [progress]="((upload.numberOfEntries - upload.numberOfErrors)/upload.numberOfEntries)*100" borderRadius="full" width="10rem" height="1rem" backgroundColor="rgba(226, 183, 152, 0.2)" mode='determinate'></progress-bar-angular>
                            <span class="numbers"> {{ upload.numberOfEntries - upload.numberOfErrors }}/ {{upload.numberOfEntries}} </span>
                        </div>
                    </td>
                    <td>
                        <span class="status-type" [ngClass]="upload.uploadFileStatus | lowercase">
                            <div class="dot" [ngClass]="upload.uploadFileStatus | lowercase"></div>
                            {{upload.uploadFileStatus}}
                        </span>
                    </td>
                    <td class="more-icon">
                        <img src="../../assets/icons/more-icon.svg" alt="More icon" class="more-icon">
                    </td>
                  </tr>
                </tbody>
            </table>
            <div>
                <app-paging *ngIf="count > 0" [pageSize]="pageSize" [count]="count" [currentPage]="currentPage" (pageChange)="pageChange($event)"></app-paging>
              </div>
        </div>
    </div>
</div>