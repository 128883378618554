import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HasValuePipe } from 'src/app/pipes/has-value.pipe';
import { NumberCompactPipe } from 'src/app/pipes/number-compact.pipe';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputFieldComponent } from './components/input-field/input-field.component';
import { SingleSelectDropdownComponent } from './components/single-select-dropdown/single-select-dropdown.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { DatepickerFieldComponent } from './components/datepicker-field/datepicker-field.component';
import { InternationalPhoneComponent } from './components/international-phone/international-phone.component';
import { ProgressIndicatorComponent } from './components/progress-indicator/progress-indicator.component';
import { InputLabelComponent } from './components/input-label/input-label.component';
import { ProgressCountComponent } from './components/progress-count/progress-count.component';
import { KycHeaderComponent } from './components/kyc-header/kyc-header.component';
import { CheckboxCustomComponent } from './components/checkbox-custom/checkbox-custom.component';
import { TwoOptionRadioButtonComponent } from './components/two-option-radio-button/two-option-radio-button.component';
import { UploadBarComponent } from './components/upload-bar/upload-bar.component';
import { RegularCheckboxComponent } from './components/regular-checbox/regular-checkbox.component';
import { CustomRadioOptionsComponent } from './components/custom-radio-options/custom-radio-options.component';
import { ModalComponent } from './components/modal/modal.component';
import { AgreementDialogComponent } from './components/modal/modal-contents/agreement-dialog/agreement-dialog.component';
import { RegularTabsComponent } from './components/regular-tabs/regular-tabs.component';
import { HistoriesHasValuePipe } from 'src/app/pipes/histories-has-value.pipe';
import { CheckIfEmptyPipe } from './_pipes/check-if-empty.pipe';
import { SingleSelectAreaDropdownComponent } from './components/single-select-area-dropdown/single-select-area-dropdown.component';
import { YearPipe } from 'src/app/pipes/year.pipe';

@NgModule({
  declarations: [
    NumberCompactPipe,
    HasValuePipe,
    InputFieldComponent,
    SingleSelectDropdownComponent,
    DatepickerFieldComponent,
    InternationalPhoneComponent,
    ProgressIndicatorComponent,
    InputLabelComponent,
    ProgressCountComponent,
    KycHeaderComponent,
    CheckboxCustomComponent,
    TwoOptionRadioButtonComponent,
    UploadBarComponent,
    RegularCheckboxComponent,
    CustomRadioOptionsComponent,
    ModalComponent,
    AgreementDialogComponent,
    RegularTabsComponent,
    HistoriesHasValuePipe,
    CheckIfEmptyPipe,
    SingleSelectAreaDropdownComponent,
    YearPipe
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgxIntlTelInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    FormsModule
  ],
  exports: [
    NumberCompactPipe,
    HasValuePipe,
    HttpClientModule,
    FormsModule,
    InputFieldComponent,
    SingleSelectDropdownComponent,
    ReactiveFormsModule,
    NgxIntlTelInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    DatepickerFieldComponent,
    InternationalPhoneComponent,
    CommonModule,
    ProgressIndicatorComponent,
    InputLabelComponent,
    ProgressCountComponent,
    KycHeaderComponent,
    CheckboxCustomComponent,
    TwoOptionRadioButtonComponent,
    UploadBarComponent,
    RegularCheckboxComponent,
    CustomRadioOptionsComponent,
    ModalComponent,
    RegularTabsComponent,
    HistoriesHasValuePipe,
    CheckIfEmptyPipe,
    SingleSelectAreaDropdownComponent,
    YearPipe
  ],
})
export class SharedModule {}
