<div class="cruds">
  <div class="cruds-column">
    <nav class="sidenav">
      <div class="sidenav-items">
        <div *ngIf="isBpsAdmin" class="utilityFilter">
          <select
            name="utility-select"
            (change)="selectUtilityChild($event)"
            class="form-select"
          >
            <option
              value=""
              [selected]="selectedUtilityId == 0"
              [disabled]="true"
              value="0"
            >
              All
            </option>
            <option *ngFor="let u of utilityList" value="{{ u.key }}">
              {{ u.value }}
            </option>
          </select>
        </div>
        <div class="crud-search">
          <input
            class="search-input"
            type="text"
            placeholder="{{ 'COMMON.SEARCH' | translate }}"
            [(ngModel)]="searchText"
            (keydown.enter)="search()"
            (ngModelChange)="this.searchTextUpdate.next($event)"
          />
          <div class="search-wrapper" (click)="search()">
            <svg
              width="19"
              height="19"
              viewBox="0 0 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M14.3952 12.9761C16.852 9.8209 16.63 5.25612 13.7295 2.3556C10.5887 -0.785201 5.49642 -0.785201 2.35561 2.3556C-0.785204 5.49642 -0.785204 10.5887 2.35561 13.7295C5.25614 16.63 9.82096 16.8519 12.9762 14.3952C12.9897 14.4106 13.0039 14.4257 13.0186 14.4403L17.2838 18.7055C17.6764 19.0982 18.313 19.0982 18.7056 18.7055C19.0981 18.3129 19.0981 17.6764 18.7056 17.2838L14.4404 13.0186C14.4256 13.0039 14.4105 12.9898 14.3952 12.9761ZM12.2426 3.75737C14.5858 6.10051 14.5858 9.89947 12.2426 12.2427C9.8995 14.5858 6.1005 14.5858 3.75736 12.2427C1.41421 9.89947 1.41421 6.10051 3.75736 3.75737C6.1005 1.41421 9.8995 1.41421 12.2426 3.75737Z"
                fill="#8F92A1"
              />
            </svg>
          </div>
        </div>
        <div
          *ngIf="
            crudSelected == CrudMenuItem.Customer ||
            crudSelected == CrudMenuItem.BillingAccounts
          "
          class="search-type-picker"
        >
          <div
            class="dropdown"
            (click)="showSelectSearchType = !showSelectSearchType"
            click-stop-propagation
          >
            <span>By:</span>
            <span>{{ selectedSearchTypeDisplay }}</span>
            <span class="arrow"
              ><img
                src="../../assets/icons/gray-arrow-down.svg"
                alt="Gray arrow down"
            /></span>
          </div>
          <div class="dropdown-opened" *ngIf="showSelectSearchType">
            <div
              (click)="selectSearchType(searchType)"
              *ngFor="let searchType of searchByOptions"
            >
              <div>{{ searchType.value }}</div>
            </div>
          </div>
        </div>
        <a
          class="nav-item"
          (click)="switchMenuItem(CrudMenuItem.TS)"
          *ngIf="permissionsService.isTsViewAllowed"
          [ngClass]="{ 'item-active': crudSelected === CrudMenuItem.TS }"
        >
          <span>{{ "COMMON.TS.SINGLE" | translate }}</span>
        </a>
        <a
          class="nav-item"
          (click)="switchMenuItem(CrudMenuItem.MainFeeder)"
          *ngIf="
            permissionsService.isF33ViewAllowed &&
            permissionsService.hasDashboardConfigFeeders
          "
          [ngClass]="{
            'item-active': crudSelected === CrudMenuItem.MainFeeder
          }"
        >
          <span>{{ "COMMON.MBC_DETAILS.MAIN_FEEDER" | translate }}</span>
        </a>
        <a
          class="nav-item"
          (click)="switchMenuItem(CrudMenuItem.InjectionSubstation)"
          *ngIf="
            permissionsService.isSsViewAllowed &&
            permissionsService.hasDashboardConfigFeeders
          "
          [ngClass]="{
            'item-active': crudSelected === CrudMenuItem.InjectionSubstation
          }"
        >
          <span>{{ "COMMON.SS.SINGLE" | translate }}</span>
        </a>
        <a
          class="nav-item"
          (click)="switchMenuItem(CrudMenuItem.SubFeeder)"
          *ngIf="
            permissionsService.isF11ViewAllowed &&
            permissionsService.hasDashboardConfigFeeders
          "
          [ngClass]="{ 'item-active': crudSelected === CrudMenuItem.SubFeeder }"
        >
          <span>{{ "COMMON.MBC_DETAILS.SUB_FEEDER" | translate }}</span>
        </a>
        <a
          class="nav-item"
          (click)="switchMenuItem(CrudMenuItem.DT)"
          [ngClass]="{ 'item-active': crudSelected === CrudMenuItem.DT }"
          *ngIf="
            permissionsService.isTransformersViewCrudAllowed &&
            permissionsService.hasDashboardConfigDTS
          "
        >
          <span>{{ "TRANSFORMERS.FULL_NAME" | translate }}</span>
        </a>
        <a
          class="nav-item"
          (click)="switchMenuItem(CrudMenuItem.UT)"
          *ngIf="permissionsService.isAreaViewAllowed"
          [ngClass]="{ 'item-active': crudSelected === CrudMenuItem.UT }"
        >
          <span>{{ "COMMON.UT.SINGLE" | translate }}</span>
        </a>
        <a
          class="nav-item"
          (click)="switchMenuItem(CrudMenuItem.BU)"
          *ngIf="permissionsService.isRegionViewAllowed"
          [ngClass]="{ 'item-active': crudSelected === CrudMenuItem.BU }"
        >
          <span>{{ "COMMON.BU.SINGLE" | translate }}</span>
        </a>
        <a
          class="nav-item"
          (click)="switchMenuItem(CrudMenuItem.Tariff)"
          *ngIf="permissionsService.isTariffViewAllowed"
          [ngClass]="{ 'item-active': crudSelected === CrudMenuItem.Tariff }"
        >
          <span>{{ "COMMON.TARIFF.SINGLE" | translate }}</span>
        </a>
        <a
          class="nav-item"
          (click)="switchMenuItem(CrudMenuItem.MeterType)"
          *ngIf="permissionsService.isMeterTypeViewAllowed"
          [ngClass]="{ 'item-active': crudSelected === CrudMenuItem.MeterType }"
        >
          <span>{{ "COMMON.MBC_DETAILS.METER_TYPE.SINGLE" | translate }}</span>
        </a>
        <a
          class="nav-item"
          (click)="switchMenuItem(CrudMenuItem.AssetRating)"
          *ngIf="permissionsService.isAssetRatingViewAllowed"
          [ngClass]="{
            'item-active': crudSelected === CrudMenuItem.AssetRating
          }"
        >
          <span>{{ "COMMON.ASSET_RATING.SINGLE" | translate }}</span>
        </a>
        <a
          class="nav-item"
          (click)="switchMenuItem(CrudMenuItem.AssetStatus)"
          *ngIf="permissionsService.isAssetStatusViewAllowed"
          [ngClass]="{
            'item-active': crudSelected === CrudMenuItem.AssetStatus
          }"
        >
          <span>{{ "COMMON.ASSET_STATUS.SINGLE" | translate }}</span>
        </a>
        <a
          class="nav-item"
          (click)="switchMenuItem(CrudMenuItem.Customer)"
          *ngIf="
            permissionsService.isCustomerCrudViewAllowed &&
            permissionsService.hasDashboardConfigBuildings
          "
          [ngClass]="{ 'item-active': crudSelected === CrudMenuItem.Customer }"
        >
          <span>{{ "CRUD_SETTINGS.CUSTOMER_ACCOUNTS" | translate }}</span>
        </a>
        <a
          class="nav-item"
          (click)="switchMenuItem(CrudMenuItem.DtMeterType)"
          *ngIf="permissionsService.isDtMeterTypeViewAllowed"
          [ngClass]="{
            'item-active': crudSelected === CrudMenuItem.DtMeterType
          }"
        >
          <span>{{ "COMMON.DT_METER_TYPE.SINGLE" | translate }}</span>
        </a>
        <a
          class="nav-item"
          (click)="switchMenuItem(CrudMenuItem.SltStatus)"
          *ngIf="permissionsService.isSltViewAllowed"
          [ngClass]="{ 'item-active': crudSelected === CrudMenuItem.SltStatus }"
        >
          <span>{{ "COMMON.SLT_STATUS.SINGLE" | translate }}</span>
        </a>
        <a
          class="nav-item"
          (click)="switchMenuItem(CrudMenuItem.MeterPrice)"
          *ngIf="permissionsService.isMeterPriceViewAllowed"
          [ngClass]="{
            'item-active': crudSelected === CrudMenuItem.MeterPrice
          }"
        >
          <span>{{ "COMMON.METER_PRICE.SINGLE" | translate }}</span>
        </a>
        <a
          class="nav-item"
          (click)="switchMenuItem(CrudMenuItem.BillingAccounts)"
          *ngIf="
            permissionsService.isBillingAccountsViewAllowed &&
            permissionsService.hasDashboardConfigReadings
          "
          [ngClass]="{
            'item-active': crudSelected === CrudMenuItem.BillingAccounts
          }"
        >
          <span>{{ "COMMON.BILLING_ACCOUNTS.SINGLE" | translate }}</span>
        </a>
      </div>
    </nav>
  </div>
  <div class="selected-crud">
    <ng-container [ngSwitch]="crudSelected">
      <crud-ts
        *ngSwitchCase="CrudMenuItem.TS"
        [searchText]="searchText"
        [utilityId]="selectedUtilityId"
        [bpsAdminLevel]="bpsAdminLevel"
      ></crud-ts>
      <crud-f33
        *ngSwitchCase="CrudMenuItem.MainFeeder"
        [searchText]="searchText"
        [utilityId]="selectedUtilityId"
        [bpsAdminLevel]="bpsAdminLevel"
      ></crud-f33>
      <crud-ss
        *ngSwitchCase="CrudMenuItem.InjectionSubstation"
        [searchText]="searchText"
        [utilityId]="selectedUtilityId"
        [bpsAdminLevel]="bpsAdminLevel"
      ></crud-ss>
      <crud-f11
        *ngSwitchCase="CrudMenuItem.SubFeeder"
        [searchText]="searchText"
        [utilityId]="selectedUtilityId"
        [bpsAdminLevel]="bpsAdminLevel"
      ></crud-f11>
      <crud-dt
        *ngSwitchCase="CrudMenuItem.DT"
        [searchText]="searchText"
        [utilityId]="selectedUtilityId"
        [bpsAdminLevel]="bpsAdminLevel"
      ></crud-dt>
      <crud-area
        *ngSwitchCase="CrudMenuItem.UT"
        [searchText]="searchText"
        [utilityId]="selectedUtilityId"
        [bpsAdminLevel]="bpsAdminLevel"
      ></crud-area>
      <crud-region
        *ngSwitchCase="CrudMenuItem.BU"
        [searchText]="searchText"
        [utilityId]="selectedUtilityId"
        [bpsAdminLevel]="bpsAdminLevel"
      ></crud-region>
      <crud-tariff
        *ngSwitchCase="CrudMenuItem.Tariff"
        [searchText]="searchText"
        [utilityId]="selectedUtilityId"
        [bpsAdminLevel]="bpsAdminLevel"
      ></crud-tariff>
      <crud-meter-type
        *ngSwitchCase="CrudMenuItem.MeterType"
        [searchText]="searchText"
        [utilityId]="selectedUtilityId"
        [bpsAdminLevel]="bpsAdminLevel"
      ></crud-meter-type>
      <crud-asset-rating
        *ngSwitchCase="CrudMenuItem.AssetRating"
        [searchText]="searchText"
        [utilityId]="selectedUtilityId"
        [bpsAdminLevel]="bpsAdminLevel"
      ></crud-asset-rating>
      <crud-asset-status
        *ngSwitchCase="CrudMenuItem.AssetStatus"
        [searchText]="searchText"
        [utilityId]="selectedUtilityId"
        [bpsAdminLevel]="bpsAdminLevel"
      ></crud-asset-status>
      <crud-customer
        *ngSwitchCase="CrudMenuItem.Customer"
        [searchType]="selectedSearchType"
        [searchText]="searchText"
        [utilityId]="selectedUtilityId"
        [bpsAdminLevel]="bpsAdminLevel"
      ></crud-customer>
      <crud-dt-meter-type
        *ngSwitchCase="CrudMenuItem.DtMeterType"
        [searchText]="searchText"
        [utilityId]="selectedUtilityId"
        [bpsAdminLevel]="bpsAdminLevel"
      ></crud-dt-meter-type>
      <crud-slt-status
        *ngSwitchCase="CrudMenuItem.SltStatus"
        [searchText]="searchText"
        [utilityId]="selectedUtilityId"
        [bpsAdminLevel]="bpsAdminLevel"
      ></crud-slt-status>
      <crud-meter-price
        *ngSwitchCase="CrudMenuItem.MeterPrice"
        [searchText]="searchText"
        [utilityId]="selectedUtilityId"
        [bpsAdminLevel]="bpsAdminLevel"
      ></crud-meter-price>
      <crud-billing-account
        *ngSwitchCase="CrudMenuItem.BillingAccounts"
        [searchType]="selectedSearchType"
        [searchText]="searchText"
        [utilityId]="selectedUtilityId"
        [bpsAdminLevel]="bpsAdminLevel"
      ></crud-billing-account>
    </ng-container>
  </div>
</div>
