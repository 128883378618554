import { AdditionalInfo } from "./additionalInfo.model";

export class MapFilterDataOut {
    public id: number = 0;
    public latitude: number = 0;
    public longitude: number = 0;
    public type: string = "";
    public additionalInfo: AdditionalInfo;
    public buildingCustomers: [] = [];
    public count: number = 0;

    constructor(data?: any) {
        this.id = data?.id ?? 0;
        this.latitude = data?.latitude ?? 0;
        this.longitude = data?.longitude ?? 0;
        this.type = data?.type ?? '';
        this.additionalInfo = new AdditionalInfo(data?.additionalInfo);
        this.buildingCustomers = data?.buildingCustomers ?? [];
        this.count = data?.count ?? 0;
    }
}