import { MapEntityType } from "../enums/map-entity-type.enum";

export class MapFilter {
    constructor(
        public Assets: SelectItem[] = [],
        public BuID: number = 0,
        public SearchValue: string = "",
        
        public NorthEastLat: number = 0,
        public NorthEastLng: number = 0,
        public SouthWestLat: number = 0,
        public SouthWestLng: number = 0,
        public Zoom: number = 0,
        public assetsToShow: number[] = [],
        public mapEntityType:MapEntityType = MapEntityType.CUSTOMER
    ){}
}

export class SelectItem
{
    constructor(
        public id : string = "",
        public name: string = "",
        public Label: string = "",
        public Value: number = 0,
        public checked : boolean = false,
        public isSelectable: boolean = false) {}
}