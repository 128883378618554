import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WorkOrderMenuItem } from 'src/app/models/workOrderMenuItem.model';
import { UtilityService } from 'src/app/services/utility.service';
import { SiteVerificationListComponent } from './site-verification/site-verification-list/site-verification-list.component';
import {Location} from '@angular/common'; 
import { PermissionsEnum } from 'src/app/models/role.model';
import { Subject } from 'rxjs/internal/Subject';
import { debounceTime } from 'rxjs';
import { PermissionsService } from 'src/app/services/permissions.service';

@Component({
  selector: 'app-work-orders',
  templateUrl: './work-orders.component.html',
  styleUrls: ['./work-orders.component.scss']
})
export class WorkOrdersComponent implements OnInit {
  selectedUtilities: number[] = [];
  utilityList: any[];
  selectedUtilityId: number = 0;
  isBpsAdmin: boolean = false;
  public WorkOrderMenuItem = WorkOrderMenuItem;
  workOrderSelected: WorkOrderMenuItem = WorkOrderMenuItem.SiteVerification;

  public searchText: string = '';
  public PermissionsEnum = PermissionsEnum;
  searchTextUpdate = new Subject<string>();
  workOrderType: string = "";

  @ViewChild(SiteVerificationListComponent)siteVerificationListComponent!: SiteVerificationListComponent;
  constructor(private router : Router,
    private route: ActivatedRoute, 
    private location: Location, 
    public permissionsService: PermissionsService,
    private utilityService: UtilityService) { }

  ngOnInit(): void {

    var utilityId = parseInt(localStorage.getItem('utilityId')!);
    if(utilityId != 0) {
      this.selectedUtilityId = utilityId;
    }
    if(!this.permissionsService.hasUtility) {
      this.isBpsAdmin = true;
      this.getUtilities();
    }
    else {

      this.workOrderType = this.route.snapshot.paramMap.get('id') || "";
      if(this.workOrderType != ""){
        this.workOrderSelected = Number(this.workOrderType);
      }
      this.searchTextUpdate.pipe(
        debounceTime(500))
        .subscribe(value => {
          if(this.searchText=="")
            this.search();
        });
      
    }
  }

  getUtilities() {
    this.utilityService.getUtilitiesForSelect().subscribe({
      next: (data) => {
        this.utilityList = data.data;
        this.selectedUtilities = this.utilityList.map(s=>s.key);
      },
      error: (error) => console.log(error.statusText)
    }); 
  }
  selectUtilityChild(e: any) {
    this.selectedUtilityId = parseInt(e.target.value); 
    setTimeout(() => { //timeout 0 milliseconds, just to have some time to update the utilityId in child component
      this.search();
    }, 0);
  }

  switchMenuItem(item: WorkOrderMenuItem): void {
    this.searchText = '';
    this.workOrderSelected = item;
    this.location.replaceState(`work-orders/tab/${this.workOrderSelected}`);
  }
  search() : void {
    switch (this.workOrderSelected) {
      case WorkOrderMenuItem.SiteVerification:
        //this.siteVerificationListComponent.filter();
        break;
      default:
        break;
    }
  }
}
