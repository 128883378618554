import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from '../config/config';
import { Observable } from 'rxjs';
import { AssetStatus } from '../models/assetStatus.model';

@Injectable({
  providedIn: 'root'
})
export class AssetStatusService {
  
  private pathApi = this.config.setting['pathApi'] + "assetStatus/";

  constructor(private http: HttpClient, private config: AppConfig) { }

  getAll(dataIn: any): Observable<any> {
    return this.http.post(`${this.pathApi}getAll`, dataIn);
  }

  save(assetStatus: AssetStatus): Observable<any> {
    return this.http.post(`${this.pathApi}save`, assetStatus);
  }

  delete(id: number): Observable<any> {
    return this.http.delete(`${this.pathApi}delete/${id}`);
  }
}
