import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as intlTelInput from 'intl-tel-input';
import { ToastrService } from 'ngx-toastr';
import { UtilityBasicInfo } from 'src/app/models/utility.model';
import { AccountKycService } from 'src/app/services/kyc/account-kyc.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  @ViewChild('telInput') telInput: any;
  @Input() phoneNumber = '';
  @Input() cssClass = 'form-control';
  @Output() phoneNumberChange = new EventEmitter<string>();
  @ViewChild('closeModal') closeModal: ElementRef;
  @ViewChild('openRegisterSuccessModal') openRegisterSuccessModal: ElementRef;

  iti: any;
  isInvalid = false;
  selectedCountryCode: any;
  constructor(
    private accountService: AccountKycService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private utilityService: UtilityService
  ) {}
  strongPassword = false;
  accountNumber: string | null;
  email: string;
  showPw: boolean = false;
  pass: string = '';
  validAccNo = false;
  invalidAccNo = false;
  customerId?: number | null;
  customerName: string = '';
  typeOfCustomer?: number;
  utility: UtilityBasicInfo;
  ngOnInit() : void {
    var utilityRoute = this.route.snapshot.paramMap.get('utility') || '';

    if (utilityRoute) {
      this.tryGetUtilityByName(utilityRoute.replace(new RegExp("-", "g"), " "));
    } else this.router.navigate(['']);
  }
  ngAfterViewInit() {
  }
  ngOnChanges(changes: SimpleChanges) {}
  ngOnDestroy() {
    if(this.iti) {
      this.iti.destroy();
    }
  }

  onFocus = () => {
    if (this.phoneNumber == undefined || this.phoneNumber == '') {
      var getCode = this.iti.getSelectedCountryData().dialCode;
      this.phoneNumber = '+' + getCode;
    }
  };

  onBlur = () => {
    this.isInvalid = false;
    if (this.phoneNumber != undefined && this.phoneNumber.trim()) {
      if (this.iti.isValidNumber()) {
        this.isInvalid = false;
      } else {
        this.isInvalid = true;
      }
    }
  };

  onPasswordStrengthChanged(event: boolean) {
    this.strongPassword = event;
  }
  onInputKeyPress = (event: KeyboardEvent) => {
    const allowedChars = /[0-9\+\-\ ]/;
    const allowedCtrlChars = /[axcv]/; // Allows copy-pasting
    const allowedOtherKeys = [
      'ArrowLeft',
      'ArrowUp',
      'ArrowRight',
      'ArrowDown',
      'Home',
      'End',
      'Insert',
      'Delete',
      'Backspace',
    ];

    if (
      !allowedChars.test(event.key) &&
      !(event.ctrlKey && allowedCtrlChars.test(event.key)) &&
      !allowedOtherKeys.includes(event.key)
    ) {
      event.preventDefault();
    }
  };

  formatIntlTelInput() {
    if (typeof intlTelInputUtils !== 'undefined') {
      // utils are lazy loaded, so must check
      var currentText = this.iti.getNumber(intlTelInputUtils.numberFormat.E164);
      if (typeof currentText === 'string') {
        // sometimes the currentText is an object :)
        this.iti.setNumber(currentText); // will autoformat because of formatOnDisplay=true
      }
    }
  }

  register() {
    if(this.typeOfCustomer == 2) {
      if (this.accountNumber != '' && this.accountNumber != null) {
        if (!this.validAccNo) {
          this.toastr.warning(
            'Please enter a valid Account number.'
          );
          return;
        }
      }
      else {
        this.toastr.warning('Account number is mandatory!')
        return;
      }
    }
    if(!this.email) {
      this.toastr.warning('Email is mandatory!');
      return;
    }
    else {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
   
      if (!emailRegex.test(this.email)) {
        this.toastr.warning("Invalid Email format.");
        return;
      }
    }
    if (this.pass?.length < 8) {
      this.toastr.warning('Password must be at least 8 characters');
      return;
    }
    if (this.pass?.search(/[a-z]/i) < 0) {
      this.toastr.warning('Password must contain at least one letter.');
      return;
    }
    if (this.pass?.search(/[0-9]/) < 0) {
      this.toastr.warning('Password must contain at least one digit.');
      return;
    }
    if (this.pass?.search(/[$&+,:;=?@#|'<>.^*()%!-]/) < 0) {
      this.toastr.warning(
        "Password must contain at least one special character ($&+,:;=?@#|'<>.^*()%!-)."
      );
      return;
    }
    if(this.email) {
      let userId = this.email.split('@')[0];
      if (this.pass.toLowerCase().includes(userId.toLowerCase())) {
        this.toastr.warning('Your password must not contain your email.');
        return;
      }
    }
    
    if (!this.iti.isValidNumber()) {
      this.toastr.warning('Please enter a valid phone number.');
      return;
    }
    const data = this.iti.getSelectedCountryData();

    var obj = {
      accountNumber: this.accountNumber,
      password: this.pass,
      email: this.email,
      utility: this.utility.id, 
      phoneNumber: this.phoneNumber,
      dialCode: data.dialCode,
      customerId: this.customerId
    };
    this.accountService.register(obj).subscribe({
      next: (response) => {
        const responseData = response.body;

        if (
          (responseData?.status === 200 || responseData?.status === 'OK') &&
          responseData?.data != undefined
        ) {
          this.openRegisterSuccessModal?.nativeElement?.click();
          this.accountNumber = null;
          this.phoneNumber = '';
          this.customerName = '';
          this.pass = '';
          this.email = '';
        } else {
          this.toastr.error(responseData?.message);
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }

  checkAccountNumber() {
    var obj = {
      utilityId: this.utility.id,
      accountNumber: this.accountNumber,
    };
    if (this.accountNumber == '' || this.accountNumber == null) {
      this.validAccNo = false;
      this.invalidAccNo = false;
      this.accountNumber = null;
      this.customerId = null;
      return;
    }
    this.accountService.checkAccountNumber(obj).subscribe({
      next: (response) => {
        const responseData = response.body;
        if (
          (responseData?.status === 200 || responseData?.status === 'OK') &&
          responseData?.data != undefined
        ) {
          this.validAccNo = true;
          this.invalidAccNo = false;
          this.customerId = responseData.data.id;
          this.customerName = responseData.data.tenantName;
        } else {
          this.invalidAccNo = true;
          this.validAccNo = false;
          this.customerId = null;
        }
      },
      error: (_) => {
        this.toastr.error('Error occured');
      },
    });
  }
  showPassword() {
    this.showPw = !this.showPw;
  }

  setCategory(typeOfCustomer: number) {
    this.typeOfCustomer = typeOfCustomer;
    setTimeout(() => {
      this.iti = intlTelInput(this.telInput.nativeElement, {
        utilsScript:
          'https://cdn.jsdelivr.net/npm/intl-tel-input@18.2.1/build/js/utils.js',
        // initialCountry: 'gh',
        initialCountry: "auto",
        nationalMode: true,
        separateDialCode: true,
        autoPlaceholder: 'off',
        geoIpLookup: callback => {
          fetch("https://ipapi.co/json")
            .then(res => res.json())
            .then(data => callback(data.country_code))
            .catch(() => callback("us"));
        },
      });
      this.selectedCountryCode = this.iti.getSelectedCountryData().dialCode;
    }, 0);

  }

  closeSuccessfullRegistrationModal() {
    this.closeModal?.nativeElement?.click();
  }
  
  proceedToLogin() {
    this.closeSuccessfullRegistrationModal();
    this.router.navigate(['/new-connection/' + this.utility.name + '/login']);
  }

  tryGetUtilityByName(name: string) {
    this.utilityService.getByName(name).subscribe({
      next: (response) => {
        const responseData = response.body;
        if (responseData?.status === 200 || responseData?.status === 'OK' && responseData.data) {
          this.utility = responseData.data;
        } else {
          this.router.navigate(['']);
        }
      },
      error: (_) => {
        this.router.navigate(['']);
      },
    });
  }
}
