import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { TypeOfConnection } from 'src/app/models/customer.model';
import { MeterPrice } from 'src/app/models/meterPrice.model';
import { PermissionsEnum } from 'src/app/models/role.model';
import { BPSAdminLevel } from 'src/app/models/user.model';
import { MeterPriceService } from 'src/app/services/meter-price.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { TranslationUtils } from 'src/assets/i18n/translation.utils';

@Component({
  selector: 'crud-meter-price',
  templateUrl: './crud-meter-price.component.html',
  styleUrls: ['./crud-meter-price.component.scss'],
})
export class CrudMeterPriceComponent implements OnInit {
  public PermissionsEnum = PermissionsEnum;
  meterPrices: MeterPrice[] = [];

  public currentPage: number = 1;
  public pageSize: number = 5;
  public count: number = 0;
  isEdit: boolean = false;
  selectedMeterPrice: MeterPrice = new MeterPrice();
  actionLabel$!: Observable<string>;
  isEditMode: boolean = false;
  selectedPhase?: TypeOfConnection;

  phases = [
    { label: 'Single Phase', value: TypeOfConnection.SinglePhase },
    { label: 'Three Phase', value: TypeOfConnection.ThreePhase },
  ];
  @ViewChild('closeModal') closeModal: ElementRef;

  @Input() searchText!: string;
  @Input() utilityId: number = 0;
  @Input() bpsAdminLevel!: BPSAdminLevel;

  constructor(
    private meterPriceService: MeterPriceService,
    public permissionsService: PermissionsService,
    private toastr: ToastrService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    if (this.utilityId != 0) {
      this.reloadTable();
    }

    this.updateActionLabel();
  }

  private updateActionLabel(): void {
    const key = this.isEditMode ? TranslationUtils.EDIT_KEY : TranslationUtils.NEW_KEY;
    this.actionLabel$ = this.translateService.stream(key);
  }

  filter() {
    this.currentPage = 1;
    let dataIn = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize,
      },
      filterParams: {
        SearchValue: this.searchText,
      },
      utilityId: this.utilityId,
    };
    this.getAllMeterPrices(dataIn);
  }

  reloadTable(searchText?: string, page: any = null) {
    if (page) {
      this.currentPage = page;
    }

    let dataIn = {
      pageInfo: {
        page: this.currentPage,
        pageSize: this.pageSize,
      },
      filterParams: {
        SearchValue: searchText,
      },
      utilityId: this.utilityId,
    };

    this.getAllMeterPrices(dataIn);
  }

  addNew() {
    this.isEditMode = false;
    this.selectedMeterPrice = new MeterPrice();
    this.updateActionLabel();
  }

  selectMeterPrice(meterPrice: MeterPrice) {
    this.isEdit = true;
    this.selectedMeterPrice = { ...meterPrice };
    this.selectedPhase =
      TypeOfConnection[
        this.selectedMeterPrice.powerPhase.toString() as keyof typeof TypeOfConnection
      ];
    this.updateActionLabel();
  }

  pageChange(value: any) {
    this.currentPage = value;
    this.reloadTable(this.searchText);
  }

  save() {
    if (this.selectedPhase == undefined)
      this.toastr.error('Please select a power phase');
    else {
      this.selectedMeterPrice.utilityId = this.utilityId;
      this.selectedMeterPrice.powerPhase = this.selectedPhase!;
      this.meterPriceService.save(this.selectedMeterPrice).subscribe({
        next: (response) => {
          if (response.status === 200 || response.status === 'OK') {
            this.closeModal?.nativeElement?.click();
            this.toastr.success(response.message);
            if (this.selectedMeterPrice.id === 0) {
              this.pageChange(1);
              return;
            }
            this.reloadTable(this.searchText);
          } else {
            this.toastr.error(response.message);
          }
        },
        error: (_) => this.toastr.error('Error occured'),
      });

      this.isEdit = false;
      this.selectedPhase = undefined;
    }
  }

  deleteMeterPrice() {
    this.meterPriceService.delete(this.selectedMeterPrice.id).subscribe({
      next: (response) => {
        this.closeModal?.nativeElement?.click();
        if (response.status === 200 || response.status === 'OK') {
          this.pageChange(1);
          this.toastr.success(response.message);
        } else {
          this.toastr.error(response.message);
        }
      },
      error: (_) => this.toastr.error('Error occured'),
    });
    this.isEdit = false;
    this.selectedPhase = undefined;
  }

  private getAllMeterPrices(dataIn: any) {
    this.meterPriceService.getAll(dataIn).subscribe({
      next: (response) => {
        this.meterPrices = response.data.data;
        this.count = response.data.count;
        if (this.count == 0) {
          this.toastr.info('No data for given input.');
        }
      },
      error: (_) => this.toastr.error('An error ocurred.'),
    });
  }

  onModalFade() {
    this.isEdit = false;
    this.selectedPhase = undefined;
  }
}
