import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { UtilityService } from 'src/app/services/utility.service';
import { ReportMenuItem } from 'src/app/models/report.menu.item.model';
import { PermissionsService } from 'src/app/services/permissions.service';
import { ReportStateService } from './services/report-state.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {
  public searchText: string = '';
  searchTextUpdate = new Subject<string>();
  pageTitle: string = '';
  crudType: string = "";
  selectedUtilityId: number = 0;
  isBpsAdmin: boolean = false;
  utilityList: any[];
  selectedUtilities: number[] = [];

  public ReportMenuItem = ReportMenuItem;

  constructor(
        private route: ActivatedRoute,
        public authService: AuthService,
        private utilityService: UtilityService,
        public permissionsService: PermissionsService,
        public reportStateService: ReportStateService,
    ) { }

  ngOnInit(): void {
    var utilityId = parseInt(localStorage.getItem('utilityId')!);
    if (utilityId != 0) {
      this.selectedUtilityId = utilityId;
    }
    if (!this.permissionsService.hasUtility) {
      this.isBpsAdmin = true;
      this.getUtilities();
    }
    else {
      this.crudType = this.route.snapshot.paramMap.get('id') || "";
      if (this.crudType != "") {
        this.reportStateService.reportSelected = Number(this.crudType);
      }
    }
    this.reportStateService.preselectMainTab();
  }

  ngOnDestroy(): void {
    this.searchTextUpdate.complete();
  }

  switchMenuItem(item: ReportMenuItem): void {
    this.searchText = '';
    this.reportStateService.mainTabSelected(item);
  }

  getUtilities() {
    this.utilityService.getUtilitiesForSelect().subscribe({
      next: (data) => {
        this.utilityList = data.data;
        this.selectedUtilities = this.utilityList.map(s => s.key);
      },
      error: (error) => console.log(error.statusText)
    });
  }
}
