import { F33Crud, initF33Crud, initF33SsCrud } from "./feeder33.model";

export interface SubstationCrud {
    id: number;
    name: string;
    number: string;
    feeders: F33Crud[];
    feedersNames: string;
    utilityId: number;
}

export interface SubstationCrudCreate {
    id: number;
    name: string;
    number: string;
    feedersIds: number[];
    utilityId: number;
}

export function initSubstationCrud(): SubstationCrud {
    return {
        id: 0,
        name: '',
        number: '',
        feeders: initF33SsCrud(),
        feedersNames: initF33SsCrud().filter(x => x.name).join(','),
        utilityId: 0
    };
}

export function mapObjToCreateObj(ss: SubstationCrud): SubstationCrudCreate {
    return {
        id: ss.id,
        name: ss.name,
        number: ss.number,
        feedersIds: ss.feeders.map(x => x.id),
        utilityId: ss.utilityId
    };
}