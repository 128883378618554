<div class="page-header d-flex justify-content-end">
    <button class="btn btn-outline-danger" [routerLink]="'/buildings'">{{'COMMON.CANCEL' | translate}}</button>
    <button class="btn btn-primary" form="buildingForm" style="width: auto;">{{'COMMON.SAVE' | translate}}</button>
  </div>
  <div class="container-fluid building-information add-new-building">
    <h1 class="page-title">{{'BUILDINGS.INFO' | translate}}</h1>

    <div class="card ">
      <form ngNativeValidate class="form-wrapper" id="buildingForm" (submit)="handleFormSubmit()" >
        <div class="form-item form-floating mb-3">
            <input name="owner" [(ngModel)]="building.buildingOwner" type="text" class="form-control" id="floatingInput" placeholder=" " required>
            <label for="floatingInput">{{'COMMON.TABLE_HEADERS.BUILDING_OWNER' | translate}}</label>
        </div>
        <div class="form-item form-floating mb-3">
            <input name="closest-landmark" [(ngModel)]="building.closestLandmark" type="text" class="form-control" id="floatingInput2" placeholder=" " required>
            <label for="floatingInput2">{{'COMMON.MBC_DETAILS.CLOSEST_LANDMARK' | translate}}</label>
        </div>
        <div class="form-item form-floating mb-3">
          <input name="address" [(ngModel)]="building.address" type="text" class="form-control" id="floatingInput3" placeholder=" " required>
          <label for="floatingInput3">{{'COMMON.ADDRESS' | translate}}</label>
        </div>
        
        <div class="form-item form-floating mb-3">
          <select name="user" required class="form-select" [ngClass]="{'valid selected-option': building.assignedUserId !== 0}" (change)="selectOption($event)">
            <option value="" disabled [selected]="building.assignedUserId === 0">{{'COMMON.FIELD_AGENT' | translate}}</option>
            <option *ngFor="let u of mobileUsers" value="{{u.id}}" [selected]="building.assignedUserId === u.id">{{u.firstName}} {{u.lastName}}</option>

           <label for="floatingInput">{{'COMMON.LAST_NAME' | translate}}</label>
          </select>
        </div>
      </form>
    </div>

  </div>