<div class="card container col-11">

    <label class="section-label">{{header}}</label>

    <div class="row" *ngFor="let item of items">
      <div [ngClass]="item.titleWidth">{{item.title | hasValue}}</div>
      <div [ngClass]="item.bodyWidth">
        {{ item?.value | hasValue }}
      </div>
      <div class="location col-1" *ngIf="item.isGeoLocation">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
          <path d="M256 0c17.7 0 32 14.3 32 32V66.7C368.4 80.1 431.9 143.6 445.3 224H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H445.3C431.9 368.4 368.4 431.9 288 445.3V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V445.3C143.6 431.9 80.1 368.4 66.7 288H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H66.7C80.1 143.6 143.6 80.1 224 66.7V32c0-17.7 14.3-32 32-32zM128 256c0 70.7 57.3 128 128 128s128-57.3 128-128s-57.3-128-128-128s-128 57.3-128 128zm128 80c-44.2 0-80-35.8-80-80s35.8-80 80-80s80 35.8 80 80s-35.8 80-80 80z"fill="#026962" />
        </svg>
      </div>
      
    </div>

</div>
  