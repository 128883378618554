import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../services/user.service';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { User } from '../../models/user.model';
import jwt_decode from "jwt-decode";

@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.scss']
})
export class UpdatePasswordComponent implements OnInit {

  currentPassword: string = "";
  newPassword: string = "";
  confirmPassword: string = "";

  showPwCurrent: boolean = false;
  showPwNew: boolean = false;
  showPwConfirm: boolean = false;

  loggedUser: User = new User;

  constructor(private toastr: ToastrService, private userService: UserService, private authService: AuthService, private router: Router) { }

  ngOnInit(): void {
    let user: any = jwt_decode(localStorage.getItem("token")!);
    if(user != undefined) {
      this.loggedUser.firstName = user["name"].split(' ')[0];
      this.loggedUser.email = user['email'];
    }
  }

  showPasswordCurrent() {
    this.showPwCurrent = !this.showPwCurrent;
  }

  showPasswordNew() {
    this.showPwNew = !this.showPwNew;
  }

  showPasswordConfirm() {
    this.showPwConfirm = !this.showPwConfirm;
  }

  handleFormSubmit() 
  {
    
      if (this.newPassword?.length < 8) {
        this.toastr.error("Password must be at least 8 characters");
        return;
      }
      if (this.newPassword?.search(/[a-z]/i) < 0) {
        this.toastr.error("Password must contain at least one letter.");
        return;
      }
      if (this.newPassword?.search(/[0-9]/) < 0) {
        this.toastr.error("Password must contain at least one digit.");
        return;
      }
      if (this.newPassword?.search(/[$&+,:;=?@#|'<>.^*()%!-]/) < 0) {
        this.toastr.error("Password must contain at least one special character ($&+,:;=?@#|'<>.^*()%!-).");
        return;
      }
      if (this.newPassword != this.confirmPassword) {
        this.toastr.error("Passwords must match");
        return;
      }

      if (this.newPassword == this.currentPassword) {
        this.toastr.error("Your new password is same as your old password");
        return;
      }
    
    
    let data = {
      email: this.loggedUser.email,
      newPassword: this.newPassword,
      currentPassword: this.currentPassword,
      confirmPassword: this.confirmPassword
    }

    this.userService.updatePassword(data).subscribe({
      next: (data: any) => {
        if(data?.status == '404') {
          this.toastr.success(data.message);
        } else {
          this.authService.logout(true);
          this.router.navigate(['']);
        }
      },
      error: (error) => console.log(error.statusText) 
    });
  }
}
